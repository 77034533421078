import React, { Component } from 'react';

class NumberInput extends Component {
    constructor(props) {
        super(props);

        this.on_change = this.on_change.bind(this);
    }

    on_change(e) {
        const { value } = e.target;
        if (value == '' && typeof this.props.blank_value !== 'undefined') {
            e.target.value = this.props.blank_value;
        }

        this.props.handleChange(e);
    }

    render() {
        let layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        const style = this.props.style || {};
        const layout_style = this.props.layout_style || {};

        return (
            <div
                className={`form-group ${this.props.className}`}
                style={layout_style}
            >
                <label>{this.props.label}</label>
                <input
                    type="number"
                    className="form-control"
                    name={this.props.name}
                    style={style}
                    onChange={this.on_change}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onKeyPress={this.props.handleKeyPress}
                    onBlur={this.props.onBlur}
                    autoComplete={this.props.autocomplete}
                    min={this.props.min}
                />
            </div>
        );
    }
}

export default NumberInput;
