import $ from 'jquery';
import handleError from './ajax_redirects/handle_error.js';

function ajaxWrapper(type, url, data, returnFunc, failFunc, returnFuncParams) {
    const beforeSend = request => {
        if (type === 'POST' || type === 'PUT') {
            request.setRequestHeader(
                'X-CSRFToken',
                window.secretReactVars.csrfmiddlewaretoken
            );
            request.setRequestHeader('credentials', 'include');
        }
    };

    if (type === 'POST' || type === 'PUT') {
        data = JSON.stringify(data);
    }

    if (!url || url.indexOf('{') > -1 || url.indexOf('}') > -1) {
        console.log('Request to ', url, ' cancelled. Not a valid path');
        if (failFunc) {
            failFunc(false);
        }
        return false;
    }

    return $.ajax({
        type,
        url,
        contentType: 'application/json',
        beforeSend,
        data,
        statusCode: {
            200(value) {
                if (typeof value === 'string') {
                    console.log(
                        'Ajax got invalid string response while requesting ',
                        url
                    );
                    failFunc();
                } else if (typeof value === 'object' && 'redirect' in value) {
                    window.location = `${value.redirect}?redirect=${window.secretReactVars.BASE_URL}`;
                } else if (returnFuncParams) {
                    returnFunc(...returnFuncParams);
                } else {
                    returnFunc(value);
                }
            },
            400(xhr) {
                handleError(xhr, type, url, data, returnFunc, failFunc);
            },
            401(xhr) {
                handleError(xhr, type, url, data, returnFunc, failFunc);
            },
            404(xhr) {
                handleError(xhr, type, url, data, returnFunc, failFunc);
            },
            500(xhr) {
                handleError(xhr, type, url, data, returnFunc, failFunc);
            },
        },
    });
}

export default ajaxWrapper;
