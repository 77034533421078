import React, { Component } from 'react';
import HTML_COLORS from 'base/colors.js';

import { Alert } from 'library';

class AnonDistrict extends Component {
    color_brightness(code) {
        var code = code.substring(1); // strip #
        const rgb = parseInt(code, 16); // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff; // extract red
        const g = (rgb >> 8) & 0xff; // extract green
        const b = (rgb >> 0) & 0xff; // extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        let text_color = 'white';
        if (luma > 100) {
            text_color = 'black';
        }

        return text_color;
    }

    render() {
        const color = HTML_COLORS[this.props.district.color];
        let number_display = null;
        let color_display = null;

        if (String(this.props.district.number) != '0') {
            number_display = (
                <div
                    style={{
                        display: 'inline-block',
                        padding: '4px',
                        margin: '0px 4px',
                    }}
                >
                    {`D${  this.props.district.number}`}
                </div>
            );
        }
        if (this.props.district.color != 'White') {
            const color_display_style = {
                padding: '4px 8px',
                background: color,
                color: this.color_brightness(color),
                borderRadius: '4px',
                boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            };

            const color_display_container_style = {
                display: 'inline-block',
            };

            if (number_display) {
                color_display_container_style.borderLeft =
                    'thin solid black';
                color_display_container_style.marginLeft = '4px';
                color_display_container_style.paddingLeft = '8px';
            }

            color_display = (
                <div style={color_display_container_style}>
                    <div style={color_display_style}>
                        {this.props.district.color}
                    </div>
                </div>
            );
        }

        let error = null;
        if (!number_display && !color_display) {
            error = <Alert type="warning" text="No Anonymous Identifier" />;
        }

        return (
            <div className={this.props.className}>
                {error}
                {number_display}
                {color_display}
            </div>
        );
    }
}

export default AnonDistrict;
