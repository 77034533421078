import React, { Component } from 'react';
import './App.css';
import ajaxWrapper from 'base/ajax.js';
import settings from 'base/settings.js';
import $ from 'jquery';

import * as Sentry from '@sentry/browser';

import { getURL } from 'functions';

import { Wrapper } from 'library';
import { BASE_DOMAIN, HEADER_HEIGHT, MATH_ANEX_EMPLOYEE_ROLES, DISTRICT_STAFF_ROLES } from 'constants';

// Scaffolding
import ErrorBoundary from 'projectLibrary/error_boundary.js';
import BrokenComponent from 'projectLibrary/broken_component.js';
import BrokenPermissionComponent from 'projectLibrary/broken_permission_component.js';

import PageNotFound from 'projectLibrary/page_not_found.js';
import Nav from 'projectLibrary/nav.js';
import TestPage from 'projectLibrary/test_page.js';
import CleverInvalid from 'projectLibrary/clever_invalid.js';
import CleverLanding from 'projectLibrary/clever_landing.js';
import ClasslinkInvalid from 'pages/classlink/invalid.js';
import ClasslinkLanding from 'pages/classlink/landing.js';
import LogIn from './pages/scaffold/logIn.js';
import PasswordResetRequest from './pages/scaffold/passwordResetRequest.js';
import PasswordReset from './pages/scaffold/passwordReset.js';
import ForcedPasswordReset from './pages/scaffold/forcedPasswordReset.js';

// API Querying
import APIDocs from './pages/admin/apiDocs.js';

import ReportingDistrictSelect from './pages/reports/reporting_district_select.js';

import ReportDashboard from './pages/reports/main.js';
import ResultsExplorer from './pages/reports/results_explorer/results_explorer.js';
import ProctorTattletale from './pages/reports/results_explorer/pages/proctor_tattletale.js';

import Proctor from './pages/proctor/proctor.js';
import PrintableAssessment from 'pages/proctor/printable_assessment';
import ResponseExplorer from './pages/analysts/response_explorer.js';
import AnonymousAssessments from './pages/proctor/anonymous_assessments.js';

import ItemsWithoutWOE from './pages/way_of_explanation/items.js';

import AdminMappingAndScoring from './pages/automated_question_response_scoring/admin_mapping_and_scoring/main.js';
import EditWaysOfThinkingKA from './pages/automated_question_response_scoring/admin_mapping_and_scoring/ka_ways_of_thinking.js';

import QuestionContext from './pages/way_of_explanation/question_context.js';
import StudentResponse from './pages/way_of_explanation/student_response.js';
import LatestComponentAssignments from './pages/approach_analysis/latest_component_assignments.js';

import MCLQC from './pages/way_of_explanation/mcl_qc.js';
import MCLQCFix from './pages/way_of_explanation/mcl_qc_fix.js';

import IndividualTime from './pages/time_tracking/individual_report.js';
import AssignAnchor from './pages/way_of_explanation/assign_anchor.js';

import QuestionConcepts from './pages/question_concepts/question_concepts.js';
import Questions from './pages/questions/list.js';
import ReviewQuestion from './pages/assessments/review_question.js';
import QuestionAnswersAssignment from './pages/questions/question_answers_assignment.js';
import StudentResponseGrouping from './pages/assessments/student_response_grouping.js';

import EditQuestion from './pages/assessments/edit_question.js';
import EditQuestionConcept from './pages/assessments/edit_question_concept.jsx';
import Assessments from './pages/assessments/assessments.js';
import AssessmentBuilder from './pages/assessments/builder/main.js';
import AssessmentAdministrationGuide from './pages/assessments/administration_guide.js';
import AssessmentPrintouts from './pages/assessments/assessment_printouts.js';
import UploadAssessmentResponses from './pages/assessments/upload_assessment_responses.js';
import ReviewAssessmentResponses from './pages/assessments/review_assessment_responses.js';

import Districts from './pages/admin/districts.js';
import EditDistrict from './pages/assessments/edit_district.js';
import UploadRoster from './pages/assessments/upload_roster.js';
import ScoreAssessment from './pages/assessments/score_assessment.js';
import CheckQuestionNumbers from './pages/assessments/check_question_numbers.js';

// build Assessments
import QuestionsByFilteredScore from './pages/assessments/questions_by_filtered_score.js';

// analyst pages
import UserQC from './pages/way_of_explanation/user_qc.js';
import AnalystOverview from './pages/analysts/analyst_overview.js';
import CalibrationQuiz from './pages/analysts/calibration_quiz.js';
import CalibrationList from './pages/analysts/calibration_list.js';
import CalibrationResponses from './pages/analysts/calibration_responses.js';

import CreateReports from './pages/analysts/create_reports.js';

import DailyComponentQC from './pages/analysts/component_daily_qc.js';
import DailyQCList from './pages/analysts/daily_qc_list.js';
import DailyQCResponses from './pages/analysts/daily_qc_responses.js';

import LatestResponses from './pages/analysts/latest_responses.js';
import EditAnalyst from './pages/analysts/edit_analyst.js';

import TestDiagram from './pages/sankey/test_diagram.js';

import ScheduleA from './pages/landing_pages/schedule_a.js';
import PrivacyPolicy from './pages/landing_pages/privacy_policy.js';
import TermsOfService from './pages/landing_pages/terms_of_service.js';
import Game from './pages/language_of_algebra/game_of_elimination.js';

import NewTeacher from './pages/landing_pages/new_teacher.js';

import QuestionScoringDashboard from './pages/assessments/question_scoring_dashboard.js';

import AssetOverview from './pages/actionable_assets/overview.js';
import EditAsset from './pages/actionable_assets/edit.js';

import SharePermissions from './pages/user/share_permissions.js';
import CanSharePermissions from './pages/user/can_share_permissions.js';

import CronActivityList from './pages/admin/cron_activity/list';
import CleverActivity from './pages/admin/cron_activity/clever';
import ClasslinkActivity from './pages/admin/cron_activity/classlink';

import ScoringConfigurationDashboard from './pages/automated_question_response_scoring/main.js';
import QuestionComponentOverview from './pages/automated_question_response_scoring/question_components_overview.js';
import ComponentAutomationMapping from './pages/automated_question_response_scoring/component_automation_mapping.js';
import AutomatedRequestList from './pages/automated_question_response_scoring/automated_request_list.js';

import WorkThatNeedsDoin from './pages/analysts/work_that_needs_doin.js';
import FindMath from './pages/analysts/find_math.js';

import ReportLookup from './pages/reports/pages/report_lookup';

import MarkdownTest from './pages/admin/markdown_test';

import FeaturesExplorer from './pages/analysts/feature_analysis/features_explorer.js';

import StudentThinkingPage from './pages/analysts/feature_analysis/student_thinking_page.js';

import ResponseFeatures from './pages/analysts/feature_analysis/features_response.js';

const ERRORS_WE_DONT_CARE_ABOUT = [
    {
        type: 'SecurityError',
        value: 'The operation is insecure.',
    },
    {
        type: 'Error',
        value: 'Extension context invalidated.',
    },
    {
        type: 'TypeError',
        value: 'Attempted to assign to readonly property.',
    },
];

const WEBFLOW_REDIRECTS = [
  '',
  'login',
];

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_loaded: false,
            context_loaded: false,
            user: null,
            alerts: 1,
        };

        this.focus = 'focus';
        this.idle_time = 0;
        this.working_time = 0;

        this.ajaxCallback = this.ajaxCallback.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.alerts = this.alerts.bind(this);
        this.track_focus = this.track_focus.bind(this);
        this.idle_timer_increment = this.idle_timer_increment.bind(this);
        this.working_timer_increment = this.working_timer_increment.bind(this);
        this.send_event = this.send_event.bind(this);
    }

    alerts() {
        this.setState({ alerts: this.state.alerts + 1 });
    }

    componentDidMount() {
        const app = this;
        $(window).on('unload', e => {
            app.send_event('Unload');
            return 'Closing';
        });

        $(window).on('blur focus', e => {
            app.track_focus(e);
        });
        $(document).ready(function () {
            // Increment the idle time counter every minute.
            const idle_interval = setInterval(app.idle_timer_increment, 1000); // 15 second
            const working_interval = setInterval(
                app.working_timer_increment,
                1000
            ); // 15 second
            // Zero the idle timer on mouse movement.
            $(this).mousemove(e => {
                if (app.focus == 'focus') {
                    if (app.idle_time > 15) {
                        app.send_event('Returned');
                    }
                    app.idle_time = 0;
                }
            });
            $(this).mouseup(e => {
                if (app.focus == 'focus') {
                    if (app.idle_time > 15) {
                        app.send_event('Returned');
                    }
                    app.idle_time = 0;
                }
            });
            $(this).keypress(e => {
                if (app.focus == 'focus') {
                    if (app.idle_time > 15) {
                        app.send_event('Returned');
                    }
                    app.idle_time = 0;
                }
            });
            $(this).scroll(e => {
                if (app.focus == 'focus') {
                    if (app.idle_time > 15) {
                        app.send_event('Returned');
                    }
                    app.idle_time = 0;
                }
            });
        });

        ajaxWrapper('GET', '/api/csrfmiddlewaretoken/', {}, this.ajaxCallback);

        const params = getURL();
        const path = params[0].toLowerCase();
        const token = localStorage.getItem('token');

        const loginNoRedirects = [
            '',
            'login',
            'passwordresetrequest',
            'passwordreset',
            'admin',
            'comparison_report',
            'anex_framework',
            'broken_page',
            'proctor',
            'p',
            'schedule_a',
            'language_of_algebra_game',
            'privacy_policy',
            'clever_invalid',
            'clever_landing',
            'classlink_invalid',
            'classlink_landing',
            'terms',
            'administration_guide',
            'assessment_printouts',
            'printable_assessment',
        ];

        if (token && !this.state.user_loaded) {
          try {
            ajaxWrapper('GET', '/users/user/', {}, this.loadUser.bind(this));
          } catch (e) {
            this.setState({ user_loaded: true })
          }
          this.setState({ token });
      } else if (WEBFLOW_REDIRECTS.includes(path) && path != 'login') {
        window.location.href = '/login/';
      } else if ( !loginNoRedirects.includes(path) && path != '') {
        localStorage.setItem('redirect', getURL().href);
        window.location.href = '/login/';
      } else {
        this.setState({ user_loaded: true });
      }
    }

    send_event(event_name) {
        if (window.cmState.user) {
            let domain = '';
            if (window.location.hostname == 'mathanex.com') {
                domain = '';
            } else if (window.location.hostname != 'localhost') {
                domain = BASE_DOMAIN;
            }

            ajaxWrapper(
                'POST',
                `${domain}/api/user/userevent/`,
                {
                    user: window.cmState.user.id,
                    event_name,
                    page: window.location.pathname,
                    params: window.location.search,
                },
                console.log
            );
        }
    }

    idle_timer_increment() {
        if (this.idle_time == 15 && this.focus == 'focus') {
            this.send_event('Idle');
            this.working_time = 0;
        }
        this.idle_time += 1;
    }

    working_timer_increment() {
        if (
            this.working_time % 60 == 0 &&
            this.working_time > 0 &&
            this.idle_time < 15 &&
            this.focus == 'focus'
        ) {
            this.send_event('Working');
        }
        this.working_time += 1;
    }

    track_focus(e) {
        const prevType = this.state.focus;

        if (prevType != e.type) {
            //  reduce double fire issues
            switch (e.type) {
                case 'blur':
                    this.focus = 'blur';
                    this.working_time = 0;
                    this.send_event('Blur');
                    break;
                case 'focus':
                    this.focus = 'focus';
                    this.working_time = 0;
                    this.send_event('Focus');
                    break;
            }
        }
    }

    loadUser(result) {
      window.cmState.setGlobalState('user', result);

      this.send_event('Loaded');
      const params = getURL();

      const no_reporting_redirect_domains = [
        'landing.mathanex.com',
        'staging.mathanex.com',
        'localhost',
      ];
      const no_reporting_redirect_paths = [
        'logout',
        'share_permissions',
        'forcedpasswordreset',
      ];

      if (params[0] != 'logout') {
        if (result.forced_password_reset) {
          if (
            window.location.pathname !=
            `/forcedpasswordreset/${result.password_reset_code}/`
          ) {
            window.location.href = `/forcedpasswordreset/${result.password_reset_code}/`;
          }
        }
      }

      this.setState({
        user: result,
        user_loaded: true,
      }, () => {
        const params = getURL();
        let path = params[0].toLowerCase();
        if (this.state.user && this.state.user.role && WEBFLOW_REDIRECTS.includes(path)) {
          this.handleLoggedUserRedirect(this.state.user.role);
        }
      });
    }

    handleLoggedUserRedirect(role) {
      if (MATH_ANEX_EMPLOYEE_ROLES.includes(role)) {
        window.location.href = '/work/';
      } else if (DISTRICT_STAFF_ROLES.includes(role) || role == 'Teacher') {
        window.location.href = '/report_navigator/';
      } else if (role == 'Student') {
        window.location.href = '/proctor/';
      }
    }

    logOut() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_time');

        window.location.href = '/login/';
    }

    ajaxCallback(value) {
        window.secretReactVars.csrfmiddlewaretoken = value.csrfmiddlewaretoken;

        window.secretReactVars.clever_client_id = value.clever_client_id;
        window.secretReactVars.clever_redirect_url = value.clever_redirect_url;

        window.secretReactVars.classlink_client_id = value.classlink_client_id;
        window.secretReactVars.classlink_redirect_url =
            value.classlink_redirect_url;

        if ('glitchtip_dsn' in value) {
            Sentry.init({
                dsn: value.glitchtip_dsn,
                debug: true,
                beforeSend(event) {
                    console.log(event);

                    if ('exception' in event) {
                        const error = event.exception.values[0];

                        for (const invalid_error of ERRORS_WE_DONT_CARE_ABOUT) {
                            if (
                                invalid_error.type == error.type &&
                                invalid_error.value == error.value
                            ) {
                                console.log(
                                    'ERRORS_WE_DONT_CARE_ABOUT Detected'
                                );
                                return null;
                            }
                        }
                    }

                    return event;
                },
            });
        }

        this.setState({
            context_loaded: true,
        });
    }

    render() {
        const params = getURL();
        window.cmState.params = params;

        const wrapper_style = {
            paddingTop: `${HEADER_HEIGHT}px`,
            paddingLeft: '0px',
            paddingRight: '0px',
            marginTop: '0px',
        };

        const adminPages = [
            'applist',
            'models',
            'modelinstances',
            'modelinstancestable',
            'instance',
            'can_share_permissions',
            'districts',
        ];

        const loggedInPages = [];
        const route = params[0].toLowerCase();

        const loading = <h1>Loading . . . </h1>;
        let content = null;
        var navbar = null;

        const loaded = this.state.user_loaded && this.state.context_loaded;
        if (loaded) {
            var navbar = <Nav user={this.state.user} logOut={this.logOut} />;

            let is_staff = false;
            if (this.state.user) {
                is_staff = this.state.user.is_staff;
            }

            const teacher_allowed_routes = [
                'report_navigator',
                'teacher_report',
                'teacher_participation_report',
                'comparison_report',
                'logout',
                'anex_framework',
                'district_overview',
                'question_overview',
                'student_data',
                'forcedpasswordreset',
            ];

            const district_user_allowed_routes = [
                'report_navigator',
                'logout',
                'forcedpasswordreset',
                'share_permissions',
            ];

            if (params[0] === '' || route == 'login') {
                content = <LogIn params={params} />;
            } else if (route == 'passwordresetrequest') {
                content = <PasswordResetRequest />;
            } else if (route == 'passwordreset') {
                content = <PasswordReset user_id={params[1]} />;
            } else if (route == 'forcedpasswordreset') {
                content = <ForcedPasswordReset user_id={params[1]} />;
            } else if (route == 'schedule_a') {
                // navbar = null;
                content = <ScheduleA />;
            } else if (route == 'privacy_policy') {
                content = <PrivacyPolicy />;
            } else if (route == 'terms') {
                content = <TermsOfService />;
            } else if (route == 'proctor' || route == 'p') {
                var navbar = null;
                content = (
                    <Proctor
                        district_id={params[1]}
                        assessment_id={params[2]}
                        selected_language={params['language']}
                        student_id={params['student_id']}
                        alternative_identifier={
                            params['alternative_identifier']
                        }
                    />
                );
            } else if (
                route == 'printable_assessment' &&
                typeof params[1] !== 'undefined' &&
                typeof params[2] !== 'undefined'
            ) {
                var navbar = null;
                wrapper_style.paddingTop = '0px';

                content = (
                    <PrintableAssessment
                        district_id={params[1]}
                        assessment_id={params[2]}
                        selected_language={params['language']}
                    />
                );
            } else if (
                route == 'response_explorer' &&
                typeof params[1] !== 'undefined'
            ) {
                content = (
                    <ResponseExplorer
                        question_id={params[1]}
                        way_of_thinking_id={params.way_of_thinking}
                    />
                );
            } else if (route == 'logout') {
                this.logOut();
            } else if (
                loggedInPages.indexOf(route) > -1 &&
                loaded &&
                typeof this.state.user.id !== 'undefined'
            ) {
                // window.location = '/login/';
                console.log('Need to be logged in');
            }

            // This is where all school employees should go
            else if (
                !is_staff &&
                this.state.user &&
                this.state.user.role == ''
            ) {
                content = (
                    <div className="container">
                        <h3>
                            Your Account Is Improperly Configured And Missing A
                            Role. Please Contact Math Anex Support at
                            support@mathanex.com.
                        </h3>
                    </div>
                );
            } else if (!is_staff && adminPages.indexOf(route) > -1 && loaded) {
                // window.location = '/';
                console.log('Not an admin', loaded, this.state.user);
            } else if (route == 'clever_invalid') {
                content = <CleverInvalid />;
            } else if (route == 'clever_landing') {
                content = (
                    <CleverLanding
                        access={params[1]}
                        refresh={params[2]}
                        role={params.role}
                        created={params.created}
                    />
                );
            } else if (route == 'classlink_invalid') {
                content = <ClasslinkInvalid />;
            } else if (route == 'classlink_landing') {
                content = (
                    <ClasslinkLanding
                        access={params[1]}
                        refresh={params[2]}
                        role={params.role}
                        created={params.created}
                    />
                );
            } else if (route == 'new_teacher') {
                content = <NewTeacher />;
            } else if (route == 'administration_guide') {
                var navbar = null;
                wrapper_style.paddingTop = '0px';
                content = (
                    <AssessmentAdministrationGuide district_id={params[1]} />
                );
            } else if (route == 'assessment_printouts') {
                var navbar = null;
                wrapper_style.paddingTop = '0px';
                content = <AssessmentPrintouts district_id={params[1]} />;
            }

            // Handlers for catching invalid route requests by specific user roles
            else if (
                !is_staff &&
                this.state.user &&
                this.state.user.role == 'Student'
            ) {
                window.location.href = '/proctor/';
                content = <p>Redirecting...</p>;
            } else if (
                !is_staff &&
                this.state.user &&
                this.state.user.role == 'Teacher' &&
                teacher_allowed_routes.indexOf(route) == -1
            ) {
                window.location.href = '/report_navigator/';
                content = <p>Redirecting...</p>;
            } else if (
                !is_staff &&
                this.state.user &&
                DISTRICT_STAFF_ROLES.indexOf(this.state.user.role) > -1 &&
                district_user_allowed_routes.indexOf(route) == -1
            ) {
                window.location.href = '/report_navigator/';
                content = <p>Redirecting...</p>;
            } else if (route == 'district_reports') {
                content = <ReportingDistrictSelect />;
            } else if (route == 'report_navigator') {
                navbar = null;
                wrapper_style.paddingTop = '0px';

                if (params[1] == 'results_explorer') {
                    content = (
                        <ResultsExplorer
                            district_id={params[2]}
                            saved_filter_id={params[3]}
                        />
                    );
                } else if (params[1] == 'lookup') {
                    content = <ReportLookup send_event={this.send_event} />;
                } else {
                    content = (
                        <ReportDashboard
                            page={params[1]}
                            admin_spoof={params['admin_spoof']}
                            district_spoof={params['district_spoof']}
                        />
                    );
                }
            } else if (route == 'share_permissions') {
                content = (
                    <SharePermissions district_id={params['district_id']} />
                );
            } else if (route == 'can_share_permissions') {
                content = <CanSharePermissions district_id={params[1]} />;
            } else if (
                !is_staff &&
                this.state.user &&
                MATH_ANEX_EMPLOYEE_ROLES.indexOf(this.state.user.role) == -1
            ) {
                content = (
                    <div className="container">
                        <h3>
                            Your Account Is Improperly Configured For A Staff
                            Account. Please Contact Math Anex Support at
                            support@mathanex.com.
                        </h3>
                    </div>
                );
            }
            // End of school employee routes
            else if (route == 'anex_framework') {
                window.location.href =
                    'https://drive.google.com/file/d/1k6_iCPUdrGnNTnY4OgzgUlf-VWjjF2Od/view';// TODO: upload to aws?
            } else if (route == 'apidocs') {
                content = <APIDocs />;
            } else if (route == 'calibration_quiz' && params.component) {
                content = (
                    <CalibrationQuiz
                        question_id={params[1]}
                        component_id={params.component}
                    />
                );
            } else if (route == 'calibration_list') {
                content = <CalibrationList user={params[1]} />;
            } else if (route == 'calibration_responses') {
                content = <CalibrationResponses calibration={params[1]} />;
            } else if (route == 'daily_qc' && params.component) {
                content = (
                    <DailyComponentQC
                        qc_user={params[1]}
                        question_id={params[2]}
                        component_id={params.component}
                    />
                );
            } else if (route == 'daily_qc_list') {
                content = <DailyQCList user={params[1]} />;
            } else if (
                route == 'daily_qc_responses' &&
                typeof params[1] !== 'undefined'
            ) {
                content = <DailyQCResponses daily_qc={params[1]} />;
            } else if (route == 'latest_responses') {
                content = <LatestResponses user={params[1]} />;
            } else if (route == 'edit_analyst') {
                content = <EditAnalyst user={params[1]} />;
            } else if (
                route == 'response_scoring' &&
                typeof params[1] !== 'undefined'
            ) {
                navbar = <Nav user={this.state.user} logOut={this.logOut} />;
                content = (
                    <AdminMappingAndScoring
                        user={this.state.user}
                        question_id={params[1]}
                    />
                );
            } else if (route == 'ka_ways_of_thinking') {
                console.log('I am here');
                content = (
                    <EditWaysOfThinkingKA
                        user={this.state.user}
                        question_id={params[1]}
                    />
                );
            } else if (route == 'items_to_categorize') {
                navbar = <Nav user={this.state.user} logOut={this.logOut} />;
                content = <ItemsWithoutWOE user={this.state.user} />;
            } else if (
                route == 'component_assignment' &&
                typeof params[1] !== 'undefined' &&
                typeof params[2] !== 'undefined'
            ) {
                navbar = <Nav user={this.state.user} logOut={this.logOut} />;
                content = (
                    <ComponentAssignment
                        question_id={params[1]}
                        component_id={params[2]}
                    />
                );
            } else if (
                route == 'component_assignment_qc' &&
                typeof params[1] !== 'undefined' &&
                typeof params[2] !== 'undefined'
            ) {
                navbar = <Nav user={this.state.user} logOut={this.logOut} />;
                let review_flagged = false;
                if ('review_flagged' in params) {
                    review_flagged = true;
                }
                content = (
                    <LatestComponentAssignments
                        question_id={params[1]}
                        component_id={params[2]}
                        review_flagged={review_flagged}
                    />
                );
            } else if (route == 'individual_time_report') {
                content = <IndividualTime user_id={params[1]} />;
            } else if (route == 'questions') {
                content = <Questions />;
            } else if (route == 'question_concepts') {
                content = <QuestionConcepts />;
            } else if (route == 'review_question') {
                content = <ReviewQuestion question_id={params[1]} />;
            } else if (route == 'question_answers_assignment') {
                content = <QuestionAnswersAssignment question_id={params[1]} />;
            } else if (route == 'student_response_grouping') {
                content = <StudentResponseGrouping question_id={params[1]} />;
            } else if (route == 'student_response') {
                content = (
                    <StudentResponse student_question_response_id={params[1]} />
                );
            } else if (route == 'edit_question_concept') {
                content = (
                    <EditQuestionConcept question_concept_id={params[1]} />
                );
            } else if (route == 'edit_question') {
                content = <EditQuestion question_id={params[1]} />;
            } else if (route == 'edit_assessment') {
                content = <AssessmentBuilder assessment_id={params[1]} />;
            } else if (route == 'upload_assessment_responses') {
                content = (
                    <UploadAssessmentResponses assessment_id={params[1]} />
                );
            } else if (route == 'review_assessment_responses') {
                content = (
                    <ReviewAssessmentResponses assessment_id={params[1]} />
                );
            } else if (route == 'score_assessment') {
                content = <ScoreAssessment assessment_id={params[1]} />;
            } else if (route == 'create_reports') {
                content = <CreateReports />;
            } else if (route == 'districts') {
                content = <Districts />;
            } else if (route == 'edit_district') {
                content = <EditDistrict district_id={params[1]} />;
            } else if (route == 'upload_roster') {
                content = <UploadRoster />;
            } else if (route == 'manage_assessments') {
                content = <Assessments />;
            } else if (route == 'question_context') {
                content = <QuestionContext question_id={params[1]} />;
            } else if (route == 'mcl_qc') {
                content = <MCLQC question_id={params[1]} />;
            } else if (route == 'mcl_qc_fix') {
                content = <MCLQCFix question_id={params[1]} />;
            } else if (route == 'user_qc') {
                content = <UserQC qc_user={params[1]} />;
            } else if (route == 'analyst_overview') {
                content = <AnalystOverview />;
            } else if (route == 'check_numbers') {
                content = <CheckQuestionNumbers />;
            } else if (route == 'tattletale') {
                content = (
                    <ProctorTattletale
                        question_id={params[1]}
                        answer={params.answer}
                        group={params.group}
                    />
                );
            } else if (route == 'assign_anchor') {
                content = (
                    <AssignAnchor
                        question_id={params[1]}
                        way_of_thinking_id={params[2]}
                    />
                );
            } else if (route == 'test_diagram') {
                content = <TestDiagram />;
            } else if (route == 'explanation_parsing') {
                content = <ExplanationParsing />;
            } else if (route == 'questions_list_by_filtered_score') {
                content = <QuestionsByFilteredScore />;
            } else if (route == 'questions_on_assessment_scoring') {
                content = (
                    <QuestionScoringDashboard question={params.question} />
                );
            } else if (route == 'actionable_assets') {
                if (params[1]) {
                    content = <EditAsset id={params[1]} />;
                } else {
                    content = <AssetOverview />;
                }
            } else if (route == 'scoring_configuration') {
                if (
                    params[1] &&
                    params[2] &&
                    params[1] === 'question_component_overview'
                ) {
                    content = (
                        <QuestionComponentOverview question_id={params[2]} />
                    );
                } else if (
                    params[1] &&
                    params[2] &&
                    params[3] &&
                    params[1] === 'component_automation_mapping'
                ) {
                    content = (
                        <ComponentAutomationMapping
                            question_id={params[2]}
                            component_id={params[3]}
                        />
                    );
                } else {
                    content = <ScoringConfigurationDashboard />;
                }
            } else if (route == 'find_math') {
                content = <FindMath question_id={params[1]} />;
            } else if (route == 'cron_activity') {
                if (params[1]) {
                    if (params[1] === 'clever') {
                        content = <CleverActivity />;
                    } else if (params[1] === 'classlink') {
                        content = <ClasslinkActivity />;
                    }
                } else {
                    content = <CronActivityList />;
                }
            } else if (route == 'features_explorer') {
                content = <FeaturesExplorer question_id={params[1]} />;
            } else if (route == 'features_response') {
                content = (
                    <ResponseFeatures
                        question_id={params[1]}
                        student_question_response_id={params[2]}
                    />
                );
            } else if (route == 'student_thinking_lab') {
                content = (
                    <StudentThinkingPage
                        question_id={params[1]}
                        component_id={params[2]}
                    />
                );
            } else if (
                route == 'anonymous_assessments' &&
                typeof params[1] !== 'undefined'
            ) {
                content = <AnonymousAssessments assessment_id={params[1]} />;
            } else if (route == 'broken_page') {
                content = <BrokenComponent />;
            } else if (route == 'broken_permission_page') {
                content = <BrokenPermissionComponent />;
            } else if (route == 'language_of_algebra_game') {
                content = <Game />;
            } else if (route == 'dict_builder') {
                content = <TestPage />;
            } else if (route == 'work') {
                content = <WorkThatNeedsDoin />;
            } else if (route == 'automated_requests_list') {
                content = <AutomatedRequestList />;
            } else if (route == 'markdown_test') {
                content = <MarkdownTest />;
            }

            // 404 handler
            else {
                content = <PageNotFound />;
            }
        }

        return (
            <div className="App container-fluid" style={{ padding: '0px' }}>
                <ErrorBoundary>
                    {navbar}
                    <Wrapper
                        style={wrapper_style}
                        content={content}
                        loaded={loaded}
                    />
                </ErrorBoundary>
            </div>
        );
    }
}

export default App;
