import React from 'react';

import ExplanationCards from './explanation_cards';

const ExplanationsExplained = data => {
    return (
        <div
            style={{
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                className="h1"
                style={Object.assign({
                    marginBottom: '20px',
                    textAlign: 'center',
                    fontSize: '26px',
                })}
            >
                Improving Mathematical Communication
            </div>
            <div
                style={{
                    fontSize: '16px',
                    margin: '0px 35px 20px',
                    textAlign: 'center',
                }}
            >
                Our goal is to support students in their pursuit of becoming
                strong communicators. We analyze their explanations, not to make
                a judgement of the student's abilities, but to identify the
                assets they demonstrated, as well as opportinuties for growth.
            </div>
        </div>
    );
};

export default ExplanationsExplained;
