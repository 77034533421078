import React from 'react';

import { Container } from 'library';

function CleverInvalid() {
    const containerStyle = {
        background: 'white',
        boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
        padding: '0px',
    };

    const wrapperStyle = {
        background: '#fafafa',
    };

    return (
        <div style={wrapperStyle}>
            <Container min_height style={containerStyle}>
                <div className="row">
                    <div className="col-12">
                        <br />
                        <br />
                        <h1>
                            Sorry your credentials we`re not found in our
                            system. Please contact support at
                            support@mathanex.com
                        </h1>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default CleverInvalid;

