import React, { Component } from 'react';

import ajaxWrapper from 'base/ajax.js';

import { Button, TextArea, Alert, FormWithChildren, Wrapper } from 'library';
import QuestionDisplay from 'pages/questions/question_display.js';

const NEEDED_SCORE = 80;

class DailyQC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            question: {
                id: 0,
                project_id: 0,
                name: '',
                question_text: '',
                options: [],
                item: { rubric: '' },
            },
            ways_of_thinking: [],
            current_woe: null,
            index: 0,
            num_questions: 20,
            studentquestionresponse: {},
            correct_answer: '',
            guess: {},
            right_answers: 0,
            num_resolved: 0,
            analyst_right: 0,
            daily_qc: {},
            qc_user_name: '',
            scored: 0,
            qc_response: null,
            completed: false,
            last_qc: {},
            num_skipped: 0,
            component: {},
            components: [],
        };
        this.submit_callbacks = 0;
        this.current_display = 0;

        this.question_callback = this.question_callback.bind(this);
        this.next = this.next.bind(this);
        this.get_responses = this.get_responses.bind(this);
        this.start_daily_qc = this.start_daily_qc.bind(this);
        this.get_qc_user = this.get_qc_user.bind(this);
        this.daily_qc_response_callback =
            this.daily_qc_response_callback.bind(this);
        this.assign_to_my_way = this.assign_to_my_way.bind(this);
        this.my_mistake = this.my_mistake.bind(this);
        this.last_qc_callback = this.last_qc_callback.bind(this);
        this.component_callback = this.component_callback.bind(this);
        this.submit_component = this.submit_component.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/dailyqc/?limit=1&completed=True&order_by=-created_at&qc_user=${this.props.qc_user}&approach_component=${this.props.component_id}`,
            {},
            this.last_qc_callback
        );
        ajaxWrapper(
            'POST',
            '/api/home/dailyqc/',
            {
                user: window.cmState.user.id,
                qc_user: this.props.qc_user,
                question: this.props.question_id,
                approach_component: this.props.component_id,
            },
            this.start_daily_qc
        );
        ajaxWrapper(
            'GET',
            `/api/user/user/${this.props.qc_user}/`,
            {},
            this.get_qc_user
        );
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            this.question_callback
        );
        ajaxWrapper(
            'GET',
            `/api/home/approachcomponent/${this.props.component_id}/`,
            {},
            this.component_callback
        );
    }

    component_callback(result) {
        this.setState({ component: result[0].approachcomponent });
    }

    get_qc_user(result) {
        const qc_user = result[0].user;
        this.setState({
            qc_user_name: `${qc_user.first_name} ${qc_user.last_name[0]}`,
        });
    }

    start_daily_qc(result) {
        this.setState({ daily_qc: result[0].dailyqc });
    }

    question_callback(result) {
        this.setState({ question: result[0].question });
    }

    next() {
        if (this.state.scored == this.state.num_questions) {
            const score =
                (this.state.right_answers / this.state.num_questions) * 100;
            const analyst_score =
                (this.state.analyst_right / this.state.num_questions) * 100;
            const resolved_pct =
                (this.state.num_resolved / this.state.num_questions) * 100;
            let passed = false;
            if (score > NEEDED_SCORE) {
                passed = true;
            }

            const data = {
                score,
                passed,
                resolved_pct,
                analyst_score,
                completed: true,
            };

            ajaxWrapper(
                'POST',
                `/api/home/dailyqc/${this.state.daily_qc.id}/`,
                data,
                console.log
            );
            this.setState({ completed: true });
        } else {
            const new_index = this.state.index + 1;
            const new_component =
                this.state.components[new_index].responsecomponentanalysis;
            this.setState({
                index: new_index,
                current_component: new_component,
                student_response: new_component.response,
                qc_response: null,
                correct_answer: '',
                guess: null,
            });
        }
    }

    submit_component(has_component) {
        const resolved =
            has_component == this.state.current_component.has_component;

        const data = {
            daily_qc: this.state.daily_qc.id,
            student_question_response: this.state.student_response.id,
            response_component_analysis: this.state.current_component.id,
            original_has_component: this.state.current_component.has_component,
            qc_has_component: has_component,
            user: window.cmState.user.id,
            qc_user: this.props.qc_user,
            resolved,
            final_has_component: has_component,
        };
        ajaxWrapper(
            'POST',
            '/api/home/dailyqcresponse/',
            data,
            this.daily_qc_response_callback
        );

        const new_score = this.state.scored + 1;
        let { right_answers } = this.state;
        let { analyst_right } = this.state;

        if (resolved) {
            right_answers += 1;
            analyst_right += 1;
            this.setState({
                correct_answer: 'Yes',
                right_answers,
                num_resolved: this.state.num_resolved + 1,
                analyst_right,
                scored: new_score,
            });
        } else {
            this.setState({
                correct_answer: 'No',
                guess: !this.state.current_component.has_component,
                scored: new_score,
            });
        }
    }

    daily_qc_response_callback(result) {
        this.setState({ qc_response: result[0].dailyqcresponse });
    }

    assign_to_my_way(form_submit) {
        console.log('Form Submit', form_submit);
        const { qc_response } = this.state;

        ajaxWrapper(
            'POST',
            '/api/home/qcchange/',
            {
                student_question_response: this.state.student_response.id,
                who_scored: this.props.qc_user,
                who_qced: window.cmState.user.id,
                original_has_component:
                    this.state.current_component.has_component,
                changed_has_component:
                    !this.state.current_component.has_component,
                approach_component: this.props.component_id,
            },
            console.log
        );

        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.state.current_component.id}/`,
            {
                has_component: !this.state.current_component.has_component,
                who_scored: window.cmState.user.id,
            },
            console.log
        );

        ajaxWrapper(
            'POST',
            '/api/home/dailyqcdiscussion/',
            {
                daily_qc_response: qc_response.id,
                text: form_submit.text,
                user: window.cmState.user.id,
            },
            console.log
        );

        this.next();
    }

    my_mistake() {
        const { qc_response } = this.state;
        this.setState({
            analyst_right: this.state.analyst_right + 1,
            num_resolved: this.state.num_resolved + 1,
        });

        ajaxWrapper(
            'POST',
            `/api/home/dailyqcresponse/${qc_response.id}/`,
            {
                resolved: true,
                final_has_component: this.state.current_component.has_component,
            },
            console.log
        );

        this.next();
    }

    last_qc_callback(result) {
        const query_params = {
            component: this.props.component_id,
            response__active: true,
            who_scored: this.props.qc_user,
            related: 'response',
            limit: 20,
            review: false,
        };

        if (result.length > 0) {
            this.setState({ last_qc: result[0].dailyqc });
            query_params.created_at__gte = result[0].dailyqc.created_at;
        }

        ajaxWrapper(
            'GET',
            '/api/home/responsecomponentanalysis/',
            query_params,
            this.get_responses
        );
    }

    get_responses(result) {
        const { length } = result;

        if (length < 20) {
            this.setState({
                loaded: true,
                error: 'User Does Not Have Enough Analyses for A QC. Try Again Later.',
            });
        } else {
            const component = result[0].responsecomponentanalysis;
            this.setState({
                loaded: true,
                student_response: component.response,
                current_component: component,
                index: 0,
                components: result,
                loaded: true,
            });
        }
    }

    render() {
        let content = null;

        // <Accordion names={[questionName]} open={[false]} ComponentList={[Paragraph]} ComponentProps={[paragraphProps]} />

        if (this.state.loaded) {
            if (this.state.completed) {
                const percent_correct =
                    (this.state.right_answers / this.state.num_questions) * 100;

                content = (
                    <div className="container">
                        <h2>
                            You matched on {percent_correct.toFixed(0)}% of
                            responses.
                        </h2>
                        <a
                            href={`/daily_qc_responses/${this.state.daily_qc.id}/`}
                        >
                            Review Matches/Mismatches
                        </a>
                    </div>
                );
            } else if (this.state.error) {
                content = (
                    <div className="container">
                        <p>{this.state.error}</p>
                    </div>
                );
            } else {
                let next = (
                    <div>
                        <Button
                            type="primary"
                            text="Yes"
                            onClick={() => this.submit_component(true)}
                        />
                        <Button
                            type="secondary"
                            text="No"
                            onClick={() => this.submit_component(false)}
                        />
                    </div>
                );

                const stud_response = this.state.student_response.answer
                    .split('\\n')
                    .join('<br/>');

                let right_wrong = null;
                if (this.state.correct_answer == 'Yes') {
                    right_wrong = <Alert type="success" text="You matched!" />;
                    next = (
                        <Button
                            type="success"
                            onClick={this.next}
                            text="Next"
                        />
                    );
                } else if (this.state.correct_answer == 'No') {
                    right_wrong = (
                        <div>
                            <Alert type="danger" text="You did not match." />
                            <p>You answered:</p>
                            <p>{this.state.guess.toString()}</p>
                            <p>They answered:</p>
                            <p>
                                {this.state.current_component.has_component.toString()}
                            </p>
                        </div>
                    );

                    next = null;
                    if (this.state.qc_response) {
                        next = (
                            <div>
                                <Button
                                    type="success"
                                    onClick={this.my_mistake}
                                    text="I Was Wrong, I Agree With Their Way Of Explanation"
                                />
                                <p>
                                    OR, if you think you are right, please
                                    explain your thinking:
                                </p>
                                <FormWithChildren
                                    submit={this.assign_to_my_way}
                                    submit_text="Add Feedback And Assign To My Way Of Explanation"
                                    submit_on_enter={false}
                                >
                                    <TextArea label="Feedback" name="text" />
                                </FormWithChildren>
                            </div>
                        );
                    }
                }

                content = (
                    <div className="container">
                        <h2>
                            Daily QC: {this.state.qc_user_name} (
                            {this.state.index + 1}/{this.state.num_questions})
                        </h2>
                        <h6>
                            Question:{' '}
                            <QuestionDisplay
                                question={this.state.question}
                                display="text"
                                image
                            />
                        </h6>
                        <h4>
                            Does this explanation contain{' '}
                            {`${this.state.component.name}: ${this.state.component.text}`}
                            ?
                        </h4>
                        <p>Student Answer</p>

                        <div
                            dangerouslySetInnerHTML={{ __html: stud_response }}
                        />
                        <div style={{ height: '50px' }} />
                        {right_wrong}
                        {next}
                    </div>
                );
            }
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default DailyQC;
