import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Wrapper,
} from 'library';

class ScoreResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: false,
            wrong: false,
        };

        this.right_answer = this.right_answer.bind(this);
        this.wrong_answer = this.wrong_answer.bind(this);
    }

    right_answer() {
        ajaxWrapper(
            'POST',
            `/api/home/studentquestionresponse/${this.props.response.id}/`,
            {
                score: 1,
                has_been_reviewed: true,
                has_been_scored: true,
                who_scored: window.cmState.user.id,
            },
            console.log
        );
        this.setState({ right: true, wrong: false });
    }

    wrong_answer() {
        ajaxWrapper(
            'POST',
            `/api/home/studentquestionresponse/${this.props.response.id}/`,
            {
                score: 0,
                has_been_reviewed: true,
                has_been_scored: true,
                who_scored: window.cmState.user.id,
            },
            console.log
        );
        this.setState({ right: false, wrong: true });
    }

    render() {
        var r_background = { backgroundColor: 'white' };
        var w_background = { backgroundColor: 'white' };

        if (this.state.right) {
            var r_background = { backgroundColor: 'green' };
        }
        if (this.state.wrong) {
            var w_background = { backgroundColor: 'red' };
        }

        return (
            <tr className="hover-row">
                <td style={{ maxWidth: '400px' }}>
                    {this.props.response.answer}
                </td>
                <td style={r_background}>
                    <Button
                        onClick={this.right_answer}
                        type="success"
                        text="Correct"
                    />
                </td>
                <td style={w_background}>
                    <Button
                        onClick={this.wrong_answer}
                        type="danger"
                        text="Incorrect"
                    />
                </td>
            </tr>
        );
    }
}

class Questions extends Component {
    constructor(props) {
        super(props);

        const anonymous = true;

        this.state = {
            loaded: false,
            questions: {},
            modal: null,
            anonymous: true,
            anon_modal: false,
            item: {},
            review_responses: {},
            show_image: true,
        };

        this.get_question = this.get_question.bind(this);
        this.select_item = this.select_item.bind(this);
        this.get_responses = this.get_responses.bind(this);
    }

    select_item(item) {
        this.setState({ item });
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            this.get_question
        );
        ajaxWrapper(
            'GET',
            `/api/home/studentquestionresponse/?review=True&has_been_reviewed=False&question=${this.props.question_id}`,
            {},
            this.get_responses
        );
    }

    get_question(result) {
        const { question } = result[0];

        this.setState({ item: question });
    }

    get_responses(result) {
        console.log('Result!', result);
        const questions = {};
        for (const index in result) {
            const response = result[index].studentquestionresponse;
            if (!questions[response.question_id]) {
                questions[response.question_id] = [];
            }
            questions[response.question_id].push(response);
        }

        this.setState({ review_responses: questions, loaded: true });
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.

        var content = null;
        const modal_display = this.state.modal;

        if (this.state.loaded) {
            if (this.state.item) {
                var show_image = null;
                if (this.state.item.images_by_language.English) {
                    var show_image = (
                        <Button
                            type="success"
                            text="Show Image"
                            onClick={() => this.setState({ show_image: true })}
                        />
                    );
                    if (this.state.show_image) {
                        var show_image = (
                            <div>
                                <img
                                    src={
                                        this.state.item.images_by_language.English
                                    }
                                />
                                <Button
                                    type="danger"
                                    text="Hide Image"
                                    onClick={() =>
                                        this.setState({ show_image: false })
                                    }
                                />
                            </div>
                        );
                    }
                }

                const responses = [];
                for (const index in this.state.review_responses[
                    this.state.item.id
                ]) {
                    responses.push(
                        <ScoreResponse
                            response={
                                this.state.review_responses[this.state.item.id][
                                index
                                ]
                            }
                        />
                    );
                }

                var content = (
                    <div className="container">
                        <div
                            style={{
                                position: 'fixed',
                                width: '100%',
                                backgroundColor: 'white',
                                marginTop: '-50px',
                            }}
                        >
                            <h3 style={{ maxWidth: '800px' }}>
                                {this.state.item.name}
                            </h3>
                            {show_image}

                            <p>
                                Correct Answer: {this.state.item.correct_answer}
                            </p>
                        </div>

                        <div style={{ height: '100px' }} />
                        <table className="table">
                            <tr>
                                <th>Response</th>
                                <th>Right Answer</th>
                                <th>Wrong Answer</th>
                            </tr>
                            {responses}
                        </table>

                        <h2>You've Completed This Item!</h2>
                    </div>
                );
            }
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default Questions;
