import React, { Component } from 'react';
import Sankey from 'pages/sankey/sankey.js';
import make_data from 'pages/sankey/test_data.js';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            width: 0,
            height: 0,
        };

        this.myRef = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onClick = this.onClick.bind(this);
        this.update_data = this.update_data.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.update_data(data);
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data != prevProps.data) {
            this.update_data(data);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }



    onClick(event, source, target) {
        const { data } = this.state;
        const source_name = data.nodes[source].name;
        const target_name = data.nodes[target].name;
        const { onClick } = this.props;
        if (onClick) {
            onClick(event, source_name, target_name);
        }
    }

    updateWindowDimensions() {
        const { offsetWidth, offsetHeight } = this.myRef.current;
        this.setState({
            width: offsetWidth,
            height: offsetHeight,
        });
    }

    update_data(data) {
        const new_data = make_data(data);
        this.setState({ data: new_data });
    }

    render() {
        const { width: widthState, height: heightState, data, editMode } = this.state;
        const { width: widthProp, height: heightProp } = this.props;
        const width = widthProp || widthState;
        const height = heightProp || heightState;

        return (
            <div ref={this.myRef}>
                <Sankey
                    data={data}
                    edit={editMode}
                    size={{ width, height }}
                    onClick={this.onClick}
                />
            </div>
        );
    }
}

export default Main;
