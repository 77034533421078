import React, { Component } from 'react';
import { formatDateString } from 'functions';
import Datetime from 'react-datetime';
import { TextInput, Select, Button } from 'library';

class DateTimePicker extends Component {
    static component_name = 'DateTimePicker';

    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <TextInput label="Name" name="name" />,
                <TextInput label="Label" name="label" />,
                <Select label="Display Time" name="display_time" boolean />,
                <Select label="Display Date" name="display_date" boolean />,
                <Select
                    label="Multiple"
                    name="multiple"
                    boolean
                    defaultoption={false}
                />,
                <TextInput label="Format" name="custom_format" />,
            ],
            can_have_children: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.check_value_prop = this.check_value_prop.bind(this);
        this.remove_selection = this.remove_selection.bind(this);
    }

    componentDidMount() {
        this.check_value_prop();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (this.props.value != prevProps.value) {
            this.check_value_prop();
        }
    }

    handleChange(e) {
        let current_value;

        if (typeof e === 'string') {
            return false;
        }

        let new_value = '';

        if (this.props.custom_format) {
            new_value = e.format(this.props.custom_format);
        } else if (this.props.display_time == false) {
            new_value = e.format('YYYY-MM-DD');
        } else if (this.props.display_date == false) {
            new_value = e.format('hh:mm A');
        } else {
            new_value = e.format('MM/DD/YYYY hh:mm A');
        }

        if (this.props.multiple == true) {
            current_value = this.props.value;
            const index = current_value.indexOf(new_value);
            if (index == -1) {
                current_value.push(new_value);
            } else {
                current_value.splice(index, 1);
            }
        } else {
            current_value = new_value;
        }

        const newState = {};
        newState[this.props.name] = current_value;
        this.props.setFormState(newState);
    }

    check_value_prop() {
        let { value } = this.props;
        const has_variable_inside =
            String(value).indexOf('{') > -1 && String(value).indexOf('}') > -1;
        const has_list_inside =
            String(value).indexOf('[') > -1 && String(value).indexOf(']') > -1;

        if (this.props.multiple) {
            if (
                typeof value === 'string' &&
                (has_variable_inside || has_list_inside)
            ) {
                const newState = {};
                value = JSON.parse(value);
                newState[this.props.name] = value;
                this.props.setFormState(newState);
            } else if (!value) {
                const newState = {};
                newState[this.props.name] = [];
                this.props.setFormState(newState);
            }
        }
    }

    remove_selection(e) {
        const selection = e.currentTarget.getAttribute('num');
        const { value } = this.props;
        const index = value.indexOf(selection);
        value.splice(index, 1);

        const newState = {};
        newState[this.props.name] = value;
        this.props.setFormState(newState);
    }

    render() {
        const { value } = this.props;
        let default_value = formatDateString(this.props.value, 'mm-dd-yyyy');

        let layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }
        // <input type="text" className="form-control" name={this.props.name} onChange={this.props.handleChange} value={this.props.value} placeholder={this.props.placeholder} />

        let display_time = true;
        if (this.props.display_time == false) {
            display_time = false;
        }

        let display_date = 'DD/MM/YYYY hh:mm';
        if (this.props.display_date == false) {
            display_date = false;
        }

        const multiple_selections = [];
        if (this.props.multiple == true) {
            default_value = '';

            Object.keys(value).forEach(key => {
                multiple_selections.push(
                    <Button
                        key={`${this.props.name}-${key}`}
                        onClick={this.remove_selection}
                        num={value[key]}
                        type="outline-danger"
                        text={value[key]}
                    />
                );
            });

            if (multiple_selections.length > 0) {
                multiple_selections.push(<br />);
                multiple_selections.push(<br />);
            }
        }

        return (
            <div className={`form-group ${this.props.className}`}>
                <label htmlFor="datetime">{this.props.label}</label>
                <div>{multiple_selections}</div>
                <Datetime
                    key={default_value}
                    timeFormat={display_time}
                    dateFormat={display_date}
                    onChange={this.handleChange}
                    defaultValue={default_value}
                    id="datetime"
                />
            </div>
        );
    }
}

export default DateTimePicker;
