import React, { Component } from 'react';

export default class FeatureDisplay extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        var feature = this.props.feature;

        var answer = feature.student_question_response.answer;

        var prev_start = 0
        var span_list = []
        for (var span of feature.spans) {
            span_list.push(<span>{answer.substring(prev_start, span.start_string_index)}</span>)
            span_list.push(<span style={{backgroundColor:'lightgreen'}}>{answer.substring(span.start_string_index, span.end_string_index)}</span>)
            prev_start = span.end_string_index;
        }

        if (prev_start < answer.length - 1) {
            span_list.push(<span>{answer.substring(prev_start, answer.length)}</span>)
        }

        return (
            span_list
        )
    }

}
