import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Icon, Image, Wrapper, Select } from 'library';

import ListControls from 'pages/reports/results_explorer/components/list_controls.js';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';
import WayToGetAnswerDropDown from 'pages/reports/results_explorer/components/way_to_get_answer.js';

const OFFSET_CHANGE = 20;
const MCL_VALUES = ['NA', 'NE', 'm', 'M', 'c', 'C', 'l', 'L'];

class Answer extends Component {
    render() {
        const { question_id } = this.props;
        const { response } = this.props;

        let way_to_get_answer = null;
        if (response.way_to_get_answer) {
            way_to_get_answer = (
                <WayToGetAnswerDropDown
                    key={`${question_id}_waytoget`}
                    text={response.way_to_get_answer}
                />
            );
        }

        let icon = <Icon icon="times" style={{ color: '#FF6262' }} />;
        if (response.is_correct) {
            icon = <Icon icon="check" style={{ color: '#4DD293' }} />;
        }

        let content = (
            <tr>
                <td style={{ width: '20%' }}>{response.answer}</td>
                <td style={{ width: '20%' }}>{response.student.student_id}</td>
                <td style={{ width: '20%', textAlign: 'left' }}>
                    <a
                        onClick={() =>
                            this.props.set_student(response.student.id)
                        }
                    >
                        <Image
                            className="icon purple"
                            src="/static/images/reporting/Person.svg"
                            style={{ width: '15px', marginRight: '8px' }}
                        />
                        <span
                            className="purple-text"
                            style={{ verticalAlign: 'top', lineHeight: '15px' }}
                        >
                            {response.student.last_name},{' '}
                            {response.student.first_name}
                        </span>
                    </a>
                </td>
                <td style={{ width: '20%', textAlign: 'left' }}>
                    {way_to_get_answer}
                </td>
                <td style={{ width: '20%' }}>{icon}</td>
            </tr>
        );

        if (this.props.communication_question) {
            content = (
                <tr>
                    <td style={{ width: '10%' }}>
                        {response.student.student_id}
                    </td>
                    <td style={{ width: '15%', textAlign: 'left' }}>
                        <a
                            onClick={() =>
                                this.props.set_student(response.student.id)
                            }
                        >
                            <Image
                                className="icon purple"
                                src="/static/images/reporting/Person.svg"
                                style={{ width: '15px', marginRight: '8px' }}
                            />
                            <span
                                className="purple-text"
                                style={{
                                    verticalAlign: 'top',
                                    lineHeight: '15px',
                                }}
                            >
                                {response.student.last_name},{' '}
                                {response.student.first_name}
                            </span>
                        </a>
                    </td>

                    <td style={{ width: '10%', textAlign: 'left' }}>
                        {response.non_explanation_answer}
                    </td>
                    <td style={{ width: '20%', textAlign: 'left' }}>
                        {response.way_of_thinking}
                    </td>

                    <td style={{ width: '40%', textAlign: 'left' }}>
                        {response.answer}
                    </td>
                    <td style={{ width: '5%', textAlign: 'left' }}>
                        {response.mcl_score}
                    </td>
                </tr>
            );
        }

        return content;
    }
}

export default class ByStudentAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            loading: false,

            student_responses: [],
            student_response_count: 0,
            offset: 0,
            mcl: [],
        };

        this.change_offset = this.change_offset.bind(this);
        this.get_student_responses = this.get_student_responses.bind(this);
        this.get_student_responses_callback =
            this.get_student_responses_callback.bind(this);
        this.csv_download = this.csv_download.bind(this);
        this.clear_way_of_thinking = this.clear_way_of_thinking.bind(this);

        this.set_student = this.set_student.bind(this);
    }

    componentDidMount() {
        this.get_student_responses();

        window.cmState.setGlobalState(
            'download_reporting_data',
            this.csv_download
        );
    }

    change_offset(absolute, change) {
        if (absolute > -1) {
            this.setState(
                {
                    offset: absolute,
                    loading: true,
                },
                this.get_student_responses
            );
        } else if (change) {
            this.setState(
                {
                    offset: this.state.offset + change,
                    loading: true,
                },
                this.get_student_responses
            );
        }
    }

    get_student_responses() {
        const data = {
            filters: this.props.filters.direct,
            compare_filters: {},

            offset: this.state.offset,
            limit: OFFSET_CHANGE,

            question_id: this.props.question.id,

            way_of_thinking: this.props.way_of_thinking,
            mcl: this.state.mcl,
        };

        this.setState({
            loading: true,
        });

        ajaxWrapper(
            'POST',
            `/reporting/results_explorer/student_responses/${this.props.district_id}/`,
            data,
            this.get_student_responses_callback
        );
    }

    get_student_responses_callback(result) {
        this.setState({
            student_responses: result.student_responses,
            student_response_count: result.student_response_count,
            loaded: true,
            loading: false,
        });
    }

    csv_download() {
        const data = {
            filters: this.props.filters.direct,
            compare_filters: {},
            question_id: this.props.question.id,

            csv: true,
        };

        const url = `/reporting/results_explorer/student_responses_csv/${this.props.district_id}/`;

        this.setState({
            download: true,
            download_url: url,
            download_query: data,
        });
    }

    clear_way_of_thinking() {
        const data = {
            way_of_thinking: '',
            way_of_thinking_name: '',
        };

        this.props.update_filters('question_details', data, 'Questions');
    }

    set_student(id) {
        this.props.update_filters(
            'student_list',
            {
                student_id: id,
            },
            'Student Profiles'
        );
    }

    refreshReponses = mcl => {
        const data = {
            filters: this.props.filters.direct,
            compare_filters: {},

            offset: this.state.offset,
            limit: OFFSET_CHANGE,

            question_id: this.props.question.id,

            way_of_thinking: this.props.way_of_thinking,
            mcl,
        };

        this.setState({
            loading: true,
        });
        ajaxWrapper(
            'POST',
            `/reporting/results_explorer/student_responses/${this.props.district_id}/`,
            data,
            this.get_student_responses_callback
        );
    };

    render() {
        let mcl_options = [{ text: 'Filter by MCL', value: '' }];
        for (let item of MCL_VALUES) {
            mcl_options.push({ text: item, value: item });
        }

        let headers = (
            <tr>
                <th style={{ width: '20%' }}>Answer</th>
                <th style={{ width: '20%' }}>Student ID</th>
                <th style={{ width: '20%' }}>Student Name</th>
                <th style={{ width: '20%', textAlign: 'left' }}>
                    Ways to get answer
                </th>
                <th style={{ width: '20%' }}>Correct</th>
            </tr>
        );
        if (this.props.communication_question) {
            headers = (
                <tr>
                    <th style={{ width: '10%' }}>Student ID</th>
                    <th style={{ width: '15%' }}>Student Name</th>
                    <th style={{ width: '10%' }}>Answer</th>
                    <th style={{ width: '20%' }}>
                        Most Likely Way Of Thinking
                    </th>
                    <th style={{ width: '40%' }}>Explanation</th>
                    <th style={{ width: '5%' }}>Quality Of Explanation</th>
                </tr>
            );
        }

        const content = null;
        const question_id = this.props.question.id;

        const answers = [];
        for (const response of this.state.student_responses) {
            answers.push(
                <Answer
                    setGlobalState={this.props.setGlobalState}
                    set_student={this.set_student}
                    question_id={question_id}
                    response={response}
                    communication_question={this.props.communication_question}
                />
            );
        }

        const list_controls = (
            <ListControls
                student_count={this.state.student_response_count}
                change={OFFSET_CHANGE}
                offset={this.state.offset}
                change_offset={this.change_offset}
            />
        );

        let download_modal = null;
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={this.state.download_url}
                    query={this.state.download_query}
                    onHide={() => this.setState({ download: false })}
                />
            );
        }

        let show_all = null;
        if (this.props.way_of_thinking) {
            show_all = (
                <div
                    className="button sad"
                    onClick={this.clear_way_of_thinking}
                    style={{ marginLeft: '5px' }}
                >
                    Show All
                </div>
            );
        }

        let mcl_helper = null;
        if (this.props.communication_question) {
            mcl_helper = (
                <a
                    className="purple-button"
                    style={{
                        textDecoration: 'none',
                        margin: '10px',
                        width: 'auto',
                        display: 'inline-block',
                    }}
                    href="https://docs.google.com/presentation/d/1S-gP6AKWhFs8jxVMqhc1mrQ2oK-3Yv4pj6lJ_XMw7LI/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                >
                    What is the MCL Framework?
                </a>
            );
        }

        return (
            <div>
                <div className="result-display-card">
                    <div
                        style={{
                            float: 'right',
                        }}
                    >
                        {mcl_helper}
                        {this.props.communication_question && (
                            <Select
                                name="mcl"
                                options={mcl_options}
                                no_blank_option={true}
                                style={{ display: 'inline-block' }}
                                setFormState={value =>
                                    this.setState(
                                        value,
                                        this.get_student_responses
                                    )
                                }
                            />
                        )}
                    </div>

                    <div className="h2 bold header">
                        {this.props.overview}
                        {show_all}
                    </div>

                    <Wrapper
                        loaded={!this.state.loading && this.state.loaded}
                        style={{ marginTop: '0px' }}
                    >
                        <table className="table student-answer-table">
                            {headers}
                            {answers}
                        </table>

                        {download_modal}
                    </Wrapper>
                </div>

                {list_controls}
            </div>
        );
    }
}
