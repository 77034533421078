import React from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    Wrapper,
    FormWithChildren,
    TextInput,
    Select,
} from 'library';

export default class EditAsset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],

            questions_with_answers: [],
        };

        this.load_asset = this.load_asset.bind(this);
        this.load_questions_answers = this.load_questions_answers.bind(this);
        this.add_question = this.add_question.bind(this);
        this.load_question_answers = this.load_question_answers.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/actionable_assets/asset/${  this.props.id  }/`,
            {},
            this.load_asset
        );
        ajaxWrapper(
            'GET',
            `/api/home/question/?answers__has_asset=${ 
                this.props.id 
                }&related=answers,answers__has_asset`,
            {},
            this.load_questions_answers
        );
    }

    load_asset(value) {
        this.setState({
            loaded: true,
            data: value[0].asset,
        });
    }

    load_questions_answers(value) {
        this.setState({
            questions_with_answers: value,
        });
    }

    render() {
        let content = null;

        if (this.state.loaded) {
            const asset = this.state.data;

            const questions = [];
            for (const item of this.state.questions_with_answers) {
                const question = item[''];
                const answers = [];

                const answer_options = [];
                for (const jtem of question.answers) {
                    const answer = jtem.questionanswer;
                    answer_options.push({});
                }

                questions.push(
                    <div>
                        <div>{question.name}</div>
                        {answers}

                        <FormWithChildren submit={this.create_connection}>
                            <Select name="answer" options={answer_options} />
                            <TextInput name="value" label="Value (-1 to 1)" />
                        </FormWithChildren>
                    </div>
                );
            }

            content = (
                <div className="simple-card">
                    <h3>{asset.name}</h3>

                    <h4>Existing Questions</h4>
                    {questions}
                </div>
            );
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ margin: '0px' }}>
                <div className="sad" style={{ padding: '30px 0px 50px 0px' }}>
                    <Container min_height>{content}</Container>
                </div>
            </Wrapper>
        );
    }
}
