import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Wrapper, Alert, Container } from 'library';

import SideNavbar from 'pages/reports/results_explorer/components/side_navbar.js';
import Header from 'pages/reports/results_explorer/components/header.js';

import ResultsDisplay from 'pages/reports/results_explorer/pages/results_display.js';
import FilterBar from 'pages/reports/results_explorer/components/filter_bar.js';
import FilterSidebar from 'pages/reports/results_explorer/components/filter_sidebar.js';

import overview_text from 'pages/reports/results_explorer/functions/overview_text.js';

function get_filter_defaults() {
    return {
        area: '',
        cluster: '',
        school: '',
        teacher: '',
        period: [],
        section: '',
        school_term: '',

        race: [],
        ell_status: [],
        special_ed: '',
        hispanic_ethnicity: '',
        grade: [],
        assessments: [],
        courses: [],
        category: '',

        starting_assessment: '',
        ending_assessment: '',

        use_current_students: false,
    };
}

function has_active_filters(filters) {
    if (
        (filters.race && filters.race.length > 0) ||
        (filters.grade && filters.grade.length > 0) ||
        (filters.ell_status && filters.ell_status.length > 0) ||
        filters.special_ed ||
        filters.hispanic_ethnicity ||
        filters.area ||
        filters.clusters ||
        filters.school ||
        filters.teacher ||
        filters.section ||
        filters.school_term
    ) {
        return true;
    }
    return false;
}

function clean_filters(incoming_state, filters) {
    Object.keys(incoming_state).forEach(key => {
        if (key === 'area') {
            filters.school = '';
            filters.cluster = '';
            filters.teacher = '';
            filters.section = '';
            filters.period = [];
        } else if (key === 'cluster') {
            filters.school = '';
            filters.teacher = '';
            filters.section = '';
            filters.period = [];
        } else if (key === 'school') {
            filters.teacher = '';
            filters.section = '';
            filters.period = [];
        } else if (key === 'teacher') {
            filters.section = '';
            filters.period = [];

            const global_lookup = window.cmState.reporting_context_lookup;
            if (
                incoming_state[key] &&
                !global_lookup[incoming_state[key]].sections
            ) {
                let teacher_detail_url = `/api/sis/districtuser/${incoming_state[key]}/`;
                teacher_detail_url +=
                    '?related=sections,sections__term,sections_not_primary,sections_not_primary__term&related__sections__only=id,name,period&related__sections_not_primary__only=id,name,period';

                filters.section_loading = teacher_detail_url;
            }
        }
    });

    return filters;
}

export default class ResultsExplorer extends Component {
    constructor(props) {
        super(props);

        let landing_page = 'Dashboard';
        if (window.cmState.user.role === 'Teacher') {
            landing_page = 'Questions';
        }
        const { district_id } = props;
        this.state = {
            loaded: false,

            height: 0,
            width: 0,
            page: landing_page,

            district: null,
            areas: [],
            schools: [],

            assessments: [],
            courses: [],
            school_terms: [],

            error: '',

            side_navbar_open: false,
            filters_open: false,
            show_percentage: false,

            filters: {
                direct: get_filter_defaults(),
                compare: get_filter_defaults(),
                district: district_id,
                student_list: {
                    category: '',
                    proficiency: '',
                    student_id: '',
                    question_id: '',
                    question_answer: '',
                },
                question_details: {
                    id: '',
                    answer: '',
                    way_of_thinking: '',
                    way_of_thinking_name: '',
                },
                timestamp: 0,
            },
        };

        this.update_dimensions = this.update_dimensions.bind(this);
        this.refresh_data = this.refresh_data.bind(this);
        this.user_has_no_access = this.user_has_no_access.bind(this);
        this.load_context = this.load_context.bind(this);

        this.load_history_state = this.load_history_state.bind(this);

        this.set_page = this.set_page.bind(this);
        this.toggle_open = this.toggle_open.bind(this);
        this.update_filters = this.update_filters.bind(this);

        this.load_teacher_details = this.load_teacher_details.bind(this);
        this.toggle_comparison = this.toggle_comparison.bind(this);

        window.cmState.setGlobalState('reporting_context_lookup', {
            '': { id: '' },
        });
    }

    componentDidMount() {
        this.refresh_data();

        this.update_dimensions();
        window.addEventListener('resize', this.update_dimensions.bind(this));

        window.addEventListener('popstate', this.load_history_state);

        window.cmState.subscribe_by_name(this, 'filter_bar_height');
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update_dimensions.bind(this));
    }

    set_page(page) {
        window.cmState.download_reporting_data = null;

        const { filters } = this.state;
        filters.student_list = {
            category: '',
            proficiency: '',
            student_id: '',
            question_id: '',
            question_answer: '',
        };

        filters.question_details = {
            id: '',
            answer: '',
            way_of_thinking: '',
            way_of_thinking_name: '',
        };

        this.setState({
            page,
            filters,
        });
    }

    get_saved_filters(value) {
        if (!value) {
            return null;
        }
        this.setState(value[0].SavedReportFilters.json_details);
    }

    load_context(value) {
        const new_state = {
            loaded: true,
            filtering_choices:
                'filtering_choices' in value ? value.filtering_choices : {},
            district: value.district,
        };

        const global_lookup = {
            '': { id: '' },
        };

        const { locations } = value;
        const { location_restricted } = value;
        new_state.location_restricted = location_restricted;

        if (locations.length === 0) {
            return false;
        }

        const schools = [];
        if (locations[0].area) {
            new_state.areas = locations;
            locations.forEach(item => {
                item.area.clusters.forEach(jtem => {
                    jtem.cluster.schools.forEach(ktem => {
                        ktem.school.users.forEach(xtem => {
                            /*
                            if (xtem.districtuser.sections){
                                for (var ytem of xtem.districtuser.sections){
                                    global_lookup[ytem.section.id] = ytem.section;
                                }
                            }
                            */

                            xtem.districtuser.name = `${xtem.districtuser.last_name}, ${xtem.districtuser.first_name}`;
                            global_lookup[xtem.districtuser.id] = JSON.parse(
                                JSON.stringify(xtem.districtuser)
                            );
                            delete xtem.districtuser.sections;
                        });

                        global_lookup[ktem.school.id] = JSON.parse(
                            JSON.stringify(ktem.school)
                        );
                        schools.push({ school: global_lookup[ktem.school.id] });
                        delete ktem.school.users;
                    });

                    global_lookup[jtem.cluster.id] = JSON.parse(
                        JSON.stringify(jtem.cluster)
                    );
                    delete jtem.cluster.schools;
                });

                global_lookup[item.area.id] = JSON.parse(
                    JSON.stringify(item.area)
                );
                delete item.area.clusters;
            });
        } else if (locations[0].school) {
            new_state.schools = locations;
            locations.forEach(item => {
                item.school.users.forEach(jtem => {
                    /*
                    for (ktem of jtem.districtuser.sections){
                        global_lookup[ktem.section.id] = JSON.parse(JSON.stringify(ktem.section));
                    }
                    */

                    jtem.districtuser.name = `${jtem.districtuser.last_name}, ${jtem.districtuser.first_name}`;
                    global_lookup[jtem.districtuser.id] = JSON.parse(
                        JSON.stringify(jtem.districtuser)
                    );
                    delete jtem.districtuser.sections;
                });

                global_lookup[item.school.id] = JSON.parse(
                    JSON.stringify(item.school)
                );
                schools.push({ school: global_lookup[item.school.id] });
                delete item.school.users;
            });
        }

        new_state.assessments = value.assessments;
        value.assessments.forEach(item => {
            global_lookup[item.assessment.id] = item.assessment;
        });

        new_state.school_terms = value.school_terms;
        value.school_terms.forEach(item => {
            global_lookup[item.schoolterm.id] = item.schoolterm;
        });

        new_state.courses = value.courses;
        value.courses.forEach(item => {
            global_lookup[item.course.id] = item.course;
        });

        window.cmState.setGlobalState(
            'reporting_context_lookup',
            global_lookup
        );
        const { filters: filtersState } = this.state;
        new_state.filters = filtersState;
        // Set current term
        if ('current_term' in value && value.current_term[0].schoolterm.id) {
            if (!new_state.filters.direct.school_term) {
                new_state.filters.direct.school_term =
                    value.current_term[0].schoolterm.id;
            }
            if (!new_state.filters.compare.school_term) {
                new_state.filters.compare.school_term =
                    value.current_term[0].schoolterm.id;
            }
        }

        const { user } = window.cmState;
        if (location_restricted) {
            if (user.role === 'Teacher') {
                new_state.filters.direct.teacher = user.district_user.id;
            } else if (user.role === 'Principal' || user.role === 'Staff') {
                new_state.schools = schools;
                new_state.filters.direct.school = schools[0].school.id;
            }
        }

        if (user.role === 'Teacher') {
            new_state.filters.direct.use_current_students = true;

            if (!(user.district_user.id in global_lookup)) {
                this.setState({
                    loaded: true,
                    error: 'Your Account Is Not Properly Configured For Reporting.  Please Contact Your System Administrator.',
                });

                return false;
            }
        }

        this.setState(new_state);
    }

    user_has_no_access() {
        if (window.cmState.user.email.indexOf('sandi.net') > -1) {
            this.setState({
                loaded: true,
                error: 'Please Contact Your IT Support at helpdesk@sandi.net or call at (619) 209-4357, your district is currently not sharing what permissions you should have access for. Tell them you need to be listed on the Clever system.',
            });
        } else {
            this.setState({
                loaded: true,
                error: 'Please Contact Your IT Support, your district is currently not sharing what permissions you should have access for. Tell them you need to be listed on the Clever system.',
            });
        }
    }

    load_history_state(full) {
        if (window.history.state && 'direct' in window.history.state.filters) {
            const current_filters = this.state;
            const scoped_filters = window.history.state;

            if (!(full && full === true)) {
                scoped_filters.filters.direct = current_filters.filters.direct;
                scoped_filters.filters.compare =
                    current_filters.filters.compare;
            }

            this.setState(scoped_filters);
        }
    }

    refresh_data() {
        const { district_id, saved_filter_id } = this.props;
        if (
            !(window.cmState.user.role === 'Super Admin') &&
            district_id != window.cmState.user.permissioned_district_id
        ) {
            this.user_has_no_access();
            return false;
        }

        const url = `/reporting/results_explorer/context/${district_id}/`;
        ajaxWrapper('GET', url, {}, this.load_context);

        this.load_history_state(true);

        if (saved_filter_id) {
            ajaxWrapper(
                'GET',
                `/api/home/SavedReportFilters/${saved_filter_id}/`,
                {},
                this.get_saved_filters
            );
        }
    }

    toggle_comparison() {
        const { show_percentage } = this.state;
        this.setState({
            show_percentage: !show_percentage,
        });
    }

    update_dimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    toggle_open(open) {
        this.setState({ side_navbar_open: open });
    }

    update_filters(name, state, page) {
        let section_loading = null;
        let teacher_detail_url = null;

        const { filters: filtersState } = this.state;
        // Remake ALL objects to prevent reference issues!!
        state = JSON.parse(JSON.stringify(state));
        const filters = JSON.parse(JSON.stringify(filtersState));

        if (name === 'filter-direct') {
            filters.direct = Object.assign(filters.direct, state);
            filters.direct = clean_filters(state, filters.direct);

            if (filters.direct.section_loading) {
                section_loading = 'direct';
                teacher_detail_url = filters.direct.section_loading;
                delete filters.direct.section_loading;
            }
        } else if (name === 'filter-compare') {
            filters.compare = Object.assign(filters.compare, state);
            filters.compare = clean_filters(state, filters.compare);

            if (filters.compare.section_loading) {
                section_loading = 'compare';
                teacher_detail_url = filters.compare.section_loading;
                delete filters.compare.section_loading;
            }
        } else if (name === 'student_list') {
            const data = {
                category: '',
                proficiency: '',
                student_id: '',
                question_id: '',
                question_answer: null,
                way_of_thinking: '',
                way_of_thinking_name: '',
            };
            filters[name] = Object.assign(data, state);
        } else {
            filters[name] = Object.assign(filters[name], state);
        }

        const new_state = {
            filters,
        };
        if (section_loading) {
            new_state.section_loading = section_loading;
        }

        if (page) {
            window.cmState.download_reporting_data = null;
            new_state.page = page;
        }

        if (section_loading) {
            this.setState(new_state, () => {
                ajaxWrapper(
                    'GET',
                    teacher_detail_url,
                    {},
                    this.load_teacher_details
                );
            });
        } else {
            this.setState(new_state);
        }
    }

    load_teacher_details(value) {
        const teacher = value[0].districtuser;
        const global_lookup = window.cmState.reporting_context_lookup;
        const ids = [];

        const all_sections = teacher.sections.concat();

        all_sections.forEach(item => {
            global_lookup[item.section.id] = JSON.parse(
                JSON.stringify(item.section)
            );
            ids.push(item.section.id);
        });

        teacher.sections_not_primary.forEach(item => {
            if (ids.indexOf(item.section.id) > -1) {
                return;
            }

            global_lookup[item.section.id] = JSON.parse(
                JSON.stringify(item.section)
            );
            ids.push(item.section.id);
            all_sections.push(item);
        });

        global_lookup[teacher.id].sections = all_sections;
        const { section_loading } = this.state;
        // Trigger filter options to update
        if (section_loading === 'direct') {
            this.update_filters('filter-direct', {
                sections_loaded: Date.now(),
            });
        } else if (section_loading === 'compare') {
            this.update_filters('filter-compare', {
                sections_loaded: Date.now(),
            });
        }
    }

    render() {
        let header_text = '';
        const {
            loaded,
            filtering_choices,
            location_restricted,
            filters: filtersState,
            assessments,
            courses,
            school_terms,
            width: widthState,
            height: heightState,
            side_navbar_open,
        } = this.state;
        if (loaded) {
            header_text = overview_text(
                filtersState.direct,
                filtersState.compare,
                assessments,
                courses,
                school_terms
            );
        }

        const { district_id } = this.props;
        let content_width = widthState;
        const filter_width = 0.25;
        const header_height = 62;
        const min_filterbar_height = 55;
        const filterbar_height = window.cmState.filter_bar_height;

        const main_content_style = {
            transition: '.2s margin-left',
            marginLeft: '210px',
            height: `${heightState}px`,
        };
        if (!side_navbar_open) {
            main_content_style.marginLeft = '76px';
            content_width -= 76;
        } else {
            content_width -= 210;
        }
        const { areas, schools, class_periods, filters_open, show_percentage } =
            this.state;
        const full_context = {
            district_id,
            filtering_choices,

            filters: filtersState,
            is_filtered: has_active_filters(filtersState.direct),
            update_filters: this.update_filters,
            set_page: this.set_page,

            location_restricted,
            areas,
            schools,
            assessments,
            school_terms,
            courses,
            class_periods,

            side_navbar_open,
            filters_open,

            header_text,
            show_percentage,
            toggle_comparison: this.toggle_comparison,
        };

        const filtered_context = {
            district_id,
            filters: filtersState,
            is_filtered: has_active_filters(filtersState.direct),
            update_filters: this.update_filters,
            set_page: this.set_page,

            has_areas: areas.length,
            school_terms,

            side_navbar_open,
            filters_open,

            header_text,
            show_percentage,
            toggle_comparison: this.toggle_comparison,
        };

        const result_style = {
            height: `${heightState - header_height - filterbar_height}px`,
            transition: '.2s margin',
            overflowY: 'auto',
        };

        if (filters_open) {
            result_style.marginRight = widthState * filter_width;
        }

        const { error: errorState } = this.state;

        let content = null;
        if (errorState) {
            content = (
                <div>
                    <div
                        style={{
                            textAlign: 'center',
                            boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
                            padding: '10px',
                        }}
                    >
                        <img
                            style={{
                                verticalAlign: 'top',
                                width: 'auto',
                                height: '50px',
                            }}
                            alt="Math ANEX"
                            src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                        />
                        <strong
                            style={{
                                fontSize: '30px',
                                padding: '2px 15px',
                                display: 'inline-block',
                            }}
                        >
                            Math ANEX
                        </strong>
                    </div>

                    <br />
                    <Container>
                        <Alert type="danger">{errorState}</Alert>
                    </Container>
                </div>
            );
        } else {
            const { page } = this.state;
            content = (
                <div className="reporting-dashboard">
                    <SideNavbar
                        height={heightState}
                        page={page}
                        set_page={this.set_page}
                        toggle_open={this.toggle_open}
                        open={side_navbar_open}
                        district={this.state.district}
                    />

                    <div style={main_content_style} className="sad">
                        <Header
                            page={page}
                            text={header_text}
                            width={content_width}
                        />

                        <FilterBar
                            {...full_context}
                            style={{
                                paddingRight: `${widthState * filter_width}px`,
                                minHeight: min_filterbar_height,
                            }}
                        />

                        <div style={result_style}>
                            <ResultsDisplay {...filtered_context} page={page} />
                            <div style={{ clear: 'both', width: '100%' }} />
                        </div>

                        <FilterSidebar
                            {...full_context}
                            height={heightState}
                            width={widthState}
                            percent_width={filter_width}
                            header_height={header_height}
                            filterbar_height={min_filterbar_height}
                            open={filters_open}
                            toggle_open={() =>
                                this.setState({
                                    filters_open: !filters_open,
                                })
                            }
                        />
                    </div>
                </div>
            );
        }

        return (
            <Wrapper loaded={loaded} style={{ marginTop: '0px' }}>
                {content}
            </Wrapper>
        );
    }
}
