import React, { Component } from 'react';

import { getURL } from 'functions';

export default class ClasslinkLogin extends Component {
    constructor(props) {
        super(props);

        this.oauth_redirect = this.oauth_redirect.bind(this);
    }

    oauth_redirect() {
        var url = getURL().href;
        if (url.indexOf('/login/') == -1) {
            localStorage.setItem('classlink_redirect', url);
        } else {
            localStorage.removeItem('classlink_redirect');
        }

        var url =
            'https://launchpad.classlink.com/oauth2/v2/auth?response_type=code&scope=full';
        url += `&redirect_uri=${window.secretReactVars.classlink_redirect_url}`;
        url += `&client_id=${window.secretReactVars.classlink_client_id}`;
        if (this.props.district_classlink_id) {
            // url += '&district_id=' + this.props.district_classlink_id
        }

        window.location = url;
    }

    render() {
        return (
            <div
                test-data-id='classlink-login'
                onClick={this.oauth_redirect}
                style={{
                    cursor: 'pointer',
                    width: '200px',
                    margin: '10px auto 10px auto',
                    textAlign: 'center',
                }}
            >
                <img
                    style={{ maxHeight: '50px', width: '100%' }}
                    src="/static/images/classlink.png"
                />
            </div>
        );
    }
}
