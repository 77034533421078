import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Alert,
    PageBreak,
    FormWithChildren,
    Wrapper,
    Select,
    TextInput,
} from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

class ComponentAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            has_component: this.props.analysis.has_component,
            review: this.props.analysis.review,
            loaded: true,
            who_scored: this.props.analysis.who_scored,
        };

        this.toggle_component = this.toggle_component.bind(this);
        this.toggle_review = this.toggle_review.bind(this);
        this.component_callback = this.component_callback.bind(this);
    }

    toggle_component() {
        this.setState({ loaded: false });
        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.props.analysis.id}/`,
            {
                has_component: !this.state.has_component,
                who_scored: window.cmState.user.id,
                automated_scoring_needs_qc_review: false,
            },
            this.component_callback
        );
    }

    toggle_review() {
        this.setState({ loaded: false });
        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.props.analysis.id}/`,
            { review: !this.state.review },
            this.component_callback
        );
    }

    component_callback(result) {
        const analysis = result[0].responsecomponentanalysis;
        this.setState({
            loaded: true,
            has_component: analysis.has_component,
            review: analysis.review,
        });
    }

    render() {
        let has_component = 'No';
        if (this.state.has_component) {
            has_component = 'Yes';
        }

        let toggle = (
            <a
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={this.toggle_component}
            >
                {has_component}
            </a>
        );
        if (!this.state.loaded) {
            toggle = 'Loading...';
        }

        let under_review = 'No';
        if (this.state.review) {
            under_review = 'Yes';
        }

        let review_toggle = (
            <a
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={this.toggle_review}
            >
                {under_review}
            </a>
        );
        if (!this.state.loaded) {
            review_toggle = 'Loading...';
        }

        return (
            <tr>
                <td>
                    {this.state.who_scored.first_name}{' '}
                    {this.state.who_scored.last_name[0]}
                </td>
                <td>
                    {`${this.props.analysis.component.name}: ${this.props.analysis.component.text}`}
                </td>
                <td>{toggle}</td>
                <td>{review_toggle}</td>
            </tr>
        );
    }
}

class StudentResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            wot_options: [],

            question: {
                id: 0,
                project_id: 0,
                name: '',
                text_by_language: {},
                options: [],
                item: { rubric: '' },
            },
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.student_question_response_callback =
            this.student_question_response_callback.bind(this);
        this.wot_callback = this.wot_callback.bind(this);
        this.rescore_response = this.rescore_response.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data() {
        let url = `/api/home/studentquestionresponse/${this.props.student_question_response_id}/`;
        url +=
            '?related=question,components,components__component,components__who_scored,way_of_thinking';
        url +=
            '&related__components__component__active=true&related__components__order_by=component__order';

        ajaxWrapper('GET', url, {}, this.student_question_response_callback);
    }

    student_question_response_callback(result) {
        const student_response = result[0].studentquestionresponse;

        if (this.state.wot_options.length == 0) {
            ajaxWrapper(
                'GET',
                `/api/home/wayofthinking/?question=${student_response.question.id}`,
                {},
                this.wot_callback
            );
        }

        this.setState({
            student_response,
            question: student_response.question,
            loaded: true,
        });
    }

    wot_callback(result) {
        const wot_options = [];
        for (const item of result) {
            const wot = item.wayofthinking;
            wot_options.push({
                text: wot.name,
                value: wot.id,
            });
        }

        this.setState({
            wot_options,
        });
    }

    rescore_response() {
        this.setState({ loaded: false });
        ajaxWrapper(
            'POST',
            `/score_response_by_components/${this.props.student_question_response_id}/`,
            {},
            this.refresh_data
        );
    }

    render() {
        const components = [];

        let evidence_options = [
            { text: 'Left Blank', value: 'Left Blank' },
            { text: 'No Evidence', value: 'No Evidence' },
            { text: 'Emerging Evidence', value: 'Emerging Evidence' },
            { text: 'Partial Evidence', value: 'Partial Evidence' },
            { text: 'Sufficient Evidence', value: 'Sufficient Evidence' },
        ];

        if (this.state.loaded) {
            const item_answer_explanations = [];
            const default_explanations = [];

            let answer = this.state.student_response.non_explanation_answer;
            if (answer == '') {
                answer = 'BLANK';
            }
            answer = answer.split('%20').join(' ');

            for (const index in this.state.student_response.components) {
                const analysis =
                    this.state.student_response.components[index]
                        .responsecomponentanalysis;
                components.push(<ComponentAnalysis analysis={analysis} />);
            }

            let way_of_thinking = null;
            if (this.state.student_response.way_of_thinking) {
                way_of_thinking = (
                    <tr>
                        <td>Way Of Thinking:</td>
                        <th>
                            {this.state.student_response.way_of_thinking.name}
                        </th>
                        <td>
                            <Alert type="warning">
                                <FormWithChildren
                                    submitUrl={`/update_student_question_response_scoring/${this.state.student_response.id}/`}
                                    redirect={this.refresh_data}
                                >
                                    <TextInput
                                        name="mcl_score"
                                        label="Change MCL Score"
                                        default={
                                            this.state.student_response
                                                .mcl_score
                                        }
                                    />
                                    <Select
                                        name="explanation_evidence"
                                        label="Change Explanation Evidence"
                                        options={evidence_options}
                                        default={
                                            this.state.student_response
                                                .explanation_evidence
                                        }
                                    />
                                </FormWithChildren>
                            </Alert>
                        </td>
                    </tr>
                );
            }

            var content = (
                <div>
                    <div className="container">
                        <div>
                            <h3>
                                <QuestionDisplay
                                    question={this.state.question}
                                    display="text"
                                    image
                                />{' '}
                            </h3>
                            <Alert type="success">{answer}</Alert>
                            <br />
                        </div>

                        <div>
                            <b>Student Response:</b>
                            <Alert type="info">
                                {this.state.student_response.answer}
                            </Alert>
                            <br />
                        </div>

                        <div>
                            <h4>Choose Their Components</h4>
                            <PageBreak />
                            <table className="table">
                                <tr>
                                    <th>Who Scored</th>
                                    <th>Component</th>
                                    <th>Has It?</th>
                                    <th>Under Review?</th>
                                </tr>
                                {components}
                            </table>
                            <br />
                        </div>

                        <div>
                            <h4>Scoring</h4>
                            <PageBreak />
                            <table className="table">
                                {way_of_thinking}
                                <tr>
                                    <td>MCL Score:</td>
                                    <th>
                                        {this.state.student_response.mcl_score}
                                    </th>
                                    <td />
                                </tr>
                            </table>
                            <Button
                                text="Recalculate Score"
                                type="warning"
                                onClick={this.rescore_response}
                            />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default StudentResponse;
