import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import ajaxWrapperPromise from 'base/ajaxPromise.js';
import { debounce} from 'functions';
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
export default class ResponseList extends Component {
    constructor(props) {
        super(props);

        this.state = { response_list: [], response_filters: {}, response_count: 0};
        this.datasource = this.getDataSource();

        this.gridRef = React.createRef();
        
        this.gridOptions = {
            rowModelType: 'infinite',
            datasource: this.datasource,
            domLayout: 'normal',
            enableCellTextSelection: true
        };
        

        this.columns = [
            {
                headerName: 'ID',
                sortable: false,
                flex: 1,
                valueGetter: 'node.id',
                cellRenderer: (props) => {
                  if (props.value !== undefined) {
                    return props.value;
                  } else {
                    return (
                      <img style={{ height: '100%' }} src="/static/images/loading_transparent.gif" />
                    );
                  }
                },
            },
            { 
                field: 'answer',
                tooltipField: 'answer',
                flex: 8,
                headerName: "Student Response",
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains', 'notContains'],
                    maxNumConditions: 1,
                },
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                
            },
            { 
                field: 'components_string',
                tooltipField: 'components_string', 
                headerName: "Current Components",
                flex: 4,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains', 'notContains'],
                    maxNumConditions: 1,
                },
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            { 
                field: 'id',
                flex: 2, 
                headerName: "Details", 
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains', 'notContains'],
                    maxNumConditions: 1,
                },
                cellRenderer: (props) => {
                    return (
                        <a href={'/features_response/' + this.props.question_id + '/' + props.value + '/'} target='_blank'>Details</a>
                    );
                },
            }
        ];
    }

    componentDidUpdate(previous_props) {
        if (this.props.scope_filters != previous_props.scope_filters) {
            this.gridRef.current?.api.setGridOption('datasource', this.getDataSource());
        }
    }

    componentDidMount() {
        
    }

    getDataSource() {
        let datasource = {
            rowCount: undefined,
            getRows: (params) => {

              ajaxWrapper(
                'POST',
                `/analysis/feature_filtered_responses/${this.props.question_id}/`,
                {
                    "scope_filters": this.props.scope_filters,
                    "response_filters": this.state.feature_filters,
                    "filter_model": params.filterModel,
                    "sort_model": params.sortModel,
                    "start_row": params.startRow,
                    "end_row": params.endRow
                },
                result => {
                    let response_list = result.responses;
                    let rowsReturned = response_list.length;
                    let rowCount = undefined;
                    if (rowsReturned < (params.endRow - params.startRow)) {
                        rowCount = params.startRow + rowsReturned;
                    }
                    this.setState({response_count: result.count});
                    params.successCallback(response_list, rowCount);

                }
                );
            },
        };
        return datasource;
    }


    render() {
        var content = (
            <div>
                <h2>Browse Responses Matching Ruleset ({this.state.response_count})</h2>
                <div className="ag-theme-quartz" style={{ height: 500, width: '100%' }}>
                    <AgGridReact
                        columnDefs={this.columns}
                        gridOptions={this.gridOptions}
                        ref={this.gridRef}
                        cacheBlockSize={100}
                        cacheOverflowSize={1}
                        maxConcurrentDatasourceRequests={1}
                        infiniteInitialRowCount={1}
                        blockLoadDebounceMillis={500}
                    />
                </div>
            </div>
        )

        return content;
    }

}