import React from 'react';

import { Image, ClickableContent } from 'library';

import GlobalSearchbar from 'pages/reports/results_explorer/components/global_searchbar.js';
import Tooltip from 'pages/reports/results_explorer/components/tooltip.js';

import lookup_object from 'pages/reports/results_explorer/functions/lookup_object.js';

export default class FilterBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0,
        };

        this.remove_filter = this.remove_filter.bind(this);
        this.create_filter_tags = this.create_filter_tags.bind(this);

        this.filter_bar_ref = React.createRef();
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'download_reporting_data');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const new_height = this.filter_bar_ref.current.offsetHeight;
        if (this.state.height != new_height) {
            this.setState({ height: new_height }, () =>
                window.cmState.setGlobalState('filter_bar_height', new_height)
            );
        }
    }

    remove_filter(event, data) {
        console.log('Removing Filter:', JSON.stringify(data));

        let filters = this.props.filters.direct;
        let filter_name = 'filter-direct';
        if (data.compare) {
            filters = this.props.filters.compare;
            filter_name = 'filter-compare';
        }

        for (const key in data) {
            const new_data = {};
            if (key == 'compare') {
                continue;
            } else if (
                [
                    'area',
                    'cluster',
                    'school',
                    'teacher',
                    'section',
                    'school_term',
                    'period',
                ].indexOf(key) > -1
            ) {
                new_data[key] = '';
            } else if (['special_ed', 'hispanic_ethnicity'].indexOf(key) > -1) {
                new_data[key] = '';
            } else {
                const value = filters[key];
                if (!value) {
                    console.log('uh oh');
                }
                const index = value.indexOf(data[key]);
                value.splice(index, 1);

                new_data[key] = value;
            }

            this.props.update_filters(filter_name, new_data);
        }
    }

    create_filter_tags(filters, compare = false) {
        const filter_tags = [];

        let exclude_tag = [];
        const { user } = window.cmState;
        if (this.props.location_restricted) {
            if (user.role == 'Teacher') {
                exclude_tag = ['area', 'cluster', 'school', 'teacher'];
            } else if (user.role == 'Principal' || user.role == 'Staff') {
                exclude_tag = ['area', 'cluster', 'school'];
            }
        }

        for (const key in filters) {
            if (exclude_tag.indexOf(key) > -1) {
                continue;
            }

            const data = [];

            // This entire statement SHOULD use a whitelist structure rather than being so permissive
            if (
                [
                    'use_current_students',
                    'defaults',
                    'section_loading',
                    'sections_loaded',
                ].indexOf(key) != -1
            ) {
                continue;
            }
            if (
                [
                    'area',
                    'cluster',
                    'school',
                    'teacher',
                    'section',
                    'school_term',
                ].indexOf(key) > -1
            ) {
                if (filters[key] == '') {
                    continue;
                }

                data.push({
                    name: lookup_object(filters[key]).name,
                    key,
                    value: filters[key],
                });
            } else if (['courses', 'assessments'].indexOf(key) > -1) {
                if (filters[key] == '') {
                    continue;
                }

                for (var item of filters[key]) {
                    data.push({
                        name: lookup_object(item).name,
                        key,
                        value: item,
                    });
                }
            } else if (['special_ed'].indexOf(key) > -1) {
                if (filters[key] == '') {
                    continue;
                }

                if (filters[key] == 'true') {
                    data.push({
                        name: 'Special Ed',
                        key,
                        value: true,
                    });
                } else {
                    data.push({
                        name: 'No Special Ed',
                        key,
                        value: false,
                    });
                }
            } else if (['hispanic_ethnicity'].indexOf(key) > -1) {
                if (filters[key] == '') {
                    continue;
                }

                if (filters[key] == 'Y') {
                    data.push({
                        name: 'Hispanic Ethnicity',
                        key,
                        value: 'Y',
                    });
                } else {
                    data.push({
                        name: 'No Hispanic Ethnicity',
                        key,
                        value: 'N',
                    });
                }
            } else if (['period'].indexOf(key) > -1) {
                if (filters[key] == '') {
                    continue;
                }

                data.push({
                    name: filters[key],
                    key,
                    value: filters[key],
                });
            } else if (filters[key].length == 0) {
                continue;
            } else {
                try {
                    for (var item of filters[key]) {
                        data.push({
                            name: item,
                            key,
                            value: item,
                        });
                    }
                } catch (e) {}
            }

            for (var item of data) {
                const final_data = {};
                final_data[item.key] = item.value;
                final_data.compare = compare;

                let tag_class = 'selection';
                if (compare) {
                    tag_class += ' comparison';
                }

                filter_tags.push(
                    <ClickableContent
                        key={`filter-tag-${item.key}-${item.value}`}
                        className={tag_class}
                        onClick={this.remove_filter}
                        data={final_data}
                        show_close
                    >
                        <div style={{ display: 'inline-block' }}>
                            {item.name}
                        </div>
                    </ClickableContent>
                );
            }
        }

        return filter_tags;
    }

    render() {
        const filters = this.props.filters.direct;
        const compare_filters = this.props.filters.compare;

        const filter_tags = this.create_filter_tags(filters);
        const compare_filter_tags = this.create_filter_tags(
            compare_filters,
            true
        );

        const buttons = [];
        // <div className='button' style={{padding: '8px', lineHeight: '16px'}} ><Image src={'/static/images/reporting/Share.svg'} className={'icon purple'} css={{width: '16px'}} /></div>
        // <div className='button' style={{lineHeight: '20px'}} ><Image src={'/static/images/reporting/Notes.svg'} className={'icon purple'} css={{width: '20px'}} /></div>
        if (window.cmState.download_reporting_data) {
            // Use global download event to traverse from navigation to results display
            buttons.push(
                <Tooltip title="Download" width={90}>
                    <div
                        onClick={window.cmState.download_reporting_data}
                        className="button"
                        style={{ lineHeight: '20px', cursor: 'pointer' }}
                    >
                        <Image
                            src="/static/images/reporting/Download.svg"
                            className="icon purple"
                            css={{ width: '20px' }}
                        />
                    </div>
                </Tooltip>
            );
        }

        return (
            <div
                style={this.props.style}
                className="filterbar"
                ref={this.filter_bar_ref}
            >
                <div style={{ float: 'right', padding: '12px 12px 0px 0px' }}>
                    <div
                        style={{ display: 'inline-block', marginRight: '10px' }}
                    >
                        <GlobalSearchbar
                            update_filters={this.props.update_filters}
                            filters={this.props.filters}
                            district_id={this.props.district_id}
                        />
                    </div>

                    {buttons}
                </div>

                <div
                    className="h2"
                    style={{
                        padding: '8px',
                        margin: '0px',
                        display: 'inline-block',
                    }}
                >
                    Applied Filters:
                </div>
                <div
                    className="multiselect"
                    style={{ display: 'inline-block', paddingLeft: '10px' }}
                >
                    {filter_tags}
                    {compare_filter_tags}
                </div>
            </div>
        );
    }
}
