import React from 'react';

import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        const totalScope = window.cmState.getGlobalState();
        const errorScope = {
            user: totalScope.user,
            params: totalScope.params,
        };

        const globalContext = JSON.parse(JSON.stringify(errorScope));

        Sentry.withScope(scope => {
            Object.keys(info).forEach(key => {
                scope.setExtra(key, info[key]);
            });
            Object.keys(globalContext).forEach(key => {
                scope.setExtra(`global_context_${key}`, globalContext[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <div
                        style={{
                            textAlign: 'center',
                            boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
                            padding: '10px',
                        }}
                    >
                        <img
                            style={{
                                verticalAlign: 'top',
                                width: 'auto',
                                height: '50px',
                            }}
                            alt="Math ANEX"
                            src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                        />
                        <strong
                            style={{
                                fontSize: '30px',
                                padding: '2px 15px',
                                display: 'inline-block',
                            }}
                        >
                            Math ANEX
                        </strong>
                    </div>

                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <h1>
                            Something went wrong. Our team has been notified.
                        </h1>
                    </div>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
