import React from 'react';
import { TextInput, CSSInput, Select } from 'library';

class Video extends React.Component {
    static component_name = 'Video';

    constructor(props) {
        super(props);

        this.state = {
            width: 640,
            playing: false,
        };

        this.config = {
            form_components: [
                <TextInput label="Video URL" name="video_url" />,
                <CSSInput label="css" name="style" default={{}} />,
                <CSSInput
                    label="play button css"
                    name="play_style"
                    default={{}}
                />,
                <Select label="autoplay" name="autoplay" boolean={false} />,
            ],
            can_have_children: true,
        };

        this.playVideo = this.playVideo.bind(this);
        this.pauseVideo = this.pauseVideo.bind(this);

        this.updateDimensions = this.updateDimensions.bind(this);
        this.container = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            width: this.container.current.getBoundingClientRect().width,
        });
    }

    playVideo() {
        // You can use the play method as normal on your video ref
        this.refs.vidRef.play();
        this.setState({ playing: true });
    }

    pauseVideo() {
        // Pause as well
        if (this.state.playing) {
            this.refs.vidRef.pause();
            this.setState({ playing: false });
        }
    }

    // You can pass your function references to your child components as props (here passing down to the Buttons component)
    render() {
        let aspect_ratio = 9 / 16;
        if (this.props.aspect_ratio) {
            aspect_ratio = this.props.aspect_ratio;
        }
        const { width } = this.state;
        const height = width * aspect_ratio;

        const play_style = this.props.play_style || {
            position: 'absolute',
            top: '0px',
            left: '0px',
            padding: `${(height - 23) / 2}px ${(width - 17.5) / 2}px`,
            zIndex: 10,
            background: 'rgba(0,0,0,.2)',
            cursor: 'pointer',
        };

        let play = null;
        if (!this.state.playing) {
            play = (
                <div
                    id="playButton"
                    onClick={this.playVideo}
                    style={play_style}
                >
                    <i
                        className="fas fa-play"
                        style={{ color: 'white', fontSize: '20px' }}
                    />
                </div>
            );
        }

        let overlay = null;
        if (this.props.overlay) {
            overlay = this.props.children;
        }

        let video_type = 'mp4';
        if (this.props.video_url) {
            video_type = this.props.video_url.substring(
                this.props.video_url.length - 3,
                this.props.video_url.length
            );
        }

        const video_style = {
            display: 'block',
            width: '100%',
            height: `${height}px`,
        };
        let video = null;
        if (
            this.props.video_url &&
            this.props.video_url.indexOf('player.vimeo.com') > -1
        ) {
            video = (
                <iframe
                    src={this.props.video_url}
                    style={video_style}
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    title={this.props.title || 'Video'}
                />
            );
        } else if (
            this.props.video_url &&
            (this.props.video_url.indexOf('youtu.be') > -1 ||
                this.props.video_url.indexOf('youtube.com') > -1)
        ) {
            const url_list = this.props.video_url.split('/');
            const youtube_id = url_list[url_list.length - 1];
            const youtube_url = `https://www.youtube.com/embed/${youtube_id}`;

            video = (
                <iframe
                    style={video_style}
                    src={youtube_url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                    allowfullscreen
                    title={this.props.title || 'Video'}
                />
            );
        } else if (this.props.autoplay) {
            video = (
                <video
                    autoPlay
                    muted
                    ref="vidRef"
                    src={this.props.video_url}
                    type={`video/${video_type}`}
                    style={video_style}
                />
            );
        } else {
            let thumbnail = null;
            if (this.props.thumbnail) {
                thumbnail = this.props.thumbnail;
            }

            video = (
                <div onClick={this.pauseVideo} style={{ position: 'relative' }}>
                    {play}
                    <video
                        controls
                        ref="vidRef"
                        src={this.props.video_url}
                        poster={thumbnail}
                        type={`video/${video_type}`}
                        style={video_style}
                    />
                </div>
            );
        }

        return (
            <div style={this.props.style || {}} ref={this.container}>
                {overlay}
                {video}
            </div>
        );
    }
}

export default Video;
