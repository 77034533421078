import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDateString } from 'functions';

import { Wrapper } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            items: {},
            last_item_scored: null,
        };

        this.item_callback = this.item_callback.bind(this);
        this.last_item_scored = this.last_item_scored.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/responsecomponentanalysis/?limit=1&order_by=-updated_at&related=component,component__approach&who_scored=${window.cmState.user.id}`,
            {},
            this.last_item_scored
        );

        ajaxWrapper(
            'GET',
            '/api/home/question/?questions_on_assessments__assessment__completed=false&category=bcba4111-d2fc-4dc1-b19a-a1b843c6e8ee',
            {},
            this.item_callback
        );
    }

    last_item_scored(result) {
        if (result.length > 0) {
            this.setState({
                last_item_scored:
                    result[0].responsecomponentanalysis.component.approach,
            });
        }
    }

    item_callback(result) {
        const items = {};
        result.forEach(({ question: item }) => {
            items[item.id.toString()] = item;
        });

        this.setState({
            items,
            loaded: true,
        });
    }

    render() {
        const headers = (
            <tr>
                <th>Name</th>
            </tr>
        );

        let recently_scored = null;

        const table_rows = [];
        const { items, last_item_scored } = this.state;
        Object.values(items).forEach(item => {
            table_rows.push(
                <tr>
                    <td>
                        <a href={`/item_answers/${item.id}/`}>
                            <QuestionDisplay question={item} display="text" />
                        </a>
                    </td>
                    <td>{formatDateString(item.deadline, 'mmm dd')}</td>
                </tr>
            );

            if (!last_item_scored) {
                return;
            }

            if (
                last_item_scored.question_id &&
                item.id == last_item_scored.question_id
            ) {
                recently_scored = (
                    <div className="simple-card">
                        <h4>Last Item Analyzed</h4>
                        <table className="table">
                            {headers}
                            <tr>
                                <td>
                                    <a href={`/item_answers/${item.id}/`}>
                                        <QuestionDisplay
                                            question={item}
                                            display="text"
                                        />
                                    </a>
                                </td>
                                <td>
                                    {formatDateString(item.deadline, 'mmm dd')}
                                </td>
                            </tr>
                        </table>
                    </div>
                );
            }
        });

        const content = (
            <div
                className="sad"
                style={{ marginTop: '-50px', padding: '30px 0px 50px 0px' }}
            >
                <div className="container">
                    <div className="simple-card">
                        <a href="https://docs.google.com/document/d/1JKOYVLtm5gXIvatGN5byJ162z7nmR_cK2EiBw9XFIws/edit">
                            Analyst Resource Guide
                        </a>
                        <h2>Items</h2>
                    </div>

                    {recently_scored}

                    <div className="simple-card">
                        <h4>All Items</h4>
                        <table className="table">
                            {headers}
                            {table_rows}
                        </table>
                    </div>
                </div>
            </div>
        );
        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default Items;
