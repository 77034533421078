function sort_by_key(array, key) {
    return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        if (x < y) return -1;
        if (y > x) return 1;
        return 0;
    });
}

function sort_by_key_reverse(array, key) {
    return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        if (x < y) return 1;
        if (y > x) return -1;
        return 0;
    });
}

function sort_by_keys(array, keys, order) {
    return array.sort((a, b) => {
        let x = a;
        let y = b;

        keys.forEach(key => {
            if (x == null || typeof x === 'undefined' || !(key in x)) {
                x = null;
            } else {
                x = x[key];
            }

            if (y == null || typeof y === 'undefined' || !(key in y)) {
                y = null;
            } else {
                y = y[key];
            }
        });

        if (order) {
            if (x == null || typeof x === 'undefined') return 1;
            if (y == null || typeof y === 'undefined') return -1;

            if (x < y) return 1;
            if (y < x) return -1;
            return 0;
        }
        if (x == null || typeof x === 'undefined') return 1;
        if (y == null || typeof y === 'undefined') return -1;

        if (x < y) return -1;
        if (y < x) return 1;
        return 0;
    });
}

function sort_objects(array, keys, order = false) {
    if (typeof keys !== 'string') {
        return sort_by_keys(array, keys, order);
    }
    if (order) {
        return sort_by_key(array, keys);
    }
    return sort_by_key_reverse(array, keys);
}

export default sort_objects;
