import React from 'react';
import { Alert } from 'library';

import Sankey from 'pages/sankey/main.js';
import StudentList from 'pages/sankey/student_list.js';

class SankeyExplorer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show_student_list: false,
            source: '',
            target: '',
        };

        this.show_student_list = this.show_student_list.bind(this);
    }

    show_student_list(event, source, target) {
        this.setState({
            show_student_list: !this.state.show_student_list,
            source,
            target,
        });
    }

    render() {
        let sankey = null;

        if (!this.props.filter_context.filters.category) {
            sankey = <Alert type="warning" text="Please Choose A Category" />;
        } else if (this.props.count == 0) {
            sankey = (
                <Alert
                    type="info"
                    text="No students have taken both assessments."
                />
            );
        } else if (this.props.data && Object.keys(this.props.data).length > 0) {
            sankey = (
                <Sankey
                    height={500}
                    data={this.props.data}
                    onClick={this.show_student_list}
                />
            );
        }

        let student_list_modal = null;
        if (this.state.show_student_list) {
            student_list_modal = (
                <StudentList
                    onHide={() => this.setState({ show_student_list: false })}
                    filter_context={this.props.filter_context}
                    source={this.state.source}
                    target={this.state.target}
                />
            );
        }

        return (
            <div className="container">
                <h2>Sankey Explorer</h2>
                {sankey}
                {student_list_modal}
            </div>
        );
    }
}

export default SankeyExplorer;
