import React from 'react';
import { regexCheck } from 'functions';

export default function TextInput(props) {
    let layout = '';
    if (props.className) {
        layout = props.className;
    }

    let value = props.default;
    if (props.value) {
        value = props.value;
    }

    const style = props.style || {};

    if (props.is_regex && value && !regexCheck(value)) {
        style.boxShadow = '2px 2px 10px rgb(255 0 0 / 20%)';
        style.border = 'red thin solid';
    }

    const layout_style = {
        position: 'relative',
        ...props.layout_style,
    };

    let input = (
        <input
            type="text"
            className="form-control"
            name={props.name}
            onChange={props.handlechange}
            value={value}
            placeholder={props.placeholder}
            onKeyPress={props.handleKeyPress}
            style={style}
            autoComplete={props.autocomplete}
            onBlur={props.onBlur}
            disabled={props.disabled | false}
        />
    );
    if (props.autoFocus) {
        input = (
            <input
                type="text"
                className="form-control"
                name={props.name}
                onChange={props.handlechange}
                value={value}
                placeholder={props.placeholder}
                onKeyPress={props.handleKeyPress}
                style={style}
                autoComplete={props.autocomplete}
                onBlur={props.onBlur}
                disabled={props.disabled | false}
            />
        );
    }

    let label = null;
    if (props.label && props.label !== '') {
        label = <label>{props.label}</label>;
    }

    let icon = null;
    if (props.right_hand_icon) {
        icon = (
            <div style={{ position: 'absolute', top: '0px', right: '0px' }}>
                {props.right_hand_icon}
            </div>
        );
    }

    return (
        <div className={`form-group ${layout}`} style={layout_style}>
            {label}
            {input}
            {icon}
        </div>
    );
}
