import { Component } from 'react';
import { resolveVariables } from 'functions';

class If extends Component {
    static component_name = 'If';

    componentDidMount() {
        // Subscribe to all variables in dataUrl
        if (this.props.logic) {
            Object.keys(this.props.logic).forEach(i => {
                window.cmState.subscribe(this, this.props.logic[i][0]);
                window.cmState.subscribe(this, this.props.logic[i][1]);
            });
        }
        if (this.props.anti_logic) {
            Object.keys(this.props.anti_logic).forEach(i => {
                window.cmState.subscribe(this, this.props.anti_logic[i][0]);
                window.cmState.subscribe(this, this.props.anti_logic[i][1]);
            });
        }
    }

    render() {
        let logic = false;
        let failed = false;

        let logic_list;
        let anti_logic;
        if (this.props.logic) {
            logic_list = resolveVariables(
                this.props.logic,
                window.cmState.getGlobalState(this)
            );
        } else {
            logic_list = [];
        }
        if (this.props.anti_logic) {
            anti_logic = resolveVariables(
                this.props.anti_logic,
                window.cmState.getGlobalState(this)
            );
        } else {
            anti_logic = [];
        }

        Object.keys(logic_list).forEach(index => {
            const logic_check = logic_list[index][0];
            const logic_value = logic_list[index][1];
            if (logic_check == 'exists') {
                const is_empty_list =
                    Array.isArray(logic_value) && logic_value.length == 0;
                if (
                    logic_value &&
                    logic_value != '' &&
                    !(
                        logic_value.indexOf('{') > -1 &&
                        logic_value.indexOf('}') > -1
                    ) &&
                    !failed &&
                    !is_empty_list
                ) {
                    logic = true;
                } else {
                    logic = false;
                    failed = true;
                }
            } else if (logic_check == logic_value && !failed) {
                logic = true;
            } else {
                logic = false;
                failed = true;
            }
        });

        if (!failed) {
            Object.keys(anti_logic).forEach(index => {
                const logic_check = anti_logic[index][0];
                const logic_value = anti_logic[index][1];
                if (logic_check == 'exists') {
                    if (
                        !logic_value ||
                        logic_value == '' ||
                        (logic_value.indexOf('{') > -1 &&
                            logic_value.indexOf('}') > -1)
                    ) {
                        logic = true;
                    } else {
                        logic = false;
                    }
                } else if (logic_check == logic_value) {
                    logic = false;
                } else {
                    logic = true;
                }
            });
        }

        if (this.props.override) {
            logic = true;
        }

        if (logic) {
            if (this.props.children) {
                return this.props.children;
            }
            return null;
        }
        return null;
    }
}

export default If;
