import React, { Component } from 'react';

import { EmptyModal, Icon } from 'library';

export default class QuestionDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            original_display: this.props.display,
            display_image: false,
        };

        this.toggle_modal = this.toggle_modal.bind(this);
    }

    toggle_modal() {
        this.setState({ display_image: !this.state.display_image });
    }

    render() {
        let image = <div />;
        var description = <div />;
        var modal = null;

        if (this.props.question && this.props.question.id) {
            let question = this.props.question;

            if (this.state.original_display == 'image') {
                if (question.images_by_language.English) {
                    image = (
                        <img
                            className="card-img-top"
                            src={question.images_by_language.English}
                            alt={question.text_by_language.English}
                        />
                    );
                } else {
                    description = (
                        <div className="card-text">
                            {question.text_by_language.English}
                        </div>
                    );
                }
            } else {
                let icon = null;
                if (this.props.image && question.images_by_language.English) {
                    icon = (
                        <Icon
                            onClick={this.toggle_modal}
                            icon_type="fas"
                            icon="question-circle"
                            style={{ cursor: 'pointer' }}
                            size={1}
                        />
                    );
                }

                var description = (
                    <div className="card-text">
                        {question.text_by_language.English} {icon}
                    </div>
                );
                if (this.state.original_display == 'full_text') {
                    description = (
                        <div className="card-text">
                            <div style={{ float: 'right' }}>{icon}</div>
                            <div>
                                <b>{question.name}</b>
                            </div>
                            <div>{question.text_by_language.English}</div>
                        </div>
                    );
                }

                if (this.state.display_image) {
                    var description = (
                        <div className="card-text">
                            {question.text_by_language.English}
                        </div>
                    );
                    var modal = (
                        <EmptyModal
                            onHide={this.toggle_modal}
                            show={this.state.display_image}
                        >
                            <div style={{ padding: '20px' }}>
                                <img
                                    className="card-img-top"
                                    src={question.images_by_language.English}
                                    alt={question.text_by_language.English}
                                />
                            </div>
                        </EmptyModal>
                    );
                }
            }
        }

        return (
            <div>
                {description}
                {image}
                {modal}
            </div>
        );
    }
}
