import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Wrapper,
    Container,
    Button,
    Alert,
    EmptyModal,
    FormWithChildren,
    TextInput,
    Select,
} from 'library';

class SnapshotOption extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { snapshot } = this.props;

        return (
            <div className="simple-card row">
                <div className="col-3">{snapshot.student_number}</div>
                <div className="col-3">
                    {`${snapshot.last_name}, ${snapshot.first_name}`}
                </div>
                <div className="col-3">
                    {this.props.school_lookup[snapshot.school_id]}
                </div>
                <div className="col-3">{snapshot.student ? snapshot.student.date_of_birth : ''}</div>
                <div className="col-3">
                    <Button
                        type="success"
                        text="Select"
                        onClick={() => this.props.select(snapshot)}
                    />
                </div>
            </div>
        );
    }
}

class SnapshotSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_snapshots: false,
            snapshots: [],
        };

        if (this.props.school_default) {
            this.state.school = this.props.school_default;
        }

        this.search = this.search.bind(this);
        this.update_filters = this.update_filters.bind(this);
    }

    search() {
        this.setState({ loading_snapshots: true });

        let filters = '?limit=100&related=student';
        filters += `&district=${this.props.snapshot_context.district}`;
        filters += `&school_term=${this.props.snapshot_context.school_term}`;

        const data = {
            last_name__icontains: this.state.last_name,
            first_name__icontains: this.state.first_name,
            school: this.state.school,
            student__student_number__icontains: this.state.student_number,
        };
        for (const key in data) {
            if (!data[key]) {
                continue;
            }

            filters += `&${key}=${data[key]}`;
        }

        ajaxWrapper('GET', `/api/sis/studentsnapshot/${filters}`, {}, value => {
            this.setState({
                snapshots: value,
                loading_snapshots: false,
            });
        });
    }

    update_filters(name, state) {
        this.setState(state);
    }

    render() {
        const data = this.props.student_assessment;

        const defaults = {
            last_name: this.state.last_name,
            first_name: this.state.first_name,
            school: this.state.school,
            student_number: this.state.student_number,
        };

        const filters = (
            <FormWithChildren
                row
                submit_text="Search"
                defaults={defaults}
                autoSetGlobalState
                globalStateName="search"
                setGlobalState={this.update_filters}
            >
                <TextInput
                    name="last_name"
                    className="col-6"
                    label="Last Name"
                />
                <TextInput
                    name="first_name"
                    className="col-6"
                    label="First Name"
                />

                <Select
                    name="school"
                    className="col-6"
                    label="School"
                    options={this.props.school_options}
                />

                <TextInput
                    name="student_number"
                    className="col-6"
                    label="Student ID"
                />
            </FormWithChildren>
        );

        const snapshots = [];
        for (const item of this.state.snapshots) {
            const snapshot = item.studentsnapshot;

            snapshots.push(
                <SnapshotOption
                    snapshot={snapshot}
                    select={this.props.select}
                    school_lookup={this.props.school_lookup}
                />
            );
        }

        return (
            <Wrapper
                style={{ marginTop: '0px' }}
                loaded={!this.state.loading_snapshots}
            >
                <div className="sad" style={{ padding: '30px 10px' }}>
                    <div className="simple-card">
                        <h3>{`Alt Id: ${this.props.identifier}`}</h3>
                    </div>
                    <div className="simple-card">
                        {filters}
                        <Button
                            type="info"
                            text="Search"
                            onClick={this.search}
                        />
                    </div>
                    {snapshots}
                </div>
            </Wrapper>
        );
    }
}

class StudentAssessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: false,
        };

        this.select = this.select.bind(this);
        this.delete = this.delete.bind(this);
    }

    select(snapshot) {
        const data = {
            student_snapshot: snapshot.id,
        };

        ajaxWrapper(
            'POST',
            `/proctor/match_anonymous_assessment/${this.props.student_assessment.id}/`,
            data,
            this.props.get_student_assessments
        );
    }

    delete() {
        ajaxWrapper(
            'POST',
            `/api/home/studentassessment/${this.props.student_assessment.id}/delete/`,
            {},
            this.props.get_student_assessments
        );
    }

    render() {
        const data = this.props.student_assessment;

        let school_default = null;
        if (data.student_alternative_identifier.indexOf(',') > -1) {
            const parts = data.student_alternative_identifier.split(',');
            if (parts.length == 4) {
                school_default = parts[2].trim();
            }
        }

        let snapshot_search = null;
        if (this.state.search) {
            snapshot_search = (
                <EmptyModal
                    show
                    onHide={() => this.setState({ search: false })}
                >
                    <SnapshotSearch
                        school_options={this.props.school_options}
                        school_lookup={this.props.school_lookup}
                        snapshot_context={this.props.snapshot_context}
                        identifier={data.student_alternative_identifier}
                        select={this.select}
                        school_default={school_default}
                    />
                </EmptyModal>
            );
        }

        return (
            <div className="simple-card">
                <div>Alt ID: {data.student_alternative_identifier}</div>
                <div>{data.created_at}</div>
                <div>
                    <Button
                        onClick={() => this.setState({ search: true })}
                        text="Search"
                    />
                </div>
                <div>
                    <Button
                        onClick={this.delete}
                        text="Delete"
                        type="danger"
                        deleteType
                    />
                </div>
                {snapshot_search}
            </div>
        );
    }
}

class AnonymousAssessments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            assessment: null,
            student_assessments: [],

            unassigned_count: 0,
            count: 0,
            matched_count: null,

            limit: 100,
        };

        this.get_assessment_callback = this.get_assessment_callback.bind(this);
        this.get_student_assessments = this.get_student_assessments.bind(this);
        this.get_student_assessments_callback =
            this.get_student_assessments_callback.bind(this);

        this.get_schools_callback = this.get_schools_callback.bind(this);

        this.run_auto_matching = this.run_auto_matching.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/assessment/${this.props.assessment_id}/?related=district`,
            {},
            this.get_assessment_callback
        );
        this.get_student_assessments();
    }

    get_assessment_callback(value) {
        this.setState(
            {
                loaded: true,
                assessment: value[0].assessment,
            },
            () =>
                ajaxWrapper(
                    'GET',
                    `/api/sis/school/?district=${this.state.assessment.district.id}`,
                    {},
                    this.get_schools_callback
                )
        );
    }

    get_schools_callback(value) {
        const schools = [];
        const school_lookup = {};

        for (const item of value) {
            schools.push({
                text: item.school.name,
                value: item.school.id,
            });

            school_lookup[item.school.id] = item.school.name;
        }

        schools.sort((a, b) => (a.text > b.text ? 1 : -1));

        this.setState({
            school_options: schools,
            school_lookup,
        });
    }

    get_student_assessments() {
        this.setState({
            assessments_loading: true,
        });

        ajaxWrapper(
            'GET',
            `/api/home/studentassessment/?assessment=${this.props.assessment_id}&exclude__student_alternative_identifier=BLANK_STRING&student_snapshot__isnull=true?limit=${this.state.limit}`,
            {},
            this.get_student_assessments_callback
        );

        ajaxWrapper(
            'GET',
            `/api/home/studentassessment/?assessment=${this.props.assessment_id}&exclude__student_alternative_identifier=BLANK_STRING&count=true`,
            {},
            value => {
                this.setState({
                    count: value.count,
                });
            }
        );
        ajaxWrapper(
            'GET',
            `/api/home/studentassessment/?assessment=${this.props.assessment_id}&exclude__student_alternative_identifier=BLANK_STRING&student_snapshot__isnull=true&count=true`,
            {},
            value => {
                this.setState({
                    unassigned_count: value.count,
                });
            }
        );
    }

    get_student_assessments_callback(value) {
        this.setState({
            student_assessments: value,
            assessments_loading: false,
        });
    }

    run_auto_matching() {
        this.setState(
            {
                loaded: false,
            },
            () =>
                ajaxWrapper(
                    'POST',
                    `/proctor/match_anonymous_assessments/${this.props.assessment_id}/`,
                    {},
                    value => {
                        this.setState({
                            matched_count: value.count,
                            loaded: true,
                        });
                    }
                )
        );
    }

    render() {
        let assessment_overview = null;
        const student_assessments = [];

        if (this.state.loaded) {
            assessment_overview = (
                <div>
                    <h3>{this.state.assessment.name}</h3>
                    <div>
                        <div>{this.state.count} Anonymous Assessments</div>
                        <div>{this.state.unassigned_count} Unassigned</div>
                    </div>
                </div>
            );

            const snapshot_context = {
                district: this.state.assessment.district.id,
                school_term: this.state.assessment.school_term_id,
            };

            for (const item of this.state.student_assessments) {
                student_assessments.push(
                    <StudentAssessment
                        student_assessment={item.studentassessment}
                        school_options={this.state.school_options}
                        school_lookup={this.state.school_lookup}
                        snapshot_context={snapshot_context}
                        get_student_assessments={this.get_student_assessments}
                    />
                );
            }
        }

        let auto_match_result = (
            <Button
                onClick={this.run_auto_matching}
                type="success"
                text="Run Auto Matching"
            />
        );
        if (this.state.matched_count != null) {
            auto_match_result = (
                <Alert type="success">
                    {`${this.state.matched_count} Assessments Automatically Matched`}
                </Alert>
            );
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ margin: '0px' }}>
                <div className="sad" style={{ padding: '30px 0px 50px 0px' }}>
                    <Container min_height>
                        <div className="simple-card">
                            {assessment_overview}
                            {auto_match_result}
                        </div>

                        <Wrapper
                            loaded={!this.state.assessments_loading}
                            style={{ margin: '0px' }}
                        >
                            {student_assessments}
                        </Wrapper>
                    </Container>
                </div>
            </Wrapper>
        );
    }
}

export default AnonymousAssessments;
