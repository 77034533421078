import React, { Component } from 'react';
import { resolveVariables, ajaxWrapper, ajaxWrapperFile } from 'functions';

import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from 'react-sortable-hoc';
import { Image, Card, Button } from 'library';

const BOOLEANS = [
    { text: 'True', value: true },
    { text: 'False', value: false },
];

const SortableItem = SortableElement(({ value, removeImage }) => (
    <Card
        css={{ display: 'inline-block', width: '20%' }}
        imageUrl={value.url}
        id={value.id}
        buttons={[
            <Button
                text="Remove Picture"
                type="danger"
                onClick={() => removeImage({ id: value.id, url: value.url })}
            />,
        ]}
    />
));

const SortableList = SortableContainer(({ items, removeImage }) => (
    <ul>
        {items.map((value, index) => (
            <SortableItem
                key={`item-${index}`}
                index={index}
                value={value}
                removeImage={removeImage}
            />
        ))}
    </ul>
));

class ImageInput extends Component {
    static component_name = 'ImageInput';

    constructor(props) {
        super(props);

        this.state = {
            files: null,
            uploaded_files: [],
            uploading_from_file_input: false,
        };

        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    onChange(e) {
        this.setState(
            {
                files: e.target.files,
                uploaded_files: this.props.value,
            },
            this.fileUpload(e.target.files)
        );
    }

    fileUpload(files) {
        const url = '/files/imageUpload/';
        const formData = new FormData();
        Object.keys(files).forEach(index => {
            if (index != 'length' && index != 'item') {
                formData.append('files[]', files[index]);
            }
        });
        formData.append('name', this.props.name);
        if (typeof this.props.is_public !== 'undefined') {
            formData.append('is_public', this.props.is_public);
        } else {
            formData.append(
                'is_public',
                !window.secretReactVars.secure_uploads
            );
        }

        ajaxWrapperFile('POST', url, formData, this.fileUploadCallback);
        this.setState({ uploading_from_file_input: true });
        this.props.setFormState({ uploading_from_file_input: true });
    }

    removeImage(imageProps) {
        let formState;
        if (this.props.multiple == true) {
            const { url } = imageProps;
            const uploaded_files = this.props.value;
            const order_files = [];

            Object.keys(uploaded_files).forEach(index => {
                const file = uploaded_files[index];
                if (file.url != url) {
                    order_files.push({
                        url: file.url,
                        order: index,
                        filename: file.filename,
                    });
                }
            });

            if (this.props.deleteUrl) {
                const { deleteUrl } = resolveVariables(
                    { deleteUrl: this.props.deleteUrl },
                    imageProps
                );
                ajaxWrapper('POST', deleteUrl, {}, console.log);
            }
            formState = {};
            formState[this.props.name] = order_files;

            this.props.setFormState(formState);
        } else {
            formState = {};
            formState[this.props.name] = '';
            this.props.setFormState(formState);
        }
    }

    fileUploadCallback(value) {
        let uploaded_files = this.props.value;

        if (this.props.multiple == true) {
            if (uploaded_files == undefined) {
                uploaded_files = [];
            }
            Object.keys(value.uploaded_files).forEach(index => {
                uploaded_files.push(value.uploaded_files[index]);
            });

            uploaded_files.sort(
                (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
            );
        } else {
            if (uploaded_files == undefined) {
                uploaded_files = '';
            }

            uploaded_files = value.uploaded_files[0].url;
        }

        const formFiles = {};

        formFiles[this.props.name] = uploaded_files;
        formFiles.uploading_from_file_input = false;

        this.setState(
            {
                uploaded_files,
                uploading_from_file_input: false,
            },
            this.props.setFormState(formFiles)
        );
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        if (this.props.multiple == true) {
            const uploaded_files = arrayMove(
                this.props.value,
                oldIndex,
                newIndex
            );
            const order_files = [];
            Object.keys(uploaded_files).forEach(index => {
                const file = uploaded_files[index];
                if (file.order != index) {
                    file.order = index;
                }

                order_files.push({
                    url: file.url,
                    order: index,
                    filename: file.filename,
                });
            });

            const formState = {};
            formState[this.props.name] = order_files;

            this.props.setFormState(formState);
        }
    };

    render() {
        const input = this.props.multiple ? (
            <input
                onChange={this.onChange}
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                name={this.props.name}
                multiple
            />
        ) : (
            <input
                onChange={this.onChange}
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                name={this.props.name}
            />
        );

        let previewText = 'Preview Your Image Below';
        if (this.props.multiple == true) {
            previewText = 'Re-order your images below';
        }

        let pictureViewer = null;
        if (
            this.props.value &&
            this.props.value.length > 0 &&
            !this.props.preview == false
        ) {
            let image_list = null;
            const image_style = { display: 'inline-block' };
            if (this.props.preview_width) {
                image_style['max-width'] = this.props.preview_width;
            }

            if (this.props.multiple == true) {
                const { value } = this.props;
                image_list = (
                    <SortableList
                        axis="x"
                        items={value}
                        onSortEnd={this.onSortEnd}
                        removeImage={this.removeImage}
                    />
                );
            } else {
                const value = { filename: '', url: this.props.value, order: 0 };
                image_list = <Image css={image_style} src={value.url} />;
            }

            pictureViewer = (
                <div className="jumbotron" style={{ padding: '10px 15px' }}>
                    <div>{previewText}</div>
                    {image_list}
                </div>
            );
        }

        const label = this.props.label && (
            <label
                style={this.props.label_style || {}}
                htmlFor="exampleFormControlFile1"
            >
                {this.props.label}
            </label>
        );

        return (
            <div
                className={`form-group ${this.props.class}`}
                style={this.props.style}
            >
                {label}
                {input}
                {pictureViewer}
            </div>
        );
    }
}

export default ImageInput;
