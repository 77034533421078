import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import format_date_string from 'functions/dates/format_date_string';
import { sort_objects } from 'functions';
import SideNavbar from 'pages/reports/components/side_navbar.js';
import Header from 'pages/reports/components/header.js';

import { Container, Button, Alert, Tabs, Wrapper } from 'library';

class AssessmentPrintouts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,
            district: {},
            height: 0,
            width: 0,
        };

        this.get_district_callback = this.get_district_callback.bind(this);

    }

    get_district_callback(result) {
        this.setState(
            {
                loaded: true,
                district: result[0].district
            },
        );
    }

    componentDidMount() {
        this.update_dimensions();
        window.addEventListener('resize', this.update_dimensions.bind(this));

        ajaxWrapper(
            'GET',
            `/admin_guide_context/${this.props.district_id}/`,
            {},
            this.get_district_callback
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update_dimensions.bind(this));
    }

    update_dimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight - 1,
        });
    }

    render() {

        var content = null;

        if (this.state.loaded) {
            let date_window_opens_candidate = this.state.district.assessments[0].assessment.date_window_opens;
            let date_window_closes_candidate = this.state.district.assessments[0].assessment.date_window_closes;
            let show_window = this.state.district.assessments.every(candidate => candidate.assessment.date_window_opens == date_window_opens_candidate && candidate.assessment.date_window_closes == date_window_closes_candidate)
            let date_window_opens = format_date_string(date_window_opens_candidate,"mm/dd");
            let date_window_closes = format_date_string(date_window_closes_candidate,"mm/dd");

            let rows = this.state.district.assessments.map(row => (
                <tr>
                    <td className="table-cell">
                        <b>{row.assessment.name}</b>
                    </td>
                    <td className="table-cell">
                        <a
                            href={"/printable_assessment/" + this.state.district.id + "/" + row.assessment.id + "?language=English"}
                        >
                            {row.assessment.name}
                        </a>
                    </td>
                    <td className="table-cell">
                        <a
                            href={"/printable_assessment/" + this.state.district.id + "/" + row.assessment.id + "?language=Spanish"}
                        >
                            {row.assessment.name}
                        </a>
                    </td>
                    <td className="table-cell">
                        <a
                            href={"/printable_assessment/" + this.state.district.id + "/" + row.assessment.id + "?language=Vietnamese"}
                        >
                            {row.assessment.name}
                        </a>
                    </td>
                    <td className="table-cell">
                        <a
                            href={"/printable_assessment/" + this.state.district.id + "/" + row.assessment.id + "?language=Tagalog"}
                        >
                            {row.assessment.name}
                        </a>
                    </td>
                </tr>
            ))


            var content = (
                <Container className="administration-guide" no_header style={{ padding: '0px', marginBottom: '50px' }}>
                    <div className="simple-card no-print">
                        <Alert type="info">
                            <h2 className='walkthrough'>Welcome to the Math ANEX Printable Assessments page!</h2>
                            <div>
                                <Button
                                    style={{
                                        padding: '12px 40px',
                                        height: 'auto',
                                        fontSize: '20px',
                                    }}
                                    type="success"
                                    text="Print Me!"
                                    onClick={() => window.print()}
                                />
                            </div>
                        </Alert>
                    </div>
                    <p className="centered">
                        <span className="doc-title">Printable Materials For Active Assessments</span>
                    </p>
                    {show_window == true && (
                    <p className="centered">
                        <span className="bold math-anex-blue">Assessment Window: {date_window_opens} - {date_window_closes}</span>
                    </p>
                    )}
                    <p className='blank-space-height'></p>
                    <p className="body-text-spacing">
                        <span>While we encourage students to take the DEMI online, we have also included printable versions if teachers think a paper-pencil version would better support their students.</span>
                    </p>

                    <p className="body-text-spacing">
                        <span><b>PLEASE NOTE:</b>  Any responses written on paper will need to be entered into the Math ANEX platform under each student’s ID so that our team can analyze the responses.</span>
                    </p>

                    <table>
                        <thead>
                            <tr className="tan-background">
                                <th className="table-cell">
                                    <h2 className="centered walkthrough">
                                        <span className="table-title">Assessment</span>
                                    </h2>
                                </th>
                                <th className="table-cell">
                                    <h2 className="centered walkthrough">
                                        <span className="table-title">English</span>
                                    </h2>
                                </th>
                                <th className="table-cell">
                                    <h2 className="centered walkthrough">
                                        <span className="table-title">Spanish</span>
                                    </h2>
                                </th>
                                <th className="table-cell">
                                    <h2 className="centered walkthrough">
                                        <span className="table-title">Vietnamese</span>
                                    </h2>
                                </th>
                                <th className="table-cell">
                                    <h2 className="centered walkthrough">
                                        <span className="table-title">Tagalog</span>
                                    </h2>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                        {rows}
                        </tbody>
                    </table>
                </Container>
            );
        }

        const header_height = 62;

        const main_content_style = {
            transition: '.2s margin-left',
            marginLeft: '76px',
            height: `${this.state.height}px`,
        };

        const result_style = {
            background: 'white',
            height: `${this.state.height - header_height}px`,
            transition: '.2s margin',
            overflowY: 'auto',
            paddingTop: '20px',
        };

        return (
            <Wrapper
                loaded={this.state.loaded}
                style={{ marginTop: '0px' }}
            >
                <div className="reporting-dashboard">
                    <SideNavbar
                        height={this.state.height}
                        district={this.state.district}
                    />

                    <div style={main_content_style} className="sad">
                        <Header
                            page="Assessment Printable Materials"
                            width={this.state.width}
                            pdf_name="Assessment Printable Materials"
                        />

                        <div style={result_style} className='no-style'>
                            {/* Main Content Display */}

                            {content}

                            <div style={{ clear: 'both', width: '100%' }} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default AssessmentPrintouts;