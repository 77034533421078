import React, { Component } from 'react';

import { Icon } from 'library';

import Question from 'pages/reports/results_explorer/components/question.js';
import TabAutoSizer from 'pages/reports/results_explorer/components/tab_autosizer.js';

import ByAnswer from 'pages/reports/results_explorer/charts/question_response_tabs/by_answer.js';
import ByStudentAnswer from 'pages/reports/results_explorer/charts/question_response_tabs/by_student_answer.js';
import WaysOfThinking from 'pages/reports/results_explorer/charts/question_response_tabs/ways_of_thinking.js';
import MCLBreakdown from 'pages/reports/results_explorer/charts/question_response_tabs/mcl_breakdown.js';

const COMMUNICATION_CATEGORY = 'bcba4111-d2fc-4dc1-b19a-a1b843c6e8ee';

export default class QuestionResponses extends Component {
    constructor(props) {
        super(props);

        const question_id = this.props.question;
        let communication_question = false;
        let context = 'Top Answers';

        const question = this.props.question_responses.question_details;
        if (question.category == COMMUNICATION_CATEGORY) {
            communication_question = true;
            context = 'Student Answers';
        }

        this.state = {
            context,
            communication_question,
        };

        this.select_context = this.select_context.bind(this);
        this.unset_question = this.unset_question.bind(this);
    }

    select_context(name) {
        this.setState({ context: name });
    }

    unset_question() {
        this.props.update_filters('question_details', { id: '' });
    }

    render() {
        const question_id = this.props.question;
        const question = this.props.question_responses.question_details;
        const { communication_question } = this.state;

        let contexts = ['Top Answers', 'Student Answers'];

        if (communication_question) {
            contexts = ['Student Answers', 'Ways of Thinking', 'MCL Breakdown'];
        }

        let content = null;
        var correct_answer = null;
        if (question.correct_answer) {
            var correct_answer = (
                <p>
                    Correct Answer(s): {question.correct_answer} Or Equivalent
                </p>
            );
        }

        let charts = null;
        if (this.state.context == 'Top Answers') {
            charts = (
                <ByAnswer
                    overview="Top Answers for Results of All Assessments"
                    update_filters={this.props.update_filters}
                    question={question}
                    question_responses={this.props.question_responses}
                />
            );
        } else if (this.state.context == 'Student Answers') {
            let title = 'Student Answers for Results of All Assessments';
            if (this.props.filters.question_details.way_of_thinking) {
                title = `Student Answers who used ${this.props.filters.question_details.way_of_thinking_name}`;
            }

            charts = (
                <ByStudentAnswer
                    overview={title}
                    district_id={this.props.district_id}
                    question={question}
                    question_responses={this.props.question_responses}
                    filters={this.props.filters}
                    update_filters={this.props.update_filters}
                    way_of_thinking={
                        this.props.filters.question_details.way_of_thinking
                    }
                    communication_question={communication_question}
                />
            );
        } else if (this.state.context == 'Ways of Thinking') {
            charts = (
                <WaysOfThinking
                    overview="All Ways Of Thinking"
                    update_filters={this.props.update_filters}
                    question={question}
                    question_responses={this.props.question_responses}
                />
            );
        } else if (this.state.context == 'MCL Breakdown') {
            charts = (
                <MCLBreakdown
                    overview="MCL Breakdown"
                    data={this.props.question_responses.mcl_list}
                    question={question}
                    question_responses={this.props.question_responses}
                />
            );
        }

        content = (
            <div className="row">
                <div className="col-3" style={{ padding: '0px' }}>
                    <div className="result-display-card">
                        <div
                            className="orange-button"
                            onClick={this.unset_question}
                            style={{
                                marginLeft: '5px',
                                padding: '8px',
                                lineHeight: '16px',
                                float: 'right',
                            }}
                        >
                            <Icon
                                size={1}
                                icon_type="fas"
                                icon="chevron-left"
                                style={{ width: '14px', textAlign: 'center' }}
                            />
                        </div>

                        <div className="h2 bold header">Question Details</div>
                        <Question
                            setGlobalState={this.props.setGlobalState}
                            question={question}
                            question_details={
                                this.props.question_responses.question_details
                                    .direct
                            }
                            id={question_id}
                            filters={this.props.filters}
                            update_filters={this.props.update_filters}
                            district={{
                                question_details:
                                    this.props.question_responses
                                        .question_details.compare,
                            }}
                            view={false}
                        />

                        {/*
                <div style={{margin: '10px 0px'}} >
                    <PageBreak />
                </div>
                <div>
                    <div className='button sad' style={{padding: '8px', lineHeight: '16px', float:'right'}} >
                        <Image src={'/static/images/reporting/Compare.svg'} className={'icon purple'} css={{width: '16px'}} />
                    </div>
                    <div className='h2 bold header'>Recommended Comparison</div>
                </div>
                */}
                    </div>
                </div>

                <div className="col-9" style={{ padding: '0px' }}>
                    <TabAutoSizer
                        tabs={contexts}
                        current={this.state.context}
                        select_tab={this.select_context}
                        side_navbar_open={this.props.side_navbar_open}
                        filters_open={this.props.filters_open}
                    />
                    {charts}
                </div>
            </div>
        );

        const no_data_content = (
            <div className="result-display-card">
                <div className="h2 bold header">Question Details</div>
                <div>
                    <p>
                        We do not have enough data for this question with these
                        filters.
                    </p>
                </div>
            </div>
        );

        return content;
    }
}
