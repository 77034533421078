import React, { Component } from 'react';

import { EmptyModal, Icon } from 'library';

class QuestionEnlargeImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_image_modal: false,
        };
    }

    render() {
        const {text} = this.props;
        const {image} = this.props;

        const style = {
            
            textAlign: 'center',
            ...this.props.style
        };
        const height = this.props.height || 150;

        const image_modal = (
            <EmptyModal
                show={this.state.show_image_modal}
                onHide={() => this.setState({ show_image_modal: false })}
            >
                <div style={{ padding: '30px' }}>
                    <img
                        style={{ minWidth: '50%', maxWidth: '100%' }}
                        src={image}
                        alt={text}
                    />
                </div>
            </EmptyModal>
        );

        let question_image = (
            <div style={this.props.style}>
                <p>{text}</p>
            </div>
        );

        if (image != '') {
            question_image = (
                <div
                    className="question-image-container"
                    onClick={() => this.setState({ show_image_modal: true })}
                    style={{ height: `${height  }px`, position: 'relative' }}
                >
                    <div className="enlarge">
                        <Icon icon="search-plus" size={3} />
                    </div>
                    <img
                        className="question-image"
                        src={image}
                        alt={text}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            width: 'auto',
                        }}
                    />
                </div>
            );
        }

        return (
            <div>
                {question_image}
                {image_modal}
            </div>
        );
    }
}

export default QuestionEnlargeImage;
