import React from 'react';
import { Modal } from 'library';

class Button extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            hover: false,
            disabled: false,
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.click = this.click.bind(this);
    }

    showModal() {
        this.setState({ modal: true });
    }

    hideModal() {
        this.setState({ modal: false });
    }

    click(event) {
        event.preventDefault();

        if (this.props.can_only_be_clicked_once) {
            this.setState({ disabled: true });
        }

        if (this.props.href) {
            if (this.props.target == '_blank') {
                window.open(this.props.href, '_blank');
            } else {
                window.location.href = this.props.href;
            }
        } else if (this.props.onClick) {
            this.props.onClick(event, this.hideModal);
        }
    }

    render() {
        const type = `btn-${this.props.type}`;
        let css = {};
        if (this.props.style) {
            css = this.props.style;
        }

        let content = null;

        let disabled = this.props.disabled || {};
        if (this.state.disabled) {
            disabled = {
                disabled: 'disabled',
            };
        }

        let on_click = this.click;

        if (this.props.deleteType == true) {
            on_click = this.showModal;
        }

        let key = {};
        if (this.props.button_key) {
            key = {
                key: this.props.button_key,
            };
        }

        content = (
            <button
                {...key}
                className={`btn ${type}`}
                onClick={on_click}
                style={css}
                num={this.props.num}
                {...disabled}
                type="button"
            >
                {this.props.text}
            </button>
        );

        if (this.props.deleteType == true && this.state.modal == true) {
            const alt = (
                <button
                    className="btn btn-success"
                    onClick={this.hideModal}
                    style={{ float: 'left', }}
                    type="button"
                >
                    {this.props.delete_text || "Don't Delete"}
                </button>
            );

            const button = (
                <button
                    className={`btn ${type}`}
                    onClick={this.click}
                    style={{ float: 'right' }}
                    type="button"
                >
                    {this.props.text}
                </button>
            );

            content = (
                <Modal
                    content={[alt, button]}
                    show
                    title={this.props.delete_warning_text || 'Are you sure?'}
                    onHide={this.hideModal}
                />
            );
        }

        return content;
    }
}

export default Button;
