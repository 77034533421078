import React, { Component } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

import { FormWithChildren, Select } from 'library';
import { sort_objects } from 'functions';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';

class DownloadResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            download: false,
            schools: null,
        };

        this.toggle_download = this.toggle_download.bind(this);
    }

    toggle_download() {
        this.setState({ download: !this.state.download });
    }

    render() {
        let filters = {'school_term': this.props.report.school_term_id};
        if (this.props.report.school_id) {
            filters['school'] = this.props.report.school['id'];
        }
            
        let download_modal = (
            <a style={{ color: 'blue', textDecoration:'underline', fontSize:'18px' }} onClick={this.toggle_download}>
                {this.props.title}
            </a>
        );
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={
                        '/reporting/results_explorer/get_met_standard/' +
                        this.props.report.district_id +
                        '/'
                    }
                    query={{
                        csv: true,
                        filters: filters,
                        compare_filters: {},
                    }}
                    onHide={this.toggle_download}
                />
            );
        }

        return <div>{download_modal}</div>;
    }
}

export default class MetStandardReport extends Component {
    constructor(props) {
        super(props);

        let options = {
            grade: [],
            race: [],
            ell_status: [],
        };
        for (let key in this.props.report['data']) {
            let pieces = key.split(/_/g);

            if (!options['grade'].includes(pieces[0])) {
                options['grade'].push(pieces[0]);
            }
            if (!options['race'].includes(pieces[1])) {
                options['race'].push(pieces[1]);
            }
            if (!options['ell_status'].includes(pieces[2])) {
                options['ell_status'].push(pieces[2]);
            }
        }

        let final_options = {};
        for (let key in options) {
            final_options[key] = [];
            for (let item of options[key]) {
                let item_text = item;
                if (item == 'None') {
                    item_text = '';
                }

                final_options[key].push({
                    text: item_text,
                    value: item,
                });
            }

            sort_objects(final_options[key], ['text']);
        }

        this.state = {
            options: final_options,
            filters: {
                grade: 'None',
                race: 'None',
                ell_status: 'None',
            },
        };

        this.update_filters = this.update_filters.bind(this);
    }

    update_filters(name, state) {
        let filters = this.state.filters;

        for (let key in state) {
            filters[key] = state[key];
        }

        this.setState({
            filters: filters,
        });
    }

    render() {
        const colors = ['#47E3FA', '#40AEFA', '#3878F9', '#3232A4', '#838093'];

        let grade_filter = null;
        let race_filter = null;
        let ell_filter = null;
        if (this.state.options['grade'].length > 1) {
            grade_filter = (
                <Select
                    name="grade"
                    label="Grade"
                    className="col-12"
                    options={this.state.options['grade']}
                    no_blank_option={true}
                ></Select>
            );
        }
        if (this.state.options['grade'].length > 1) {
            race_filter = (
                <Select
                    name="race"
                    label="Race"
                    className="col-12"
                    options={this.state.options['race']}
                    no_blank_option={true}
                ></Select>
            );
        }
        if (this.state.options['ell_status'].length > 1) {
            ell_filter = (
                <Select
                    name="ell_status"
                    label="English Learner"
                    className="col-12"
                    options={this.state.options['ell_status']}
                    no_blank_option={true}
                ></Select>
            );
        }

        let labels_ordered = [1, 2, 3, 4];
        let label_dict = {
            1: '1 - Standard Not Met',
            2: '2 - Standard Nearly Met',
            3: '3 - Standard Met',
            4: '4 - Standard Exceeded',
        };
        let labels = [];
        let data = [];

        let lookup_key = `${this.state.filters['grade']}_${this.state.filters['race']}_${this.state.filters['ell_status']}`;
        let report_data = this.props.report.data[lookup_key];

        if (report_data) {
            for (let key of labels_ordered) {
                let percentage = (
                    (report_data[key] / report_data['total']) *
                    100
                ).toFixed(0);

                if (key in report_data) {
                    data.push(report_data[key]);
                } else {
                    data.push(0);
                }

                let label_string = `${label_dict[key]} (${percentage}%)`;
                labels.push(label_string);
            }
        }

        const chart_data = {
            labels,
            datasets: [
                {
                    label: `Results`,
                    data,
                    backgroundColor: colors,
                },
            ],
        };

        const pie_options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'center',
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'circle',
                        font: {
                            size: 14,
                        },
                        padding: 20,
                    },
                },
            },
        };

        return (
            <div>
                <div>
                    <div
                        className="h1"
                        style={{ fontSize: '24px', marginBottom: '15px' }}
                    >
                        Met Standard Report
                    </div>
                </div>
                <div className="sad-simple-card-container row">
                    <div className="col-8 " style={{ padding: '0px' }}>
                        <div className="simple-card">
                            <div
                                style={{
                                    padding: '20px 0px',
                                }}
                            >
                                <Doughnut
                                    data={chart_data}
                                    options={pie_options}
                                    style={{
                                        cursor: 'pointer',
                                        height: '500px',
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-4 " style={{ padding: '0px' }}>
                        <div className="simple-card">
                            <div>
                                <div
                                    style={{
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        padding: '15px',
                                    }}
                                >
                                    Filter your results:
                                </div>
                                <FormWithChildren
                                    key={'choose_a_school'}
                                    className="row"
                                    autoSetGlobalState
                                    setGlobalState={this.update_filters}
                                    globalStateName="filter"
                                    defaults={this.state.filters}
                                >
                                    {grade_filter}
                                    {race_filter}
                                    {ell_filter}
                                </FormWithChildren>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ fontSize: '16px' }}>
                    <br />
                    {'* This report should be used for compliance only. '}
                    <DownloadResults title='Download Individual Student Results For Compliance Report' report={this.props.report}/>
                </div>
            </div>
        );
    }
}
