import React, { Component } from 'react';

import {
    Container,
    Wrapper,
    Image,
    Alert,
    Icon,
    Button,
    Markdown,
} from 'library';
import { sort_objects } from 'functions';

import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

function remove_tags(str) {
    if (str === null || str === '') return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, '');
}

class AnswerForSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_table: false,
        };
    }

    render() {
        let item = this.props.item;
        let show_table = this.state.show_table;

        let student_names = [];
        let student_detail_rows = [];
        let detail_button = null;

        let student_names_text = [];

        if ('student_details' in item) {
            let ordered_details = sort_objects(item['student_details'], [
                'name',
            ]);
            for (let student of ordered_details) {
                let name = student['name'];
                if (window.cmState.user.email == 'demo@mathanex.com') {
                    let name_pieces = name.split(',');
                    name = `${name_pieces[0][0]}, ${name_pieces[1]}`;
                }

                student_names.push(student['name']);
                student_detail_rows.push(
                    <tr>
                        <td>
                            <a
                                href={`/report_navigator/lookup/?student=${student['id']}`}
                                style={{ color: 'blue' }}
                            >
                                {name}
                            </a>
                        </td>
                        <td>{student['answer']}</td>
                    </tr>
                );

                student_names_text.push(<div className="col-6">{name}</div>);
            }

            detail_button = (
                <div
                    className="purple-button-outline"
                    onClick={() => this.setState({ show_table: true })}
                    style={{
                        float: 'right',
                        display: 'inline-block',
                        width: 'auto',
                        marginLeft: '8px',
                        padding: '2px 8px',
                    }}
                >
                    <i
                        className="fas fa-plus"
                        style={{
                            marginRight: '5px',
                            fontSize: '12px',
                        }}
                    />{' '}
                    Expand
                </div>
            );
            if (this.state.show_table) {
                detail_button = (
                    <div
                        className="purple-button-outline"
                        onClick={() => this.setState({ show_table: false })}
                        style={{
                            float: 'right',
                            display: 'inline-block',
                            width: 'auto',
                            marginLeft: '8px',
                            padding: '2px 8px',
                        }}
                    >
                        <i
                            className="fas fa-minus"
                            style={{
                                marginRight: '5px',
                                fontSize: '12px',
                            }}
                        />{' '}
                        Collapse
                    </div>
                );
            }

            if (student_names_text.length > 12) {
                student_names_text = student_names_text.slice(0, 11);

                student_names_text.push(<div className="col-6">{'. . .'}</div>);
            }
        }

        let student_detail_table = (
            <table
                className="table bordered-table"
                style={{ background: 'white' }}
            >
                <tr>
                    <td style={{ fontWeight: 500 }}>Name</td>
                    <td style={{ fontWeight: 500 }}>Answer</td>
                </tr>
                {student_detail_rows}
            </table>
        );

        let percentage = '0';
        let total_students = 0;
        if ('student_details' in item) {
            percentage = (
                (item['student_details'].length / item['total_students']) *
                100
            ).toFixed(0);
            total_students = item['student_details'].length;
        } else {
            percentage = (
                (item['students_who_answered'] / item['total_students']) *
                100
            ).toFixed(0);
            total_students = item['students_who_answered'];
        }

        let alert_type = 'default';
        let container_style = {};
        if (item['answer']['is_correct']) {
            container_style['background'] = '#d4edda';
            container_style['borderColor'] = '#c3e6cb';
        }

        let clean_answer_description = (
            <Markdown text={item['answer']['way_to_get']} />
        );
        // remove_tags(item['answer']['way_to_get']);

        let student_display = <div className="row">{student_names_text}</div>;
        if (show_table) {
            student_display = student_detail_table;
        }

        return (
            <div className="simple-card" style={container_style}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', paddingRight: '10%' }}>
                        <div
                            style={{ fontWeight: '600', fontSize: '18px' }}
                        >{`Answer: ${item['answer']['text']}`}</div>
                    </div>

                    <div style={{ width: '50%' }}>
                        {detail_button}
                        <div
                            style={{
                                marginBottom: '10px',
                                fontWeight: '600',
                                fontSize: '16px',
                                display: 'inline-block',
                            }}
                        >{`${total_students} Students (${percentage}%)`}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', paddingRight: '10%' }}>
                        <div style={{ marginBottom: '20px', fontSize: '16px' }}>
                            {clean_answer_description}
                        </div>
                    </div>

                    <div style={{ width: '50%' }}>
                        <div style={{ fontSize: '16px' }}>
                            {student_display}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default class QuestionOnAssessmentReport extends Component {
    constructor(props) {
        super(props);

        this.navigate_to_reengagement =
            this.navigate_to_reengagement.bind(this);
    }

    navigate_to_reengagement() {
        this.props.send_event('Clicked Reengagement');
        window.open(this.props['question']['generic_reengagement'], '_blank');
    }

    render() {
        let data = this.props;

        let show_table = true;

        let answers = [];
        if (data['question']['category'] == 'Explanation') {
            for (let key of ['m', 'c', 'l']) {
                let item = data['answers'][key];

                answers
                    .push
                    // New component required
                    ();
            }
        } else if (data['question']['category'] == 'Mindset') {
            let answers_lookup = {};
            for (let item of data['answers']) {
                answers_lookup[item['answer']['text']] = item;
            }

            let scale = [
                'Strongly Agree / Yes',
                'Agree / Sometimes',
                'Disagree / Not Really',
                'Strongly Disagree / No',
                'Left Blank',
            ];
            for (let key of scale) {
                if (!(key in answers_lookup)) {
                    continue;
                }
                let item = answers_lookup[key];

                answers.push(
                    <AnswerForSection item={item} show_table={show_table} />
                );
            }
        } else {
            let unordered_anwers = [];
            let unordered_correct_anwers = [];
            for (let item of data['answers']) {
                let section_item = item;

                if (data['section']) {
                    if (!(data['section'] in item)) {
                        continue;
                    }
                    section_item = item[data['section']];

                    if (section_item['answer']['is_correct']) {
                        unordered_correct_anwers.push({
                            jsx: (
                                <AnswerForSection
                                    item={section_item}
                                    show_table={show_table}
                                />
                            ),
                            count: section_item['student_details'].length,
                        });
                    } else {
                        unordered_anwers.push({
                            jsx: (
                                <AnswerForSection
                                    item={section_item}
                                    show_table={show_table}
                                />
                            ),
                            count: section_item['student_details'].length,
                        });
                    }
                } else {
                    if (section_item['answer']['is_correct']) {
                        unordered_correct_anwers.push({
                            jsx: (
                                <AnswerForSection
                                    item={section_item}
                                    show_table={show_table}
                                />
                            ),
                            count: section_item['students_who_answered'],
                        });
                    } else {
                        unordered_anwers.push({
                            jsx: (
                                <AnswerForSection
                                    item={section_item}
                                    show_table={show_table}
                                />
                            ),
                            count: section_item['students_who_answered'],
                        });
                    }
                }
            }
            sort_objects(unordered_correct_anwers, ['count'], true);
            for (let jtem of unordered_correct_anwers) {
                answers.push(jtem['jsx']);
            }

            sort_objects(unordered_anwers, ['count'], true);
            for (let jtem of unordered_anwers) {
                answers.push(jtem['jsx']);
            }
        }
        let why_this_item = null;
        let generic_reengagement = null;
        let additional_details = null;
        if (data['question']['reason_to_ask']) {
            why_this_item = [
                <div
                    style={{
                        fontSize: '20px',
                        fontWeight: '600',
                        marginBottom: '6px',
                    }}
                >
                    Why this item
                </div>,
                <div style={{ fontSize: '16px', marginBottom: '20px ' }}>
                    {data['question']['reason_to_ask']}
                </div>,
            ];
        }
        if (data['question']['generic_reengagement']) {
            generic_reengagement = [
                <div
                    style={{
                        marginBottom: '6px',
                    }}
                >
                    <a
                        style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: 'blue',
                        }}
                        className="hover-underline"
                        onClick={this.navigate_to_reengagement}
                    >
                        Re-Engagement Activity Slides
                        <i
                            class="fas fa-comments"
                            style={{
                                opacity: '.8',
                                fontSize: '18px',
                                margin: '0px 4px',
                                color: 'blue',
                            }}
                        ></i>
                    </a>
                </div>,
                <div style={{ fontSize: '16px', marginBottom: '10px ' }}>
                    {data['question']['reengagement_description']}
                </div>,
            ];
        }

        if (why_this_item || generic_reengagement) {
            additional_details = (
                <div
                    className="col-7"
                    style={{ padding: '0px 15px', width: '60%' }}
                >
                    {why_this_item}
                    {generic_reengagement}
                </div>
            );
        }

        let question_display = (
            <div className="row">
                <div className="col-5" style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            display: 'inline-block',
                            border: 'thin solid #ccc',
                            borderRadius: '3px',
                        }}
                    >
                        <QuestionEnlargeImage
                            image={data['question']['image']}
                            text={data['question']['text']}
                            height="220"
                        />
                    </div>
                </div>
                {additional_details}
            </div>
        );

        return (
            <div>
                <div
                    style={{
                        padding: '15px',
                    }}
                >
                    {question_display}
                </div>

                <div
                    style={{
                        padding: '15px',
                    }}
                >
                    {answers}
                </div>
            </div>
        );
    }
}
