import React from 'react';

export default function Card(props) {
    let image = <div />;
    if (props.imageUrl) {
        image = (
            <img
                className="card-img-top"
                src={props.imageUrl}
                alt={props.imageAlt}
            />
        );
    }

    return (
        <div
            className={`card col-md-4 col-sm-12 ${props.cssClass}`}
            style={props.css}
            data-id={props.data_id}
        >
            {image}
            <div className="card-body">
                <h5 className="card-title">{props.name}</h5>
                <div className="card-text">{props.description}</div>
                {props.buttons}
            </div>
        </div>
    );
}
