import React, { Component } from 'react';
import { Audio, Video, Alert } from 'library';

import TEXT_DICT from './translations.js';

export default class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { question } = this.props;
        const lang = this.props.language;

        const question_text = question.text_by_language[lang];
        const question_image = question.images_by_language[lang];
        const question_video = question.videos_by_language[lang];

        let question_content = null;
        let question_audio = null;

        if (question_video) {
            question_content = (
                <Video
                    video_url={question_video}
                    thumbnail={question_image}
                    alt={question_text}
                    style={{ margin: '20px 0px', position: 'relative' }}
                    aspect_ratio={9 / 16}
                />
            );
        } else if (question_image) {
            question_content = (
                <div
                    style={{
                        boxShadow: '2px 2px 10px rgb(0 0 0 / 10%)',
                        borderRadius: '4px',
                        padding: '10px',
                        background: 'white',
                        border: 'thin solid #eee',
                    }}
                >
                    <img
                        style={{ width: '100%' }}
                        src={question_image}
                        alt={question_text}
                    />
                </div>
            );
        } else {
            question_content = <div>{question_text}</div>;
        }

        if (question.audio_by_language[lang] && !this.props.printable) {
            question_audio = (
                <div>
                    <Alert
                        type="info"
                        style={{ margin: '0px', padding: '8px 15px' }}
                    >
                        <div style={{ float: 'right' }}>
                            <Audio
                                src={question.audio_by_language[lang]}
                                simple_player={true}
                            />
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                            {
                                TEXT_DICT[
                                    'Click here if you would like to hear this question read aloud'
                                ][lang]
                            }
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </Alert>
                </div>
            );
        }

        return (
            <div>
                {question_content}
                {question_audio}
            </div>
        );
    }
}
