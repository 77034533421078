import React, { Component } from 'react';

import { Remark } from 'react-remark';
import supersub from 'remark-supersub';

export default class Markdown extends Component {
    render() {
        return <Remark remarkPlugins={[supersub]}>{this.props.text}</Remark>;
    }
}
