import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Bar, Line } from 'react-chartjs-2';

import { Wrapper } from 'library';
import student_list_header_text from 'pages/reports/results_explorer/functions/student_list_header_text.js';

function percent_ticks(value, index, ticks) {
    return `${value}%`;
}

const BASIC_BAR_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            align: 'end',
            labels: {
                usePointStyle: true,
                font: {
                    size: 14,
                },
                padding: 20,
            },
        },
    },
    elements: {
        pointStyle: 'circle',
    },
    scales: {
        y: {
            min: 0,
            grid: {
                drawBorder: false,
            },
            ticks: {
                // Include a dollar sign in the ticks
                callback: percent_ticks,
            },
        },
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    size: 16,
                },
            },
        },
    },
};

function copy_bar_chart_options() {
    const options = JSON.parse(JSON.stringify(BASIC_BAR_OPTIONS));
    options.scales.y.ticks.callback = percent_ticks;
    return options;
}

class ByGradeLevel extends Component {
    render() {
        const bar_chart_options = copy_bar_chart_options();

        bar_chart_options.plugins.legend = {
            display: false,
        };

        bar_chart_options.plugins.tooltip = {};
        bar_chart_options.plugins.tooltip.enabled = false;
        bar_chart_options.plugins.tooltip.external =
            this.props.create_custom_tooltip;

        const labels = [];
        const data = [];

        for (var key in this.props.data) {
            if (
                key == '' ||
                key == 'Kindergarten' ||
                key == 'K' ||
                key == 'PreKindergarten'
            ) {
                continue;
            }

            const int_key = parseInt(key);

            if (isNaN(int_key)) {
                continue;
            }

            labels.push(int_key);
        }

        const sorted_labels = labels.sort((a, b) => (a < b ? -1 : 1));

        for (var key of sorted_labels) {
            let percent = 0;
            if (this.props.data[key]) {
                percent = parseInt(
                    (this.props.data[key].count / this.props.data[key].total) *
                        100
                );
            } else {
                console.log('oops');
            }

            data.push(percent);
        }

        const ctx = document.getElementById('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(252,127,83,.5)');
        gradient.addColorStop(0.8, 'rgba(252,127,83,0)');
        gradient.addColorStop(1, 'rgba(252,127,83,0)');

        return (
            <Line
                data={{
                    labels: sorted_labels,
                    datasets: [
                        {
                            data,
                            fill: {
                                target: 'origin',
                                above: gradient, // Area will be red above the origin
                            },
                            pointBackgroundColor: '#FC7F53',
                            pointBorderColor: 'rgba(252, 127, 83, .5)',
                            pointBorderWidth: 4,
                            borderColor: '#FC7F53',
                            tension: 0.4,
                        },
                    ],
                }}
                options={bar_chart_options}
                style={{ maxHeight: '420px' }}
            />
        );
    }
}

class ByRace extends Component {
    render() {
        const bar_chart_options = copy_bar_chart_options();
        bar_chart_options.plugins.legend = {
            display: false,
        };

        bar_chart_options.plugins.tooltip = {};
        bar_chart_options.plugins.tooltip.enabled = false;
        bar_chart_options.plugins.tooltip.external =
            this.props.create_custom_tooltip;

        const labels = [];
        const data = [];

        for (const key in this.props.data) {
            if (!key) {
                continue;
            }

            labels.push(key);

            const percent = parseInt(
                (this.props.data[key].count / this.props.data[key].total) * 100
            );
            data.push(percent);
        }

        return (
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor: '#93A1FD',
                            borderRadius: 5,
                            borderSkipped: false,
                        },
                    ],
                }}
                options={bar_chart_options}
                style={{ maxHeight: '340px' }}
            />
        );
    }
}

class ByELI extends Component {
    render() {
        const bar_chart_options = copy_bar_chart_options();
        bar_chart_options.plugins.legend = {
            display: false,
        };

        bar_chart_options.plugins.tooltip = {};
        bar_chart_options.plugins.tooltip.enabled = false;
        bar_chart_options.plugins.tooltip.external =
            this.props.create_custom_tooltip;

        const labels = [];
        const data = [];

        for (const key in this.props.data) {
            if (!key || key == 'To Be Determined') {
                continue;
            }

            labels.push(key);

            const percent = parseInt(
                (this.props.data[key].count / this.props.data[key].total) * 100
            );
            data.push(percent);
        }

        return (
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor: '#3878F9',
                            borderRadius: 5,
                            borderSkipped: false,
                        },
                    ],
                }}
                options={bar_chart_options}
                style={{ maxHeight: '340px' }}
            />
        );
    }
}

class BySpecialEd extends Component {
    render() {
        const bar_chart_options = copy_bar_chart_options();
        bar_chart_options.plugins.legend = {
            display: false,
        };

        bar_chart_options.plugins.tooltip = {};
        bar_chart_options.plugins.tooltip.enabled = false;
        bar_chart_options.plugins.tooltip.external =
            this.props.create_custom_tooltip;

        const labels = [];
        const data = [];

        for (const key in this.props.data) {
            if (key && key == 'true') {
                labels.push('Yes');
            } else {
                labels.push('No');
            }

            const percent = parseInt(
                (this.props.data[key].count / this.props.data[key].total) * 100
            );
            data.push(percent);
        }

        return (
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor: '#FC7F53',
                            borderRadius: 5,
                            borderSkipped: false,
                        },
                    ],
                }}
                options={bar_chart_options}
                style={{ maxHeight: '340px' }}
            />
        );
    }
}

class BySchool extends Component {
    render() {
        const bar_chart_options = copy_bar_chart_options();
        bar_chart_options.plugins.legend = {
            display: false,
        };

        bar_chart_options.plugins.tooltip = {};
        bar_chart_options.plugins.tooltip.enabled = false;
        bar_chart_options.plugins.tooltip.external =
            this.props.create_custom_tooltip;

        bar_chart_options.indexAxis = 'y';
        delete bar_chart_options.scales.y.ticks;
        bar_chart_options.scales.x.ticks.callback = percent_ticks;

        const school_list = [];
        for (const key in this.props.data) {
            const percent = parseInt(
                (this.props.data[key].count / this.props.data[key].total) * 100
            );

            school_list.push({
                school: key,
                count: percent,
            });
        }
        const sorted_school_list = school_list.sort((a, b) =>
            a.count >= b.count ? -1 : 1
        );

        const labels = [];
        const data = [];

        for (const item of sorted_school_list) {
            labels.push(item.school);
            data.push(item.count);

            if (data.length >= 10) {
                break;
            }
        }

        return (
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            axis: 'y',
                            data,
                            backgroundColor: '#47E3FA',
                            borderRadius: 5,
                            borderSkipped: false,
                        },
                    ],
                }}
                options={bar_chart_options}
                style={{ maxHeight: '340px' }}
            />
        );
    }
}

export default class StudentDemographics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            loading: false,

            demographic_data: {},
        };

        this.get_students_by_demographic =
            this.get_students_by_demographic.bind(this);
        this.get_students_by_demographic_callback =
            this.get_students_by_demographic_callback.bind(this);
        this.create_custom_tooltip = this.create_custom_tooltip.bind(this);
    }

    componentDidMount() {
        this.get_students_by_demographic();
    }

    get_students_by_demographic() {
        const data = {
            filters: this.props.filters.direct,
            compare_filters: {},

            question_id: this.props.filters.student_list.question_id,
            answer: this.props.filters.student_list.question_answer,

            category: this.props.filters.student_list.category.toLowerCase(),
            proficiency: this.props.filters.student_list.proficiency,
        };

        ajaxWrapper(
            'POST',
            `/reporting/results_explorer/student_profiles_by_demographic/${this.props.district_id}/`,
            data,
            this.get_students_by_demographic_callback
        );
    }

    get_students_by_demographic_callback(result) {
        this.setState({
            demographic_data: result.demographic_data,
            loading: false,
            loaded: true,
        });
    }

    create_custom_tooltip(context) {
        var tooltip_text = student_list_header_text(this.props.filters).percent;

        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            const container = document.getElementsByClassName(
                'reporting-dashboard'
            )[0];
            container.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        let field = tooltipModel.title[0];
        if (!isNaN(parseFloat(field))) {
            if (field == '1') {
                field = '1st grade students';
            }
            if (field == '2') {
                field = '2nd grade students';
            }
            if (field == '3') {
                field = '3rd grade students';
            } else {
                field += 'th grade students';
            }
        } else if (['Yes', 'No'].indexOf(field) > -1) {
            if (field == 'Yes') {
                field = 'students in special ed';
            } else {
                field = 'students not in special ed';
            }
        } else {
            field += ' students';
        }

        var tooltip_text = tooltip_text.replace('students', field);
        const percent = tooltipModel.body[0].lines[0];

        const position = context.chart.canvas.getBoundingClientRect();
        // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

        let final_left = 0;
        let caret_left = 25;
        const total_width = window.innerWidth;
        const total_left =
            position.left + window.pageXOffset + tooltipModel.caretX - 40;
        const overlap = total_width - (total_left + 400);
        if (overlap < -1) {
            final_left = `${total_left + overlap - 100}px`;
            caret_left = 25 - overlap;
        } else {
            final_left = `${total_left}px`;
        }

        // Set caret Position
        const caret = `<div class="tooltip-bottom-arrow" style="left: ${caret_left}px;"></div>`;
        tooltipEl.innerHTML = `<div class="tooltip-wrapper"><div class="tooltip-text" style="display:block;"><div class="h2">${percent}${tooltip_text}</div>${caret}</div></div>`;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.top = `${
            position.top + window.pageYOffset + tooltipModel.caretY - 15
        }px`;
        tooltipEl.style.left = final_left;

        // tooltipEl.style.font = bodyFont.string;
        tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
        tooltipEl.style.pointerEvents = 'none';
    }

    render() {
        const header_text = student_list_header_text(this.props.filters);

        let header = null;
        if (header_text.title) {
            header = (
                <div className="col-12">
                    <div className="result-display-card">
                        <div className="h1 bold header">
                            {header_text.title}
                        </div>
                    </div>
                </div>
            );
        }

        const content = (
            <div className="row">
                {header}

                <div className="col-12">
                    <div className="result-display-card">
                        <div className="h2 bold header">
                            Results by grade level
                        </div>
                        <ByGradeLevel
                            data={this.state.demographic_data.grade}
                            tooltip_text={header_text.percent}
                            create_custom_tooltip={this.create_custom_tooltip}
                        />
                    </div>
                </div>

                <div className="col-12 row">
                    <div className="col-6" style={{ padding: '0px' }}>
                        <div className="result-display-card">
                            <div className="h2 bold header">
                                Results by Race/Ethnicity
                            </div>
                            <ByRace
                                data={this.state.demographic_data.race}
                                tooltip_text={header_text.percent}
                                create_custom_tooltip={
                                    this.create_custom_tooltip
                                }
                            />
                        </div>
                    </div>

                    <div className="col-6" style={{ padding: '0px' }}>
                        <div className="result-display-card">
                            <div className="h2 bold header">
                                Students by ELI Status
                            </div>
                            <ByELI
                                data={this.state.demographic_data.ell_status}
                                tooltip_text={header_text.percent}
                                create_custom_tooltip={
                                    this.create_custom_tooltip
                                }
                            />
                        </div>
                    </div>

                    <div className="col-6" style={{ padding: '0px' }}>
                        <div className="result-display-card">
                            <div className="h2 bold header">
                                Students by Special Ed
                            </div>
                            <BySpecialEd
                                data={this.state.demographic_data.special_ed}
                                tooltip_text={header_text.percent}
                                create_custom_tooltip={
                                    this.create_custom_tooltip
                                }
                            />
                        </div>
                    </div>

                    <div className="col-6" style={{ padding: '0px' }}>
                        <div className="result-display-card">
                            <div className="h2 bold header">
                                Students by School
                            </div>
                            <BySchool
                                data={this.state.demographic_data.school__name}
                                tooltip_text={header_text.percent}
                                create_custom_tooltip={
                                    this.create_custom_tooltip
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <Wrapper
                loaded={!this.state.loading && this.state.loaded}
                style={{ marginTop: '0px' }}
            >
                {content}
            </Wrapper>
        );
    }
}
