import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { EmptyModal, Icon, Alert } from 'library';

import PercentDisplay from 'pages/reports/results_explorer/components/percent_display.js';
import WayToGetAnswerDropDown from 'pages/reports/results_explorer/components/way_to_get_answer.js';
import FindingAnswer from 'pages/reports/results_explorer/components/finding_answer';

class Answer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,

            description: this.props.description,
            is_correct: this.props.is_correct,
            id: this.props.id,
            show_finding_answer: false,
        };

        this.toggle_details = this.toggle_details.bind(this);
        this.submit_form = this.submit_form.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
    }

    toggle_details() {
        this.setState({ show_details: !this.state.show_details });
    }

    submit_form(state) {
        console.log('Submit Form', state);
        let url = '/api/home/questionanswer/';
        if (this.state.id) {
            url += `${this.state.id}/`;
        }

        const data = {
            question: this.props.question_id,
            answer: this.props.answer,
            is_correct: state.is_correct,
            description: state.description,
        };

        ajaxWrapper('POST', url, data, this.submit_callback);
    }

    submit_callback(result) {
        this.setState({
            description: result[0].questionanswer.description,
            is_correct: result[0].questionanswer.is_correct,
            edit: false,
        });
    }

    render() {
        const { question_id } = this.props;

        let icon = <Icon icon="times" style={{ color: '#FF6262' }} />;
        if (this.state.is_correct) {
            icon = <Icon icon="check" style={{ color: '#4DD293' }} />;
        }

        let description = null;
        if (this.state.description) {
            description = (
                <WayToGetAnswerDropDown
                    key={`${question_id}_waytoget`}
                    text={this.state.description}
                />
            );
        }
        // REMOVING THE COMMENT BELOW WILL ALLOW TEACHERS
        // TO SEND EMAILS TO US FOR WAYS TO GET AN ANSWER
        // else {
        //     description = (
        //         <div
        //             onClick={() => this.setState({ show_finding_answer: true })}
        //             style={{
        //                 cursor: 'pointer',
        //                 color: 'blue',
        //                 textDecoration: 'underline',
        //             }}
        //         >
        //             Have a way to get answer?
        //         </div>
        //     );
        // }

        return (
            <>
                {this.state.show_finding_answer && (
                    <EmptyModal
                        show
                        onHide={() =>
                            this.setState({ show_finding_answer: false })
                        }
                    >
                        <FindingAnswer
                            question_id={question_id}
                            answer={this.props.answer}
                            image={this.props.image}
                        />
                    </EmptyModal>
                )}
                <tr>
                    <td>
                        <div>{this.props.answer}</div>
                    </td>
                    <td>
                        <PercentDisplay
                            percent={this.props.percent}
                            diff={null}
                            style={{
                                fontWeight: 500,
                                textDecoration: 'underline',
                                color: '#0000EE',
                            }}
                            update_filters={this.props.update_filters}
                            data={{
                                question_id,
                                question_answer: this.props.answer,
                            }}
                        />
                    </td>
                    {/* <td>{icon}</td> */}
                    <td style={{ textAlign: 'left' }}>{description}</td>
                </tr>
            </>
        );
    }
}

export default class ByAnswer extends Component {
    render() {
        const data = this.props.question_responses;
        const grouped_responses =
            data.answers_details.total_responses -
            data.answers_details.ungrouped_responses;

        const headers = (
            <tr>
                <th width="20">Answer</th>
                <th width="20">Percent Answered</th>
                {/* <th width='20' >Correct</th> */}
                <th width="40" style={{ textAlign: 'left' }}>
                    Ways to get answer
                </th>
            </tr>
        );

        const content = null;
        const question_id = this.props.question.id;

        const answers = [];
        if (data && data.answers) {
            for (const answer of data.answers.slice(0, 20)) {
                answers.push(
                    <Answer
                        update_filters={this.props.update_filters}
                        question_id={question_id}
                        id={answer.id}
                        answer={answer.text}
                        description={answer.description}
                        image={this.props.question.image}
                        percent={parseInt(
                            (answer.responses / grouped_responses) * 100
                        )}
                        diff={null}
                        is_correct={answer.is_correct}
                    />
                );
            }
        }

        let validated_alert = null;
        if (data.answers_details.ungrouped_responses > 0) {
            const grouped_percent = parseInt(
                (grouped_responses / data.answers_details.total_responses) * 100
            );

            validated_alert = (
                <div style={{ display: 'inline-block', float: 'right' }}>
                    <Alert
                        type="warning"
                        text={`${grouped_percent}% have been validated`}
                    />
                </div>
            );
        }

        return (
            <div className="result-display-card">
                {validated_alert}
                <div className="h2 bold header">{this.props.overview}</div>

                <h5>
                    {`Correct Answer is ${this.props.question.correct_answer} or equivalent`}
                </h5>

                <table className="table student-answer-table">
                    {headers}
                    {answers}
                </table>
            </div>
        );
    }
}
