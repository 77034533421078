import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    FormWithChildren,
    Select,
    TextInput,
    Wrapper,
    Alert,
    NumberInput,
} from 'library';
import HTML_COLORS from 'base/colors.js';

class EditDistrict extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            district: { name: '' },
        };

        this.load_districts = this.load_districts.bind(this);
        this.get_district = this.get_district.bind(this);

        this.submit = this.submit.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/sis/district/', {}, this.load_districts);
    }

    load_districts(value) {
        const taken_colors = [];
        const taken_numbers = [];

        for (let item of value) {
            const { district } = item;
            if (district.id == this.props.district_id) {
                continue;
            }

            taken_numbers.push(String(district.number));
            taken_colors.push(district.color);
        }

        this.setState(
            {
                taken_numbers,
                taken_colors,
            },
            () => {
                if (!this.props.district_id) {
                    this.setState({ loaded: true });
                } else {
                    ajaxWrapper(
                        'GET',
                        `/api/sis/district/${this.props.district_id}/`,
                        {},
                        this.get_district
                    );
                }
            }
        );
    }

    get_district(result) {
        const { district } = result[0];

        this.setState({ district, loaded: true });
    }

    submit(state, callback) {
        const submit_url = `/api/sis/district/${
            this.props.district_id ? this.props.district_id + '/' : ''
        }`;

        const data = state;

        if (!data.number || isNaN(data.number)) {
            this.setState({
                error: 'Provide A Number!',
                message: '',
            });

            setTimeout(() => {
                callback();
            }, 500);

            return false;
        }
        if (this.state.taken_numbers.indexOf(data.number) > -1) {
            this.setState({
                error: 'That Number Is Taken!',
                message: '',
            });

            setTimeout(() => {
                callback();
            }, 500);

            return false;
        }
        this.setState({
            error: null,
            submit_callback: callback,
        });

        ajaxWrapper('POST', submit_url, data, this.callback);
    }

    callback(value) {
        if ('error' in value) {
            this.setState({ error: value['error'] });
        } else {
            this.setState({
                message: 'Saved Successfully',
                error: '',
            });

            this.state.submit_callback();
        }
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        var content = null;

        if (this.state.loaded) {
            let title = 'Add New District';
            if (this.props.district_id) {
                title = `Edit District: ${this.state.district.name}`;
            }

            const color_options = [];
            for (const key in HTML_COLORS) {
                if (this.state.taken_colors.indexOf(key) > -1) {
                    continue;
                }

                color_options.push({
                    text: key,
                    value: key,
                });
            }

            let error = null;
            let message = null;
            if (this.state.error) {
                error = <Alert text={this.state.error} type="danger" />;
            }
            if (this.state.message) {
                message = <Alert text={this.state.message} type="success" />;
            }

            let name_input = (
                <Alert
                    type="warning"
                    text="You cannot change the name of an integrated District"
                />
            );
            if (
                !this.state.district.classlink_id &&
                !this.state.district.clever_id
            ) {
                name_input = <TextInput name="name" label="Name" />;
            }

            var content = (
                <div className="container">
                    <Button
                        type="link"
                        text="< Back to Districts"
                        href="/districts/"
                    />
                    <h2>{title}</h2>
                    {error}
                    {message}
                    <FormWithChildren
                        submit={this.submit}
                        defaults={this.state.district}
                    >
                        {name_input}

                        <TextInput
                            name="proctor_url_name"
                            label="Proctor URL Name (No spaces or special characters)"
                        />

                        <NumberInput name="number" label="Number" />
                        <Select
                            options={color_options}
                            name="color"
                            label="Color"
                        />
                    </FormWithChildren>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default EditDistrict;
