import { updateHistory, filters_have_changed } from 'functions';

export default function save_filter_details(page, filters) {
    const timestamp = Date.now();
    let last_timestamp = timestamp;
    if (window.history.state && window.history.state.navigation_timestamp) {
        last_timestamp = window.history.state.navigation_timestamp;
    }

    filters.timestamp = timestamp;
    const data = {
        page,
        filters,
    };

    if (!window.history.state) {
        data.navigation_timestamp = timestamp;
        var deep_copy_data = JSON.parse(JSON.stringify(data));
        var uri = `/report_navigator/results_explorer/${filters.district}/?timestamp=${data.navigation_timestamp}`;
        updateHistory(deep_copy_data, uri);
    } else if (
        filters_have_changed(
            window.history.state.filters.student_list,
            data.filters.student_list
        ) ||
        filters_have_changed(
            window.history.state.filters.question_details,
            data.filters.question_details
        ) ||
        window.history.state.page != data.page
    ) {
        data.navigation_timestamp = last_timestamp;
        var deep_copy_data = JSON.parse(JSON.stringify(data));
        var uri = `/report_navigator/results_explorer/${filters.district}/?timestamp=${data.navigation_timestamp}`;
        updateHistory(deep_copy_data, uri);
    } else if (
        filters_have_changed(
            window.history.state.filters.direct,
            data.filters.direct
        ) ||
        filters_have_changed(
            window.history.state.filters.compare,
            data.filters.compare
        )
    ) {
        data.navigation_timestamp = timestamp;
        var deep_copy_data = JSON.parse(JSON.stringify(data));
        var uri = `/report_navigator/results_explorer/${filters.district}/?timestamp=${data.navigation_timestamp}`;
        updateHistory(deep_copy_data, uri, true);
    }

    return timestamp;
}
