function anon_district_text(district) {
    let text_only = '';

    if (String(district.number) != '0') {
        text_only += `D${  district.number}`;
    }
    if (district.color != 'White') {
        if (text_only) {
            text_only += ' : ';
        }

        text_only += district.color;
    }

    if (!text_only) {
        return 'No Anonymous Identifier';
    }

    return text_only;
}

export default anon_district_text;
