import React, { Component } from 'react';
import { Icon } from 'library';

export default class ListControls extends Component {
    render() {
        const offset_change = this.props.change;

        var list_controls = null;
        let prev = (
            <div className="previous">
                <Icon size={1} icon="chevron-left light-purple-text" />
            </div>
        );
        if (this.props.offset > 0) {
            prev = (
                <div
                    className="previous"
                    onClick={() =>
                        this.props.change_offset(-1, -1 * offset_change)
                    }
                >
                    <Icon size={1} icon="chevron-left purple-text" />
                </div>
            );
        }

        let next = (
            <div
                className="next"
                onClick={() => this.props.change_offset(-1, offset_change)}
            >
                <Icon size={1} icon="chevron-right purple-text" />
            </div>
        );

        let page_max = this.props.offset + offset_change;

        if (this.props.student_count < page_max) {
            page_max = this.props.student_count;
            next = (
                <div className="next">
                    <Icon size={1} icon="chevron-right light-purple-text" />
                </div>
            );
        }

        const pages = [];
        if (this.props.offset == 0) {
            pages.push(<div className="list-page active">1</div>);
        } else {
            pages.push(
                <div
                    className="list-page"
                    onClick={() => this.props.change_offset(0)}
                >
                    1
                </div>
            );
        }
        for (const shift of [-1, 0, 1]) {
            const change = shift * offset_change;
            var new_index = this.props.offset + change;
            if (new_index > 0 && new_index < this.props.student_count) {
                const page = parseInt(new_index / offset_change) + 1;

                if (shift == 0) {
                    pages.push(<div className="list-page active">{page}</div>);
                } else {
                    pages.push(
                        <div
                            className="list-page"
                            onClick={() => this.props.change_offset(new_index)}
                        >
                            {page}
                        </div>
                    );
                }
            }
        }

        // If you are on the first page, and a third page exists, add it to the list
        if (this.props.offset == 0 && this.props.student_count > (page_max + offset_change)) {
            pages.push(
                <div
                    className="list-page"
                    onClick={() => this.props.change_offset(offset_change * 2)}
                >
                    3
                </div>
            );
        }

        let context_rows = this.props.context_rows ? this.props.context_rows : 0;
        let context_offset = 0;
        if (this.props.offset > 0){
            context_offset = this.props.context_rows;
        }

        var list_controls = (
            <div
                className="list-controls"
                style={{ position: 'relative', margin: '20px 10px' }}
            >
                <div style={{ position: 'absolute', top: '0px', left: '0px' }}>
                    {this.props.offset + 1 - context_offset} - {page_max - context_rows} out of{' '}
                    {this.props.student_count - context_rows}.
                </div>
                <div style={{ textAlign: 'center' }}>
                    {prev}
                    {pages}
                    {next}
                </div>
            </div>
        );

        return list_controls;
    }
}
