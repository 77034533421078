import React from 'react';

import { Image, PageBreak } from 'library';

const MCLExplained = data => {
    return (
        <div
            style={{
                textAlign: 'center',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                className="h1"
                style={Object.assign({
                    marginBottom: '20px',
                    textAlign: 'center',
                    fontSize: '26px',
                })}
            >
                Wait... What Makes A Good Math Explanation?
            </div>
            <div style={{ margin: '10px 50px', fontSize: '16px' }}>
                The MCL framework, briefly summarized below, identifies evidence
                of three key components of a good math explanation - math,
                context, and logic. Using this can help highlight potential
                opportunities for growth and next steps in your classroom.
            </div>
            <div style={{ textAlign: 'center' }}>
                <div className="mcl_explain_card">
                    <div className="mcl_explain_card_header">
                        <div style={{ width: '60px' }}>
                            <Image src={'/static/images/mcl_math.png'} />
                        </div>
                        <div>M</div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '70%',
                        }}
                    >
                        <div
                            className="h1"
                            style={{ textDecoration: 'underline' }}
                        >
                            Math
                        </div>
                        <div>
                            Students are clearly telling us what math they did
                        </div>
                    </div>
                </div>
                <div className="mcl_explain_card">
                    <div className="mcl_explain_card_header">
                        <div style={{ width: '60px' }}>
                            <Image src={'/static/images/mcl_context.png'} />
                        </div>
                        <div>C</div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '70%',
                        }}
                    >
                        <div
                            className="h1"
                            style={{ textDecoration: 'underline' }}
                        >
                            Context
                        </div>
                        <div style={{ alignSelf: 'left' }}>
                            Students are clearly connecting back to the
                        </div>{' '}
                        <div style={{ alignSelf: 'left' }}>
                            situational context of the problem
                        </div>
                    </div>
                </div>
                <div className="mcl_explain_card">
                    <div className="mcl_explain_card_header">
                        <div style={{ width: '60px' }}>
                            <Image src={'/static/images/mcl_logic.png'} />
                        </div>
                        <div>L</div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '70%',
                        }}
                    >
                        <div
                            className="h1"
                            style={{ textDecoration: 'underline' }}
                        >
                            Logic
                        </div>
                        <div style={{ alignSelf: 'left' }}>
                            Students are clearly explaining why they did the
                            math
                        </div>
                        <div style={{ alignSelf: 'left' }}>
                            Students are explaining the meaning of the
                            quantities
                        </div>
                    </div>
                </div>
            </div>

            <PageBreak style={{ margin: '60px 0px 100px' }} />
        </div>
    );
};

export default MCLExplained;
