import React, { Component } from 'react';

import proctor_rendering from 'pages/reports/results_explorer/functions/proctor_rendering.js';

export default class ProctorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { details } = this.props;
        const { assessment } = this.props.student_assessment;

        const question_lookup = {};
        for (const item of assessment.questions_on_assessments) {
            const { question } = item.questiononassessment;
            question_lookup[question.id] = question;
        }

        const rows = proctor_rendering(details, question_lookup);

        const content = (
            <table className="table">
                <tr>
                    <th width="20%">Type</th>
                    <th width="20%">Question</th>
                    <th width="60%">Events</th>
                </tr>

                {rows}
            </table>
        );

        return <div className="result-display-card">{content}</div>;
    }
}
