import React, { Component } from 'react';

import { Button, Wrapper } from 'library';

import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';

class Response extends Component {
    constructor(props) {
        super(props);
        const { response } = this.props;
        const { anchor } = response;
        this.state = { anchor };
        this.toggle_anchor = this.toggle_anchor.bind(this);
        this.submit_anchor = this.submit_anchor.bind(this);
        this.change_wot = this.change_wot.bind(this);
    }

    toggle_anchor() {
        const { anchor } = this.state;
        this.setState({ anchor: !anchor });
        this.submit_anchor(!anchor);
        //
    }

    submit_anchor(anchor_status) {
        const { response } = this.props;
        const { id } = response;
        ajaxWrapper('POST', `/api/home/studentquestionresponse/${id}/`, {
            anchor: anchor_status,
        });
    }

    change_wot(id) {
        const { response } = this.props;
        const { id: idResponse } = response;
        ajaxWrapper(
            'POST',
            `/api/home/studentquestionresponse/${idResponse}/`,
            { way_of_thinking: id },
            alert
        );
    }

    render() {
        const { response } = this.props;
        let { answer } = response;
        answer = answer.split('\\n').join('<br/>');

        let anchor = (
            <Button
                type="primary"
                text="Make Anchor"
                onClick={this.toggle_anchor}
            />
        );
        const { anchor: anchor_status } = this.state;
        if (anchor_status) {
            anchor = (
                <Button type="danger" text="X" onClick={this.toggle_anchor} />
            );
        }

        const to_na = (
            <Button
                type="danger"
                deleteType
                text="Move To NA"
                onClick={() =>
                    this.change_wot('dfe97bb9-b8f5-47a4-9cdd-986254f5a1c3')
                }
            />
        );
        const { response: responseProps } = this.props;
        return (
            <div
                className="col-3"
                style={{ padding: '10px', border: '1px solid black' }}
            >
                <p>
                    <b>
                        {responseProps.non_explanation_answer}:{' '}
                        {responseProps.mcl_score}
                    </b>
                </p>
                <p dangerouslySetInnerHTML={{ __html: answer }} />
                {anchor}

                {to_na}
            </div>
        );
    }
}

class AssignAnchor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            responses: [],
            question: {},
            m: 'M',
            c: 'C',
            l: 'L',
            ne_na: '',
        };

        this.question_callback = this.question_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.response_callback = this.response_callback.bind(this);
    }

    componentDidMount() {
        const { question_id } = this.props;
        ajaxWrapper(
            'GET',
            `/api/home/question/${question_id}/`,
            {},
            this.question_callback
        );
        this.refresh();
    }

    setGlobalState(name, state) {
        if (state.m || state.c || state.l || state.ne_na) {
            this.setState({ loaded: false });
            this.setState(state, this.refresh);
        } else {
            this.setState(state);
        }
    }

    refresh() {
        const { ne_na, m, c, l } = this.state;
        const { way_of_thinking_id } = this.props;
        let mcl_score = ne_na;
        if (!mcl_score) {
            mcl_score = m + c + l;
        }
        ajaxWrapper(
            'GET',
            `/api/home/studentquestionresponse/?way_of_thinking=${way_of_thinking_id}`,
            {},
            this.response_callback
        );
    }

    question_callback(result) {
        const { question } = result[0];
        this.setState({ question });
    }

    response_callback(result) {
        const responses = result.map(
            response => response.studentquestionresponse
        );

        sort_objects(responses, ['response_length']);
        this.setState({ responses, loaded: true });
    }

    render() {
        const { ne_na, m, c, l, responses: responsesState } = this.state;
        let mcl_score = ne_na;
        if (!mcl_score) {
            mcl_score = m + c + l;
        }
        const responses = responsesState
            .filter(response => !!response && response.answer)
            .map(response => <Response response={response} />);

        const { question } = this.state;
        const content = (
            <div>
                <div className="container" style={{ padding: '10px' }}>
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: question.name,
                        }}
                    />
                    <p>Number of Responses: {responses.length}</p>
                    <div className="row">{responses}</div>
                </div>
            </div>
        );
        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default AssignAnchor;
