import React, { Component } from 'react';
import { resolveVariables, sort_objects } from 'functions';
import ajaxWrapper from 'base/ajax.js';
import { ClickableContent } from 'library';

// Example
// var gender =  {'value':'','name':'gender','label':'Gender','options':[{'value':'Pick One','text':'Pick One'}, {'value':'Male', 'text':'Male'}, {'value':'Female','text':'Female'},{'value':'Other','text':"I don't identify as either"}]}

const BOOLEANS = [
    { text: '', value: '' },
    { text: 'Yes', value: true },
    { text: 'No', value: false },
];

class Select extends Component {
    static component_name = 'Select';

    /**
     * JSX Component to render a form select input
     *
     * @param {object} props
     * @param {string} props.name
     * @param {string|array} props.value
     * @param {string} props.defaultoption
     * @param {string} props.label
     * @param {string} props.optionsUrl
     * @param {object} props.optionsUrlMap
     * @param {object} props.options
     * @param {bool} props.boolean
     * @param {bool} props.multiple
     * @param {bool} props.radio
     * @param {string} props.radio_type
     * @param {string} props.layout
     * @param {string} props.label_style
     * @param {string} props.button_style
     * @param {bool} props.no_blank_option
     * @param {bool} props.hide_select
     * @param {string} props.className
     * @param {bool} props.sneaky_input
     * @param {(newValue) => void} props.onChange optional callback called when value changes
     * @returns
     */
    constructor(props) {
        super(props);
        this.state = { options: [] };

        this.optionsCallback = this.optionsCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeSelection = this.removeSelection.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.check_value_prop = this.check_value_prop.bind(this);
        this.update_options = this.update_options.bind(this);
        this.get_value = this.get_value.bind(this);

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.update_options();
        this.refreshData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.optionsUrl != prevProps.optionsUrl) {
            this.refreshData();
        }

        let optionDict = this.props.options;

        if (this.props.boolean) {
            optionDict = BOOLEANS;
        } else if (!this.props.options) {
            optionDict = this.state.options;
        }
        if (optionDict != this.state.options) {
            this.setState({ options: optionDict });
        }
    }

    update_options() {
        let optionDict = this.props.options;
        if (this.props.boolean) {
            optionDict = BOOLEANS;
        } else if (!this.props.options) {
            optionDict = this.state.options;
        }
        if (optionDict != this.state.options) {
            this.setState({ options: optionDict });
        }
    }

    check_value_prop() {
        let value = this.get_value();
        const has_variable_inside =
            String(value).indexOf('{') > -1 && String(value).indexOf('}') > -1;
        const has_list_inside =
            String(value).indexOf('[') > -1 && String(value).indexOf(']') > -1;

        if (
            this.props.multiple &&
            typeof value === 'string' &&
            (has_variable_inside || has_list_inside)
        ) {
            try {
                const newState = {};
                value = JSON.parse(value);

                if (
                    value.length > 0 &&
                    typeof Object.keys(value[0]) !== 'string' &&
                    this.props.optionsUrlMap
                ) {
                    const new_value = [];
                    for (const index in value) {
                        const resolved_value = resolveVariables(
                            this.props.optionsUrlMap,
                            value[index]
                        );
                        new_value.push(resolved_value.value);
                    }
                    value = new_value;
                }

                newState[this.props.name] = value;
                this.props.setFormState(newState);
            } catch (e) {
                console.log(e);
            }
        }
    }

    refreshData() {
        if (this.props.optionsUrl && this.props.optionsUrl != '') {
            // Subscribe to all variables in optionsUrl
            window.cmState.subscribe(this, this.props.optionsUrl);

            const { options_url } = resolveVariables(
                { options_url: this.props.optionsUrl },
                window.cmState.getGlobalState()
            );
            ajaxWrapper(
                'GET',
                options_url,
                {},
                this.optionsCallback.bind(this)
            );
        }
    }

    optionsCallback(value) {
        const options = [];
        for (const index in value) {
            let textValue = value[index];
            let valueValue = value[index];

            if (this.props.optionsUrlMap) {
                const resolvedValue = resolveVariables(
                    this.props.optionsUrlMap,
                    textValue
                );
                textValue = resolvedValue.text;
                valueValue = resolvedValue.value;
            }
            options.push({ text: textValue, value: valueValue });
        }

        sort_objects(options, ['text']);

        this.setState({ options });
    }

    get_value() {
        let { value } = this.props;
        if (this.props.multiple && typeof value === 'undefined') {
            value = [];
        }

        return value;
    }

    handleChange = e => {
        const selection = e.target.value;
        const newState = {};

        if (this.props.multiple == true) {
            const value = JSON.parse(JSON.stringify(this.get_value()));

            const index = value.indexOf(selection);
            if (!selection) {
            } else if (index == -1) {
                value.push(selection);
            } else {
                value.splice(index, 1);
            }

            newState[this.props.name] = value;
        } else if (this.props.boolean) {
            // If the select is designated as a boolean, we need to convert the value
            if (selection == 'true') {
                newState[this.props.name] = true;
            } else {
                newState[this.props.name] = false;
            }
        } else {
            newState[this.props.name] = selection;
        }

        this.props.setFormState(newState);

        if (this.props.multiple && !this.props.radio) {
            this.ref.current.value = '';
        }
        if (this.props.onChange) {
            this.props.onChange(newState);
        }
    };

    removeSelection(event, data) {
        const selection = data;
        const value = this.get_value();
        const index = value.indexOf(selection);
        value.splice(index, 1);

        const newState = {};
        newState[this.props.name] = value;
        this.props.setFormState(newState);
    }

    render() {
        let layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var label = null;
        if (this.props.label) {
            var label = (
                <label style={this.props.label_style}>{this.props.label}</label>
            );
        }

        // Check if default value should be used
        if (this.props.multiple == true) {
            var value = this.get_value();

            if (value.length == 0) {
                value = this.props.defaultoption;
            }
        } else {
            var value = String(this.get_value());
            if (value == '' || value == 'undefined') {
                value = this.props.defaultoption;
            }
        }

        const optionDict = this.state.options;
        const options = [];

        // Check if default is inside options and add it if not
        let found_default = null;
        let current_default = this.props.defaultoption;
        if (this.props.boolean) {
            if (current_default == 'true') {
                current_default = true;
            } else {
                current_default = false;
            }
        }

        for (var index in optionDict) {
            if (optionDict[index].value === current_default) {
                found_default = optionDict[index];
            }
        }

        if (this.props.radio) {
            const type = ` btn-${this.props.radio_type || 'outline-secondary'}`;
            const button_style = {
                border: 'thin solid',
                ...this.props.button_style,
            };
            // Render Radio Components
            // Create JSX for select options
            for (var index in optionDict) {
                var active = '';
                const current_option = String(optionDict[index].value);

                if (typeof value === 'undefined') {
                } else if (
                    this.props.multiple &&
                    value.indexOf(current_option) > -1
                ) {
                    active = ' active';
                } else if (value == current_option) {
                    active = ' active';
                }

                options.push(
                    <label
                        className={`btn${active}${type}`}
                        style={button_style}
                    >
                        <input
                            style={{ display: 'none' }}
                            type="radio"
                            name={this.props.name}
                            key={index}
                            value={String(optionDict[index].value)}
                            onClick={this.handleChange}
                        />
                        {optionDict[index].text}
                    </label>
                );
            }

            var select_jsx = <div className="radios">{options}</div>;

            if (this.props.boolean) {
                var active = '';
                let toggle_value = 'true';
                if (value && value != 'false') {
                    active = ' active';
                    toggle_value = 'false';
                }

                select_jsx = (
                    <div className="radios">
                        <label
                            className={`btn${active}${type}`}
                            style={this.props.button_style}
                        >
                            <input
                                style={{ display: 'none' }}
                                type="radio"
                                name={this.props.name}
                                key={index}
                                value={toggle_value}
                                onClick={this.handleChange}
                            />
                            Active
                        </label>
                    </div>
                );
            }
        } else {
            // Render Select Component
            if (!found_default && !this.props.no_blank_option) {
                let selected = {};
                if (this.props.multiple || !value) {
                    selected = { selected: 'selected' };
                }

                options.push(<option key={-1} value="" {...selected} />);
            }

            // Create JSX for select options
            for (var index in optionDict) {
                options.push(
                    <option key={index} value={String(optionDict[index].value)}>
                        {optionDict[index].text}
                    </option>
                );
            }

            var select_jsx = (
                <select
                    className="form-control"
                    name={this.props.name}
                    onChange={this.handleChange}
                    value={value}
                >
                    {options}
                </select>
            );

            var multipleSelections = [];
            if (this.props.multiple == true) {
                const optionsDict = {};
                for (var index in optionDict) {
                    optionsDict[optionDict[index].value] =
                        optionDict[index].text;
                }

                for (var index in value) {
                    multipleSelections.push(
                        <ClickableContent
                            key={`${this.props.name}-${index}`}
                            className="selection"
                            onClick={this.removeSelection}
                            data={value[index]}
                            show_close
                        >
                            <div style={{ display: 'inline-block' }}>
                                {optionsDict[value[index]]}
                            </div>
                        </ClickableContent>
                    );
                }

                select_jsx = (
                    <select
                        ref={this.ref}
                        className="form-control"
                        name={this.props.name}
                        onChange={this.handleChange}
                    >
                        {options}
                    </select>
                );
            }
        }

        let hide_select_style = null;
        if (this.props.hide_select) {
            hide_select_style = {
                display: 'none',
            };
        }

        let content = (
            <div
                className={`form-group multiselect ${this.props.className}`}
                style={this.props.style}
            >
                {label}
                <div style={hide_select_style}>{select_jsx}</div>
                <div>{multipleSelections}</div>
                <div style={{ width: '100%', clear: 'both' }} />
            </div>
        );

        if (this.props.sneaky_input) {
            content = (
                <select
                    className={`sneaky-input ${this.props.className}`}
                    name={this.props.name}
                    onChange={this.handleChange}
                    value={value}
                >
                    {options}
                </select>
            );
        }

        return content;
    }
}

export default Select;
