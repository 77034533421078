import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Alert, Button } from 'library';

class RunAutoAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            responses_scored: null,
            responses_scored_slow: null,
            error: '',
        };

        this.run = this.run.bind(this);
        this.run_callback = this.run_callback.bind(this);

        this.run_slow = this.run_slow.bind(this);
        this.run_callback_slow = this.run_callback_slow.bind(this);
    }

    run() {
        let url = null;

        if (this.props.component['type'] == 'Math') {
            url = `/api/automated_scoring/math_component/${this.props.component['id']}/`;
        } else if (this.props.component['type'] == 'Logic') {
            url = `/api/automated_scoring/logic_component/${this.props.component['id']}/`;
        }

        if (url == null) {
            this.setState({
                error: 'This component does not have a Math/Logic Type assigned. Please do that first.',
            });
        } else {
            this.setState(
                { loading: true },
                ajaxWrapper('POST', url, {}, this.run_callback)
            );
        }
    }

    run_callback(result) {
        if (result['error'] && result['error'] != null) {
            this.setState({
                loading: false,
                error: result['error'],
            });
        } else {
            this.setState({
                loading: false,
                responses_scored: result['responses_scored'],
            });
        }
    }

    run_slow() {
        console.log('Run Slow');
        let url = `/api/automated_scoring/automation_requests/${this.props.component['id']}/`;

        this.setState({ loading: true });

        ajaxWrapper('POST', url, {}, this.run_callback_slow);
    }

    run_callback_slow(result) {
        console.log('Slow Result', result);
        this.setState({
            loading: false,
            responses_scored_slow: result['id'],
        });
    }

    render() {
        let button = (
            <Button
                type="secondary"
                text="Run Auto Analysis"
                onClick={this.run}
            />
        );

        let button_slow = (
            <Button
                type="primary"
                style={{ float: 'right' }}
                text="Run Auto (For Slow Components)"
                onClick={this.run_slow}
            />
        );

        let message = null;

        if (this.state.error != '') {
            button = null;

            message = <Alert type="danger" text={this.state.error} />;
        } else if (this.state.loading) {
            button = null;
            button_slow = null;

            message = (
                <Alert
                    type="warning"
                    text="Analysis is in progress.  DO NOT leave this page."
                />
            );
        } else if (this.state.responses_scored != null) {
            message = (
                <Alert
                    type="success"
                    text={`Analysis completed. ${this.state.responses_scored} responses have been scored`}
                />
            );
        } else if (this.state.responses_scored_slow != null) {
            message = (
                <Alert
                    type="success"
                    text={
                        <p>
                            <a href="/automated_requests_list/">
                                Check this page
                            </a>{' '}
                            to see if its completed. We will also send you an
                            email.
                        </p>
                    }
                />
            );
        }

        return (
            <div className="simple-card">
                {message}
                {button}
                {button_slow}
            </div>
        );
    }
}

export default RunAutoAnalysis;
