import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    TextInput,
    FormWithChildren,
    Wrapper,
} from 'library';


class Response extends Component {
    constructor(props) {
        super(props);

        this.state = {
            qc: this.props.response.review,
            has_been_reviewed: false,
        };
        this.toggle_qc = this.toggle_qc.bind(this);
        this.submit_qc = this.submit_qc.bind(this);
    }

    toggle_qc() {
        this.setState({ qc_modal: !this.state.qc_modal });
        //
    }

    submit_qc(state) {
        ajaxWrapper(
            'POST',
            `/api/home/studentquestionresponse/${this.props.response.id}/`,
            { has_been_reviewed: true, mcl_score: state.mcl_score },
            () => this.setState({ has_been_reviewed: true })
        );
    }

    render() {
        let { answer } = this.props.response;
        answer = answer.split('\\n').join('<br/>');

        let scorer = 'Auto';
        if (this.props.response.who_scored) {
            scorer =
                this.props.response.who_scored.first_name +
                this.props.response.who_scored.last_name;
        }

        let way_of_thinking = 'None';
        if (this.props.response.way_of_thinking.text) {
            way_of_thinking = this.props.response.way_of_thinking.text;
        }

        const change_mcl = (
            <FormWithChildren submit={this.submit_qc}>
                <TextInput
                    name="mcl_score"
                    label="MCL Score"
                    default={this.props.response.mcl_score}
                />
            </FormWithChildren>
        );

        if (this.state.has_been_reviewed) {
            return null;
        }
        return (
            <tr>
                <td>{this.props.response.non_explanation_answer}</td>
                <td dangerouslySetInnerHTML={{ __html: answer }} />
                <td>{scorer}</td>
                <td
                    dangerouslySetInnerHTML={{ __html: way_of_thinking }}
                />
                <td>{this.props.response.mcl_score}</td>
                <td>{this.props.response.review_notes}</td>
                <td>{change_mcl}</td>
            </tr>
        );

    }
}

class MCLQCFix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            responses: [],
            question: {},
            view_all: false,
            user: '',
            m: 'M',
            c: 'C',
            l: 'L',
            ne_na: '',
            word_search: '',
        };

        this.question_callback = this.question_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/studentquestionresponse/?question=${this.props.question_id
            }&review=True&related=who_scored,way_of_thinking&has_been_reviewed=False`,
            {},
            this.question_callback
        );
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    question_callback(result) {
        const responses = [];

        for (const index in result) {
            const response = result[index].studentquestionresponse;
            responses.push(response);
        }

        this.setState({ responses, loaded: true });
    }

    render() {
        const responses = [];
        for (const index in this.state.responses) {
            const response = this.state.responses[index];
            responses.push(<Response response={response} />);
        }

        const content = (
            <div>
                <div className="container" style={{ padding: '10px' }}>
                    <p>Number of Responses: {responses.length}</p>
                    <table className="table">
                        <tr>
                            <th>Answer</th>
                            <th>Explanation</th>
                            <th>Scorer</th>
                            <th>Way of Explanation</th>
                            <th>Current Score</th>
                            <th>Notes</th>
                            <th>Change Score</th>
                        </tr>
                        {responses}
                    </table>
                </div>
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default MCLQCFix;
