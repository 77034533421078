import React, { Component } from 'react';
import { sort_objects } from 'functions';

import { Icon, FormWithChildren, Select, Button } from 'library';

import lookup_object from 'pages/reports/results_explorer/functions/lookup_object.js';

const category_options = [
    { text: 'Knowledge', value: 'knowledge' },
    { text: 'Application', value: 'application' },
    { text: 'Communication', value: 'communication' },
];

const RACE_ETHNICITY_CHOICES = [
    {
        text: 'American Indian or Alaska Native',
        value: 'American Indian or Alaska Native',
    },
    { text: 'Asian', value: 'Asian' },
    { text: 'Black or African American', value: 'Black or African American' },
    { text: 'Filipino', value: 'Filipino' },
    { text: 'Hispanic', value: 'Hispanic' },
    {
        text: 'Native Hawaiian or Other Pacific Islander',
        value: 'Native Hawaiian or Other Pacific Islander',
    },
    { text: 'Two or More Races', value: 'Two or More Races' },
    { text: 'White', value: 'White' },
];

const LEP_CHOICES = [
    { text: 'English Learner', value: 'English Learner' },
    { text: 'English Only', value: 'English Only' },
    {
        text: 'Initially Fluent English Proficient (I-FEP)',
        value: 'Initially Fluent English Proficient (I-FEP)',
    },
    {
        text: 'Redesignated Fluent English Proficient',
        value: 'Redesignated Fluent English Proficient',
    },
    { text: 'To Be Determined', value: 'To Be Determined' },
];

const special_ed_choices = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
];

const hispanic_ethnicity_choices = [
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' },
];

const GRADE_LEVEL_CHOICES = [
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 },
    { text: '11', value: 11 },
    { text: '12', value: 12 },
];

const student_pool_choices = [
    { text: 'Past Students', value: false },
    { text: 'Current Students', value: true },
];

export default class FilterSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'direct',

            direct_options: {
                course_options: [],
                term_options: [],
                assessment_options: [],
                area_options: [],
                cluster_options: [],
                school_options: [],
                teacher_options: [],
                section_options: [],
                period_options: [],
            },

            compare_options: {
                course_options: [],
                term_options: [],
                assessment_options: [],
                area_options: [],
                cluster_options: [],
                school_options: [],
                teacher_options: [],
                section_options: [],
                period_options: [],
            },
        };

        this.update_filters = this.update_filters.bind(this);
        this.refresh_all_options = this.refresh_all_options.bind(this);
        this.refresh_options = this.refresh_options.bind(this);
    }

    componentDidMount() {
        this.refresh_all_options();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const new_filters = JSON.stringify(this.props.filters);
        const old_filters = JSON.stringify(prevProps.filters);

        if (new_filters != old_filters) {
            this.refresh_all_options();
        } else if (prevProps.areas.length != this.props.areas.length) {
            this.refresh_all_options();
        }
    }

    refresh_all_options() {
        const direct_options = this.refresh_options(this.props.filters.direct);
        const compare_options = this.refresh_options(
            this.props.filters.compare
        );

        this.setState({
            direct_options,
            compare_options,
            loaded: true,
        });
    }

    refresh_options(filters) {
        const course_options = [];
        for (var item of this.props.courses) {
            course_options.push({
                text: item.course.name,
                value: item.course.id,
            });
        }

        const term_options = [];
        for (var item of this.props.school_terms) {
            term_options.push({
                text: item.schoolterm.name,
                value: item.schoolterm.id,
            });
        }

        const assessment_options = [];
        for (var item of this.props.assessments) {
            if (
                this.props.assessment_compare ||
                !filters.school_term ||
                filters.school_term == item.assessment.school_term_id
            ) {
                assessment_options.push({
                    text: item.assessment.name,
                    value: item.assessment.id,
                });
            }
        }

        const selected_area = lookup_object(filters.area);
        const selected_cluster = lookup_object(filters.cluster);
        const selected_school = lookup_object(filters.school);
        const selected_teacher = lookup_object(filters.teacher);
        const selected_section = lookup_object(filters.section);

        let district_with_clusters = false;
        if (this.props.areas.length > 0) {
            district_with_clusters = true;
        }

        const area_options = [];
        if (district_with_clusters) {
            area_options.push();

            for (var item of this.props.areas) {
                area_options.push({
                    text: item.area.name,
                    value: item.area.id,
                });
            }

            sort_objects(area_options, ['text']);

            area_options.unshift({
                text: 'All Areas',
                value: '',
            });
        }

        var cluster_options = [
            {
                text: 'Please Select An Area',
                value: '',
            },
        ];
        if (selected_area.id && selected_area.id != '') {
            console.log('Here', selected_area);
            var cluster_options = [];

            for (var item of selected_area.clusters) {
                cluster_options.push({
                    text: item.cluster.name,
                    value: item.cluster.id,
                });
            }

            sort_objects(cluster_options, ['text']);

            cluster_options.unshift({
                text: 'All Clusters',
                value: '',
            });
        }

        var school_options = [
            {
                text: 'Please Select A Cluster',
                value: '',
            },
        ];

        if (selected_cluster.id && selected_cluster.id != '') {
            var school_options = [];
            for (var item of selected_cluster.schools) {
                school_options.push({
                    text: item.school.name,
                    value: item.school.id,
                });
            }

            sort_objects(school_options, ['text']);

            school_options.unshift({
                text: 'All Schools',
                value: '',
            });
        }

        // Additional loop for districts without clusters or principals with multiple schools
        else if (!district_with_clusters || this.props.schools.length > 0) {
            var school_options = [];
            for (var item of this.props.schools) {
                school_options.push({
                    text: item.school.name,
                    value: item.school.id,
                });
            }

            sort_objects(school_options, ['text']);

            // Principals should NOT be able to remove the school filter
            if (!this.props.location_restricted) {
                school_options.unshift({
                    text: 'All Schools',
                    value: '',
                });
            }
        }

        var teacher_options = [
            {
                text: 'Please Select A School',
                value: '',
            },
        ];
        if (selected_school.id && selected_school.id != '') {
            var teacher_options = [];
            for (var item of selected_school.users) {
                teacher_options.push({
                    text: item.districtuser.name,
                    value: item.districtuser.id,
                });
            }

            sort_objects(teacher_options, ['text']);

            teacher_options.unshift({
                text: 'All Teachers',
                value: '',
            });
        }

        var section_options = [
            {
                text: 'Please Select A Teacher',
                value: '',
            },
        ];
        var period_options = [
            {
                text: 'Please Select A Teacher',
                value: '',
            },
        ];
        if (selected_teacher.id && selected_teacher.id != '') {
            var section_options = [];
            var period_options = [];
            const periods = [];

            const selected_term = lookup_object(filters.school_term);
            const start_date = new Date(Date.parse(selected_term.start_date));
            const end_date = new Date(Date.parse(selected_term.end_date));

            if (selected_teacher.sections) {
                for (var item of selected_teacher.sections) {
                    const section_start = new Date(
                        Date.parse(item.section.term.start_date)
                    );
                    const section_end = new Date(
                        Date.parse(item.section.term.end_date)
                    );

                    const start_date_in_term =
                        section_start > start_date && section_start < end_date;
                    const end_date_in_term =
                        section_end > start_date && section_end < end_date;
                    const section_wraps_term =
                        section_start < start_date && section_end > end_date;

                    if (
                        !start_date_in_term &&
                        !end_date_in_term &&
                        !section_wraps_term
                    ) {
                        continue;
                    }
                    console.log(
                        'VALID SECTION',
                        item.section.name,
                        item.section
                    );

                    section_options.push({
                        text: item.section.name,
                        value: item.section.id,
                    });

                    if (periods.indexOf(item.section.period) == -1) {
                        periods.push(item.section.period);
                        period_options.push({
                            text: item.section.period,
                            value: item.section.period,
                        });
                    }
                }
            }

            sort_objects(section_options, ['text']);
            sort_objects(period_options, ['text']);

            section_options.unshift({
                text: 'All Sections',
                value: '',
            });
        }

        const new_state = {
            course_options,
            term_options,
            assessment_options,

            area_options,
            cluster_options,
            school_options,
            teacher_options,
            section_options,
            period_options,
        };

        return new_state;
    }

    update_filters(name, state) {
        this.props.update_filters(name, state);
    }

    render() {
        let comparison_switch = null;

        if (this.state.type === 'compare') {
            comparison_switch = (
                <Button
                    type="warning"
                    text="Click Me To Show Comparison"
                    onClick={this.props.toggle_comparison}
                    style={{ marginBottom: '10px' }}
                ></Button>
            );

            if (this.props.show_percentage) {
                comparison_switch = (
                    <div
                        className="purple-button"
                        onClick={this.props.toggle_comparison}
                        style={{
                            marginBottom: '10px',
                            width: 'auto',
                            display: 'inline-block',
                            padding: '9px 12px',
                        }}
                    >
                        Click Me To Hide Comparison
                    </div>
                );
            }
        }

        const width = this.props.width * this.props.percent_width;
        const header_height = 62;
        const tab_height = 46;
        const content_height =
            this.props.height -
            header_height -
            this.props.filterbar_height -
            tab_height;

        const sidebar_style = {
            width: `${width}px`,
            height: this.props.height - header_height,
            overflow: 'hidden',
            transition: '.2s height',
            zIndex: 10,
            position: 'absolute',
            top: `${this.props.header_height}px`,
            right: '0px',
        };

        let icon = (
            <Icon
                size={1}
                icon_type="fas"
                icon="chevron-up"
                style={{ float: 'right', padding: '18px' }}
            />
        );
        if (!this.props.open) {
            sidebar_style.height = `${this.props.filterbar_height}px`;
            icon = (
                <Icon
                    size={1}
                    icon_type="fas"
                    icon="chevron-down"
                    style={{ float: 'right', padding: '18px' }}
                />
            );
        }

        let type_toggle = null;
        if (this.state.type == 'direct') {
            type_toggle = (
                <div className="row">
                    <div
                        style={{ fontSize: '16px', cursor: 'pointer' }}
                        className="col-6 filter-type purple-text purple-border"
                    >
                        Filter
                    </div>
                    <div
                        onClick={() => this.setState({ type: 'compare' })}
                        style={{ fontSize: '16px', cursor: 'pointer' }}
                        className="col-6 filter-type light-purple-border"
                    >
                        Compare To
                    </div>
                </div>
            );
        } else {
            type_toggle = (
                <div className="row">
                    <div
                        onClick={() => this.setState({ type: 'direct' })}
                        style={{ fontSize: '16px', cursor: 'pointer' }}
                        className="col-6 filter-type light-purple-border"
                    >
                        Filter
                    </div>
                    <div
                        style={{ fontSize: '16px', cursor: 'pointer' }}
                        className="col-6 filter-type purple-text purple-border"
                    >
                        Compare To
                    </div>
                </div>
            );
        }

        const forms = [];
        if (this.state.loaded) {
            const filter_choices = this.props.filtering_choices;

            const race_ethnicity_choices = filter_choices.race
                ? filter_choices.race
                : RACE_ETHNICITY_CHOICES;
            const lep_choices = filter_choices.english
                ? filter_choices.english
                : LEP_CHOICES;
            const grade_level_choices = filter_choices.grade
                ? filter_choices.grade
                : GRADE_LEVEL_CHOICES;

            for (const type of ['direct', 'compare']) {
                let options = null;
                if (type == 'direct') {
                    options = this.state.direct_options;
                } else if (type == 'compare') {
                    options = this.state.compare_options;
                }

                const form_key = `filter-${type}`;
                const form_style = {};
                if (type != this.state.type) {
                    form_style.display = 'none';
                }

                const defaults = this.props.filters[type];

                let area_select = (
                    <Select
                        name="area"
                        label="Area"
                        multiple={false}
                        options={options.area_options}
                        className="col-12"
                    />
                );
                let cluster_select = (
                    <Select
                        name="cluster"
                        label="Cluster"
                        multiple={false}
                        options={options.cluster_options}
                        className="col-12"
                    />
                );
                let school_select = (
                    <Select
                        name="school"
                        label="School"
                        multiple={false}
                        options={options.school_options}
                        className="col-12"
                    />
                );
                let teacher_select = (
                    <Select
                        name="teacher"
                        label="Teacher"
                        multiple={false}
                        options={options.teacher_options}
                        className="col-12"
                    />
                );

                if (this.props.areas.length == 0) {
                    area_select = null;
                    cluster_select = null;
                }

                const exclude_tag = [];
                const { user } = window.cmState;
                if (this.props.location_restricted) {
                    if (user.role == 'Teacher') {
                        area_select = null;
                        cluster_select = null;
                        school_select = null;
                        teacher_select = null;
                    } else if (
                        user.role == 'Principal' ||
                        user.role == 'Staff'
                    ) {
                        area_select = null;
                        cluster_select = null;
                        school_select = (
                            <Select
                                name="school"
                                label="School"
                                multiple={false}
                                options={options.school_options}
                                className="col-12"
                                no_blank_option
                            />
                        );
                    }
                }
                if (type === 'compare') {
                    forms.push(comparison_switch);
                }

                if (
                    this.state.type === 'direct' ||
                    (this.state.type === 'compare' &&
                        this.props.show_percentage)
                ) {
                    forms.push(
                        <FormWithChildren
                            key={form_key}
                            uses_global_dict
                            autoSetGlobalState
                            defaults={defaults}
                            globalStateName={form_key}
                            setGlobalState={this.update_filters}
                            style={form_style}
                        >
                            <div>
                                <div className="h2 bold">Location</div>
                            </div>
                            {area_select}
                            {cluster_select}
                            {school_select}
                            {teacher_select}
                            <Select
                                name="period"
                                label="Period"
                                multiple={false}
                                options={options.period_options}
                                className="col-12"
                            />
                            <Select
                                name="section"
                                label="Section"
                                multiple={false}
                                options={options.section_options}
                                className="col-12"
                            />

                            <div className="h2 bold">Coursework</div>
                            <Select
                                name="school_term"
                                label="Term"
                                multiple={false}
                                options={options.term_options}
                                className="col-12"
                            />
                            <Select
                                name="assessments"
                                label="Assessment"
                                multiple
                                options={options.assessment_options}
                                className="col-12"
                            />

                            <Select
                                name="grade"
                                label="Grade Level"
                                options={grade_level_choices}
                                className="col-12"
                                multiple
                            />
                            <Select
                                name="courses"
                                label="Course"
                                multiple
                                options={options.course_options}
                                className="col-12"
                            />

                            <div className="h2 bold">Demographics</div>
                            <Select
                                name="race"
                                label="Race/Ethnicity"
                                options={race_ethnicity_choices}
                                className="col-12"
                                multiple
                            />
                            <Select
                                name="hispanic_ethnicity"
                                label="Hispanic Ethnicity"
                                options={hispanic_ethnicity_choices}
                                className="col-12"
                            />
                            <Select
                                name="ell_status"
                                label="English Proficiency"
                                options={lep_choices}
                                className="col-12"
                                multiple
                            />
                            <Select
                                name="special_ed"
                                label="Special Education"
                                options={special_ed_choices}
                                className="col-12"
                            />
                        </FormWithChildren>
                    );
                }
            }
        }

        return (
            <div
                style={sidebar_style}
                className="sad filter-sidebar light-purple-border"
            >
                <div
                    style={{
                        cursor: 'pointer',
                        height: `${this.props.filterbar_height - 1}px`,
                        background: 'white',
                    }}
                    onClick={this.props.toggle_open}
                >
                    {icon}
                    <div
                        className="h2 bold"
                        style={{
                            height: `${this.props.filterbar_height}px`,
                            padding: '15px',
                            margin: '0px',
                        }}
                    >
                        Filter & Compare
                    </div>
                </div>

                <div className="divider-line" />

                {type_toggle}

                <div
                    style={{
                        padding: '15px',
                        height: `${content_height}px`,
                        overflow: 'auto',
                    }}
                    className="sad"
                >
                    {forms}
                </div>
            </div>
        );
    }
}
