import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    TextInput,
    Alert,
    FormWithChildren,
    NumberInput,
    Select,
    Wrapper,
} from 'library';

const LANGUAGE_VALUES = ['English', 'Spanish'];

class StudentAnswer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.response) {
            var stud_answer = this.props.response.non_explanation_answer;
            if (stud_answer.startsWith("b'") || stud_answer.startsWith('b"')) {
                var stud_answer = stud_answer.substring(
                    2,
                    stud_answer.length - 1
                );
            }
            let stud_response = this.props.response.answer;
            if (
                stud_response.startsWith("b'") ||
                stud_response.startsWith('b"')
            ) {
                stud_response = stud_response.substring(
                    2,
                    stud_response.length - 1
                );
            }
            stud_response = stud_response.split('\\n').join('<br/>');

            let style = { cursor: 'pointer' };
            if (
                this.props.selected_responses &&
                this.props.selected_responses.indexOf(this.props.response.id) >
                    -1
            ) {
                style = { cursor: 'pointer', backgroundColor: '#eee' };
            }

            // <td>{this.props.response.agreement_score}</td>
            return (
                <tr style={style}>
                    <td dangerouslySetInnerHTML={{ __html: stud_response }} />
                </tr>
            );
        }
        return null;
    }
}

class Explanations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'random',
            offset: 0,
            total: 0,
            explanations: [],
        };

        this.setGlobalState = this.setGlobalState.bind(this);
        this.get_explations = this.get_explations.bind(this);
        this.get_explations_callback = this.get_explations_callback.bind(this);
    }

    componentDidMount() {
        this.get_explations();
    }

    setGlobalState(name, state) {
        this.setState(state, this.get_explations);
    }

    get_explations() {
        let order_by = '';
        if (this.state.order && this.state.order != 'random') {
            order_by = `&order_by=${this.state.order}`;
        }

        const offset = `&offset=${this.state.offset}`;

        let assessment = '';
        if (this.props.assessment) {
            assessment = `&assessment=${this.props.assessment}`;
        }

        const type = `type=${this.props.type}`;

        let unit_filters = '';
        console.log('Unit Filters', this.props.unit_filters);
        if (this.props.unit_filters) {
            unit_filters = `&unit_filters=${this.props.unit_filters}`;
        }

        let context_filters = '';
        if (this.props.context_filters) {
            context_filters = `&context_filters=${this.props.context_filters}`;
        }

        let language_filter = '';
        if (this.props.language) {
            language_filter = `&language=${this.props.language}`;
        }

        ajaxWrapper(
            'GET',
            `/contextTest/${this.props.question_id}/${this.state.offset}/10/?${type}${order_by}${assessment}${unit_filters}${context_filters}${language_filter}`,
            {},
            this.get_explations_callback
        );
    }

    get_explations_callback(result) {
        const explanations = [];
        for (const i in result.studentresponses) {
            const explanation = result.studentresponses[i];
            explanations.push(explanation);
        }

        this.setState({
            explanations,
            total: result.responses_count,
        });
    }

    render() {
        const explanations_list = [];
        for (const index in this.state.explanations) {
            explanations_list.push(
                <StudentAnswer response={this.state.explanations[index]} />
            );
        }

        const explanations_display = (
            <table className="table">
                <tr>
                    <th>
                        Explanations: {this.state.total} (
                        {((this.state.total / this.props.total) * 100).toFixed(
                            0
                        )}
                        %)
                    </th>
                </tr>
                {explanations_list}
            </table>
        );

        return (
            <div>
                <FormWithChildren
                    autoSetGlobalState
                    setGlobalState={this.setGlobalState}
                    globalStateName="order_offset"
                    default
                >
                    <Select
                        options={[
                            { text: 'Random', value: 'random' },
                            {
                                text: 'Longest First',
                                value: '-response_length',
                            },
                            {
                                text: 'Shortest First',
                                value: 'response_length',
                            },
                        ]}
                        name="order"
                        label="Sort By"
                    />
                    <NumberInput name="offset" label="Offset" min={0} />
                </FormWithChildren>
                {explanations_display}
            </div>
        );
    }
}

class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            question: {
                id: 0,
                project_id: 0,
                name: '',
                question_text: '',
                options: [],
                item: { rubric: '' },
            },
            saved: false,
            unit_filters: '',
            context_filters: '',
        };

        this.question_callback = this.question_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.save_context = this.save_context.bind(this);
    }

    componentDidMount() {
        const { current_offset } = this.state;

        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            this.question_callback
        );
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    question_callback(value) {
        const { question } = value[0];
        this.setState({
            question: question,
            loaded: true,
            unit_filters: question.unit_filters,
            context_filters: question.context_filters,
        });
    }

    save_context() {
        ajaxWrapper(
            'POST',
            `/api/home/question/${this.props.question_id}/`,
            {
                unit_filters: this.state.unit_filters,
                context_filters: this.state.context_filters,
            },
            () => this.setState({ saved: true })
        );
    }

    render() {
        var content = null;

        let language_options = [];
        for (let item of LANGUAGE_VALUES) {
            language_options.push({ text: item, value: item });
        }

        // <Accordion names={[questionName]} open={[false]} ComponentList={[Paragraph]} ComponentProps={[paragraphProps]} />

        if (this.state.loaded) {
            let saved = null;
            if (this.state.saved) {
                saved = <Alert text="Saved" type="success" />;
            }

            var content = (
                <div>
                    <div className="container">
                        <h3>{this.state.question.text_by_language.English}</h3>
                        <p>
                            Explanations Found:{' '}
                            {this.state.question.total_responses}
                        </p>
                        <FormWithChildren
                            autoSetGlobalState
                            setGlobalState={this.setGlobalState}
                            globalStateName="context"
                            defaults={this.state.question}
                        >
                            <Select
                                name="language"
                                label="Language"
                                options={language_options}
                            />
                            <TextInput
                                name="unit_filters"
                                label="Unit Filters (Comma Seperated)"
                            />
                            <TextInput
                                name="context_filters"
                                label="Context Filters (Comma Seperated)"
                            />
                        </FormWithChildren>
                        <Button
                            type="primary"
                            text="Save"
                            onClick={this.save_context}
                        />
                        {saved}
                        <div className="row">
                            <div className="col-6">
                                <h2>Both Contexts</h2>
                                <Explanations
                                    key={`include_${this.state.unit_filters}_${this.state.context_filters}_${this.state.language}`}
                                    type="include"
                                    unit_filters={this.state.unit_filters}
                                    context_filters={this.state.context_filters}
                                    language={this.state.language}
                                    question_id={this.props.question_id}
                                    total={this.state.question.total_responses}
                                />
                            </div>
                            <div className="col-6">
                                <h2>No Context</h2>
                                <Explanations
                                    key={`exclude_${this.state.unit_filters}_${this.state.context_filters}_${this.state.language}`}
                                    type="exclude"
                                    unit_filters={this.state.unit_filters}
                                    context_filters={this.state.context_filters}
                                    language={this.state.language}
                                    question_id={this.props.question_id}
                                    total={this.state.question.total_responses}
                                />
                            </div>
                            <div className="col-6">
                                <h2>Unit Context Only</h2>
                                <Explanations
                                    key={`unit_${this.state.unit_filters}_${this.state.context_filters}_${this.state.language}`}
                                    type="unit"
                                    unit_filters={this.state.unit_filters}
                                    context_filters={this.state.context_filters}
                                    language={this.state.language}
                                    question_id={this.props.question_id}
                                    total={this.state.question.total_responses}
                                />
                            </div>
                            <div className="col-6">
                                <h2>Item Context Only</h2>
                                <Explanations
                                    key={`context_${this.state.unit_filters}_${this.state.context_filters}_${this.state.language}`}
                                    type="context"
                                    unit_filters={this.state.unit_filters}
                                    context_filters={this.state.context_filters}
                                    language={this.state.language}
                                    question_id={this.props.question_id}
                                    total={this.state.question.total_responses}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default Question;
