import React from 'react';
import { TextInput, CSSInput } from 'library';

class Accordion extends React.Component {
    static component_name = 'Accordion';

    constructor(props) {
        super(props);

        this.state = {
            open: this.props.open,
        };

        this.config = {
            form_components: [
                <TextInput label="Name" name="name" />,
                <TextInput label="Index" name="index" />,
                <CSSInput label="css" name="style" default={{}} />,
            ],
            can_have_children: true,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        const {name} = this.props;
        const index = this.props.index.replaceAll(' ', '_');

        let dataParent = `#accordion-${  index}`;
        if (this.props.multiple == true) {
            dataParent = `#accordion-${  index}`;
        }

        let collapsed = 'collapsed collapse';
        if (this.props.open == true) {
            collapsed += ' show';
        }

        let chevron = <i className="fas fa-chevron-right" />;
        if (this.props.open == true || this.state.open == true) {
            chevron = <i className="fas fa-chevron-down" />;
        }

        const content = (
            <div>
                <div className="card-header" id={`heading${  index}`}>
                    <h4 className="mb-0" style={{ textAlign: 'left' }}>
                        <button
                            style={{ width: '100%' }}
                            className="btn accordion-btn collapsed"
                            onClick={this.toggle}
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${  index}`}
                            aria-expanded="true"
                            aria-controls={`collapse${  index}`}
                        >
                            {name}

                            <div style={{ position: 'absolute', right: '0px' }}>
                                {chevron}
                            </div>
                        </button>
                    </h4>
                </div>
                <div className="">
                    <div
                        id={`collapse${  index}`}
                        className={collapsed}
                        aria-labelledby={`heading${  index}`}
                        data-parent={dataParent}
                    >
                        <div className="card-body">{this.props.children}</div>
                    </div>
                </div>
            </div>
        );

        return (
            <div
                key={index}
                className={`accordion ${  this.props.className}`}
                style={this.props.style}
                id="accordion"
            >
                {content}
            </div>
        );
    }
}

export default Accordion;
