import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Wrapper,
} from 'library';


class UserQC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            qc_changes: [],
        };
        this.qc_change_callback = this.qc_change_callback.bind(this);
    }

    componentDidMount() {
        const { qc_user } = this.props;
        ajaxWrapper(
            'GET',
            `/api/home/qcchange/?order_by=-created_at&related=student_question_response,who_qced,approach_component&who_scored=${qc_user}`,
            {},
            this.qc_change_callback
        );
    }

    qc_change_callback(result) {
        const changes = result.map((change) => change.qcchange);

        this.setState({ qc_changes: changes, loaded: true });
    }

    render() {
        const { qc_changes } = this.state;
        const table_rows = [];
        qc_changes.forEach((change) => {

            if (change.student_question_response) {
                table_rows.push(
                    <tr>
                        <td>
                            {
                                change.student_question_response
                                    .non_explanation_answer
                            }
                        </td>
                        <td>
                            <a
                                href={
                                    `/student_response/${change.student_question_response.id
                                    }/`
                                }
                                target="_blank" rel="noreferrer"
                            >
                                {change.student_question_response.answer}
                            </a>
                        </td>
                        <td>{change.approach_component.name}</td>
                        <td>{change.original_has_component}</td>
                        <td>
                            {change.who_qced.first_name}{' '}
                            {change.who_qced.last_name}
                        </td>
                        <td>{change.changed_has_component}</td>
                    </tr>
                );
            } else {
                table_rows.push(
                    <tr>
                        <td>Response Has Been Removed</td>
                        <td>Response Has Been removed</td>
                        <td>{change.approach_component.name}</td>
                        <td>{change.original_has_component}</td>
                        <td>
                            {change.who_qced.first_name}{' '}
                            {change.who_qced.last_name}
                        </td>
                        <td>{change.changed_has_component}</td>
                    </tr>
                );
            }
        })

        const headers = (
            <tr>
                <th>Student Answer</th>
                <th>Student Explanation</th>
                <th>Component</th>
                <th>Original Has Component</th>
                <th>Who Changed The Answer?</th>
                <th>QCed Has Component</th>
            </tr>
        );

        const content = (
            <div>
                <div className="container">
                    <h2>QC Changes</h2>
                    <table className="table">
                        {headers}
                        {table_rows}
                    </table>
                </div>
            </div>
        );
        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default UserQC;
