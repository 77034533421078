import { BASE_DOMAIN } from 'constants';
import React, { Component } from 'react';


export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="site-wrapper overflow-hidden position-relative">
                <header className="site-header site-header--menu-right landing-14-menu site-header--absolute site-header--sticky">
                    <div className="container">
                        <nav className="navbar site-navbar">
                            <div className="brand-logo">
                                <a href={`${BASE_DOMAIN}/`}>
                                    <img
                                        src="https://mathanex-media.learning.amplify.com/MathANEXLogo.png"
                                        alt=""
                                        style={{ height: '40px' }}
                                    />{' '}
                                    Math ANEX
                                </a>
                            </div>
                            <div className="menu-block-wrapper">
                                <div className="menu-overlay" />
                                <nav className="menu-block" id="append-menu-header">
                                    <div className="mobile-menu-head">
                                        <div className="go-back">
                                            <i className="fa fa-angle-left" />
                                        </div>
                                        <div className="current-menu-title" />
                                        <div className="mobile-menu-close">
                                            &times;
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a className="btn btn-success focus-reset" href="/">
                                    Home
                                </a>
                            </div>
                            <div className="ms-auto d-none d-xs-inline-flex">
                                <a
                                    target="_blank"
                                    className="btn btn-primary focus-reset"
                                    href="/login/"
                                >
                                    Log In
                                </a>
                            </div>

                            <div className="mobile-menu-trigger">
                                <span />
                            </div>
                        </nav>
                    </div>
                </header>

                <div className="cta-area-l-14">
                    <div className="container position-relative">
                        <div className="" />
                        <div className="row cta-area-l-14-content justify-content-center text-lg-start text-left">
                            <div className="col-lg-12">
                                <div className="cta-content">
                                    <h1>Math ANEX Privacy Policy</h1>
                                    <p>Effective Date: June 30, 2022</p>
                                    <p>
                                        Math ANEX ("Math ANEX," "we," or "us")
                                        understands that privacy is tremendously
                                        important to our online visitors to our
                                        website ("Website Visitors"), to schools
                                        who use our Service ("Schools"), and to
                                        students whose information we may access
                                        on behalf of a School ("Students"). Math
                                        ANEX provides a platform that enables
                                        Schools to share assessment results with
                                        Students, Teachers, and school staff as
                                        well as let students take assessments
                                        through the Math ANEX platform. This
                                        privacy policy applies to our website
                                        https://mathanex.com and to the Math
                                        ANEX platform (our "Service") and
                                        describes the steps we take to protect
                                        your data.
                                    </p>
                                    <p>
                                        We strive to be transparent in our data
                                        collection and use practices. This is
                                        the type of personal information we may
                                        collect. Student Data is used only for
                                        educational purposes at the direction of
                                        the School.
                                    </p>
                                    <h3>
                                        1. HOW WE COLLECT AND USE INFORMATION
                                    </h3>
                                    <p>
                                        We collect the following types of
                                        information:
                                    </p>
                                    <p>
                                        <b>Information about Schools:</b> We ask
                                        for certain information when a School
                                        administrator registers a School with
                                        Math ANEX, or if the administrator
                                        corresponds with us online, including a
                                        name, school name, school district,
                                        school email address and/or account name
                                        and password, phone number, message
                                        content, and information relating to the
                                        School's information systems. We may
                                        also retain information provided by a
                                        School if the School sends us a message,
                                        posts content to our website or through
                                        our Service, or responds to emails or
                                        surveys. Once a School begins using the
                                        Math ANEX Service, we will collect
                                        content and information provided by the
                                        School through the School's use of the
                                        Service and we will keep records of
                                        activities related to the Service. We
                                        use this information to operate,
                                        maintain, and provide to the features
                                        and functionality of the Service, to
                                        analyze and improve our Service
                                        offerings and functionality, and to
                                        communicate with our Schools and Website
                                        Visitors.
                                    </p>
                                    <p>
                                        <b>Student Data:</b> Through the course
                                        of providing its Service to a School,
                                        Math ANEX may have access to personally
                                        identifiable information about students
                                        ("Student Data") that is provided by the
                                        School, by the parent or guardian, or by
                                        the Student. Depending on the features
                                        and functionality of the Service
                                        selected by the School, a School may
                                        authorize Math ANEX to receive Student
                                        Data from applications such as Clever,
                                        Global Grid for Learning, Classlink,
                                        etc. as well as through sharing
                                        documents. Math ANEX has access to
                                        Student Data only as requested by the
                                        School, Teacher or Staff. The type of
                                        Student Data we collect will depend on
                                        how the School uses the Service and the
                                        learning applications which the School
                                        connects through the Service. In many
                                        instances, Math ANEX receives Student
                                        Data only from the School or its
                                        designated third parties and never
                                        interacts with the Student directly. In
                                        some instances, depending on the level
                                        of Math ANEX’s Service selected by the
                                        School, the Schools may allow Students
                                        to log into the Math ANEX Service to
                                        access third party applications that
                                        have been authorized by the School. In
                                        that instance, the School provides each
                                        student with login credentials or the
                                        school enables students to log in via a
                                        third party application such as Clever,
                                        Global Grid for Learning or Classlink.
                                    </p>
                                    <p>
                                        Student Data is used for educational
                                        purposes or research purposes. We
                                        consider Student Data to be confidential
                                        and do not use such data for any purpose
                                        other than to in accordance with
                                        contractual agreements with the School.
                                        Our collection, use, and disclosure of
                                        Student Data is governed by our
                                        agreement with the School, by the
                                        provision of the Family Educational
                                        Rights and Privacy Act (FERPA), the
                                        Children's Online Privacy Protection Act
                                        (COPPA), and applicable state laws which
                                        relate to the collection of Student
                                        Data. If you are a Student or parent,
                                        please contact your School if you have
                                        questions about the School's use of
                                        technology service providers like Math
                                        ANEX.
                                    </p>
                                    <p>
                                        <b>Information about Parents:</b>{' '}
                                        Depending on the features and
                                        functionality selected by the School, we
                                        may collect personal information about a
                                        student's parent or legal guardian (a
                                        “Parent”) that is provided by the
                                        school.{' '}
                                    </p>
                                    <p>
                                        <b>Information about Others:</b> We also
                                        collect personal information from
                                        Developers and other individuals who
                                        create an account, correspond with us
                                        online or otherwise use or interact with
                                        the Math ANEX Service, including a name,
                                        contact information and information
                                        relating to the Developer business or
                                        service. We may also retain information
                                        if the Developer sends us a message,
                                        posts content to our website or through
                                        our Service, or responds to emails or
                                        surveys. Once a Developer begins using
                                        the Math ANEX Service, we will collect
                                        content and information about the
                                        Developer's use of and interaction with
                                        the Service and we will keep records of
                                        activities related to the Service. We
                                        use this information to operate,
                                        maintain, and provide to the features
                                        and functionality of the Service, to
                                        analyze and improve our Service
                                        offerings and functionality, and to
                                        communicate with our Developers and
                                        others.
                                    </p>
                                    <p>
                                        <b>
                                            Information collected through use of
                                            the Service:
                                        </b>{' '}
                                        We may collect information you provide
                                        or upload to the Service as well as
                                        activity and actions within our Service.
                                        For example, we collect information from
                                        files that you upload, messages you send
                                        or receive through the Service, and
                                        content you post such as in product
                                        reviews or comments. If you are a
                                        Developer, we will also collect
                                        information you make available to us
                                        when developing an Application via the
                                        Service.
                                    </p>
                                    <p>
                                        <b>
                                            Information collected through
                                            technology:
                                        </b>{' '}
                                        Like most websites and online services,
                                        we and our third-party partners
                                        automatically collect certain types of
                                        usage information when you visit our
                                        Services, read our emails, or otherwise
                                        engage with us. We typically collect
                                        this information through a variety of
                                        tracking technologies, including
                                        cookies, web beacons, file information
                                        and similar technology (collectively,
                                        "tracking technologies"). For example,
                                        we collect information about your device
                                        and its software, such as your IP
                                        address, browser type, Internet service
                                        provider, platform type, device type,
                                        operating system, date and time stamp, a
                                        unique ID that allows us to uniquely
                                        identify your browser, mobile device, or
                                        your account, and other such
                                        information. We also collect information
                                        about the way you use our Service, for
                                        example, the site from which you came
                                        and the site to which you are going when
                                        you leave our website, the pages you
                                        visit, the links you click, how
                                        frequently you access the Service,
                                        whether you open emails or click the
                                        links contained in emails, whether you
                                        access the Service from multiple
                                        devices, and other actions you take on
                                        the Service. When you access our Service
                                        from a mobile device, we may collect
                                        unique identification numbers associated
                                        with your device or our mobile
                                        application, mobile carrier, device
                                        type, model, and manufacturer, and
                                        mobile device operating system. We may
                                        be able to determine your approximate
                                        location by analyzing other information,
                                        like an IP address to associate a user
                                        with their School. We may also collect
                                        analytics data, or use third-party
                                        analytics tools, to help us measure
                                        traffic and usage trends for the
                                        Service. Although we do our best to
                                        honor the privacy preferences of our
                                        visitors, we are not able to respond to
                                        Do Not Track signals from your browser
                                        at this time.
                                    </p>
                                    <p>
                                        Cookies and related technology allow us
                                        to provide you with a better user
                                        experience. For example, we may use the
                                        data collected through cookies to: (a)
                                        remember information so that a user will
                                        not have to re-enter it during
                                        subsequent visits; (b) provide custom,
                                        personalized content and information;
                                        (c) identify you across multiple
                                        devices; (d) provide and monitor the
                                        effectiveness of our Service; (e)
                                        monitor aggregate metrics such as total
                                        number of visitors, traffic, and usage
                                        on our website and our Service; (f)
                                        diagnose or fix technology problems; (g)
                                        help users efficiently access
                                        information after signing in; and (h)
                                        otherwise to plan for and enhance our
                                        Service.
                                    </p>
                                    <p>
                                        If you would prefer not to accept
                                        cookies, most browsers will allow you
                                        to: (i) change your browser settings to
                                        notify you when you receive a cookie,
                                        which lets you choose whether or not to
                                        accept it; (ii) disable existing
                                        cookies; or (iii) set your browser to
                                        automatically reject cookies. Please
                                        note that doing so may negatively impact
                                        your experience using the Service, as
                                        some features and services on our
                                        Service may not work properly. Depending
                                        on your mobile device and operating
                                        system, you may not be able to delete or
                                        block all cookies. You may also set your
                                        email options to prevent the automatic
                                        downloading of images that may contain
                                        technologies that would allow us to know
                                        whether you have accessed our email and
                                        performed certain functions with it.
                                    </p>

                                    <p>
                                        Third Party Tracking: We will never use
                                        Student Data for targeted advertising.
                                    </p>
                                    <h4>
                                        Student privacy is very important to us.
                                        Student Data is only shared in limited
                                        circumstances – and never shared with or
                                        sold to third parties for marketing
                                        purposes.
                                    </h4>
                                    <h3>2. HOW WE SHARE YOUR INFORMATION</h3>
                                    <p>
                                        Math ANEX only shares personal
                                        information in a few limited
                                        circumstances, described below. We do
                                        not rent or sell information for
                                        marketing purposes and we will never
                                        share or sell Student Data with third
                                        parties for marketing purposes.
                                    </p>
                                    <p>Who we may share information with:</p>
                                    <p>
                                        We share information within the Service
                                        as needed to perform the Service and/or
                                        at the direction of the School. For
                                        example, information including Student
                                        Data will be shared between and among
                                        authorized School users such as teachers
                                        and School administrators, and will be
                                        shared with third parties such as
                                        specific Developers as directed and
                                        authorized by the School. This sharing
                                        will depend on the settings and
                                        functionality selected by the School.
                                    </p>
                                    <p>
                                        We may share information with our
                                        trusted third-party service providers
                                        who perform technology services on our
                                        behalf (e.g., web hosting and analytics
                                        services), but strictly for the purpose
                                        of carrying out their work for us.
                                        Contractors and service providers who
                                        may have access to Student Data in the
                                        course of performing their services are
                                        subject to confidentiality and data
                                        security requirements.
                                    </p>
                                    <p>
                                        We may be required to share information
                                        with law enforcement or other third
                                        parties when compelled to do so by court
                                        order or other legal process, to comply
                                        with statutes or regulations, or if we
                                        believe in good faith that the
                                        disclosure is necessary to protect the
                                        rights, property or personal safety of
                                        our users.
                                    </p>
                                    <p>
                                        We may share information in an
                                        aggregated and/or anonymous form that
                                        does not reasonably identify an
                                        individual or School. For example, we
                                        may use and share aggregate or
                                        anonymized data to study and improve our
                                        Service, user functionality, product
                                        offerings, or share aggregate results.
                                    </p>

                                    <p>
                                        How Student Data is shared: Other than
                                        the disclosures described above, Math
                                        ANEX does not disclose Student Data
                                        other than as authorized and permitted
                                        by the School.{' '}
                                    </p>
                                    <h4>
                                        We take measures to keep data safe and
                                        secure.
                                    </h4>
                                    <h3>3. HOW WE PROTECT YOUR INFORMATION</h3>
                                    <p>
                                        Storage and processing: Any information
                                        collected through the Service from US
                                        residents is stored in the United
                                        States. Math ANEX maintains strict
                                        administrative, technical, and physical
                                        procedures to protect information stored
                                        in our servers, which are located in the
                                        United States. Access to information is
                                        limited (through user/password
                                        credentials) to those employees who
                                        require it to perform their job
                                        functions. We use industry-standard
                                        Secure Socket Layer (SSL) encryption
                                        technology to safeguard the account
                                        registration process and sign-up
                                        information. Other security safeguards
                                        include but are not limited to data
                                        encryption, firewalls, and physical
                                        access controls to building and files.{' '}
                                    </p>
                                    <p>
                                        Unauthorized disclosure: If there is any
                                        disclosure or access to any personally
                                        identifiable Student Data by an
                                        unauthorized party that compromises the
                                        security, confidentiality, or integrity
                                        of the Student Data, we will promptly
                                        notify the affected School(s) and will
                                        use reasonable efforts to cooperate with
                                        their investigation of the incident.
                                    </p>
                                    <h4>
                                        Questions about data? Here are your
                                        options.
                                    </h4>
                                    <h3>
                                        4. YOUR CHOICES ABOUT YOUR INFORMATION
                                    </h3>
                                    <p>
                                        Access to Student Data: Student Data is
                                        provided and controlled by the Schools.
                                        If you have any questions about
                                        reviewing, modifying, or deleting
                                        personal information of a student,
                                        please contact your School directly.
                                    </p>
                                    <p>
                                        How long we keep User Content: Following
                                        termination or deactivation of a School
                                        account, Math ANEX may retain School
                                        profile information and content for a
                                        commercially reasonable time for backup,
                                        archival, or audit purposes, but any and
                                        all Student Data associated with the
                                        School will be deleted promptly. We may
                                        maintain de-identified or aggregated
                                        data, including usage data, for
                                        analytics purposes.{' '}
                                    </p>
                                    <h4>
                                        We do not collect any information from
                                        students without consent.
                                    </h4>
                                    <h3>5. CHILDREN'S PRIVACY</h3>
                                    <p>
                                        Math ANEX does not knowingly collect any
                                        information from children under the age
                                        of 13 (or the applicable age of consent
                                        in local jurisdictions) unless and until
                                        the School has obtained appropriate
                                        parental consent for the student to use
                                        the Service. Because Math ANEX collects
                                        and uses Student Data at the direction
                                        of and under the control of a School,
                                        Math ANEX relies on each School to
                                        provide appropriate notice to parents of
                                        the School's use of third party service
                                        providers such as Math ANEX, and for the
                                        Schools to provide consent, if
                                        necessary, and authorization for Math
                                        ANEX to collect Student Data, as
                                        permitted by the Children's Online
                                        Privacy Protection Act (COPPA) and other
                                        applicable data protection laws. Please
                                        contact us at support@mathanex.com if
                                        you believe we have inadvertently
                                        collected personal information of a
                                        child without proper consent so that we
                                        may delete such data as soon as
                                        possible.
                                    </p>
                                    <h3>
                                        6. LINKS TO OTHER WEB SITES AND SERVICES
                                    </h3>
                                    <p>
                                        We are not responsible for the practices
                                        employed by websites, applications or
                                        services linked to or from our Service.{' '}
                                    </p>
                                    <h3>7. HOW TO CONTACT US</h3>
                                    <p>
                                        If you have any questions about this
                                        Privacy Policy or the Service, we
                                        recommend contacting us by emailing us
                                        at support@mathanex.com.
                                    </p>
                                    <p>
                                        If a Student contacts Math ANEX with a
                                        question about our Service, we will
                                        collect personal information from that
                                        Student only as necessary to respond to
                                        the Student's request and direct the
                                        Student to contact the Student's School,
                                        and we will then delete or anonymize the
                                        personal data of the Student after
                                        providing our response.
                                    </p>

                                    <h3>8. CHANGES TO OUR PRIVACY POLICY</h3>
                                    <p>
                                        Math ANEX may modify or update this
                                        Privacy Policy from time to time so you
                                        should review this page periodically. If
                                        we change the policy in a material
                                        manner, for example if we seek to use
                                        personal information in a materially
                                        different way than we had previously, we
                                        will provide at least 30 days' notice.
                                        Of course, you can always opt-out by
                                        contacting support@mathanex.com to
                                        remove any data we may have collected
                                        about you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
