function format_time_difference(diff, verbose) {
    var msec = diff;

    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;

    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;

    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    let output = '';
    if (hh > 0) {
        output += hh + ' hours ';
    }
    if (mm > 0) {
        if (ss > 0) {
            output += mm + ':' + ss + ' mins';
        } else {
            output += mm + ' mins';
        }
    }
    if (hh == 0 && mm == 0 && ss > 0) {
        output += ss + ' seconds';
    }

    if (verbose) {
        return output;
    } else {
        if (ss < 10) {
            ss = '0' + ss;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        if (hh > 0) {
            return hh + ':' + mm + ':' + ss;
        } else {
            return mm + ':' + ss;
        }
    }
}

export default format_time_difference;
