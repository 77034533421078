import React from 'react';

export default function PageBreak(props) {
    const style = {
        width: '100%',
        borderBottom: '1px solid #ccc',
        margin: '0px',
        ...props.style,
    };

    return <div style={style}>{props.text}</div>;
}
