import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDateString } from 'functions';

import { Wrapper } from 'library';

class LatestResponses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            student_question_responses: [],
            offset: 0,
        };

        this.refresh_responses = this.refresh_responses.bind(this);
        this.response_callback = this.response_callback.bind(this);
        this.analyst_callback = this.analyst_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/user/user/${this.props.user}/`,
            {},
            this.analyst_callback
        );
        this.refresh_responses();
    }

    analyst_callback(result) {
        this.setState({ user: result[0].user, loaded: true });
    }

    refresh_responses() {
        ajaxWrapper(
            'GET',
            `/api/home/studentquestionresponse/?limit=50&order_by=-updated_at&related=question,way_of_thinking&who_scored=${this.props.user}&offset=${this.state.offset}`,
            {},
            this.response_callback
        );
    }

    response_callback(result) {
        const student_question_responses = [];
        for (const index in result) {
            const response = result[index].studentquestionresponse;
            student_question_responses.push(response);
        }

        this.setState({
            student_question_responses,
            loaded: true,
        });
    }

    render() {
        const responses = [];
        for (const index in this.state.student_question_responses) {
            const response = this.state.student_question_responses[index];
            let { way_of_thinking } = response;
            if (!way_of_thinking) {
                way_of_thinking = { text: 'Generic Way of Thinking' };
            }

            responses.push(
                <tr>
                    <td>
                        {formatDateString(
                            response.updated_at,
                            'mm/dd/yyyy HH:MM'
                        )}
                    </td>
                    <td>{response.question.name}</td>
                    <td>{response.non_explanation_answer}</td>
                    <td>
                        <a
                            href={`/student_response/${response.id}/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {response.answer}
                        </a>
                    </td>
                    <td
                        dangerouslySetInnerHTML={{
                            __html: way_of_thinking.text,
                        }}
                    />
                </tr>
            );
        }

        let content = null;
        if (this.state.loaded) {
            content = (
                <div>
                    <div className="container">
                        <h2>
                            Latest Analyses: {this.state.user.first_name}{' '}
                            {this.state.user.last_name[0]}
                        </h2>
                        <table className="table">
                            <tr>
                                <th>Date</th>
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Student Response</th>
                                <th>Way of Thinking</th>
                                <th>Way of Explanation</th>
                            </tr>
                            {responses}
                        </table>
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default LatestResponses;
