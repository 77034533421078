import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Wrapper, Alert } from 'library';

import StaffParticipation from './staff_participation';
import TeacherParticipation from './teacher_participation';

export default class Participation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,

            role: null,

            open_date: new Date(),
            current_date: new Date(),

            assessment_options: [],
        };

        this.current_date_updater = this.current_date_updater.bind(this);
        this.get_participation = this.get_participation.bind(this);
        this.load_participation = this.load_participation.bind(this);
    }

    componentDidMount() {
        this.get_participation();

        this.current_date_updater();
    }

    current_date_updater() {
        setTimeout(
            function () {
                this.setState(
                    { current_date: new Date() },
                    this.current_date_updater()
                );
            }.bind(this),
            2000
        );
    }

    get_participation() {
        var url = `/reporting/participation/`;
        if (this.props.admin_spoof) {
            url += `?admin_spoof=${this.props.admin_spoof}`;
        }

        this.setState(
            {
                loaded: false,
            },
            function () {
                ajaxWrapper('GET', url, {}, this.load_participation);
            }.bind(this)
        );
    }

    load_participation(result) {
        if ('error' in result) {
            this.setState({
                loaded: true,
                error: result['error'],
            });
            return null;
        }

        let assessment_options = [];
        if ('assessments' in result) {
            for (let item of result['assessments']) {
                let assessment = item['assessment'];
                assessment_options.push({
                    text: `${assessment['name']}`,
                    value: assessment['id'],
                });
            }
        }

        let section_options = [];
        let section_lookup = {};
        if ('sections' in result) {
            for (let item of result['sections']) {
                let section = item['section'];
                section_options.push({
                    text: `${section['name']} : Period ${section['period']}`,
                    value: section['id'],
                });

                section_lookup[section['id']] = section;
            }
        }

        let school_options = [];
        let school_lookup = {};
        if ('schools' in result) {
            for (let item of result['schools']) {
                let school = item['school'];
                school_options.push({
                    text: `${school['name']}`,
                    value: school['id'],
                });

                school_lookup[school['id']] = school;
            }
        }

        this.setState({
            loaded: true,
            open_date: new Date(),

            role: result['role'],

            student_assessments: result['student_assessments'],
            students_without_assessment: result['students_without_assessment'],

            schools: result['schools'],
            teachers: result['teachers'],

            assessment_options: assessment_options,

            school_lookup: school_lookup,
            school_options: school_options,

            section_lookup: section_lookup,
            section_options: section_options,
        });
    }

    render() {
        let participation_display = null;
        if (this.state.role) {
            if (this.state.role == 'teacher') {
                participation_display = (
                    <TeacherParticipation {...this.state} />
                );
            } else {
                participation_display = <StaffParticipation {...this.state} />;
            }
        }

        let error = null;
        if (this.state.error) {
            error = <Alert type="danger" text={this.state.error} />;
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ marginTop: '0px' }}>
                {error}
                {participation_display}
            </Wrapper>
        );
    }
}
