window.cmState = { completed: 'False' };

function setGlobalState(name, state) {
    if (typeof state === 'object') {
        const newState = window.cmState[name] || {};
        Object.keys(state).forEach(index => {
            newState[index] = state[index];
        });
        window.cmState[name] = newState;
    } else {
        window.cmState[name] = state;
    }

    checkSubscribersToUpdate(name, state);
}

function checkSubscribersToUpdate(name, state) {
    if (window.cmState.kwarg_subscribers) {
        if (window.cmState.kwarg_subscribers[name]) {
            const subscriberList = window.cmState.kwarg_subscribers[name];
            updateSubscribers(subscriberList);
        }

        // Check if state update is more complex than a string value
        if (typeof state === 'object') {
            Object.keys(state).forEach(key => {
                // Use the variable format to compose a accurate name
                const deeperName = `${name}.${key}`;

                if (window.cmState.kwarg_subscribers[deeperName]) {
                    const subscriberList =
                        window.cmState.kwarg_subscribers[deeperName];
                    updateSubscribers(subscriberList);
                }
            });
        }
    }

    return true;
}

function updateSubscribers(context) {
    Object.keys(context).forEach(index => {
        const component = context[index];
        if (component.refreshData) {
            component.refreshData();
        } else if (component.forceUpdate) {
            component.forceUpdate();
        }
    });
}

function subscribe(component, unresolvedString) {
    // Components must subscribe themselves to global state updates
    // This is accomplished by providing their own context, and the string
    // containing whichever variables they want to subcribe to
    if (!unresolvedString) {
        return false;
    }

    // Instantiate the subscription dictionary
    if (!window.cmState.kwarg_subscribers) {
        window.cmState.kwarg_subscribers = {};
    }

    // Pull all varaibles out of submitted string
    const anythingInsideCurlyBracketsRegex = /\{([^{}]+)\}/g;
    let matches = [];
    if (typeof unresolvedString === 'string') {
        matches = [
            ...unresolvedString.matchAll(anythingInsideCurlyBracketsRegex),
        ];
    }

    Object.keys(matches).forEach(index => {
        let match = matches[index][1];

        // Reduce varaible down to one value because subscription only checks that deep
        if (match.indexOf('.') > -1) {
            const match_peices = match.split('.');
            [match] = match_peices;
        }
        subscribe_by_name(component, match);
    });
}

function is_valid_react_child(child) {
    if (
        child != null &&
        typeof child === 'object' &&
        String(child.$$typeof) === 'Symbol(react.element)'
    ) {
        return true;
    }

    return false;
}

function subscribe_by_name(component, name) {
    if (!window.cmState.kwarg_subscribers) {
        window.cmState.kwarg_subscribers = {};
    }

    if (!window.cmState.kwarg_subscribers[name]) {
        window.cmState.kwarg_subscribers[name] = [];
    }

    if (window.cmState.kwarg_subscribers[name].indexOf(component) == -1) {
        window.cmState.kwarg_subscribers[name].push(component);
    }
}

function getGlobalState() {
    return window.cmState;
}

window.cmState.setGlobalState = setGlobalState;
window.cmState.getGlobalState = getGlobalState;
window.cmState.subscribe = subscribe;
window.cmState.subscribe_by_name = subscribe_by_name;
window.cmState.is_valid_react_child = is_valid_react_child;

export default setGlobalState;
