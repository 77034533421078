import React from 'react';

export default function Checkbox(props) {
    let checked = false;
    if (props.checked === true) {
        checked = true;
    }
    // instead of indexOf because I was having trouble with "4" and 4.
    else if (props.checkList) {
        Object.keys(props.checkList).forEach(index => {
            if (props.checkList[index] === props.value) {
                checked = true;
            }
        });
    }

    return (
        <div className="custom-control custom-checkbox" style={props.style}>
            <input
                type="checkbox"
                className="custom-control-input"
                checked={checked}
                id={`${props.name}-${props.value}`}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
            />
            <label
                className="custom-control-label"
                htmlFor={`${props.name}-${props.value}`}
            >
                {props.label}
            </label>
        </div>
    );
}
