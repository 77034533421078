import React, { Component } from 'react';

import { Wrapper, Alert, Container, Button } from 'library';
import { ajaxWrapper } from 'base';
import { prettifyString } from 'functions';

import SideNavbar from 'pages/reports/components/side_navbar.js';
import Header from 'pages/reports/components/header.js';

import AssessmentOverview from '../report_types/assessment_overview';
import QuestionOnAssessmentReport from '../report_types/question_on_assessment';
import MCLForQuestion from '../report_types/mcl_for_question';
import SingleStudentReport from '../report_types/single_student';
import MetStandardReport from '../report_types/met_standard';
import EssentialMathAssetsReport from '../report_types/essential_math_assets';

export default class ReportLookup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            report: null,

            height: 0,
            width: 0,
        };

        this.update_dimensions = this.update_dimensions.bind(this);
        this.load_report = this.load_report.bind(this);
    }

    componentDidMount() {
        this.update_dimensions();
        window.addEventListener('resize', this.update_dimensions.bind(this));

        if (window.location.search.includes('student=')) {
            this.setState({
                loaded: true,
                report: {
                    display_name: 'Student Report',
                    data: {
                        type: 'single_student_report',
                    },
                },
            });
        } else {
            let url = `/reporting/report_lookup/${window.location.search}`;
            ajaxWrapper('GET', url, {}, this.load_report);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update_dimensions.bind(this));
    }

    update_dimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight - 1,
        });
    }

    load_report(result) {
        this.setState({
            loaded: true,
            report: result,
        });
    }

    render() {
        const header_height = 62;

        const main_content_style = {
            transition: '.2s margin-left',
            marginLeft: '76px',
            height: `${this.state.height}px`,
        };

        const result_style = {
            height: `${this.state.height - header_height}px`,
        };

        let report_to_display = null;
        if (this.state.report) {
            if ('error' in this.state.report) {
                report_to_display = (
                    <Alert type="danger" text={this.state.report['error']} />
                );
            } else if (this.state.report.data.type == 'single_student_report') {
                let student_id = window.location.search.split('student=')[1];
                report_to_display = <SingleStudentReport id={student_id} />;
            } else if (
                this.state.report.data.type == 'overview_for_assessment'
            ) {
                //This should be added to the report generation step
                if (
                    this.state.report.data['context'] &&
                    this.state.report.data['context'].length > 0 &&
                    this.state.report.data['context'][0]['type'] === 'period' &&
                    this.state.report.teacher_id
                ) {
                    this.state.report.data['context'].push({
                        id: this.state.report.teacher_id,
                        type: 'teacher',
                    });
                }

                report_to_display = (
                    <AssessmentOverview {...this.state.report.data} />
                );
            } else if (
                this.state.report.data.type == 'question_on_assessment_report'
            ) {
                report_to_display = (
                    <QuestionOnAssessmentReport
                        {...this.state.report.data}
                        send_event={this.props.send_event}
                    />
                );
            } else if (
                this.state.report.data.type ==
                'mcl_for_question_on_assessment_report'
            ) {
                report_to_display = (
                    <MCLForQuestion {...this.state.report.data} />
                );
            } else if (this.state.report.type == 'met_standard') {
                report_to_display = (
                    <MetStandardReport report={this.state.report} />
                );
            } else if (this.state.report.type == 'essential_math_assets') {
                report_to_display = (
                    <EssentialMathAssetsReport report={this.state.report} />
                );
            }
        }

        let report_name = 'PDF';
        if (this.state.report) {
            report_name = this.state.report.display_name;
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ marginTop: '0px' }}>
                <div className="reporting-dashboard">
                    <SideNavbar
                        height={this.state.height}
                        district={this.state.district}
                    />

                    <div
                        style={main_content_style}
                        className="sad no-margin-print"
                    >
                        <Header
                            page={'Report'}
                            width={this.state.width}
                            report_name={report_name}
                        />

                        <div className="report-container" style={result_style}>
                            <div className="no-print">
                                <Alert type="info" style={{ margin: '15px' }}>
                                    <img
                                        class="icon purple"
                                        src="/static/images/reporting/question-3-circle.svg"
                                        alt="image"
                                        style={{ margin: '11px 5px 11px 0px' }}
                                    />
                                    <div
                                        className="h2"
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '15px',
                                        }}
                                    >
                                        Want to share your results?
                                    </div>
                                    <div
                                        style={{
                                            padding: '8px 30px',
                                            height: 'auto',
                                            fontSize: '20px',
                                            display: 'inline-block',
                                            width: 'auto',
                                        }}
                                        className="purple-button"
                                        onClick={() => window.print()}
                                    >
                                        {'Print Me!'}
                                    </div>
                                </Alert>
                            </div>
                            <div
                                style={{
                                    marginTop: '40px',
                                    padding: '0px 15px',
                                }}
                            >
                                {/* Main Content Display */}

                                {report_to_display}
                            </div>
                            <div style={{ clear: 'both', width: '100%' }} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
