import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Alert } from 'library';
import QuestionDisplay from 'pages/questions/question_display.js';

export default class FindMath extends Component {
    constructor(props) {
        super(props);

        this.state = { math_list: [], loaded: false, question: {} };

        this.math_callback = this.math_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'POST',
            '/api/find_math/' + this.props.question_id + '/',
            {},
            this.math_callback
        );

        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            result => {
                let question = result[0]['question'];

                this.setState({
                    question: question,
                });
            }
        );
    }

    math_callback(data) {
        this.setState({ math_list: data.math_list, loaded: true });
    }

    render() {
        var math_rows = [];
        for (var math of this.state.math_list) {
            var row = (
                <tr>
                    <td>{math[0]}</td>
                    <td>{math[1]}</td>
                </tr>
            );
            math_rows.push(row);
        }

        var content = (
            <div className="container" style={{ marginTop: '50px' }}>
                <div>
                    <h2 class="col-12">Find All Math</h2>
                    <Alert type="info">
                        <QuestionDisplay
                            question={this.state.question}
                            display="full_text"
                            image
                        />
                    </Alert>
                    <a
                        href={
                            '/scoring_configuration/question_component_overview/' +
                            this.props.question_id +
                            '/'
                        }
                    >
                        Back to Components Overview
                    </a>
                </div>
                <div style={{ height: '30px' }}></div>
                <table className="table">
                    <tr>
                        <th>Math Expression</th>
                        <th># Of Responses</th>
                    </tr>
                    {math_rows}
                </table>
            </div>
        );

        return <Wrapper content={content} loaded={this.state.loaded} />;
    }
}
