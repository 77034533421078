import React from 'react';
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal, sankeyJustify } from 'd3-sankey';

const COLOR_MAPPING = {
    Strong: 0,
    Proficient: 2,
    Incomplete: 4,
    Blank: 6,
};

function getMousePosition(event) {
    const CTM = event.target.getScreenCTM();

    return {
        x: (event.clientX - CTM.e) / CTM.a,
        y: (event.clientY - CTM.f) / CTM.d,
    };
}

class Rect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { size } = this.props;
        const { index } = this.props;
        const { x0 } = this.props;
        const { x1 } = this.props;
        const { y0 } = this.props;
        const { y1 } = this.props;
        const { name } = this.props;
        const { length } = this.props;
        const { colors } = this.props;

        return (
            <>
                <rect
                    x={x0}
                    y={y0}
                    width={x1 - x0}
                    height={y1 - y0}
                    fill={colors(index / length)}
                    data-index={index}
                />
                <text
                    x={x0 < size.width / 2 ? x1 + 6 : x0 - 6}
                    y={(y1 + y0) / 2}
                    style={{
                        fill: d3.rgb(colors(index / length)).darker(),
                        alignmentBaseline: 'middle',
                        fontSize: 9,
                        textAnchor: x0 < size.width / 2 ? 'start' : 'end',
                        pointerEvents: 'none',
                        userSelect: 'none',
                    }}
                >
                    {name}
                </text>
            </>
        );
    }
}

class Link extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
        };

        this.onClick = this.onClick.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onClick(event) {
        const { data, onClick } = this.props;
        if (onClick) {
            onClick(
                event,
                data.source.index,
                data.target.index
            );
        }
    }

    onMouseEnter() {
        this.setState({ hover: true });
    }

    onMouseLeave() {
        this.setState({ hover: false });
    }

    render() {
        const { data } = this.props;
        const { width } = this.props;
        const { length } = this.props;
        const { colors } = this.props;

        const link = sankeyLinkHorizontal();

        let start_color = colors(COLOR_MAPPING[data.source.name] / length);
        let end_color = colors(COLOR_MAPPING[data.target.name] / length);
        const { hover } = this.state;
        if (hover) {
            start_color = d3.rgb(start_color).brighter();
            end_color = d3.rgb(end_color).brighter();
        }

        return (
            <>
                <defs>
                    <linearGradient
                        id={`gradient-${data.index}`}
                        gradientUnits="userSpaceOnUse"
                        x1={data.source.x1}
                        x2={data.target.x0}
                    >
                        <stop offset="0" stopColor={start_color} />
                        <stop offset="100%" stopColor={end_color} />
                    </linearGradient>
                </defs>
                <path
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onClick}
                    d={link(data)}
                    fill="none"
                    stroke={`url(#gradient-${data.index})`}
                    strokeOpacity={0.5}
                    strokeWidth={width}
                />
            </>
        );
    }
}

class Sankey extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        const { size } = this.props;
        this.sankey_render = sankey()
            .nodeAlign(sankeyJustify)
            .nodeWidth(10)
            .nodePadding(10)
            .extent([
                [0, 0],
                [size.width, size.height],
            ]);

        this.dragElement = React.createRef();
        this.graph = React.createRef();
        this.offset = React.createRef();

        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('mousemove', this.onMouseMove);
    }


    componentWillReceiveProps(nextProps) {
        const { size } = this.props;
        if (size != nextProps.size) {
            this.sankey_render = sankey()
                .nodeAlign(sankeyJustify)
                .nodeWidth(10)
                .nodePadding(10)
                .extent([
                    [0, 0],
                    [nextProps.size.width, nextProps.size.height],
                ]);

            this.setState({});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('mousemove', this.onMouseMove);
    }


    onMouseUp() {
        this.dragElement.current = null;
    }

    onMouseDown(e) {
        if (e.target.tagName === 'rect') {
            this.dragElement.current = e.target;
            this.offset.current = getMousePosition(e);
            this.offset.current.y -= parseFloat(
                e.target.getAttributeNS(null, 'y')
            );
        }
    }

    onMouseMove(e) {
        if (this.dragElement.current) {
            const coord = getMousePosition(e);
            this.dragElement.current.setAttributeNS(
                null,
                'y',
                coord.y - this.offset.current.y
            );
        }
    }

    render() {
        const { props } = this;

        const colors = props.edit ? d3.interpolateWarm : d3.interpolateCool;
        const { onClick } = props;
        if (props.data) {
            this.graph.current = this.sankey_render(props.data);
            const { links, nodes } = this.graph.current;

            return (
                <svg width={props.size.width} height={props.size.height}>
                    <g>
                        {links.map((d) => (
                            <Link
                                onClick={onClick}
                                data={d}
                                width={d.width}
                                length={nodes.length}
                                colors={colors}
                            />
                        ))}
                    </g>
                    <g>
                        {nodes.map((d) => (
                            <Rect
                                size={props.size}
                                index={d.index}
                                x0={d.x0}
                                x1={d.x1}
                                y0={d.y0}
                                y1={d.y1}
                                name={d.name}
                                value={d.value}
                                length={nodes.length}
                                colors={colors}
                            />
                        ))}
                    </g>
                </svg>
            );
        }

        return <div>Loading</div>;
    }
}

export default Sankey;
