import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Alert,
    FormWithChildren,
    TextInput,
    TextArea,
    PageBreak,
    Container,
} from 'library';

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            error: '',
        };

        this.submit = this.submit.bind(this);
    }

    submit(state) {
        this.setState({ error: '' });

        let text = `<p>Email: ${  state.email  }</p>`;
        text += `<p>Id: ${  state.id  }</p>`;
        text += `<p>How Can We Help?: ${  state.message  }</p>`;

        ajaxWrapper(
            'POST',
            '/api/email/',
            {
                user_email: state.email,
                subject: 'Math Anex: Report Dashboard Request',
                text,
            },
            () => this.setState({ sent: true })
        );
    }

    render() {
        let error = null;
        if (this.state.error != '') {
            error = <Alert type="danger" text={this.state.error} />;
        } else if (this.state.sent) {
            error = <Alert type="success" text="Message Sent." />;
        }

        const {user} = window.cmState;
        const data = {
            email: user.email,
            id: user.id,
        };

        return (
            <Container style={{ padding: '20px' }}>
                <div style={{ fontSize: '20px' }}>
                    Questions about the dashboard? Check out our
                    <a
                        style={{
                            color: '#007bff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            marginLeft: '5px',
                        }}
                        href="https://docs.google.com/document/d/1mISX7C4F5hrQBFn__FWMvhzQn1FngJ8G3cBVPg5ArWs/edit"
                        target="_blank" rel="noreferrer"
                    >
                        resource guide.
                    </a>
                </div>
                <div style={{ padding: '20px 0px' }}>
                    <PageBreak />
                </div>
                <div style={{ fontSize: '20px' }}>
                    Need additional assistance? Make your request below.
                </div>
                <FormWithChildren
                    submit={this.submit}
                    submit_text="Send Request"
                    submitClassName="purple-button"
                    submit_on_enter={false}
                    defaults={data}
                >
                    <TextInput name="email" style={{ display: 'none' }} />
                    <TextInput name="id" style={{ display: 'none' }} />

                    <div style={{ fontSize: '16px', marginBottom: '5px' }}>
                        How Can We Help?
                    </div>
                    <TextArea rows={3} name="message" required />
                </FormWithChildren>
                {error}
            </Container>
        );
    }
}
