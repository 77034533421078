import React, { Component } from 'react';

import {} from 'library';

const CHARACTER_WIDTH = 9;

class TabSelect extends Component {
    render() {
        let value_name = this.props.name;

        if (this.props.name == 'All') {
            value_name = '';
        }

        let active = '';
        if (value_name == this.props.current) {
            active = ' active';
        }

        const style = {};
        if (this.props.hidden) {
            style.display = 'block';
        }

        return (
            <div
                className={`student-list-display-toggle h2 lighter${  active}`}
                onClick={() => this.props.select_tab(value_name)}
                style={style}
            >
                {this.props.name}
            </div>
        );
    }
}

export default class TabAutoSizer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header_width: 0,
            show_hidden_tabs: false,
        };

        this.select_tab = this.select_tab.bind(this);
        this.header_ref = React.createRef();
    }

    componentDidMount() {
        this.setState({
            header_width: this.header_ref.current.offsetWidth,
        });
    }

    select_tab(name) {
        this.setState({
            show_hidden_tabs: false,
        });

        if (this.props.select_tab) {
            this.props.select_tab(name);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.side_navbar_open != nextProps.side_navbar_open ||
            this.props.filters_open != nextProps.filters_open
        ) {
            // Make a guess
            const total = window.innerWidth;
            let remaining = total;
            if (nextProps.side_navbar_open) {
                remaining -= 210;
            } else {
                remaining -= 76;
            }
            if (nextProps.filters_open) {
                remaining -= total * 0.25;
            }

            // Resize immediately only if the space shrinks
            if (remaining < this.state.header_width) {
                nextState.header_width = remaining;
            }

            setTimeout(
                () => {
                    if (
                        this.state.header_width !=
                        this.header_ref.current.offsetWidth
                    ) {
                        this.setState({
                            header_width: this.header_ref.current.offsetWidth,
                        });
                    }
                },
                200
            );
        }

        return true;
    }

    render() {
        const tabs = [];
        const hidden_tabs = [];

        let expected_width = 20 + CHARACTER_WIDTH * 3; // Start with expected width of ... button

        for (const name of this.props.tabs) {
            expected_width += 20 + name.length * CHARACTER_WIDTH;

            if (this.state.header_width > expected_width) {
                tabs.push(
                    <TabSelect
                        name={name}
                        current={this.props.current}
                        select_tab={this.select_tab}
                    />
                );
            } else {
                hidden_tabs.push(
                    <TabSelect
                        name={name}
                        current={this.props.current}
                        select_tab={this.select_tab}
                        hidden
                    />
                );
            }
        }

        if (hidden_tabs.length > 0) {
            tabs.push(
                <div
                    className="student-list-display-toggle h2 lighter"
                    onClick={() =>
                        this.setState({
                            show_hidden_tabs: !this.state.show_hidden_tabs,
                        })
                    }
                >
                    ...
                </div>
            );
        }
        const hidden_tabs_style = {
            display: 'none',
            position: 'absolute',
            top: '115%',
            right: '0px',
            background: 'white',
            borderRadius: '4px',
            padding: '0px 10px 10px',
            zIndex: '10',
            border: 'thin solid',
        };
        if (this.state.show_hidden_tabs) {
            hidden_tabs_style.display = 'block';
        }
        const hidden_tabs_container = (
            <div className="light-purple-border" style={hidden_tabs_style}>
                {hidden_tabs}
            </div>
        );

        return (
            <div ref={this.header_ref}>
                <div
                    style={{
                        textAlign: 'left',
                        margin: '0px 15px',
                        position: 'relative',
                        display: 'inline-block',
                    }}
                >
                    {tabs}
                    {hidden_tabs_container}
                </div>
            </div>
        );
    }
}
