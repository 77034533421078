import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Alert,
    Wrapper,
    DictDisplay,
    FormWithChildren,
    Select,
    EmptyModal,
    PageBreak,
} from 'library';

import { filters_have_changed } from 'functions';

import QuestionDisplay from 'pages/questions/question_display.js';
import ResponseFilterForm from 'pages/analysts/response_filter_form';
import MathComponentWayToGetForm from './components/math_way_to_get_form.js';
import LogicComponentWayToGetForm from './components/logic_way_to_get_form.js';
import RunAutoAnalysis from './components/run_auto_analysis.js';

let MATH_PARSING_KEYS = [
    'tool',
    'numbers_ahead',
    'numbers_behind',
    'numbers_surrounding',
];

class MathParsing extends Component {
    constructor(props) {
        super(props);

        this.state = {show: false}

        this.toggle_display = this.toggle_display.bind(this);
    }

    toggle_display() {
        this.setState({show: !this.state.show})
    }

    render() {
        let content = <Button type='primary' onClick={this.toggle_display} text={'Show Math Details'} />

        if (this.state.show) {
            var math_details = []
            for (var key in this.props.math_parsing_data) {
                math_details.push(<h4>{key}</h4>)
                for (var i in this.props.math_parsing_data[key]) {
                    math_details.push(<p>{JSON.stringify(this.props.math_parsing_data[key][i])}</p>)
                }
                
            }
            content = <EmptyModal onHide={this.toggle_display} show={true}>
                <div style={{padding:'20px'}}>
                    {math_details}
                </div>
            </EmptyModal>
        }

        return (content)
    }
}

class ComponentAssignment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            has_component: this.props.response.has_component,
        };

        this.toggle_component = this.toggle_component.bind(this);
        this.component_callback = this.component_callback.bind(this);
    }

    toggle_component() {
        this.setState({ loaded: false });
        ajaxWrapper(
            'POST',
            `/change_component_assignment/${this.props.response.rca_id}/`,
            {
                has_component: !this.state.has_component,
                who_scored: window.cmState.user.id,
            },
            this.component_callback
        );
    }

    component_callback(result) {
        console.log('component_callback', result);
        this.setState({
            has_component:
                result['response_component_analysis']['has_component'],
        });
    }

    render() {
        var response = this.props.response;
        var component_text = 'Yes';
        if (!this.state.has_component) {
            component_text = 'No';
        }

        var scored_by_human = 'No';
        if (response.who_scored == null) {
            scored_by_human = 'Not Scored';
        } else if (response.who_scored != 358) {
            scored_by_human = 'Yes';
        }

        if (this.props.type == 'Logic') {
            return (
                <tr>
                    <td>{response.non_explanation_answer}</td>
                    <td style={{ wordBreak: 'break-word' }}>
                        <a
                            href={`/student_response/${response.id}/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {response.answer}
                        </a>
                    </td>
                    <td style={{ wordBreak: 'break-word' }}>
                        {response.cleaned_explanation}
                    </td>
                    <td>{response.found_phrases}</td>

                    <td>
                        <a
                            onClick={this.toggle_component}
                            style={{
                                color: 'blue',
                                textDecoration: 'underline',
                            }}
                        >
                            {component_text}
                        </a>
                    </td>
                    <td>{scored_by_human}</td>
                </tr>
            );
        } else {
            let math_parsing_jsx = <MathParsing math_parsing_data={response['math_parsing']} />;
        

            return (
                <tr>
                    <td>{response.non_explanation_answer}</td>
                    <td>
                        <a
                            href={`/student_response/${response.id}/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {response.answer}
                        </a>
                    </td>
                    <td>{math_parsing_jsx}</td>

                    <td>
                        <a
                            onClick={this.toggle_component}
                            style={{
                                color: 'blue',
                                textDecoration: 'underline',
                            }}
                        >
                            {component_text}
                        </a>
                    </td>
                    <td>{scored_by_human}</td>
                </tr>
            );
        }
    }
}

class ComponentAutomationMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: null,

            loaded: false,
            loading_responses: false,
            responses: [],
            wot_lookup: {},

            filters: {
                answer_include: '',
                answer_exclude: '',
                explanation_include: '',
                explanation_exclude: '',
                components_include: [],
                components_exclude: [],

                way_of_thinking: null,
                limit: 45,
                offset: 0,

                max_characters: 400,
                max_rows: 15,
                longest: false,
                mcl: [],
            },

            answer_columns: null,
            displayed_responses: [],

            show_filters: true,
            component_options: [],

            existing_mapping: [],
        };

        this.get_component = this.get_component.bind(this);

        this.refresh_response_data = this.refresh_response_data.bind(this);
        this.put_responses_under_review = this.put_responses_under_review.bind(this);
        this.load_responses = this.load_responses.bind(this);

        this.filter_responses = this.filter_responses.bind(this);
        this.filter_responses_custom = this.filter_responses_custom.bind(this);

        this.save_mapping = this.save_mapping.bind(this);
        this.mark_all_reviewed = this.mark_all_reviewed.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            result => {
                this.setState(
                    {
                        question: result[0]['question'],
                    },
                    this.get_component
                );
            }
        );
    }

    get_component() {
        ajaxWrapper(
            'GET',
            `/api/home/approachcomponent/${this.props.component_id}/`,
            {},
            result => {
                this.setState(
                    {
                        loaded: true,
                        component: result[0]['approachcomponent'],
                        existing_mapping:
                            result[0]['approachcomponent'][
                                'ways_to_get_mapping'
                            ],
                    },
                    this.refresh_response_data()
                );
            }
        );
    }

    refresh_response_data() {
        this.setState({ loading_responses: true });

        const data = this.state.filters;
        ajaxWrapper(
            'POST',
            `/api/scoring_configuration/responses_by_component_explorer/${this.props.question_id}/${this.props.component_id}/`,
            data,
            this.load_responses
        );
    }

    put_responses_under_review() {
        this.setState({ loading_responses: true });

        const data = this.state.filters;
        ajaxWrapper(
            'POST',
            `/api/scoring_configuration/put_responses_under_review/${this.props.question_id}/${this.props.component_id}/`,
            data,
            this.refresh_response_data
        );
    }

    load_responses(value) {
        if ('error' in value) {
            console.log(value.error);

            this.setState({
                error: value.error,
                responses: [],
                response_count: 0,
                loading_responses: false,
            });

            return false;
        }

        const request_filters = value.filters;
        if (filters_have_changed(request_filters, this.state.filters)) {
            return false;
        }

        this.setState({
            error: null,
            responses: value.responses,
            response_count: value.response_count,
            loading_responses: false,
        });
    }

    filter_responses(incoming_filters) {
        let state = this.state.filters;

        if (state.offset && state.offset > this.state.response_count) {
            state.offset = this.state.response_count - 1;
        } else if (!state.offset || state.offset < 0) {
            state.offset = 0;
        }
        console.log('Incoming Filters', incoming_filters);
        let new_filters = Object.assign(state, incoming_filters);

        this.setState({ filters: new_filters }, this.refresh_response_data);
    }

    filter_responses_custom(name, incoming_filters) {
        let state = this.state.filters;
        let new_filters = Object.assign(state, incoming_filters);
        this.setState({ filters: new_filters }, this.refresh_response_data);
    }

    save_mapping(incoming_mapping) {
        var url = `/api/home/approachcomponent/${this.props.component_id}/`;
        ajaxWrapper(
            'POST',
            url,
            { ways_to_get_mapping: incoming_mapping },
            () => {
                window.location.reload();
            }
        );
    }

    mark_all_reviewed() {
        var rca_ids = [];
        for (var response of this.state.responses) {
            rca_ids.push(response['rca_id']);
        }

        ajaxWrapper(
            'POST',
            '/api/mark_rca_reviewed/',
            { rca_ids: rca_ids },
            this.refresh_response_data
        );
    }

    render() {
        let response_filters = (
            <ResponseFilterForm
                defaults={this.state.filters}
                question_id={this.props.question_id}
                way_of_thinking_id={this.props.way_of_thinking_id}
                update_filters={this.filter_responses}
                refresh={this.refresh_response_data}
            />
        );

        let error = null;
        if (this.state.error) {
            error = <Alert text={this.state.error} />;
        }

        let headers_row = null;
        let component_display = null;
        let ways_to_get_form = null;

        let question_synonyms_jsx = null;

        let sort_by_length = (
            <Button
                type="info"
                text="Ascending Length"
                onClick={() => this.filter_responses({ longest: true })}
            />
        );
        if (this.state.filters.longest) {
            sort_by_length = (
                <Button
                    type="warning"
                    text="Descending Length"
                    onClick={() => this.filter_responses({ longest: false })}
                />
            );
        }

        if (this.state.loaded) {
            component_display = (
                <div>
                    <span>
                        <b>{this.state.component.name}: </b>
                    </span>
                    <span>{this.state.component.text}</span>
                </div>
            );

            if (this.state.component.type == 'Logic') {
                headers_row = (
                    <tr>
                        <th>Application Answer</th>
                        <th>
                            <span style={{ marginRight: '10px' }}>
                                Explanation
                            </span>
                            {sort_by_length}
                        </th>
                        <th>Clean Explanation</th>
                        <th>Found Phrases</th>
                        <th>Has Component</th>
                        <th>Human Scored?</th>
                    </tr>
                );

                question_synonyms_jsx = (
                    <div>
                        <Button
                            type="info"
                            text="Synonyms"
                            onClick={() => {
                                this.setState({ synonym_display: true });
                            }}
                        />
                        <EmptyModal
                            show={this.state.synonym_display}
                            onHide={() => {
                                this.setState({ synonym_display: false });
                            }}
                        >
                            <div
                                className="container"
                                style={{
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                    color: 'black',
                                }}
                            >
                                <h3>Existing Synonyms</h3>
                                <DictDisplay
                                    dict={this.state.question.synonyms}
                                />
                                <PageBreak
                                    style={{
                                        paddingBottom: '10px',
                                        marginBottom: '10px',
                                    }}
                                />

                                <Button
                                    target="_blank"
                                    text="Edit Question"
                                    type="info"
                                    href={`/edit_question/${this.props.question_id}/`}
                                />
                            </div>
                        </EmptyModal>
                    </div>
                );
            } else {
                headers_row = (
                    <tr>
                        <th>Application Answer</th>
                        <th>
                            <span style={{ marginRight: '10px' }}>
                                Explanation
                            </span>
                            {sort_by_length}
                        </th>
                        <th>Math Parsing</th>
                        <th>Has Component</th>
                        <th>Human Scored?</th>
                    </tr>
                );
            }

            if (this.state.component.type == 'Logic') {
                // Also include question synonyms in some form

                ways_to_get_form = (
                    <LogicComponentWayToGetForm
                        ways_to_get_mapping={this.state.existing_mapping}
                        save_mapping={this.save_mapping}
                    />
                );
            } else {
                ways_to_get_form = (
                    <MathComponentWayToGetForm
                        ways_to_get_mapping={this.state.existing_mapping}
                        save_mapping={this.save_mapping}
                    />
                );
            }
        }

        const response_list = [];
        for (const response of this.state.responses) {
            let wot_text = null;
            if (response.way_of_thinking_id) {
                wot_text = this.state.wot_lookup[response.way_of_thinking_id];
            }

            response_list.push(
                <ComponentAssignment
                    type={this.state.component.type}
                    response={response}
                />
            );
        }

        const response_table = (
            <table className="table">
                {headers_row}
                {response_list}
            </table>
        );

        const next_page = [];
        if (this.state.filters.offset > 0) {
            next_page.push(
                <Button
                    text="Previous"
                    type="warning"
                    onClick={() =>
                        this.filter_responses({
                            offset: Math.max(
                                0,
                                this.state.filters.offset -
                                    this.state.filters.limit
                            ),
                        })
                    }
                />
            );
        }
        if (
            this.state.filters.offset + this.state.filters.limit <
            this.state.response_count
        ) {
            next_page.push(
                <Button
                    text="Next"
                    type="primary"
                    onClick={() =>
                        this.filter_responses({
                            offset:
                                this.state.filters.offset +
                                this.state.filters.limit,
                        })
                    }
                />
            );
        }

        let warning_jsx = null;
        if (this.state.warning) {
            warning_jsx = <Alert type="warning">{this.state.warning}</Alert>;
        }

        var mark_as_reviewed = null;
        if (this.state.filters.under_review == true) {
            mark_as_reviewed = (
                <div style={{ marginTop: '40px' }}>
                    <Button
                        type="warning"
                        text="Mark All As Reviewed"
                        deleteType={true}
                        onClick={this.mark_all_reviewed}
                    />
                </div>
            );
        }

        let content = (
            <div className="sad">
                <div
                    className="container"
                    style={{ paddingTop: '15px', paddingBottom: '15px' }}
                >
                    <div className="simple-card">
                        <Button
                            type="link"
                            text={[
                                <i className="fas fa-arrow-left" />,
                                <span style={{ marginLeft: '5px' }}>
                                    Back to Question
                                </span>,
                            ]}
                            href={`/scoring_configuration/question_component_overview/${this.props.question_id}/`}
                        />
                        <Button
                            type="link"
                            text="MCL Scoring"
                            href={
                                '/response_scoring/' +
                                this.props.question_id +
                                '/'
                            }
                        />
                        <h2>Component Mapping For:</h2>
                        <Alert type="info">
                            <QuestionDisplay
                                question={this.state.question}
                                display="full_text"
                                image
                            />
                            {question_synonyms_jsx}
                        </Alert>
                        <Alert type="info">{component_display}</Alert>

                        <div>{ways_to_get_form}</div>
                        <div className="simple-grey-container">
                            <RunAutoAnalysis component={this.state.component} />
                        </div>
                        <div>{warning_jsx}</div>
                    </div>

                    <div className="simple-card">
                        <h4>Response Explorer</h4>
                        <Alert type="info">
                            {response_filters}
                            <FormWithChildren
                                key={this.state.form_key}
                                row
                                autoSetGlobalState
                                globalStateName="component_search"
                                setGlobalState={this.filter_responses_custom}
                                dont_resolve_anything
                            >

                                <Select
                                    name="scored_by_human"
                                    label="Scored By Human"
                                    className="col-3"
                                    boolean={true}
                                />
                                <Select
                                    className="col-3"
                                    optionsUrl={`/api/user/user/?scored_components__component__id=${this.props.component_id}`}
                                    optionsUrlMap={{
                                        text: '{user.email}',
                                        value: '{user.id}',
                                    }}
                                    name="who_scored_id"
                                    label="Scored By"
                                />
                                <Select
                                    name="under_review"
                                    label="Under Review"
                                    className="col-3"
                                    boolean={true}
                                />
                            </FormWithChildren>
                        </Alert>

                        <Wrapper
                            style={{}}
                            loaded={!this.state.loading_responses}
                        >
                            <p>{this.state.response_count} Responses</p>
                            <Button type='danger' text='Put All Responses Under Review' onClick={this.put_responses_under_review} deleteType={true} />
                            <div className="row" style={{ margin: '0px' }}>
                                {response_table}
                                {error}
                                <div className="col-12">{next_page}</div>
                            </div>
                            {mark_as_reviewed}
                        </Wrapper>
                    </div>
                </div>
            </div>
        );

        return (
            <Wrapper
                style={{ marginTop: '0px' }}
                loaded={this.state.loaded}
                content={content}
            />
        );
    }
}

export default ComponentAutomationMapping;
