import React, { Component } from 'react';
import { sort_objects } from 'functions';

import { FormWithChildren, Button, TextInput, Select, Wrapper } from 'library';
import QuestionOption from './question_option.js';

class AllQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort_by: '',
            order: 'Decending',
            group_by: 'grade',
            category: this.props.scope_category ? this.props.scope_category : '',
            text: '',
            name: '',
        };

        this.update_filters = this.update_filters.bind(this);
        this.toggle_order = this.toggle_order.bind(this);
    }

    update_filters(name, state) {
        this.setState(state);
    }

    toggle_order() {
        if (this.state.order == 'Decending') {
            this.setState({ order: 'Acending' });
        } else {
            this.setState({ order: 'Decending' });
        }
    }

    render() {
        let question_options = [];
        let question_options_jsx = [];
        for (const key in this.props.questions) {
            const question = this.props.questions[key];

            if (
                this.state.category &&
                question.category != this.state.category
            ) {
                continue;
            }
            question_options.push(question);
        }

        if (this.state.sort_by) {
            let reverse = false;
            if (this.state.order == 'Decending') {
                reverse = true;
            }
            question_options = sort_objects(
                question_options,
                ['results', this.state.sort_by, 'pct_correct'],
                reverse
            );
        }

        for (var index = 0; index < question_options.length; index++) {
            if (
                this.state.name == '' ||
                question_options[index].name
                    .toLowerCase()
                    .indexOf(this.state.name.toLowerCase()) > -1
            ) {
                if (
                    this.state.text == '' ||
                    (question_options[index].text_by_language.English &&
                        question_options[
                            index
                        ].text_by_language.English.toLowerCase().indexOf(
                            this.state.text.toLowerCase()
                        ) > -1)
                ) {
                    question_options_jsx.push(
                        <QuestionOption
                            question={question_options[index]}
                            performance={question_options[index].results}
                            group_by={this.state.group_by}
                            group_by_list={
                                this.props.filter_lists[this.state.group_by]
                            }
                            choose_question={this.props.choose_question}
                        />
                    );
                }
            }
        }

        const sort_options = [];
        for (
            var index = 0;
            index < this.props.filter_lists[this.state.group_by].length;
            index++
        ) {
            const filter = this.props.filter_lists[this.state.group_by][index];
            sort_options.push({ text: filter, value: filter });
        }

        if (this.props.questions.length == 0) {
            question_options_jsx = <Wrapper loaded={false} />;
        }

        return (
            <div className="row col-12" style={{ padding: '10px 0px' }}>
                <div className="col-12">
                    <h3>All Questions</h3>
                </div>
                <div className="col-12">
                    <FormWithChildren
                        autoSetGlobalState
                        setGlobalState={this.update_filters}
                        globalStateName="filter"
                        defaults={this.state}
                        row
                    >
                        <Select
                            label="Group"
                            name="group_by"
                            className="col-6"
                            options={[
                                { text: 'By Grade', value: 'grade' },
                                {
                                    text: 'By English Proficiency',
                                    value: 'ell_status',
                                },
                                { text: 'By Race/Ethnicity', value: 'race' },
                            ]}
                        />
                        {!this.props.scope_category && (
                            <Select
                                label="Question Type"
                                name="category"
                                className="col-6"
                                options={[
                                    { text: 'Knowledge', value: 'Knowledge' },
                                    { text: 'Application', value: 'Application' },
                                    { text: 'Mindset', value: 'Mindset' },
                                    { text: 'Field Test', value: 'Field Test' },
                                    { text: 'Not Scored', value: 'Not Scored' },
                                ]}
                            />
                        )}
                        {this.props.scope_category && (
                            <div className='col-6' style={{ verticalAlign: 'middle' }}><b>Question Type: {this.props.scope_category}</b></div>
                        )}
                        <TextInput
                            label="Question Name"
                            name="name"
                            className="col-3"
                        />
                        <TextInput
                            label="Question Text"
                            name="text"
                            className="col-3"
                        />
                        <Select
                            label="Sort By"
                            name="sort_by"
                            className="col-4"
                            options={sort_options}
                        />
                        <Button
                            text={this.state.order}
                            type="info"
                            style={{ margin: '31px 5px 0px 0px' }}
                            onClick={this.toggle_order}
                        />
                    </FormWithChildren>
                </div>
                <div className="col-12">{question_options_jsx}</div>
            </div>
        );
    }
}

export default AllQuestions;
