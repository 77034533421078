import $ from 'jquery';

function ajaxWrapperFile(type, url, data, returnFunc) {
    let headers = {};
    let xhrFields = {};
    if (window.location.hostname === 'localhost') {
        url = 'http://localhost:8000' + url;
        xhrFields['withCredentials'] = true;
    }

    if (type === 'POST') {
        data.csrfmiddlewaretoken = window.secretReactVars.csrfmiddlewaretoken;
        headers['X-CSRFToken'] = window.secretReactVars.csrfmiddlewaretoken;
    }

    // url = "http://localhost:8000" + url;

    $.ajax({
        type,
        url,
        data,
        headers,
        xhrFields,

        dataType: 'json',
        processData: false,
        contentType: false,

        success(value) {
            returnFunc(value);
        },
        error(xhr, _status, _error) {
            throw new Error(xhr.responseText);
        },
    });
}

export default ajaxWrapperFile;
