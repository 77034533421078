import lookup_object from './lookup_object.js';

function join_string_with_conjunctions(values, conjunction) {
    if (typeof conjunction === 'undefined') {
        conjunction = 'or';
    }

    let string = '';
    for (let index = 0; index < values.length; index++) {
        if (index == 0) {
        } else if (index < values.length - 1) {
            string += ', ';
        } else if (index == values.length - 1) {
            string += ` ${conjunction} `;
        }

        string += values[index];
    }

    return string;
}

export default function overview_text(filters, compare_filters) {
    let location = 'District';
    if (filters.teacher) {
        if (window.cmState.user.id == -1) {
            // 8 || window.cmState.user.id == 197) {
            location = 'Demo Teacher';
        } else {
            var teacher = lookup_object(filters.teacher);
            location = `${teacher.first_name} ${teacher.last_name}'s class`;
        }
    } else if (filters.school) {
        if (window.cmState.user.id == -1) {
            // 8 || window.cmState.user.id == 197) {
            location = 'Demo School';
        } else {
            var school = lookup_object(filters.school);
            location = school.name;
        }
    } else if (filters.cluster) {
        var cluster = lookup_object(filters.cluster);
        location = cluster.name;
    } else if (filters.area) {
        var area = lookup_object(filters.area);
        location = area.name;
    }

    let descriptors = '';
    if (filters.ell_status.length > 0) {
        descriptors += `${join_string_with_conjunctions(filters.ell_status)} `;
    }

    descriptors += 'Students';

    if (filters.race.length > 0) {
        descriptors += ` of ${join_string_with_conjunctions(
            filters.race
        )} descent `;
    }

    if (filters.special_ed) {
        descriptors += ' in Special Education ';
    } else if (filters.special_ed == 'false') {
        descriptors += ' not in Special Education ';
    }

    if (filters.grade.length > 0) {
        descriptors += ` in Grade(s) ${join_string_with_conjunctions(
            filters.grade
        )}`;
    }

    const term_text = '';
    let assessment_text = '';
    if (filters.assessments.length > 0) {
        var assessment_names = [];
        for (var i = 0; i < filters.assessments.length; i++) {
            var assessment = lookup_object(filters.assessments[i]);
            assessment_names.push(assessment.name);
        }
        assessment_text = `Results of ${join_string_with_conjunctions(
            assessment_names,
            'and'
        )}`;
    } else if (filters.school_term) {
        var term_name = lookup_object(filters.school_term).name;
        assessment_text = `Results of All Assessments During ${term_name}`;
    }

    // Text for filtered courses
    let course_text = '';
    if (filters.courses.length > 0) {
        const course_names = [];
        for (var i = 0; i < filters.courses.length; i++) {
            const course = lookup_object(filters.courses[i]);
            course_names.push(course.name);
        }
        course_text = ` enrolled in ${join_string_with_conjunctions(
            course_names
        )}`;
        descriptors += course_text;
    }

    let compared_to = 'District';
    if (compare_filters.teacher && compare_filters.teacher != '') {
        console.log('Teacher', compare_filters.teacher);
        var teacher = lookup_object(compare_filters.teacher);
        compared_to = `${teacher.first_name} ${teacher.last_name}`;
    } else if (compare_filters.school) {
        var school = lookup_object(compare_filters.school);
        compared_to = school.name;
    } else if (compare_filters.cluster) {
        var cluster = lookup_object(compare_filters.cluster);
        compared_to = cluster.name;
    } else if (compare_filters.area) {
        var area = lookup_object(compare_filters.area);
        compared_to = area.name;
    }

    let compared_descriptors = '';
    if (compare_filters.ell_status.length > 0) {
        compared_descriptors += `${join_string_with_conjunctions(
            compare_filters.ell_status
        )} `;
    }

    compared_descriptors += 'Students';

    if (compare_filters.race.length > 0) {
        compared_descriptors += ` of ${join_string_with_conjunctions(
            compare_filters.race
        )} descent `;
    }

    if (compare_filters.special_ed) {
        compared_descriptors += ' in Special Education ';
    } else if (compare_filters.special_ed == 'false') {
        compared_descriptors += ' not in Special Education ';
    }

    if (compare_filters.grade.length > 0) {
        compared_descriptors += ` in Grade(s) ${join_string_with_conjunctions(
            compare_filters.grade
        )}`;
    }

    let compare_assessment_text = '';
    const compare_term_text = '';
    if (compare_filters.assessments.length > 0) {
        var assessment_names = [];

        for (const item of compare_filters.assessments) {
            var assessment = lookup_object(item);
            assessment_names.push(assessment.name);
        }

        compare_assessment_text = `Results of ${join_string_with_conjunctions(
            assessment_names
        )}`;
    } else if (compare_filters.school_term) {
        var term_name = lookup_object(compare_filters.school_term).name;
        compare_assessment_text = `Results of All Assessments During ${term_name}`;
    }

    let overview = location;

    if (descriptors != 'Students') {
        overview = `${descriptors} in ${location}`;

        if (compared_descriptors != 'Students') {
            compared_to = `${compared_descriptors} in ${compared_to}`;
        } else if (compared_to == 'District') {
            compared_to = `${compared_to} excluding ${descriptors}`;
            if (location.indexOf('District') == -1) {
                compared_to += ` in ${location}`;
            }
        } else {
            compared_to = `${descriptors} in ${compared_to}`;
        }
    }
    if (compared_descriptors != 'Students') {
        compared_to = `${compared_descriptors} in ${compared_to}`;
    }

    if (assessment_text) {
        overview = `${assessment_text} for ${overview}`;
    }

    if (
        compare_filters.assessments.length == 0 &&
        filters.school_term == compare_filters.school_term &&
        assessment_text
    ) {
        compared_to = `${assessment_text} for ${compared_to}`;
    } else if (compare_assessment_text) {
        compared_to = `${compare_assessment_text} for ${compared_to}`;
    }

    return {
        overview,
        compared_to,
    };
}
