import React, { Component } from 'react';

import {
    FormWithChildren,
    TextInput,
    Select,
} from 'library';


class LogicComponentWayToGetForm extends Component {
    constructor(props) {
        super(props);
        
        let ways_to_get_mapping = Object.assign({
            'numbers': '',
            'units': '',
            'context': '',
        }, this.props.ways_to_get_mapping);
        if (!('phrases' in ways_to_get_mapping)){
            ways_to_get_mapping['phrases'] = [];
        }

        this.state = {
            phrases: ways_to_get_mapping['phrases'],
            numbers: ways_to_get_mapping['numbers'],
            units: ways_to_get_mapping['units'],
            context: ways_to_get_mapping['context'],
        };

        this.add_phrase = this.add_phrase.bind(this);
        this.update_phrases = this.update_phrases.bind(this);
        this.save_mapping = this.save_mapping.bind(this);
    }

    componentDidMount() {}

    add_phrase(event) {
        if (event.key === 'Enter') {
            var value = event.currentTarget.value;
            if (!value || this.state.phrases.includes(value)) {
                this.setState({
                    warning: 'Phrase already submitted',
                });
                return false;
            }

            let new_phrases = [...this.state.phrases];
            new_phrases.push(value);
            this.setState({ phrases: new_phrases, warning: '' });
            event.currentTarget.value = '';
        }
    }

    update_phrases(name, state) {
        let new_state = {};

        if ('phrases' in state){
            new_state['phrases'] = [...state['phrases']];
        }
        for (let item of ['numbers', 'units', 'context']){
            if (item in state){
                new_state[item] = state[item];
            }
        }

        if (Object.keys(new_state).length > 0) {
            this.setState(new_state);
        }
    }


    save_mapping() {
        let existing_mapping = {
            phrases: [...this.state.phrases],
            numbers: this.state.numbers,
            units: this.state.units,
            context: this.state.context,
        };

        this.props.save_mapping(existing_mapping);
    }

    render() {
        let existing_phrase_options = this.state.phrases.map(
            data => ({
                text: data,
                value: data,
            })
        );

        let defaults = {
            phrases: this.state.phrases,
            numbers: this.state.numbers,
            units: this.state.units,
            context: this.state.context,
        };

        let edit_form = (
            <div className="simple-grey-container">
                <div className="simple-card">
                    <TextInput
                        name='phrase'
                        label="New Phrase (Enter to submit)"
                        handleKeyPress={this.add_phrase}
                    />
                    <FormWithChildren
                        submit={this.save_mapping}
                        autoSetGlobalState={true}
                        setGlobalState={this.update_phrases}
                        defaults={defaults}
                        row={true}
                    >
                        <Select
                            label="Phrases"
                            name="phrases"
                            options={existing_phrase_options}
                            multiple={true}
                            hide_select={true}
                            className='col-12'
                        />

                        <TextInput name='numbers' label='Numbers (Separated by commas)' className='col-4' />
                        <TextInput name='units' label='Units (Separated by commas)' className='col-4' />
                        <TextInput name='context' label='Context (Separated by commas)' className='col-4' />
                        
                    </FormWithChildren>
                </div>
            </div>
        );

        return (
            <div className="simple-grey-container">
                {edit_form}
            </div>
        );
    }
}

export default LogicComponentWayToGetForm;
