export default function student_list_header_text(filters) {
    let title = 'Students';
    let percent_text = '% of students';

    if (filters.student_list.category && filters.student_list.proficiency) {
        title +=
            ` with a ${ 
            filters.student_list.proficiency 
            } in ${ 
            filters.student_list.category}`;
        percent_text +=
            ` got a ${ 
            filters.student_list.proficiency 
            } in ${ 
            filters.student_list.category}`;
    } else if (filters.student_list.question_id) {
        if (filters.student_list.question_answer) {
            title +=
                ` who answered with ${  filters.student_list.question_answer}`;
            percent_text +=
                ` answered with ${  filters.student_list.question_answer}`;
        } else {
            title += ' who got the correct answer';
            percent_text += ' got the correct answer';
        }
    } else if (filters.student_list.way_of_thinking) {
        title += ` who used ${  filters.student_list.way_of_thinking_name}`;
        percent_text += ` used ${  filters.student_list.way_of_thinking_name}`;
    }

    return {
        title,
        percent: percent_text,
    };
}
