import React from 'react';

class Alert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            class: ' start',
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                class: '',
            });
        }, 100);
    }

    render() {
        let type = 'primary';
        if (this.props.type) {
            type = this.props.type;
        }

        let animation = '';
        if (this.props.animated) {
            animation = ` animated_alert${this.state.class}`;
        }

        return (
            <div
                className={`alert alert-${type}${animation}`}
                role="alert"
                style={this.props.style}
            >
                {this.props.text}
                {this.props.children}
            </div>
        );
    }
}

export default Alert;
