import React, { Component } from 'react';

import { Button } from 'library';


export default class AssessmentDisplay extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        var assessment = this.props.assessment;

        let style = Object.assign({
            display: 'flex',
            borderWidth: '1px',
            margin: '5px'
        }, this.props.style);

        var content = <div style={style} >
            {`${assessment.name} for ${assessment.district.name}`}
        </div>;

        if (this.props.link){
            content = <Button type={this.props.type}
                href={`/edit_assessment/${assessment.id}`}
                target="_blank"
                style={style}

                text={`${assessment.name} for ${assessment.district.name}`}
            />;
        }

        return (content)
    }
}

