import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    FileInput,
    Alert,
    Wrapper,
} from 'library';

class UploadAssessmentResponses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            assessment: {},
            questions: [],
            error: '',
        };

        this.get_assessment = this.get_assessment.bind(this);
        this.get_questions = this.get_questions.bind(this);
        this.upload_csv = this.upload_csv.bind(this);
        this.upload_callback = this.upload_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/assessment/${this.props.assessment_id}/`,
            {},
            this.get_assessment
        );
        ajaxWrapper(
            'GET',
            `/api/home/questiononassessment/?related=question&order_by=order&assessment=${this.props.assessment_id}`,
            {},
            this.get_questions
        );
    }

    get_assessment(result) {
        const { assessment } = result[0];

        this.setState({ assessment, loaded: true });
    }

    get_questions(result) {
        console.log('Get Questions', result);
        const questions = [];
        for (const index in result) {
            questions.push(result[index].questiononassessment.question);
        }
        this.setState({ questions });
    }

    upload_csv(state) {
        console.log('State', state);
        ajaxWrapper(
            'POST',
            `/upload_assessment_responses_csv/${this.props.assessment_id
            }/`,
            state,
            this.upload_callback
        );
    }

    upload_callback(result) {
        console.log('Upload Result', result);
        if (result.error != '') {
            this.setState({ error: result.error });
        } else {
            window.location.href =
                `/review_assessment_responses/${this.props.assessment_id
                }/`;
        }
    }

    render() {
        let content = null;

        if (this.state.loaded) {
            const questions = [<p style={{ marginLeft: '20px' }}>Student ID</p>];
            for (const index in this.state.questions) {
                const question = this.state.questions[index];
                questions.push(
                    <p style={{ marginLeft: '20px' }}>{question.name}</p>
                );
            }

            let error = null;
            if (this.state.error != '') {
                error = <Alert type="danger" text={this.state.error} />;
            }

            content = (
                <div className="container">
                    <h2>
                        Upload Responses to Assessment:{' '}
                        {this.state.assessment.name}
                    </h2>
                    <h4 style={{ marginTop: '50px' }}>Upload CSV</h4>

                    <FormWithChildren
                        submit={this.upload_csv}
                        submit_text="Upload"
                    >
                        <FileInput
                            required
                            name="assessment_responses"
                        />
                    </FormWithChildren>

                    {error}

                    <h4 style={{ marginTop: '50px' }}>
                        Please Upload the Assessment as a csv with the following
                        headers:
                    </h4>
                    {questions}
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default UploadAssessmentResponses;
