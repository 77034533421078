import React, { Component } from 'react';
import { ajaxWrapperFile } from 'functions';
import { Audio, Alert } from 'library';

class AudioInput extends Component {
    static component_name = 'AudioInput';

    constructor(props) {
        super(props);

        this.state = {
            files: null,
            uploaded_files: [],
        };

        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
    }

    onChange(e) {
        this.setState(
            {
                files: e.target.files,
                error: null,
            },
            this.fileUpload(e.target.files)
        );
    }

    fileUpload(files) {
        this.props.setFormState({ uploading_from_file_input: true });

        let url = '/files/audioUpload/';
        if (this.props.uploadUrl) {
            url = this.props.uploadUrl;
        }

        const formData = new FormData();
        Object.keys(files).forEach(index => {
            if (index != 'length' && index != 'item') {
                formData.append('files[]', files[index]);
            }
        });

        formData.append('name', this.props.name);

        if (typeof this.props.is_public !== 'undefined') {
            formData.append('is_public', this.props.is_public);
        } else {
            formData.append(
                'is_public',
                !window.secretReactVars.secure_uploads
            );
        }

        ajaxWrapperFile('POST', url, formData, this.fileUploadCallback);
    }

    fileUploadCallback(value) {
        if ('error' in value.uploaded_files[0]) {
            this.setState({ error: value.uploaded_files[0].error });
            return false;
        }

        const newState = { uploading_from_file_input: false };
        newState[this.props.name] = value.uploaded_files[0].url;

        this.props.setFormState(newState);
    }

    render() {
        const { styleInput, styleAudio } = this.props;
        const input = (
            <input
                onChange={this.onChange}
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                name={this.props.name}
                style={styleInput}
            />
        );

        let current = null;
        if (this.props.value !== '') {
            current = <Audio src={this.props.value} style={styleAudio} />;
        }

        let error = null;
        if (this.state.error) {
            error = <Alert type="danger" text={this.state.error} />;
        }

        return (
            <div className="form-group">
                <label htmlFor="exampleFormControlFile1">
                    {this.props.label}
                </label>
                <br />
                {error}
                {current}
                {input}
            </div>
        );
    }
}

export default AudioInput;
