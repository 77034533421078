import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

import { FormWithChildren, Select } from 'library';
import { sort_objects } from 'functions';

export default class EssentialMathAssetsReport extends Component {
    constructor(props) {
        super(props);

        let options = {
            grade: [],
            race: [],
            ell_status: [],
        };
        for (let key in this.props.report['data']) {
            let pieces = key.split(/_/g);

            if (!options['grade'].includes(pieces[0])) {
                options['grade'].push(pieces[0]);
            }
            if (!options['race'].includes(pieces[1])) {
                options['race'].push(pieces[1]);
            }
            if (!options['ell_status'].includes(pieces[2])) {
                options['ell_status'].push(pieces[2]);
            }
        }

        let final_options = {};
        for (let key in options) {
            final_options[key] = [];
            for (let item of options[key]) {
                let item_text = item;
                if (item == 'None') {
                    item_text = '';
                }

                final_options[key].push({
                    text: item_text,
                    value: item,
                });
            }

            sort_objects(final_options[key], ['text']);
        }

        this.state = {
            options: final_options,
            filters: {
                grade: 'None',
                race: 'None',
                ell_status: 'None',
            },
        };

        this.update_filters = this.update_filters.bind(this);
    }

    update_filters(name, state) {
        let filters = this.state.filters;

        for (let key in state) {
            filters[key] = state[key];
        }

        this.setState({
            filters: filters,
        });
    }

    render() {
        const colors = [
            '#47E3FA',
            '#40AEFA',
            '#3878F9',
            '#3232A4',
            '#6a587e',
            '#9488e4',
        ];

        let grade_filter = null;
        let race_filter = null;
        let ell_filter = null;
        if (this.state.options['grade'].length > 1) {
            grade_filter = (
                <Select
                    name="grade"
                    label="Grade"
                    className="col-12"
                    options={this.state.options['grade']}
                    no_blank_option={true}
                ></Select>
            );
        }
        if (this.state.options['grade'].length > 1) {
            race_filter = (
                <Select
                    name="race"
                    label="Race"
                    className="col-12"
                    options={this.state.options['race']}
                    no_blank_option={true}
                ></Select>
            );
        }
        if (this.state.options['ell_status'].length > 1) {
            ell_filter = (
                <Select
                    name="ell_status"
                    label="English Learner"
                    className="col-12"
                    options={this.state.options['ell_status']}
                    no_blank_option={true}
                ></Select>
            );
        }

        let labels_ordered = [
            'Computationally Accurate',
            'Units',
            'Quantities in Context',
            'Mathematical Words and Symbols',
            'Diagrams',
            'Spatial Reasoning',
        ];

        let lookup_key = `${this.state.filters['grade']}_${this.state.filters['race']}_${this.state.filters['ell_status']}`;
        let report_data = this.props.report.data[lookup_key];

        let data = [];
        //let labels = []; //Object.keys(report_data); //[1, 2, 3, 4];

        if (report_data) {
            //labels = Object.keys(report_data);
            for (let key of labels_ordered) {
                if (key in report_data && report_data[key]['max'] > 0) {
                    data.push(
                        parseInt(
                            (report_data[key]['total'] /
                                report_data[key]['max']) *
                                100
                        )
                    );
                } else {
                    data.push(0);
                }
            }
        }

        const chart_data = {
            labels: labels_ordered,
            datasets: [
                {
                    label: `Results`,
                    data,
                    backgroundColor: colors,
                },
            ],
        };

        const pie_options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    position: 'right',
                    align: 'center',
                    labels: {
                        usePointStyle: true,
                        font: {
                            size: 14,
                        },
                        padding: 20,
                    },
                },
            },
            scales: {
                y: {
                    min: 0,
                    max: 100,
                },
            },
        };

        return (
            <div>
                <div>
                    <div
                        className="h1"
                        style={{ fontSize: '24px', marginBottom: '15px' }}
                    >
                        Essential Math Assets Report
                    </div>
                </div>
                <div className="sad-simple-card-container row">
                    <div className="col-8 " style={{ padding: '0px' }}>
                        <div className="simple-card">
                            <div
                                style={{
                                    padding: '20px 0px',
                                }}
                            >
                                <Bar
                                    data={chart_data}
                                    options={pie_options}
                                    style={{
                                        cursor: 'pointer',
                                        width: '100%',
                                        maxWidth: '800px',
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-4 " style={{ padding: '0px' }}>
                        <div className="simple-card">
                            <div>
                                <div
                                    style={{
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        padding: '15px',
                                    }}
                                >
                                    Filter your results:
                                </div>
                                <FormWithChildren
                                    key={'choose_a_school'}
                                    className="row"
                                    autoSetGlobalState
                                    setGlobalState={this.update_filters}
                                    globalStateName="filter"
                                    defaults={this.state.filters}
                                >
                                    {grade_filter}
                                    {race_filter}
                                    {ell_filter}
                                </FormWithChildren>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
