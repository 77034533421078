import React from 'react';

class TableHeader extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.sort_by != '' && this.props.sort_name != '') {
            // Subscribe to sort_name
            window.cmState.subscribe_by_name(this, this.props.sort_name);

            const current_sort =
                window.cmState.getGlobalState()[this.props.sort_name];
            const reverse_sort = `-${this.props.sort_by}`;
            if (this.props.sort_by == current_sort) {
                window.cmState.setGlobalState(
                    this.props.sort_name,
                    reverse_sort
                );
            } else if (reverse_sort == current_sort) {
                window.cmState.setGlobalState(
                    this.props.sort_name,
                    this.props.sort_by
                );
            }
        }
    }

    render() {
        const style = this.props.style || {};
        if (this.props.sort_name != '' && this.props.sort_name != undefined) {
            const current_sort =
                window.cmState.getGlobalState()[this.props.sort_name];
            const reverse_sort = `-${this.props.sort_by}`;
            // style['cursor'] = 'pointer';
        }

        return (
            <th onClick={this.onClick} style={style}>
                {this.props.data || this.props.children}
            </th>
        );
    }
}

export default TableHeader;
