import React from 'react';
import { CSVLink } from 'react-csv';
import ajaxWrapper from 'base/ajax.js';

import { EmptyModal, Wrapper } from 'library';

export default class DownloadModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };

        this.csv_callback = this.csv_callback.bind(this);
    }

    componentDidMount() {
        if (this.props.url) {
            ajaxWrapper(
                'POST',
                this.props.url,
                this.props.query,
                this.csv_callback
            );
        } else if (this.props.data) {
            this.setState({
                data: this.props.data,
                loaded: true,
            });
        }
    }

    csv_callback(value) {
        this.setState({
            loaded: true,
            data: value,
        });
    }

    render() {
        let content = (
            <Wrapper
                loaded
                style={{ margin: '0px', padding: '20px', textAlign: 'center' }}
            >
                <div className="h2 bold header">
                    Your data is being prepared. Please hold.
                </div>
            </Wrapper>
        );

        var filename = "MathAnex_StudentParticipation.csv"
        if (this.props.filename) {
            filename = this.props.filename
        }

        if (this.state.loaded) {
            content = (
                <Wrapper
                    loaded
                    style={{
                        margin: '0px',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    <div className="h2 bold header">
                        Your Download is Ready.
                    </div>
                    <div>
                        <CSVLink
                            filename={filename}
                            data={this.state.data}
                            style={{ display: 'inline-block' }}
                        >
                            <div className="purple-button">Download Data</div>
                        </CSVLink>
                    </div>
                </Wrapper>
            );
        }

        return (
            <EmptyModal show onHide={this.props.onHide}>
                {content}
            </EmptyModal>
        );
    }
}
