import React from 'react';

function PageNotFound() {


    return (
        <div>
            <br />
            <br />
            <br />
            <br />

            <div style={{ textAlign: 'center' }}>
                <h2>
                    Something isn`t adding up! The page you`re looking for
                    is nowhere to be found.
                </h2>
            </div>
        </div>
    );

}

export default PageNotFound;
