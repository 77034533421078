import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Alert,
    Wrapper,
    FormWithChildren,
    TextInput,
    Select,
} from 'library';

import { filters_have_changed } from 'functions';

import QuestionDisplay from 'pages/questions/question_display.js';
import ResponseFilterForm from './response_filter_form';

const MCL_VALUES = ['NA', 'NE', 'm', 'M', 'c', 'C', 'l', 'L'];

class StudentQuestionResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changed: false,
        };
    }

    render() {
        let evidence_options = [
            { text: 'Blank', value: 'Blank' },
            { text: 'No Evidence', value: 'No Evidence' },
            { text: 'Emerging Evidence', value: 'Emerging Evidence' },
            { text: 'Partial Evidence', value: 'Partial Evidence' },
            { text: 'Sufficient Evidence', value: 'Sufficient Evidence' },
        ];

        let response = this.props.response;

        let mcl_and_evidence = [
            <td>{response.mcl_score}</td>,
            <td>{response.explanation_evidence}</td>,
        ];
        if (this.props.allow_editing) {
            let alert_type = 'warning';
            if (this.state.changed) {
                alert_type = 'success';
            }
            mcl_and_evidence = (
                <td colspan="2">
                    <Alert type={alert_type}>
                        <FormWithChildren
                            submitUrl={`/update_student_question_response_scoring/${response.id}/`}
                            redirect={() => this.setState({ changed: true })}
                            style={{ display: 'flex', alignItems: 'baseline' }}
                        >
                            <TextInput
                                name="mcl_score"
                                default={response.mcl_score}
                            />
                            <Select
                                name="explanation_evidence"
                                options={evidence_options}
                                default={response.explanation_evidence}
                            />
                        </FormWithChildren>
                    </Alert>
                </td>
            );
        }

        return (
            <tr>
                <td>
                    <a
                        href={`/student_response/${response.id}/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {response.answer}
                    </a>
                </td>
                <td>{response.non_explanation_answer}</td>
                {mcl_and_evidence}
            </tr>
        );
    }
}

class ResponseExplorer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: null,

            loaded: false,
            loading_responses: false,
            responses: [],
            question: null,
            wot_lookup: {},

            filters: {
                answer_include: '',
                answer_exclude: '',
                explanation_include: '',
                explanation_exclude: '',
                components_include: [],
                components_exclude: [],

                way_of_thinking: null,
                limit: 45,
                offset: 0,

                max_characters: 400,
                max_rows: 15,
                longest: false,
                mcl: [],
                exclude_mcl: [],
            },

            answer_columns: null,
            displayed_responses: [],

            show_filters: true,
            component_options: [],
        };

        this.refresh_response_data = this.refresh_response_data.bind(this);
        this.load_responses = this.load_responses.bind(this);

        this.filter_responses = this.filter_responses.bind(this);
        this.mark_all_reviewed = this.mark_all_reviewed.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            result => {
                this.setState({
                    loaded: true,
                    question: result[0]['question'],
                });
            }
        );

        this.refresh_response_data();
    }

    mark_all_reviewed() {
        var response_ids = [];
        for (var response of this.state.responses) {
            response_ids.push(response['id']);
        }

        ajaxWrapper(
            'POST',
            '/api/mark_responses_reviewed/',
            { response_ids: response_ids },
            this.refresh_response_data
        );
    }

    refresh_response_data() {
        this.setState({ loading_responses: true });

        const data = this.state.filters;
        if (data['offset'] == '') {
            data['offset'] = 0;
        }
        ajaxWrapper(
            'POST',
            `/get_filtered_responses/${this.props.question_id}/`,
            data,
            this.load_responses
        );
    }

    load_responses(value) {
        if ('error' in value) {
            console.log(value.error);

            this.setState({
                error: value.error,
                responses: [],
                response_count: 0,
                loading_responses: false,
            });

            return false;
        }

        const request_filters = value.filters;
        if (filters_have_changed(request_filters, this.state.filters)) {
            return false;
        }

        this.setState({
            error: null,
            responses: value.responses,
            response_count: value.response_count,
            loading_responses: false,
        });
    }

    filter_responses(incoming_filters) {
        let state = this.state.filters;

        if (state.offset && state.offset > this.state.response_count) {
            state.offset = this.state.response_count - 1;
        } else if (!state.offset || state.offset < 0) {
            state.offset = 0;
        }

        let new_filters = Object.assign(state, incoming_filters);
        if ('review' in new_filters && new_filters['review'] == true) {
            //new_filters['has_been_reviewed'] = false;
        }

        this.setState({ filters: new_filters }, this.refresh_response_data);
    }

    render() {
        let mcl_options = [];
        for (let item of MCL_VALUES) {
            mcl_options.push({ text: item, value: item });
        }

        let response_filters = (
            <ResponseFilterForm
                question_id={this.props.question_id}
                way_of_thinking_id={this.props.way_of_thinking_id}
                update_filters={this.filter_responses}
                refresh={this.refresh_response_data}
            />
        );

        var mark_as_reviewed = null;
        /*
        if (this.state.filters.review == true) {
            mark_as_reviewed = (
                <div style={{ marginTop: '40px' }}>
                    <Button
                        type="warning"
                        text="Mark All As Reviewed"
                        deleteType={true}
                        onClick={this.mark_all_reviewed}
                    />
                </div>
            );
        }
        */

        let error = null;
        if (this.state.error) {
            error = <Alert text={this.state.error} />;
        }

        const response_list = [];
        for (const response of this.state.responses) {
            let wot_text = null;
            if (response.way_of_thinking_id) {
                wot_text = this.state.wot_lookup[response.way_of_thinking_id];
            }

            let allow_editing = true;
            /*
            if ('review' in this.state.filters) {
                allow_editing = true;
            }
            */
            response_list.push(
                <StudentQuestionResponse
                    response={response}
                    allow_editing={allow_editing}
                />
            );
        }

        let sort_by_length = (
            <Button
                type="info"
                text="Ascending Length"
                onClick={() =>
                    this.filter_responses('search', { longest: true })
                }
            />
        );
        if (this.state.filters.longest) {
            sort_by_length = (
                <Button
                    type="warning"
                    text="Decending Length"
                    onClick={() =>
                        this.filter_responses('search', { longest: false })
                    }
                />
            );
        }

        const response_table = (
            <table className="table">
                <tr>
                    <th style={{ width: '30%' }}>
                        <span style={{ marginRight: '10px' }}>Explanation</span>
                        {sort_by_length}
                    </th>
                    <th style={{ width: '20%' }}>Answer</th>
                    <th style={{ width: '20%' }}>MCL Score</th>
                    <th style={{ width: '30%' }}>
                        Evidence of A Quality Explanation
                    </th>
                </tr>
                {response_list}
            </table>
        );

        const content = (
            <div className="container">
                <div
                    className="row"
                    style={{ paddingTop: '15px', paddingBottom: '15px' }}
                >
                    <div className="col-12">
                        <Button
                            type="link"
                            text={[
                                <i className="fas fa-arrow-left" />,
                                <span style={{ marginLeft: '5px' }}>
                                    Back to Question
                                </span>,
                            ]}
                            href={`/scoring_configuration/question_component_overview/${this.props.question_id}/`}
                        />
                        <Button
                            type="link"
                            text="MCL Scoring"
                            href={
                                '/response_scoring/' +
                                this.props.question_id +
                                '/'
                            }
                        />
                        <h1>Explore Responses To:</h1>
                        <Alert type="info">
                            <QuestionDisplay
                                question={this.state.question}
                                display="text"
                                image
                            />
                        </Alert>

                        <Alert type="info" text={response_filters} />
                    </div>

                    <Wrapper style={{}} loaded={!this.state.loading_responses}>
                        <div className="row" style={{ margin: '0px' }}>
                            <p>{this.state.response_count} Responses</p>
                            {response_table}
                            {error}

                            {mark_as_reviewed}
                        </div>
                    </Wrapper>
                </div>
            </div>
        );

        return (
            <Wrapper style={{}} loaded={this.state.loaded} content={content} />
        );
    }
}

export default ResponseExplorer;
