import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import {
    TextInput,
    Select,
    NumberInput,
    Alert,
    FormWithChildren,
    Wrapper,
} from 'library';

class WayOfThinking extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
    }

    submit(state, callback) {
        console.log(state);

        const error_text = [];

        if (!('name' in state) || !state.name) {
            error_text.push('Please enter a name.');
        }

        if (!('answers' in state) || state.answers.length == 0) {
            error_text.push('Please add at least one answer.');
        }

        if (!('order' in state) || state.order == 0) {
            error_text.push('Please set order value to something above 0.');
        }

        if (error_text.length > 0) {
            this.setState({
                error: error_text,
            });

            setTimeout(
                () => {
                    callback({ success: true });
                },
                100
            );

            return false;
        }

        state.answer_mapping = {
            answers: state.answers,
            components: state.components,
        };

        let url = '/api/home/wayofthinking/';
        const { way_of_thinking } = this.props;
        if (way_of_thinking) {
            url += `${way_of_thinking.id}/`;
        }

        this.setState({
            form_callback: callback,
        });
        ajaxWrapper('POST', url, state, this.submit_callback);
    }

    submit_callback() {
        const { refresh_data } = this.props;
        const { form_callback } = this.state;
        refresh_data();

        if (form_callback) {
            form_callback({ success: true });
        }

        this.setState({
            error: null,
            form_callback: null,
        });
    }

    render() {
        let content = null;
        let { way_of_thinking } = this.props;

        const valid_answers = [];
        const missing_answers = [];

        const urls = {
            // submitUrl: '/api/home/wayofthinking/',
        };
        let title = 'Add New';
        if (way_of_thinking) {
            title = way_of_thinking.name;
            // urls['submitUrl'] += way_of_thinking['id'] +'/';
            urls.deleteUrl =
                `/api/home/wayofthinking/${way_of_thinking.id}/delete/`;

            if ('answers' in way_of_thinking.answer_mapping) {
                const { answer_mapping } = way_of_thinking;
                const { answers } = answer_mapping;
                const { answer_list } = this.props;
                answers.forEach((answer) => {
                    if (answer_list.indexOf(answer) > -1) {
                        valid_answers.push(answer);
                    } else {
                        missing_answers.push(answer);
                    }
                })
            }

            way_of_thinking.answers = valid_answers;
            way_of_thinking.components =
                way_of_thinking.answer_mapping.components;
        } else {
            const { question_id } = this.props;
            way_of_thinking = {
                question: question_id,
            };
        }

        let missing_answers_jsx = null;
        if (missing_answers.length > 0) {
            missing_answers_jsx = (
                <Alert type="warning">
                    <div>
                        The following answers were not found and need to be
                        remapped.
                    </div>
                    <div>{missing_answers.join(', ')}</div>
                </Alert>
            );
        }

        const { error: error_state } = this.state;
        const error = error_state ? error_state.map((item) => (<Alert type="danger" key={item}>{item}</Alert>))
            : [];

        const { answer_options, component_options } = this.props;
        const form_elements = [
            <h4>{title}</h4>,
            <TextInput name="name" label="Name" />,
            <NumberInput name="order" label="Order" />,

            <Select
                name="answers"
                label="Answers"
                options={answer_options}
                multiple
            />,
            missing_answers_jsx,

            <Select
                name="components"
                label="Components"
                options={component_options}
                multiple
            />,

            <TextInput name="question" style={{ display: 'none' }} />,
        ];

        form_elements.push(...error);

        const form = (
            <div className="box-item">
                <FormWithChildren
                    defaults={way_of_thinking}
                    submit={this.submit}
                    {...urls}
                    reset_state_on_submit
                >
                    {form_elements}
                </FormWithChildren>
            </div>
        );

        content = form;

        return content;
    }
}

class EditWaysOfThinking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ways_of_thinking: [],
            component_options: [],
            loaded: false,
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.ways_of_thinking_callback =
            this.ways_of_thinking_callback.bind(this);
        this.load_approaches = this.load_approaches.bind(this);
    }

    componentDidMount() {
        const { question_id } = this.props;
        ajaxWrapper(
            'GET',
            `/api/home/approach/?related=components&question=${question_id}`,
            {},
            this.load_approaches
        );

        this.refresh_data();
    }

    refresh_data() {
        const { question_id } = this.props;
        const url =
            `/api/home/wayofthinking/?question=${question_id
            }&order_by=order`;
        ajaxWrapper('GET', url, {}, this.ways_of_thinking_callback);
    }

    load_approaches(result) {
        const component_options = [];
        result.forEach((approach) => {
            const { components } = approach.approach;
            components.forEach((component) => {
                const component_name = component.approachcomponent.name;
                component_options.push({
                    text: component_name,
                    value: component_name,
                });
            })
        })

        component_options.sort((a, b) => (a.value > b.value ? 1 : -1));

        this.setState({
            component_options,
        });
    }

    ways_of_thinking_callback(result) {
        const ways_of_thinking = result.map((way_of_thinking) => way_of_thinking.wayofthinking);

        this.setState({
            ways_of_thinking,
            loaded: true,
        });
    }

    render() {
        const ways_of_thinking = [];
        const { scoring, scored: scoredState, ways_of_thinking: ways_of_thinkingState, component_options } = this.state;
        const score_disabled = {};
        if (scoring) {
            score_disabled.disabled = 'disabled';
        }
        const { question_id, answer_options, answer_list } = this.props;
        ways_of_thinkingState.forEach((way_of_thinking) => {

            ways_of_thinking.push(
                <WayOfThinking
                    key={way_of_thinking.id}
                    question_id={question_id}
                    way_of_thinking={way_of_thinking}
                    refresh_data={this.refresh_data}
                    component_options={component_options}
                    answer_options={answer_options}
                    answer_list={answer_list}
                />
            );
        })

        ways_of_thinking.push(
            <WayOfThinking
                key="add_new"
                question_id={question_id}
                refresh_data={this.refresh_data}
                component_options={component_options}
                answer_options={answer_options}
                answer_list={answer_list}
            />
        );

        const content = (
            <div className="container">
                <h1>Ways Of Thinking:</h1>
                <div className="shadowbox">{ways_of_thinking}</div>
                <br />
                <br />
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default EditWaysOfThinking;
