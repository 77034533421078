import React, { Component } from 'react';

import { Select } from 'library';

import PercentDisplay from 'pages/reports/results_explorer/components/percent_display.js';

class WayOfThinking extends Component {
    constructor(props) {
        super(props);
        this.state = { modal: false };

        this.toggle_modal = this.toggle_modal.bind(this);
        this.show_student_list = this.show_student_list.bind(this);
    }

    toggle_modal() {
        this.setState({ modal: !this.state.modal });
    }

    show_student_list() {
        const data = {
            way_of_thinking: this.props.way_of_thinking.id,
            way_of_thinking_name: this.props.way_of_thinking.name,
        };
        this.props.update_filters('question_details', data, 'Questions');
    }

    render() {
        const wot = this.props.way_of_thinking;

        let example_text = '';
        for (const item of wot.example.split('\\n')) {
            example_text += `<p>${item}</p>`;
        }

        return (
            <div className="col-4" style={{ padding: '5px' }}>
                <div
                    className="card"
                    style={{ marginBottom: '15px', padding: '15px' }}
                >
                    <div
                        title={wot.name}
                        className="h2 bold header"
                        style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden',
                        }}
                    >
                        {wot.name}
                    </div>
                    <div
                        className="sad"
                        style={{
                            borderRadius: '4px',
                            padding: '10px',
                            margin: '10px 0px 20px',
                        }}
                    >
                        <span className="grey-text">Example Response: </span>
                        <span
                            style={{
                                maxHeight: '150px',
                                height: '150px',
                                overflowY: 'auto',
                                display: 'flex',
                            }}
                            dangerouslySetInnerHTML={{ __html: example_text }}
                        />
                    </div>

                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <PercentDisplay
                            percent={
                                wot.percent.percent
                                    ? wot.percent.percent
                                    : wot.percent
                            }
                            diff={null}
                        />
                    </div>

                    <div
                        className="purple-button"
                        onClick={this.show_student_list}
                    >
                        View Student Responses
                    </div>
                </div>
            </div>
        );
    }
}

export default class WaysOfThinking extends Component {
    constructor(props) {
        super(props);
        this.state = { filter: 'Descending' };
    }

    render() {
        const ways_of_thinking = [];
        if (
            this.props.question_responses &&
            this.props.question_responses.answers
        ) {
            let answers = [...this.props.question_responses.answers];
            if (this.state.filter) {
                if (this.state.filter === 'Descending') {
                    answers = answers.sort((a, b) => {
                        const x = a.percent.percent
                            ? a.percent.percent
                            : a.percent;
                        const y = b.percent.percent
                            ? b.percent.percent
                            : b.percent;
                        return y - x;
                    });
                } else {
                    answers = answers.sort((a, b) => {
                        const x = a.percent.percent
                            ? a.percent.percent
                            : a.percent;
                        const y = b.percent.percent
                            ? b.percent.percent
                            : b.percent;
                        return x - y;
                    });
                }
            }

            for (const index in answers) {
                const wot = answers[index];
                ways_of_thinking.push(
                    <WayOfThinking
                        way_of_thinking={wot}
                        filters={this.props.filters}
                        update_filters={this.props.update_filters}
                        district_id={this.props.district_id}
                    />
                );
            }
        }

        return (
            <div className="result-display-card">
                <div style={{ float: 'right' }}>
                    <Select
                        name={'filter'}
                        placeholder="Order by %"
                        default="Descending"
                        options={[
                            { text: 'Descending', value: 'Descending' },
                            { text: 'Ascending', value: 'Ascending' },
                        ]}
                        no_blank_option={true}
                        setFormState={value => this.setState(value)}
                        style={{ margin: '0px' }}
                    />
                </div>

                <div className="h2 bold header">{this.props.overview}</div>

                <div className="row">{ways_of_thinking}</div>
            </div>
        );
    }
}
