import React, { Component } from 'react';

import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

export default class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { lang } = this.props;
        let image = <div />;
        let description = <div />;
        const { question } = this.props;

        const question_image = question.images_by_language[lang];
        const question_text = question.text_by_language[lang];

        if (question_image) {
            image = (
                <QuestionEnlargeImage
                    image={question_image}
                    text={question_text}
                    height={150}
                />
            );
        } else {
            description = <div className="card-text">{question_text}</div>;
        }

        return (
            <div className="col-md-3 col-sm-12" style={{ textAlign: 'center' }}>
                <div
                    className="card"
                    style={{ padding: '15px 15px', marginBottom: '15px' }}
                >
                    <div style={{ minHeight: '150px' }}>
                        {description}
                        {image}
                    </div>
                    <div>
                        {this.props.info}
                        {this.props.view}
                    </div>
                </div>
            </div>
        );
    }
}
