import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Button, EmptyModal } from 'library';

const OFFSET_CHANGE = 12;

export default class StudentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            loading: true,
            student_count: -1,
        };

        this.change_offset = this.change_offset.bind(this);
        this.get_students = this.get_students.bind(this);
        this.get_student_callback = this.get_student_callback.bind(this);
    }

    componentDidMount() {
        this.get_students();
    }



    get_students() {
        const { filter_context, source, target, district_id } = this.props;
        const { offset } = this.state;
        const starting_filters = filter_context.filters;

        const filters = {
            starting_assessment: starting_filters.starting_assessment,
            starting_value: source,
            ending_assessment: starting_filters.ending_assessment,
            ending_value: target,
            category: starting_filters.category,
        };

        const data = {
            filters,
            offset,
            limit: OFFSET_CHANGE,
        };

        ajaxWrapper(
            'POST',
            `/sankey_student_list/${district_id}/`,
            data,
            this.get_student_callback
        );
        this.setState({ loading: true });
    }

    get_student_callback(result) {
        this.setState({
            student_list: result.students,
            student_count: result.count,
            loading: false,
        });
    }

    change_offset(change) {
        const { offset } = this.state;
        this.setState(
            {
                offset: offset + change,
            },
            this.get_students
        );
    }

    render() {
        const { filter_context } = this.props;
        const starting_filters = filter_context.filters;
        let { category } = starting_filters;
        category = category.charAt(0).toUpperCase() + category.slice(1);

        let starting_assessment_name = '';
        let ending_assessment_name = '';
        const { assessments } = filter_context
        assessments.forEach(assessment => {
            if (assessment.id == starting_filters.starting_assessment) {
                starting_assessment_name = assessment.name;
            }
            if (assessment.id == starting_filters.ending_assessment) {
                ending_assessment_name = assessment.name;
            }
        })

        let next = (
            <Button
                onClick={() => this.change_offset(OFFSET_CHANGE)}
                text="Next"
                type="success"
            />
        );

        let prev = null;
        const { offset, student_count, loading } = this.state;
        if (offset > 0) {
            prev = (
                <Button
                    onClick={() => this.change_offset(-1 * OFFSET_CHANGE)}
                    text="Previous"
                    type="danger"
                />
            );
        }

        let page_max = offset + OFFSET_CHANGE;
        if (student_count < page_max) {
            page_max = student_count;
            next = null;
        }

        let student_list_modal = null;
        if (loading == true) {
            student_list_modal = (
                <EmptyModal show>
                    <Wrapper loaded={false} />
                </EmptyModal>
            );
        } else {
            const { student_list } = this.state;
            const { source, target, onHide } = this.props;
            const rows = student_list.map(student => (
                <tr>
                    <td>{student.student_number}</td>
                    <td>{`${student.last_name}, ${student.first_name}`}</td>
                </tr>
            ))

            const list_description =
                `Students who went from ${source
                } on ${starting_assessment_name
                } to ${target
                } on ${ending_assessment_name
                } in ${category}`;

            student_list_modal = (
                <EmptyModal show onHide={() => onHide()}>
                    <div style={{ padding: '20px 15px' }}>
                        <h4>{list_description}</h4>
                        <br />
                        <table className="table">
                            <tr>
                                <th>Student ID</th>
                                <th>Name</th>
                            </tr>
                            {rows}
                        </table>
                        <div className="row">
                            <div className="col-6">{prev}</div>
                            <div className="col-6">{next}</div>
                            <div className="col-12">
                                <br />
                            </div>
                            <div className="col-12">
                                Students: {offset + 1} - {page_max}{' '}
                                out of {student_count}.
                            </div>
                        </div>
                    </div>
                </EmptyModal>
            );
        }

        return student_list_modal;
    }
}
