import React, { Component } from 'react';
import { Alert } from 'library';

const BUTTON_VALUES = [
    ['Functions', 'AC', '÷'],
    [7, 8, 9, '×'],
    [4, 5, 6, '-'],
    [1, 2, 3, '+'],
    [0, '.', '='],
];

const SCIENTIFIC_BUTTON_VALUES = [
    ['Rad', 'Deg', 'x!', '(', ')', '%', 'AC'],
    ['Inv', 'sin', 'ln', 7, 8, 9, '÷'],
    ['π', 'cos', 'log', 4, 5, 6, '×'],
    ['e', 'tan', '√', 1, 2, 3, '-'],
    ['', '', 'x to the y', 0, '.', '=', '+'],
];

const SCIENTIFIC_POPOUT_VALUES = [
    ['(', ')', '%'],
    ['Rad', 'Deg', 'Inv'],
    ['sin', 'cos', 'tan'],
    ['π', '√', 'x to the y'],
    ['e', 'ln', 'log'],
];

const inverted_style = {
    whiteSpace: 'nowrap',
    fontSize: '.8em',
};
const INVERTED_BUTTONS = {
    sin: (
        <span style={inverted_style}>
            sin<sup>-1</sup>
        </span>
    ),
    cos: (
        <span style={inverted_style}>
            cos<sup>-1</sup>
        </span>
    ),
    tan: (
        <span style={inverted_style}>
            tan<sup>-1</sup>
        </span>
    ),
    ln: (
        <span>
            e<sup>x</sup>
        </span>
    ),
    log: (
        <span>
            10<sup>x</sup>
        </span>
    ),
    '√': (
        <span>
            x<sup>2</sup>
        </span>
    ),
    'x to the y': (
        <span>
            <sup>y</sup>√x
        </span>
    ),
};

function round(value, precision) {
    return Math.round(value * 10 ** precision) / 10 ** precision;
}

class CalculatorButton extends Component {
    constructor(props) {
        super(props);

        this.click = this.click.bind(this);
    }

    click(event) {
        let { value } = this.props;
        if (
            typeof value === 'object' &&
            'props' in value &&
            value.props.children[0] == 'x'
        ) {
            value = 'x to the y';
        }

        this.props.onClick(event, value);
    }

    render() {
        return (
            <button
                className={this.props.className}
                onClick={this.click}
                type="button"
            >
                {this.props.text}
            </button>
        );
    }
}

class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            result: '',
            exponent: false,
            inverted: false,
            trig_mode: 'deg',
            popout: false,
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handle_key_press = this.handle_key_press.bind(this);
        this.click = this.click.bind(this);

        this.reset = this.reset.bind(this);
        this.evaluate = this.evaluate.bind(this);
        this.add_character = this.add_character.bind(this);
        this.check_value_for_number = this.check_value_for_number.bind(this);
        this.math_functions = this.math_functions.bind(this);

        this.calc_ref = React.createRef();
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            width: this.calc_ref.current.offsetWidth,
            height: this.calc_ref.current.offsetHeight,
            window_width: window.innerWidth,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && this.props.open != nextProps.open) {
            const that = this;
            setTimeout(() => {
                this.calc_ref.current.focus();
            }, 100);
        }
    }

    handle_key_press(event) {
        const { key } = event;

        if (!isNaN(key)) {
            this.click(event, key);
        } else if (['=', '/', '×', '-', '+', '.'].indexOf(key) != -1) {
            this.click(event, key);
        } else if (key === 'Enter') {
            this.click(event, '=');
        } else if (key === '*') {
            this.click(event, '×');
        }
    }

    click(event, value) {
        event.preventDefault();

        if (this.props.track_event_details) {
            this.props.track_event_details({
                type: 'calculator',
                value,
            });
        }

        const current_value = this.state.value;
        const current_result = this.state.result;

        if (value === 'AC') {
            this.reset(value);
        } else if (['+-', '%'].indexOf(value) > -1) {
            const evaluated_result = this.evaluate();
            if (!evaluated_result) {
                return false;
            }

            if (!this.check_value_for_number(evaluated_result)) {
                return false;
            }

            let new_result;
            if (value === '+-') {
                new_result = evaluated_result * -1;
            } else if (value === '%') {
                new_result = evaluated_result / 100;
            }

            if (isNaN(new_result)) {
                new_result = '';
            }

            this.setState({
                value: '',
                result: new_result,
            });
        } else if (value === '.') {
            let new_value = '';

            let current_numbers_in_value = current_value.split(/×|\/|-|\+/);
            let last_number_in_value =
                current_numbers_in_value[current_numbers_in_value.length - 1];

            if (!last_number_in_value.includes('.')) {
                new_value = current_value + value;
            } else {
                const lastIndex = current_value.lastIndexOf('.');
                const after = current_value.slice(
                    lastIndex,
                    current_value.length
                );
                let found_non_number = false;
                Object.keys(after).forEach(char => {
                    if (isNaN(char)) {
                        found_non_number = true;
                    }
                });

                if (found_non_number) {
                    new_value = current_value + value;
                }
            }

            this.setState({
                value: new_value,
            });
        } else if (value === '=') {
            let evaluated_result = '';
            if (this.state.exponent) {
                if (this.state.inverted) {
                    evaluated_result =
                        current_result **
                        (1 / eval(this.clean_string(current_value)));
                } else {
                    evaluated_result =
                        current_result **
                        eval(this.clean_string(current_value));
                }
            } else {
                evaluated_result = this.evaluate();
            }

            if (!evaluated_result) {
                return false;
            }

            this.setState({
                result: evaluated_result,
                value: '',
                exponent: false,
                error: null,
            });
        } else if (value === 'Rad') {
            this.setState({ trig_mode: 'rad' });
        } else if (value === 'Deg') {
            this.setState({ trig_mode: 'deg' });
        } else if (
            ['sin', 'cos', 'tan', 'ln', 'log', '√'].indexOf(value) > -1
        ) {
            this.math_functions(value);
        } else if (value == 'x!') {
            const evaluated_result = this.evaluate();
            if (!evaluated_result) {
                return false;
            }

            const new_result = this.factorialize(evaluated_result);
            this.setState({
                result: new_result,
                value: '',
            });
        } else if (value == 'x to the y') {
            const evaluated_result = this.evaluate();
            if (!evaluated_result) {
                return false;
            }

            this.setState({
                value: '',
                result: evaluated_result,
                exponent: true,
            });
        } else if (value == 'Inv') {
            this.setState({ inverted: !this.state.inverted });
        } else if (value == 'Functions') {
            this.setState({ popout: !this.state.popout });
        } else {
            this.add_character(value);
        }
    }

    check_value_for_number(value) {
        if (isNaN(value)) {
            return false;
        }

        return true;
    }

    add_character(value) {
        const current_value = this.state.value;

        const last_character = current_value[current_value.length - 1];
        const operators = ['+', '-', '/', '*'];
        if (operators.indexOf(value) > -1) {
            if (operators.indexOf(last_character) > -1) {
                return false;
            }
            if (!last_character && !this.state.result) {
                return false;
            }
        }

        let new_value = current_value + value;
        let new_result = this.state.result;

        if (this.state.exponent) {
            // Do not modify result when inputting exponent;
        } else if (this.check_value_for_number(value) && current_value == '') {
            new_result = '';
        }

        if (current_value === '0' && value === '0') {
            new_value = '0';
        }

        if (String(current_value).length < 16) {
            this.setState({
                value: new_value,
                result: new_result,
            });
        }
    }

    math_functions(value) {
        let evaluated_result = this.evaluate();
        if (!evaluated_result) {
            return false;
        }

        let new_result = 0;

        if (this.state.inverted) {
            if (value == 'sin') {
                new_result = Math.asin(evaluated_result);
            } else if (value == 'cos') {
                new_result = Math.acos(evaluated_result);
            } else if (value == 'tan') {
                new_result = Math.atan(evaluated_result);
            } else if (value == 'ln') {
                new_result = Math.E ** evaluated_result;
            } else if (value == 'log') {
                new_result = 10 ** evaluated_result;
            } else if (value == '√') {
                new_result = evaluated_result ** 2;
            }

            if (
                ['sin', 'cos', 'tan'].indexOf(value) > -1 &&
                this.state.trig_mode == 'deg'
            ) {
                new_result *= 180 / Math.PI;
            }
        } else {
            if (
                ['sin', 'cos', 'tan'].indexOf(value) > -1 &&
                this.state.trig_mode == 'deg'
            ) {
                evaluated_result *= Math.PI / 180;
            }

            if (value == 'sin') {
                new_result = Math.sin(evaluated_result);
            } else if (value == 'cos') {
                new_result = Math.cos(evaluated_result);
            } else if (value == 'tan') {
                new_result = Math.tan(evaluated_result);
            } else if (value == 'ln') {
                new_result = Math.log(evaluated_result);
            } else if (value == 'log') {
                new_result = Math.log10(evaluated_result);
            } else if (value == '√') {
                new_result = Math.sqrt(evaluated_result);
            }
        }

        this.setState({
            value: '',
            result: new_result,
        });
    }

    factorialize(value) {
        if (value % 1 != 0) {
            return null;
        }
        if (value == 0) {
            return 1;
        }

        return value * this.factorialize(value - 1);
    }

    clean_string(value) {
        value = String(value);

        value = value.replaceAll('×', '*');
        value = value.replaceAll('÷', '/');
        value = value.replaceAll(/π/g, 'Math.PI');
        value = value.replaceAll(/e/g, 'Math.E');

        return value;
    }

    evaluate() {
        const current_result = this.state.result;
        const current_value = this.clean_string(this.state.value);

        let new_result = '';

        if (this.state.exponent) {
            this.setState({
                error: 'Please Evaluate Your Expression First',
            });

            return null;
        }

        try {
            new_result = eval(current_result + current_value);
        } catch (event) {
            this.setState({
                error: 'Your Expression is Invalid',
            });

            return null;
        }

        return new_result;
    }

    reset(value) {
        this.setState({
            value: '',
            result: '',
            exponent: false,
            error: null,
        });
    }

    render() {
        let calc_class = 'calc-wrapper';
        let button_text = BUTTON_VALUES;
        const popout_text = SCIENTIFIC_POPOUT_VALUES;
        let container_width = this.state.width - 40;
        const max_height = 40;

        if (this.props.scientific) {
            calc_class = 'calc-wrapper scientific-calc-wrapper';
            button_text = SCIENTIFIC_BUTTON_VALUES;
            container_width = 460;
        }

        let rounded_result = '';
        if (this.state.result) {
            rounded_result = round(this.state.result, 7);
        }
        const value_string = rounded_result + this.state.value;
        let value = [value_string];

        const value_length = String(value_string).length;
        const pixel_per_char = container_width / value_length;
        let font_size = Math.floor(pixel_per_char * (16 / 9));
        if (font_size > max_height) {
            font_size = max_height;
        }

        const calc_screen_style = { fontSize: `${font_size}px` };

        if (this.state.exponent) {
            if (this.state.inverted) {
                value = [<sup>{this.state.value}</sup>, '√', rounded_result];
            } else {
                value = [rounded_result, <sup>{this.state.value}</sup>];
            }

            calc_screen_style.paddingTop = '15px';
            calc_screen_style.fontSize = `${font_size * 0.8}px`;
        }

        const buttons = [];
        for (const row of button_text) {
            for (const character of row) {
                let display_character = character;

                let class_name = 'calc-button';
                if (character === '=') {
                    class_name = 'calc-button calc-equals';
                } else if (character == 'AC') {
                    class_name = 'calc-button calc-red';
                } else if (character === 'Functions') {
                    class_name = 'calc-button calc-functions small';
                    if (this.state.popout) {
                        class_name += ' calc-mode';
                        display_character = 'X';
                    }
                }

                buttons.push(
                    <CalculatorButton
                        className={class_name}
                        value={character}
                        text={display_character}
                        onClick={this.click}
                    />
                );
            }
        }

        const popout_buttons = [];
        for (const row of popout_text) {
            for (const character of row) {
                let display_character = character;

                if (character == 'x to the y') {
                    display_character = (
                        <span>
                            x<sup>y</sup>
                        </span>
                    );
                }

                if (this.state.inverted && character in INVERTED_BUTTONS) {
                    display_character = INVERTED_BUTTONS[character];
                }

                let class_name = 'calc-button';
                if (character === 'Rad') {
                    class_name = 'calc-button calc-mode';
                    if (this.state.trig_mode == 'rad') {
                        class_name = 'calc-button calc-mode active';
                    }
                } else if (character === 'Deg') {
                    class_name = 'calc-button calc-mode';
                    if (this.state.trig_mode == 'deg') {
                        class_name = 'calc-button calc-mode active';
                    }
                } else if (character === 'Inv') {
                    class_name = 'calc-button calc-mode';
                    if (this.state.inverted) {
                        class_name = 'calc-button calc-mode active';
                    }
                }

                if (
                    ['Deg', 'Rad', 'Inv', 'sin', 'cos', 'tan', 'log'].indexOf(
                        character
                    ) > -1
                ) {
                    class_name += ' small';
                }

                popout_buttons.push(
                    <CalculatorButton
                        className={class_name}
                        value={character}
                        text={display_character}
                        onClick={this.click}
                    />
                );
            }
        }

        let popout = null;
        if (this.state.popout) {
            let popout_class = 'calc-popout-wrapper';
            if (this.state.window_width < 768) {
                popout_class = 'calc-popout-mobile-wrapper';
            }
            popout = (
                <div className={popout_class}>
                    <div className="calc-popout-button-box">
                        {popout_buttons}
                    </div>
                </div>
            );
        }

        let error = null;
        const container_style = {};
        let box_style = {};
        if (this.state.error) {
            const error_height = 85;
            container_style.height = `${320 + error_height}px`;

            box_style = {
                height: `calc(100% - 75px - ${error_height}px)`,
            };
            error = (
                <div style={{ paddingTop: '10px' }}>
                    <Alert type="warning" text={this.state.error} />
                </div>
            );
        }

        return (
            <div
                style={{ outline: 'none', position: 'relative' }}
                ref={this.calc_ref}
                tabIndex="0"
                onKeyDown={this.handle_key_press}
            >
                <div className={calc_class} style={container_style}>
                    <div className="calc-screen" style={calc_screen_style}>
                        {value}
                    </div>
                    <div className="calc-button-box" style={box_style}>
                        {buttons}
                    </div>

                    {error}
                </div>

                {popout}
            </div>
        );
    }
}

export default Calculator;
