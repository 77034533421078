import React from 'react';
import Sankey from 'pages/sankey/main.js';

class TestDiagram extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: 'small',
        };
    }

    render() {
        const { data } = this.state;
        return (
            <div className="container">
                <br />
                <br />
                <h2>Test Diagram</h2>
                <Sankey height={500} data={data} />
            </div>
        );
    }
}

export default TestDiagram;
