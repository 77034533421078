import $ from 'jquery';
import { ajaxWrapper } from 'functions';
import clearTokens from './clear_tokens.js';

function refreshToken(type, url, data, returnFunc) {
    let refreshData = {};
    refreshData.csrfmiddlewaretoken =
        window.secretReactVars.csrfmiddlewaretoken;

    refreshData.refresh = '';
    if (localStorage.getItem('refresh_token')) {
        refreshData.refresh = localStorage.getItem('refresh_token');
    }

    refreshData = JSON.stringify(refreshData);

    $.ajax({
        type: 'POST',
        url: '/users/token/refresh/',
        contentType: 'application/json',
        data: refreshData,
        statusCode: {
            401(_xhr) {
                clearTokens();
            },
        },
        success(value) {
            localStorage.setItem('token', value.access);
            ajaxWrapper(type, url, data, returnFunc);
        },
        error(_xhr, _status, _error) {
            clearTokens();
        },
    });
}

export default refreshToken;
