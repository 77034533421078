import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    FormWithChildren,
    Select,
    TextInput,
    Alert,
    Wrapper,
} from 'library';

export default class SharePermissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            error: '',

            schools: [],
            permissions_shared_to: [],
        };

        this.get_user_context = this.get_user_context.bind(this);
        this.load_user_schools = this.load_user_schools.bind(this);
        this.load_schools = this.load_schools.bind(this);

        this.submit = this.submit.bind(this);
        this.share_callback = this.share_callback.bind(this);

        if (this.props.district_id === undefined) {
            this.district_id = window.cmState.user.district_user.district_id;
        }
        else {
            this.district_id = this.props.district_id;
        }
    }

    componentDidMount() {
        this.get_user_context();
    }

    get_user_context() {
        const { user } = window.cmState;

        const related = [
            'district_user',
            'permissioned_schools',
            'permissions_shared_to',
            'permissions_shared_to__permissioned_schools',
        ];

        ajaxWrapper(
            'GET',
            `/api/user/user/${user.id}/?related=${related.join(',')}`,
            {},
            this.load_user_schools
        );
    }

    load_user_schools(value) {
        let { user } = value[0];
        if (!(user.can_share_permissions || user.is_staff)) {
            this.setState({
                loaded: true,
                error: "You're account is improperly configured to share permissions.  Please contact support@mathanex.com if this is in error.",
            });

            return null;
        } if ((
            user.role !== 'District Admin' &&
            user.permissioned_schools.length > 0
        ) && !user.is_staff) {
            this.setState({
                loaded: true,
                error: "You're account is improperly configured to share permissions.  Please contact support@mathanex.com if this is in error.",
            });

            return null;
        }

        this.setState({
            permissions_shared_to: user.permissions_shared_to,
        });
        const { schools } = this.state;

        if (schools.length === 0) {
            ({ user } = (window.cmState));
            ajaxWrapper(
                'GET',
                `/api/sis/school/?order_by=name&district=${this.district_id}`,
                {},
                this.load_schools
            );
        }
    }

    load_schools(value) {
        this.setState({
            schools: value,
            loaded: true,
        });
    }

    submit(data, callback) {
        if (data.email) {
            data.email = data.email.toLowerCase();
        }
        data.district_id = this.district_id;

        this.setState(
            {
                callback,
            },
            () =>
                ajaxWrapper(
                    'POST',
                    '/users/share_permissions/',
                    data,
                    this.share_callback
                )
        );
    }

    share_callback(value) {
        const { callback } = this.state;
        if ('error' in value) {
            this.setState({
                error: value.error,
            });
            callback();

            return null;
        }

        this.get_user_context();
        callback();
    }

    render() {
        const { user } = window.cmState;

        const defaults = {};

        const users = [];
        const container_style = {
            position: 'relative',
            background: '#eee',
            padding: '5px',
            boxShadow: '2px 2px 10px rgb(0 0 0 / 10%) inset',
        };
        const { permissions_shared_to } = this.state;
        permissions_shared_to.forEach((item) => {
            const schools = [];
            const { permissioned_schools } = item.user;
            permissioned_schools.forEach(({ school }) => {
                schools.push(
                    <div
                        className="btn btn-secondary disabled"
                        style={{ fontWeight: 'normal' }}
                    >
                        {school.name}
                    </div>
                );
            })

            users.push(
                <div className="simple-card">
                    <div style={{ float: 'right' }}>
                        <FormWithChildren
                            deleteUrl={
                                `/users/clear_permissions/${item.user.id
                                }/`
                            }
                            deleteRedirectUrl="/share_permissions/"
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        {item.user.email}
                    </div>
                    <div>{schools}</div>
                    <div style={{ clear: 'both' }} />
                </div>
            );
        })
        const { schools } = this.state;
        const school_options = schools.map(({ school }) => ({
            text: school.name,
            value: school.id,
        }));


        let content = (
            <div>
                <div>
                    <h3>Share Permissions With Email</h3>
                    <FormWithChildren defaults={defaults} submit={this.submit}>
                        <TextInput
                            name="permissions_shared_from"
                            style={{ display: 'none' }}
                        />
                        <TextInput name="email" label="Email" />
                        <TextInput name="password" label="Initial Password" />
                        <Select
                            name="schools"
                            label="Schools"
                            multiple
                            options={school_options}
                        />

                        <Alert type="warning">
                            Be Aware That Selecting NO Schools Will Result In
                            District-Wide Access!
                        </Alert>
                    </FormWithChildren>
                    <br />
                    <br />
                </div>

                <div>
                    <h3>Permissions Already Shared</h3>
                    <div style={container_style}>{users}</div>
                </div>
            </div>
        );

        let error = null;
        const { error: errorState, schools: schoolsState, loaded } = this.state;
        if (errorState) {
            if (schoolsState.length > 0) {
                error = <Alert type="danger">{errorState}</Alert>;
            } else {
                content = <Alert type="danger">{errorState}</Alert>;
            }
        }

        return (
            <Wrapper loaded={loaded}>
                <Container min_height>
                    {error}
                    {content}
                </Container>
            </Wrapper>
        );
    }
}
