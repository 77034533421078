import React from 'react';

const ExplanationCards = data => {
    const EVIDENCE_OPTIONS = [
        'Blank',
        'No Evidence',
        'Emerging Evidence',
        'Partial Evidence',
        'Sufficient Evidence',
    ];

    let results = null;

    let text_style = {
        fontSize: '22px',
        fontWeight: '500',
        marginBottom: '10px',
    };

    if ('answers' in data) {
        let answers_lookup = {};
        for (let item of data['answers']) {
            answers_lookup[item['answer']['text']] = item;
        }
        let found_evidence = [];

        for (let key of EVIDENCE_OPTIONS) {
            if (key in answers_lookup) {
                found_evidence.push(
                    <div className="found_evidence_card">
                        <div style={text_style}>
                            {answers_lookup[key]['students_who_answered']}
                        </div>
                        <div style={text_style}>Students</div>
                    </div>
                );
            } else {
                found_evidence.push(
                    <div className="found_evidence_card">
                        <div style={text_style}>0</div>
                        <div style={text_style}>Students</div>
                    </div>
                );
            }
        }

        results = (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                {found_evidence}
            </div>
        );
    }

    let title_style = {
        fontSize: '22px',
        fontWeight: '500',
        marginBottom: '10px',
        height: '46pt',
    };
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                <div className="evidence_card">
                    <div style={title_style}>Blank</div>
                </div>
                <div
                    className="evidence_card"
                    style={Object.assign({
                        backgroundColor: 'rgb(230 230 230)',
                    })}
                >
                    <div style={title_style}>No Evidence</div>
                    <div>of a Mathematical Explanation</div>
                </div>
                <div
                    className="evidence_card"
                    style={Object.assign({
                        backgroundColor: 'rgb(154 219 223)',
                    })}
                >
                    <div style={title_style}>Emerging Evidence</div>
                    <div>of a Mathematical Explanation</div>
                </div>
                <div
                    className="evidence_card"
                    style={Object.assign({
                        backgroundColor: 'rgb(178 214 240)',
                    })}
                >
                    <div style={title_style}>Partial Evidence</div>
                    <div>of a good</div>
                    <div>Mathematical</div>
                    <div>Explanation</div>
                </div>
                <div
                    className="evidence_card"
                    style={Object.assign({
                        backgroundColor: 'rgb(194 198 233)',
                    })}
                >
                    <div style={title_style}>Sufficient Evidence</div>
                    <div>of a good</div>
                    <div>Mathematical</div>
                    <div>Explanation</div>
                </div>
            </div>
            {results}
        </div>
    );
};

export default ExplanationCards;
