import React, { Component } from 'react';

import { Alert, Container } from 'library';

export default class ClasslinkLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
        };
    }

    componentDidMount() {
        localStorage.setItem('token', this.props.access);
        localStorage.setItem('refresh_token', this.props.refresh);
        localStorage.setItem('token_time', new Date());

        if (
            'classlink_redirect' in localStorage &&
            localStorage.classlink_redirect.indexOf('proctor') > -1
        ) {
            var url = localStorage.classlink_redirect;
            localStorage.removeItem('classlink_redirect');

            console.log('Found Classlink Redirect', url);
            window.location = url;
        } else if (this.props.role != 'Student') {
            console.log('Found Non-Student Redirect', url);
            window.location = '/report_navigator/';
        } else {
            window.location = '/proctor/';
        }
    }

    render() {
        const container_style = {
            background: 'white',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            padding: '0px',
        };

        const wrapper_style = {
            background: '#fafafa',
        };

        let error = null;
        if (this.state.error) {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        return (
            <div style={wrapper_style}>
                <Container min_height style={container_style}>
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            {error}
                            <h1>
                                Welcome to MathAnex. Please hold while we
                                redirect you.
                            </h1>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
