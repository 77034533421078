import React from 'react';
import resolveVariables from 'base/resolver.js';
import ajaxWrapper from 'base/ajax.js';

function TableRow(props) {
    const data = [];
    Object.keys(props.data).forEach(index => {
        data.push(<td>{props.data[index]}</td>);
    });
    return <tr>{data}</tr>;
}

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = { headers: this.props.headers, data: this.props.data };

        this.refreshData = this.refreshData.bind(this);
        this.refreshDataCallback = this.refreshDataCallback.bind(this);
    }

    componentDidMount() {
        if (this.props.dataUrl) {
            this.refreshData();
        }
    }

    refreshData() {
        if (this.props.dataUrl) {
            ajaxWrapper(
                'GET',
                this.props.dataUrl,
                {},
                this.refreshDataCallback
            );
        }
    }

    refreshDataCallback(value) {
        const data = [];
        let newValue;
        Object.keys(value).forEach(index => {
            newValue = resolveVariables(
                this.props.dataMapping,
                value[index][this.props.objectName]
            );
            data.push(newValue);
        });
        this.setState({ data });
    }

    render() {
        const headers = [];
        const rows = [];

        Object.keys(this.state.headers).forEach(index => {
            headers.push(<th>{this.state.headers[index]}</th>);
        });

        Object.keys(this.state.data).forEach(index => {
            if (
                this.state.data[index] == null ||
                this.state.data[index] === undefined
            ) {
                rows.push(<TableRow data="" />);
            } else {
                rows.push(<TableRow data={this.state.data[index]} />);
            }
        });

        if (headers.length === 0) {
            Object.keys(this.props.headers).forEach(index => {
                headers.push(<th>{this.props.headers[index]}</th>);
            });

            Object.keys(this.props.data).forEach(index => {
                if (
                    this.state.data[index] == null ||
                    this.state.data[index] === undefined
                ) {
                    rows.push(<TableRow data="" />);
                } else {
                    rows.push(<TableRow data={this.state.data[index]} />);
                }
            });
        }

        return (
            <table className="table">
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }
}

export default Table;
