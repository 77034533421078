import React from 'react';
import { Image, Tabs } from '../../../library/index';
import InfoTab from './InfoTab';
import AssessmentTab from './AssessmentTab';
import ResultsTab from './ResultsTab';

export default class Question extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let question = this.props.question;

        return (
            <div className="col-12" style={{ padding: '0px 0px 20px' }}>
                <Tabs
                    initial_tab="Info"
                    tab_names={['Info', 'Results', 'Assessments']}
                    change_tab={name => {
                        if (name !== 'Results') return;

                        this.props.loadAnswers(question);
                    }}
                    tab_contents={{
                        Info: (
                            <InfoTab
                                makeCopy={this.props.makeCopy}
                                question={question}
                            />
                        ),
                        Assessments: (
                            <AssessmentTab
                                questions_on_assessment={
                                    question.questions_on_assessments
                                }
                            />
                        ),
                        Results: question.answers ? (
                            <ResultsTab answers={question.answers} />
                        ) : (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <Image
                                    css={{ width: '100px' }}
                                    src="/static/images/loading_transparent.gif"
                                />
                            </div>
                        ),
                    }}
                />
            </div>
        );
    }
}
