export default function filters_have_changed(old_filters, new_filters) {
    let changed = false;
    for (const key in new_filters) {
        if (
            key == 'form_child_update_key' ||
            key == 'required' ||
            key == 'loaded'
        ) {
            continue;
        }
        if (typeof new_filters[key] === 'object') {
            if (
                JSON.stringify(old_filters[key]) !=
                JSON.stringify(new_filters[key])
            ) {
                changed = true;
                break;
            }
        } else if (old_filters[key] != new_filters[key]) {
            changed = true;
            break;
        }
    }

    return changed;
}
