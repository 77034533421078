import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Wrapper, Container } from 'library';
import proctor_rendering from 'pages/reports/results_explorer/functions/proctor_rendering.js';
import QuestionDisplay from 'pages/questions/question_display.js';

class ProctorTattletale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            details: {},
            question: {},
            title: ""
        };

        this.get_details = this.get_details.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/reporting/results_explorer/proctor_details_by_question/${this.props.question_id}/?answer=${this.props.answer}&group=${this.props.group}`,
            {},
            this.get_details
        );
    }

    get_details(value) {
        this.setState({
            loaded: true,
            details: value.details,
            question: value.question,
            title: value.title
        });
    }

    render() {
        const examples = [];

        if (this.state.loaded) {
            const question_lookup = {};
            question_lookup[this.state.question.id] = this.state.question;

            for (const details of this.state.details) {
                const rows = proctor_rendering(details.events, question_lookup);

                examples.push(
                    <div className="result-display-card">
                        <table className="table">
                            <tr>
                                <th width="20%">Type</th>
                                <th width="20%">Question</th>
                                <th width="60%">Events</th>
                            </tr>

                            {rows}
                        </table>
                    </div>
                );
            }
        }

        return (
            <Wrapper loaded={this.state.loaded}>
                <Container min_height>
                    <div className="reporting-dashboard">
                        <div className="result-display-card">
                            <h3 className="no-style">
                                <QuestionDisplay
                                    question={this.state.question}
                                    display="text"
                                    image
                                />
                            </h3>
                            <h4 className="no-style">
                                {`Answer: ${this.state.title}`}
                            </h4>
                        </div>
                        {examples}
                    </div>
                </Container>
            </Wrapper>
        );
    }
}

export default ProctorTattletale;
