import React, { Component } from 'react';
import settings from 'base/settings.js';

import { TextInput, LogInForm, PasswordInput, Wrapper } from 'library';

class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
        };
    }

    render() {
        const { params } = this.props;
        const content = (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <br />
                        <br />
                    </div>
                    <div className="col-md-4" />
                    <div className="col-md-4" style={{ textAlign: 'center' }}>
                        <img
                            src={
                                'https://mathanex-media.learning.amplify.com/math_anex_logo.png'
                            }
                            alt={'Math ANEX'}
                            style={{
                                height: '50px',
                                width: 'auto',
                                marginBottom: '20px',
                            }}
                        />
                        <h3>Log In To View Your DEMI Results.</h3>
                        <LogInForm
                            redirectUrl={settings.LOGGED_IN_HOME}
                            defaults={['', '']}
                            submitUrl="/users/token/"
                            params={params}
                            components={[TextInput, PasswordInput]}
                            componentProps={[
                                {
                                    value: '',
                                    placeholder: 'Your Email Address',
                                    name: 'email',
                                    label: '',
                                },
                                {
                                    value: '',
                                    placeholder: 'Your Password',
                                    name: 'password',
                                    label: '',
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-4" />
                </div>
            </div>
        );
        const { loaded } = this.state;
        return (
            <div>
                <Wrapper loaded={loaded} content={content} />
            </div>
        );
    }
}

export default LogIn;
