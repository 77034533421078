import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDate } from 'functions';

import { FormWithChildren, DateTimePicker, Wrapper } from 'library';

import parse_sessions from 'pages/time_tracking/parse_sessions.js';

class Individual extends Component {
    constructor(props) {
        const today = new Date();
        const now = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
        const yesterday = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 1
        );

        super(props);
        this.state = {
            loaded: false,
            events: [],
            user: {},
            start_date: formatDate(yesterday, 'yyyy-mm-dd'),
            end_date: formatDate(now, 'yyyy-mm-dd'),
        };

        this.get_events = this.get_events.bind(this);
        this.get_user = this.get_user.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    componentDidMount() {
        const { user_id } = this.props;
        ajaxWrapper('GET', `/api/user/user/${user_id}/`, {}, this.get_user);
        this.query_events();
    }

    setGlobalState(name, state) {
        this.setState(state, this.query_events);
    }

    get_user(result) {
        this.setState({ user: result[0].user });
    }

    get_events(result) {
        const events = result.map(event => event.userevent);

        this.setState({ events, loaded: true });
    }

    query_events() {
        const { start_date: startDate, end_date: endDate } = this.state;
        const { user_id } = this.props;
        let start_date = '';
        if (startDate) {
            start_date = `&created_at__gte=${startDate} 00:00:00`;
        }
        let end_date = '';
        if (endDate) {
            end_date = `&created_at__lte=${endDate} 23:59:59`;
        }
        ajaxWrapper(
            'GET',
            `/api/user/userevent/?order_by=created_at&user=${user_id}${start_date}${end_date}`,
            {},
            this.get_events
        );
    }

    render() {
        let content = null;
        const { loaded, events } = this.state;

        if (loaded) {
            const user_events = [];

            const parsed_sessions = parse_sessions(events);
            const { sessions } = parsed_sessions;

            const { total_time } = parsed_sessions;
            const hours = Math.floor(total_time / 3600);
            const minutes = Math.floor((total_time - hours * 3600) / 60);
            const seconds = Math.floor(
                total_time - hours * 3600 - minutes * 60
            );
            user_events.push(
                <tr>
                    <th>Total Time</th>
                    <th> </th>
                    <th>
                        {`${hours} hours, ${minutes} minutes, ${seconds} seconds.`}
                    </th>
                </tr>
            );

            sessions.forEach(session => {
                user_events.push(
                    <tr>
                        <th>New Session</th>
                        <th> </th>
                        <th>{session.total_time}</th>
                    </tr>
                );
                const { events: eventsSession } = session;
                eventsSession.forEach(user_event => {
                    user_events.push(
                        <tr>
                            <td>{user_event.event_name}</td>
                            <td>{user_event.page}</td>
                            <td>{user_event.created_at}</td>
                        </tr>
                    );
                });
            });
            const { user: userState } = this.state;
            content = (
                <div>
                    <div className="container">
                        <h3>{userState.email}`s Time On Math Anex</h3>
                        <FormWithChildren
                            row
                            setGlobalState={this.setGlobalState}
                            autoSetGlobalState
                            globalStateName="date_filter"
                            defaults={this.state}
                        >
                            <DateTimePicker
                                name="start_date"
                                label="Start Date"
                                className="col-md-6 col-xs-12"
                                display_time={false}
                            />
                            <DateTimePicker
                                name="end_date"
                                label="End Date"
                                className="col-md-6 col-xs-12"
                                display_time={false}
                            />
                        </FormWithChildren>
                        <table className="table">
                            <tr>
                                <th>Event Name</th>
                                <th>Page</th>
                                <th>Time</th>
                            </tr>
                            {user_events}
                        </table>
                    </div>
                </div>
            );
        }
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default Individual;
