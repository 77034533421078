import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Icon,
    Button,
    FormWithChildren,
    TextArea,
    PageBreak,
    EmptyModal,
    Wrapper,
} from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

class UnResolvedReponse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_loading: false,
            outstanding_requests: 0,
        };

        this.respond = this.respond.bind(this);
        this.mark_resolved = this.mark_resolved.bind(this);
        this.mark_un_resolved = this.mark_un_resolved.bind(this);

        this.ajax_callback = this.ajax_callback.bind(this);
        this.change_has_component = this.change_has_component.bind(this);
    }

    respond(state) {
        console.log('State', state);
        this.setState({
            outstanding_requests: this.state.outstanding_requests + 1,
            is_loading: true,
        });

        ajaxWrapper(
            'POST',
            '/api/home/dailyqcdiscussion/',
            {
                daily_qc_response: state.qc_response,
                user: state.user,
                text: state.text,
            },
            this.ajax_callback
        );
    }

    change_has_component() {
        const { response } = this.props;
        const has_component = !response.final_has_component;

        this.setState({
            outstanding_requests: this.state.outstanding_requests + 3,
            is_loading: true,
        });

        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${response.response_component_analysis_id}/`,
            { has_component },
            this.ajax_callback
        );
        ajaxWrapper(
            'POST',
            `/api/home/dailyqcresponse/${response.id}/`,
            { final_has_component: has_component },
            this.ajax_callback
        );

        if (has_component == response.original_has_component) {
            const { daily_qc } = this.props;
            ajaxWrapper(
                'POST',
                `/api/home/dailyqc/${daily_qc.id}/`,
                { analyst_score: daily_qc.analyst_score + 5 },
                this.ajax_callback
            );
        } else {
            this.ajax_callback();
        }
    }

    mark_resolved() {
        const { response } = this.props;
        if (!response.resolved) {
            this.setState({
                outstanding_requests: this.state.outstanding_requests + 2,
                is_loading: true,
            });

            ajaxWrapper(
                'POST',
                `/api/home/dailyqcresponse/${response.id}/`,
                { resolved: true },
                this.ajax_callback
            );

            const { daily_qc } = this.props;
            const number_mismatched =
                (100 - daily_qc.score) /
                (100 / daily_qc.daily_qc_responses.length);
            let resolved_pct =
                daily_qc.resolved_pct + (1 / number_mismatched) * 100;
            if (resolved_pct > 100) {
                resolved_pct = 100;
            }
            console.log(
                'Mark Resolved',
                daily_qc,
                number_mismatched,
                daily_qc.score,
                daily_qc.daily_qc_responses.length,
                daily_qc.resolved_pct,
                resolved_pct
            );
            ajaxWrapper(
                'POST',
                `/api/home/dailyqc/${daily_qc.id}/`,
                { resolved_pct },
                this.ajax_callback
            );
        }
    }

    mark_un_resolved() {
        const { response } = this.props;
        if (response.resolved) {
            this.setState({
                outstanding_requests: this.state.outstanding_requests + 2,
                is_loading: true,
            });

            ajaxWrapper(
                'POST',
                `/api/home/dailyqcresponse/${response.id}/`,
                { resolved: false },
                this.ajax_callback
            );

            const { daily_qc } = this.props;
            const number_mismatched =
                (100 - daily_qc.score) /
                (100 / daily_qc.daily_qc_responses.length);
            let resolved_pct =
                daily_qc.resolved_pct - (1 / number_mismatched) * 100;
            if (resolved_pct < 0) {
                resolved_pct = 0;
            }
            console.log(
                'Mark Resolved',
                daily_qc,
                number_mismatched,
                daily_qc.score,
                daily_qc.daily_qc_responses.length,
                daily_qc.resolved_pct,
                resolved_pct
            );
            ajaxWrapper(
                'POST',
                `/api/home/dailyqc/${daily_qc.id}/`,
                { resolved_pct },
                this.ajax_callback
            );
        }
    }

    ajax_callback() {
        if (this.state.outstanding_requests > 1) {
            this.setState({
                outstanding_requests: this.state.outstanding_requests - 1,
            });
        } else {
            this.setState(
                {
                    outstanding_requests: this.state.outstanding_requests - 1,
                    is_loading: false,
                },
                this.props.refresh
            );
        }
    }

    render() {
        const { response } = this.props;

        const discussions = [];
        for (const discussion_index in response.daily_qc_discussions) {
            const discussion =
                response.daily_qc_discussions[discussion_index]
                    .dailyqcdiscussion;
            discussions.push(
                <tr>
                    <th>
                        {discussion.user.first_name}{' '}
                        {discussion.user.last_name[0]}
                    </th>
                    <td dangerouslySetInnerHTML={{ __html: discussion.text }} />
                </tr>
            );
        }

        let resolved_color = 'white';
        let unresolved_color = 'black';
        if (response.resolved) {
            resolved_color = 'black';
            unresolved_color = 'white';
        }

        var og_color = 'white';
        var manager_color = 'black';

        if (this.props.component_qc) {
            if (response.final_has_component == false) {
                var og_color = 'black';
                var manager_color = 'white';
            } else {
                var og_color = 'white';
                var manager_color = 'black';
            }
        }

        let buttons = null;
        if (
            window.cmState.user.role == 'Manager Analyst' ||
            window.cmState.user.role == 'Super Admin'
        ) {
            buttons = (
                <table className="table">
                    <tr>
                        <th>Change QC Response Status</th>
                        <th />
                    </tr>
                    <tr>
                        <td
                            style={{
                                borderBottom: `5px solid ${unresolved_color}`,
                            }}
                        >
                            <Button
                                type="danger"
                                text="Not Resolved"
                                onClick={this.mark_un_resolved}
                            />
                        </td>
                        <td
                            style={{
                                borderBottom: `5px solid ${resolved_color}`,
                            }}
                        >
                            <Button
                                type="primary"
                                text="Resolved"
                                onClick={this.mark_resolved}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ borderBottom: `5px solid ${og_color}` }}>
                            <Button
                                type="info"
                                text="Does Not Have Component"
                                onClick={this.change_has_component}
                            />
                        </td>
                        <td
                            style={{
                                borderBottom: `5px solid ${manager_color}`,
                            }}
                        >
                            <Button
                                type="warning"
                                text="Has Component"
                                onClick={this.change_has_component}
                            />
                        </td>
                    </tr>
                </table>
            );
        }

        let onHide = null;
        if (!this.state.is_loading) {
            onHide = this.props.onHide;
        }

        return (
            <EmptyModal show onHide={onHide}>
                <Wrapper loaded={!this.state.is_loading}>
                    <div className="card" style={{ padding: '10px' }}>
                        <div className="card-body">
                            <h5 className="card-title">
                                <b>Student Response:</b>{' '}
                                <a
                                    href={`/student_response/${response.student_question_response.id}/`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {response.student_question_response.answer}
                                </a>
                            </h5>
                            <div className="card-text">
                                <p>
                                    <b>
                                        {this.props.qc_user.first_name}{' '}
                                        {this.props.qc_user.last_name[0]}'s "has
                                        Component":
                                    </b>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: response.original_has_component.toString(),
                                        }}
                                    />
                                </p>
                                <p>
                                    <b>
                                        {this.props.user.first_name}{' '}
                                        {this.props.user.last_name[0]}'s "Has
                                        Component":
                                    </b>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: response.qc_has_component.toString(),
                                        }}
                                    />
                                </p>
                                <p>
                                    <b>Component: </b>
                                    {`${this.props.daily_qc.approach_component.name}: ${this.props.daily_qc.approach_component.text}`}
                                </p>
                                <p>
                                    <b>Current "Has Component":</b>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: response.final_has_component.toString(),
                                        }}
                                    />
                                </p>
                                <PageBreak />
                                <h5 style={{ marginTop: '10px' }}>
                                    Discussion
                                </h5>
                                <table className="table">{discussions}</table>
                                <FormWithChildren
                                    key={`${response.id}_${discussions.length}`}
                                    submit={this.respond}
                                    defaults={{
                                        text: '',
                                        user: window.cmState.user.id,
                                        qc_response: response.id,
                                    }}
                                    submit_text="Submit"
                                    submit_on_enter={false}
                                >
                                    <TextArea label="Response" name="text" />
                                </FormWithChildren>
                                {buttons}
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </EmptyModal>
        );
    }
}

class Response extends Component {
    constructor(props) {
        super(props);

        this.select_response = this.select_response.bind(this);
    }

    select_response() {
        this.props.select_response(this.props.response);
    }

    render() {
        const { response } = this.props;
        let match = <Icon size={2} icon="times" style={{ color: 'red' }} />;
        if (response.original_has_component == response.qc_has_component) {
            match = <Icon size={2} icon="check" style={{ color: 'green' }} />;
        }

        let view = (
            <Button
                type="primary"
                text="View Discussion/Resolve"
                onClick={this.select_response}
            />
        );
        if (response.resolved) {
            view = (
                <Button
                    type="primary"
                    text="View Discussion"
                    onClick={this.select_response}
                />
            );
        }

        let last_response_user = 'NA';
        if (response.daily_qc_discussions.length > 0) {
            const last_response =
                response.daily_qc_discussions[
                    response.daily_qc_discussions.length - 1
                ].dailyqcdiscussion;
            console.log('Last response', last_response);
            last_response_user = `${last_response.user.first_name} ${last_response.user.last_name[0]}`;
        }

        let link = 'This Response Has Been Removed.';
        if (response.student_question_response) {
            link = (
                <a
                    href={`/student_response/${response.student_question_response.id}/`}
                >
                    {response.student_question_response.answer}
                </a>
            );
        }

        return (
            <tr>
                <td>{link}</td>
                <td
                    dangerouslySetInnerHTML={{
                        __html: response.original_has_component.toString(),
                    }}
                />
                <td
                    dangerouslySetInnerHTML={{
                        __html: response.qc_has_component.toString(),
                    }}
                />
                <td>{match}</td>
                <td>
                    {view}
                    <p>Last Response By: {last_response_user}</p>
                </td>
            </tr>
        );
    }
}

class DailyQCResponses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_loading: false,
            loaded: false,
            error: null,
            daily_qc: {},
            selected_response: null,
        };

        this.daily_qc_callback = this.daily_qc_callback.bind(this);
        this.refresh = this.refresh.bind(this);
        this.select_response = this.select_response.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        const related = [
            'question',
            'user',
            'qc_user',
            'daily_qc_responses',
            'daily_qc_responses__student_question_response',
            'daily_qc_responses__daily_qc_discussions',
            'daily_qc_responses__daily_qc_discussions__user',
            'approach_component',
        ];

        ajaxWrapper(
            'GET',
            `/api/home/dailyqc/${this.props.daily_qc}/?related=${related.join(
                ','
            )}`,
            {},
            this.daily_qc_callback
        );
        this.setState({ is_loading: true });
    }

    daily_qc_callback(result) {
        if (result.length == 0) {
            this.setState({
                error: 'No daily QC could be found for this item.  Please contact technical support if this issue persists.',
                is_loading: false,
                loaded: true,
            });
            return false;
        }

        let { selected_response } = this.state;
        const { dailyqc } = result[0];
        if (selected_response) {
            for (const index in dailyqc.daily_qc_responses) {
                const response =
                    dailyqc.daily_qc_responses[index].dailyqcresponse;
                if (response.id == selected_response.id) {
                    selected_response = response;
                }
            }
        }

        this.setState({
            daily_qc: result[0].dailyqc,
            is_loading: false,
            loaded: true,
            selected_response,
        });
    }

    select_response(response) {
        this.setState({ selected_response: response });
    }

    render() {
        let component_qc = false;
        if (
            this.state.daily_qc.approach_component &&
            this.state.daily_qc.approach_component.name
        ) {
            component_qc = true;
        }

        const unresolved_responses = [];
        const resolved_responses = [];
        for (const index in this.state.daily_qc.daily_qc_responses) {
            const response =
                this.state.daily_qc.daily_qc_responses[index].dailyqcresponse;

            if (response.resolved) {
                resolved_responses.push(
                    <Response
                        component_qc={component_qc}
                        response={response}
                        select_response={this.select_response}
                        refresh={this.refresh}
                    />
                );
            } else {
                unresolved_responses.push(
                    <Response
                        component_qc={component_qc}
                        response={response}
                        select_response={this.select_response}
                        refresh={this.refresh}
                    />
                );
            }
        }

        let loading_modal = null;
        let unresolved_response_modal = null;
        if (this.state.is_loading) {
            loading_modal = (
                <EmptyModal show>
                    <Wrapper loaded={!this.state.is_loading} />
                </EmptyModal>
            );
        } else if (this.state.selected_response) {
            unresolved_response_modal = (
                <UnResolvedReponse
                    component_qc={component_qc}
                    qc_user={this.state.daily_qc.qc_user}
                    user={this.state.daily_qc.user}
                    refresh={this.refresh}
                    response={this.state.selected_response}
                    daily_qc={this.state.daily_qc}
                    onHide={() => this.setState({ selected_response: null })}
                />
            );
        }

        let content = null;
        if (this.state.error) {
            content = (
                <div className="container">
                    <div
                        className="alert alert-danger"
                        style={{ textAlign: 'center' }}
                    >
                        {this.state.error}
                    </div>
                </div>
            );
        } else if (this.state.loaded) {
            let { answer } = this.state.daily_qc;
            answer = answer.split('%20').join(' ');
            let answer_display = <p>Answer: {answer}</p>;
            if (component_qc) {
                answer_display = (
                    <p>
                        Does this explanation contain{' '}
                        {`${this.state.daily_qc.approach_component.name}: ${this.state.daily_qc.approach_component.text}`}
                        ?
                    </p>
                );
            }

            content = (
                <div>
                    <div className="container">
                        <h2>Daily QC</h2>
                        <p>
                            Who Was QCed:{' '}
                            {this.state.daily_qc.qc_user.first_name}{' '}
                            {this.state.daily_qc.qc_user.last_name[0]}
                        </p>
                        <p>
                            Who QCed: {this.state.daily_qc.user.first_name}{' '}
                            {this.state.daily_qc.user.last_name[0]}
                        </p>
                        <p>
                            Match Score: {this.state.daily_qc.score.toFixed(0)}%
                        </p>
                        <p>
                            Analyst Score:{' '}
                            {this.state.daily_qc.analyst_score.toFixed(0)}%
                        </p>
                        <p>
                            Resolved Pct:{' '}
                            {this.state.daily_qc.resolved_pct.toFixed(0)}%
                        </p>
                        <p>
                            Question:{' '}
                            <QuestionDisplay
                                question={this.state.daily_qc.question}
                                display="text"
                                image
                            />
                        </p>
                        {answer_display}

                        <h4>Unresolved Responses</h4>
                        <table className="table">
                            <tr>
                                <th>Student Response</th>
                                <th>
                                    {this.state.daily_qc.qc_user.first_name}{' '}
                                    {this.state.daily_qc.qc_user.last_name[0]}'s
                                    WoE
                                </th>
                                <th>
                                    {this.state.daily_qc.user.first_name}{' '}
                                    {this.state.daily_qc.user.last_name[0]}'s
                                    WoE
                                </th>
                                <th>Match?</th>
                            </tr>
                            {unresolved_responses}
                        </table>

                        <h4>Resolved Responses</h4>
                        <table className="table">
                            <tr>
                                <th>Student Response</th>
                                <th>
                                    {this.state.daily_qc.qc_user.first_name}{' '}
                                    {this.state.daily_qc.qc_user.last_name[0]}'s
                                    WoE
                                </th>
                                <th>
                                    {this.state.daily_qc.user.first_name}{' '}
                                    {this.state.daily_qc.user.last_name[0]}'s
                                    WoE
                                </th>
                                <th>Match?</th>
                            </tr>
                            {resolved_responses}
                        </table>
                    </div>

                    {unresolved_response_modal}
                    {loading_modal}
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default DailyQCResponses;
