import React, { Component } from 'react';

import { Button } from 'library';

class SelectionChoice extends Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
    }

    select() {
        this.props.setGlobalState('asset', {
            id: this.props.value,
            heuristic_value: this.props.heuristic_value,
        });
    }

    render() {
        let btn_type = 'primary';
        if (this.props.heuristic_value === -1) {
            btn_type = 'warning';
        }

        return (
            <Button
                type={btn_type}
                text={this.props.name}
                onClick={this.select}
            />
        );
    }
}

export default class StandardsFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const assets = [];
        const not_assets = [];

        for (const item of this.props.assets_by_category) {
            const { category } = item;

            for (const jtem of category.assets) {
                const { asset } = jtem;
                assets.push(
                    <SelectionChoice
                        value={asset.id}
                        name={asset.name}
                        heuristic_value={1}
                        setGlobalState={this.props.setGlobalState}
                    />
                );

                not_assets.push(
                    <SelectionChoice
                        value={asset.id}
                        name={asset.name}
                        heuristic_value={-1}
                        setGlobalState={this.props.setGlobalState}
                    />
                );
            }
        }

        return (
            <div style={{ padding: '20px', paddingBottom: '40px' }}>
                <h2 style={{ textAlign: 'left' }}>Choose Asset</h2>
                <div style={{ padding: '10px' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <h4>Has:</h4>
                        {assets}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <h4>Has Not:</h4>
                        {not_assets}
                    </div>
                </div>
                <br />
            </div>
        );
    }
}
