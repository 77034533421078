import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import PopupCellRenderer from 'pages/analysts/feature_analysis/PopupCellRenderer';
import { Button } from 'library';

export default class ResponseList extends Component {
    constructor(props) {
        super(props);

        this.state = { feature_list: [], feature_filters: {}};
        this.addRowAsGroup = this.addRowAsGroup.bind(this);
        this.datasource = this.getDataSource();

        this.gridRef = React.createRef();
        
        this.gridOptions = {
            rowModelType: 'infinite',
            datasource: this.datasource,
            domLayout: 'normal',
            enableCellTextSelection: true,
            rowSelection: 'single'
        };
        

        this.columns = [
            {
                headerName: 'ID',
                sortable: false,
                flex: 1,
                valueGetter: 'node.id',
                cellRenderer: (props) => {
                  if (props.value !== undefined) {
                    return props.value;
                  } else {
                    return (
                      <img style={{ height: '100%' }} src="/static/images/loading_transparent.gif" />
                    );
                  }
                },
            },
            { 
                field: 'count',
                flex: 2,
                headerName: "Count of Responses",
                sort: 'desc',
                sortIndex: 1,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            { 
                field: 'level', 
                headerName: "Feature Level",
                flex: 2,
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan'],
                    maxNumConditions: 1,
                },
                sort: 'desc',
                sortIndex: 0,
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            { 
                field: 'name',
                flex: 4, 
                headerName: "Feature Name", 
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains', 'notContains'],
                    maxNumConditions: 1,
                },
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            { 
                field: 'string_value', 
                flex: 4,
                headerName: "Canonical Value", 
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains', 'notContains'],
                    maxNumConditions: 1,
                },
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            { 
                field: 'confidence', 
                flex: 2,
                headerName: "Confidence",
                filter: 'agNumberColumnFilter', 
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan'],
                    maxNumConditions: 1,
                },
            },
        ];
    }

    componentDidUpdate(previous_props) {
        if (this.props.scope_filters != previous_props.scope_filters) {
            this.gridRef.current?.api.setGridOption('datasource', this.getDataSource());
        }
    }

    componentDidMount() {
        
    }

    addRowAsGroup() {
        let selectedRows = this.gridRef.current?.api.getSelectedRows();
        if (selectedRows.length === 1) {
            let row = selectedRows[0];
            this.props.add_group_from_feature_table(row.name, row.string_value, row.confidence);
        }
    }

    getDataSource() {
        let datasource = {
            rowCount: undefined,
            getRows: (params) => {
                ajaxWrapper(
                    'POST',
                    `/analysis/aggregated_feature_values/${this.props.question_id}/`,
                    {
                        "scope_filters": this.props.scope_filters,
                        "feature_filters": this.state.feature_filters,
                        "filter_model": params.filterModel,
                        "sort_model": params.sortModel,
                        "start_row": params.startRow,
                        "end_row": params.endRow
                    },
                    result => {
                        let feature_list = result;
                        let rowsReturned = feature_list.length;
                        let rowCount = undefined;
                        if (rowsReturned < (params.endRow - params.startRow)) {
                            rowCount = params.startRow + rowsReturned;
                        }
                        this.setState({response_count: result.count});
                        params.successCallback(feature_list, rowCount);

                    }
                );
            },
        };
        return datasource;
    }

    render() {

        var content = (
            <div>
                <h2>Features of Responses Matching Ruleset</h2>
                <Button
                            
                            type="primary"
                            onClick={this.addRowAsGroup}
                            text="Add selected row to Ruleset"
                        />
                <a href={'https://docs.google.com/document/d/1ok1jks2HW2rhI-edooJM2zbqfs344X1LQvGUtuc8v9M/edit'} target='_blank'>Want to learn more about the Features? Click here!</a>
                <div className="ag-theme-quartz" style={{ height: 500, width: '100%' }}>
                    <AgGridReact
                        columnDefs={this.columns}
                        gridOptions={this.gridOptions}
                        ref={this.gridRef}
                        cacheBlockSize={100}
                        cacheOverflowSize={1}
                        maxConcurrentDatasourceRequests={1}
                        infiniteInitialRowCount={1}
                        blockLoadDebounceMillis={500}
                    />
                </div>
            </div>
        )

        return content;
    }

};