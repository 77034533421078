import React, { Component } from 'react';
import {
    FormWithChildren,
    TextInput,
    TextArea,
    Select,
    Button,
    NumberInput,
    EmptyModal,
} from 'library';

class EditComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.submit = this.submit.bind(this);
        this.toggle_open = this.toggle_open.bind(this);
    }

    submit() {
        this.setState({ open: false });
        this.props.refresh_data();
    }

    toggle_open() {
        this.setState({ open: !this.state.open });
    }

    render() {
        let text = 'Add New Component';
        let url = '/api/home/ApproachComponent/';
        let delete_url = null;
        let button_type = 'primary';

        let button_style = {};

        if (this.props.id) {
            url += `${this.props.id  }/`;
            delete_url =
                `/api/home/ApproachComponent/${  this.props.id  }/delete/`;

            text = 'Edit';

            button_style = {
                position: 'absolute',
                top: '0px',
                right: '15px',
            };
            button_type = 'info';
        }

        const data = { ...this.props};
        data.approach = data.approach_id;

        let content = (
            <Button type={button_type} text={text} onClick={this.toggle_open} />
        );

        if (this.state.open) {
            content = (
                <EmptyModal show onHide={this.toggle_open}>
                    <div style={{ padding: '20px' }}>
                        <FormWithChildren
                            defaults={data}
                            submitUrl={url}
                            deleteUrl={delete_url}
                            redirect={this.submit}
                        >
                            <TextInput
                                name="approach_id"
                                style={{ display: 'none' }}
                            />

                            <TextInput label="Name" name="name" />

                            <NumberInput label="Order" name="order" />
                            <Select
                                label="Type"
                                name="type"
                                options={[
                                    { text: 'Math', value: 'Math' },
                                    { text: 'Logic', value: 'Logic' },
                                ]}
                            />
                            <TextArea label="Text" name="text" />

                        </FormWithChildren>
                    </div>
                </EmptyModal>
            );
        }

        return content;
    }
}

export default EditComponent;
