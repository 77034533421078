import React from 'react';

export default function TextArea(props) {
    let layout = '';
    if (props.layout) {
        layout = props.layout;
    }
    let input_class = '';
    if (props.input_class) {
        input_class = props.input_class;
    }

    let label = null;
    if (props.label && props.label !== '') {
        label = <label>{props.label}</label>;
    }

    return (
        <div className={`form-group ${layout}`}>
            {label}
            <textarea
                className={`form-control ${input_class}`}
                name={props.name}
                rows={props.rows}
                onChange={props.handlechange}
                onBlur={props.onBlur}
                value={props.value}
                placeholder={props.placeholder}
                disabled={props.disabled | false}
            />
        </div>
    );
}
