export default function get_valid_keys(dict) {
    const keys = [];
    for (const item in dict) {
        if (dict[item]) {
            keys.push(item);
        }
    }

    return keys;
}
