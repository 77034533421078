
const math_anex_employee_roles = [
    'Super Admin',
    'Analyst',
    'Manager Analyst',
];

class UserValidator {
    logged_id(){
        if (!window.cmState.user) {
            return false;
        }

        return true;
    }

    is_staff() {
        if (!this.logged_id()){
            return false;
        } 

        let user = window.cmState.user;

        let valid_staff = false;
        if (user.is_staff){
            valid_staff = true;
        }

        if (math_anex_employee_roles.includes(user.role)){
            valid_staff = true;
        }

        return valid_staff;
    }

    is_teacher() {

    }

    is_district_staff() {

    }

}


export default UserValidator;
