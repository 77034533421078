import React, { Component } from 'react';
import { Form, TextInput, Button } from 'library';

class CSSInput extends Component {
    constructor(props) {
        super(props);

        this.setGlobalState = this.setGlobalState.bind(this);
        this.addCSSField = this.addCSSField.bind(this);
    }

    setGlobalState(name, state) {
        const newState = {};

        Object.keys(state).forEach(index => {
            if (index.indexOf('value_') > -1) {
                const i = index.split('_')[1];
                name = state[`name_${i}`];
                newState[name] = state[index];
            }
        });

        const formState = {};
        formState[this.props.name] = newState;
        this.props.setFormState(formState);
    }

    addCSSField() {
        const value = JSON.parse(JSON.stringify(this.props.value));

        value[''] = '';

        const formState = {};
        formState[this.props.name] = value;
        this.props.setFormState(formState);
    }

    render() {
        const components = [];
        const componentProps = [];
        const defaults = {};
        let i = 0;

        Object.keys(this.props.value).forEach(index => {
            components.push(TextInput);
            componentProps.push({
                name: `name_${i}`,
                layout: 'col-6',
                style: { width: '100%' },
            });
            defaults[`name_${i}`] = index;

            components.push(TextInput);
            componentProps.push({
                name: `value_${i}`,
                layout: 'col-6',
                style: { width: '100%' },
            });
            defaults[`value_${i}`] = this.props.value[index];
            i += 1;
        });

        return (
            <div className="form-group ">
                {this.props.label}
                <br />
                <Button
                    type="primary"
                    text="Add CSS Field"
                    onClick={this.addCSSField}
                />
                <br />
                <Form
                    layout="form-inline"
                    components={components}
                    componentProps={componentProps}
                    defaults={defaults}
                    autoSetGlobalState
                    setGlobalState={this.setGlobalState}
                    globalStateName="form"
                />
            </div>
        );
    }
}

export default CSSInput;
