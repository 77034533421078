import React from 'react';
import { Button, Icon, EmptyModal, Audio } from 'library';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal';
import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

export default class InfoTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show_details: !this.props.question.archived,
            show_download: false,
            show_confirmation_copy: false,
        };
    }

    render() {
        let question = this.props.question;

        const audioStyle = {
            width: '80%',
            marginInline: 'auto',
            display: 'block',
            marginTop: '8px',
        };

        let audio_displays = [];
        for (let key in question.audio_by_language) {
            audio_displays.push(
                <Audio
                    key={key}
                    src={question.audio_by_language[key]}
                    style={audioStyle}
                />
            );
        }

        return (
            <div
                style={{
                    paddingBlock: '12px',
                    borderTop: '1px solid #dee2e6',
                    transition: 'all ease-in-out 1s',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBlock: '12px',
                        borderTop: '1px solid #dee2e6',
                    }}
                >
                    <h5
                        title={question.name}
                        style={{
                            maxWidth: '85%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {question.name}
                    </h5>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                            justifyContent: 'center',
                        }}
                    >
                        {question.total_responses === 0 && (
                            <a
                                target="_blank"
                                style={{
                                    width: 'fit-content',
                                    borderRadius: '5px',
                                    display: 'block',
                                    minWidth: '0px',
                                    padding: '8px 7px 8px 9px',
                                    height: 'fit-content',
                                    lineHeight: '0px',
                                }}
                                className="btn-primary"
                                href={'/edit_question/' + question.id}
                            >
                                <Icon size={1} icon="edit" />
                            </a>
                        )}
                        <a
                            target="_blank"
                            style={{
                                width: 'fit-content',
                                borderRadius: '5px',
                                display: 'block',
                                minWidth: '0px',
                                padding: '8px',
                                height: 'fit-content',
                                lineHeight: '0px',
                                color: 'white',
                            }}
                            className="btn-primary"
                            onClick={() =>
                                this.setState({
                                    show_details: !this.state.show_details,
                                })
                            }
                        >
                            <Icon size={1} icon="eye" />
                        </a>
                    </div>
                </div>
                <div
                    style={{
                        display: this.state.show_details ? 'flex' : 'none',
                        flexWrap: 'wrap',
                    }}
                >
                    <QuestionEnlargeImage
                        image={question.images_by_language.English}
                        text={question.text_by_language.English}
                        height={150}
                    />
                    <div
                        style={{
                            width: '20%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'flex-start',
                        }}
                    >
                        <h4
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                height: 'fit-content',
                            }}
                        >
                            Text
                        </h4>
                        {Object.entries(question.text_by_language).map(
                            entry => (
                                <p
                                    key={entry[0]}
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        height: 'fit-content',
                                        margin: 0,
                                    }}
                                >
                                    {`${entry[0]}: ${entry[1]}`}
                                </p>
                            )
                        )}
                    </div>
                    <div
                        style={{
                            width: '20%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'flex-start',
                        }}
                    >
                        <h4
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                height: 'fit-content',
                            }}
                        >
                            Audio
                        </h4>
                        {audio_displays}
                    </div>
                    <div
                        style={{
                            width: '35%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'flex-start',
                            gap: '4px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>
                                Answer Type:
                            </h6>
                            <span>{question.answer_type}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>
                                Category:
                            </h6>
                            <span>{question.category.name}</span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>
                                Correct Answer:
                            </h6>
                            <span
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'auto',
                                    width: '50%',
                                }}
                            >
                                {question.correct_answer}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>
                                Non Explanation Question:
                            </h6>
                            <span
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'auto',
                                    width: '50%',
                                }}
                            >
                                {question.non_explanation_question.name}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>Notes:</h6>
                            <span
                                style={{
                                    width: '50%',
                                }}
                            >
                                {question.notes}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>Active:</h6>
                            <span
                                style={{
                                    width: '50%',
                                }}
                            >
                                {question.archived ? 'No' : 'Yes'}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <h6 style={{ margin: 0, width: '50%' }}>
                                Number of Answers:
                            </h6>
                            <span
                                style={{
                                    width: '50%',
                                }}
                            >
                                {question.total_responses}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <Button
                            onClick={() => setShowConfirmationCopy(true)}
                            text="Copy"
                            type="warning"
                            style={{ float: 'right' }}
                        />
                        {this.state.show_confirmation_copy && (
                            <EmptyModal
                                show={this.state.show_confirmation_copy}
                                onHide={() => {}}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        paddingInline: '48px',
                                        gap: '8px',
                                        paddingBlock: '32px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <h1 style={{ width: '100%' }}>
                                        Are you sure you want to copy it ?
                                    </h1>
                                    <Button
                                        onClick={() => {
                                            this.props.makeCopy(question);
                                            setShowConfirmationCopy(false);
                                        }}
                                        text="Confirm"
                                        type="primary"
                                    />
                                    <Button
                                        onClick={() =>
                                            setShowConfirmationCopy(false)
                                        }
                                        text="Cancel"
                                        type="danger"
                                    />
                                </div>
                            </EmptyModal>
                        )}
                        <div onClick={() => setShowDownload(true)}>
                            <Button type="info" text="Download Responses" />
                        </div>
                        {this.state.show_download && (
                            <DownloadModal
                                key={question.id}
                                url={`/reporting/question_student_responses_csv/${question.id}/`}
                                query={{}}
                                onHide={() => setShowDownload(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
