import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';
import {
    Wrapper,
    Alert,
    Container,
    Button,
    Icon,
    FormWithChildren,
    Select,
} from 'library';
import { prettifyString } from 'functions';

import SideNavbar from 'pages/reports/components/side_navbar.js';
import Header from 'pages/reports/components/header.js';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';
import Participation from 'pages/reports/pages/participation';

class DownloadResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            download: false,
            section_ids: null,
        };

        this.toggle_download = this.toggle_download.bind(this);
    }

    toggle_download() {
        this.setState({ download: !this.state.download });
    }

    render() {
        let filters = this.props.filters;
        filters['assessments'] = [this.props.assessment['id']];

        let download_modal = (
            <a style={{ color: 'blue' }} onClick={this.toggle_download}>
                {this.props.title}
            </a>
        );
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={
                        '/reporting/results_explorer/get_student_answers/' +
                        this.props.district_id +
                        '/'
                    }
                    query={{
                        csv: true,
                        filters: filters,
                        compare_filters: {},
                        answers_only: true,
                    }}
                    onHide={this.toggle_download}
                />
            );
        }

        return <div>{download_modal}</div>;
    }
}
export default class TeacherRoster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_students: false,
            show_students: false,
            students: [],
        };

        this.get_teachers_students = this.get_teachers_students.bind(this);
        this.load_teachers_students = this.load_teachers_students.bind(this);
    }

    get_teachers_students() {
        let url = `/reporting/teachers_roster/?teacher=${this.props.teacher}`;

        this.setState(
            {
                show_students: true,
                loading_students: true,
            },
            function () {
                ajaxWrapper('GET', url, {}, this.load_teachers_students);
            }.bind(this)
        );
    }

    load_teachers_students(result) {
        this.setState({
            students: result['all_students'],
            loading_students: false,
        });
    }

    render() {
        let student_lookup = null;
        if (this.props.teacher) {
            let show_students = (
                <a
                    className="purple-button-block"
                    onClick={this.get_teachers_students}
                    style={{ color: 'white' }}
                >
                    Show All Students
                </a>
            );
            if (this.state.show_students) {
                let students = [];
                for (let item of this.state.students) {
                    students.push(
                        <tr>
                            <td>
                                <a
                                    href={`/report_navigator/lookup/?student=${item['id']}`}
                                    style={{ color: 'blue' }}
                                >
                                    {`${item['last_name']}, ${item['first_name']}`}
                                </a>
                            </td>
                        </tr>
                    );
                }
                show_students = (
                    <table className="table bordered-table">
                        <tr>
                            <td>
                                <span style={{ fontWeight: '500' }}>
                                    Click Name To View Details
                                </span>
                                <div
                                    className="purple-button"
                                    onClick={() =>
                                        this.setState({ show_students: false })
                                    }
                                    style={{
                                        display: 'inline-block',
                                        width: 'auto',
                                        color: 'white',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Hide
                                </div>
                            </td>
                        </tr>
                        {students}
                    </table>
                );
            }

            student_lookup = (
                <div>
                    <h4>Looking for a specific student?</h4>
                    <Wrapper
                        loaded={!this.state.loading_students}
                        style={{ marginTop: '0px' }}
                    >
                        {show_students}
                    </Wrapper>
                </div>
            );
        }

        return student_lookup;
    }
}
