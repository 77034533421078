import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDateString } from 'functions';

import { Icon, Button, Wrapper } from 'library';

class CalibrationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            calibrations: [],
            user: { first_name: '', last_name: '' },
        };

        this.analyst_callback = this.analyst_callback.bind(this);
        this.calibration_callback = this.calibration_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/user/user/${this.props.user}/`,
            {},
            this.analyst_callback
        );
        ajaxWrapper(
            'GET',
            `/api/home/calibration/?&order_by=-updated_at&related=question,component,calibration_responses__count&user=${this.props.user}`,
            {},
            this.calibration_callback
        );
    }

    analyst_callback(result) {
        this.setState({ user: result[0].user, loaded: true });
    }

    calibration_callback(result) {
        const calibrations = [];
        for (const index in result) {
            const { calibration } = result[index];
            calibrations.push(calibration);
        }

        this.setState({ calibrations });
    }

    render() {
        const calibrations = [];
        for (const index in this.state.calibrations) {
            const calibration = this.state.calibrations[index];
            if (calibration.calibration_responses == 0) {
                continue;
            }

            let passed = (
                <Icon size={2} icon="times" style={{ color: 'red' }} />
            );
            if (calibration.passed) {
                passed = (
                    <Icon size={2} icon="check" style={{ color: 'green' }} />
                );
            }

            let answer = <td>{calibration.answer}</td>;
            if (calibration.component.text) {
                answer = (
                    <td>
                        {`${calibration.component.name}: ${calibration.component.text}`}
                    </td>
                );
            }
            calibrations.push(
                <tr>
                    <td>
                        {formatDateString(
                            calibration.created_at,
                            'mm/dd/yyyy HH:MM'
                        )}
                    </td>
                    <td>
                        {formatDateString(
                            calibration.updated_at,
                            'mm/dd/yyyy HH:MM'
                        )}
                    </td>
                    <td>{calibration.question.name}</td>
                    {answer}
                    <td>{calibration.score.toFixed(2)}%</td>
                    <td>{passed}</td>
                    <td>
                        <Button
                            type="primary"
                            text="Review"
                            href={`/calibration_responses/${calibration.id}/`}
                        />
                    </td>
                </tr>
            );
        }

        const content = (
            <div>
                <div className="container">
                    <h2>
                        Calibration Quizzes: {this.state.user.first_name}{' '}
                        {this.state.user.last_name[0]}
                    </h2>
                    <table className="table">
                        <tr>
                            <th>Start Time</th>
                            <th>Finish Time</th>
                            <th>Question</th>
                            <th>Answer/Component</th>
                            <th>Score</th>
                            <th>Passed?</th>
                            <th>Review Responses</th>
                        </tr>
                        {calibrations}
                    </table>
                </div>
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default CalibrationList;
