import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    Wrapper,
    Select,
    Button
} from 'library';

class CreateReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            jobs_initiated: [],
            submitted: false,
            selected_district: "",
            selected_term: "",
            assessments: [],
        };

        this.get_jobs = this.get_jobs.bind(this);
        this.get_assessments = this.get_assessments.bind(this);
        this.jobs_initiated_callback = this.jobs_initiated_callback.bind(this);

        this.create_reports = this.create_reports.bind(this);
        this.create_reports_callback = this.create_reports_callback.bind(this);
        this.create_reports_failure = this.create_reports_failure.bind(this);
    }

    componentDidMount() {
        this.get_jobs()
        this.get_assessments()
    }

    async get_jobs() {
        // get create_reports jobs
        ajaxWrapper(
            'GET',
            `/reporting/reports_jobs_statuses/`,
            {},
            this.jobs_initiated_callback
        );
    }

    jobs_initiated_callback(result) {
        this.setState({
            jobs_initiated: result,
        });
    }

    async get_assessments() {
        // get assessments
        ajaxWrapper(
            'GET',
            `/api/home/assessment/`,
            {},
            response => {
                this.setState({assessments: response})
            }
        );
    }

    create_reports(event, submit_success, submit_failure) {
        let request_data = {
            "district_id": event.district
        }
        if (event.assessment){
            request_data["assessment_id"] = event.assessment
        }
        if (event.school_term){
            request_data["school_term_id"] = event.school_term
        }
        ajaxWrapper(
            'POST',
            `/reporting/create_reports`,
            request_data,
            ((response_data) => {
                this.create_reports_callback(request_data, response_data, submit_success, submit_failure)})
        );
    }

    create_reports_callback(request, response, submit_success, submit_failure) {
        let district_id = request["district_id"]
        let assessment_id = request["assessment_id"] || ""
        let school_term_id = request["school_term_id"] || ""
        let timestamp = null
        let status = null
        let job_id = ""

        if (!response["error"]){
            timestamp = response["ResponseMetadata"]["HTTPHeaders"]["date"]
            status = response["ResponseMetadata"]["HTTPStatusCode"] == "200" ?
                        "INITIATED" : "ERROR"
            job_id = "pending"
            submit_success()
        } else {
            timestamp = Date.now()
            status = "ERROR"
            submit_failure()
        }
        this.setState({ jobs_initiated: [{
            "timestamp": (new Date(timestamp)).toISOString(),
            "district_name": "",
            "district_id": district_id,
            "assessment_name": "",
            "assessment_id": assessment_id,
            "school_term_name": "",
            "school_term_id": school_term_id,
            "status": status,
            "job_id": job_id
        }, ...this.state.jobs_initiated]})
    }

    create_reports_failure(){
        console.log("Failure while attempting to create reports")
    }

    render() {
        let create_reports = null;
        let reports_status_table = null;
        const table_rows = [];

        if (
            window.cmState.user.role == 'Super Admin' &&
            !this.props.isDuplicated
        ) {
            create_reports = 
                <Wrapper
                    loaded
                    style={{
                        margin: '0px',
                        padding: '20px',
                        textAlign: 'center',
                        backgroundColor: "#ffe37a"
                    }}
                    >
                    <div className="h2 bold header">
                        Create Reports:
                    </div>
                    <FormWithChildren
                        row
                        submit={this.create_reports}
                        submit_on_enter
                        submit_failure={this.create_reports_failure}
                        submit_text="Create Reports"
                        reset_state_on_submit
                    >
                        <Select
                            className="col-6"
                            required
                            optionsUrl="/api/sis/district/"
                            optionsUrlMap={{
                                text: '{district.name}',
                                value: '{district.id}',
                            }}
                            name="district"
                            label="District (Required)"
                            onChange={(event)=>this.setState({selected_district: event["district"]})}
                        />
                        <Select
                            className="col-6"
                            optionsUrl="/api/sis/schoolterm/"
                            optionsUrlMap={{
                                text: '{schoolterm.name}',
                                value: '{schoolterm.id}',
                            }}
                            name="school_term"
                            label="School Term (Optional)"
                            onChange={(event)=>this.setState({selected_term: event["school_term"]})}
                        />
                        <Select
                            className="col-6"
                            options={this.state.assessments.map((assessment) => ({
                                    "text": assessment["assessment"]["name"],
                                    "value": assessment["assessment"]["id"],
                                    "district": assessment["assessment"]["district_id"] || "",
                                    "school_term": assessment["assessment"]["school_term_id"] || "",
                                })).filter((option) => this.state.selected_district ?
                                    option["district"] == this.state.selected_district :
                                    true   
                                ).filter((option) => this.state.selected_term ?
                                    option["school_term"] == this.state.selected_term :
                                    true
                                ).sort((a,b) => a["district"].localeCompare(b["district"])
                                ).sort((a,b) => a["text"].localeCompare(b["text"])
                                )
                            }
                            name="assessment"
                            label="Assessment (Optional)"
                        />
                    </FormWithChildren>
                </Wrapper>

            const { jobs_initiated: jobsInitiated } = this.state;
            jobsInitiated.forEach(job_initiated => {
                table_rows.push(
                    <tr>
                        <td>{(new Date(job_initiated.timestamp)).toISOString()}</td>
                        <td>
                            <div>
                                {job_initiated.district_name}
                            </div>
                            <div>
                                {job_initiated.district_id}
                            </div>
                        </td>
                        <td>
                            <div>
                                {job_initiated.assessment_name}
                            </div>
                            <div>
                                {job_initiated.assessment_id}
                            </div>
                        </td>
                        <td>
                            <div>
                                {job_initiated.school_term_name}
                            </div>
                            <div>
                                {job_initiated.school_term_id}
                            </div>
                        </td>
                        <td>{job_initiated.status}</td>
                        <td>{job_initiated.job_id}</td>
                    </tr>
                );
            })
            reports_status_table =
                <Wrapper loaded
                    style={{
                        margin: '0px',
                        padding: '20px',
                        textAlign: 'center',
                        backgroundColor: "#f7efd2"
                    }}>
                    <div>
                        <span className="h2 bold header">
                            Reports Jobs Statuses:
                        </span>
                        <Button 
                            type="info"
                            text="⟳"
                            style={{
                                margin: "0px 0px 10px 30px",
                                padding: "2px",
                                height: "5%",
                                fontSize: "2rem"
                            }}
                            onClick={this.get_jobs}
                            />
                    </div>
                    <table className="table">
                        <tr>
                            <th><h5>Timestamp</h5></th>
                            <th><h5>District</h5></th>
                            <th><h5>Assessment</h5></th>
                            <th><h5>School Term</h5></th>
                            <th><h5>Status</h5></th>
                            <th><h5>Job ID</h5></th>
                        </tr>
                        {table_rows}
                        <tr>
                            <td>{}</td>
                            <td>{}</td>
                        </tr>
                    </table>
                </Wrapper>
        }

        return (
            <>
                <div className="h1 bold header" style={{textAlign: 'center', margin: "10px"}}>
                        Reports Creation and Status
                </div>
                {create_reports}
                <br />
                {reports_status_table}
            </>
        );
    }
}

export default CreateReports;
