import React, { Component } from 'react';

class BasicFileInput extends Component {
    static component_name = 'BasicFileInput';

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.setFormState({ [this.props.name]: e.target.files[0] });
        if (this.props.onChange) {
            this.props.onChange(e.target.files[0]);
        }
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="exampleFormControlFile1">
                    {this.props.label}
                </label>
                <br />
                <input
                    onChange={this.handleChange}
                    type="file"
                    className="form-control-file"
                    id="exampleFormControlFile1"
                    name={this.props.name}
                />
            </div>
        );
    }
}

export default BasicFileInput;
