import $ from 'jquery';

function handleerror(xhr, status, error) {
    //Error Handler
    console.log('Ajax Failure');
    console.log(xhr.responseText);
    console.log(status);
    console.log(error);
    //Error Handler
}

function clearTokens() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/login/';
}

function ajaxWrapper(type, url, data, returnFunc) {
    let headers = {};
    let xhrFields = {};

    let isFormData = (data instanceof FormData);
    let contentType = (isFormData ? false : 'application/json');
    // false contentType instead of multipart/form-data so browser can auto-detect and inject boundaries

    if (window.location.hostname === 'localhost') {
        url = 'http://localhost:8000' + url;
        xhrFields['withCredentials'] = true;
    }

    if (type === 'POST') {
        if (isFormData) {
            data.append('csrfmiddlewaretoken', window.secretReactVars.csrfmiddlewaretoken);
        } else {
            data.csrfmiddlewaretoken = window.secretReactVars.csrfmiddlewaretoken;
        }
        headers['X-CSRFToken'] = window.secretReactVars.csrfmiddlewaretoken;
    }

    if ((type === 'POST' || type === 'PUT') && !isFormData) {
        data = JSON.stringify(data);
    }

    let authToken = '';
    let beforeSend = null;
    if (localStorage.getItem('token')) {
        authToken = `Bearer ${localStorage.getItem('token')}`;
        beforeSend = request =>
            request.setRequestHeader('Authorization', authToken);
    }

    $.ajax({
        type,
        url,
        contentType,
        beforeSend,
        processData: !isFormData,
        data,
        headers,
        xhrFields,
        statusCode: {
            200(value) {
                console.log('return!');
                console.log(value);
                if (typeof value === 'object' && 'redirect' in value) {
                    window.location = `${value.redirect}?redirect=${window.secretReactVars.BASE_URL}`;
                }
                returnFunc(value);
            },
            400(value) {
                value = { error: 'Bad Request' };
                returnFunc(value);
            },
            401(xhr) {
                if (url.endsWith('/users/token/')) {
                    var value = { error: 'Invalid Credentials' };
                    returnFunc(value);
                } else {
                    refreshToken(type, url, data, xhr.responseJSON, returnFunc);
                }
            },
            403(xhr) {
                console.log('PERMISSIONED ERROR', xhr);
                alert(xhr['responseJSON']['detail']);
            },
            408(value) {
                var value = { error: 'Request Timed Out' };
                returnFunc(value);
            },
            500(value) {
                var value = { error: 'Internal Server Error' };
                returnFunc(value);
            },
        },
    });
}

function refreshToken(type, url, data, responseJSON, returnFunc) {
    if (url === '/users/user/' && responseJSON.code === 'user_not_found') {
        clearTokens();
        return false;
    }

    let refreshData = {};
    refreshData.csrfmiddlewaretoken =
        window.secretReactVars.csrfmiddlewaretoken;

    refreshData.refresh = '';
    if (localStorage.getItem('refresh_token')) {
        refreshData.refresh = localStorage.getItem('refresh_token');
    }

    refreshData = JSON.stringify(refreshData);

    // Revert data to JSON for POST and PUT requests
    if (type === 'POST' || type === 'PUT') {
        data = JSON.parse(data);
    }

    return $.ajax({
        type: 'POST',
        url: '/users/token/refresh/',
        contentType: 'application/json',
        data: refreshData,
        statusCode: {
            401(_xhr) {
                clearTokens();
            },
            500(_xhr) {
                clearTokens();
            },
        },
        success(value) {
            localStorage.setItem('token', value.access);
            ajaxWrapper(type, url, data, returnFunc);
        },
        error(xhr, status, error) {
            handleerror(xhr, status, error);
            clearTokens();
            // window.location.href = window.location.href;
        },
    });
}

export default ajaxWrapper;
