import React from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    Wrapper,
    PageBreak,
    FormWithChildren,
    TextInput,
    Button,
} from 'library';

export default class Overview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],
        };

        this.refresh = this.refresh.bind(this);
        this.load_overview = this.load_overview.bind(this);
        this.create_category = this.create_category.bind(this);
        this.create_asset = this.create_asset.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        ajaxWrapper(
            'GET',
            '/api/actionable_assets/category/?related=assets',
            {},
            this.load_overview
        );
    }

    load_overview(value) {
        this.setState({
            loaded: true,
            data: value,
        });
    }

    create_category(value, callback) {
        console.log(value);
        ajaxWrapper(
            'POST',
            '/api/actionable_assets/category/',
            value,
            this.refresh
        );

        setTimeout(callback, 1000);
    }

    create_asset(value, callback) {
        console.log(value);
        ajaxWrapper(
            'POST',
            '/api/actionable_assets/asset/',
            value,
            this.refresh
        );

        setTimeout(callback, 1000);
    }

    render() {
        const categories = [];
        for (const item of this.state.data) {
            const assets = [];

            for (const jtem of item.category.assets) {
                assets.push(
                    <div style={{ marginBottom: '10px' }}>
                        <FormWithChildren
                            style={{ float: 'right' }}
                            deleteUrl={
                                `/api/actionable_assets/asset/${ 
                                jtem.asset.id 
                                }/delete/`
                            }
                            deleteRedirectUrl="/actionable_assets/"
                         />
                        <Button
                            style={{ float: 'right' }}
                            type="primary"
                            href={
                                `/actionable_assets/${ 
                                jtem.asset.id 
                                }/`
                            }
                            text="Review"
                        />

                        <h5>{jtem.asset.name}</h5>

                        <div style={{ clear: 'both' }} />
                    </div>
                );
            }

            categories.push(
                <div className="simple-card">
                    <h4>{item.category.name}</h4>
                    <PageBreak style={{ margin: '10px' }} />
                    {assets}

                    <FormWithChildren
                        submit={this.create_asset}
                        defaults={{ category: item.category.id }}
                    >
                        <h4>Add Asset</h4>
                        <TextInput name="name" label="Name" />
                    </FormWithChildren>
                </div>
            );
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ margin: '0px' }}>
                <div className="sad" style={{ padding: '30px 0px 50px 0px' }}>
                    <Container min_height>
                        {categories}

                        <div className="simple-card">
                            <FormWithChildren submit={this.create_category}>
                                <h4>Add Category</h4>
                                <TextInput name="name" label="Name" />
                            </FormWithChildren>
                        </div>
                    </Container>
                </div>
            </Wrapper>
        );
    }
}
