import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects, formatDate, formatDateString } from 'functions';

import {
    FormWithChildren,
    Select,
    DateTimePicker,
    Icon,
    Tabs,
    Wrapper,
    Button,
} from 'library';
import { extend } from 'jquery';

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: 'black',
        };

        this.toggleHighlight = this.toggleHighlight.bind(this);
        this.clicked = this.clicked.bind(this);
    }

    toggleHighlight() {
        if (this.state.color == 'black') {
            this.setState({ color: 'blue' });
        } else {
            this.setState({ color: 'black' });
        }
    }

    clicked() {
        this.props.page_select(this.props.name);
    }

    render() {
        return (
            <div
                className="col-3"
                style={{
                    color: this.state.color,
                    padding: '20px',
                    cursor: 'pointer',
                }}
                onMouseEnter={this.toggleHighlight}
                onMouseLeave={this.toggleHighlight}
                onClick={this.clicked}
            >
                <h4>{this.props.name}</h4>
                <h4>{this.props.amount}</h4>
            </div>
        );
    }
}

class Assessments extends Component {
    constructor(props) {
        super(props);

        this.back_to_main = this.back_to_main.bind(this);
    }

    back_to_main() {
        this.props.page_select('main');
    }

    render() {
        var possible_props = [
            ['date_window_opens', 'Date Window Opens'],
            ['date_window_closes', 'Date Window Closes'],
            ['date_analysis_finished', 'Last Date for Analysis'],
            ['missing_students', 'Anonymous Assessments'],
            ['assessment_count', 'Assessments Taken'],
        ];
        var assessment_rows = [];
        for (var assessment of this.props.assessments) {
            var extra_data = [];
            for (var prop of possible_props) {
                if (prop[0] in assessment) {
                    if (prop[0] == 'missing_students') {
                        extra_data.push(
                            <td>
                                <a
                                    href={
                                        '/anonymous_assessments/' +
                                        assessment.id +
                                        '/'
                                    }
                                >
                                    {assessment[prop[0]]}
                                </a>
                            </td>
                        );
                    } else {
                        extra_data.push(<td>{assessment[prop[0]]}</td>);
                    }
                }
            }

            var row = (
                <tr>
                    <td>{assessment.district_name}</td>
                    <td>
                        <a
                            href={'/edit_assessment/' + assessment.id + '/'}
                            target="_blank"
                        >
                            {assessment.name}
                        </a>
                    </td>
                    {extra_data}
                </tr>
            );
            assessment_rows.push(row);
        }

        var headers = [<th>District Name</th>, <th>Assessment Name</th>];
        var assessment = this.props.assessments[0];
        if (assessment) {
            for (var prop of possible_props) {
                if (prop[0] in assessment) {
                    headers.push(<th>{prop[1]}</th>);
                }
            }
        }

        return (
            <div className="container-fluid">
                <div
                    style={{
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        padding: '20px',
                    }}
                >
                    <div class="row">
                        <h2 class="col-12">{this.props.name}</h2>
                        <Button
                            type="primary"
                            text="Back To Main"
                            onClick={this.back_to_main}
                        />
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <table className="table">
                        <tr>{headers}</tr>
                        {assessment_rows}
                    </table>
                </div>
            </div>
        );
    }
}

class KnowledgeAndApplication extends Component {
    constructor(props) {
        super(props);

        this.back_to_main = this.back_to_main.bind(this);
    }

    back_to_main() {
        this.props.page_select('main');
    }

    render() {
        var item_rows = [];
        for (var item of this.props.items) {
            if (item['count']) {
                var row = (
                    <tr>
                        <td>
                            <a
                                href={
                                    '/student_response_grouping/' +
                                    item.id +
                                    '/'
                                }
                                target="_blank"
                            >
                                {item.name}
                            </a>
                        </td>
                        <td>{item.text}</td>
                        <td>{item.count}</td>
                    </tr>
                );
            } else {
                var row = (
                    <tr>
                        <td>
                            <a
                                href={
                                    '/student_response_grouping/' +
                                    item.id +
                                    '/'
                                }
                                target="_blank"
                            >
                                {item.name}
                            </a>
                        </td>
                        <td>{item.text}</td>
                    </tr>
                );
            }

            item_rows.push(row);
        }

        var headers = (
            <tr>
                <th>Item Name</th>
                <th>Item Text</th>
            </tr>
        );

        if (item['count']) {
            headers = (
                <tr>
                    <th>Item Name</th>
                    <th>Item Text</th>
                    <th>Un-Grouped Responses</th>
                </tr>
            );
        }

        return (
            <div className="container-fluid">
                <div
                    style={{
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        padding: '20px',
                    }}
                >
                    <div class="row">
                        <h2 class="col-12">{this.props.name}</h2>
                        <Button
                            type="primary"
                            text="Back To Main"
                            onClick={this.back_to_main}
                        />
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <table className="table">
                        {headers}
                        {item_rows}
                    </table>
                </div>
            </div>
        );
    }
}

class Explanation extends Component {
    constructor(props) {
        super(props);

        this.back_to_main = this.back_to_main.bind(this);
    }

    back_to_main() {
        this.props.page_select('main');
    }

    render() {
        var item_rows = [];
        for (var item of this.props.items) {
            var row = (
                <tr>
                    <td>{item.name}</td>
                    <td>{item.text}</td>
                    <td>
                        <a
                            href={
                                '/scoring_configuration/question_component_overview/' +
                                item.id +
                                '/'
                            }
                            target="_blank"
                        >
                            Components
                        </a>
                    </td>
                    <td>
                        <a
                            href={'/response_explorer/' + item.id + '/'}
                            target="_blank"
                        >
                            Response Explorer
                        </a>
                    </td>
                    <td>
                        <a
                            href={'/response_scoring/' + item.id + '/'}
                            target="_blank"
                        >
                            Response Scoring
                        </a>
                    </td>
                </tr>
            );

            item_rows.push(row);
        }

        return (
            <div className="container-fluid">
                <div
                    style={{
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        padding: '20px',
                    }}
                >
                    <div class="row">
                        <h2 class="col-12">{this.props.name}</h2>
                        <Button
                            type="primary"
                            text="Back To Main"
                            onClick={this.back_to_main}
                        />
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <table className="table">
                        <tr>
                            <th>Item Name</th>
                            <th>Item Text</th>
                            <th>Components</th>
                            <th>Response Review</th>
                            <th>MCL Scoring</th>
                        </tr>
                        {item_rows}
                    </table>
                </div>
            </div>
        );
    }
}

class Components extends Component {
    constructor(props) {
        super(props);

        this.back_to_main = this.back_to_main.bind(this);
    }

    back_to_main() {
        this.props.page_select('main');
    }

    render() {
        var component_rows = [];
        var count_header = null;
        var count = null;
        for (var component of this.props.components) {
            if (component.component.review_count) {
                count = <td>{component.component.review_count}</td>
                count_header = <th>Count</th>
            }
            var row = (
                <tr>
                    <td>{component.question.name}</td>
                    <td>{component.question.text}</td>
                    <td>
                        <a
                            href={
                                '/scoring_configuration/component_automation_mapping/' +
                                component.question.id +
                                '/' +
                                component.component.id +
                                '/'
                            }
                            target="_blank"
                        >
                            {component.component.name}
                        </a>
                    </td>
                    {count}
                </tr>
            );

            component_rows.push(row);
        }

        return (
            <div className="container-fluid">
                <div
                    style={{
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        padding: '20px',
                    }}
                >
                    <div class="row">
                        <h2 class="col-12">{this.props.name}</h2>
                        <Button
                            type="primary"
                            text="Back To Main"
                            onClick={this.back_to_main}
                        />
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <table className="table">
                        <tr>
                            <th>Item Name</th>
                            <th>Item Text</th>
                            <th>Component Name</th>
                            {count_header}
                        </tr>
                        {component_rows}
                    </table>
                </div>
            </div>
        );
    }
}

export default class WorkThatNeedsDoin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            district_id: '',
            data: {
                assessments: {
                    being_analyzed: [],
                    being_taken: [],
                    being_prepped: [],
                    with_unknown_students: [],
                },
                knowledge_and_application: {
                    all: [],
                    without_correct_answer: [],
                    with_ungrouped_responses: [],
                    without_popular_ways_to_get_answer: [],
                },
                explanation: {
                    all: [],
                    ready_for_scoring: [],
                    un_automated_components: [],
                    component_review: [],
                },
            },
            page: 'main',
        };

        this.data_callback = this.data_callback.bind(this);
        this.page_select = this.page_select.bind(this);
        this.work_query = this.work_query.bind(this);
        this.filter_by_district = this.filter_by_district.bind(this);
    }

    componentDidMount() {
        this.work_query()
    }

    filter_by_district(data) {
        this.setState({district_id: data['district_id'], loaded: false}, this.work_query)
    }

    work_query() {
        ajaxWrapper('GET', '/work_that_needs_doin/?district_id=' + this.state.district_id, {}, this.data_callback);
    }

    data_callback(result) {
        console.log('Data Callback', result);

        this.setState({ data: result, loaded: true });
    }

    page_select(name) {
        this.setState({ page: name });
    }

    render() {
        let content = <div>Error</div>;

        if (this.state.page == 'main') {
            content = (
                <div className="container-fluid">


                    <div
                        style={{
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            padding: '20px',
                        }}
                    >
                        <div class="row">
                            <h2 class="col-12">Assessments</h2>
                        </div>
                        <div className="row">
                            <Page
                                name="Being Prepped"
                                amount={
                                    this.state.data.assessments.being_prepped
                                        .length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="Being Taken"
                                amount={
                                    this.state.data.assessments.being_taken
                                        .length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="Being Analyzed"
                                amount={
                                    this.state.data.assessments.being_analyzed
                                        .length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="With Unknown Students"
                                amount={
                                    this.state.data.assessments
                                        .with_unknown_students.length
                                }
                                page_select={this.page_select}
                            />
                        </div>
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <div
                        style={{
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            padding: '20px',
                        }}
                    >
                        <div class="row">
                            <h2 class="col-12">
                                Knowledge and Application Items
                            </h2>
                        </div>
                        <div className="row">
                            <Page
                                name="KA On Incomplete Assessments"
                                amount={
                                    this.state.data.knowledge_and_application
                                        .all.length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="W/O A Correct Answer"
                                amount={
                                    this.state.data.knowledge_and_application
                                        .without_correct_answer.length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="With Un-grouped Responses"
                                amount={
                                    this.state.data.knowledge_and_application
                                        .with_ungrouped_responses.length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="Without Ways To Get Answer"
                                amount={
                                    this.state.data.knowledge_and_application
                                        .without_popular_ways_to_get_answer
                                        .length
                                }
                                page_select={this.page_select}
                            />
                        </div>
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <div
                        style={{
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            padding: '20px',
                        }}
                    >
                        <div class="row">
                            <h2 class="col-12">Communication Items</h2>
                        </div>
                        <div className="row">
                            <Page
                                name="On Incomplete Assessments"
                                amount={this.state.data.explanation.all.length}
                                page_select={this.page_select}
                            />
                            <Page
                                name="Components W/O Automation"
                                amount={
                                    this.state.data.explanation
                                        .un_automated_components.length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="Components That Need Review"
                                amount={
                                    this.state.data.explanation.component_review
                                        .length
                                }
                                page_select={this.page_select}
                            />
                            <Page
                                name="Ready For Final Review and Scoring"
                                amount={
                                    this.state.data.explanation
                                        .ready_for_scoring.length
                                }
                                page_select={this.page_select}
                            />
                        </div>
                    </div>
                    <FormWithChildren defaults={this.state} submit={this.filter_by_district} row={true} submit_text={'Filter'} style={{marginTop:'20px'}}>
                            <Select
                                name="district_id"
                                label="District"
                                optionsUrl="/api/sis/district/?order_by=name"
                                optionsUrlMap={{
                                    text: '{district.name}',
                                    value: '{district.id}',
                                }}
                                class='col-3'
                            />
                     </FormWithChildren>
                </div>
            );
        } else {
            var page_dict = {
                'Being Prepped': (
                    <Assessments
                        page_select={this.page_select}
                        assessments={this.state.data.assessments.being_prepped}
                        name={this.state.page}
                    />
                ),
                'Being Taken': (
                    <Assessments
                        page_select={this.page_select}
                        assessments={this.state.data.assessments.being_taken}
                        name={this.state.page}
                    />
                ),
                'Being Analyzed': (
                    <Assessments
                        page_select={this.page_select}
                        assessments={this.state.data.assessments.being_analyzed}
                        name={this.state.page}
                    />
                ),
                'With Unknown Students': (
                    <Assessments
                        page_select={this.page_select}
                        assessments={
                            this.state.data.assessments.with_unknown_students
                        }
                        name={this.state.page}
                    />
                ),
                'W/O A Correct Answer': (
                    <KnowledgeAndApplication
                        page_select={this.page_select}
                        name={this.state.page}
                        items={
                            this.state.data.knowledge_and_application
                                .without_correct_answer
                        }
                    />
                ),
                'KA On Incomplete Assessments': (
                    <KnowledgeAndApplication
                        page_select={this.page_select}
                        name={this.state.page}
                        items={this.state.data.knowledge_and_application.all}
                    />
                ),
                'With Un-grouped Responses': (
                    <KnowledgeAndApplication
                        page_select={this.page_select}
                        name={this.state.page}
                        items={
                            this.state.data.knowledge_and_application
                                .with_ungrouped_responses
                        }
                    />
                ),
                'Without Ways To Get Answer': (
                    <KnowledgeAndApplication
                        page_select={this.page_select}
                        name={this.state.page}
                        items={
                            this.state.data.knowledge_and_application
                                .without_popular_ways_to_get_answer
                        }
                    />
                ),
                'Components W/O Automation': (
                    <Components
                        page_select={this.page_select}
                        name={this.state.page}
                        components={
                            this.state.data.explanation.un_automated_components
                        }
                    />
                ),
                'Components That Need Review': (
                    <Components
                        page_select={this.page_select}
                        name={this.state.page}
                        components={
                            this.state.data.explanation.component_review
                        }
                    />
                ),
                'Ready For Final Review and Scoring': (
                    <Explanation
                        page_select={this.page_select}
                        name={this.state.page}
                        items={this.state.data.explanation.ready_for_scoring}
                    />
                ),
                'On Incomplete Assessments': (
                    <Explanation
                        page_select={this.page_select}
                        name={this.state.page}
                        items={this.state.data.explanation.all}
                    />
                ),
            };
            content = page_dict[this.state.page];
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}
