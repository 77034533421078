import React, { Component } from 'react';

import Tooltip from 'pages/reports/results_explorer/components/tooltip.js';

export default class PercentDisplay extends Component {
    constructor(props) {
        super(props);

        this.show_student_list = this.show_student_list.bind(this);
    }

    show_student_list() {
        if (!this.props.data) {
            return false;
        }

        this.props.update_filters(
            'student_list',
            this.props.data,
            'Student Profiles'
        );
    }

    render() {
        const to_fixed = this.props.to_fixed || 0;

        const style = {
            fontWeight: 600,
            cursor: 'pointer',
            display: 'inline-block',
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            ...this.props.style,
        };

        const diff_style = {
            display: 'inline-block',
            paddingLeft: '5px',
            fontSize: '12px',
        };

        let { percent } = this.props;
        if (isNaN(percent)) {
            percent = 0;
        }
        const fixed_percent = percent.toFixed(to_fixed);
        let extension = '%';
        if (typeof this.props.extension !== 'undefined') {
            extension = this.props.extension;
        }

        let diff = null;
        let diff_display = null;
        if (this.props.diff && !isNaN(percent)) {
            diff = this.props.diff.toFixed(to_fixed);
            diff_display = this.props.show_percentage ? (
                <div className="percent-diff" style={diff_style}>
                    {diff + extension}
                </div>
            ) : (
                <></>
            );
        }

        let tooltip = (
            <div style={style}>
                <div
                    onClick={this.show_student_list}
                    className="percent"
                    style={style}
                >
                    {fixed_percent + extension}
                </div>
                {diff_display}
            </div>
        );

        if (this.props.proficiency_header) {
            let proficiency = this.props.proficiency_header.toLowerCase();
            if (proficiency == 'left blank') {
                proficiency = 'no evidence';
            }

            let percentage_header = `${fixed_percent}% of students showed ${proficiency} in the ${this.props.data.category.toLowerCase()} category.`;
            if (this.props.diff) {
                let direction = 'higher';
                if (this.props.diff < 0) {
                    direction = 'lower';
                }

                percentage_header += `  This is ${Math.abs(
                    this.props.diff
                ).toFixed(to_fixed)}% ${direction} than district results.`;
            }

            const tooltip_title = <div className="h2">{percentage_header}</div>;
            const tooltip_text = '(click the percent for a student list)';

            tooltip = (
                <Tooltip title={tooltip_title} text={tooltip_text}>
                    <div
                        onClick={this.show_student_list}
                        className="percent"
                        style={style}
                    >
                        {fixed_percent + extension}
                    </div>
                    {diff_display}
                </Tooltip>
            );
        }

        return (
            <div
                className="percent-container"
                style={this.props.container_style}
            >
                {tooltip}
            </div>
        );
    }
}
