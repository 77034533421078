// using datamapping in list or form you can pass a dictionary like so dataMapping={'name':'Bob', 'id':'{id}', 'children':'{children.length}'}
// in this case, name will always be bob, id will resolve to the id of the data and children will resolve to the length of data.children

function resolveVariables(dataMapping, data) {
    const mappedData = fillDict(dataMapping, data);
    // console.log("Resolver", dataMapping, data)
    return mappedData;
}

function fillDict(dict, data) {
    let info = { ...dict };
    if (Array.isArray(dict)) {
        info = Object.assign([], dict);
    }

    Object.keys(info).forEach(key => {
        // Broad Detection of React.Element type and probably other things.
        if (info[key]) {
            if (typeof info[key].type === 'function') {
                console.log('React.Component');
            } else if (typeof info[key] === 'object') {
                info[key] = fillDict(info[key], data);
            } else {
                const tempStr = info[key];
                info[key] = fillData(tempStr, data);
            }
        }
    });

    return info;
}

function fillData(tempStr, data) {
    if (typeof tempStr !== 'string') {
        return tempStr;
    }

    const dataSplit = tempStr.split('{');
    // Add initial text to output
    let cleaned = dataSplit[0];
    // Search through string pieces to find closing tag
    for (let i = 1; i < dataSplit.length; i + 1) {
        const innerSplit = dataSplit[i].split('}');
        if (innerSplit.length > 1) {
            const variable = innerSplit[0].split('.');
            let value = data;

            Object.keys(variable).forEach(key => {
                const miniVar = variable[key];
                if (miniVar === 'length') {
                    value = value.length;
                } else if (value) {
                    value = value[miniVar];
                }
            });

            if (typeof value === 'object') {
                cleaned += JSON.stringify(value) + innerSplit[1];
            } else {
                cleaned += value + innerSplit[1];
            }
        } else {
            cleaned += innerSplit[0];
        }
    }

    return cleaned;
}

function isJsonable(v) {
    try {
        return (
            JSON.stringify(v) === JSON.stringify(JSON.parse(JSON.stringify(v)))
        );
    } catch (e) {
        /* console.error("not a dict",e); */
        return false;
    }
}

export default resolveVariables;
