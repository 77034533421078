import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects, formatDate, formatDateString } from 'functions';

import { FormWithChildren, DateTimePicker, Icon, Tabs, Wrapper } from 'library';

class Analyst extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { analyst } = this.props;
        const total_time = analyst.total_seconds;
        if (total_time > 0) {
            const hours = Math.floor(total_time / 3600);
            let minutes = Math.floor((total_time - hours * 3600) / 60);
            if (minutes < 10) {
                minutes = `0${minutes}`;
            }
            let seconds = Math.floor(total_time - hours * 3600 - minutes * 60);
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            var time_display = `${hours}:${minutes}:${seconds}`;
        } else {
            time_display = 'None';
        }

        let answer_without_wot = (
            <Icon size={2} icon="times" style={{ color: 'red' }} />
        );
        if (analyst.answer_without_wot) {
            answer_without_wot = (
                <Icon size={2} icon="check" style={{ color: 'green' }} />
            );
        }

        let { name } = analyst;
        if (window.cmState.user.role == 'Super Admin') {
            name = <a href={`/edit_analyst/${analyst.id}/`}>{analyst.name}</a>;
        }

        var last_analysis = 'NA';
        if (analyst.last_analysis != 'NA') {
            var last_analysis = formatDateString(
                analyst.last_analysis,
                'mm/dd/yyyy'
            );
        }

        let { scored_since_last_qc } = analyst;
        if (analyst.last_daily_qc == 'NA') {
            scored_since_last_qc = analyst.total;
        }

        console.log('Analyst', analyst);

        return (
            <tr>
                <td>{name}</td>
                <td>
                    <a href={`/calibration_list/${analyst.id}/`}>
                        {analyst.calibrations}
                    </a>
                </td>
                <td>{last_analysis}</td>
                <td>
                    <a href={`/latest_responses/${analyst.id}/`}>
                        {analyst.total}
                    </a>
                </td>
                <td>
                    <a
                        href={`/user_qc/${analyst.id}/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {analyst.qcs}
                    </a>
                </td>
                <td>{time_display}</td>
                <td>
                    {((analyst.total / analyst.total_seconds) * 3600).toFixed(
                        1
                    )}
                </td>
                <td>
                    <a href={`/daily_qc_list/${analyst.id}/`}>
                        {analyst.avg_daily_qc.toFixed(2)}%
                    </a>
                </td>
                <td>
                    <a href={`/daily_qc_list/${analyst.id}/`}>
                        {analyst.avg_resolved_pct.toFixed(2)}%
                    </a>
                </td>
                <td>{scored_since_last_qc}</td>
            </tr>
        );
    }
}

class AnalystOverview extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const now = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
        const last_week = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 6
        );

        this.state = {
            loaded: false,
            text: '',
            answer: '',
            question: this.props.item_id,
            way_of_thinking: this.props.way_of_thinking_id,
            alert: '',
            start_date: formatDate(last_week, 'yyyy-mm-dd'),
            end_date: formatDate(now, 'yyyy-mm-dd'),
        };

        this.analyst_callback = this.analyst_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
        this.setState(state, this.refresh_analysts);
    }

    componentDidMount() {
        this.refresh_analysts();
    }

    refresh_analysts() {
        ajaxWrapper(
            'GET',
            `/analyst_overview_query/?start_date=${this.state.start_date}&end_date=${this.state.end_date}`,
            {},
            this.analyst_callback
        );
    }

    analyst_callback(result) {
        const analyst_results = result.analysts;
        const analysts = [];
        for (const item in analyst_results) {
            if (item != 'null') {
                analysts.push(analyst_results[item]);
            }
        }

        sort_objects(analysts, ['total'], true);

        console.log('Analyst Callback', result);
        console.log(analysts);

        this.setState({
            analysts,
            teams: result.teams,
            loaded: true,
        });
    }

    render() {
        const analysts = [];
        for (var index in this.state.analysts) {
            const analyst = this.state.analysts[index];
            if (
                analyst.last_analysis != 'NA' &&
                window.cmState.user.role == 'Super Admin'
            ) {
                analysts.push(<Analyst analyst={analyst} />);
            } else {
                analysts.push(<Analyst analyst={analyst} />);
            }
        }

        if (window.cmState.user.role == 'Super Admin') {
            const team_rows = [];
            for (var index in this.state.teams) {
                const team = this.state.teams[index];

                let responses_per_hour = 'NA';
                const total_time = team.total_seconds;
                if (total_time > 0) {
                    responses_per_hour = (
                        (team.responses_analyzed / team.total_seconds) *
                        3600
                    ).toFixed(2);
                    const hours = Math.floor(total_time / 3600);
                    let minutes = Math.floor((total_time - hours * 3600) / 60);
                    if (minutes < 10) {
                        minutes = `0${minutes}`;
                    }
                    let seconds = Math.floor(
                        total_time - hours * 3600 - minutes * 60
                    );
                    if (seconds < 10) {
                        seconds = `0${seconds}`;
                    }
                    var time_display = `${hours}:${minutes}:${seconds}`;
                } else {
                    time_display = 'None';
                }

                team_rows.push(
                    <tr>
                        <td>{team.manager_name}</td>
                        <td>{team.qcs}</td>
                        <td>{team.responses_analyzed}</td>
                        <td>{time_display}</td>
                        <td>{responses_per_hour}</td>
                        <td>{team.daily_qc_average.toFixed(2)}%</td>
                        <td>{team.resolved_pct.toFixed(2)}%</td>
                        <td>{team.analyzed_since_last_qc}</td>
                    </tr>
                );
            }

            var team_content = (
                <div>
                    <div style={{ padding: '10px 0px' }}>
                        <FormWithChildren
                            row
                            defaults={this.state}
                            autoSetGlobalState
                            globalStateName="dates"
                            setGlobalState={this.setGlobalState}
                        >
                            <DateTimePicker
                                display_time={false}
                                name="start_date"
                                label="Start Date"
                                className="col-md-6 col-sm-12"
                            />
                            <DateTimePicker
                                display_time={false}
                                name="end_date"
                                label="End Date"
                                className="col-md-6 col-sm-12"
                            />
                        </FormWithChildren>
                    </div>
                    <table className="table">
                        <tr>
                            <th>Manager Name</th>
                            <th>QCs</th>
                            <th>Responses Analyzed</th>
                            <th>Time Spent H:M:S (Updated Once Per Day)</th>
                            <th>Components Per Hour</th>
                            <th>Daily QC Avg</th>
                            <th>Resolved Pct</th>
                            <th>Analyzed Since Last QC</th>
                        </tr>
                        {team_rows}
                    </table>
                </div>
            );
        }

        const analyst_content = (
            <div>
                <div style={{ padding: '10px 0px' }}>
                    <FormWithChildren
                        row
                        defaults={this.state}
                        autoSetGlobalState
                        globalStateName="dates"
                        setGlobalState={this.setGlobalState}
                    >
                        <DateTimePicker
                            display_time={false}
                            name="start_date"
                            label="Start Date"
                            className="col-md-6 col-sm-12"
                        />
                        <DateTimePicker
                            display_time={false}
                            name="end_date"
                            label="End Date"
                            className="col-md-6 col-sm-12"
                        />
                    </FormWithChildren>
                </div>
                <table className="table">
                    <tr>
                        <th>Name</th>
                        <th># of Item/Answers Calibrated</th>
                        <th>Last Analysis Done</th>
                        <th>Responses Analyzed</th>
                        <th>QCs</th>
                        <th>Time Spent H:M:S (Updated Once Per Day)</th>
                        <th>Components Per Hour</th>
                        <th>Daily QC Avg</th>
                        <th>Resolved Pct</th>
                        <th>Analyzed Since Last QC</th>
                    </tr>
                    {analysts}
                </table>
            </div>
        );

        if (window.cmState.user.role == 'Super Admin') {
            var tabs = null;
            if (window.cmState.user.role != 'Manager Analyst') {
                var tabs = (
                    <Tabs
                        initial_tab="Teams"
                        tab_names={['Analysts', 'Teams']}
                        tab_contents={{
                            Analysts: analyst_content,
                            Teams: team_content,
                        }}
                    />
                );
            }
            var content = (
                <div className="">
                    <div className="container-fluid">
                        <h2>Analyst Overview</h2>
                        {tabs}
                    </div>
                </div>
            );
        } else {
            var content = (
                <div className="">
                    <div className="container-fluid">
                        <h2>Analyst Overview</h2>
                        {analyst_content}
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default AnalystOverview;
