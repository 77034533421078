import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    Select,
    TextInput,
    TextArea,
    Wrapper,
    DictBuilder,
    Alert,
} from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

class EditQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            question: {},
            add_new: false,

            response_count: 0,
        };

        this.get_question = this.get_question.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        if (!this.props.question_id) {
            this.setState({
                loaded: true,
                add_new: true,
            });
        } else {
            ajaxWrapper(
                'GET',
                '/api/home/question/' +
                    this.props.question_id +
                    '/?related=standard_domains',
                {},
                this.get_question
            );

            ajaxWrapper(
                'GET',
                '/api/home/studentquestionresponse/?question=' +
                    this.props.question_id +
                    '&count',
                {},
                value => this.setState({ response_count: value['count'] })
            );
        }
    }

    get_question(result) {
        const question = result[0].question;
        question.category = question.category_id;
        question.non_explanation_question =
            question.non_explanation_question_id;

        question.question = question.question_id;
        delete [question.question];

        const domains = question.standard_domains;
        question['standard_domains[]'] = [];
        for (let item of domains) {
            const domain = item['domain'].id;
            question['standard_domains[]'].push(domain);
        }
        this.setState({
            question,
            loaded: true,
        });
    }

    redirect(value) {
        if (this.state.add_new) {
            window.location = `/edit_question/${value[0].question.id}/`;
        } else {
            window.location = '/questions/';
        }
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        var content = null;
        const modal_display = this.state.modal;

        if (this.state.loaded) {
            let title = 'Add New Question';
            let submitUrl = '/api/home/question/';
            let deleteUrl = null;
            if (this.props.question_id) {
                title = `Edit Question Version: ${this.state.question.name}`;
                submitUrl += `${this.state.question.id}/`;
                deleteUrl = `${submitUrl}delete/`;
            }

            let question_in_use = null;
            let question_in_use_warning = `This question had been answered by ${this.state.response_count} students.  Be very careful about editing the image or text!`;
            if (this.state.response_count > 0) {
                question_in_use = (
                    <Alert type="danger">{question_in_use_warning}</Alert>
                );
            }

            var content = (
                <div className="container">
                    <h2>{title}</h2>
                    <Alert type="info">
                        <QuestionDisplay
                            question={this.state.question}
                            display="text"
                            image
                        />
                    </Alert>

                    {question_in_use}

                    <FormWithChildren
                        submitUrl={submitUrl}
                        deleteUrl={deleteUrl}
                        redirect={this.redirect}
                        defaults={this.state.question}
                        protect_form={this.state.response_count}
                        protect_form_text={question_in_use_warning}
                    >
                        <TextInput name="name" label="Name" />

                        <DictBuilder
                            name="text_by_language"
                            label="Text By Language"
                        />
                        <DictBuilder
                            name="audio_by_language"
                            label="Audio By Language"
                            value_type="audio"
                        />
                        <DictBuilder
                            name="images_by_language"
                            label="Images By Language"
                            value_type="image"
                        />
                        <DictBuilder
                            name="videos_by_language"
                            label="Videos By Language"
                            value_type="file"
                        />

                        <Select
                            required
                            optionsUrl="/api/home/questioncategory/"
                            optionsUrlMap={{
                                text: '{questioncategory.name}',
                                value: '{questioncategory.id}',
                            }}
                            name="category"
                            label="Category"
                        />

                        <Select
                            optionsUrl="/api/learning_standards/domain/"
                            optionsUrlMap={{
                                text: '{domain.name}',
                                value: '{domain.id}',
                            }}
                            name="standard_domains[]"
                            label="Standard Domains"
                            multiple
                        />

                        <TextInput
                            name="topic"
                            label="Topic (Currently Only In Use For Lodi)"
                        />

                        <Select
                            name="answer_type"
                            label="Answer Type"
                            options={[
                                { text: 'Numeric Answer', value: 'numeric' },
                                {
                                    text: 'Number Range(s) ex. "1,3","5,inf" ==> Between 1 and 3 or Between 5 and infinity.',
                                    value: 'number_range',
                                },
                                {
                                    text: 'Looking for An Equation',
                                    value: 'equation',
                                },
                                {
                                    text: 'Multiple Choice',
                                    value: 'multiple_choice',
                                },
                                { text: 'Multi-Select', value: 'multi_select' },
                                { text: 'No Answer', value: 'no_answer' },
                            ]}
                        />

                        <DictBuilder
                            name="multiple_choices_by_language"
                            label="Multiple Choices By Language"
                            top_level_list
                        />

                        <TextInput
                            name="correct_answer"
                            label="Correct Answers (Use ; to seperate)"
                        />

                        <Select
                            optionsUrl="/api/home/question/"
                            optionsUrlMap={{
                                text: '{question.name}',
                                value: '{question.id}',
                            }}
                            name="non_explanation_question"
                            label="Non Explanation Question"
                        />
                        <Select
                            options={[
                                { text: 'Yes', value: 'true' },
                                { text: 'No', value: 'false' },
                            ]}
                            name="non_explanation_numeric"
                            label="Non Explanation Numeric?"
                        />

                        <Select
                            options={[
                                { text: 'Yes', value: 'true' },
                                { text: 'No', value: 'false' },
                            ]}
                            name="archived"
                            label="Archived?"
                        />

                        <Select
                            boolean
                            name="needs_review"
                            label="Needs Review"
                        />
                        <TextInput
                            name="slack_channel"
                            label="Slack Channel (ex. C033MKVRTN2)"
                        />
                        <TextArea name="notes" label="Notes" />

                        <DictBuilder
                            name="synonyms"
                            label="Synonyms (Comma Seperated)"
                            custom_keys={true}
                        />

                        <TextArea name="reason_to_ask" label="Why this item?" />
                        <TextInput
                            name="generic_reengagement"
                            label="Re-Engagement Link"
                        />

                        <TextArea
                            name="reengagement_description"
                            label="Re-Engagement Description"
                        />
                    </FormWithChildren>

                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default EditQuestion;
