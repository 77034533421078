import React, { Component } from 'react';

import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Button, Alert } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';
import EditApproach from 'pages/approach_analysis/edit_approach.js';
import EditComponent from 'pages/approach_analysis/edit_component.js';

class ApproachComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const component_display = `${this.props.component.name}: ${this.props.component.text}`;

        let edit_button = null;

        if (window.cmState.user.role == 'Super Admin') {
            edit_button = (
                <EditComponent
                    {...this.props.component}
                    refresh_data={this.props.refresh_data}
                />
            );
        }

        return (
            <div className="simple-card">
                <div className="row">
                    <div className="col-9" style={{ minHeight: '45px' }}>
                        {component_display}
                    </div>
                    <div className="col-3">
                        <Button
                            href={`/scoring_configuration/component_automation_mapping/${this.props.question_id}/${this.props.component.id}/`}
                            text="Configure Mapping (1.0)"
                            type="primary"
                            style={{ marginBottom: '10px' }}
                        />
                        <Button
                            href={`/student_thinking_lab/${this.props.question_id}/${this.props.component.id}/`}
                            text="Configure Ruleset (2.0)"
                            type="primary"
                            style={{ marginBottom: '10px' }}
                        />
                        {edit_button}
                    </div>
                </div>
            </div>
        );
    }
}

class QuestionComponentsOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            question: null,
            approaches: [],
        };

        this.refresh_approach_data = this.refresh_approach_data.bind(this);
    }

    componentDidMount() {
        this.refresh_approach_data();
    }

    refresh_approach_data() {
        if (this.state.loaded) {
            this.setState({ loaded: false });
        }

        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/?related=approaches,approaches__components`,
            {},
            result => {
                let question = result[0]['question'];
                let approaches = question['approaches'];
                delete question['approaches'];

                this.setState({
                    question: question,
                    approaches: approaches,
                    loaded: true,
                });
            }
        );
    }

    render() {
        let add_approach = null;
        const approaches = [];

        for (let item of this.state.approaches) {
            const approach = item['approach'];
            const components = [];

            for (let jtem of approach['components']) {
                const component = jtem['approachcomponent'];

                components.push(
                    <ApproachComponent
                        refresh_data={this.refresh_approach_data}
                        component={component}
                        question_id={this.props.question_id}
                        total={component.total}
                    />
                );
            }

            let add_component = null;
            let edit_approach = null;
            if (window.cmState.user.role == 'Super Admin') {
                add_component = (
                    <EditComponent
                        approach_id={approach.id}
                        refresh_data={this.refresh_approach_data}
                    />
                );

                edit_approach = (
                    <EditApproach
                        {...approach}
                        refresh_data={this.refresh_approach_data}
                    />
                );
            }

            approaches.push(
                <div>
                    <div className="simple-card">
                        <h4>{approach.name}</h4>
                        <div>{approach.text}</div>
                        {edit_approach}

                        <div className="simple-grey-container">
                            {components}
                            <div className="simple-card">
                                <div className="row">
                                    <div className="col-9"></div>
                                    <div className="col-3">{add_component}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (window.cmState.user.role == 'Super Admin') {
            add_approach = (
                <EditApproach
                    question_id={this.props.question_id}
                    refresh_data={this.refresh_approach_data}
                />
            );
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ marginTop: '0px' }}>
                <div className="sad">
                    <div className="container">
                        <div className="simple-card">
                            <div>
                                <h2>Question Components Overview</h2>
                                <Alert type="info">
                                    <QuestionDisplay
                                        question={this.state.question}
                                        display="full_text"
                                        image
                                    />
                                </Alert>
                            </div>

                            <div>
                                <Button
                                    text="Explore All Responses"
                                    type="success"
                                    href={`/response_explorer/${this.props.question_id}/`}
                                />

                                {add_approach}

                                <Button
                                    text="Find Math"
                                    type="dark"
                                    href={`/find_math/${this.props.question_id}/`}
                                />

                                <Button
                                    text="Context Explorer"
                                    type="dark"
                                    href={`/question_context/${this.props.question_id}/`}
                                />
                            </div>
                        </div>

                        {approaches}
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default QuestionComponentsOverview;
