import React from 'react';

export default function Header(props) {
    const style = props.style || {};

    let header = <h1 style={style}>{props.text}</h1>;
    if (props.size) {
        if (props.size === 2) {
            header = <h2 style={style}>{props.text}</h2>;
        } else if (props.size === 3) {
            header = <h3 style={style}>{props.text}</h3>;
        } else if (props.size === 4) {
            header = <h4 style={style}>{props.text}</h4>;
        } else if (props.size === 5) {
            header = <h5 style={style}>{props.text}</h5>;
        } else if (props.size === 6) {
            header = <h6 style={style}>{props.text}</h6>;
        }
    }

    return <div className={props.className}>{header}</div>;
}
