import React, { Component } from 'react';
import { sort_objects } from 'functions';
import ajaxWrapper from 'base/ajax.js';

import { Icon, FormWithChildren, Select, Wrapper } from 'library';
import QuestionImage from 'pages/questions/image.js';

class FilterHeader extends Component {
    constructor(props) {
        super(props);

        this.sort_by = this.sort_by.bind(this);
    }

    sort_by() {
        this.props.question_sort(this.props.text);
    }

    render() {
        let sort_icon = null;
        if (this.props.sort_by == this.props.text) {
            if (this.props.reverse) {
                sort_icon = <Icon size={1} icon="caret-down" />;
            } else {
                sort_icon = <Icon size={1} icon="caret-up" />;
            }
        }

        return (
            <th onClick={this.sort_by} style={{ cursor: 'pointer' }}>
                {this.props.text} {sort_icon}
            </th>
        );
    }
}

export default class QuestionsByFilteredScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: {},
            question_list: [],
            loaded: false,
            group_by: 'student_assessment__student_snapshot__grade',
            category: '',
            sort_by: '',
            reverse: false,
        };

        this.get_questions = this.get_questions.bind(this);
        this.query_questions = this.query_questions.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);

        this.question_sort = this.question_sort.bind(this);
    }

    componentDidMount() {
        this.query_questions();
    }

    setGlobalState(name, state) {
        this.setState(state, this.query_questions);
    }

    query_questions() {
        const data = {
            group_by: this.state.group_by,
            category: this.state.category,
        };

        ajaxWrapper(
            'POST',
            '/questions_by_filtered_score/',
            data,
            this.get_questions
        );
    }

    get_questions(result) {
        const question_list = [];
        for (const index in result.question_results) {
            question_list.push(result.question_results[index]);
        }
        this.setState({
            questions: result.question_results,
            filter_list: result.filter_list,
            loaded: true,
            question_list,
        });
    }

    question_sort(sort_by) {
        console.log('Question Sort', sort_by);
        let reverse = false;
        if (sort_by == this.state.sort_by) {
            reverse = !this.state.reverse;
        }

        let question_list = [];
        const { questions } = this.state;
        for (var index in questions) {
            var question = questions[index];
            if (question.results[sort_by]) {
                question_list.push(question);
            }
        }
        question_list = sort_objects(
            question_list,
            ['results', sort_by, 'pct_correct'],
            reverse
        );

        for (var index in questions) {
            var question = questions[index];
            if (!question.results[sort_by]) {
                question_list.push(question);
            }
        }

        this.setState({
            sort_by,
            reverse,
            question_list,
        });
    }

    render() {
        const question_table_rows = [];

        for (var index in this.state.question_list) {
            const question = this.state.question_list[index];
            const row = [];
            row.push(
                <td>
                    <QuestionImage question={question} />
                </td>
            );

            for (const i in this.state.filter_list) {
                const filter = this.state.filter_list[i];
                const question_details = question.results[filter];
                if (question_details) {
                    const { pct_correct } = question_details;
                    let color = '#006B3D';
                    if (pct_correct < 80 && pct_correct >= 60) {
                        color = '#069C56';
                    } else if (pct_correct < 60 && pct_correct >= 40) {
                        color = '#FF980E';
                    } else if (pct_correct < 40 && pct_correct >= 20) {
                        color = '#FF681E';
                    } else if (pct_correct < 20) {
                        color = '#D3212C';
                    }
                    row.push(
                        <td style={{ backgroundColor: color }}>
                            {pct_correct.toFixed(2)}% ({question_details.count})
                        </td>
                    );
                } else {
                    row.push(<td>N/A</td>);
                }
            }
            question_table_rows.push(<tr>{row}</tr>);
        }

        const headers = [];
        for (var index in this.state.filter_list) {
            headers.push(
                <FilterHeader
                    text={this.state.filter_list[index]}
                    question_sort={this.question_sort}
                    sort_by={this.state.sort_by}
                    reverse={this.state.reverse}
                />
            );
        }

        const content = (
            <div className="container">
                <h2>Questions By Score</h2>
                <FormWithChildren
                    autoSetGlobalState
                    setGlobalState={this.setGlobalState}
                    globalStateName="filter"
                    defaults={this.state}
                    row
                >
                    <Select
                        label="Group"
                        name="group_by"
                        className="col-6"
                        options={[
                            {
                                text: 'By Grade',
                                value: 'student_assessment__student_snapshot__grade',
                            },
                            {
                                text: 'By English Proficiency',
                                value: 'student_assessment__student_snapshot__ell_status',
                            },
                            {
                                text: 'By Race/Ethnicity',
                                value: 'student_assessment__student_snapshot__race',
                            },
                        ]}
                    />
                    <Select
                        label="Question Type"
                        name="category"
                        className="col-6"
                        options={[
                            { text: 'Knowledge', value: 'Knowledge' },
                            { text: 'Application', value: 'Application' },
                            { text: 'Mindset', value: 'Mindset' },
                            { text: 'Not Scored', value: 'Not Scored' },
                        ]}
                    />
                </FormWithChildren>

                <table className="table">
                    <tr
                        style={{
                            position: 'sticky',
                            top: '60px',
                            backgroundColor: 'white',
                        }}
                    >
                        <th>Question</th>
                        {headers}
                    </tr>
                    {question_table_rows}
                </table>
            </div>
        );

        return <Wrapper content={content} loaded={this.state.loaded} />;
    }
}
