import React, { Component } from 'react';

import { Icon } from 'library';
import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

const COMMUNICATION_CATEGORY = 'bcba4111-d2fc-4dc1-b19a-a1b843c6e8ee';

export default class QuestionAnswerDetailCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answer_width: 0,
            answer_height: 0,
            show_more: false,
        };

        this.answer_ref = React.createRef();
    }

    componentDidMount() {
        this.setState({
            answer_width: this.answer_ref.current.offsetWidth,
            answer_height: this.answer_ref.current.offsetHeight,
        });
    }

    render() {
        const { question_response } = this.props;
        const { question } = this.props;
        const { order } = this.props;

        const question_details = (
            <QuestionEnlargeImage
                image={question.images_by_language.English}
                text={question.text_by_language.English}
            />
        );

        let answer_description = null;
        if (
            question_response.group &&
            question_response.group.parent &&
            question_response.group.parent.description
        ) {
            answer_description = (
                <div style={{ padding: '10px 0px' }}>
                    <div className="grey-text">
                        One Way To Get This Answer:{' '}
                    </div>
                    <div
                        className="sad-card"
                        dangerouslySetInnerHTML={{
                            __html: question_response.group.parent.description,
                        }}
                    />
                </div>
            );
        }

        const answer_wrapper_style = {
            position: 'relative',
            maxHeight: '200px',
            overflow: 'hidden',
            transition: '.2s height',
        };
        let show_more = null;
        if (this.state.show_more) {
            answer_wrapper_style.maxHeight = 'none';
            answer_wrapper_style.height = `${this.state.answer_height}px`;
        } else if (this.state.answer_height > 200) {
            const show_more_style = {
                cursor: 'pointer',
                position: 'absolute',
                right: '0px',
                bottom: '0px',
                border: 'thin solid',
                padding: '6px 12px',
                background: 'white',
            };

            show_more = (
                <div
                    onClick={() => this.setState({ show_more: true })}
                    className="purple-text icon-button light-purple-border"
                    style={show_more_style}
                >
                    <Icon size={1} icon="chevron-down" /> Show More
                </div>
            );
        }

        let marker = null;
        if (question_response.has_been_scored) {
            marker = (
                <Icon
                    icon="times"
                    size="2"
                    className="marker"
                    style={{ color: '#adadad' }}
                />
            );

            if (question.category_id == COMMUNICATION_CATEGORY) {
                if (question_response.score > 0) {
                    marker = (
                        <div
                            style={{
                                float: 'right',
                                background: 'rgba(77, 210, 147, .5)',
                                padding: '2px 4px',
                                borderRadius: '3px',
                                border: 'thin solid #eee',
                            }}
                        >
                            {question_response.mcl_score}
                        </div>
                    );
                } else {
                    marker = (
                        <div
                            style={{
                                float: 'right',
                                background: 'rgba(173, 173, 173, .5)',
                                padding: '2px 4px',
                                borderRadius: '3px',
                                border: 'thin solid #eee',
                            }}
                        >
                            {question_response.mcl_score}
                        </div>
                    );
                }
            } else if (question_response.score >= 1) {
                marker = (
                    <Icon
                        icon="check"
                        size="2"
                        className="marker"
                        style={{ color: '#4DD293' }}
                    />
                );
            }
        }

        return (
            <div
                className="col-6 col-xxl-3 question"
                style={{ padding: '0px' }}
            >
                <div className="result-display-card">
                    <div
                        className="h2 bold"
                        style={{
                            margin: '10px 0px',
                            display: 'flex',
                            gap: '8px',
                        }}
                    >
                        <span>{`Question #${order + 1}`}:</span>
                        <span>{question.category}</span>
                    </div>

                    {question_details}

                    <div style={answer_wrapper_style}>
                        <div ref={this.answer_ref}>
                            <div style={{ padding: '10px 0px' }}>
                                <span className="grey-text">
                                    Student Answer:{' '}
                                </span>
                                <div className="sad-card">
                                    {marker}
                                    {question_response.answer}
                                    <div style={{ clear: 'both' }} />
                                </div>
                            </div>

                            {answer_description}

                            {show_more}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
