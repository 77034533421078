import React, { Component } from 'react';

const TOOLTIP_TEXT = {
    'Strong Evidence':
        'Strong Evidence indicates that a student showed proficiency across most of all of the skills required to solve the items they were assessed on.',
    'Partial Evidence':
        'Partial Evidence indicates that a student showed proficiency across many of the skills required to solve the items they were assessed on.',
    'Incomplete Evidence':
        "Incomplete Evidence means that we did not get a lot of evidence that they were proficient or strong in the topic and we'd like to look elsewhere or on a more personal level to better understand their strengths.",
    'Left Blank':
        "Left Blank indicates that these students left every answer blank on the assessment. Maybe they were unable to finish in time or started the assessment but didn't take it, or they were intimidated by a group of questions. We don't know and we have no idea how they would've performed.",
    Knowledge:
        'This competency addresses procedural and conceptual understandings that align to the development of a skill. These items are tasks that resemble problems experienced in classroom instructional activities, or offer opportunities for connecting and/or building upon a mathematical idea(s) continued throughout the unit. This competency also includes the use of tools (pencil and paper, concrete models, a ruler, a compass, a spreadsheet, a statistical package, or dynamic geometry software, etc.).',
    Application:
        'This competency addresses a student’s ability to make assumptions and approximations about, strategize, and/or model a situation. This includes recognizing the role of student reflection and revision as essential to this process. Items in this category can present a “stretch” for students, but are most importantly grounded in contexts that arise in everyday life, society, and the workplace. Application problems are also defined as items where students construct their own pathway(s) and consider and select appropriate conceptual and physical tools to solve mathematical problems.',
    Communication:
        'This competency addresses a student’s ability to communicate their mathematical understanding with items that involve justifying a mathematical idea or concept by referencing an appropriate math model, connecting to the context of the task, and using clear and complete logic. Items in this category provide an opportunity for students to develop conjectures and illustrate their understanding verbally and/or in written form. Students should be able to build a logical progression of statements across genres of mathematical texts as they generate their ideas and formulate their own solution paths individually or as a group. Genres of mathematical texts students should be able to read, write, speak, and listen to include explanations of thinking, point of view in strategy development/processing, and argument validity.',
};

export default class Tooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_hovered: false,
            show: false,

            left: 0,
            width: this.props.width || 400,
        };

        this.wrapper = React.createRef();

        this.show_tooltip = this.show_tooltip.bind(this);
        this.hide_tooltip = this.hide_tooltip.bind(this);
        this.on_hover = this.on_hover.bind(this);
        this.on_hover_exit = this.on_hover_exit.bind(this);
    }

    hide_tooltip() {
        if (this.state.is_hovered) {
            return false;
        }

        this.setState({
            show: false,
        });
    }

    show_tooltip() {
        if (!this.state.is_hovered) {
            return false;
        }

        const left = this.wrapper.current ? this.wrapper.current.offsetLeft : 0;
        const top = this.wrapper.current ? this.wrapper.current.offsetTop : 0;
        const width = this.wrapper.current
            ? this.wrapper.current.clientWidth
            : 0;

        let left_position = (-1 * this.state.width) / 2 + width / 2;
        if (left <= Math.abs(left_position)) {
            left_position = parseInt(-1 * (left - 15));
        }

        const arrow_left = width / 2 + Math.abs(left_position) - 15;

        this.setState({
            show: true,
            left: left_position,
            arrow_left,
        });
    }

    on_hover() {
        this.setState({ is_hovered: true });

        setTimeout(() => {
            this.show_tooltip();
        }, 300);
    }

    on_hover_exit() {
        this.setState({ is_hovered: false });

        setTimeout(() => {
            this.hide_tooltip();
        }, 500);
    }

    render() {
        const title = this.props.title || (
            <div className="h2">{this.props.lookup_name}</div>
        );

        const tooltip_style = {
            left: `${this.state.left}px`,
            width: `${this.state.width}px`,
        };

        const tooltip_arrow_style = {
            left: `${this.state.arrow_left}px`,
        };

        let tooltip_text = TOOLTIP_TEXT[this.props.lookup_name];
        if (this.props.text) {
            tooltip_text = this.props.text;
        }

        if (this.state.show) {
            tooltip_style.display = 'block';
        }

        const tooltip = (
            <div className="tooltip-text" style={tooltip_style}>
                {title}
                {tooltip_text}
                <div
                    className="tooltip-bottom-arrow"
                    style={tooltip_arrow_style}
                />
            </div>
        );

        return (
            <div
                className="tooltip-wrapper"
                ref={this.wrapper}
                onMouseEnter={this.on_hover}
                onMouseLeave={this.on_hover_exit}
            >
                {this.props.children}
                {tooltip}
            </div>
        );
    }
}
