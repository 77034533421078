import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';

import { Button, Wrapper } from 'library';

class ReportNavigatorDistrictSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            districts: [],
        };

        this.get_districts = this.get_districts.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/sis/district/', {}, this.get_districts);
    }

    get_districts(result) {
        const districts = result.map(d => d.district);

        sort_objects(districts, ['name']);

        this.setState({ districts, loaded: true });
    }

    render() {
        let content = null;
        const districts = [];
        const { districts: districtsState } = this.state;
        districtsState.forEach(district => {
            districts.push(
                <tr>
                    <td>
                        <Button
                            type="primary"
                            text="View"
                            href={`/report_navigator/?district_spoof=${district.id}`}
                        />
                    </td>
                    <td>{district.name}</td>
                </tr>
            );
        });

        content = (
            <div className="container" style={{ textAlign: 'left' }}>
                <h2>Select A District</h2>
                <Button
                    text="Add A District"
                    type="success"
                    href="/edit_district/"
                />
                <div style={{ height: '20px' }} />
                <table className="table">{districts}</table>
            </div>
        );
        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default ReportNavigatorDistrictSelect;
