import React, { Component } from 'react';

import { BarElement, LinearScale, CategoryScale, Chart} from "chart.js"
Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(BarElement)

import { Bar } from 'react-chartjs-2';

import { Icon, FormWithChildren, Select, ClickableContent } from 'library';

import PercentDisplay from 'pages/reports/results_explorer/components/percent_display.js';
import Tooltip from 'pages/reports/results_explorer/components/tooltip.js';

const AREA_CHOICES = [
    {
        text: 'View by:',
        value: 'none',
    },
    {
        text: 'Class',
        value: 'Class',
    },
    {
        text: 'School',
        value: 'School',
    },
    {
        text: 'Area',
        value: 'Area',
    },
    {
        text: 'District',
        value: 'District',
    },
];

const TIME_CHOICES = [
    {
        text: 'Current Students',
        value: 'Current Students',
    },
    {
        text: 'Student from Assessment',
        value: 'Student from Assessment',
    },
];

const COMPARE_CHOICES = [
    {
        text: 'Compare to:',
        value: null,
    },
    {
        text: 'Compare to last semester',
        value: 'Compare to last semester',
    },
    {
        text: 'Compare to my School',
        value: 'Compare to my School',
    },
    {
        text: 'Compare to my District',
        value: 'Compare to my District',
    },
];

export default class DistrictReport extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const content = null;
        const { district } = this.props.data;

        const proficiencies = ['Strong', 'Partial', 'Incomplete', 'Blank'];
        const categories = ['Knowledge', 'Application', 'Communication'];
        const table_row_headers = [
            'Strong Evidence',
            'Partial Evidence',
            'Incomplete Evidence',
            'Left Blank',
        ];
        const table_rows = [];
        for (var key of table_row_headers) {
            table_rows.push([
                <td>
                    <Tooltip lookup_name={key}>{key}</Tooltip>
                </td>,
            ]);
        }

        const proficiency_data = {};
        for (let index = 0; index < proficiencies.length; index++) {
            var key = proficiencies[index];
            const key_header = table_row_headers[index];

            proficiency_data[key] = [];

            for (const category of categories) {
                const category_key = category.toLowerCase();
                let diff = null;
                let percentage = 0;

                let participation = 0;
                for (var prof in this.props.data[category_key]) {
                    participation += this.props.data[category_key][prof];
                }
                let district_participation = 0;
                for (var prof in this.props.data.district[category_key]) {
                    district_participation +=
                        this.props.data.district[category_key][prof];
                }

                if (
                    !(participation == 0 || !this.props.data[category_key][key])
                ) {
                    percentage =
                        (this.props.data[category_key][key] / participation) *
                        100;
                }

                if (this.props.is_filtered) {
                    const district_pct =
                        (this.props.data.district[category_key][key] /
                            district_participation) *
                        100;

                    if (!isNaN(percentage - district_pct)) {
                        diff = percentage - district_pct;
                    }
                }

                const data = {
                    category,
                    proficiency: key,
                };
                table_rows[index].push(
                    <td>
                        <PercentDisplay
                            show_percentage={this.props.show_percentage}
                            percent={percentage}
                            diff={diff}
                            update_filters={this.props.update_filters}
                            data={data}
                            proficiency_header={key_header}
                        />
                    </td>
                );

                proficiency_data[key].push(percentage);
            }
        }

        const table_rows_jsx = [];
        for (const row of table_rows) {
            table_rows_jsx.push(<tr>{row}</tr>);
        }

        const state = {
            labels: ['Knowledge', 'Application', 'Communication'],
            datasets: [
                {
                    label: 'Strong',
                    backgroundColor: '#3878F9',
                    data: proficiency_data.Strong,
                    borderRadius: 5,
                    borderSkipped: false,
                    categoryPercentage: 0.5,
                },
                {
                    label: 'Partial',
                    backgroundColor: '#93A1FD',
                    data: proficiency_data.Partial,
                    borderRadius: 5,
                    borderSkipped: false,
                    categoryPercentage: 0.5,
                },
                {
                    label: 'Incomplete',
                    backgroundColor: '#FC7F53',
                    data: proficiency_data.Incomplete,
                    borderRadius: 5,
                    borderSkipped: false,
                    categoryPercentage: 0.5,
                },
                {
                    label: 'Left Blank',
                    backgroundColor: '#47E3FA',
                    data: proficiency_data.Blank,
                    borderRadius: 5,
                    borderSkipped: false,
                    categoryPercentage: 0.5,
                },
            ],
        };

        const bar_chart_options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        usePointStyle: true,
                        font: {
                            size: 14,
                        },
                        padding: 20,
                    },
                },
            },
            elements: {
                pointStyle: 'circle',
            },
            scales: {
                y: {
                    min: 0,
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback(value, index, ticks) {
                            return `${value}%`;
                        },
                    },
                },
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        font: {
                            size: 16,
                        },
                    },
                },
            },
        };

        return (
            <div style={{ position: 'relative' }}>
                <div className="result-display-card">
                    <div style={{ paddingBottom: '20px' }}>
                        <div style={{ float: 'right' }}>
                            <FormWithChildren
                                key="district_overview"
                                style={{ display: 'inline-block' }}
                                defaults={this.props.standard_filters}
                                autoSetGlobalState
                                globalStateName="district_overview"
                                setGlobalState={
                                    this.props.update_standard_filter_view
                                }
                            >
                                {/*
                                <Select name='area_scope' options={AREA_CHOICES} style={{display: 'inline-block', margin: '0px 10px 0px 0px'}} />
                                */}
                                <Select
                                    name="time_scope"
                                    options={TIME_CHOICES}
                                    style={{
                                        display: 'inline-block',
                                        margin: '0px 10px 0px 0px',
                                    }}
                                />

                                <Select
                                    name="compare_scope"
                                    options={COMPARE_CHOICES}
                                    style={{
                                        display: 'inline-block',
                                        margin: '0px 10px 0px 0px',
                                    }}
                                />
                            </FormWithChildren>
                            <ClickableContent
                                key="district_overview_zoom_in"
                                onClick={this.zoom_in}
                                className="purple-text sad icon-button"
                            >
                                <Icon size={1} icon="search-plus" />
                            </ClickableContent>
                            <ClickableContent
                                key="district_overview_zoom_out"
                                onClick={this.zoom_out}
                                className="purple-text sad icon-button"
                                style={{ margin: '0px' }}
                            >
                                <Icon size={1} icon="search-minus" />
                            </ClickableContent>
                        </div>

                        <div className="h2 bold header">
                            Results for All Assessments
                        </div>
                    </div>
                    <Bar
                        data={state}
                        options={bar_chart_options}
                        style={{ maxHeight: '460px' }}
                    />
                </div>

                <div className="result-display-card">
                    <div className="h2 bold header">Overview</div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '6px',
                            alignItems: 'center',
                        }}
                    />
                    <table className="table">
                        <tr>
                            <td className="detail">Category</td>
                            <th className="detail">
                                <Tooltip lookup_name="Knowledge">
                                    Knowledge
                                </Tooltip>
                            </th>
                            <th className="detail">
                                <Tooltip lookup_name="Application">
                                    Application
                                </Tooltip>
                            </th>
                            <th className="detail">
                                <Tooltip lookup_name="Communication">
                                    Communication
                                </Tooltip>
                            </th>
                        </tr>
                        {table_rows_jsx}
                    </table>
                </div>
            </div>
        );
    }
}
