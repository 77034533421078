import React from 'react';

import { formatDate } from 'functions';

import { FunctionalCalculator, Icon } from 'library';

export default function proctor_rendering(details, question_lookup) {
    const rows = [];
    const opened_questions = [];

    for (const item of details) {
        const event = item.proctorevent;
        const calculator = new FunctionalCalculator();

        let question_text = '';
        if (event.question_id) {
            question_text =
                question_lookup[event.question_id].text_by_language.English;
        }

        const json_details = [];
        const raw_json_details = [];
        const existing_answers = {};
        let last_detail = null;

        let desmos_running_text = '';
        let desmos_last_answer = 0;
        for (const input of event.additional_details) {
            const date_string = formatDate(
                new Date(input.timestamp),
                'HH:MM:SS'
            );

            if (input.type == 'open') {
                if (opened_questions.indexOf(event.question_id) > -1) {
                    json_details.push(
                        <div>
                            <Icon
                                size={2}
                                icon="folder-open"
                                style={{
                                    color: '#ffd43b',
                                    opacity: '.6',
                                    marginRight: '5px',
                                }}
                            />
                            {`Opened at ${date_string}`}
                        </div>
                    );
                } else {
                    json_details.push(
                        <div>
                            <Icon
                                size={2}
                                icon="folder-open"
                                style={{
                                    color: 'green',
                                    opacity: '.6',
                                    marginRight: '5px',
                                }}
                            />
                            {`Opened at ${date_string}`}
                        </div>
                    );
                }
            } else if (input.type == 'input') {
                for (var key in input.value) {
                    if (!(key in existing_answers)) {
                        existing_answers[key] = {
                            last_value: '',
                            direction: 0,
                            changed: false,
                        };
                    }

                    if (
                        input.value[key].length >
                        existing_answers[key].last_value.length
                    ) {
                        if (existing_answers[key].direction < 0) {
                            json_details.push(
                                <div>
                                    <Icon
                                        size={2}
                                        icon="eraser"
                                        style={{
                                            color: 'red',
                                            opacity: '.6',
                                            marginRight: '5px',
                                        }}
                                    />
                                    <span style={{ fontWeight: '600' }}>
                                        {key}
                                    </span>
                                    {`: ${existing_answers[key].last_value}`}
                                </div>
                            );
                        }

                        existing_answers[key].last_value = input.value[key];
                        existing_answers[key].direction = 1;
                        existing_answers[key].changed = true;
                    } else if (
                        input.value[key].length <
                        existing_answers[key].last_value.length
                    ) {
                        if (existing_answers[key].direction > 0) {
                            json_details.push(
                                <div>
                                    <Icon
                                        size={2}
                                        icon="pencil-alt"
                                        style={{
                                            color: 'green',
                                            opacity: '.6',
                                            marginRight: '5px',
                                        }}
                                    />
                                    <span style={{ fontWeight: '600' }}>
                                        {key}
                                    </span>
                                    {`: ${existing_answers[key].last_value}`}
                                </div>
                            );
                        }

                        existing_answers[key].last_value = input.value[key];
                        existing_answers[key].direction = -1;
                        existing_answers[key].changed = true;
                    }
                }
            } else if (input.type == 'calculator') {
                if (
                    input.value == '=' ||
                    (input.value == 'AC' && last_detail.value == '=')
                ) {
                    const last_displayed = calculator.get_display_value();
                    calculator.input(input.value);
                    const displayed = calculator.get_display_value();

                    if (input.value == '=') {
                        json_details.push(
                            <div>
                                <Icon
                                    size={2}
                                    icon="calculator"
                                    style={{
                                        color: 'green',
                                        opacity: '.6',
                                        marginRight: '5px',
                                    }}
                                />
                                {`${last_displayed} -> ${displayed}`}
                            </div>
                        );
                    } else {
                        json_details.push(
                            <div>
                                <Icon
                                    size={2}
                                    icon="calculator"
                                    style={{
                                        color: 'red',
                                        opacity: '.6',
                                        marginRight: '5px',
                                    }}
                                />
                                {last_displayed}
                            </div>
                        );
                    }
                } else {
                    calculator.input(input.value);
                }
            } else if (input.type == 'desmos-calculator') {
                if ('add' in input.value) {
                    desmos_running_text += input.value['add'];
                } else if ('remove' in input.value) {
                    let last_index = desmos_running_text.lastIndexOf(
                        input.value['remove']
                    );
                    if (last_index >= 0) {
                        desmos_running_text = desmos_running_text.substring(
                            0,
                            last_index
                        );
                    }
                } else if (
                    'evaluate' in input.value &&
                    input.value['evaluate']
                ) {
                    desmos_running_text = desmos_running_text.replace(
                        '\\operatorname{ans}',
                        desmos_last_answer
                    );
                    desmos_running_text = desmos_running_text.replace(
                        '\\div',
                        '/'
                    );

                    console.log(
                        'DESMOS PRE EVAL',
                        desmos_running_text,
                        desmos_last_answer
                    );

                    let new_result = '';

                    try {
                        new_result = evaluatex(desmos_running_text)();
                    } catch (exception) {
                        new_result =
                            'Evaluation Error (PLEASE SEND LINK TO DEV TEAM)';
                    }

                    console.log('DESMOS EVAL', new_result);
                    json_details.push(
                        <div>
                            <Icon
                                size={2}
                                icon="calculator"
                                style={{
                                    color: 'green',
                                    opacity: '.6',
                                    marginRight: '5px',
                                }}
                            />
                            {`${desmos_running_text} -> ${new_result}`}
                        </div>
                    );
                    desmos_last_answer = new_result;
                    desmos_running_text = '';
                }
            }

            raw_json_details.push(
                <div className="row">
                    <div className="col-2" style={{ fontWeight: '500' }}>
                        {`${input.type}:`}
                    </div>
                    <div className="col-10">{JSON.stringify(input.value)}</div>
                </div>
            );

            last_detail = input;
        }

        json_details.push(<div style={{ height: '10px' }} />);
        for (var key in existing_answers) {
            if (existing_answers[key].changed) {
                json_details.push(
                    <div>
                        {'Final Value for '}
                        <span style={{ fontWeight: '600' }}>{key}</span>
                        {`: ${existing_answers[key].last_value}`}
                    </div>
                );
            }
        }

        rows.push(
            <tr>
                <td width="20%">{event.type}</td>
                <td width="20%">{question_text}</td>
                <td width="60%">{json_details}</td>
            </tr>
        );

        opened_questions.push(event.question_id);
    }

    return rows;
}
