import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Alert } from 'library';
import QuestionDisplay from 'pages/questions/question_display.js';
import FeatureDisplay from 'pages/analysts/feature_analysis/feature_display.js';

export default class FindMath extends Component {
    constructor(props) {
        super(props);

        this.state = { feature_list: [], loaded: false, question: {} };

        this.feature_callback = this.feature_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/analysis/analysisfeature/?related=student_question_response&order_by=created_at&student_question_response=' + this.props.student_question_response_id,
            {},
            this.feature_callback
        );

        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            result => {
                let question = result[0]['question'];

                this.setState({
                    question: question,
                });
            }
        );

    }

    feature_callback(data) {
        this.setState({ feature_list: data, loaded: true });
    }


    render() {
        var feature_rows = [];
        for (var feature_item of this.state.feature_list) {
            var feature = feature_item.analysisfeature;
             var row = <tr>
                <td>{feature.level} - {feature.name}</td>
                <td>{feature.string_value}</td>
                <td>{feature.confidence}</td>
                <td><FeatureDisplay feature={feature} /></td>
            </tr>
            feature_rows.push(row);
        }

        var content = (
            <div className="container" style={{ marginTop: '50px' }}>
                <div>
                    <h2 class="col-12">Find All Math</h2>
                    <Alert type="info">
                        <QuestionDisplay
                            question={this.state.question}
                            display="full_text"
                            image
                        />
                    </Alert>

                </div>
                <div style={{ height: '30px' }}></div>
                <table className="table">
                    <tr>
                        <th>Feature Name</th>
                        <th>Value</th>
                        <th>Confidence</th>
                        <th>Student Explanation</th>
                    </tr>
                    {feature_rows}
                </table>
            </div>
        );

        return <Wrapper content={content} loaded={this.state.loaded} />;
    }
}
