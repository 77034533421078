import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDateString, regexCheck } from 'functions';

import {
    Button,
    Select,
    FormWithChildren,
    EmptyModal,
    TextInput,
    Tabs,
    Alert,
    CopyToClipboard,
    DateTimePicker,
    Wrapper,
} from 'library';

class ComponentAssignment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            switch_answer_modal: false,
            has_component: this.props.analysis.has_component,
            automated_scoring_needs_qc_review:
                this.props.analysis.automated_scoring_needs_qc_review,
            automated_scoring_conflict_resolved:
                this.props.analysis.automated_scoring_conflict_resolved,

            review: false,
            anchor: this.props.analysis.anchor,
        };

        this.toggle_switch = this.toggle_switch.bind(this);
        this.switch_component = this.switch_component.bind(this);
        this.switch_autoscored_component =
            this.switch_autoscored_component.bind(this);
        this.toggle_anchor = this.toggle_anchor.bind(this);
        this.complete_review = this.complete_review.bind(this);
    }

    toggle_switch() {
        this.setState({
            switch_answer_modal: !this.state.switch_answer_modal,
        });
    }

    switch_component() {
        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.props.analysis.id}/`,
            { has_component: !this.state.has_component },
            () =>
                this.setState({
                    has_component: !this.state.has_component,
                    switch_answer_modal: false,
                })
        );
    }

    switch_autoscored_component() {
        const data = {
            has_component: !this.state.has_component,
            automated_scoring_conflict_resolved: true,
        };

        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.props.analysis.id}/`,
            data,
            () =>
                this.setState({
                    has_component: !this.state.has_component,
                    automated_scoring_conflict_resolved: true,
                })
        );
    }

    toggle_anchor() {
        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.props.analysis.id}/`,
            { anchor: !this.state.anchor },
            () =>
                this.setState({
                    anchor: !this.state.anchor,
                })
        );
    }

    complete_review() {
        ajaxWrapper(
            'POST',
            `/api/home/responsecomponentanalysis/${this.props.analysis.id}/`,
            { review: false },
            this.props.refresh
        );
    }

    render() {
        const { analysis } = this.props;

        let has_component = 'No';
        let switch_component_to = 'Yes';
        if (this.state.has_component) {
            has_component = 'Yes';
            switch_component_to = 'No';
        }

        let notes = null;
        let review_complete = null;
        if (this.props.review == 'true' || this.props.review == true) {
            notes = <td>{analysis.notes}</td>;
            review_complete = (
                <Button
                    type="danger"
                    text="Reviewed"
                    deleteType
                    onClick={this.complete_review}
                />
            );
        }

        let make_anchor = null;
        if (
            ['Super Admin', 'Manager Analyst'].indexOf(
                window.cmState.user.role
            ) > -1
        ) {
            make_anchor = (
                <Button
                    type="success"
                    text="Make Anchor"
                    onClick={this.toggle_anchor}
                    style={{ marginBottom: '5px' }}
                />
            );
            if (this.state.anchor == true) {
                make_anchor = (
                    <Button
                        type="danger"
                        text="Take Away Anchor"
                        onClick={this.toggle_anchor}
                        style={{ marginBottom: '5px' }}
                    />
                );
            }
        }

        let automated_scoring_conflict_resolution = null;
        if (
            this.state.automated_scoring_needs_qc_review &&
            !this.state.automated_scoring_conflict_resolved
        ) {
            automated_scoring_conflict_resolution = (
                <Button
                    type="danger"
                    text="Flip Autoscore"
                    onClick={this.switch_autoscored_component}
                    style={{ marginBottom: '5px' }}
                />
            );
        }

        const max_length = 1000;
        const answer = analysis.response.answer.substring(0, max_length);

        return (
            <tr>
                <td>
                    {`${analysis.who_scored.first_name} ${analysis.who_scored.last_name[0]}`}
                </td>
                <td>{formatDateString(analysis.updated_at, 'mm/dd/yyyy')}</td>
                <td>{analysis.response.non_explanation_answer}</td>
                <td style={{ wordBreak: 'break-all' }}>
                    <a
                        href={`/student_response/${analysis.response.id}/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <CopyToClipboard hover text={answer}>
                            {answer}
                        </CopyToClipboard>
                    </a>
                </td>

                <td>
                    <a
                        onClick={this.toggle_switch}
                        style={{ color: '#007bff', pointer: 'cursor' }}
                    >
                        {has_component}
                    </a>

                    <EmptyModal
                        onHide={this.toggle_switch}
                        show={this.state.switch_answer_modal}
                    >
                        <div style={{ padding: '20px' }}>
                            <p>Change Answer to: {switch_component_to}?</p>
                            <Button
                                type="danger"
                                text="Switch"
                                onClick={this.switch_component}
                            />
                        </div>
                    </EmptyModal>
                </td>

                <td>
                    {make_anchor}
                    {automated_scoring_conflict_resolution}
                    {review_complete}
                </td>

                {notes}
            </tr>
        );
    }
}

class LatestResponses extends Component {
    constructor(props) {
        super(props);

        let starting_tab = 'Analysts';
        let start_with_review = 'false';
        if (window.cmState.user.role == 'Analyst') {
            starting_tab = 'Responses';
        }

        if (this.props.review_flagged) {
            starting_tab = 'Responses';
            start_with_review = 'true';
        }

        this.state = {
            loaded: false,
            loading_responses: false,

            analysts: [],
            assessment_options: [],
            response_component_analysis: [],

            filters: {
                analyst: null,
                regex: '',
                regex_exclude: '',
                review: start_with_review,
                has_component: '',
                automated_scoring_needs_qc_review: false,
            },

            limit: 50,
            offset: 0,

            initial_tab: starting_tab,
            tab: starting_tab,
        };

        this.component_callback = this.component_callback.bind(this);
        this.refresh_responses = this.refresh_responses.bind(this);
        this.response_callback = this.response_callback.bind(this);
        this.response_count_callback = this.response_count_callback.bind(this);
        this.analyst_callback = this.analyst_callback.bind(this);
        this.assessment_callback = this.assessment_callback.bind(this);

        this.remove_component_assignments =
            this.remove_component_assignments.bind(this);
        this.remove_automated_scoring_needs_qc_review =
            this.remove_automated_scoring_needs_qc_review.bind(this);
        this.remove_component_assignments_callback =
            this.remove_component_assignments_callback.bind(this);

        this.setGlobalState = this.setGlobalState.bind(this);
        this.change_tab = this.change_tab.bind(this);

        this.next_analyses = this.next_analyses.bind(this);
        this.prev_analyses = this.prev_analyses.bind(this);
    }

    componentDidMount() {
        if (
            window.cmState.user.role == 'Super Admin' ||
            window.cmState.user.role == 'Manager Analyst'
        ) {
            ajaxWrapper(
                'GET',
                `/analyst_on_component/?component_id=${this.props.component_id}`,
                {},
                this.analyst_callback
            );
        }
        ajaxWrapper(
            'GET',
            `/api/home/approachcomponent/${this.props.component_id}/?related=approach,approach__question`,
            {},
            this.component_callback
        );

        ajaxWrapper(
            'GET',
            `/api/home/assessment/?questions_on_assessments__question=${this.props.question_id}`,
            {},
            this.assessment_callback
        );

        this.refresh_responses();
    }

    setGlobalState(name, state) {
        const { filters } = this.state;
        for (const key in state) {
            filters[key] = state[key];

            if (key == 'has_component') {
                if (state[key] == 'false') {
                    filters[key] = false;
                }
                if (state[key] == 'true') {
                    filters[key] = true;
                }
            }
        }
        this.setState({ filters }, this.refresh_responses);
    }

    component_callback(result) {
        this.setState({
            component: result[0].approachcomponent,
            loaded: true,
        });
    }

    analyst_callback(result) {
        this.setState({
            analysts: result,
        });
    }

    assessment_callback(result) {
        const assessments = [];
        for (const item of result) {
            assessments.push({
                text: item.assessment.name,
                value: item.assessment.id,
            });
        }

        this.setState({
            assessment_options: assessments,
        });
    }

    refresh_responses() {
        this.setState({
            loading_responses: true,
        });

        const [url, data] = this.url_from_filters();
        if (!url){
            return false;
        }

        const count_url = `${url}&count`;

        ajaxWrapper('GET', count_url, {}, this.response_count_callback);
        ajaxWrapper('GET', url, {}, this.response_callback);
    }

    url_from_filters() {
        let url = `/api/home/responsecomponentanalysis/?component=${this.props.component_id}&response__active=true&limit=${this.state.limit}&order_by=-updated_at&related=response,who_scored&offset=${this.state.offset}`;
        const data = {
            component: this.props.component_id,
        };

        url += '&automated_scoring_conflict_resolved=false';

        if (this.state.filters.analyst) {
            data.who_scored = this.state.filters.analyst;
            url += `&who_scored=${this.state.filters.analyst}`;
        } else if (window.cmState.user.role == 'Analyst') {
            data.who_scored = window.cmState.user.id;
            url += `&who_scored=${window.cmState.user.id}`;
        }

        if (this.state.filters.has_component !== '') {
            data.has_component = this.state.filters.has_component;
            url += `&has_component=${this.state.filters.has_component}`;
        }

        if (this.state.filters.assessment) {
            data.response__student_assessment__assessment =
                this.state.filters.assessment;
            url += `&response__student_assessment__assessment=${this.state.filters.assessment}`;
        }
        if (this.state.filters.start_date) {
            data.updated_at__gt = this.state.filters.start_date;
            url += `&updated_at__gt=${this.state.filters.start_date}`;
        }
        if (this.state.filters.end_date) {
            data.updated_at__lt = this.state.filters.end_date;
            url += `&updated_at__lt=${this.state.filters.end_date}`;
        }

        if (this.state.filters.automated_scoring_needs_qc_review) {
            data.automated_scoring_needs_qc_review =
                this.state.filters.automated_scoring_needs_qc_review;
            url += `&automated_scoring_needs_qc_review=${this.state.filters.automated_scoring_needs_qc_review}`;
        }

        if (this.state.filters.regex) {
            let regex_text = this.state.filters.regex;
            regex_text = regex_text.split(' ').join('.*');
            if (!regexCheck(regex_text)) {
                this.setState({
                    regex_error: true,
                });
                return [null, null];
            }
            this.setState({
                regex_error: false,
            });

            data.response__answer__iregex = regex_text;
            url += `&response__answer__iregex=${regex_text}`;
        }

        if (this.state.filters.regex_exclude) {
            let regex_exclude_text = this.state.filters.regex_exclude;
            regex_exclude_text = regex_exclude_text.split(' ').join('.*');
            if (!regexCheck(regex_exclude_text)) {
                this.setState({
                    regex_error: true,
                });
                return [null, null];
            }
            this.setState({
                regex_error: false,
            });

            data.exclude__response__answer__iregex = regex_exclude_text;
            url += `&exclude__response__answer__iregex=${regex_exclude_text}`;
        }

        if (
            this.state.filters.review == 'true' ||
            this.state.filters.review == true
        ) {
            data.review = true;
            url += '&review=true';
        } else if (
            this.state.filters.review == 'false' ||
            this.state.filters.review == false
        ) {
            data.review = false;
            url += '&review=false';
        }

        for (const key in data) {
            if (data[key] == 'true') {
                data[key] = true;
            } else if (data.key == 'false') {
                data[key] = false;
            }
        }

        return [url, data];
    }

    response_callback(result) {
        if ('error' in result) {
            this.setState({ error: result.error });
            return false;
        }
        this.setState({ error: null });

        const response_component_analysis = [];
        for (const index in result) {
            const response = result[index].responsecomponentanalysis;
            response_component_analysis.push(response);
        }

        this.setState({
            response_component_analysis,
            loading_responses: false,
        });
    }

    response_count_callback(result) {
        this.setState({
            total: result.count,
        });
    }

    remove_component_assignments(event, callback) {
        const total_data = {};

        this.setState({
            loading_responses: true,
        });

        const [url, data] = this.url_from_filters();
        if (!url){
            return false;
        }

        total_data.total = this.state.total;
        total_data.filters = data;

        const remove_url = '/remove_component_assignments/';

        this.setState(
            {
                remove_error: null,
                remove_callback: callback,
            },
            ajaxWrapper(
                'POST',
                remove_url,
                total_data,
                this.remove_component_assignments_callback
            )
        );
    }

    remove_automated_scoring_needs_qc_review(event, callback) {
        const total_data = {};

        this.setState({
            loading_responses: true,
        });

        const data = {
            ids: [],
        };
        for (const item of this.state.response_component_analysis) {
            data.ids.push(item.id);
        }

        const remove_url = '/remove_automated_scoring_needs_qc_review/';

        this.setState(
            {
                remove_error: null,
                remove_callback: callback,
            },
            ajaxWrapper(
                'POST',
                remove_url,
                data,
                this.remove_component_assignments_callback
            )
        );
    }

    remove_component_assignments_callback(result) {
        console.log(result);
        if ('error' in result) {
            this.setState({
                remove_error: result.error,
                loading_responses: false,
            });
            return false;
        }

        this.state.remove_callback();
        this.refresh_responses();
    }

    change_tab(name) {
        this.setState({ tab: name });
    }

    next_analyses() {
        this.setState(
            {
                offset: this.state.offset + this.state.limit,
            },
            this.refresh_responses
        );
    }

    prev_analyses() {
        this.setState(
            {
                offset: this.state.offset - this.state.limit,
            },
            this.refresh_responses
        );
    }

    render() {
        const analysts = [];
        const analyst_options = [];
        const analyst_info = [];
        for (var i = 0; i < this.state.analysts.length; i++) {
            const analyst = this.state.analysts[i];

            analyst_options.push({
                text: `${analyst.first_name} ${analyst.last_name}`,
                value: analyst.id,
            });

            analyst_info.push(
                <tr>
                    <td>{`${analyst.first_name} ${analyst.last_name}`}</td>
                    <td>{formatDateString(analyst.last_qc, 'mm/dd/yyyy')}</td>
                    <td>{analyst.total_since_last_qc}</td>
                    <td>
                        <a href={`/daily_qc_list/${analyst.id}/`}>
                            {analyst.average_qc}%
                        </a>
                    </td>
                    <td>
                        <a href={`/daily_qc_list/${analyst.id}/`}>
                            {analyst.resolved_pct}%
                        </a>
                    </td>
                    <td>
                        <Button
                            type="primary"
                            text="Start QC"
                            href={`/daily_qc/${analyst.id}/${this.props.question_id}/?component=${this.props.component_id}`}
                            target="_blank"
                        />
                    </td>
                </tr>
            );
        }

        const analyses = [];
        for (
            var i = 0;
            i < this.state.response_component_analysis.length;
            i++
        ) {
            const analysis = this.state.response_component_analysis[i];
            if (!analysis.response) {
                continue;
            }
            analyses.push(
                <ComponentAssignment
                    key={analysis.id}
                    analysis={analysis}
                    review={this.state.filters.review}
                    refresh={this.refresh_responses}
                    automated_scoring_qc={
                        this.state.filters.automated_scoring_needs_qc_review
                    }
                />
            );
        }

        const headers = [
            <th>Analyst</th>,
            <th>Date</th>,
            <th>Answer</th>,
            <th>Student Response</th>,
            <th>Has Component?</th>,
            <th>Custom Actions</th>,
        ];
        if (
            this.state.filters.review == 'true' ||
            this.state.filters.review == true
        ) {
            headers.push(<th>Notes</th>);
        }

        const analyst_table = null;
        var filters = (
            <FormWithChildren
                row
                setGlobalState={this.setGlobalState}
                globalStateName="filter"
                autoSetGlobalState
                defaults={this.state.filters}
            >
                <Select
                    className="col-4"
                    label="Analyst"
                    options={analyst_options}
                    name="analyst"
                />
                <Select
                    className="col-4"
                    label="Saved For Review?"
                    name="review"
                    boolean
                />
                <Select
                    className="col-4"
                    label="Has Component?"
                    name="has_component"
                    options={[
                        { text: 'Either', value: '' },
                        { text: 'Yes', value: 'true' },
                        { text: 'No', value: 'false' },
                    ]}
                />
                <TextInput
                    className="col-6"
                    label="Search Response Text"
                    name="regex"
                />
                <TextInput
                    className="col-6"
                    label="Exclude Response Text"
                    name="regex_exclude"
                />

                <Select
                    className="col-4"
                    label="Assessment"
                    options={this.state.assessment_options}
                    name="assessment"
                />
                <DateTimePicker
                    className="col-4"
                    display_time={false}
                    label="Start Date"
                    name="start_date"
                />
                <DateTimePicker
                    className="col-4"
                    display_time={false}
                    label="End Date"
                    name="end_date"
                />

                <Select
                    className="col-4"
                    boolean
                    label="Show Automated QC"
                    name="automated_scoring_needs_qc_review"
                />
            </FormWithChildren>
        );

        if (window.cmState.user.role == 'Analyst') {
            var filters = (
                <FormWithChildren
                    row
                    setGlobalState={this.setGlobalState}
                    globalStateName="filter"
                    autoSetGlobalState
                    defaults={this.state.filters}
                >
                    <TextInput
                        className="col-3"
                        label="Search Response Text"
                        name="regex"
                    />
                    <TextInput
                        className="col-3"
                        label="Exclude Response Text"
                        name="regex_exclude"
                    />
                    <Select
                        className="col-3"
                        label="Has Component?"
                        name="has_component"
                        options={[
                            { text: 'Either', value: '' },
                            { text: 'Yes', value: 'true' },
                            { text: 'No', value: 'false' },
                        ]}
                    />
                    <Select
                        className="col-3"
                        label="Saved For Review?"
                        name="review"
                        boolean
                    />
                </FormWithChildren>
            );
        }

        var previous_button = null;
        if (this.state.offset > 0) {
            var previous_button = (
                <Button
                    type="warning"
                    text="Prev"
                    onClick={this.prev_analyses}
                />
            );
        }
        let next_button = null;
        if (this.state.response_component_analysis.length == this.state.limit) {
            next_button = (
                <Button
                    type="primary"
                    text="Next"
                    onClick={this.next_analyses}
                    style={{ position: 'relative', right: 0 }}
                />
            );
        }

        let clear_analysis = null;
        if (window.cmState.user.role == 'Super Admin') {
            clear_analysis = (
                <Alert type="warning">
                    {`Your filters match with ${this.state.total} responses.`}
                    <Button
                        style={{ marginLeft: '10px' }}
                        type="danger"
                        deleteType
                        text="Remove Component Assignments"
                        onClick={this.remove_component_assignments}
                    />
                </Alert>
            );
        }

        let remove_automated_scoring_needs_qc_review = null;
        if (this.state.filters.automated_scoring_needs_qc_review == true) {
            remove_automated_scoring_needs_qc_review = (
                <Alert type="warning">
                    <Button
                        style={{ marginLeft: '10px' }}
                        type="danger"
                        deleteType
                        text={
                            'Remove "Automated Scoring Needs Review" from visible assignments'
                        }
                        onClick={this.remove_automated_scoring_needs_qc_review}
                    />
                </Alert>
            );
        }

        const analysis_table = (
            <table className="table" style={{ tableLayout: 'fixed' }}>
                <tr>{headers}</tr>
                {analyses}
                <tr>
                    <td>{previous_button}</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{next_button}</td>
                </tr>
            </table>
        );

        let regex_error = null;
        if (this.state.regex_error) {
            regex_error = (
                <Alert
                    text="Your Regex seems to be incomplete.  Please continue typing to see responses."
                    type="info"
                />
            );
        }

        let remove_error = null;
        if (this.state.remove_error) {
            remove_error = (
                <Alert text={this.state.remove_error} type="danger" />
            );
        }

        let error = null;
        if (this.state.error) {
            error = <Alert text={this.state.error} type="warning" />;
        }

        const responses_tab = (
            <div style={{ padding: '10px 0px' }}>
                {filters}
                {clear_analysis}
                {remove_automated_scoring_needs_qc_review}
                {remove_error}

                {regex_error}
                <Wrapper
                    style={{}}
                    loaded={!this.state.loading_responses}
                    content={analysis_table}
                />
            </div>
        );

        const analysts_tab = (
            <div style={{ padding: '10px 0px' }}>
                <table className="table" style={{ tableLayout: 'fixed' }}>
                    <tr>
                        <th>Analyst Name</th>
                        <th>Last QC</th>
                        <th>Analyzed Since Last QC</th>
                        <th>Average QC %</th>
                        <th>Resolved %</th>
                        <th>Run New QC</th>
                    </tr>
                    {analyst_info}
                </table>
            </div>
        );

        let display_content = null;
        if (window.cmState.user.role != 'Analyst') {
            display_content = (
                <Tabs
                    initial_tab={this.state.initial_tab}
                    tab_names={['Analysts', 'Responses']}
                    tab_contents={{
                        Analysts: analysts_tab,
                        Responses: responses_tab,
                    }}
                    change_tab={this.change_tab}
                />
            );
        } else {
            display_content = responses_tab;
        }

        let content = null;
        if (this.state.loaded) {
            content = (
                <div>
                    <div className="container">
                        <h2>
                            {
                                this.state.component.approach.question
                                    .name
                            }
                        </h2>
                        <h4>
                            {`Approach: ${this.state.component.approach.name}: ${this.state.component.approach.text}`}
                        </h4>
                        <h4>
                            {`Component: ${this.state.component.type}: ${this.state.component.name}: ${this.state.component.text}`}
                        </h4>
                        {error}

                        {display_content}
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default LatestResponses;
