export default function lookup_object(id) {
    const global_lookup = window.cmState.reporting_context_lookup;

    let object = null;
    if (id in global_lookup && global_lookup[id]) {
        object = global_lookup[id];
    } else {
        console.log('Global Lookup Error!!', id);
        object = {};
    }

    return object;
}
