import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Container, Button, Alert, Tabs, Wrapper } from 'library';

import AssessmentSettings from './settings.js';
import AssessmentQuestions from './questions_on_assessment.js';

const FILTER_LISTS = {
    ell_status: [
        '',
        'English Learner',
        'English Only',
        'Initially Fluent English Proficient (I-FEP)',
        'Redesignated Fluent English Proficient',
        'To Be Determined',
        'Adult English Learner',
        'LEP',
        'IFEP',
        'TBD',
    ],
    grade: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    race: [
        '',
        'American Indian',
        'American Indian or Alaska Native',
        'Asian',
        'Black or African American',
        'Caucasian',
        'Filipino',
        'Hawaiian or Other Pacific Islander',
        'Hispanic',
        'Native Hawaiian or Other Pacific Islander',
        'PacIsl/Hawaiian',
        'Two OR More',
        'Two or More Races',
        'Unknown',
        'White',
    ],
    special_ed: [true, false],
};

class AssessmentBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,

            assessment: {
                questions: [],
                assessment: {},
            },
            all_questions: [],
            questions_on_assessment: [],
            filter_lists: FILTER_LISTS,

            refresh_assessment_callback: null,
            full_refresh_assessment_callback: null,

            report_alerts: [],
            duplicate: false,
            form_key: 'form_key',

            studentassessment_count: 0,
        };

        this.full_refresh_assessment = this.full_refresh_assessment.bind(this);
        this.refresh_assessment = this.refresh_assessment.bind(this);

        this.get_questions_callback = this.get_questions_callback.bind(this);
        this.get_assessment_callback = this.get_assessment_callback.bind(this);
    }

    go_to_proctor_page = () => {
        window.location.href = `/proctor/${this.state.assessment.district_id}/${this.props.assessment_id}`;
    };

    go_to_printable_page = () => {
        window.location.href = `/printable_assessment/${this.state.assessment.district_id}/${this.props.assessment_id}`;
    };


    componentDidMount() {
        ajaxWrapper(
            'POST',
            '/questions_by_filtered_score/',
            {},
            this.get_questions_callback
        );

        if (!this.props.assessment_id) {
            this.setState({ loaded: true });
        } else {
            this.full_refresh_assessment();
        }

        if (this.props.assessment_id) {
            ajaxWrapper(
                'GET',
                '/api/home/studentassessment/?assessment=' +
                this.props.assessment_id +
                '&count',
                {},
                value => this.setState({ studentassessment_count: value['count'] })
            );
        }
    }

    full_refresh_assessment(callback) {
        this.refresh_assessment(callback)
    }

    refresh_assessment(callback) {
        var assessment_related =
            'questions_on_assessments,questions_on_assessments__question,questions_on_assessments__question__category&related__questions_on_assessments__order_by=order';

        if (typeof callback === 'function') {
            this.setState(
                {
                    refresh_assessment_callback: callback,
                },
                ajaxWrapper(
                    'GET',
                    `/api/home/assessment/${this.props.assessment_id}/?related=${assessment_related}`,
                    {},
                    this.get_assessment_callback
                )
            );
        } else {
            ajaxWrapper(
                'GET',
                `/api/home/assessment/${this.props.assessment_id}/?related=${assessment_related}`,
                {},
                this.get_assessment_callback
            );
        }
    }

    get_questions_callback(result) {
        this.setState({ all_questions: result.question_results });
    }

    get_assessment_callback(result) {
        const { assessment } = result[0];

        const questions_on_assessment = [];
        if (assessment && 'questions_on_assessments' in assessment) {
            for (
                let index = 0;
                index < assessment.questions_on_assessments.length;
                index++
            ) {
                questions_on_assessment.push(
                    assessment.questions_on_assessments[index]
                        .questiononassessment
                );
            }
        }

        if (this.state.refresh_assessment_callback) {
            this.state.refresh_assessment_callback();
        }

        this.setState(
            {
                loaded: true,
                assessment,
                questions_on_assessment,
                refresh_assessment_callback: null,
                loading: false
            }
        );
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        var content = null;

        if (this.state.loaded) {
            let assessment_in_use = null;
            let assessment_in_use_warning = `This assessment had been taken by ${this.state.studentassessment_count} students.  Be very careful about editing!`;
            if (this.state.studentassessment_count > 0) {
                assessment_in_use = (
                    <Alert type="danger">{assessment_in_use_warning}</Alert>
                );
            }

            const tab_names = ['Settings', 'Questions'];

            const tab_contents = {
                Settings: (
                    <AssessmentSettings
                        setDuplica={id => {
                            this.setState({
                                duplicate: true,
                                form_key: 'duplicate_form_key',
                                assessment: {
                                    ...this.state.assessment,
                                    id: null,
                                    name: `(Duplicate) ${this.state.assessment.name}`,
                                    published: false,
                                    completed: false,
                                },
                            });
                            window.scrollTo(0, 0);
                        }}
                        isDuplicated={this.state.duplicate}
                        assessment={this.state.assessment}
                        form_key={this.state.form_key}

                        protect_form={this.state.studentassessment_count}
                        protect_form_text={assessment_in_use_warning}
                    />
                ),
                Questions: (
                    <AssessmentQuestions
                        assessment={this.state.assessment}
                        all_questions={this.state.all_questions}
                        filter_lists={this.state.filter_lists}
                        questions_on_assessment={
                            this.state.questions_on_assessment
                        }
                        refresh_assessment={this.refresh_assessment}
                        full_refresh_assessment={this.full_refresh_assessment}
                    />
                )
            };

            const proctor = (
                <Alert type="success">
                    <div>Links</div>
                    <Button
                        onClick={this.go_to_proctor_page}
                        text="Proctor"
                        type="success"
                    />
                    <Button
                        onClick={this.go_to_printable_page}
                        text="Printable Version"
                        type="success"
                    />
                </Alert>
            );

            const tabs = (
                <Tabs
                    tab_names={tab_names}
                    tab_contents={tab_contents}
                    change_tab={this.change_tab}
                />
            );

            let title = 'Add New Assessment';
            if (this.state.assessment.id) {
                title = `Edit Assessment: ${this.state.assessment.name}`;
            }

            var content = (
                <Container style={{ padding: '0px', marginBottom: '50px' }}>
                    <h2>{title}</h2>
                    {proctor}
                    {assessment_in_use}
                    <br />
                    {tabs}
                </Container>
            );
        }



        return (
            <Wrapper
                loaded={this.state.loaded && !this.state.loading}
                content={content}
            />
        );
    }
}

export default AssessmentBuilder;
