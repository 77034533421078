import React, { Component } from 'react';
import { getChildren } from 'functions';
import { Wrapper } from 'library';

class Sidebar extends Component {
    static component_name = 'Sidebar';

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            height: '0px',
            width: '0px',
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
        window.addEventListener('focus', this.updateDimensions.bind(this));

        if (this.props.start_open) {
            this.setState({ open: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
        window.removeEventListener('focus', this.updateDimensions.bind(this));
    }

    updateDimensions() {
        const that = this;
        setTimeout(() => {
            let height = 'auto';
            let width = 'auto';

            if (that.props.widthPercent) {
                if (that.props.min_width) {
                    if (
                        window.innerWidth * (that.props.widthPercent / 100) <
                        this.props.min_width
                    ) {
                        width = `${this.props.min_width}px`;
                    } else {
                        width = `${String(
                            window.innerWidth * (that.props.widthPercent / 100)
                        )}px`;
                    }
                } else {
                    width = `${String(
                        window.innerWidth * (that.props.widthPercent / 100)
                    )}px`;
                }
            }

            if (that.props.width) {
                width = that.props.width;
            }

            if (that.props.height) {
                height = that.props.height;
                if (this.props.height == '100%') {
                    height = `${String(
                        window.innerHeight - that.props.headerHeight
                    )}px`;
                }
            }

            that.setState({
                height,
                width,
            });
        }, 10);
    }

    toggle() {
        if (this.state.open || this.props.open) {
            this.setState({ open: false });
            if (this.props.toggleOpen) {
                this.props.toggleOpen(false);
            }
        } else {
            this.setState({ open: true });
            if (this.props.toggleOpen) {
                this.props.toggleOpen(true);
            }

            const that = this;
            setTimeout(() => {
                if (
                    that.props.focus_on_open &&
                    that.props.focus_on_open.current
                ) {
                    that.props.focus_on_open.current.focus();
                }
            }, 100);
        }
    }

    render() {
        const position = {
            position: 'fixed',
            top: `${this.props.headerHeight}px`,
            right: '0px',
            width: this.state.width,
            background: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.2) -2px 2px 5px',
            zIndex: '1000',
        };

        let toggleText = this.props.openerText;
        let open = false;
        if (this.props.open || this.state.open) {
            open = true;
            toggleText = 'X Close';
        }

        const openerStyle = {
            position: 'absolute',
            top: this.props.openerPosition,
            right: '0px',
            padding: '10px',
            background: 'white',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            boxShadow: 'rgba(0, 0, 0, 0.3) -4px 2px 5px',
        };

        const container_style = {
            height: this.state.height,
            overflowY: 'scroll',
            paddingRight: '0px',
        };

        if (this.state.height == 'auto') {
            container_style.overflowY = 'hidden';
        }

        if (this.props.show_overflow && open) {
            delete container_style.overflowY;
            container_style.overflow = 'visible';
        }

        const close_button_style = {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            background: 'white',
            zIndex: 10,
        };

        if (this.props.left_side) {
            openerStyle.left = openerStyle.right;
            position.left = position.right;
            delete openerStyle.right;
            delete position.right;

            openerStyle.borderTopRightRadius = '10px';
            openerStyle.borderBottomRightRadius = '10px';
            delete openerStyle.borderTopLeftRadius;
            delete openerStyle.borderBottomLeftRadius;

            close_button_style.textAlign = 'right';

            container_style.paddingLeft = '0px';
            delete container_style.paddingRight;
        }

        const children = getChildren(this.props);
        const content = [];
        children.forEach(child => {
            const new_child = React.cloneElement(child, { open });
            content.push(new_child);
        });

        let opener = null;
        let close_button = null;

        if (!this.props.no_opener) {
            container_style.paddingTop = '60px';
            container_style.paddingBottom = '10px';

            if (this.props.opener && !open) {
                // Custom Opener JSX
                openerStyle.padding = '0px';
                opener = (
                    <div style={openerStyle}>
                        <button
                            className="btn btn-link"
                            style={{ color: 'black' }}
                            onClick={this.toggle.bind(this)}
                            type="button"
                        >
                            {this.props.opener}
                        </button>
                    </div>
                );
            } else if (open) {
                // Close option
                close_button = (
                    <div style={close_button_style}>
                        <button
                            className="btn btn-outline-danger"
                            onClick={this.toggle.bind(this)}
                            style={{ margin: '10px 20px', border: '1px solid' }}
                            type="button"
                        >
                            {toggleText}
                        </button>
                    </div>
                );
                // content = this.props.children;
            } else {
                // Standard opener
                opener = (
                    <div style={openerStyle}>
                        <button
                            className="btn btn-info"
                            onClick={this.toggle.bind(this)}
                            style={{ minWidth: '0px' }}
                            type="button"
                        >
                            {toggleText}
                        </button>
                    </div>
                );
            }
        }

        if (!open) {
            container_style.height = '0px';
            container_style.width = '0px';
            container_style.paddingTop = '0px';
            container_style.paddingBottom = '0px';
        }
        const sidebar_inner_content = (
            <div className="container" style={container_style}>
                <Wrapper
                    content={content}
                    loaded={this.props.loaded}
                    style={{ marginTop: '0px' }}
                />
            </div>
        );

        return (
            <div style={position}>
                {opener}
                {close_button}
                {sidebar_inner_content}
            </div>
        );
    }
}

export default Sidebar;
