import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    Select,
    Wrapper,
} from 'library';

class EditAnalyst extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            user: {},
        };

        this.get_user = this.get_user.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/user/user/${this.props.user}/?related=managees`,
            {},
            this.get_user
        );
    }

    get_user(result) {
        const { user } = result[0];
        if (user.calibrated_to_answers_without_wot) {
            user.calibrated_to_answers_without_wot = 'true';
        } else {
            user.calibrated_to_answers_without_wot = 'false';
        }

        user['managees[]'] = [];
        for (const index in user.managees) {
            user['managees[]'].push(user.managees[index].user.id);
        }

        this.setState({ user, loaded: true });
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        var content = null;
        const modal_display = this.state.modal;

        if (this.state.loaded) {
            const title =
                `Edit User: ${this.state.user.first_name
                } ${this.state.user.last_name}`;
            const submitUrl = `/api/user/user/${this.state.user.id}/`;
            const deleteUrl = `${submitUrl}delete/`;

            var content = (
                <div className="container">
                    <h2>{title}</h2>
                    <FormWithChildren
                        submitUrl={submitUrl}
                        deleteUrl={deleteUrl}
                        redirectUrl="/analyst_overview/"
                        defaults={this.state.user}
                        dont_resolve_anything
                    >
                        <Select
                            options={[
                                { text: 'Analyst', value: 'Analyst' },
                                {
                                    text: 'Manager Analyst',
                                    value: 'Manager Analyst',
                                },
                                { text: 'Super Admin', value: 'Super Admin' },
                            ]}
                            name="role"
                            label="Role"
                            default={this.state.user.role}
                        />
                        <Select
                            options={[
                                { text: 'Yes', value: 'true' },
                                { text: 'No', value: 'false' },
                            ]}
                            name="calibrated_to_answers_without_wot"
                            label="Categorize Items Without Ways Of Thinking"
                            default={
                                this.state.user
                                    .calibrated_to_answers_without_wot
                            }
                        />
                        <Select
                            multiple
                            optionsUrl="/api/user/user/?order_by=id&role__in=Analyst,Manager Analyst,Super Admin"
                            optionsUrlMap={{
                                text: '{user.first_name} {user.last_name}',
                                value: '{user.id}',
                            }}
                            name="managees[]"
                            label="Analysts Managed By User"
                            default={this.state.user['managees[]']}
                        />
                    </FormWithChildren>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default EditAnalyst;
