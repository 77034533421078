export default function intersect(a, b) {
    let t;

    // indexOf to loop over shorter
    if (b.length > a.length) {
        t = b;
        b = a;
        a = t;
    }

    return a.filter(e => b.indexOf(e) > -1);
}
