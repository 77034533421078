import React from 'react';
import { Button, Icon } from 'library';

class CopyToClipboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_hovering: false,
        };

        this.myRef = React.createRef();
        this.copy_to_clipboard = this.copy_to_clipboard.bind(this);
    }

    copy_to_clipboard() {
        /* Select the text field */
        this.myRef.current.select();
        /* Copy the text inside the text field */
        document.execCommand('copy');
    }

    render() {
        const input_style = {
            background: '#eee',
            maxWidth: '400px',
            display: 'inline-block',
        };

        let content = (
            <p style={this.props.style || {}}>
                <input
                    style={input_style}
                    className="form-control"
                    type="text"
                    ref={this.myRef}
                    value={this.props.text}
                />
                <Button
                    style={{ marginLeft: '5px' }}
                    text="Copy"
                    onClick={this.copy_to_clipboard}
                    type="success"
                />
            </p>
        );

        if (this.props.hover) {
            let hover_button = null;
            if (this.state.is_hovering) {
                hover_button = (
                    <div
                        style={{
                            position: 'absolute',
                            right: '0px',
                            bottom: '0px',
                            zIndex: 10,
                            background: 'white',
                        }}
                    >
                        <Button
                            style={{
                                width: '20px',
                                minWidth: '20px',
                                height: '20px',
                            }}
                            text={<Icon icon="copy" size={1} />}
                            onClick={this.copy_to_clipboard}
                            type="outline-success"
                        />
                    </div>
                );
            }

            content = (
                <div
                    style={{ position: 'relative' }}
                    onMouseEnter={() => this.setState({ is_hovering: true })}
                    onMouseLeave={() => this.setState({ is_hovering: false })}
                >
                    {this.props.children}
                    {hover_button}
                    <input
                        style={{
                            width: '10px',
                            height: '10px',
                            opacity: '0',
                            position: 'absolute',
                            right: '0px',
                            bottom: '0px',
                            zIndex: 1,
                        }}
                        type="text"
                        ref={this.myRef}
                        value={this.props.text}
                    />
                </div>
            );
        }

        return content;
    }
}

export default CopyToClipboard;
