import React, { Component } from 'react';
import { resolveVariables, ajaxWrapper, resolveAllChildren } from 'functions';
import { Wrapper } from 'library';

class Instance extends Component {
    static component_name = 'Instance';

    constructor(props) {
        super(props);

        this.state = {
            componentData: null,
            loaded: false,
        };

        this.ajaxCallback = this.ajaxCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.no_object_found = this.no_object_found.bind(this);
    }

    componentDidMount() {
        // Subscribe to all variables in dataUrl
        window.cmState.subscribe(this, this.props.dataUrl);

        this.refreshData();
    }

    refreshData() {
        const { dataUrl } = resolveVariables(
            { dataUrl: this.props.dataUrl },
            window.cmState.getGlobalState()
        );
        ajaxWrapper(
            'GET',
            dataUrl,
            {},
            this.ajaxCallback,
            this.no_object_found
        );
    }

    ajaxCallback(result) {
        let componentData = {};
        if (
            result[0] &&
            this.props.objectName &&
            this.props.objectName in result[0]
        ) {
            componentData = result[0][this.props.objectName];
        }

        if (this.props.dataMapping) {
            componentData = resolveVariables(
                this.props.dataMapping,
                componentData
            );
        }

        this.setState({ componentData, loaded: true });
    }

    no_object_found() {
        const componentData = {};
        this.setState({ componentData, loaded: true });
    }

    render() {
        let content = [];

        if (this.state.loaded) {
            const data = this.state.componentData;
            data.refreshData = this.refreshData;
            data.setGlobalState = this.props.setGlobalState;
            content = resolveAllChildren(this, data);
        }

        return (
            <Wrapper
                className={this.props.className || this.props.class}
                loaded={this.state.loaded}
                content={content}
            />
        );
    }
}

export default Instance;
