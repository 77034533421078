import React, { Component } from 'react';

import { Button, PageBreak } from 'library';

class Step extends Component {
    constructor(props) {
        super(props);
        this.state = { opacity: 0 };
    }

    componentDidMount() {
        setTimeout(() => this.setState({ opacity: 1 }), 1000);
    }

    render() {
        return (
            <p
                className="fade-in"
                style={{
                    fontFamily: 'Lato',
                    fontSize: '50px',
                    marginTop: '10px',
                    opacity: this.state.opacity,
                }}
            >
                {this.props.step}
            </p>
        );
    }
}

export default class Game extends Component {
    constructor(props) {
        super(props);

        this.state = {
            original_expression_one: {
                expression: {
                    expression: {
                        expression: 'x',
                        operand: 'multiply',
                        value: 10,
                    },
                    operand: 'add',
                    value: 4,
                },
                operand: 'divide',
                value: 5,
            },
            original_expression_two: {
                expression: 20,
                operand: null,
                value: null,
            },
            expression_one: {
                expression: {
                    expression: {
                        expression: 'x',
                        operand: 'multiply',
                        value: 10,
                    },
                    operand: 'add',
                    value: 4,
                },
                operand: 'divide',
                value: 5,
            },
            expression_two: { expression: 20, operand: null, value: null },
            steps: [],
            complete: false,
            options_available: true,
        };

        this.get_expression = this.get_expression.bind(this);
        this.choose_operation = this.choose_operation.bind(this);
        this.update_expressions = this.update_expressions.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);

        this.state.steps = [
            <span>
                {this.get_expression(this.state.original_expression_one)} ={' '}
                {this.get_expression(this.state.original_expression_two)}
            </span>,
        ];
    }

    messagesEndRef = React.createRef();

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    get_expression(expression_dict) {
        if (typeof expression_dict === 'string') {
            return expression_dict;
        } if (typeof expression_dict === 'number') {
            return expression_dict;
        }

        console.log('Expression Dict', expression_dict);
        let expression = this.get_expression(expression_dict.expression);

        if (expression == 'x') {
            if (expression_dict.operand == 'multiply') {
                expression = `${expression_dict.value  }x`;
            }
            if (expression_dict.operand == 'divide') {
                expression = `${expression  }/${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'add') {
                expression = `${expression  } + ${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'subtract') {
                expression = `${expression  } - ${  expression_dict.value}`;
            }
        } else if (typeof expression === 'string') {
            if (expression_dict.operand == 'multiply') {
                expression = `${expression_dict.value  }(${  expression  })`;
            }
            if (expression_dict.operand == 'divide') {
                expression =
                    `(${  expression  })` + `/${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'add') {
                expression = `${expression  } + ${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'subtract') {
                expression = `${expression  } - ${  expression_dict.value}`;
            }
        } else if (typeof expression === 'number') {
            console.log('Here!', expression);
            if (expression_dict.operand == 'multiply') {
                expression = `${expression  } x ${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'divide') {
                expression = `${expression  } / ${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'add') {
                expression = `${expression  } + ${  expression_dict.value}`;
            }
            if (expression_dict.operand == 'subtract') {
                expression = `${expression  } - ${  expression_dict.value}`;
            }
        }

        return expression;
    }

    operate(expression, operand, value) {
        if (typeof expression.expression === 'number') {
            if (operand == 'multiply') {
                expression.expression *= value;
            }
            if (operand == 'divide') {
                expression.expression /= value;
            }
            if (operand == 'add') {
                expression.expression += value;
            }
            if (operand == 'subtract') {
                expression.expression -= value;
            }
        } else if (operand == 'multiply' && expression.operand == 'divide') {
            var new_divisor = expression.value / value;
            if (new_divisor == 1) {
                expression = expression.expression;
            } else {
                expression.value = new_divisor;
            }
        } else if (
            operand == 'multiply' &&
            expression.operand == 'multiply'
        ) {
            var new_multiplier = expression.value * value;
            if (new_multiplier == 1) {
                expression = expression.expression;
            } else {
                expression.value = new_multiplier;
            }
        } else if (operand == 'divide' && expression.operand == 'multiply') {
            var new_multiplier = expression.value / value;
            if (new_multiplier == 1) {
                expression = expression.expression;
            } else {
                expression.value = new_multiplier;
            }
        } else if (operand == 'divide' && expression.operand == 'divide') {
            var new_divisor = expression.value * value;
            if (new_multiplier == 1) {
                expression = expression.expression;
            } else {
                expression.value = new_divisor;
            }
        } else if (operand == 'add' && expression.operand == 'add') {
            var new_addend = expression.value + value;
            if (new_addend == 0) {
                expression = expression.expression;
            } else {
                expression.value = new_addend;
            }
        } else if (operand == 'add' && expression.operand == 'subtract') {
            var new_subtractor = expression.value - value;
            if (new_subtractor == 0) {
                expression = expression.expression;
            } else {
                expression.value = new_subtractor;
            }
        } else if (operand == 'subtract' && expression.operand == 'add') {
            var new_addend = expression.value - value;
            if (new_addend == 0) {
                expression = expression.expression;
            } else {
                expression.value = new_addend;
            }
        } else if (
            operand == 'subtract' &&
            expression.operand == 'subtract'
        ) {
            var new_subtractor = expression.value + value;
            if (new_subtractor == 0) {
                expression = expression.expression;
            } else {
                expression.value = new_subtractor;
            }
        } else {
            expression = {
                expression,
                operand,
                value,
            };
        }

        return expression;
    }

    choose_operation(operation, value) {
        let operand_display = null;
        if (operation == 'multiply') {
            operand_display = <span>Multiply Both Sides By {value}</span>;
        }
        if (operation == 'divide') {
            operand_display = <span>Divide Both Sides By {value}</span>;
        }
        if (operation == 'add') {
            operand_display = <span>Add {value} To Both Sides</span>;
        }
        if (operation == 'subtract') {
            operand_display = <span>Subtract {value} From Both Sides</span>;
        }

        const {steps} = this.state;
        steps.push(operand_display);

        const temp_expression_one = {
            expression: this.state.expression_one,
            operand: operation,
            value,
        };
        const temp_expression_two = {
            expression: this.state.expression_two,
            operand: operation,
            value,
        };
        steps.push(
            <span>
                {this.get_expression(temp_expression_one)} ={' '}
                {this.get_expression(temp_expression_two)}
            </span>
        );

        const new_expression_one = this.operate(
            this.state.expression_one,
            operation,
            value
        );
        const new_expression_two = this.operate(
            this.state.expression_two,
            operation,
            value
        );

        this.setState({ steps, options_available: false }, () =>
            this.update_expressions(new_expression_one, new_expression_two)
        );
    }

    update_expressions(new_expression_one, new_expression_two) {
        const {steps} = this.state;
        steps.push(<span>Perform Arithmetic Calculation.</span>);

        steps.push(
            <span>
                {this.get_expression(new_expression_one)} ={' '}
                {this.get_expression(new_expression_two)}
            </span>
        );

        setTimeout(
            () =>
                this.setState({
                    expression_one: new_expression_one,
                    expression_two: new_expression_two,
                    steps,
                }),
            2000
        );
        setTimeout(() => this.setState({ options_available: true }), 4000);
    }

    render() {
        const current_value = this.state.expression_one.value;
        var buttons = null;
        if (this.state.options_available) {
            var buttons = (
                <div>
                    <Button
                        type="primary"
                        text={`Add ${  current_value  } To Both Sides`}
                        onClick={() =>
                            this.choose_operation('add', current_value)
                        }
                    />
                    <Button
                        type="primary"
                        text={`Subtract ${  current_value  } From Both Sides`}
                        onClick={() =>
                            this.choose_operation('subtract', current_value)
                        }
                    />
                    <Button
                        type="primary"
                        text={`Multiply Both Sides By ${  current_value}`}
                        onClick={() =>
                            this.choose_operation('multiply', current_value)
                        }
                    />
                    <Button
                        type="primary"
                        text={`Divide Both Sides By ${  current_value}`}
                        onClick={() =>
                            this.choose_operation('divide', current_value)
                        }
                    />
                </div>
            );
        }

        if (current_value == null) {
            buttons = <h2>YOU DID IT!</h2>;
        }

        const step_display = [];
        for (const step_index in this.state.steps) {
            const new_step = this.state.steps[step_index];
            step_display.push(<Step step={new_step} />);
        }
        step_display.push(<div ref={this.messagesEndRef} />);

        return (
            <div className="container">
                <h1 style={{ fontFamily: 'Lato', marginTop: '20px' }}>
                    Solve for x
                </h1>
                <h4 style={{ fontFamily: 'Lato' }}>
                    Make the equation below look like x = ...
                </h4>
                <PageBreak />

                <div style={{ height: '500px', overflowY: 'scroll' }}>
                    {step_display}
                </div>

                {buttons}

                <div style={{ height: '20px' }} />
            </div>
        );
    }
}
