import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Button, Alert, Wrapper } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';
import EditWaysOfThinking from 'pages/automated_question_response_scoring/admin_mapping_and_scoring/ways_of_thinking.js';
import MCLMappings from 'pages/automated_question_response_scoring/admin_mapping_and_scoring/mcl_mapping.js';


function ScoreAssessment({ assessment, score_all_responses, score_disabled }) {
    return (
        <Button
            type="danger"
            text={`Score ${assessment.name}`}
            onClick={() => score_all_responses(assessment.id)}
            disabled={score_disabled}
        />
    );
}

class ResponseScoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: null,
            loaded: false,

            response_details: [],
            answer_list: [],

            scoring: false,
            has_been_scored: false,
        };

        this.load_question = this.load_question.bind(this);
        this.load_related_question_answers =
            this.load_related_question_answers.bind(this);

        this.get_responses = this.get_responses.bind(this);
        this.load_responses = this.load_responses.bind(this);

        this.score_all_responses = this.score_all_responses.bind(this);
        this.score_all_responses_callback = this.score_all_responses_callback.bind(this);
    }

    componentDidMount() {
        const { question_id } = this.props;
        ajaxWrapper(
            'GET',
            `/api/home/question/${question_id}/`,
            {},
            this.load_question
        );

        this.get_responses();
    }

    get_responses() {
        const { question_id } = this.props;
        ajaxWrapper(
            'GET',
            `/response_status_by_question_by_assessment/${question_id}/`,
            {},
            this.load_responses
        );
    }

    load_related_question_answers(result) {
        const answer_options = [];
        const answer_list = [];

        result[0].question.answers.forEach(item => {
            const answer = item.questionanswer;
            answer_options.push({
                text: answer.answer,
                value: answer.answer,
            });
            answer_list.push(answer.answer);
        });

        answer_options.sort((a, b) => (a.text > b.text ? 1 : -1));

        this.setState({
            answer_options,
            answer_list,
        });
    }

    load_question(result) {
        const { question } = result[0];

        if (question.non_explanation_question_id) {
            ajaxWrapper(
                'GET',
                `/api/home/question/${question.non_explanation_question_id}/?related=answers`,
                {},
                this.load_related_question_answers
            );
        }

        this.setState({
            question: result[0].question,
        });
    }

    load_responses(value) {
        this.setState({
            loaded: true,
            response_details: value,
        });
    }

    score_all_responses(assessment) {
        const { question_id } = this.props;
        this.setState(
            {
                scoring: true,
                has_been_scored: false,
            },
            ajaxWrapper(
                'POST',
                `/score_all_responses_by_components/${question_id}/${assessment}/`,
                {},
                this.score_all_responses_callback
            )
        );
    }

    score_all_responses_callback(result) {
        this.setState(
            {
                scoring: false,
                has_been_scored: true,
                response_details: [],
            },
            this.get_responses
        );
    }

    render() {
        const score_disabled = {};
        let scored = null;

        if (this.state.scoring) {
            score_disabled.disabled = 'disabled';
        }
        if (this.state.has_been_scored) {
            scored = (
                <Alert type="success">Responses Scored Successfully!</Alert>
            );
        }

        const scoring_buttons = [];
        const completed_scoring_buttons = [];
        
        this.state.response_details.forEach(item => {
            if (item.assessment.completed){
                completed_scoring_buttons.push(
                    <tr>
                        <td>{item.district_name}</td>
                        <td>{item.assessment.name}</td>
                        <td>{item.total}</td>
                        <td>{item.with_components}</td>
                        <td>{item.already_scored}</td>
                        <td>
                            <ScoreAssessment
                                score_all_responses={this.score_all_responses}
                                assessment={item.assessment}
                                score_disabled={score_disabled}
                            />
                        </td>
                    </tr>
                );
            }
            else {
                scoring_buttons.push(
                    <tr>
                        <td>{item.district_name}</td>
                        <td>{item.assessment.name}</td>
                        <td>{item.total}</td>
                        <td>{item.with_components}</td>
                        <td>{item.already_scored}</td>
                        <td>
                            <ScoreAssessment
                                score_all_responses={this.score_all_responses}
                                assessment={item.assessment}
                                score_disabled={score_disabled}
                            />
                        </td>
                    </tr>
                );
            }
        });

        

        const { answer_options, answer_list, question } = this.state;
        const { question_id } = this.props;
        const ways_of_thinking = (
            <EditWaysOfThinking
                question_id={question_id}
                answer_options={answer_options}
                answer_list={answer_list}
            />
        );

        const mcl_mappings = <MCLMappings question_id={question_id} />;

        const content = (
            <div className="container">
                <Alert type="info">
                    <QuestionDisplay question={question} display="text" image />
                </Alert>
                {ways_of_thinking}
                {mcl_mappings}
                <br />
                <br />

                <div>
                    <Alert type="warning">
                        <div>
                            Scoring all Responses can take significant time.
                            <br />
                            Hit these buttons only when required.
                            <br />
                            <br />
                        </div>
                        <table className="evens" style={{ width: '100%' }}>
                            <tr>
                                <th>District</th>
                                <th>Name</th>
                                <th>Total Unscored</th>
                                <th>With Components</th>
                                <th>Already Scored</th>
                                <th> </th>
                            </tr>
                            {scoring_buttons}
                            <tr>
                                <td colspan={'6'} style={{padding:'20px 0px 10px'}}  ><b>COMPLETED ASSESSMENTS</b></td>
                            </tr>
                            {completed_scoring_buttons}
                        </table>
                    </Alert>
                    {scored}
                </div>

                <br />
                <br />
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default ResponseScoring;
