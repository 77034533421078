import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    TextInput,
    Select,
    Button,
    NumberInput,
} from 'library';

import { filters_have_changed } from 'functions';

const MCL_VALUES = ['NA', 'NE', 'm', 'M', 'c', 'C', 'l', 'L'];
const LANGUAGE_VALUES = ['English', 'Spanish', 'Vietnamese', 'Tagalog'];

class ResponseFilterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            question: null,

            component_options: [],
            wot_options: [],

            filters: {
                answer_include: '',
                answer_exclude: '',
                explanation_include: '',
                explanation_exclude: '',
                components_include: [],
                components_exclude: [],

                way_of_thinking: this.props.way_of_thinking_id,
                limit: 45,
                offset: 0,

                max_characters: 400,
                max_rows: 15,
                longest: false,
                mcl: [],
                school_term: '',
            },
            timestamp: Date.now(),
        };

        this.load_question = this.load_question.bind(this);

        this.filter_responses = this.filter_responses.bind(this);
    }

    componentDidMount() {
        const related = [
            'approaches',
            'approaches__components',
            'ways_of_thinking',
            'questions_on_assessments',
            'questions_on_assessments__assessment',
            'questions_on_assessments__assessment__district',
            'questions_on_assessments__assessment__school_term',
        ];

        ajaxWrapper(
            'GET',
            `/api/home/question/${
                this.props.question_id
            }/?related=${related.join(',')}`,
            {},
            this.load_question
        );
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (filters_have_changed(this.state.filters, this.props.defaults)) {
            const timestamp = Date.now();
            this.setState({
                filters: Object.assign({}, this.props.defaults),
                form_key: timestamp,
            });
        }
    }

    load_question(result) {
        const { question } = result[0];
        const wot_options = [
            {
                text: 'Tools, Math, Context',
                value: '636a1bdd-939b-4995-ab4e-87d3f1c9508f',
            },
            {
                text: 'Generic Ways of Thinking',
                value: 'ff2c6eac-6f83-4145-8c76-2a22153d29ab',
            },
            {
                text: 'Assorted Calculations',
                value: 'b8f75f3f-5015-4bf2-9df1-4d49e1d93ae2',
            },
        ];

        const school_term_options = [];
        const district_options = [];
        const found_ids = [];
        for (var item of question.questions_on_assessments) {
            const details = item.questiononassessment;
            if (!details.assessment.id || !details.assessment.school_term.id) {
                continue;
            }

            if (found_ids.indexOf(details.assessment.school_term.id) == -1) {
                school_term_options.push({
                    text: `${details.assessment.school_term.name}`,
                    value: details.assessment.school_term.id,
                });

                found_ids.push(details.assessment.school_term.id);
            }
            if (found_ids.indexOf(details.assessment.district.id) == -1) {
                district_options.push({
                    text: details.assessment.district.name,
                    value: details.assessment.district.id,
                });

                found_ids.push(details.assessment.district.id);
            }
        }

        for (const way_of_thinking of question.ways_of_thinking) {
            wot_options.push({
                text: way_of_thinking.wayofthinking.name,
                value: way_of_thinking.wayofthinking.id,
            });
        }

        wot_options.sort((a, b) => (a.text > b.text ? 1 : -1));

        const component_options = [];
        for (const approach of question.approaches) {
            for (const component of approach.approach.components) {
                component_options.push({
                    text: component.approachcomponent.name,
                    value: component.approachcomponent.id,
                });
            }
        }

        component_options.sort((a, b) => (a.text > b.text ? 1 : -1));

        this.setState({
            question: question,
            wot_options: wot_options,
            component_options: component_options,
            school_term_options: school_term_options,
            district_options: district_options,
            loaded: true,
        });
    }

    filter_responses(name, state) {
        const new_filters = Object.assign(this.state.filters, state);

        this.setState(
            { filters: new_filters },
            this.props.update_filters(new_filters)
        );
    }

    render() {
        let mcl_options = [];
        for (let item of MCL_VALUES) {
            mcl_options.push({ text: item, value: item });
        }

        let language_options = [];
        for (let item of LANGUAGE_VALUES) {
            language_options.push({ text: item, value: item });
        }

        let evidence_options = [
            { text: 'Blank', value: 'Blank' },
            { text: 'No Evidence', value: 'No Evidence' },
            { text: 'Emerging Evidence', value: 'Emerging Evidence' },
            { text: 'Partial Evidence', value: 'Partial Evidence' },
            { text: 'Sufficient Evidence', value: 'Sufficient Evidence' },
        ];

        return (
            <div>
                <a
                    href="https://docs.google.com/document/d/1H1U5nXHb-VJqZa-yA2qsy07C5L9LwROTY7zmJ2JXHNU/edit"
                    target="_blank"
                    rel="noreferrer"
                >
                    Search Bar Shortcuts
                </a>
                <FormWithChildren
                    key={this.state.form_key}
                    row
                    submit_text="Search"
                    defaults={this.state.filters}
                    autoSetGlobalState
                    globalStateName="search"
                    setGlobalState={this.filter_responses}
                >
                    <TextInput
                        name="explanation_include"
                        className="col-6"
                        label="Include Search Term"
                    />
                    <TextInput
                        name="explanation_exclude"
                        className="col-6"
                        label="Exclude Search Term"
                    />

                    <Select
                        name="components_include"
                        className="col-6"
                        label="Include Component"
                        options={this.state.component_options}
                        multiple
                    />
                    <Select
                        name="components_exclude"
                        className="col-6"
                        label="Exclude Component"
                        options={this.state.component_options}
                        multiple
                    />

                    <NumberInput
                        name="offset"
                        label="Offset"
                        className="col-2"
                    />

                    <Select
                        name="mcl"
                        className="col-2"
                        label="MCL"
                        options={mcl_options}
                        multiple={true}
                    />

                    <Select
                        name="exclude_mcl"
                        className="col-2"
                        label="Exclude MCL"
                        options={mcl_options}
                        multiple={true}
                    />

                    <Select
                        name="student_assessment__language"
                        className="col-2"
                        label="Language"
                        options={language_options}
                    />

                    <Select
                        name="explanation_evidence"
                        className="col-4"
                        label="Explanation Evidence"
                        options={evidence_options}
                    />


                    <Select
                        name="student_assessment__assessment__district"
                        className="col-4"
                        label="District"
                        options={this.state.district_options}
                    />
                    <Select
                        name="student_assessment__assessment__school_term"
                        className="col-4"
                        label="School Term"
                        options={this.state.school_term_options}
                    />
                </FormWithChildren>

                <Button
                    style={{ float: 'right' }}
                    type="info"
                    text="Refresh"
                    onClick={this.props.refresh}
                />
                <div style={{ width: '100%', clear: 'both' }}></div>
            </div>
        );
    }
}

export default ResponseFilterForm;
