import React from 'react';

export default function Modal(props) {
    const backgroundStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        background: 'rgba(0,0,0,0.2)',
        zIndex: '1',
    };

    const modalStyle = {};
    let modalClass = 'modal fade';
    if (props.show === true) {
        modalClass += ' show';
        modalStyle.display = 'block';
    }

    const { content } = props;

    return (
        <div
            className={modalClass}
            tabIndex="-1"
            role="dialog"
            style={modalStyle}
        >
            <div onClick={props.onHide} style={backgroundStyle} />
            <div
                className="modal-dialog"
                role="document"
                style={{ zIndex: '10' }}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={props.onHide}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">{content}</div>
                    <div className="modal-footer">{props.buttons}</div>
                </div>
            </div>
        </div>
    );
}
