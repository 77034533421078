import { formatDate } from 'functions';
// using datamapping in list or form you can pass a dictionary like so dataMapping={'name':'Bob', 'id':'{id}', 'children':'{children.length}'}
// in this case, name will always be bob, id will resolve to the id of the data and children will resolve to the length of data.children

function resolveVariables(dataMapping, dataValues) {
    const mappedData = fillDict(dataMapping, dataValues);

    return mappedData;
}

function fillDict(dataMapping, dataValues) {
    // A copy of the incoming mapping needs to be made in order to ensure
    // changes are made an saved safely
    let popupatedData = { ...dataMapping };
    if (Array.isArray(dataMapping)) {
        popupatedData = Object.assign([], dataMapping);
    }

    for (const key in popupatedData) {
        // Broad Detection of React.Element type and probably other things.
        if (popupatedData[key]) {
            // Depending on the type of value stored in the key, either
            // Pass over functions
            // Repeat this function one level deeper
            // Map data into the value

            if (typeof popupatedData[key].type === 'function') {
            } else if (typeof popupatedData[key] === 'object') {
                popupatedData[key] = fillDict(popupatedData[key], dataValues);
            } else {
                const tempStr = popupatedData[key];
                popupatedData[key] = fillData(tempStr, dataValues);
            }
        }
    }

    return popupatedData;
}

function fillData(tempStr, dataValues) {
    if (typeof tempStr !== 'string') {
        return tempStr;
    }

    const dataSplit = tempStr.split('{');
    // Add initial text to output
    let resolvedValue = dataSplit[0];

    // Search through string pieces to find closing tag
    // This loop is essential to resolving more than one variable in
    // a single string
    for (let i = 1; i < dataSplit.length; i++) {
        const innerSplit = dataSplit[i].split('}');

        if (innerSplit.length > 1) {
            // Split the variable into however many different levels
            // have been included
            if (innerSplit[0] == 'datetime.today') {
                var today = new Date();
                value = formatDate(today, 'yyyy-mm-dd');
            } else if (innerSplit[0] == 'datetime.last_month') {
                var today = new Date();
                const last_month = new Date(
                    new Date().setDate(today.getDate() - 30)
                );
                value = formatDate(last_month, 'yyyy-mm-dd');
            } else {
                const variableNames = innerSplit[0].split('.');
                var value = dataValues;

                for (const j in variableNames) {
                    const variableName = variableNames[j];

                    if (typeof value === 'undefined') {
                        break;
                    } else if (variableName == 'length') {
                        value = value.length;
                    } else if (!isNaN(parseInt(variableName))) {
                        value = value[parseInt(variableName)];
                    } else if (value) {
                        value = value[variableName];
                    }
                }
            }

            // If the resolved value cannot be simply repesented as a string
            // convert it to JSON first
            if (
                resolvedValue.indexOf('undefined') == -1 &&
                value != undefined
            ) {
                if (typeof value === 'object') {
                    resolvedValue += JSON.stringify(value) + innerSplit[1];
                } else {
                    resolvedValue += value + innerSplit[1];
                }
            } else if (innerSplit[0].startsWith('params')) {
                resolvedValue += innerSplit[1];
            } else {
                resolvedValue += `{${innerSplit[0]}}${innerSplit[1]}`;
            }
        } else {
            resolvedValue += innerSplit[0];
        }
    }

    return resolvedValue;
}

// A simple brute force test to ensure an object is JSON compatible
function isJsonable(v) {
    try {
        return (
            JSON.stringify(v) === JSON.stringify(JSON.parse(JSON.stringify(v)))
        );
    } catch (e) {
        /* console.error("not a dict",e); */
        return false;
    }
}

export default resolveVariables;
