import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    Button,
    FormWithChildren,
    Select,
    Wrapper,
    Alert,
} from 'library';

import AnonDistrict from 'pages/assessments/components/anon_district.js';
import anon_district_text from 'pages/assessments/components/anon_district_text.js';

function QuestionOnAssessment(props) {
    const { assessment, question } = props;

    let button = (
        <Button
            type="danger"
            text="Inactive"
            onClick={() => props.toggle_active(assessment.id, question.id)}
        />
    );
    if (question.scoring_active) {
        button = (
            <Button
                type="success"
                text="Active"
                onClick={() => props.toggle_active(assessment.id, question.id)}
            />
        );
    }

    return (
        <div style={{ width: '100%', padding: '5px' }}>
            <div style={{ float: 'right' }}>{button}</div>
            <div>{question.question.name}</div>
            <div
                style={{
                    clear: 'both',
                    borderBottom: 'thin solid #eee',
                    paddingTop: '5px',
                }}
            />
        </div>
    );
}

function Assessment(props) {
    const { assessment } = props;

    return (
        <div className="simple-card">
            <div>
                <h4 style={{ display: 'inline-block' }}>{assessment.name}</h4>
                <div className="simple-grey-tag">
                    <AnonDistrict district={assessment.district} />
                </div>
            </div>
            <div>{props.questions}</div>
        </div>
    );
}

class QuestionScoringDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            assessments: [],

            district: '',
            question: this.props.question || '',
        };

        this.setGlobalState = this.setGlobalState.bind(this);
        this.get_assessments = this.get_assessments.bind(this);
        this.toggle_active = this.toggle_active.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/home/assessment/?completed=false&related=district,questions_on_assessments,questions_on_assessments__question&related__questions_on_assessments__order_by=question__name',
            {},
            this.get_assessments
        );
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    get_assessments(result) {
        const assessments = [];
        const districts = {};

        for (let item of result) {
            const { assessment } = item;
            assessments.push(assessment);

            if (!(assessment.district.id in districts)) {
                districts[assessment.district.id] = assessment.district;
            }
        }

        const district_options = [];
        Object.keys(districts).forEach(key => {
            const district = districts[key];
            district_options.push({
                text: anon_district_text(district),
                value: district.id,
            });
        });

        this.setState({
            assessments,
            district_options,
            loaded: true,
        });
    }

    toggle_active(assessment_id, question_id) {
        const new_assessments = JSON.parse(
            JSON.stringify(this.state.assessments)
        );

        let found = false;
        for (const assessment of new_assessments) {
            for (const question of assessment.questions_on_assessments) {
                if (question.questiononassessment.id == question_id) {
                    question.questiononassessment.scoring_active =
                        !question.questiononassessment.scoring_active;
                    ajaxWrapper(
                        'POST',
                        `/api/home/QuestionOnAssessment/${question_id}/`,
                        {
                            scoring_active:
                                question.questiononassessment.scoring_active,
                        },
                        () => {}
                    );

                    found = true;
                    break;
                }
            }

            if (found) {
                break;
            }
        }

        this.setState({ assessments: new_assessments });
    }

    render() {
        const allowed_roles = ['Super Admin', 'Manager Analyst'];

        if (allowed_roles.indexOf(window.cmState.user.role) == -1) {
            return <h1>You do not belong here</h1>;
        }

        const assessments = [];
        const question_ids = [];
        const question_options = [];
        for (const assessment of this.state.assessments) {
            if (
                this.state.district &&
                assessment.district.id != this.state.district
            ) {
                continue;
            }

            const questions = [];
            for (const question of assessment.questions_on_assessments) {
                if (
                    question_ids.indexOf(
                        question.questiononassessment.question.id
                    ) == -1
                ) {
                    question_ids.push(
                        question.questiononassessment.question.id
                    );
                    question_options.push({
                        text: question.questiononassessment.question
                            .name,
                        value: question.questiononassessment.question
                            .id,
                    });
                }

                if (
                    this.state.question &&
                    question.questiononassessment.question.id !=
                        this.state.question
                ) {
                    continue;
                }

                questions.push(
                    <QuestionOnAssessment
                        assessment={assessment}
                        question={question.questiononassessment}
                        toggle_active={this.toggle_active}
                    />
                );
            }

            if (questions.length > 0) {
                assessments.push(
                    <Assessment assessment={assessment} questions={questions} />
                );
            }
        }

        const defaults = {
            district: this.state.district,
            question: this.state.question,
        };
        const filters = (
            <Alert type="info">
                <FormWithChildren
                    className="row"
                    defaults={defaults}
                    autoSetGlobalState
                    setGlobalState={this.setGlobalState}
                    globalStateName="assessment"
                >
                    <div className="col-3">
                        <Select
                            options={this.state.district_options}
                            name="district"
                            label="District"
                        />
                    </div>
                    <div className="col-3">
                        <Select
                            options={question_options}
                            name="question"
                            label="Question"
                        />
                    </div>
                </FormWithChildren>
            </Alert>
        );

        const content = (
            <div className="container">
                <h2>Questions On Assessments: Is Scoring Active?</h2>
                {filters}
                <Container
                    style={{
                        background: '#eee',
                        padding: '10px',
                        marginBottom: '10px',
                    }}
                >
                    {assessments}
                </Container>
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default QuestionScoringDashboard;
