import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    FormWithChildren,
    Alert,
    NumberInput,
    Image,
    Header,
    Wrapper,
} from 'library';

class ScoreAssessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            questions: {},
            question_scores_by_grade: {},
            grade_total_by_category: {},
            grade_total_by_category_band: {},
            assessment: {},
            scoring_assessments: false,
            clickedQuestion: null,
        };

        this.get_assessment = this.get_assessment.bind(this);
        this.get_assessment_performance =
            this.get_assessment_performance.bind(this);
        this.all_questions_are_scored =
            this.all_questions_are_scored.bind(this);
        this.score_grade_bands = this.score_grade_bands.bind(this);
        this.assessment_performance = this.assessment_performance.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/assessment/${this.props.assessment_id}/`,
            {},
            this.get_assessment
        );
        this.assessment_performance();
    }

    assessment_performance() {
        ajaxWrapper(
            'GET',
            `/assessment_performance/${this.props.assessment_id}/`,
            {},
            this.get_assessment_performance
        );
    }

    get_assessment(result) {
        this.setState({ assessment: result[0].assessment });
    }

    get_assessment_performance(result) {
        result.loaded = true;
        result.scoring_assessments = false;
        this.setState(result);
    }

    all_questions_are_scored() {
        ajaxWrapper(
            'POST',
            `/assessment_scoring_by_question/${this.props.assessment_id}/`,
            {},
            this.assessment_performance
        );
        this.setState({ scoring_assessments: true });
    }

    score_grade_bands(grade_bands, returnFunc) {
        console.log('Score Grade Bands', grade_bands);
        const grade_list = [];
        for (const index in this.state.grade_count) {
            grade_list.push(index);
        }

        grade_bands['grade_list[]'] = grade_list;

        ajaxWrapper(
            'POST',
            `/assessment_grade_band_scoring/${this.props.assessment_id}/`,
            grade_bands,
            this.assessment_performance
        );
        this.setState(
            { scoring_assessments: true },
            returnFunc({ success: true })
        );
    }

    loadQuestion = result => {
        const questionData = result[0].question;
        this.setState({ clickedQuestion: questionData });
    };

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        var content = null;

        if (this.state.loaded) {
            const questions = [];
            const score_questions = [];
            const overall_score_by_question = [];
            const overall_score_by_question_by_grade_list = {};

            const knowledge_scores_by_grade_list = {};
            const application_scores_by_grade_list = {};
            const communication_scores_by_grade_list = {};

            const knowledge_bands_by_grade_list = {};
            const application_bands_by_grade_list = {};
            const communication_bands_by_grade_list = {};
            const questions_by_category = {
                Knowledge: 0,
                Application: 0,
                Explanation: 0,
            };

            const grade_list = [];

            for (const grade_index in this.state.question_scores_by_grade) {
                grade_list.push(grade_index);
                overall_score_by_question_by_grade_list[grade_index] = [];
                overall_score_by_question_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);

                knowledge_scores_by_grade_list[grade_index] = [];
                knowledge_scores_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);

                application_scores_by_grade_list[grade_index] = [];
                application_scores_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);

                communication_scores_by_grade_list[grade_index] = [];
                communication_scores_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);

                knowledge_bands_by_grade_list[grade_index] = [];
                knowledge_bands_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);

                application_bands_by_grade_list[grade_index] = [];
                application_bands_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);

                communication_bands_by_grade_list[grade_index] = [];
                communication_bands_by_grade_list[grade_index].push([
                    <td>{grade_index}</td>,
                ]);
            }

            console.log('Grade List', grade_list);
            console.log(
                'Overall Score By Question',
                overall_score_by_question_by_grade_list
            );

            var i = 0;
            for (const question_index in this.state.assessment_questions) {
                i += 1;
                const question =
                    this.state.assessment_questions[question_index];
                questions_by_category[question.category] += 1;
                questions.push(
                    <th>
                        <span
                            onClick={() => {
                                ajaxWrapper(
                                    'GET',
                                    `/api/home/question/${question.id}/?related=answers,answers__response_groups,answers__has_asset`,
                                    {},
                                    this.loadQuestion
                                );
                            }}
                            title={question.text}
                            style={{
                                cursor: 'pointer',
                                color: 'blue',
                                textDecoration: 'underline',
                            }}
                        >
                            Question #{i}: {question.category}
                        </span>
                    </th>
                );

                let has_been_scored = 0;
                let needs_review = 0;
                let count = 0;
                let sum = 0;
                for (const g in grade_list) {
                    var pct_correct = 'NA';
                    var grade_index = grade_list[g];
                    var grade =
                        this.state.question_scores_by_grade[grade_index];
                    if (grade && grade[question.id]) {
                        has_been_scored += grade[question.id].has_been_scored;
                        needs_review += grade[question.id].review;
                        count += grade[question.id].count;
                        sum += grade[question.id].sum;
                        if (grade[question.id].count > 0) {
                            pct_correct = `${(
                                (grade[question.id].sum /
                                    grade[question.id].count) *
                                100
                            ).toFixed(2)}%`;
                        }
                    }

                    overall_score_by_question_by_grade_list[grade_index].push(
                        <td>{pct_correct}</td>
                    );
                }

                if (question.category == 'Explanation') {
                    score_questions.push(
                        <td>
                            <a
                                title="Click To Score"
                                href={`/response_explorer/${question.id}/`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {has_been_scored} ({needs_review})
                            </a>
                        </td>
                    );
                } else {
                    score_questions.push(
                        <td>
                            <a
                                title="Click To Score"
                                href={`/student_response_grouping/${question.id}/`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {has_been_scored} ({needs_review})
                            </a>
                        </td>
                    );
                }

                var pct_correct = 'NA';
                if (count > 0) {
                    pct_correct = `${((sum / count) * 100).toFixed(2)}%`;
                }
                overall_score_by_question.push(<td>{pct_correct}</td>);
            }

            const overall_score_by_question_by_grade = [];
            for (const index in overall_score_by_question_by_grade_list) {
                overall_score_by_question_by_grade.push(
                    <tr>{overall_score_by_question_by_grade_list[index]}</tr>
                );
            }

            const score_by_question = (
                <div style={{ overflowX: 'scroll', width: '100%' }}>
                    <table className="table">
                        <tr>
                            <th>Grade</th>
                            {questions}
                        </tr>
                        <tr>
                            <th>Has Been Scored (Needs Review)</th>
                            {score_questions}
                        </tr>
                        <tr>
                            <th>Overall</th>
                            {overall_score_by_question}
                        </tr>
                        {overall_score_by_question_by_grade}
                    </table>
                </div>
            );

            const possible_knowledge_scores = [<th>Left Blank</th>];
            for (let i = 0.0; i <= questions_by_category.Knowledge; i++) {
                possible_knowledge_scores.push(<th>{i}</th>);
            }

            for (const g in grade_list) {
                var grade_index = grade_list[g];
                if (
                    !this.state.grade_total_by_category ||
                    !this.state.grade_total_by_category[grade_index]
                ) {
                    continue;
                }
                var grade =
                    this.state.grade_total_by_category[grade_index].knowledge;

                var total_in_grade = 0;
                var pct_in_grade = 'N/A';
                if ('Blank' in grade) {
                    total_in_grade = grade.Blank;
                    pct_in_grade = (
                        (total_in_grade / this.state.grade_count[grade_index]) *
                        100
                    ).toFixed(2);
                }
                knowledge_scores_by_grade_list[grade_index].push(
                    <td>
                        {total_in_grade} ({pct_in_grade}%)
                    </td>
                );

                for (var i = 0.0; i <= questions_by_category.Knowledge; i++) {
                    var total_in_grade = 0;
                    var pct_in_grade = 'N/A';
                    if (`${i}.0` in grade) {
                        total_in_grade = grade[`${i}.0`];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }
                    knowledge_scores_by_grade_list[grade_index].push(
                        <td>
                            {total_in_grade} ({pct_in_grade}%)
                        </td>
                    );
                }
            }

            const knowledge_scores_by_grade = [];
            for (const index in knowledge_scores_by_grade_list) {
                knowledge_scores_by_grade.push(
                    <tr>{knowledge_scores_by_grade_list[index]}</tr>
                );
            }

            const knowledge_total_by_grade = (
                <table className="table">
                    <tr>
                        <th>Grade</th>
                        {possible_knowledge_scores}
                    </tr>
                    {knowledge_scores_by_grade}
                </table>
            );

            const possible_application_scores = [<th>Left Blank</th>];
            for (let i = 0.0; i <= questions_by_category.Application; i++) {
                possible_application_scores.push(<th>{i}</th>);
            }

            for (const g in grade_list) {
                var grade_index = grade_list[g];
                if (
                    !this.state.grade_total_by_category ||
                    !this.state.grade_total_by_category[grade_index]
                ) {
                    continue;
                }
                var grade =
                    this.state.grade_total_by_category[grade_index].application;

                var total_in_grade = 0;
                var pct_in_grade = 'N/A';
                if ('Blank' in grade) {
                    total_in_grade = grade.Blank;
                    pct_in_grade = (
                        (total_in_grade / this.state.grade_count[grade_index]) *
                        100
                    ).toFixed(2);
                }
                application_scores_by_grade_list[grade_index].push(
                    <td>
                        {total_in_grade} ({pct_in_grade}%)
                    </td>
                );

                for (var i = 0.0; i <= questions_by_category.Application; i++) {
                    var total_in_grade = 0;
                    var pct_in_grade = 'N/A';
                    if (`${i}.0` in grade) {
                        total_in_grade = grade[`${i}.0`];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }
                    application_scores_by_grade_list[grade_index].push(
                        <td>
                            {total_in_grade} ({pct_in_grade}%)
                        </td>
                    );
                }
            }

            const application_scores_by_grade = [];
            for (const index in application_scores_by_grade_list) {
                application_scores_by_grade.push(
                    <tr>{application_scores_by_grade_list[index]}</tr>
                );
            }

            const application_total_by_grade = (
                <table className="table">
                    <tr>
                        <th>Grade</th>
                        {possible_application_scores}
                    </tr>
                    {application_scores_by_grade}
                </table>
            );

            const possible_communication_scores = [<th>Left Blank</th>];
            for (
                var i = 0.0;
                i <= questions_by_category.Explanation * 4;
                i += 0.5
            ) {
                possible_communication_scores.push(<th>{i}</th>);
            }

            for (const g in grade_list) {
                var grade_index = grade_list[g];
                if (
                    !this.state.grade_total_by_category ||
                    !this.state.grade_total_by_category[grade_index]
                ) {
                    continue;
                }
                var grade =
                    this.state.grade_total_by_category[grade_index]
                        .communication;

                var total_in_grade = 0;
                var pct_in_grade = 'N/A';
                if ('Blank' in grade) {
                    total_in_grade = grade.Blank;
                    pct_in_grade = (
                        (total_in_grade / this.state.grade_count[grade_index]) *
                        100
                    ).toFixed(2);
                }
                communication_scores_by_grade_list[grade_index].push(
                    <td>
                        {total_in_grade} ({pct_in_grade}%)
                    </td>
                );

                for (
                    var i = 0.0;
                    i <= questions_by_category.Explanation * 4;
                    i += 0.5
                ) {
                    var total_in_grade = 0;
                    var pct_in_grade = 'N/A';
                    if (`${i}.0` in grade) {
                        total_in_grade = grade[`${i}.0`];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }
                    if (i in grade) {
                        total_in_grade = grade[i];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }
                    communication_scores_by_grade_list[grade_index].push(
                        <td>
                            {total_in_grade} ({pct_in_grade}%)
                        </td>
                    );
                }
            }

            const communication_scores_by_grade = [];
            for (const index in communication_scores_by_grade_list) {
                communication_scores_by_grade.push(
                    <tr>{communication_scores_by_grade_list[index]}</tr>
                );
            }

            const communication_total_by_grade = (
                <div style={{ overflowX: 'scroll', width: '100%' }}>
                    <table className="table">
                        <tr>
                            <th>Grade</th>
                            {possible_communication_scores}
                        </tr>
                        {communication_scores_by_grade}
                    </table>
                </div>
            );

            let scoring_alert = null;
            if (this.state.scoring_assessments) {
                scoring_alert = (
                    <Alert
                        type="success"
                        text="Scoring the Assessments. Please Wait."
                    />
                );
            }

            const form_fields = [];
            const categories = ['Knowledge', 'Application', 'Communication'];
            const proficiency_bands = ['Incomplete', 'Proficient', 'Strong'];
            for (const index in categories) {
                const category = categories[index];
                form_fields.push(
                    <Header
                        className="col-12"
                        size={5}
                        text={categories[index]}
                    />
                );
                for (const i in grade_list) {
                    var grade_index = grade_list[i];
                    form_fields.push(
                        <Header
                            className="col-12"
                            size={6}
                            text={`Grade: ${grade_index}`}
                        />
                    );
                    for (const prof_index in proficiency_bands) {
                        var proficiency = proficiency_bands[prof_index];
                        form_fields.push(
                            <NumberInput
                                className="col-4"
                                label={`Minimum ${proficiency} Score.`}
                                name={`${grade_index}_${proficiency}_${category}`}
                            />
                        );
                    }
                }
            }

            for (const g in grade_list) {
                var grade_index = grade_list[g];
                if (
                    !this.state.grade_total_by_category ||
                    !this.state.grade_total_by_category[grade_index]
                ) {
                    continue;
                }
                var grade =
                    this.state.grade_total_by_category_band[grade_index];

                const proficiencies = [
                    'Blank',
                    'Incomplete',
                    'Partial',
                    'Strong',
                ];
                for (let i = 0; i < proficiencies.length; i++) {
                    var proficiency = proficiencies[i];

                    var total_in_grade = 0;
                    var pct_in_grade = 'N/A';
                    if (proficiency in grade.knowledge) {
                        total_in_grade = grade.knowledge[proficiency];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }

                    knowledge_bands_by_grade_list[grade_index].push(
                        <td>
                            {total_in_grade} ({pct_in_grade}%)
                        </td>
                    );

                    var total_in_grade = 0;
                    var pct_in_grade = 'N/A';
                    if (proficiency in grade.application) {
                        total_in_grade = grade.application[proficiency];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }
                    application_bands_by_grade_list[grade_index].push(
                        <td>
                            {total_in_grade} ({pct_in_grade}%)
                        </td>
                    );

                    var total_in_grade = 0;
                    var pct_in_grade = 'N/A';
                    if (proficiency in grade.communication) {
                        total_in_grade = grade.communication[proficiency];
                        pct_in_grade = (
                            (total_in_grade /
                                this.state.grade_count[grade_index]) *
                            100
                        ).toFixed(2);
                    }
                    communication_bands_by_grade_list[grade_index].push(
                        <td>
                            {total_in_grade} ({pct_in_grade}%)
                        </td>
                    );
                }
            }

            const knowledge_bands_by_grade = [];
            for (const index in knowledge_bands_by_grade_list) {
                knowledge_bands_by_grade.push(
                    <tr>{knowledge_bands_by_grade_list[index]}</tr>
                );
            }

            const knowledge_band_by_grade = (
                <table className="table">
                    <tr>
                        <th>Grade</th>
                        <th>Left Blank</th>
                        <th>Incomplete</th>
                        <th>Partial</th>
                        <th>Strong</th>
                    </tr>
                    {knowledge_bands_by_grade}
                </table>
            );

            const application_bands_by_grade = [];
            for (const index in application_bands_by_grade_list) {
                application_bands_by_grade.push(
                    <tr>{application_bands_by_grade_list[index]}</tr>
                );
            }

            const application_band_by_grade = (
                <table className="table">
                    <tr>
                        <th>Grade</th>
                        <th>Left Blank</th>
                        <th>Incomplete</th>
                        <th>Partial</th>
                        <th>Strong</th>
                    </tr>
                    {application_bands_by_grade}
                </table>
            );

            const communication_bands_by_grade = [];
            for (const index in communication_bands_by_grade_list) {
                communication_bands_by_grade.push(
                    <tr>{communication_bands_by_grade_list[index]}</tr>
                );
            }

            const communication_band_by_grade = (
                <table className="table">
                    <tr>
                        <th>Grade</th>
                        <th>Left Blank</th>
                        <th>Incomplete</th>
                        <th>Partial</th>
                        <th>Strong</th>
                    </tr>
                    {communication_bands_by_grade}
                </table>
            );

            var content = (
                <div className="container">
                    <h2>{this.state.assessment.name}</h2>
                    <h4>Score By Question</h4>
                    {score_by_question}

                    <div style={{ height: '50px' }} />
                    <Button
                        text="Questions Are Scored, Tally Totals By Category"
                        type="primary"
                        onClick={this.all_questions_are_scored}
                    />
                    {scoring_alert}

                    <h4 style={{ marginTop: '20px' }}>
                        Knowledge Total By Grade
                    </h4>
                    {knowledge_total_by_grade}

                    <h4 style={{ marginTop: '20px' }}>
                        Application Total By Grade
                    </h4>
                    {application_total_by_grade}

                    <h4 style={{ marginTop: '20px' }}>
                        Communication Total By Grade
                    </h4>
                    {communication_total_by_grade}

                    <div style={{ height: '50px' }} />
                    <h4>Grade Band Scoring</h4>
                    <FormWithChildren
                        row
                        submit={this.score_grade_bands}
                        submit_text="Save and Score"
                        defaults={this.state.assessment.score_bands}
                    >
                        {form_fields}
                    </FormWithChildren>
                    {scoring_alert}

                    <h4 style={{ marginTop: '20px' }}>
                        Knowledge Band By Grade
                    </h4>
                    {knowledge_band_by_grade}

                    <h4 style={{ marginTop: '20px' }}>
                        Application Band By Grade
                    </h4>
                    {application_band_by_grade}

                    <h4 style={{ marginTop: '20px' }}>
                        Communication Band By Grade
                    </h4>
                    {communication_band_by_grade}
                </div>
            );
        }
        return (
            <>
                {this.state.clickedQuestion && (
                    <div
                        style={{
                            paddingInline: '5vw',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        <div
                            onClick={() =>
                                this.setState({ clickedQuestion: null })
                            }
                            style={{
                                position: 'absolute',
                                top: '6px',
                                right: '6px',
                                margin: '0px',
                                minWidth: '0px',
                                borderRadius: '4px',
                                color: 'white',
                                background: '#FC7F53',
                                padding: '10px',
                                lineHeight: '15px',
                                cursor: 'pointer',
                            }}
                        >
                            <Image
                                src="/static/images/reporting/Vector.svg"
                                className="icon"
                                css={{ width: '15px' }}
                            />
                        </div>
                        <img
                            style={{
                                minWidth: '50%',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                            }}
                            src={
                                this.state.clickedQuestion.images_by_language
                                    .English
                            }
                            alt={
                                this.state.clickedQuestion.text_by_language
                                    .English
                            }
                        />
                    </div>
                )}
                <Wrapper loaded={this.state.loaded} content={content} />
            </>
        );
    }
}

export default ScoreAssessment;
