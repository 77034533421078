import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    Button,
    PageBreak,
    EmptyModal,
    FormWithChildren,
    TextArea,
} from 'library';

import QuestionImage from 'pages/questions/image.js';
import AssetFilter from './components/asset_filter.js';

class ExistingAsset extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const content = null;

        const { has_asset } = this.props;

        const asset_name = this.props.assets_lookup[has_asset.asset_id].name;

        let asset_class = 'btn btn-success';
        if (has_asset.heuristic_value === -1) {
            asset_class = 'btn btn-danger';
        }

        return (
            <div
                className={asset_class}
                style={{ margin: '0px 5px 5px 0px' }}
                onClick={() => this.props.delete_asset(has_asset.id)}
            >
                {asset_name}
            </div>
        );
    }
}

class NewAnswer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { value } = this.props.item;

        return (
            <tr>
                <td>{value}</td>
                <td>
                    <Button
                        type="primary"
                        text="Create Answer"
                        onClick={() => this.props.create_answer(value)}
                        style={{ marginLeft: '5px' }}
                    />
                </td>
            </tr>
        );
    }
}

export default class QuestionAnswersAssignment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,

            assets_by_category: {},
            assets_lookup: {},
            modal: false,

            sorted_answers: [],
            top_answers: [],
        };

        this.assets_callback = this.assets_callback.bind(this);
        this.refresh_question = this.refresh_question.bind(this);
        this.question_callback = this.question_callback.bind(this);
        this.answers_callback = this.answers_callback.bind(this);

        this.create_answer = this.create_answer.bind(this);

        this.select_modal = this.select_modal.bind(this);
        this.hide_modal = this.hide_modal.bind(this);

        this.assign_asset = this.assign_asset.bind(this);
        this.delete_asset = this.delete_asset.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/actionable_assets/category/?related=assets',
            {},
            this.assets_callback
        );
        // ajaxWrapper('GET','/question/top_answers/'+ this.props.question_id +'/', {}, this.answers_callback);
    }

    assets_callback(value) {
        const assets_lookup = {};
        for (const item of value) {
            for (const jtem of item.category.assets) {
                const { asset } = jtem;
                assets_lookup[asset.id] = asset;
            }
        }

        this.setState(
            {
                assets_by_category: value,
                assets_lookup,
            },
            this.refresh_question
        );
    }

    answers_callback(value) {
        this.setState({
            top_answers: value.top_answers,
            total_answers: value.total,
        });
    }

    refresh_question() {
        const related = 'answers,answers__has_asset,answers__response_groups';
        ajaxWrapper(
            'GET',
            `/api/home/question/?id=${this.props.question_id}&related=${related}`,
            {},
            this.question_callback
        );
    }

    question_callback(value) {
        const { question } = value[0];

        var sorted_answers = [];
        for (const item of question.answers) {
            const answer = item.questionanswer;
            let total_responses = 0;
            for (const jtem of answer.response_groups) {
                const group = jtem.studentresponsegroup;
                total_responses += group.total_responses;
            }

            answer.total_responses = total_responses;
        }
        var sorted_answers = question.answers.sort(
            (a, b) =>
                b.questionanswer.total_responses -
                a.questionanswer.total_responses
        );

        this.setState({
            loaded: true,
            question: value[0].question,
            sorted_answers,
            modal: false,
        });
    }

    create_answer(value) {
        const data = {
            question: this.props.question_id,
            answer: value,
        };

        ajaxWrapper(
            'POST',
            '/api/home/questionanswer/',
            data,
            this.refresh_question
        );
    }

    select_modal(event) {
        const answer_id = event.target.getAttribute('num');
        this.setState({
            modal: true,
            answer_id,
        });
    }

    hide_modal() {
        this.setState({ modal: false });
    }

    assign_asset(name, state) {
        console.log('Assigning Standard', this.state.answer_id, name, state);

        const data = {
            answer: this.state.answer_id,
            asset: state.id,
            heuristic_value: state.heuristic_value,
        };

        ajaxWrapper(
            'POST',
            '/api/actionable_assets/QuestionAnswerHasAsset/',
            data,
            this.refresh_question
        );
    }

    delete_asset(id) {
        ajaxWrapper(
            'POST',
            `/api/actionable_assets/QuestionAnswerHasAsset/${id}/delete/`,
            {},
            this.refresh_question
        );
    }

    render() {
        let content = null;

        const answers = [];
        if (this.state.loaded) {
            const { question } = this.state;
            const question_details = <QuestionImage question={question} />;

            const found_answers = [];

            for (var item of this.state.sorted_answers) {
                const answer = item.questionanswer;
                found_answers.push(answer.answer);

                const description = (
                    <FormWithChildren
                        submitUrl={`/api/home/questionanswer/${answer.id}/`}
                        defaults={{ description: answer.description }}
                    >
                        <TextArea name="description" />
                    </FormWithChildren>
                );

                const assets = [];
                for (var item of answer.has_asset) {
                    const has_asset = item.questionanswerhasasset;

                    assets.push(
                        <ExistingAsset
                            has_asset={has_asset}
                            assets_lookup={this.state.assets_lookup}
                            delete_asset={this.delete_asset}
                        />
                    );
                }

                answers.push(
                    <tr>
                        <td>{answer.answer}</td>
                        <td>{description}</td>
                        <td>
                            <div>{assets}</div>
                            <div>
                                <Button
                                    type="primary"
                                    text="Add Asset"
                                    onClick={this.select_modal}
                                    num={answer.id}
                                />
                            </div>
                        </td>
                    </tr>
                );
            }

            const new_answers = [];
            for (var item of this.state.top_answers) {
                if (found_answers.indexOf(item.value) > -1) {
                    continue;
                }

                new_answers.push(
                    <NewAnswer
                        item={item}
                        refresh_question={this.refresh_question}
                        create_answer={this.create_answer}
                    />
                );
            }

            let modal = null;
            if (this.state.modal) {
                modal = (
                    <EmptyModal
                        show
                        onHide={() => this.setState({ modal: false })}
                    >
                        <AssetFilter
                            assets_by_category={this.state.assets_by_category}
                            setGlobalState={this.assign_asset}
                        />
                    </EmptyModal>
                );
            }

            content = (
                <Container>
                    <br />
                    <br />
                    <h2>Question Details</h2>

                    {modal}

                    <div className="row">
                        <div className="col-12">{question_details}</div>

                        <div className="col-12" style={{ margin: '10px 0px' }}>
                            <table className="table">
                                <tr>
                                    <th>Answer</th>
                                    <th>Description</th>
                                    <th>Sensemaking</th>
                                </tr>
                                {answers}
                            </table>
                        </div>

                        <PageBreak />

                        <div className="col-12" style={{ margin: '10px 0px' }}>
                            <h4>Top Answers</h4>
                            <table className="table">
                                <tr>
                                    <th>Answer</th>
                                    <th>Create</th>
                                </tr>
                                {new_answers}
                            </table>
                        </div>
                    </div>
                </Container>
            );
        }

        return content;
    }
}
