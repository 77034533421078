import React, { Component } from 'react';

import PercentDisplay from 'pages/reports/results_explorer/components/percent_display.js';
import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

const COMMUNICATION_CATEGORY = 'bcba4111-d2fc-4dc1-b19a-a1b843c6e8ee';

export default class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { displayOrder } = this.props;
        let image = <div />;
        let description = <div />;

        if (this.props.question.image) {
            image = (
                <QuestionEnlargeImage
                    image={this.props.question.image}
                    text={this.props.question.text}
                    height={150}
                />
            );
        } else {
            description = (
                <div className="card-text">{this.props.question.text}</div>
            );
        }

        let pct =
            (this.props.question_details.sum /
                this.props.question_details.count) *
            100;
        if (this.props.question.category == COMMUNICATION_CATEGORY) {
            pct =
                (this.props.question_details.sum /
                    (this.props.question_details.count * 4)) *
                100;
        }

        var diff = null;
        if (
            this.props.filters &&
            this.props.district &&
            this.props.district.question_details[this.props.id]
        ) {
            let district_pct =
                (this.props.district.question_details[this.props.id].sum /
                    this.props.district.question_details[this.props.id].count) *
                100;
            if (this.props.question.category == COMMUNICATION_CATEGORY) {
                district_pct =
                    (this.props.district.question_details[this.props.id].sum /
                        (this.props.district.question_details[this.props.id]
                            .count *
                            4)) *
                    100;
            }

            var diff = pct - district_pct;
        }

        let view = (
            <div
                className="purple-button"
                onClick={() => this.props.set_question(this.props.id)}
            >
                View more
            </div>
        );
        if (this.props.view == false) {
            view = null; // <div className='purple-button' >Why this question</div>;
        }

        let question_classes = 'card question light-purple-border';
        let image_classes = '';
        if (!this.props.card) {
            question_classes = 'question';
            image_classes = 'light-purple-border';
        }

        return (
            <div
                className={question_classes}
                style={{
                    textAlign: 'center',
                    marginBottom: '10px',
                    padding: '10px',
                    position: 'relative',
                }}
            >
                {this.props.question_order !== undefined && displayOrder ? (
                    <div
                        className="question-number"
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '5px',
                            zIndex: 10,
                        }}
                    >
                        Question #{this.props.question_order}
                    </div>
                ) : (
                    <></>
                )}
                <div className={image_classes}>
                    {image}
                    {description}
                </div>
                <PercentDisplay
                    show_percentage={this.props.show_percentage}
                    percent={pct}
                    diff={diff}
                    update_filters={this.props.update_filters}
                    page="student_list"
                    data={{ question_id: this.props.id }}
                    container_style={{ padding: '10px 0px 20px 0px' }}
                />
                {view}
            </div>
        );
    }
}
