import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Form, PasswordInput, Wrapper } from 'library';

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, found: false };

        this.found_user = this.found_user.bind(this);
    }

    componentDidMount() {
        const { user_id } = this.props;
        ajaxWrapper(
            'POST',
            `/users/password_reset_check/${user_id}/`,
            {},
            this.found_user
        );
    }

    found_user(result) {
        if (result.found) {
            this.setState({ loaded: true, found: true });
        } else {
            this.setState({ loaded: true, found: false });
        }
    }

    render() {
        const Components = [PasswordInput];
        const password_props = { confirm_password: true };

        const ComponentProps = [password_props];
        const defaults = { password: '' };
        const { user_id } = this.props;

        const submitUrl = `/users/resetPassword/${user_id}/`;
        const { found: foundState } = this.state;
        let content = null;
        if (foundState) {
            content = (
                <div className="container">
                    <h2>Reset Password</h2>
                    <Form
                        components={Components}
                        redirectUrl="/login/"
                        componentProps={ComponentProps}
                        submitUrl={submitUrl}
                        defaults={defaults}
                        disableSubmitOnMount={true}
                    />
                </div>
            );
        } else {
            content = (
                <div className="container">
                    <h2>Your Password Reset Link Has Expired.</h2>
                    <a href="/passwordResetRequest/">
                        Please Re-initiate a password reset request by clicking
                        here
                    </a>
                </div>
            );
        }
        const { loaded: loadedState } = this.state;
        return <Wrapper loaded={loadedState} content={content} />;
    }
}

export default PasswordReset;
