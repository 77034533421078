import React from 'react';
import { Header } from 'library';

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.select_tab = this.select_tab.bind(this);
    }

    select_tab(name) {
        this.props.select_tab(this.props.name);
    }

    render() {
        const style = { listStyle: 'none', ...this.props.style };
        let tab = null;

        let tab_name = this.props.name;
        if (this.props.only_show_current_name) {
            tab_name = '';
        }

        if (this.props.name == this.props.selected_tab) {
            tab = (
                <div
                    onClick={this.select_tab}
                    className={`tab-header active col-${this.props.columns} ${this.props.tab_class}`}
                >
                    <div className="tab-name">{tab_name}</div>
                </div>
            );
        } else {
            tab = (
                <div
                    onClick={this.select_tab}
                    className={`tab-header col-${this.props.columns} ${this.props.tab_class}`}
                >
                    <div className="tab-name">{tab_name}</div>
                </div>
            );
        }

        return tab;
    }
}

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);
        const initial_tab = this.props.initial_tab || this.props.tab_names[0];

        this.state = {
            selected_tab: initial_tab,
            current_time: this.props.current_time,
        };
        if (this.props.current_tab) {
            this.state.selected_tab = this.props.current_tab;
        }

        this.select_tab = this.select_tab.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.current_time != this.state.current_time) {
            if (
                this.props.current_tab &&
                this.props.current_tab != this.state.selected_tab
            ) {
                this.setState({
                    selected_tab: this.props.current_tab,
                    current_time: this.props.current_time,
                });
            }
        }
    }

    select_tab(name) {
        this.setState({ selected_tab: name });

        if (this.props.change_tab) {
            this.props.change_tab(name);
        }
    }

    render() {
        let columns = Math.floor(12 / this.props.tab_names.length);
        if (this.props.tab_names.length == 5) {
            columns = '2_4';
        }

        const tabs = [];
        if (this.props.tab_header_start) {
            tabs.push(this.props.tab_header_start);
        }
        for (let index = 0; index < this.props.tab_names.length; index++) {
            const name = this.props.tab_names[index];
            tabs.push(
                <Tab
                    tab_class={this.props.tab_class}
                    columns={columns}
                    name={name}
                    selected_tab={this.state.selected_tab}
                    select_tab={this.select_tab}
                    only_show_current_name={this.props.only_show_current_name}
                />
            );
        }

        const tabs_content = [];
        Object.keys(this.props.tab_contents).forEach(key => {
            const tab_content = this.props.tab_contents[key];

            if (this.state.selected_tab == key) {
                tabs_content.push(
                    <div
                        className={`justify-content-center tab-content col-12 ${this.props.content_is_row}`}
                    >
                        {tab_content}
                    </div>
                );
            } else {
                tabs_content.push(
                    <div
                        style={{
                            opacity: '0',
                            height: '1px',
                            width: '1px',
                            overflow: 'hidden',
                        }}
                        className="justify-content-center tab-content col-12"
                    >
                        {tab_content}
                    </div>
                );
            }
        });

        let current_tab = null;
        if (this.props.only_show_current_name) {
            current_tab = (
                <div
                    className="col-12"
                    style={{
                        padding: '0px',
                        ...this.props.header_style,
                    }}
                >
                    <Header
                        size={4}
                        text={this.state.selected_tab}
                        style={{ marginBottom: '-10px' }}
                    />
                </div>
            );
        }

        return (
            <div className="tab-list row">
                {current_tab}
                <div
                    className={this.props.tab_container_class || 'row col-12'}
                    style={{ padding: '0px' }}
                >
                    {tabs}
                </div>
                <div className="tab-content-wrapper row col-12">
                    {tabs_content}
                </div>
            </div>
        );
    }
}
