export default function regexCheck(value) {
    if (value === '\\') {
        return false;
    }

    try {
        RegExp(value);
    } catch (e) {
        return false;
    }

    if (value.indexOf('[]') > -1) {
        return false;
    }

    return true;
}
