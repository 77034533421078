import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { FormWithChildren, Button, EmptyModal, Select, Wrapper } from 'library';
import QuestionOnAssessment from './question_on_assessment.js';
import QuestionFieldTestGroup from './question_field_test_group.js';
import AllQuestions from './all_questions.js';
import { group } from 'd3';
import { sort_objects } from 'functions';

class QuestionsOnAssessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            choose_question: false,
            question_to_replace: null,
            order_slot: null,
            group_by: 'grade',
            scope_category: undefined,
        };

        this.update_filters = this.update_filters.bind(this);
        this.toggle_choose_question = this.toggle_choose_question.bind(this);
        this.choose_question = this.choose_question.bind(this);
        this.choose_question_callback =
            this.choose_question_callback.bind(this);
        this.change_group_order = this.change_group_order.bind(this);
    }

    update_filters(name, state) {
        this.setState(state);
    }

    toggle_choose_question(question_to_replace, order_slot, scope_category) {
        console.log('order: %d', order_slot);
        const new_state = {
            choose_question: !this.state.choose_question,
        };

        if (typeof question_to_replace !== 'undefined') {
            new_state.question_to_replace = question_to_replace;
        }

        if (typeof order_slot !== 'undefined') {
            new_state.order_slot = order_slot;
        }

        if (typeof scope_category !== 'undefined') {
            new_state.scope_category = scope_category;
        }
        else {
            new_state.scope_category = undefined;
        }

        this.setState(new_state);
    }

    choose_question(question) {
        const questions = this.props.questions_on_assessment;

        console.log('order_slot_final: %d', this.state.order_slot);

        if (this.state.question_to_replace) {
            var data = {
                question,
            };
            // Replace question for question on assessment
            this.setState(
                {
                    loading: true,
                },
                ajaxWrapper(
                    'POST',
                    `/api/home/questiononassessment/${this.state.question_to_replace.id}/`,
                    data,
                    this.choose_question_callback
                )
            );
        } else {
            let order = 0;
            if (this.state.order_slot != null) {
                order = this.state.order_slot;
            }
            else if (questions.length > 0) {
                const max = questions.reduce((prev, current) => (prev && prev.order > current.order) ? prev : current);
                order = max.order + 1;
            }

            var data = {
                order: order,
                question: question,
                assessment: this.props.assessment.id,
            };
            // Create new question on assessment
            this.setState(
                {
                    loading: true,
                },
                ajaxWrapper(
                    'POST',
                    '/api/home/questiononassessment/',
                    data,
                    this.choose_question_callback
                )
            );
        }
    }

    choose_question_callback(value) {
        this.props.full_refresh_assessment(() =>
            this.setState({
                choose_question: false,
                question_to_replace: null,
                loading: false,
                scope_category: undefined
            })
        );
    }

    change_group_order(first_group_order, second_group_order) {
        let data = {
            order_from: first_group_order,
            order_to: second_group_order
        }
        this.setState(
            {
                loading: true,
            },
            ajaxWrapper(
                'POST',
                `/change_question_order/${this.props.assessment.id}/`,
                data,
                this.choose_question_callback
            )
        );
    }

    render() {
        let questions_on_assessment = this.props.questions_on_assessment;
        const questions_on_assessment_jsx = [
            <div className="col-12">
                <div style={{ padding: '0px 15px' }}>
                    <Button
                        text="Add Question"
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={e => this.toggle_choose_question()}
                    />
                </div>
            </div>,
        ];

        let question_groups = {};
        questions_on_assessment.forEach((question) =>{
            let group = question_groups[question.order] || {order: question.order, questions_on_assessment: []};
            group.questions_on_assessment.push(question);
            question_groups[question.order] = group;
        });
        let question_groups_list = sort_objects(Object.values(question_groups),["order"])
        let category_stats = {}
        for (
            let index = 0;
            index < question_groups_list.length;
            index++
        ) {
            let group = question_groups_list[index];
            let category_candidate = group.questions_on_assessment[0].question.category.name;
            let category_mismatch = !group.questions_on_assessment.every((q) => q.question.category.name == category_candidate);

            if (!category_mismatch) {
                let category_stat = category_stats[category_candidate] || {name: category_candidate, count: 0};
                category_stat.count += 1;
                category_stats[category_candidate] = category_stat;
            }
      
            let previous_question = null;
            let next_question = null;
            if (index > 0) {
                previous_question = question_groups_list[index - 1];
            }
            if (index + 1 < question_groups_list.length) {
                next_question = question_groups_list[index + 1];
            }

            let is_field_test = category_candidate === "Field Test";
            if (category_mismatch) {
                questions_on_assessment_jsx.push("Broken question group!");
            }
            else if(is_field_test) {
                questions_on_assessment_jsx.push(
                    <QuestionFieldTestGroup
                    key={`qa_${group.questions_on_assessment[0].id}`}
                    group={group}
                    previous_group={previous_question}
                    next_group={next_question}
                    choose_question={this.toggle_choose_question}
                    refresh_assessment={this.props.refresh_assessment}
                    change_group_order={this.change_group_order}
                    disable_controls={!this.state.loading}
                    />
                )
            }
            else {
                let question_on_assessment = group.questions_on_assessment[0];
                questions_on_assessment_jsx.push(
                    <QuestionOnAssessment
                        key={`qa_${question_on_assessment.id}`}
                        question_on_assessment={question_on_assessment}
                        previous_group={previous_question}
                        next_group={next_question}
                        choose_question={this.toggle_choose_question}
                        refresh_assessment={this.props.refresh_assessment}
                        change_group_order={this.change_group_order}
                        disable_controls={!this.state.loading}
                    />
                );
            }
        }

        let category_stats_jsx = []
        for (const [key, category] of Object.entries(category_stats)) {
            category_stats_jsx.push(
                <li className="text-tight-spacing li-bullet-1">
                <span>
                    <b>{category.name}:</b>&nbsp;{category.count}
                </span>
                </li>
            )
        }

        let choose_question_modal = null;
        if (this.state.choose_question) {
            choose_question_modal = (
                <EmptyModal show onHide={e => this.toggle_choose_question()}>
                    <AllQuestions
                        questions={this.props.all_questions}
                        choose_question={this.choose_question}
                        filter_lists={this.props.filter_lists}
                        scope_category={this.state.scope_category}
                    />
                </EmptyModal>
            );
        }

        if (this.state.loading) {
            choose_question_modal = (
                <EmptyModal show>
                    <Wrapper loaded={false} />
                </EmptyModal>
            );
        }

        return (
            <div className="row col-12" style={{ padding: '10px 0px' }}>
                <div className="col-12 simple-card">
                    <h2>Question Category Stats</h2>
                    <ul className='bullet-wrapper'>
                        {category_stats_jsx}
                    </ul>
                </div>

                {questions_on_assessment_jsx}

                {choose_question_modal}

            </div>
        );
    }
}

export default QuestionsOnAssessment;
