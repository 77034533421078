import React, { Component } from 'react';

import Question from 'pages/reports/results_explorer/components/question.js';
import TabAutoSizer from 'pages/reports/results_explorer/components/tab_autosizer.js';

export default class QuestionOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standard_view: false,
            domain: '',
        };

        this.set_question = this.set_question.bind(this);
        this.select_domain = this.select_domain.bind(this);
    }

    set_question(id) {
        this.props.update_filters('question_details', { id });
    }

    select_domain(name) {
        this.setState({
            domain: name,
        });
    }

    render() {
        const global_state = window.cmState.getGlobalState();
        const qsd =
            this.props.data && this.props.data.questions
                ? Object.values(this.props.data.questions).sort(
                      (a, b) => a.order - b.order
                  )
                : [];
        // var questions = this.props.data.questions || {};
        const content = null;

        const knowledge = [];
        const application = [];
        const communication = [];

        const sections = {
            knowledge: [],
            application: [],
            communication: [],
        };
        const section_ids = {
            '334071cb-c9a7-4b19-af58-c0532fb1c699': 'knowledge',
            '99979bd2-935e-4915-85dc-b72d3fd002ce': 'application',
            'bcba4111-d2fc-4dc1-b19a-a1b843c6e8ee': 'communication',
        };

        const domain_names = ['All'];
        let order = 1;
        for (const question of qsd) {
            const { id } = question;
            const question_details = this.props.data.question_details[id];

            if (!question_details) {
                console.log('No Question Details!!', id);
                continue;
            }

            if (!this.props.data.district.question_details[id]) {
                console.log('No District Question Details');
                // continue;
            }

            let domain_found = false;
            for (const standard of question.standard_domains) {
                if (this.state.domain == standard.name) {
                    domain_found = true;
                }

                if (domain_names.indexOf(standard.name) > -1) {
                    continue;
                }

                // domain_names.push(standard.name);
            }

            if (this.state.domain && !domain_found) {
                continue;
            }

            console.log('Question', question.category, question.text);
            const section = section_ids[question.category];
            if (section) {
                console.log('Adding To Section', question.category, section);
                sections[section].push(
                    <div className="col-md-3 col-sm-12">
                        <Question
                            show_percentage={this.props.show_percentage}
                            setGlobalState={this.props.setGlobalState}
                            id={id}
                            card
                            question={question}
                            question_details={question_details}
                            question_order={order}
                            filters={this.props.filters}
                            update_filters={this.props.update_filters}
                            district={this.props.data.district}
                            set_question={this.set_question}
                            displayOrder={
                                this.props.filters.direct.assessments.length ===
                                1
                            }
                        />
                    </div>
                );

                order += 1;
            } else {
                console.log('No Section!', question.category);
            }
        }

        console.log('Sections', sections);

        const section_jsx = [];
        if (this.state.domain) {
            var header = this.state.domain;
            const all_sections = [];
            for (var key in sections) {
                all_sections.push(...sections[key]);
            }
            section_jsx.push(
                <div className="result-display-card">
                    <div className="h2 bold header">{header}</div>
                    <div className="row">{all_sections}</div>
                </div>
            );
        } else {
            for (var key in sections) {
                var header = key[0].toUpperCase() + key.slice(1);
                section_jsx.push(
                    <div className="result-display-card">
                        <div className="h2 bold header">{header}</div>
                        <div className="row">{sections[key]}</div>
                    </div>
                );
            }
        }

        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        gap: '6px',
                        alignItems: 'center',
                    }}
                />
                <TabAutoSizer
                    tabs={domain_names}
                    current={this.state.domain}
                    select_tab={this.select_domain}
                    side_navbar_open={this.props.side_navbar_open}
                    filters_open={this.props.filters_open}
                />

                {section_jsx}
            </div>
        );
    }
}
