import React, { Component } from 'react';
import { stripHtml } from 'functions';

import { Icon, Markdown } from 'library';

export default class WayToGetAnswerDropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    render() {
        const dropdown_style = {
            float: 'right',
            cursor: 'pointer',
        };

        let { text } = this.props;
        text +=
            '\n\nNote: There may be other procedures, ways of guessing or estimation to get this answer as well.';

        let dropdown = (
            <div style={{ float: 'right' }}>
                <Icon size={1} icon="chevron-down" />
            </div>
        );

        let closed = 'closed';
        if (this.state.open) {
            dropdown = (
                <div style={{ float: 'right' }}>
                    <Icon size={1} icon="chevron-up" />
                </div>
            );
            closed = '';
        }

        return (
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ open: !this.state.open })}
            >

                {dropdown}
                <div className={`way-to-get-dropdown-container ${closed}`}>
                    <Markdown text={text} />
                </div>
            </div>
        );
    }
}
