import React from 'react';

import { UserValidator } from 'functions';
import { Image, Icon, EmptyModal } from 'library';
import ContactUs from 'pages/reports/results_explorer/components/contact.js';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            help: false,
        };
    }

    render() {
        /*
        <Image src={'/static/images/reporting/Frame 114.svg'} className={'icon purple'} css={{margin: '18px 20px 18px 0px'}} />
        */

        const direct_style = {
            background: 'white',
            padding: '5px 10px',
            border: 'thin solid #6764FA',
            borderRadius: '4px',
            display: 'inline-block',
            color: '#6764FA',
            margin: '0px 10px 0px 0px',
        };

        const compare_style = {
            background: 'white',
            padding: '5px 10px',
            border: 'thin solid #B4B5D7',
            borderRadius: '4px',
            display: 'inline-block',
            color: '#B4B5D7',
            margin: '0px 10px 0px 0px',
        };

        const button_width = 48;
        const icons = 45 * 2;
        const width =
            this.props.width -
            this.props.page.length * 15 -
            36 -
            icons -
            button_width;

        const back_button = (
            <div
                className="orange-button"
                onClick={() => window.history.back()}
            >
                <Icon
                    size={1}
                    icon_type="fas"
                    icon="chevron-left"
                    style={{ width: '14px', textAlign: 'center' }}
                />
            </div>
        );

        let help = null;
        if (this.state.help) {
            help = (
                <EmptyModal show onHide={() => this.setState({ help: false })}>
                    <ContactUs />
                </EmptyModal>
            );
        }

        return (
            <div>
                <div style={{ float: 'right' }}>
                    <a onClick={() => this.setState({ help: true })}>
                        <Image
                            src="/static/images/reporting/question-3-circle.svg"
                            className="icon purple"
                            css={{ margin: '18px 20px 18px 0px' }}
                        />
                    </a>
                    <a href="/logout/">
                        <Image
                            src="/static/images/reporting/Sign Out, Right.svg"
                            className="icon purple"
                            css={{ margin: '18px 20px 18px 0px' }}
                        />
                    </a>
                </div>

                <div
                    className="h1"
                    style={{
                        margin: '0px',
                        padding: '18px',
                        display: 'inline-block',
                        verticalAlign: 'top',
                    }}
                >
                    {this.props.page}
                </div>
                <div
                    style={{
                        display: 'inline-block',
                        padding: '14px 18px 0px 0px',
                        verticalAlign: 'top',
                    }}
                >
                    {back_button}
                </div>
                <div
                    style={{
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        width: `${width}px`,
                        overflow: 'hidden',
                        paddingTop: '14px',
                    }}
                >
                    <div className="h2" style={direct_style}>
                        {this.props.text.overview}
                    </div>
                    <div className="h2" style={compare_style}>
                        {this.props.text.compared_to}
                    </div>
                    {new UserValidator().is_staff() && (
                        <a href="/work/">
                            <div
                                className="purple-button"
                                style={{
                                    display: 'inline-block',
                                    color: 'white',
                                    width: 'fit-content',
                                }}
                            >
                                Go to Admin / Analyst Page
                            </div>
                        </a>
                    )}
                </div>
                <div className="divider-line" />

                {help}
            </div>
        );
    }
}
