import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';

import { Button, Wrapper } from 'library';
import AnonDistrict from 'pages/assessments/components/anon_district.js';

class DistrictList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            districts: [],
        };

        this.get_districts = this.get_districts.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/sis/district/', {}, this.get_districts);
    }

    get_districts(result) {
        const districts = [];
        for (const index in result) {
            districts.push(result[index].district);
        }

        sort_objects(districts, ['name']);

        this.setState({
            districts,
            loaded: true,
        });
    }

    render() {
        let content = null;
        const districts = [];

        districts.push(
            <tr>
                <th>Name</th>
                <th>Anonymous Identifiers</th>
                <th>Edit</th>
            </tr>
        );

        for (const index in this.state.districts) {
            const district = this.state.districts[index];
            districts.push(
                <tr>
                    <td>{district.name}</td>
                    <td>
                        <AnonDistrict district={district} />
                    </td>
                    <td>
                        <Button
                            type="primary"
                            text="Edit"
                            href={`/edit_district/${district.id}/`}
                        />
                    </td>
                </tr>
            );
        }

        content = (
            <div className="container" style={{ textAlign: 'left' }}>
                <h2>Select A District</h2>
                <Button
                    text="Add A District"
                    type="success"
                    href="/edit_district/"
                />
                <div style={{ height: '20px' }} />
                <table className="table">{districts}</table>
            </div>
        );
        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default DistrictList;
