import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import {
    TextInput,
    NumberInput,
    Alert,
    FormWithChildren,
    Wrapper,
    Button,
} from 'library';

class WayOfThinking extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);
    }

    submit(state, callback) {
        console.log(state);

        const error_text = [];

        if (!('name' in state) || !state.name) {
            error_text.push('Please enter a name.');
        }

        if (!('order' in state) || state.order == 0) {
            error_text.push('Please set order value to something above 0.');
        }

        if (error_text.length > 0) {
            this.setState({
                error: error_text,
            });

            setTimeout(() => {
                callback({ success: true });
            }, 100);

            return false;
        }

        let url = '/api/home/wayofthinking/';
        let way_of_thinking = this.props.way_of_thinking;
        if (way_of_thinking) {
            url += `${way_of_thinking.id}/`;
        }

        this.setState({
            form_callback: callback,
        });
        ajaxWrapper('POST', url, state, this.submit_callback);
    }

    submit_callback() {
        let form_callback = this.state.form_callback;
        this.props.refresh_data();

        if (form_callback) {
            form_callback({ success: true });
        }

        this.setState({
            error: null,
            form_callback: null,
        });
    }

    render() {
        let content = null;
        let way_of_thinking = this.props.way_of_thinking;

        const urls = {
            // submitUrl: '/api/home/wayofthinking/',
        };
        let title = 'Add New';
        if (way_of_thinking) {
            title = way_of_thinking.name;
            // urls['submitUrl'] += way_of_thinking['id'] +'/';
            urls.deleteUrl = `/api/home/wayofthinking/${way_of_thinking.id}/delete/`;
        } else {
            let question_id = this.props.question_id;
            way_of_thinking = {
                question: question_id,
            };
        }

        const { error: error_state } = this.state;
        const error = error_state
            ? error_state.map(item => (
                  <Alert type="danger" key={item}>
                      {item}
                  </Alert>
              ))
            : [];

        const form_elements = [
            <h4>{title}</h4>,
            <TextInput name="name" label="Name" />,
            <NumberInput name="order" label="Order" />,

            <TextInput name="question" style={{ display: 'none' }} />,
        ];

        form_elements.push(...error);

        const form = (
            <div className="box-item">
                <FormWithChildren
                    key={this.props.form_key}
                    defaults={way_of_thinking}
                    submit={this.submit}
                    {...urls}
                    reset_state_on_submit
                >
                    {form_elements}
                </FormWithChildren>
            </div>
        );

        content = form;

        return content;
    }
}

class EditWaysOfThinkingKA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ways_of_thinking: [],
            component_options: [],
            loaded: false,
            form_key: 0,
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.ways_of_thinking_callback =
            this.ways_of_thinking_callback.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data() {
        let question_id = this.props.question_id;
        const url = `/api/home/wayofthinking/?question=${question_id}&order_by=order`;
        ajaxWrapper('GET', url, {}, this.ways_of_thinking_callback);
    }

    ways_of_thinking_callback(result) {
        const ways_of_thinking = result.map(
            way_of_thinking => way_of_thinking.wayofthinking
        );

        let form_key = this.state.form_key;
        this.setState({
            ways_of_thinking,
            loaded: true,
            form_key: form_key + 1,
        });
    }

    render() {
        const ways_of_thinking = [];
        const {
            scoring,
            scored: scoredState,
            ways_of_thinking: ways_of_thinkingState,
            component_options,
        } = this.state;
        const score_disabled = {};
        if (scoring) {
            score_disabled.disabled = 'disabled';
        }

        let question_id = this.props.question_id;
        let answer_options = this.props.answer_options;
        let answer_list = this.props.answer_list;
        ways_of_thinkingState.forEach(way_of_thinking => {
            ways_of_thinking.push(
                <WayOfThinking
                    key={way_of_thinking.id}
                    question_id={question_id}
                    way_of_thinking={way_of_thinking}
                    refresh_data={this.refresh_data}
                    component_options={component_options}
                    answer_options={answer_options}
                    answer_list={answer_list}
                />
            );
        });

        ways_of_thinking.push(
            <WayOfThinking
                key={'add_new_' + this.state.form_key}
                question_id={question_id}
                refresh_data={this.refresh_data}
                component_options={component_options}
                answer_options={answer_options}
                answer_list={answer_list}
                form_key={this.state.form_key}
            />
        );

        const content = (
            <div className="container">
                <h1>Ways Of Thinking:</h1>
                <Button
                    type="dark"
                    href={
                        '/student_response_grouping/' +
                        this.props.question_id +
                        '/'
                    }
                    text="Student Answers"
                />
                <div className="shadowbox">{ways_of_thinking}</div>
                <br />
                <br />
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default EditWaysOfThinkingKA;
