import React from 'react';

const BUTTON_VALUES = [
    ['Functions', 'AC', '÷'],
    [7, 8, 9, '×'],
    [4, 5, 6, '-'],
    [1, 2, 3, '+'],
    [0, '.', '='],
];

const SCIENTIFIC_BUTTON_VALUES = [
    ['Rad', 'Deg', 'x!', '(', ')', '%', 'AC'],
    ['Inv', 'sin', 'ln', 7, 8, 9, '÷'],
    ['π', 'cos', 'log', 4, 5, 6, '×'],
    ['e', 'tan', '√', 1, 2, 3, '-'],
    ['', '', 'x to the y', 0, '.', '=', '+'],
];

const SCIENTIFIC_POPOUT_VALUES = [
    ['(', ')', '%'],
    ['Rad', 'Deg', 'Inv'],
    ['sin', 'cos', 'tan'],
    ['π', '√', 'x to the y'],
    ['e', 'ln', 'log'],
];

const inverted_style = {
    whiteSpace: 'nowrap',
    fontSize: '.8em',
};
const INVERTED_BUTTONS = {
    sin: (
        <span style={inverted_style}>
            sin<sup>-1</sup>
        </span>
    ),
    cos: (
        <span style={inverted_style}>
            cos<sup>-1</sup>
        </span>
    ),
    tan: (
        <span style={inverted_style}>
            tan<sup>-1</sup>
        </span>
    ),
    ln: (
        <span>
            e<sup>x</sup>
        </span>
    ),
    log: (
        <span>
            10<sup>x</sup>
        </span>
    ),
    '√': (
        <span>
            x<sup>2</sup>
        </span>
    ),
    'x to the y': (
        <span>
            <sup>y</sup>√x
        </span>
    ),
};

function round(value, precision) {
    return (
        Math.round(
            parseFloat((value * 10 ** precision).toPrecision(precision))
        ) /
        10 ** precision
    );
}

export default function FunctionalCalculator() {
    this.state = {
        value: '',
        result: '',
        exponent: false,
        inverted: false,
        trig_mode: 'deg',
        popout: false,
    };

    this.setState = new_state => {
        Object.keys(new_state).forEach(key => {
            this.state[key] = new_state[key];
        });
    };

    this.input = key => {
        this.click(key);
    };

    this.click = value => {
        const current_value = this.state.value;
        const current_result = this.state.result;

        if (value === 'AC') {
            this.reset(value);
        } else if (['+-', '%'].indexOf(value) > -1) {
            let new_result;
            const evaluated_result = this.evaluate();
            if (!evaluated_result) {
                return false;
            }

            if (!this.check_value_for_number(evaluated_result)) {
                return false;
            }

            if (value === '+-') {
                new_result = evaluated_result * -1;
            } else if (value === '%') {
                new_result = evaluated_result / 100;
            }

            if (Number.isNaN(new_result)) {
                new_result = '';
            }

            this.setState({
                value: '',
                result: new_result,
            });
        } else if (value === '.') {
            let new_value = '';

            if (!current_value.includes('.')) {
                new_value = current_value + value;
            } else {
                const lastIndex = current_value.lastIndexOf('.');
                const after = current_value.slice(
                    lastIndex,
                    current_value.length
                );
                let found_non_number = false;
                Object.keys(after).forEach(char => {
                    if (Number.isNaN(char)) {
                        found_non_number = true;
                    }
                });

                if (found_non_number) {
                    new_value = current_value + value;
                }
            }

            this.setState({
                value: new_value,
            });
        } else if (value === '=') {
            let evaluated_result = '';
            if (this.state.exponent) {
                if (this.state.inverted) {
                    evaluated_result =
                        current_result **
                        (1 / eval(this.clean_string(current_value)));
                } else {
                    evaluated_result =
                        current_result **
                        eval(this.clean_string(current_value));
                }
            } else {
                evaluated_result = this.evaluate();
            }

            if (!evaluated_result) {
                return false;
            }

            this.setState({
                result: evaluated_result,
                value: '',
                exponent: false,
                error: null,
            });
        } else if (value === 'Rad') {
            this.setState({ trig_mode: 'rad' });
        } else if (value === 'Deg') {
            this.setState({ trig_mode: 'deg' });
        } else if (
            ['sin', 'cos', 'tan', 'ln', 'log', '√'].indexOf(value) > -1
        ) {
            this.math_functions(value);
        } else if (value == 'x!') {
            const evaluated_result = this.evaluate();
            if (!evaluated_result) {
                return false;
            }

            const new_result = this.factorialize(evaluated_result);
            this.setState({
                result: new_result,
                value: '',
            });
        } else if (value == 'x to the y') {
            const evaluated_result = this.evaluate();
            if (!evaluated_result) {
                return false;
            }

            this.setState({
                value: '',
                result: evaluated_result,
                exponent: true,
            });
        } else if (value == 'Inv') {
            this.setState({ inverted: !this.state.inverted });
        } else if (value == 'Functions') {
            this.setState({ popout: !this.state.popout });
        } else {
            this.add_charactor(value);
        }
    };

    this.check_value_for_number = value => {
        if (Number.isNaN(value)) {
            return false;
        }

        return true;
    };

    this.add_charactor = value => {
        const current_value = this.state.value;
        const last_character = current_value[current_value.length - 1];

        const operators = ['+', '-', '/', '*'];
        if (operators.indexOf(value) > -1) {
            if (operators.indexOf(last_character) > -1) {
                return false;
            }
            if (!last_character && !this.state.result) {
                return false;
            }
        }

        let new_value = current_value + value;
        let new_result = this.state.result;

        if (this.state.exponent) {
            // Do not modify result when inputting exponent;
        } else if (this.check_value_for_number(value) && current_value == '') {
            new_result = '';
        }

        if (current_value === '0' && value === '0') {
            new_value = '0';
        }

        if (String(current_value).length < 16) {
            this.setState({
                value: new_value,
                result: new_result,
            });
        }
    };

    this.math_functions = value => {
        let evaluated_result = this.evaluate();
        if (!evaluated_result) {
            return false;
        }

        let new_result = 0;

        if (this.state.inverted) {
            if (value == 'sin') {
                new_result = Math.asin(evaluated_result);
            } else if (value == 'cos') {
                new_result = Math.acos(evaluated_result);
            } else if (value == 'tan') {
                new_result = Math.atan(evaluated_result);
            } else if (value == 'ln') {
                new_result = Math.E ** evaluated_result;
            } else if (value == 'log') {
                new_result = 10 ** evaluated_result;
            } else if (value == '√') {
                new_result = evaluated_result ** 2;
            }

            if (
                ['sin', 'cos', 'tan'].indexOf(value) > -1 &&
                this.state.trig_mode == 'deg'
            ) {
                new_result *= 180 / Math.PI;
            }
        } else {
            if (
                ['sin', 'cos', 'tan'].indexOf(value) > -1 &&
                this.state.trig_mode == 'deg'
            ) {
                evaluated_result *= Math.PI / 180;
            }

            if (value == 'sin') {
                new_result = Math.sin(evaluated_result);
            } else if (value == 'cos') {
                new_result = Math.cos(evaluated_result);
            } else if (value == 'tan') {
                new_result = Math.tan(evaluated_result);
            } else if (value == 'ln') {
                new_result = Math.log(evaluated_result);
            } else if (value == 'log') {
                new_result = Math.log10(evaluated_result);
            } else if (value == '√') {
                new_result = Math.sqrt(evaluated_result);
            }
        }

        this.setState({
            value: '',
            result: new_result,
        });
    };

    this.factorialize = value => {
        if (value % 1 != 0) {
            return null;
        }
        if (value == 0) {
            return 1;
        }

        return value * this.factorialize(value - 1);
    };

    this.clean_string = value => {
        value = value.replaceAll('×', '*');
        value = value.replaceAll('÷', '/');
        value = value.replaceAll(/π/g, 'Math.PI');
        value = value.replaceAll(/e/g, 'Math.E');

        return value;
    };

    this.evaluate = () => {
        const current_result = this.state.result;
        let current_value = this.state.value;

        current_value = current_value.replaceAll('×', '*');
        current_value = current_value.replaceAll('÷', '/');
        current_value = current_value.replaceAll(/π/g, 'Math.PI');
        current_value = current_value.replaceAll(/e/g, 'Math.E');

        let new_result = '';

        if (this.state.exponent) {
            this.setState({
                error: 'Please Evaluate Your Expression First',
            });

            return null;
        }

        try {
            new_result = eval(current_result + current_value);
        } catch (event) {
            this.setState({
                error: 'Your Expression is Invalid',
            });
            return null;
        }

        return new_result;
    };

    this.reset = value => {
        this.setState({
            value: '',
            result: '',
            exponent: false,
            error: null,
        });
    };

    this.get_display_value = () => {
        let rounded_result = '';
        if (this.state.result) {
            rounded_result = round(this.state.result, 15);
        }

        const value_string = rounded_result + this.state.value;
        let value = [value_string];

        if (this.state.exponent) {
            if (this.state.inverted) {
                value = [<sup>{this.state.value}</sup>, '√', rounded_result];
            } else {
                value = [rounded_result, <sup>{this.state.value}</sup>];
            }
        }

        return value;
    };
}
