import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    Wrapper,
    TextInput,
    Alert,
    Button,
    PageBreak,
} from 'library';
import { intersect, get_valid_keys } from 'functions';

import QuestionDisplay from './question_display.js';
import QuestionForm from './question_form.js';

import TEXT_DICT from './translations.js';

export default class PrintableAssessment extends Component {
    constructor(props) {
        super(props);

        this.last_focus_event = null;

        this.state = {
            loaded: false,
            selected_language: this.props.selected_language || 'English',

            assessment: null,
            questions: [],
            question_answers: {},

            language_options: [],
        };

        this.load_context = this.load_context.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/proctor/load_context/${this.props.district_id}/${this.props.assessment_id}/`,
            {},
            this.load_context
        );
    }

    load_context(value) {
        let district = value['district'];
        let assessment = value['assessment'];
        let questions_on_assessment = assessment['questions_on_assessments'];

        const questions = [];
        const explanation_questions = {};

        let language_options = [];
        for (const key in questions_on_assessment[0]['questiononassessment'][
            'question'
        ].text_by_language) {
            language_options.push(key);
        }

        for (let item of questions_on_assessment) {
            let question_on_assessment = item['questiononassessment'];
            let question = question_on_assessment['question'];

            if (!question_on_assessment.active) {
                continue;
            }

            if (question.non_explanation_question_id) {
                explanation_questions[question.non_explanation_question_id] =
                    question;
            }

            // Get intersection of lists
            language_options = intersect(
                language_options,
                get_valid_keys(question.text_by_language)
            );
        }

        for (let item of questions_on_assessment) {
            var question_on_assessment = item['questiononassessment'];
            let question = question_on_assessment['question'];

            if (!question_on_assessment['active']) {
                continue;
            }

            // Map explanation questions to application questions
            if (question.id in explanation_questions) {
                question.explanation_question =
                    explanation_questions[question.id];
            }

            // Skip explanation questions
            if (question.non_explanation_question_id) {
                continue;
            }

            //Skip field test for printable assessments
            if (question.category.name == 'Field Test') {
                continue;
            }

            questions.push(question);

            // Check for languages in text
            language_options = intersect(
                language_options,
                get_valid_keys(question.text_by_language)
            );

            // Check for languages with either image or video
            const mixed_media_options = [
                ...new Set([
                    ...get_valid_keys(question.images_by_language),
                    ...get_valid_keys(question.videos_by_language),
                ]),
            ];
            language_options = intersect(language_options, mixed_media_options);
        }

        let new_state = {
            loaded: true,
            assessment: assessment,
            district: district,
            questions: questions,
            language_options: language_options,
        };

        console.log(new_state);
        this.setState(new_state);
    }

    render() {
        const lang = this.state.selected_language;

        let questions = [];
        for (let item of this.state.questions) {
            let question_display = (
                <QuestionDisplay
                    key={item.id}
                    language={lang}
                    question={item}
                    printable={true}
                />
            );

            let question_form = (
                <QuestionForm
                    key={item.id}
                    language={lang}
                    question={item}
                    question_answers={{}}
                    printable={true}
                />
            );

            questions.push(
                <div className="simple-card" style={{ breakInside: 'avoid' }}>
                    <div className="row">
                        <div className="col-5">{question_display}</div>
                        <div className="col-7">{question_form}</div>
                    </div>
                </div>
            );
        }

        let assessment_name = null;
        if (this.state.assessment) {
            assessment_name = (
                <div
                    style={{ lineHeight: '48px', fontSize: '22px' }}
                >{`${this.state.assessment.name} — ${this.state.district.name}`}</div>
            );
        }

        let new_language_button = null;

        let languages_block = null;
        let language_options = [];
        for (let option of this.state.language_options) {
            if (option == this.state.selected_language) {
                continue;
            }

            language_options.push(
                <Button
                    type="warning"
                    text={option}
                    href={`/printable_assessment/${this.props.district_id}/${this.props.assessment_id}/?language=${option}`}
                />
            );
        }
        if (language_options.length > 0) {
            languages_block = (
                <div>
                    <div style={{ margin: '12px 0px' }}>
                        <PageBreak />
                    </div>
                    <div>
                        This assessment also supports the following languages.
                        Click to view.
                        <div>{language_options}</div>
                    </div>
                </div>
            );
        }

        let content = (
            <div>
                <div className="simple-card no-print">
                    <Alert type="info">
                        <h2>Welcome to the printable Math ANEX Assessment!</h2>
                        <div>
                            <Button
                                style={{
                                    padding: '12px 40px',
                                    height: 'auto',
                                    fontSize: '20px',
                                }}
                                type="success"
                                text="Print Me!"
                                onClick={() => window.print()}
                            />
                        </div>
                        {languages_block}
                    </Alert>
                </div>
                <div className="simple-card">
                    <div style={{ float: 'right' }}>
                        <label
                            style={{
                                display: 'inline-block',
                                width: '100px',
                                textAlign: 'right',
                                marginRight: '10px',
                            }}
                        >
                            {'Name'}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            style={{
                                display: 'inline-block',
                                width: '220px',
                            }}
                            disabled={true}
                        />

                        <br />

                        <label
                            style={{
                                display: 'inline-block',
                                width: '100px',
                                textAlign: 'right',
                                marginRight: '10px',
                            }}
                        >
                            {'Date'}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            style={{
                                display: 'inline-block',
                                width: '220px',
                            }}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <div>
                            <img
                                src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                                style={{
                                    height: '30px',
                                    width: '30px',
                                    marginRight: '10px',
                                }}
                            />
                            <div style={{ display: 'inline-block' }}>
                                Math ANEX
                            </div>
                        </div>
                        <div>{assessment_name}</div>
                    </div>

                    <div>{new_language_button}</div>
                </div>

                {questions}
            </div>
        );

        if (
            this.state.language_options.length > 0 &&
            !this.state.language_options.includes(lang)
        ) {
            content = (
                <div className="simple-card">
                    <Alert type="danger">
                        <h3>
                            The assessment does not support the chosen language.
                            Please try a different language
                        </h3>
                        <Button
                            type="success"
                            text={'Go Back'}
                            href={`/printable_assessment/${this.props.district_id}/${this.props.assessment_id}/`}
                        />
                    </Alert>
                </div>
            );
        }

        return (
            <Wrapper
                style={{ marginTop: '0px', padding: '0px' }}
                loaded={this.state.loaded}
            >
                <div>
                    <Container
                        min_height
                        no_header
                        className="sad-simple-card-container"
                    >
                        {content}
                    </Container>
                </div>
            </Wrapper>
        );
    }
}
