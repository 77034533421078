import React, { Component } from 'react';

import ajaxWrapper from 'base/ajax.js';
import { Alert, Container, PageBreak, Button } from 'library';

export default class NewTeacher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sections: [],
            error: '',
        };

        this.teacher_callback = this.teacher_callback.bind(this);
    }

    componentDidMount() {
        const {user} = window.cmState;
        ajaxWrapper(
            'GET',
            `/api/sis/section/?teacher=${ 
                user.district_user.id 
                }&related=students`,
            {},
            this.teacher_callback
        );
    }

    teacher_callback(value) {
        this.setState({
            sections: value,
        });
    }

    render() {
        const {user} = window.cmState;

        const container_style = {
            background: 'white',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            padding: '0px',
        };

        const wrapper_style = {
            background: '#fafafa',
        };

        let error = null;
        if (this.state.error) {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        const sections = [];
        for (const item of this.state.sections) {
            const {section} = item;

            if (sections.length > 0) {
                sections.push(<PageBreak />);
            }

            sections.push(
                <div style={{ padding: '10px' }}>
                    <h5>{section.name}</h5>
                    <div>{section.students.length} students attending</div>
                </div>
            );
        }

        return (
            <div style={wrapper_style}>
                <Container min_height style={container_style}>
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            {error}
                            <h2>
                                Congratulations {user.first_name}{' '}
                                {user.last_name}, you have been imported to
                                MathAnex.
                            </h2>
                            <br />
                            <br />

                            <h4>
                                We have found the following sections under your
                                account:
                            </h4>
                            {sections}
                            <br />
                            <br />

                            <h4>
                                Data will begin to populate once your students
                                take an assessment. In the meantime, feel free
                                to explore our reporting dashboard.
                            </h4>
                            <Button
                                type="primary"
                                text="To Reporting Dashboard"
                                href="/report_navigator/"
                            />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
