import React, { Component } from 'react';

class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = { password: '', password_confirm: '' };
        this.verifyPassword = this.verifyPassword.bind(this);
    }

    verifyPassword = e => {
        const name = e.target.getAttribute('name');
        const newState = {};
        const value = e.target.value;
        let passwordsMatch = false
        if (value !== ""){
            if (name === "password_confirm"){
                passwordsMatch = (value === this.state.password)
            } else if (name === "password"){
                passwordsMatch = (value === this.state.password_confirm)
            }
        }
        newState[name] = value;

        this.setState(newState);
        e.disableSubmit = !passwordsMatch;
        this.props.handlechange(e);
    };

    render() {
        const layout = this.props.layout || '';

        var label = null;
        if (this.props.label) {
            label = <label>{this.props.label}</label>;
        }

        let passwordConfirm = <div />;
        if (this.props.confirm_password) {
            let valid = ' is-valid';
            if (this.state.password == '') {
                valid = ' is-blank';
                passwordConfirm = (
                    <div className={`form-group ${this.props.layout}`}>
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            className={`form-control${valid}`}
                            name="password_confirm"
                            onChange={e => this.verifyPassword(e)}
                            value={this.state.password_confirm}
                        />
                    </div>
                );
            } else if (this.state.password != this.state.password_confirm) {
                valid = ' is-invalid';
                passwordConfirm = (
                    <div className={`form-group ${this.props.layout}`}>
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            className={`form-control${valid}`}
                            name="password_confirm"
                            onChange={e => this.verifyPassword(e)}
                            value={this.state.password_confirm}
                        />
                        <div className="invalid-feedback">
                            Passwords Do Not Match!
                        </div>
                    </div>
                );
            } else {
                passwordConfirm = (
                    <div className={`form-group ${this.props.layout}`}>
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            className={`form-control${valid}`}
                            name="password_confirm"
                            onChange={e => this.verifyPassword(e)}
                            value={this.state.password_confirm}
                        />
                        <div className="valid-feedback">Passwords Match!</div>
                    </div>
                );
            }
        }

        return (
            <div>
                <div className={`form-group ${this.props.layout}`}>
                    {label}
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder={this.props.placeholder}
                        onChange={e => {
                            this.verifyPassword(e);
                        }}
                        value={this.state.password}
                    />
                </div>
                {passwordConfirm}
            </div>
        );
    }
}

export default PasswordInput;
