import React, { Component } from 'react';
import { Image } from 'library';

class Wrapper extends Component {
    componentDidMount() {}

    render() {
        const loading = (
            <div style={{ textAlign: 'center', width: '100%' }}>
                <Image
                    css={{ width: '100px' }}
                    src="/static/images/loading_transparent.gif"
                />
            </div>
        );

        let style = {
            paddingLeft: '0px',
            paddingRight: '0px',
            width: '100%',
            marginTop: '50px',
        };

        if (this.props.css) {
            style = Object.assign(style, this.props.css);
        } else if (this.props.style) {
            style = Object.assign(style, this.props.style);
        }

        if (!this.props.loaded) {
            style.marginTop = '50px';
        }

        let content = this.props.children;
        if (!content) {
            content = this.props.content;
        }

        return (
            <div className="container-fluid" style={style}>
                {this.props.loaded ? content : loading}
            </div>
        );
    }
}

export default Wrapper;
