import React, { Component } from 'react';

import { Button } from 'library';
import QuestionDisplay from 'pages/questions/image.js';

class QuestionOption extends Component {
    constructor(props) {
        super(props);

        this.choose_question = this.choose_question.bind(this);
    }

    choose_question() {
        this.props.choose_question(this.props.question.id);
    }

    render() {
        let question = null;
        if (this.props.question) {
            question = <QuestionDisplay question={this.props.question} />;
        }

        // var performance = <QuestionPerformance group_by_list={this.props.group_by_list} group_by={this.props.group_by} performance={this.props.performance} />;
        // {performance}

        const select_button = (
            <Button
                text="Select"
                type="success"
                onClick={this.choose_question}
            />
        );

        return (
            <div
                style={{
                    width: '100%',
                    padding: '20px',
                    margin: '20px',
                    border: 'solid #444 1px',
                }}
            >
                <div className="row col-12" style={{ padding: '0px' }}>
                    <div className="col-3">{question}</div>
                    <div className="row col-6 question-performance">
                        <a
                            href={`/edit_question/${this.props.question.id}/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {this.props.question.name}
                        </a>
                    </div>
                    <div className="col-3">{select_button}</div>
                </div>
            </div>
        );
    }
}

export default QuestionOption;
