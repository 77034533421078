import React from 'react';

class BrokenComponent extends React.Component {

    render() {
        const { children } = this.props;

        return children;
    }
}

export default BrokenComponent;
