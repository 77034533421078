import React from 'react';

function Nav() {
    const name = (
        <div>
            <img
                src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                style={{ height: '35px', width: 'auto' }}
                alt="Math ANEX"
            />
            <strong> Math ANEX</strong>
        </div>
    );

    let links = [];
    let welcome = null;
    const { user } = window.cmState;

    if (!user) {
        links = [['/logIn/', 'Log In']];
    } else {
        welcome = (
            <li className="nav-item">
                <span className="nav-link disabled" data-value="about">
                    Welcome, {window.cmState.user.first_name}.
                </span>
            </li>
        );

        if (user.role === 'Analyst') {
            links = [
                ['/work/', 'Ops Work'],
                [
                    'https://docs.google.com/document/d/1JKOYVLtm5gXIvatGN5byJ162z7nmR_cK2EiBw9XFIws/edit',
                    'Analyst Resource Guide',
                ],
            ];
        } else if (user.role === 'Manager Analyst') {
            const resources = (
                <div className="dropdown">
                    <button
                        className="nav-link dropdown-toggle"
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            outline: 'none',
                        }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Resources
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <a
                            className="dropdown-item"
                            target="_blank"
                            href="https://docs.google.com/document/d/1JKOYVLtm5gXIvatGN5byJ162z7nmR_cK2EiBw9XFIws/edit"
                            rel="noreferrer"
                        >
                            Analyst Resource Guide
                        </a>
                        <a
                            className="dropdown-item"
                            target="_blank"
                            href="https://docs.google.com/document/d/1lDc7ysAxVUYjI4MFGCceJpKReO2wWV5DtoD5rU2DTMI/edit"
                            rel="noreferrer"
                        >
                            Manager Resource Guide
                        </a>
                    </div>
                </div>
            );

            links = [
                ['/work/', 'Ops Work'],
                ['', 'Resources', resources],
            ];
        } else if (user.role === 'Super Admin') {
            links = [
                ['/work/', 'Ops Work'],
                ['/district_reports/', 'District Reports'],
                ['/manage_assessments/', 'Assessments'],
                ['/districts/', 'Districts'],
            ];
        }

        links.push(['/logout/', 'Log Out']);
    }

    if (window.cmState.params[0] === 'forcedpasswordreset') {
        links = [['/logout/', 'Log Out']];
    }

    const linkList = [];
    links.forEach(link => {
        if (link[1] === 'Resources') {
            linkList.push(<li className="nav-item">{link[2]}</li>);
        } else {
            linkList.push(
                <li className="nav-item">
                    <a className="nav-link" href={link[0]}>
                        {link[1]}
                    </a>
                </li>
            );
        }
    });

    return (
        <nav
            className="navbar navbar-expand-lg fixed-top navbar-light"
            style={{
                padding: '10px',
                backgroundColor: 'white',
                boxShadow: 'rgb(0 0 0 / 10%) 2px 2px 10px',
            }}
        >
            <a
                className="navbar-brand"
                href="/"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
                {name}
            </a>

            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>

            <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav mr-auto">
                    {welcome}
                    {linkList}
                </ul>
            </div>
        </nav>
    );
}

export default Nav;
