import React, { Component } from 'react';

import {} from 'library';

import QuestionAnswerDetailCard from 'pages/reports/results_explorer/components/question_answer_detail_card.js';

export default class AssessmentDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { student_assessment } = this.props;
        const { assessment } = student_assessment;

        const question_lookup = {};
        for (var item of assessment.questions_on_assessments) {
            var question = item.questiononassessment;
            question_lookup[question.question.id] = question;
        }

        const ordered_questions = [];
        const questions = [];
        for (var item of student_assessment.student_question_responses) {
            const question_response = item.studentquestionresponse;
            if (!question_lookup[question_response.question_id]) {
                continue;
            }

            var { question } = question_lookup[question_response.question_id];
            const { order } = question_lookup[question_response.question_id];

            questions.push({
                jsx: (
                    <QuestionAnswerDetailCard
                        key={question.id}
                        question_response={question_response}
                        question={question}
                        order={order}
                    />
                ),
                order,
            });
        }

        questions.sort((a, b) => (a.order > b.order ? 1 : -1));

        for (let j = 0; j < questions.length; j++) {
            ordered_questions.push(questions[j].jsx);
        }

        return <div className="row">{ordered_questions}</div>;
    }
}
