import React from 'react';
import { createRef } from 'react';
import { Button } from 'library';

class Audio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
        };

        this.play = this.play.bind(this);
        this.restart = this.restart.bind(this);

        this.audio_ref = createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                class: '',
            });
        }, 100);
    }

    play() {
        // You can use the play method as normal on your video ref
        this.audio_ref.current.play();
        this.setState({ playing: true });
    }

    restart() {
        this.audio_ref.current.load();
        this.setState({ playing: false });
    }

    render() {
        let content = (
            <audio
                controls
                autoPlay={this.props.autoPlay}
                style={this.props.style}
                ref={this.audio_ref}
            >
                <source src={this.props.src} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        );

        if (this.props.simple_player) {
            let button = (
                <Button
                    text={<i className="fas fa-play" />}
                    type="info"
                    onClick={this.play}
                />
            );
            if (this.state.playing) {
                button = (
                    <Button
                        text={<i className="fas fa-undo" />}
                        type="warning"
                        onClick={this.restart}
                    />
                );
            }
            content = (
                <div>
                    <div>{button}</div>
                    <div style={{ display: 'none' }}>{content}</div>
                </div>
            );
        }

        return content;
    }
}

export default Audio;
