import { ajaxWrapper } from 'base';
import React from 'react';

class BrokenPermissionComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/raise_permission_error/', {}, value =>
            this.setState({ value })
        );
    }

    render() {
        return <div></div>;
    }
}

export default BrokenPermissionComponent;
