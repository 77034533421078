import React, { useEffect, useState } from 'react';
import { sort_objects } from 'functions';

import ajaxWrapper from '../../base/ajax';
import {
    FormWithChildren,
    TextInput,
    TextArea,
    Wrapper,
    Select,
    EmptyModal,
    Button,
    Alert,
    PageBreak,
} from '../../library/index';
import Question from './components/Question';
import AllQuestions from './builder/all_questions';

const EditQuestionConcept = ({ question_concept_id }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [question_concept, setQuestionConcept] = useState();
    const [addNew, setAddNew] = useState(false);
    const [showAddQuestion, setShowAddQuestion] = useState(false);
    const [tags, setTags] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [question_lookup, setQuestionLookup] = useState([]);
    const [showAddTagModal, setAddTagModal] = useState(false);

    const sortAndSetTags = resultTags => {
        const orderedTags = sort_objects(resultTags, ['text']);
        setTags(orderedTags);
    };

    const loadTags = () => {
        ajaxWrapper('GET', '/api/home/tag/', {}, result => {
            const resultTags = result.map(item => ({
                text: item.tag.name,
                value: item.tag.id,
            }));
            sortAndSetTags(resultTags);
        });
    };

    const reloadTags = () => {
        loadTags();
        setAddTagModal(!showAddTagModal);
    };

    const createNewTag = (state, formCallback) => {
        const filteredTags = tags.filter(tag => tag.text === state.name);
        if (filteredTags.length === 0) {
            ajaxWrapper('POST', '/api/home/tag/', state, () => {
                reloadTags();
                formCallback();
            });
        } else {
            alert(`Tag ${state.name} already exists.`);
            formCallback();
        }
    };
    const question_related_models = [
        'category',
        'non_explanation_question',
        'questions_on_assessments',
        'questions_on_assessments__assessment',
        'questions_on_assessments__assessment__district',
    ];

    useEffect(() => {
        if (!question_concept_id) {
            setLoaded(true);
            setAddNew(true);
            loadTags();
        } else {
            const related = question_related_models
                .map(item => `question__${item}`)
                .join(',');
            ajaxWrapper(
                'GET',
                `/api/home/questionconcept/${question_concept_id}/?related=tags,question,${related}`,
                {},
                result => {
                    const question_concept = result[0].questionconcept;
                    const tagsIdList = question_concept.tags.map(
                        tag => tag.tag.id
                    );
                    question_concept['tags[]'] = tagsIdList;
                    delete question_concept.tags;

                    question_concept.parent_clone = question_concept.parent_clone_id;
                    delete question_concept.parent_clone_id;

                    setQuestionConcept(question_concept);
                    setLoaded(true);
                }
            );

            loadTags();
        }

        ajaxWrapper(
            'GET',
            `/api/home/question/`,
            {},
            questionResult => {
                let questions = [];
                let question_lookup = {};

                for (var item of questionResult){
                    questions.push(item.question);
                    question_lookup[item.question.id] = item.question;
                }

                setQuestions(questions);
                setQuestionLookup(question_lookup);
            }
        );

    }, []);

    const redirectPage = () => {
        // Do not delete. This will be used later to redirect user to question version page.
        // And also create one to modify.
        // if (addNew) {
        //     ajaxWrapper(
        //         'POST',
        //         '/api/home/question/',
        //         { category: '334071cb-c9a7-4b19-af58-c0532fb1c699' },
        //         (result) => {
        //             const questionId = result[0].question.id;
        //             window.location = `/edit_question/${questionId}/`;
        //         }
        //     );

        // } else {
        //     window.location = '/questions/';
        // }
        window.location = '/question_concepts/';
    };
    const turnOnModal = () => {
        setAddTagModal(!showAddTagModal);
    };

    let title = '';
    if (loaded) {
        title = question_concept_id
            ? `Edit Question: ${question_concept.name}`
            : 'Add New Question';
    }

    const submitUrl = question_concept_id
        ? `/api/home/questionconcept/${`${question_concept_id}/`}`
        : '/api/home/questionconcept/';
    // const submitUrl = "/api/home/questionconcept/" + question_concept_id + "/?related=tags";
    const deleteUrl = loaded && question_concept_id ? `${submitUrl}delete/` : null;

    const loadAnswers = question => {
        if (question.answers) return;
        ajaxWrapper(
            'GET',
            `/reporting/results_explorer/student_response_summary/${question.id}/`,
            {},
            result => {
                setQuestionConcept(prev => ({
                    ...prev,
                    question: prev.question.map(item =>
                        item.question.id === question.id
                            ? {
                                  ...item,
                                  question: {
                                      ...item.question,
                                      answers: result,
                                  },
                              }
                            : item
                    ),
                }));
            }
        );
    };

    const makeCopy = oldQuestion => {
        const question = { ...oldQuestion };
        const categoryId = question.category.id;
        const nonExplanationQuestionId = question.non_explanation_question.id;

        delete question.id;
        delete question.category;
        delete question.non_explanation_question;

        ajaxWrapper(
            'POST',
            `/api/home/question/?related=${question_related_models.join(
                ','
            )}`,
            {
                ...question,
                name: `(duplicate) ${question.name}`,
                category: categoryId,
                non_explanation_question: nonExplanationQuestionId,
                question_concept: question_concept.id,
            },
            result => {
                let copied_question = result[0].question;

                const associated_questions = [
                    ...question_concept.question,
                    { question: copied_question },
                ];

                let new_question_concept = {
                    ...question_concept,
                    question: associated_questions,
                };

                setQuestionConcept(new_question_concept);
            }
        );
    };

    const chooseQuestion = questionIndex => {
        const question = question_lookup[questionIndex];

        if (
            question_concept.question
                .map(question => question.question.id)
                .includes(question.id)
        ) {
            setError(
                <Alert
                    type="danger"
                    text="Question version already linked to this question"
                />
            );
            return;
        }
        
        ajaxWrapper(
            'POST',
            `/api/home/question/${
                question.id
            }/?related=${question_related_models.join(',')}`,
            {
                ...question,
                id: question.id,
                question_concept: question_concept.id,
                category: question.category_id,
                non_explanation_question:
                    question.non_explanation_question_id,
            },
            result => {
                setError(null);
                setQuestionConcept(prev => ({
                    ...prev,
                    question: [...prev.question, result[0]],
                }));
                setShowAddQuestion(false);
            }
        );
    };
    return (
        <Wrapper
            loaded={loaded}
            content={
                <div className="container">
                    <div>
                        <h2>{title}</h2>
                        <FormWithChildren
                            submitUrl={submitUrl}
                            deleteUrl={deleteUrl}
                            redirect={redirectPage}
                            defaults={question_concept}
                        >
                            <TextInput name="name" label="Name" />
                            <TextInput name="canva_link" label="Canva Link" />

                            <TextArea name="notes" label="General Notes" />
                            <Button
                                onClick={turnOnModal}
                                text="Create New Tag"
                                type="primary"
                                style={{ float: 'right', marginTop: '31px' }}
                            />
                            <Select
                                name="tags[]"
                                label="Tags"
                                multiple
                                options={tags}
                                style={{ marginRight: '200px' }}
                            />

                            <Select
                                name="parent_clone"
                                label="Parent Clone"
                                optionsUrl="/api/home/questionconcept/?order_by=-created_at"
                                optionsUrlMap={{
                                    text: '{questionconcept.name}',
                                    value: '{questionconcept.id}',
                                }}
                            />
                        </FormWithChildren>
                        <EmptyModal
                            show={showAddTagModal}
                            onHide={turnOnModal}
                            style={{ padding: '30px' }}
                        >
                            <div style={{ padding: '30px' }}>
                                <FormWithChildren
                                    submit={createNewTag}
                                    reset_state_on_submit
                                >
                                    <TextInput name="name" label="Name" />
                                </FormWithChildren>
                            </div>
                        </EmptyModal>
                        
                        <EmptyModal
                            show={showAddQuestion}
                            onHide={() => setShowAddQuestion(false)}
                        >
                            {error && <div>{error}</div>}
                            <AllQuestions
                                questions={questions}
                                choose_question={chooseQuestion}
                                group_by="grade"
                                filter_lists={{ grade: [] }}
                            />
                        </EmptyModal>
                    </div>
                                
                    <div style={{padding:'40px 0px 60px'}}><PageBreak/></div>

                    {question_concept && (
                        <div className='row'>
                            <div style={{width:'100%'}}>
                                {question_concept_id ? (
                                    <Button
                                        text="Link New Question Version"
                                        type="warning"
                                        onClick={() =>
                                            setShowAddQuestion(true)
                                        }
                                        style={{ float:'right' }}
                                    />
                                ) : (
                                    <></>
                                )}

                                <h2>Question Versions</h2>
                            </div>
                            {question_concept.question.map(
                                ({ question }) => (
                                    <Question
                                        key={question.id}
                                        loadAnswers={loadAnswers}
                                        makeCopy={makeCopy}
                                        question={question}
                                    />
                                )
                            )}
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default EditQuestionConcept;
