import React, { Component } from 'react';

import { Container } from 'library';

export default class ClasslinkInvalid extends Component {
    render() {
        const container_style = {
            background: 'white',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            padding: '0px',
        };

        const wrapper_style = {
            background: '#fafafa',
        };

        return (
            <div style={wrapper_style}>
                <Container min_height style={container_style}>
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            <h1>
                                Sorry your credentials we're not found in our
                                system. Please contact support at
                                support@mathanex.com
                            </h1>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
