import React, { Component } from 'react';
import {
    If,
    Alert,
    TextInput,
    Button,
    EmptyModal,
    FormWithChildren,
    Header,
} from 'library';

class JsonInput extends Component {
    static component_name = 'JsonInput';

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            current_val: '',
            rows: [],
        };

        this.handle_change = this.handle_change.bind(this);
        this.show_modal = this.show_modal.bind(this);
        this.hide_modal = this.hide_modal.bind(this);
        this.add_row = this.add_row.bind(this);
        this.delete_row = this.delete_row.bind(this);
        this.build_json = this.build_json.bind(this);
        this.set_json_state = this.set_json_state.bind(this);
    }

    handle_change = e => {
        const name = e.target.getAttribute('name');
        const newState = {};
        const { value } = e.target;

        if (value == '') {
            newState[name] = value;
            this.setState({ error: null }, this.props.setFormState(newState));
        } else {
            try {
                newState[name] = JSON.parse(value);
                this.setState(
                    { error: null },
                    this.props.setFormState(newState)
                );
            } catch (err) {
                this.setState({ error: err.toString(), current_val: value });
            }
        }
    };

    show_modal() {
        let json = this.props.value;
        if (typeof json === 'string') {
            try {
                json = JSON.parse(json);
            } catch {
                json = {};
            }
        }

        let rows = [];
        Object.keys(json).forEach(index => {
            const temp_dict = { key: index, value: json[index] };
            rows.push(temp_dict);
        });

        if (rows.length == 0) {
            rows = [{ key: '', value: '' }];
        }
        this.setState({ modal: true, rows });
    }

    hide_modal() {
        this.setState({ modal: false });
    }

    add_row() {
        const { rows } = this.state;
        rows.push({ key: '', value: '' });
        this.setState({ rows });
    }

    delete_row() {
        const { rows } = this.state;
        rows.pop();
        this.setState({ rows });
    }

    build_json() {
        const new_json = {};
        Object.keys(this.state.rows).forEach(index => {
            const row = this.state.rows[index];
            if (row.key != '') {
                new_json[row.key] = row.value;
            }
        });

        const newState = {};
        newState[this.props.name] = new_json;

        this.setState(
            { error: null, modal: false },
            this.props.setFormState(newState)
        );
    }

    set_json_state(name, state) {
        const { rows } = this.state;
        Object.keys(state).forEach(key => {
            if (key.startsWith('key_')) {
                const row = parseInt(key.substring(4, key.length), 10);
                rows[row].key = state[key];
            } else if (key.startsWith('value_')) {
                const row = parseInt(key.substring(6, key.length), 10);
                rows[row].value = state[key];
            }
        });
        this.setState({ rows });
    }

    render() {
        const rows = [
            <Header size={3} className="col-6" text="Key" />,
            <Header size={3} className="col-6" text="Value" />,
        ];
        Object.keys(this.state.rows).forEach(index => {
            const row = this.state.rows[index];
            rows.push(
                <TextInput
                    className="col-6"
                    name={`key_${index}`}
                    default={row.key}
                />
            );
            rows.push(
                <TextInput
                    className="col-6"
                    name={`value_${index}`}
                    default={row.value}
                />
            );
        });

        let layout = '';
        if (this.props.className) {
            layout = this.props.className;
        }

        let label = null;
        if (this.props.label && this.props.label != '') {
            label = <label>{this.props.label}</label>;
        }

        const json =
            typeof this.props.value === 'string'
                ? this.props.value
                : JSON.stringify(this.props.value);

        let remove_row = null;
        if (this.state.rows.length > 0) {
            remove_row = (
                <Button
                    type="danger"
                    text="Delete Row"
                    className="col-4"
                    onClick={this.delete_row}
                />
            );
        }

        return (
            <div className={`form-group ${layout}`}>
                {label}
                <textarea
                    className="form-control"
                    name={this.props.name}
                    rows={this.props.rows}
                    onChange={this.handle_change}
                    onBlur={this.props.onBlur}
                    value={json}
                    placeholder={this.props.placeholder}
                />
                <Button
                    text="Json Builder"
                    onClick={this.show_modal}
                    type="primary"
                />
                <If logic={this.state.error}>
                    <Alert text={this.state.error} type="danger" />
                </If>

                <EmptyModal onHide={this.hide_modal} show={this.state.modal}>
                    <div className="container" style={{ padding: '10px' }}>
                        <Header text="Build Your Json" />
                        <FormWithChildren
                            row
                            className="row"
                            autoSetGlobalState
                            setGlobalState={this.set_json_state}
                            globalStateName="json"
                            dont_resolve_anything={
                                this.props.dont_resolve_anything
                            }
                        >
                            {rows}
                        </FormWithChildren>
                        <div className="row">
                            <Button
                                type="success"
                                text="Add Row"
                                className="col-4"
                                onClick={this.add_row}
                            />
                            {remove_row}
                            <Button
                                type="primary"
                                text="Generate JSON"
                                className="col-4"
                                onClick={this.build_json}
                            />
                        </div>
                    </div>
                </EmptyModal>
            </div>
        );
    }
}

export default JsonInput;
