import $ from 'jquery';

function ajaxWrapperFile(type, url, data, returnFunc) {
    if (type === 'POST') {
        data.append(
            'csrfmiddlewaretoken',
            window.secretReactVars.csrfmiddlewaretoken
        );
    }

    // url = "http://localhost:8000" + url;
    let SUCCESS = false;
    let AJAXING = true;

    $.ajax({
        type,
        url,
        data,

        dataType: 'json',
        processData: false,
        contentType: false,

        success(value) {
            AJAXING = false;
            SUCCESS = true;
            returnFunc(value);
        },
        error(xhr, _status, _error) {
            AJAXING = false;
            console.log(xhr.responseText);
        },
    });
}

export default ajaxWrapperFile;
