import React, { useEffect, useState } from 'react';
import { sort_objects } from 'functions';
import ajaxWrapper from 'base/ajax.js';

import { Button, Wrapper, ClickableContent } from 'library';
import Question from './question_concept';

export default () => {
    const [loaded, setLoaded] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleSelect = event => {
        const selectedTag = tags.filter(
            ({ tag }) => tag.id === event.target.value
        );

        if (!selectedTags.includes(selectedTag[0])) {
            setSelectedTags([...selectedTags, ...selectedTag]);
        }
    };

    const handleRemove = tag =>
        setSelectedTags(selectedTags.filter(({ tag: t }) => t.id !== tag.id));

    const filterQuestions = () => {
        if (selectedTags.length === 0) return questions;

        const filteredQuestions = [];
        questions.forEach(question => {
            const questionTags = question.tags.map(({ tag }) => tag.id);
            const selectedTagIds = selectedTags.map(({ tag }) => tag.id);

            if (selectedTagIds.every(tag => questionTags.includes(tag))) {
                filteredQuestions.push(question);
            }
        });

        return filteredQuestions;
    };

    useEffect(() => {
        ajaxWrapper('GET', '/api/home/questionconcept/?related=tags', {}, result => {
            const data = [];
            for (let item of result) {
                data.push(item.questionconcept);
            }

            sort_objects(data, ['created_at']);

            setQuestions(data);
            setLoaded(true);
        });
        ajaxWrapper('GET', '/api/home/tag/', {}, result => setTags(result));
    }, []);

    const content = (
        <div className="container">
            <div>
                <Button
                    type="success"
                    href="/edit_question_concept/"
                    text="Add New Question"
                    style={{ float: 'right' }}
                />
                <h2>Questions (Items)</h2>
            </div>

            <div>
                <label htmlFor="tags">Filter by tag</label>
                <select
                    name="tags"
                    id="tags"
                    className="form-control"
                    onChange={handleSelect}
                    defaultValue="Select a tag"
                >
                    <option value="Select a tag" disabled>
                        Select a tag
                    </option>
                    {tags?.map(({ tag }) => (
                        <option value={tag.id} key={tag.id}>
                            {tag.name}
                        </option>
                    ))}
                </select>
                <div>
                    {selectedTags?.map(({ tag }) => (
                        <ClickableContent
                            key={tag.id}
                            className="selection"
                            style={{
                                background: '#6764fa',
                                borderRadius: '5px',
                                marginRight: '5px',
                                color: 'white',
                                padding: '2px 6px',
                                margin: '10px 0px 10px 4px',
                                cursor: 'pointer',
                            }}
                            show_close
                            onClick={() => handleRemove(tag)}
                        >
                            {/* {JSON.stringify(tag)} */}
                            <div style={{ display: 'inline-block' }}>
                                {tag.name}
                            </div>
                        </ClickableContent>
                    ))}
                </div>
            </div>

            <table className="table" style={{ marginTop: '0.5rem' }}>
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>Name</th>
                        <th style={{ width: '35%' }}>Notes</th>
                        <th style={{ width: '20%' }}>Tags</th>
                        <th style={{ width: '5%' }}>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {filterQuestions().map(question => (
                        <Question key={question.id} question={question} />
                    ))}
                </tbody>
            </table>
        </div>
    );

    return <Wrapper loaded={loaded} content={content} />;
};
