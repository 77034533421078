import React, { Component } from 'react';

import { Button, PageBreak } from 'library';

import QuestionCard from 'pages/questions/question_card.js';
import TEXT_DICT from './translations.js';

class GoToQuestion extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const lang = this.props.language;
        const { index } = this.props;

        const type = this.props.type || 'primary';
        const question_button = (
            <Button
                type={type}
                text={TEXT_DICT['Go to Question'][lang]}
                onClick={() => this.props.set_question(index)}
                style={{ margin: '10px' }}
            />
        );

        /*
        if (!this.props.assessment_completed) {
            return (
                question_button
            )
        }
        else {
            return (
                <QuestionCard question={this.props.question} view={question_button} info={this.props.info} lang={lang} />
            )
        }
        */

        return (
            <QuestionCard
                question={this.props.question}
                view={question_button}
                info={this.props.info}
                lang={lang}
            />
        );
    }
}

export default class ReviewQuestions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const lang = this.props.language;
        const questions = [];

        let review_questions = this.props.questions;
        if (this.props.skipped_only) {
            review_questions = this.props.skipped_questions;
        }

        const no_answer_questions = 0;
        for (let index = 0; index < review_questions.length; index++) {
            let context = null;
            let type = null;

            const question = review_questions[index];

            const full_index = this.props.questions.indexOf(question);

            if (!this.props.skipped_only) {
                if (this.props.skipped_questions.indexOf(question) > -1) {
                    context = <span>{TEXT_DICT.Skipped[lang]}</span>;
                    type = 'warning';
                } else {
                    let answer = null;
                    if (question.id in this.props.question_answers) {
                        answer =
                            this.props.question_answers[question.id].answer;
                    }

                    var explanation_answer = null;
                    if (question.explanation_question) {
                        const { explanation_question } = question;
                        if (
                            this.props.question_answers[explanation_question.id]
                        ) {
                            // var explanation_answer = <span><br></br>Explanation: {this.props.question_answers[explanation_question.id].answer}</span>
                        } else {
                            var explanation_answer = (
                                <span>{TEXT_DICT.Skipped[lang]}</span>
                            );
                        }
                    }

                    if (answer == undefined) {
                        context = <span>{TEXT_DICT.Skipped[lang]}</span>;
                        type = 'warning';
                    } else {
                        // context = <span><br></br>{'Answered: ' + answer}{explanation_answer}</span>;
                    }
                }
            }

            if (question.answer_type == 'no_answer') {
                /*
                if (!this.props.skipped_only) {
                    questions.push(<div className='card col-md-3'><p>Question #{full_index + 1} was just for context, there is no answer.</p></div>);
                }
                */
            } else {
                questions.push(
                    <GoToQuestion
                        key={question.id}
                        question={question}
                        language={lang}
                        type={type}
                        index={full_index}
                        set_question={this.props.set_question}
                        assessment_completed={this.props.assessment_completed}
                        info={context}
                    />
                );
            }
        }

        let header_text = TEXT_DICT['Skipped Questions:'][lang];
        if (!this.props.skipped_only) {
            header_text = TEXT_DICT['Questions:'][lang];
        }

        let skip_to_review_page = null;
        if (this.props.completed_once && !this.props.assessment_completed) {
            skip_to_review_page = (
                <Button
                    type="warning"
                    text={TEXT_DICT['Go Back To Review Page'][lang]}
                    onClick={this.props.skip_to_review_page}
                    style={{ margin: '10px' }}
                />
            );
        }

        let complete_assessment_button = null;
        if (this.props.assessment_completed) {
            complete_assessment_button = (
                <Button
                    type="success"
                    onClick={this.props.setSubmitted}
                    text={TEXT_DICT['Submit Assessment'][lang]}
                    style={{
                        margin: '10px',
                        minWidth: '50%',
                        fontSize: '20px',
                        padding: '25px 0px',
                    }}
                />
            );
        }

        let questions_jsx = <div />;
        if (questions.length > 0 && !this.props.student_assessment.completed) {
            questions_jsx = <div>
                <PageBreak style={{ margin: '40px 0px' }} />
                <h4>{header_text}</h4>
                <div className="row">
                    <div className="col-12 row">{questions}</div>
                    <div className="col-12 row">{skip_to_review_page}</div>
                </div>

                <div style={{ textAlign: 'center', margin: '40px 0px' }}>
                    {complete_assessment_button}
                </div>
            </div>
        }

        return questions_jsx;
    }
}
