import { resolveVariables, ajaxWrapper } from 'functions';

function runFunctions(functions, setState, setGlobalState) {
    Object.keys(functions).forEach(index => {
        console.log('Running Function', functions[index], functions);
        const tempFunction = functions[index];
        const { values } = resolveVariables(
            { values: tempFunction[1] },
            window.cmState.getGlobalState()
        );

        if (tempFunction[0] === 'ajaxWrapper') {
            for (let i = 0; i <= index; i + 1) {
                functions.shift();
            }
            ajaxWrapper(
                values.type,
                values.url,
                values.data,
                runFunctions,
                null,
                [functions, setState, setGlobalState]
            );
        } else if (tempFunction[0] === 'setState') {
            setState(values);
        } else if (tempFunction[0] === 'setGlobalState') {
            Object.keys(values).forEach(key => {
                window.cmState.setGlobalState(key, values[key]);
            });
        } else if (tempFunction[0] === 'redirect') {
            window.location = values;
        }
    });
}

export default runFunctions;
