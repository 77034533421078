import React, { Component } from 'react';

import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Button, Alert } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';
import EditApproach from 'pages/approach_analysis/edit_approach.js';
import EditComponent from 'pages/approach_analysis/edit_component.js';

export default class AutomatedRequestList extends Component {
    constructor(props) {
        super(props);
        this.state = { automated_requests: [], loaded: false };

        this.get_automated_requests = this.get_automated_requests.bind(this);
        this.update_automated_request =
            this.update_automated_request.bind(this);
    }

    componentDidMount() {
        this.get_automated_requests();
    }

    get_automated_requests() {
        ajaxWrapper(
            'GET',
            '/api/home/automationrequest/?order_by=-created_at&related=component,component__approach',
            {},
            this.update_automated_request
        );
    }

    update_automated_request(result) {
        this.setState({ automated_requests: result });
    }

    render() {
        var automation_rows = [];
        for (var request of this.state.automated_requests) {
            var auto = request.automationrequest;

            let question_id = auto.component.approach.question_id;
            let component_id = auto.component.id;
            let component_name = auto.component.name;

            var row = (
                <tr>
                    <td>
                        <a
                            href={
                                '/scoring_configuration/component_automation_mapping/' +
                                question_id +
                                '/' +
                                component_id +
                                '/'
                            }
                            target="_blank"
                        >
                            {component_name}
                        </a>
                    </td>
                    <td>{auto.created_at}</td>
                    <td>{auto.started}</td>
                    <td>{auto.finished}</td>
                    <td>{auto.error}</td>
                </tr>
            );

            automation_rows.push(row);
        }

        return (
            <div className="container">
                <h3 style={{ marginTop: '50px' }}>Automated Request List</h3>
                <table className="table">
                    <tr>
                        <th>Component</th>
                        <th>Request Time</th>
                        <th>Start Time</th>
                        <th>Finish Time</th>
                        <th>Error</th>
                    </tr>
                    {automation_rows}
                </table>
            </div>
        );
    }
}
