import React, { Component } from 'react';

import { Button } from 'library';

class Link extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const style = { color: '#007bff' };
        if (this.props.page == this.props.current_page) {
            style.fontWeight = 'bold';
        }

        let text = <span>{this.props.display_name}</span>;
        if (this.props.href) {
            text = (
                <a className="nav-link" style={style} href={this.props.href}>
                    {this.props.display_name}
                </a>
            );
        }

        return <li className="nav-item">{text}</li>;
    }
}

export default class Navbar extends Component {
    render() {
        let assessment_name = null;
        if (this.props.assessment) {
            assessment_name = (
                <Link
                    display_name={`${this.props.assessment.name} — ${this.props.district.name}`}
                />
            );
        }

        let new_language_button = null;
        if (this.props.selected_language) {
            new_language_button = (
                <div style={{ float: 'right' }}>
                    <Button
                        type="info"
                        text={
                            <div>
                                <i class="fas fa-question-circle"></i>
                                <div
                                    style={{
                                        marginLeft: '10px',
                                        display: 'inline-block',
                                    }}
                                >
                                    Choose a new language
                                </div>
                            </div>
                        }
                        href={window.location.pathname}
                    ></Button>
                </div>
            );
        }

        return (
            <nav
                className="navbar navbar-expand-lg fixed-top"
                style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    boxShadow: '2px 2px 10px rgb(0, 0, 0, .1)',
                }}
            >
                <div className="navbar-nav mr-auto">
                    <a
                        className="navbar-brand"
                        href="/"
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                    >
                        <div>
                            <img
                                src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                                style={{ height: '30px', width: '30px' }}
                            />
                            <strong>Math ANEX</strong>
                        </div>
                    </a>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div
                        className="collapse navbar-collapse "
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav mr-4">{assessment_name}</ul>
                    </div>
                </div>

                <div className="navbar-nav ml-auto">{new_language_button}</div>
            </nav>
        );
    }
}
