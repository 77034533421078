import React from 'react';
import { Checkbox } from 'library';

class CheckGroup extends React.Component {
    handleChange(e) {
        const selection = e.currentTarget.value;
        const newState = {};

        let value = [];
        if (this.props.value && typeof this.props.value !== 'undefined') {
            value = this.props.value;
        }
        const index = value.indexOf(selection);
        if (index === -1) {
            value.push(selection);
        } else {
            value.splice(index, 1);
        }

        newState[this.props.name] = value;

        this.props.setFormState(newState);
    }

    render() {
        let value = [];
        if (this.props.value && typeof this.props.value !== 'undefined') {
            value = this.props.value;
        }

        const type = ` btn-${this.props.type}`;

        let layout = '';
        if (this.props.layout) {
            layout = `form-group ${this.props.layout}`;
        }

        let label = null;
        if (this.props.label && this.props.label != '') {
            label = <label>{this.props.label}</label>;
        }

        const buttons = [];
        const css = {};

        Object.keys(this.props.options).forEach(index => {
            let checked = false;
            if (value.indexOf(this.props.options[index]) > -1) {
                checked = true;
            }

            buttons.push(
                <Checkbox
                    name={this.props.name}
                    checked={checked}
                    label={this.props.options[index]}
                    value={this.props.options[index]}
                    onChange={this.handleChange}
                />
            );
        });

        return (
            <div className={layout} style={{ display: 'block' }}>
                {label}
                {buttons}
            </div>
        );
    }
}

export default CheckGroup;
