import React, { Component } from 'react';
import { sort_objects, formatDateString, format_time_difference } from 'functions';
import ajaxWrapper from 'base/ajax.js';

import { Button, FormWithChildren, Select, Wrapper, Accordion } from 'library';
import QuestionImage from 'pages/questions/image.js';

class CronActivity extends Component {
    constructor(props) {
        super(props);
    }

    render () {
        let cron = this.props.cron;

        let started = new Date(cron.created_at);
        let elapsed = 0;

        if (cron.end_date){
            let finished = new Date(cron.end_date);
            elapsed = finished.getTime() - started.getTime();
        }
        
        return (
            <div>
                <div>{cron.command_name}</div>
                <div>{formatDateString(started, 'mm/dd/yyyy')}</div>
                
                <div>{format_time_difference(elapsed)}</div>

                <div>{cron.completed}</div>
            </div>
        )
    }
}

class CronActivityList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            crons: [],
            cron_options: [],
        };

        this.load_activity = this.load_activity.bind(this);
    }

    componentDidMount() {
        const url = '/api/modelWebsite/cronactivity/?limit=250&order_by=-created_at';

        ajaxWrapper('GET', url, {}, this.load_activity);
    }

    load_activity(result) {
        const crons = [];
        let command_names = [];

        for (let item of result) {
            crons.push(item.cronactivity);
            
            let command_parts = item.cronactivity.command_name.split(' ');
            if (command_parts.length > 1){
                command_names.push(command_parts[1]);
            }
        }

        command_names = new Set(command_names);
        let cron_options = []
        command_names.forEach(item => {
            cron_options.push({text: item, value: item});
        });

        this.setState({
            crons: crons,
            cron_options: cron_options,
            loaded: true,
        });
    }

    render() {
        const { loaded } = this.state;

        let activity = [];
        for (let cron of this.state.crons){
            if (this.state.command_name && !(cron.command_name.includes(this.state.command_name))){
                continue;
            }
            activity.push(<div className='simple-card'><CronActivity cron={cron} /></div>);
        }

        return <Wrapper loaded={loaded} >
            <div className="container">
                <div><h3>{`Cron Activity Dashboard`}</h3></div>
                <div>
                    <Button type='info' text='Clever Sync' href='/cron_activity/clever/' />
                    <Button type='info' text='Classlink Sync' href='/cron_activity/classlink/' />
                </div>
                <FormWithChildren
                    autoSetGlobalState
                    setGlobalState={(name, state) => this.setState(state)}
                    globalStateName="crons"
                    row
                >
                    <div className="col-12">
                        <Select
                            options={this.state.cron_options}
                            name="command_name"
                            label="Commands"
                        />
                    </div>
                </FormWithChildren>

                <div className='sad-simple-card-container' >
                    {activity}
                </div>
            </div>
        </Wrapper>;
    }
}

export default CronActivityList;
