import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Image } from 'library';

import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';
import ListControls from 'pages/reports/results_explorer/components/list_controls.js';

const OFFSET_CHANGE = 100;

export default class Participation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [],
            rows: [],
            total: 0,

            offset: 0,

            loading: true,
            loaded: false,

            csv_data: [],
            csv_loading: '',
            questions: [],

            force_schools: false,
            force_students: false,
        };

        this.get_student_filters = this.get_student_filters.bind(this);
        this.get_students = this.get_students.bind(this);
        this.student_participation_callback =
            this.student_participation_callback.bind(this);

        this.change_offset = this.change_offset.bind(this);

        this.csv_download = this.csv_download.bind(this);

        this.csv_button = React.createRef();
    }

    componentDidMount() {
        this.get_students();

        window.cmState.setGlobalState(
            'download_reporting_data',
            this.csv_download
        );
    }

    get_student_filters() {
        const data = {
            filters: this.props.filters,
            compare_filters: {},
            offset: this.state.offset,
            limit: OFFSET_CHANGE,
        };

        if (this.state.force_schools) {
            data.force_schools = this.state.force_schools;
        } else if (this.state.force_students) {
            data.force_students = this.state.force_students;
        }

        return data;
    }

    get_students() {
        const { district_id } = this.props;

        const data = this.get_student_filters();

        this.setState({ loading: true });

        console.log('Data', data);

        ajaxWrapper(
            'POST',
            `/reporting/results_explorer/student_participation/${this.props.district_id}/`,
            data,
            this.student_participation_callback
        );
    }

    student_participation_callback(result) {
        this.setState({
            headers: result.headers,
            rows: result.data,
            total: result.total,
            includes_context_total: result.includes_context_total,
            loading: false,
            loaded: true,
        });
    }

    change_offset(absolute, change) {
        if (absolute > -1) {
            this.setState(
                {
                    offset: absolute,
                    loading: true,
                },
                this.get_students
            );
        } else if (change) {
            this.setState(
                {
                    offset: this.state.offset + change,
                    loading: true,
                },
                this.get_students
            );
        }
    }

    csv_download(event) {
        const data = this.get_student_filters();
        data.csv = true;
        const url = `/reporting/results_explorer/student_participation/${this.props.district_id}/`;

        this.setState({
            download: true,
            download_url: url,
            download_query: data,
        });
    }

    render() {
        const headers = [];
        for (var index in this.state.headers) {
            headers.push(<th>{this.state.headers[index]}</th>);
        }

        const rows = [];
        for (var index in this.state.rows) {
            const data = this.state.rows[index];
            const temp_row = [];

            for (let i = 0; i < this.state.headers.length; i++) {
                temp_row.push(
                    <td style={{ whiteSpace: 'pre-line' }}>{data[i]}</td>
                );
            }
            rows.push(<tr>{temp_row}</tr>);
        }

        let header_text = '';

        let force_schools_button = null;
        const cluster_filtered = this.props.filters.cluster;
        const school_filtered = this.props.filters.school;
        const teacher_filtered = this.props.filters.teacher;
        if (
            this.props.has_areas &&
            !cluster_filtered &&
            !school_filtered &&
            !teacher_filtered
        ) {
            header_text = 'By Area/Cluster';
            force_schools_button = (
                <div
                    className="purple-button"
                    onClick={() =>
                        this.setState(
                            { force_schools: true },
                            this.get_students
                        )
                    }
                    style={{ maxWidth: '300px' }}
                >
                    <Image
                        className="icon white"
                        src="/static/images/reporting/Class.svg"
                        style={{ width: '20px', marginRight: '8px' }}
                    />
                    Show Results by School
                </div>
            );

            if (this.state.force_schools) {
                header_text = 'By School';
                force_schools_button = (
                    <div
                        className="purple-button"
                        onClick={() =>
                            this.setState(
                                { force_schools: false },
                                this.get_students
                            )
                        }
                        style={{ maxWidth: '300px' }}
                    >
                        <Image
                            className="icon white"
                            src="/static/images/reporting/Class.svg"
                            style={{ width: '20px', marginRight: '8px' }}
                        />
                        Show Results by Area/Cluster
                    </div>
                );
            }
        }

        let force_students_button = null;
        if (school_filtered && !teacher_filtered) {
            header_text = 'By Teacher';
            force_students_button = (
                <div
                    className="purple-button"
                    onClick={() =>
                        this.setState(
                            { force_students: true },
                            this.get_students
                        )
                    }
                    style={{ maxWidth: '300px' }}
                >
                    <Image
                        className="icon white"
                        src="/static/images/reporting/Person.svg"
                        style={{ width: '20px', marginRight: '8px' }}
                    />
                    Show Results by Student
                </div>
            );

            if (this.state.force_students) {
                header_text = 'By Student';
                force_students_button = (
                    <div
                        className="purple-button"
                        onClick={() =>
                            this.setState(
                                { force_students: false },
                                this.get_students
                            )
                        }
                        style={{ maxWidth: '300px' }}
                    >
                        <Image
                            className="icon white"
                            src="/static/images/reporting/Class.svg"
                            style={{ width: '20px', marginRight: '8px' }}
                        />
                        Show Results by Teacher
                    </div>
                );
            }
        }

        let list_controls = null;
        if (this.state.total) {
            let context_rows = this.state.includes_context_total ? 1 : 0;

            list_controls = (
                <ListControls
                    student_count={this.state.total}
                    change={OFFSET_CHANGE}
                    offset={this.state.offset}
                    change_offset={this.change_offset}
                    context_rows={context_rows}
                />
            );
        }

        let content = (
            <div style={{ margin: '15px 0px', width: '100%' }}>
                <Wrapper
                    loaded={!this.state.loading && this.state.loaded}
                    style={{ marginTop: '0px' }}
                >
                    <div style={{ float: 'right' }}>
                        {force_schools_button}
                        {force_students_button}
                    </div>

                    <div className="header h2 bold">{header_text}</div>
                    <table className="table">
                        <tr>{headers}</tr>
                        {rows}
                    </table>
                    <div>{list_controls}</div>
                </Wrapper>
            </div>
        );

        if (rows.length == 0) {
            content = (
                <div style={{ margin: '15px 0px', width: '100%' }}>
                    <Wrapper
                        loaded={!this.state.loading && this.state.loaded}
                        style={{ marginTop: '0px' }}
                    >
                        <div className="h2 header bold">
                            No participation found for your filters
                        </div>
                    </Wrapper>
                </div>
            );
        }

        let download_modal = null;
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={this.state.download_url}
                    query={this.state.download_query}
                    onHide={() => this.setState({ download: false })}
                />
            );
        }

        return (
            <div className="result-display-card">
                {content}
                {download_modal}
            </div>
        );
    }
}
