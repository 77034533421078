import React from 'react';
import { resolveVariables, getChildren } from 'functions';

function resolveAllChildren(component, data, formState) {
    const context = component.props;
    const topChildren = getChildren(context);
    const components = [];

    Object.keys(topChildren).forEach(index => {
        const childComponent = topChildren[index];
        if (!childComponent) {
            return;
        }

        let dataMapping = { ...data };
        Object.keys(childComponent.props).forEach(propName => {
            if (
                ['logic', 'anti_logic', 'dataUrl', 'optionsUrl'].indexOf(
                    propName
                ) > -1
            ) {
                return;
            }
            dataMapping[propName] = childComponent.props[propName];
        });

        const fullData = {
            props: data,
            ...window.cmState.getGlobalState(),
        };

        dataMapping = resolveVariables(dataMapping, fullData);

        if (formState) {
            dataMapping = getFormProps(
                formState,
                childComponent,
                dataMapping,
                index,
                component.props.autoFocus
            );
        }

        const mappedChildren = resolveAllChildren(
            childComponent,
            data,
            formState
        );
        if (mappedChildren.length > 0) {
            dataMapping.children = mappedChildren;
        }

        const componentInstance = React.cloneElement(
            childComponent,
            dataMapping
        );
        components.push(componentInstance);
    });

    return components;
}

function getFormProps(state, component, data, index, autoFocus) {
    if (component.props) {
        const value = state[component.props.name];

        data.value = value;
        if (index === 0 && autoFocus) {
            data.autoFocus = true;
        }
    }

    return data;
}

export default resolveAllChildren;
