import React, { Component } from 'react';

import ajaxWrapper from 'base/ajax.js';

import { Button, Alert, Wrapper } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

const NEEDED_SCORE = 80;

function shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
}

class CalibrationQuiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component_loaded: false,
            responses_loaded: false,

            question: {
                id: 0,
                project_id: 0,
                name: '',
                images_by_language: {},
                text_by_language: {},
                options: [],
                item: { rubric: '' },
            },
            ways_of_thinking: [],

            current_woe: null,
            index: 0,
            num_questions: 15,
            studentquestionresponse: {},
            correct_answer: '',
            guess: {},
            right_answers: 0,
            calibration: {},
            responses: [],
            component: null,
            scored: 0,
            error: '',
        };
        this.submit_callbacks = 0;
        this.current_display = 0;

        this.question_callback = this.question_callback.bind(this);
        this.next = this.next.bind(this);

        this.start_calibration = this.start_calibration.bind(this);

        this.check_for_component_anchors =
            this.check_for_component_anchors.bind(this);
        this.component_callback = this.component_callback.bind(this);
        this.submit_component = this.submit_component.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/approachcomponent/${this.props.component_id}/`,
            {},
            this.component_callback
        );
        ajaxWrapper(
            'GET',
            `/api/home/responsecomponentanalysis/?related=response&response__active=True&anchor=True&component=${this.props.component_id}`,
            {},
            this.check_for_component_anchors
        );
    }

    component_callback(result) {
        this.setState({
            component_loaded: true,
            component: result[0].approachcomponent,
        });
    }

    check_for_component_anchors(result) {
        if (result.length > 0) {
            const shuffled_responses = shuffle(result);

            this.setState({
                responses: shuffled_responses,
                responses_loaded: true,
                current_component:
                    shuffled_responses[0].responsecomponentanalysis,
                student_response:
                    shuffled_responses[0].responsecomponentanalysis.response,
                num_questions: shuffled_responses.length,
            });

            ajaxWrapper(
                'POST',
                '/api/home/calibration/',
                {
                    question: this.props.question_id,
                    component: this.props.component_id,
                    user: window.cmState.user.id,
                },
                this.start_calibration
            );
            ajaxWrapper(
                'GET',
                `/api/home/question/${this.props.question_id}/`,
                {},
                this.question_callback
            );
        } else {
            this.setState({
                error: 'No anchors have been set for this answer, please check with your manager.',
                responses_loaded: true,
            });
        }
    }

    start_calibration(result) {
        this.setState({ calibration: result[0].calibration });
    }

    question_callback(result) {
        this.setState({ question: result[0].question });
    }

    next() {
        const new_index = this.state.index + 1;

        this.setState({
            index: new_index,
            correct_answer: '',
            student_response:
                this.state.responses[new_index].responsecomponentanalysis
                    .response,
            current_component:
                this.state.responses[new_index].responsecomponentanalysis,
        });
    }

    submit_component(has_component) {
        ajaxWrapper(
            'POST',
            '/api/home/calibrationresponse/',
            {
                calibration: this.state.calibration.id,
                student_question_response: this.state.student_response.id,
                anchored_component_presence:
                    this.state.current_component.has_component,
                user_component_presence: has_component,
                user: window.cmState.user.id,
            },
            console.log
        );
        const scored = this.state.scored + 1;
        let { right_answers } = this.state;
        if (has_component == this.state.current_component.has_component) {
            this.setState({
                correct_answer: 'Yes',
                right_answers: this.state.right_answers + 1,
                scored: this.state.scored + 1,
            });
            right_answers += 1;
        } else {
            this.setState({
                correct_answer: 'No',
                guess: true,
                scored: this.state.scored + 1,
            });
        }

        if (scored == this.state.num_questions) {
            const score = (right_answers / this.state.responses.length) * 100;
            let passed = false;
            if (score > NEEDED_SCORE) {
                passed = true;
            }
            ajaxWrapper(
                'POST',
                `/api/home/calibration/${this.state.calibration.id}/`,
                { score, passed, completed: true },
                console.log
            );
        }
    }

    render() {
        let content = null;

        if (this.state.responses_loaded && this.state.component_loaded) {
            if (this.state.scored == this.state.num_questions) {
                const percent_correct =
                    (this.state.right_answers / this.state.num_questions) * 100;
                if (percent_correct > NEEDED_SCORE) {
                    var next_steps = (
                        <h3>
                            That is enough to unlock this question!
                            Congratulations,
                            <a
                                href={`/component_assignment/${this.props.question_id}/${this.state.component.id}/`}
                            >
                                go ahead and start analyzing!
                            </a>
                        </h3>
                    );
                } else {
                    var next_steps = (
                        <h3>
                            That is not enough to unlock this question. Please,
                            <a
                                href={`/calibration_quiz/${this.props.question_id}/?component=${this.state.component.id}`}
                            >
                                Try Again
                            </a>
                        </h3>
                    );
                }

                content = (
                    <div className="container">
                        <h2>
                            You scored {percent_correct.toFixed(0)}% correct.
                        </h2>
                        {next_steps}
                    </div>
                );
            } else if (this.state.error) {
                content = (
                    <div className="container">
                        <p>{this.state.error}</p>
                    </div>
                );
            } else if (this.state.responses.length == 0) {
                content = (
                    <div className="container">
                        <p>
                            Please Contact Snehita Sana on Slack for Permission
                            to Analyze This Answer
                        </p>
                    </div>
                );
            } else {
                const stud_response = this.state.student_response.answer
                    .split('\\n')
                    .join('<br/>');

                let next = (
                    <div>
                        <Button
                            type="primary"
                            text="Yes"
                            onClick={() => this.submit_component(true)}
                        />
                        <Button
                            type="secondary"
                            text="No"
                            onClick={() => this.submit_component(false)}
                        />
                    </div>
                );

                let right_wrong = null;
                if (this.state.correct_answer == 'Yes') {
                    right_wrong = (
                        <Alert type="success" text="You got it right!" />
                    );
                    next = (
                        <Button
                            type="success"
                            onClick={this.next}
                            text="Next"
                        />
                    );
                } else if (this.state.correct_answer == 'No') {
                    right_wrong = (
                        <div>
                            <Alert type="danger" text="You got it wrong!" />
                            <p>You guessed:</p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: !this.state.current_component
                                        .has_component,
                                }}
                            />
                            <p>The right answer was:</p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.current_component
                                        .has_component,
                                }}
                            />
                            <p>
                                <strong>
                                    If you disagree or have any questions,
                                    please reach out to your manager.
                                </strong>
                            </p>
                        </div>
                    );
                    next = (
                        <Button
                            type="success"
                            onClick={this.next}
                            text="Next"
                        />
                    );
                }

                content = (
                    <div className="container">
                        <h2>
                            Calibration Quiz ({this.state.scored + 1}/
                            {this.state.responses.length})
                        </h2>
                        <h4>
                            Question:{' '}
                            <QuestionDisplay
                                question={this.state.question}
                                display="text"
                                image
                            />
                        </h4>
                        <h4>
                            Does this explanation contain{' '}
                            {`${this.state.component.name}: ${this.state.component.text}`}
                            ?
                        </h4>
                        <p>Student Answer</p>

                        <div
                            dangerouslySetInnerHTML={{
                                __html: stud_response,
                            }}
                        />
                        <div style={{ height: '50px' }} />
                        {right_wrong}
                        {next}
                    </div>
                );
            }
        }

        return (
            <Wrapper
                loaded={
                    this.state.responses_loaded && this.state.component_loaded
                }
                content={content}
            />
        );
    }
}

export default CalibrationQuiz;
