import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { FormWithChildren, BasicFileInput, Alert, Select, Wrapper } from 'library';

class UploadRoster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            error: '',
            message: '',
            roster: null
        };

        this.form_ref = React.createRef();

        this.get_districts = this.get_districts.bind(this);
        this.upload_csv = this.upload_csv.bind(this);
        this.upload_callback = this.upload_callback.bind(this);
        this.handle_element_change = this.handle_element_change.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/sis/district/', {}, this.get_districts);
    }

    get_districts(result) {
        const districts = [];
        for (let index = 0; index < result.length; index++) {
            districts.push({
                value: result[index].district.id,
                text: result[index].district.name,
            });
        }

        this.setState({ districts, loaded: true });
    }

    handle_element_change() {
        // clear messaging from this component and the child form when inputs change
        this.setState({ message: '', error: '' });
        this.form_ref.current.set_form_state({ required: [] });
    }

    upload_csv(state) {
        console.log('State', state);
        let formData = new FormData();
        if (state.roster) {
            formData.append('roster', state.roster);
        }

        this.form_ref.current.set_form_state({ form_is_saving_right_now: true });
        ajaxWrapper(
            'POST',
            `/sis/upload_roster/${state.district}/`,
            formData,
            this.upload_callback
        );
    }

    upload_callback(result) {
        console.log('Upload Result', result);
        if (result.error) {
            this.setState({ error: result.error });
        } else {
            this.setState({ message: result.message });
        }
        this.form_ref.current.set_form_state({ form_is_saving_right_now: false });
    }

    render() {
        let content = null;

        const required_headers = [
            'Student ID',
            'Student First Name',
            'Student Last Name',
            'Course',
            'Period',
            'Teacher Name',
            'School Name',
        ];
        const required_headers_jsx = [];
        for (let index = 0; index < required_headers.length; index++) {
            required_headers_jsx.push(<div>{required_headers[index]}</div>);
        }

        if (this.state.loaded) {
            let message = null;
            if (this.state.message) {
                message = <Alert type="success" text={this.state.message} />;
            }

            let error = null;
            if (this.state.error) {
                error = <Alert type="danger" text={this.state.error} />;
            }

            content = (
                <div className="container">
                    <h2>Upload Roster CSV</h2>

                    <FormWithChildren
                        ref={this.form_ref}
                        submit={this.upload_csv}
                        submit_text="Upload"
                    >
                        <Select
                            required
                            name="district"
                            label="District"
                            options={this.state.districts}
                            onChange={this.handle_element_change}
                        />
                        <BasicFileInput
                            required
                            name="roster"
                            label="CSV File"
                            onChange={this.handle_element_change}
                        />
                    </FormWithChildren>

                    {error}
                    {message}

                    <h4 style={{ marginTop: '50px' }}>
                        Please Upload the Roster as a csv with the following
                        headers:
                    </h4>
                    {required_headers_jsx}
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default UploadRoster;
