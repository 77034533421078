import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Alert, Button, PageBreak, Wrapper } from 'library';

import { intersect, get_valid_keys, sort_objects } from 'functions';

import CleverLogin from 'projectLibrary/clever_login.js';
import ClasslinkLogin from 'pages/classlink/login.js';
import StudentLookup from 'pages/proctor/student_lookup.js';
import TEXT_DICT from './translations.js';
import { group } from 'd3';

const CLEVER_TEMP_DISTRICT = 'bd63dbe1-d6db-440c-8ab7-18910b71bdb4';

class LanguageSelect extends Component {
    render() {
        return (
            <div>
                <Button
                    key={this.props.item}
                    text={<h4 style={{ margin: '0px' }}>{this.props.text}</h4>}
                    type={'outline-primary'}
                    onClick={() => this.props.set_language(this.props.item)}
                    style={{
                        width: '50%',
                        marginBottom: '15px',
                        border: '1px solid',
                        height: 'auto',
                    }}
                />
            </div>
        );
    }
}

export default class Validation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,

            student_id: this.props.student_id || null,
            alternative_identifier: this.props.alternative_identifier || null,

            supported_language_error: false,
            show_all_assessment_options: false,

            student_lookup_form_callback: null,
        };
        // Handle converting string parameters to actual value
        if (this.state.student_id == 'null') {
            this.state.student_id = null;
        }
        if (this.state.alternative_identifier == 'null') {
            this.state.alternative_identifier = null;
        }

        this.set_language = this.set_language.bind(this);
        this.get_context = this.get_context.bind(this);
        this.context_callback = this.context_callback.bind(this);

        this.validate_student = this.validate_student.bind(this);
        this.validate_student_callback =
            this.validate_student_callback.bind(this);

        this.get_student_assessment = this.get_student_assessment.bind(this);
        this.student_assessment_callback =
            this.student_assessment_callback.bind(this);
    }

    componentDidMount() {
        if (this.props.selected_language) {
            this.get_context();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selected_language != prevProps.selected_language) {
            this.get_context();
        }
    }

    set_language(lang) {
        this.props.set_language(lang);
    }

    get_context() {
        let user_set = false;
        if (window.cmState.user && window.cmState.user.district_user) {
            user_set = true;
        }
        // User has inputted their student number
        else if (
            'student_id' in this.state &&
            this.state['student_id'] &&
            this.state['student_id'] != 'ALTERNATIVE'
        ) {
            user_set = true;
        }
        // User has inputted alternative data
        else if (
            'alternative_identifier' in this.state &&
            this.state['alternative_identifier']
        ) {
            user_set = true;
        }

        
        if (this.props.district_id && this.props.assessment_id && user_set) { // Everything ready case
            const load_context = new Promise((resolve, reject) => {
                if (this.props.context_cache != null) {
                    this.setState(
                        { loading: true },
                        resolve(this.props.context_cache)
                    );
                }
                else {
                    this.setState(
                        { loading: true },
                        ajaxWrapper(
                            'GET',
                            `/proctor/load_context/${this.props.district_id}/${this.props.assessment_id}/`,
                            {},
                            resolve
                        )
                    );
                }
            });

            const load_student_assessment = new Promise((resolve,reject) => {
                this.get_student_assessment(resolve);
            });
            
            Promise.all([load_context, load_student_assessment]).then((values) => {
                let context = values[0];
                let student_assessment = values[1];
                this.context_callback(context, student_assessment.field_test_choices);
                this.student_assessment_callback(student_assessment);
            }).catch((error) => {
                console.log("Error!");
                console.log(error);
            });
        } else if (this.props.district_id && this.props.assessment_id) { // Assessment chosen, Missing User
            this.setState(
                { loading: true },
                ajaxWrapper(
                    'GET',
                    `/proctor/load_context/${this.props.district_id}/${this.props.assessment_id}/`,
                    {},
                    this.context_callback
                )
            );
        } else if (this.props.district_id) { // Assessment not chosen yet
            this.setState(
                { loading: true },
                ajaxWrapper(
                    'GET',
                    `/proctor/load_context/${this.props.district_id}/${this.props.assessment_id}/`,
                    {},
                    this.context_callback
                )
            );
        } else {
            // If user is logged in and on blank URL, redirect them
            if (window.cmState.user && window.cmState.user.district_user) {
                window.location = `/proctor/${window.cmState.user.district_user.district_id}/?language=${this.props.selected_language}`;
            }
        }
    }

    context_callback(value, field_test_choices) {
        let new_state = {
            loading: false,
        };

        if ('assessment_redirect' in value) {
            window.location = `/proctor/${value['assessment_redirect']['district_id']}/${value['assessment_redirect']['assessment_id']}/?language=${this.props.selected_language}`;
            return false;
        }

        if (!('district' in value) || !value['district']) {
            // Raise missing district error
            new_state['bad_district_id_error'] = true;
        }

        if (!('assessment' in value) && this.props.assessment_id) {
            // Raise bad assessment error
            new_state['bad_assessment_id_error'] = true;
        }

        if ('assessment_options' in value) {
            let parent_state = {
                district: value['district'],
            };
            this.props.load_context(parent_state);

            new_state = Object.assign(new_state, value);
            if (value['assessment_options'].length == 0) {
                new_state['no_active_assessments_error'] = true;
            }
        }

        if ('assessment' in value) {
            let district = value['district'];
            let assessment = value['assessment'];
            let questions_on_assessment_initial = assessment.questions_on_assessments;
            let questions_on_assessment = null;
            if (field_test_choices == null) {
                questions_on_assessment = questions_on_assessment_initial;
            }
            else {
                questions_on_assessment = questions_on_assessment_initial.filter((question) =>
                    question.questiononassessment.question.category.name != 'Field Test' || question.questiononassessment.id === field_test_choices[question.questiononassessment.order]
                );
            }
           

            let language_options = [];
            // Get options from first question
            let first_question = null;

            const total_questions = 0;
            const questions = [];
            const explanation_questions = {};
            for (let item of questions_on_assessment) {
                let question_on_assessment = item['questiononassessment'];
                let question = question_on_assessment['question'];

                if (!question_on_assessment.active) {
                    continue;
                }

                if (!first_question) {
                    first_question =
                        questions_on_assessment[0].questiononassessment
                            .question;
                    for (const key in first_question.text_by_language) {
                        language_options.push(key);
                    }
                }

                if (question.non_explanation_question_id) {
                    explanation_questions[
                        question.non_explanation_question_id
                    ] = question;

                    // Get intersection of lists
                    language_options = intersect(
                        language_options,
                        get_valid_keys(question.text_by_language)
                    );
                }
            }

            for (let item of questions_on_assessment) {
                var question_on_assessment = item['questiononassessment'];
                let question = question_on_assessment['question'];

                if (!question_on_assessment.active) {
                    continue;
                }

                console.log(
                    question.id,
                    'text',
                    get_valid_keys(question.text_by_language),
                    'image',
                    get_valid_keys(question.images_by_language)
                );

                // Map explanation questions to application questions
                if (question.id in explanation_questions) {
                    question.explanation_question =
                        explanation_questions[question.id];
                }

                // Skip explanation questions
                if (question.non_explanation_question_id) {
                    continue;
                }

                questions.push(question);

                // Check for languages in text
                language_options = intersect(
                    language_options,
                    get_valid_keys(question.text_by_language)
                );

                // Check for languages with either image or video
                const mixed_media_options = [
                    ...new Set([
                        ...get_valid_keys(question.images_by_language),
                        ...get_valid_keys(question.videos_by_language),
                    ]),
                ];
                language_options = intersect(
                    language_options,
                    mixed_media_options
                );
            }

            console.log(
                'Assessment Languages',
                language_options,
                total_questions
            );

            let parent_state = {
                assessment: assessment,
                district: district,
                questions: questions,
                assessment_language_options: language_options,
                cached_context: value,
            };

            this.props.load_context(parent_state);
        }

        this.setState(new_state);
    }

    validate_student(state, callback) {
        const data = {
            assessment: this.props.assessment_id,
            role: 'student',
            language: this.props.selected_language,
        };

        data.id = state.id;
        data.student_number = state.student_id;
        data.name = state.name;
        data.school = state.school;
        data.teacher = state.teacher;

        // Check for student and create StudentAssessment object to hold answers
        this.setState(
            {
                student_lookup_form_callback: callback,
                student_lookup_data: data,
            },
            function () {
                ajaxWrapper(
                    'POST',
                    `/proctor/student_validation/${this.props.district.id}/`,
                    data,
                    this.validate_student_callback
                );
            }
        );

        this.props.track_event_details({
            type: 'lookup',
            value: {
                name: data.name,
                student_number: data.student_number,
            },
        });
    }

    validate_student_callback(value) {
        if (!('student_id' in value)) {
            this.setState({
                error: TEXT_DICT[value.error][this.props.selected_language],
                additional_details: true,
                error_timestamp: Date.now(),
            });
            if (this.state.student_lookup_form_callback) {
                this.state.student_lookup_form_callback([]);
            }

            return false;
        }

        let alt_data = this.state.student_lookup_data;
        let alternative_identifier = `${alt_data['name']},${alt_data['student_number']},${alt_data['school']},${alt_data['teacher']}`;

        this.setState(
            {
                alternative_identifier: alternative_identifier,
                student_id: value['student_id'],
            },
            this.get_context
        );
    }

    get_student_assessment(callback) {
        let data = {
            assessment: this.props.assessment_id,
            language: this.props.selected_language,
        };

        if (window.cmState.user && window.cmState.user.district_user) {
            data['student_id'] = window.cmState.user.district_user.id;
        } else if (
            'student_id' in this.state &&
            this.state['student_id'] &&
            this.state['student_id'] != 'ALTERNATIVE'
        ) {
            data['student_id'] = this.state.student_id;
        } else {
            data['alternative_identifier'] = this.state.alternative_identifier;
        }

        var callback_function = typeof callback !== 'undefined' ? callback : this.student_assessment_callback;

        // Create StudentAssessment object to hold answers
        console.log("About to ajax");
        console.log(callback);
        ajaxWrapper(
            'POST',
            '/proctor/get_student_assessment/',
            data,
            callback_function
        );
    }

    student_assessment_callback(value) {
        if (!('student_assessment' in value)) {
            this.setState({
                error: TEXT_DICT[value.error][this.props.selected_language],
                error_timestamp: Date.now(),
            });

            return false;
        }

        const question_answers = {};
        for (let index = 0; index < value.existing_answers.length; index++) {
            const answer =
                value.existing_answers[index].studentquestionresponse;
            question_answers[answer.question_id] = answer;
        }

        if (this.state.student_lookup_form_callback) {
            this.state.student_lookup_form_callback([]);
        }

        this.setState({
            error: '',
            student_lookup_form_callback: null,
        });

        this.props.load_student_assessment({
            student: value.student,
            student_assessment:
                value.student_assessment[0]['studentassessment'],
            question_answers,
        });
    }

    render() {
        const lang = this.props.selected_language;

        let error = null;
        if (this.state.error) {
            error = (
                <div style={{ marginTop: '5px' }}>
                    <Alert
                        key={this.state.error_timestamp}
                        animated
                        type="danger"
                        text={this.state.error}
                    />
                </div>
            );
        }

        let choose_language_jsx = null;
        let student_lookup = null;
        let assessment_options_jsx = [];

        if (!lang || lang == 'undefined') {
            const languages = [];
            for (var item of this.props.language_options) {
                let display_name = item;
                if (item in TEXT_DICT) {
                    display_name = TEXT_DICT[item][item];
                }

                languages.push(
                    <LanguageSelect
                        item={item}
                        text={display_name}
                        set_language={this.set_language}
                    />
                );
            }

            choose_language_jsx = <div>{languages}</div>;
        } else if (!window.cmState.user && !this.state.student_id) {
            const oauth_logins = [];

            if (this.props.district) {
                var id_lookup = null;

                if (
                    this.props.district.clever_id ||
                    this.props.district.id == CLEVER_TEMP_DISTRICT
                ) {
                    oauth_logins.push(
                        <CleverLogin
                            district_clever_id={this.props.district.clever_id}
                        />
                    );
                    id_lookup = (
                        <div>
                            <Button
                                type="warning"
                                text={TEXT_DICT['Clever Unknown'][lang]}
                                onClick={() =>
                                    this.setState({ show_id_lookup: true })
                                }
                            />
                        </div>
                    );
                }
                if (this.props.district.classlink_id) {
                    oauth_logins.push(
                        <ClasslinkLogin
                            district_classlink_id={
                                this.props.district.classlink_id
                            }
                        />
                    );
                    id_lookup = (
                        <div>
                            <Button
                                type="warning"
                                text={TEXT_DICT['Classlink Unknown'][lang]}
                                onClick={() =>
                                    this.setState({ show_id_lookup: true })
                                }
                            />
                        </div>
                    );
                }

                if (!id_lookup || this.state.show_id_lookup) {
                    var id_lookup = (
                        <StudentLookup
                            district_id={this.props.district.id}
                            assessment_id={this.props.assessment_id}
                            selected_language={lang}
                            error={error}
                            validate_student={this.validate_student}
                            additional_details={this.state.additional_details}
                        />
                    );
                }
            } else {
                oauth_logins.push(<CleverLogin />);
                oauth_logins.push(<ClasslinkLogin />);
            }

            student_lookup = (
                <div>
                    <h3>{TEXT_DICT.Login[lang]}</h3>
                    <div
                        style={{
                            display: 'inline-block',
                            padding: '0px 0px 20px 0px',
                        }}
                    >
                        {oauth_logins}
                    </div>

                    <div style={{ padding: '10px' }} />
                    {id_lookup}
                </div>
            );
        } else if (this.state.assessment_options) {

            let sorted_data = this.state.assessment_options;

            // Begin Check if UCSD assessment option special case should be applied
            // TODO erase this as soon as possible or upgrade to real supported feature
            if (!this.state.show_all_assessment_options && this.props.district.name == 'San Diego Unified School District') {
                let whitelist_assessment_ids = ['973557fb-847b-4db4-a931-692fa7bfa866','20df642a-42ae-434a-adcf-abf84e0a7845','f4399948-b373-48a1-891e-397a60663361'];
                // Check if user has alternate_assessment_flag
                console.log("user log");
                console.log(window.cmState.user);
                if (window.cmState.user && window.cmState.user.alternate_assessment_flag) {
                    // user only sees special options
                    sorted_data = sorted_data.filter((item) => whitelist_assessment_ids.some(id => id == item['assessment']['id']));
                }
                else {
                    // special options are EXCLUDED from what user sees
                    sorted_data = sorted_data.filter((item) => !whitelist_assessment_ids.some(id => id == item['assessment']['id']));
                }
            }

            for (let item of sorted_data) {
                let option = item['assessment'];

                // Dont show assessments outside of students grade
                if (
                    !this.state.show_all_assessment_options &&
                    window.cmState.user &&
                    window.cmState.user.district_user.grade
                ) {
                    let user_grade = parseInt(
                        window.cmState.user.district_user.grade
                    );
                    if (
                        option['grade_levels'] &&
                        !option['grade_levels'].includes(user_grade)
                    ) {
                        continue;
                    }
                }

                let option_url = `/proctor/${this.props.district_id}/${option['id']}/`;
                option_url += `?language=${this.props.selected_language}`;
                option_url += `&student_id=${this.state.student_id}`;
                option_url += `&alternative_identifier=${this.state.alternative_identifier}`;

                assessment_options_jsx.push(
                    <div>
                        <Button
                            text={
                                <h4 style={{ margin: '0px' }}>
                                    {option['name']}
                                </h4>
                            }
                            type={'outline-primary'}
                            href={option_url}
                            style={{
                                width: '50%',
                                marginBottom: '15px',
                                border: '1px solid',
                                height: 'auto',
                            }}
                        />
                    </div>
                );
            }

            if (
                this.state.assessment_options.length > 0 &&
                !this.state.show_all_assessment_options
            ) {
                if (assessment_options_jsx.length == 0) {
                    assessment_options_jsx.push(
                        <div>
                            <Alert
                                text={
                                    <h4 style={{ margin: '0px' }}>
                                        {
                                            TEXT_DICT[
                                                'No active assessments matching your grade'
                                            ][lang]
                                        }
                                    </h4>
                                }
                                type={'danger'}
                            />
                        </div>
                    );
                }

                assessment_options_jsx.push(
                    <div>
                        <PageBreak style={{ margin: '20px 0px 40px 0px' }} />
                        <div>
                            <Button
                                text={
                                    TEXT_DICT[
                                        'I would like to see assessments outside of my grade'
                                    ][lang]
                                }
                                type={'warning'}
                                onClick={() =>
                                    this.setState({
                                        show_all_assessment_options: true,
                                    })
                                }
                                deleteType={true}
                                delete_text={'No'}
                                style={{}}
                            />
                        </div>
                    </div>
                );
            }
        }

        let bad_district_id_error_jsx = null;
        let no_active_assessments_error_jsx = null;
        let bad_assessment_id_error_jsx = null;
        let supported_language_error_jsx = null;
        if (this.state.bad_district_id_error) {
            bad_district_id_error_jsx = (
                <Alert
                    type="danger"
                    text={TEXT_DICT['bad_district_id_error'][lang]}
                />
            );
        }
        if (this.state.no_active_assessments_error) {
            no_active_assessments_error_jsx = (
                <Alert
                    type="danger"
                    text={TEXT_DICT['no_active_assessments_error'][lang]}
                />
            );
        }
        if (this.state.bad_assessment_id_error) {
            bad_assessment_id_error_jsx = (
                <Alert
                    type="danger"
                    text={TEXT_DICT['bad_assessment_id_error'][lang]}
                />
            );
        }
        if (this.props.supported_language_error) {
            supported_language_error_jsx = (
                <div>
                    <div>
                        <Alert
                            type="danger"
                            text={TEXT_DICT['supported_language_error'][lang]}
                        />
                    </div>
                    <div>
                        <Button
                            type="outline-primary"
                            text={TEXT_DICT['Change Language'][lang]}
                            onClick={() => this.props.set_language(null)}
                            style={{
                                width: '50%',
                                marginBottom: '15px',
                                border: '1px solid',
                                height: 'auto',
                            }}
                        />
                    </div>
                </div>
            );
        }

        const content = (
            <div style={{ textAlign: 'center' }}>
                <div>
                    {bad_district_id_error_jsx}
                    {no_active_assessments_error_jsx}
                    {bad_assessment_id_error_jsx}
                    {supported_language_error_jsx}
                </div>

                {choose_language_jsx}
                {assessment_options_jsx}
                {student_lookup}
            </div>
        );

        return (
            <Wrapper
                style={{ marginTop: '0px', padding: '0px' }}
                content={content}
                loaded={!this.state.loading}
            />
        );
    }
}
