import React, { Component } from 'react';

import {
    Button,
    TextInput,
    TextArea,
    Select,
    FormWithChildren,
    EmptyModal,
    Wrapper,
} from 'library';

import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';

class MCLScore extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <Button type="primary" text="NA" style={{ height: '35px' }} />
                <div>
                    <Button
                        type="primary"
                        text="M"
                        style={{ height: '35px' }}
                    />
                    <Button
                        type="primary"
                        text="m"
                        style={{ height: '35px' }}
                    />
                </div>
                <div>
                    <Button
                        type="primary"
                        text="C"
                        style={{ height: '35px' }}
                    />
                    <Button
                        type="primary"
                        text="c"
                        style={{ height: '35px' }}
                    />
                </div>
                <div>
                    <Button
                        type="primary"
                        text="L"
                        style={{ height: '35px' }}
                    />
                    <Button
                        type="primary"
                        text="l"
                        style={{ height: '35px' }}
                    />
                </div>
                <Button type="primary" text="NE" style={{ height: '35px' }} />
            </div>
        );
    }
}

class Response extends Component {
    constructor(props) {
        super(props);

        this.state = { qc: this.props.response.review, qc_modal: false };
        this.toggle_qc = this.toggle_qc.bind(this);
        this.submit_qc = this.submit_qc.bind(this);
    }

    toggle_qc() {
        this.setState({ qc_modal: !this.state.qc_modal });
        //
    }

    submit_qc(state) {
        ajaxWrapper(
            'POST',
            `/api/home/studentquestionresponse/${this.props.response.id}/`,
            {
                review: true,
                review_notes: state.review_notes,
                has_been_reviewed: false,
            },
            () => this.setState({ qc: true, qc_modal: false })
        );
    }

    render() {
        let { answer } = this.props.response;
        answer = answer.split('\\n').join('<br/>');

        let qc = <Button type="danger" text="X" onClick={this.toggle_qc} />;
        if (this.state.qc) {
            qc = (
                <div>
                    <p style={{ color: 'red' }}>Under Review</p>
                    <Button type="danger" text="X" onClick={this.toggle_qc} />
                </div>
            );
        }

        const qc_modal = (
            <EmptyModal show={this.state.qc_modal} onHide={this.toggle_qc}>
                <div style={{ padding: '20px' }}>
                    <p>
                        <b>Current Score: {this.props.response.mcl_score}</b>
                    </p>
                    <p>{this.props.response.non_explanation_answer}</p>
                    <p dangerouslySetInnerHTML={{ __html: answer }} />
                    <FormWithChildren submit={this.submit_qc}>
                        <TextArea
                            rows={2}
                            label="Please Tell Us What you would score it and why."
                            name="review_notes"
                        />
                    </FormWithChildren>
                </div>
            </EmptyModal>
        );

        return (
            <div
                className="col-3"
                style={{ padding: '10px', border: '1px solid black' }}
            >
                <p>
                    <b>{this.props.response.non_explanation_answer}</b>
                </p>
                <p dangerouslySetInnerHTML={{ __html: answer }} />
                {qc}
                {qc_modal}
            </div>
        );
    }
}

class MCLQC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            responses: [],
            question: null,
            view_all: false,
            user: '',
            m: 'M',
            c: 'C',
            l: 'L',
            ne_na: '',
            word_search: '',
            answer: '',
        };

        this.question_callback = this.question_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.response_callback = this.response_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            this.question_callback
        );
        this.refresh();
    }

    refresh() {
        let mcl_score = this.state.ne_na;
        if (!mcl_score) {
            mcl_score = this.state.m + this.state.c + this.state.l;
        }
        ajaxWrapper(
            'GET',
            `/api/home/studentquestionresponse/?question=${this.props.question_id}&mcl_score=${mcl_score}`,
            {},
            this.response_callback
        );
    }

    setGlobalState(name, state) {
        if (state.m || state.c || state.l || state.ne_na) {
            this.setState({ loaded: false });
            this.setState(state, this.refresh);
        } else {
            this.setState(state);
        }
    }

    question_callback(result) {
        const { question } = result[0];
        this.setState({ question });
    }

    response_callback(result) {
        const responses = [];

        for (const index in result) {
            const response = result[index].studentquestionresponse;
            responses.push(response);
        }

        sort_objects(responses, ['response_length']);
        this.setState({ responses, loaded: true });
    }

    render() {
        const responses = [];
        let mcl_score = this.state.ne_na;
        if (!mcl_score) {
            mcl_score = this.state.m + this.state.c + this.state.l;
        }
        console.log('mcl_score', mcl_score);
        for (const index in this.state.responses) {
            if (this.state.responses[index]) {
                const response = this.state.responses[index];
                if (response.answer) {
                    if (response.mcl_score == mcl_score) {
                        if (
                            !this.state.word_search ||
                            response.answer
                                .toLowerCase()
                                .indexOf(this.state.word_search.toLowerCase()) >
                                -1
                        ) {
                            if (
                                !this.state.answer ||
                                response.non_explanation_answer ==
                                    this.state.answer
                            ) {
                                responses.push(
                                    <Response response={response} />
                                );
                            }
                        }
                    }
                }
            }
        }

        let qc_fix = null;

        if (window.cmState.user.is_staff) {
            qc_fix = (
                <a
                    href={`/mcl_qc_fix/${this.props.question_id}/`}
                    className="btn btn-primary"
                >
                    QC Fix
                </a>
            );
        }

        let question_text = null;
        if (this.state.question){
            question_text = <h2
                dangerouslySetInnerHTML={{
                    __html: this.state.question.text_by_language
                        .English,
                }}
            />;
        }
        
        const content = (
            <div>
                <div className="container" style={{ padding: '10px' }}>
                    {question_text}
                    {qc_fix}
                    <FormWithChildren
                        row
                        autoSetGlobalState
                        setGlobalState={this.setGlobalState}
                        globalStateName="mcl"
                        defaults={this.state}
                    >
                        <Select
                            name="ne_na"
                            label="NE/NA"
                            options={[
                                { text: 'NA', value: 'NA' },
                                { text: 'NE', value: 'NE' },
                            ]}
                            className="col-3"
                        />
                        <Select
                            name="m"
                            label="M"
                            options={[
                                { text: 'm', value: 'm' },
                                { text: 'M', value: 'M' },
                            ]}
                            className="col-3"
                        />
                        <Select
                            name="c"
                            label="C"
                            options={[
                                { text: 'c', value: 'c' },
                                { text: 'C', value: 'C' },
                            ]}
                            className="col-3"
                        />
                        <Select
                            name="l"
                            label="L"
                            options={[
                                { text: 'l', value: 'l' },
                                { text: 'L', value: 'L' },
                            ]}
                            className="col-3"
                        />
                        <TextInput
                            name="word_search"
                            label="Single Word Search"
                            className="col-6"
                        />
                        <TextInput
                            name="answer"
                            label="Answer"
                            className="col-6"
                        />
                    </FormWithChildren>
                    <p>Number of Responses: {responses.length}</p>
                    <div className="row">{responses}</div>
                </div>
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default MCLQC;
