import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

// components
import { Alert, Wrapper } from 'library';
import { filters_have_changed } from 'functions';

// reports
import DistrictOverview from 'pages/reports/results_explorer/charts/district_overview.js';
import SenseMaking from 'pages/reports/results_explorer/charts/sense_making.js';
import QuestionOverview from 'pages/reports/results_explorer/charts/question_overview.js';
// import StandardsOverview from 'pages/reports/charts/standards_overview.js';
import MindsetOverview from 'pages/reports/results_explorer/charts/mindset_overview.js';
import QuestionResponses from 'pages/reports/results_explorer/charts/question_responses.js';

import StudentList from 'pages/reports/results_explorer/charts/student_list.js';
import StudentDemographics from 'pages/reports/results_explorer/charts/student_demographics.js';

import StudentAnswers from 'pages/reports/results_explorer/charts/student_answers.js';
import Participation from 'pages/reports/results_explorer/charts/participation.js';

import SankeyExplorer from 'pages/reports/results_explorer/charts/sankey_explorer.js';
import save_filter_details from 'pages/reports/results_explorer/functions/save_filter_details.js';

export default class ResultsDisplay extends Component {
    constructor(props) {
        super(props);
        const { filters: filtersProp } = props;
        this.state = {
            loading: false,
            loaded: false,
            error: '',
            filter_timestamp: Date.now(),

            student_list_display: 'list',

            district_overview: null,
            question_overview: null,
            mindset_overview: null,
            question_responses: null,
            sense_making: null,

            sankey_explorer: {},

            standard_filters: {
                area_scope: null,
                time_scope: filtersProp.direct.use_current_students
                    ? 'Current Students'
                    : 'Student from Assessment',
                compare_scope: null,
            },
        };

        this.get_scores = this.get_scores.bind(this);
        this.query_scores = this.query_scores.bind(this);

        this.update_standard_filter_view =
            this.update_standard_filter_view.bind(this);
    }

    componentDidMount() {
        this.query_scores();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { filters: filtersProp, page: pageProp } = this.props;
        if (
            nextProps.page != pageProp ||
            filters_have_changed(
                nextProps.filters.direct,
                filtersProp.direct
            ) ||
            filters_have_changed(
                nextProps.filters.compare,
                filtersProp.compare
            ) ||
            filters_have_changed(
                nextProps.filters.student_list,
                filtersProp.student_list
            ) ||
            filters_have_changed(
                nextProps.filters.question_details,
                filtersProp.question_details
            ) ||
            nextProps.filters.timestamp != filtersProp.timestamp
        ) {
            nextState.loading = true;
        }

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { filters: filtersProp, page: pageProp } = this.props;
        if (
            prevProps.page != pageProp ||
            filters_have_changed(
                prevProps.filters.direct,
                filtersProp.direct
            ) ||
            filters_have_changed(
                prevProps.filters.compare,
                filtersProp.compare
            ) ||
            filters_have_changed(
                prevProps.filters.student_list,
                filtersProp.student_list
            ) ||
            filters_have_changed(
                prevProps.filters.question_details,
                filtersProp.question_details
            ) ||
            prevProps.filters.timestamp != filtersProp.timestamp
        ) {
            this.query_scores();
        }
    }

    get_scores(result) {
        if (
            this.props.filters.timestamp &&
            'filter_timestamp' in result &&
            this.props.filters.timestamp != result.filter_timestamp
        ) {
            return false;
        }

        console.log('get Scores', result);
        const new_data = { loaded: true, loading: false };
        new_data[result.page] = result;

        if (result.data_available) {
            new_data.data_available = result.data_available;
        } else {
            new_data.data_available = 'Yes';
        }

        this.setState(new_data);
    }

    query_scores() {
        const { district_id, filters: filtersProp, page } = this.props;

        const data = {
            filters: filtersProp.direct,
            compare_filters: filtersProp.compare,
        };

        if (district_id !== 'cbfaca6c-6aa9-48a6-ae84-a458f345ac99') {
            // Demo district
            save_filter_details(page, filtersProp);
            data.filter_timestamp = filtersProp.timestamp;
        }

        // question_responses
        if (page === 'Dashboard') {
            ajaxWrapper(
                'POST',
                `/reporting/results_explorer/district_overview_query/${district_id}/`,
                data,
                this.get_scores
            );
        } else if (page === 'Sense Making') {
            ajaxWrapper(
                'POST',
                `/reporting/results_explorer/actionable_assets/${district_id}/`,
                data,
                this.get_scores
            );
        } else if (page === 'Questions') {
            if (filtersProp.question_details.id) {
                /*
                if (!this.state.question_overview){ // TO BE REMOVED
                    ajaxWrapper('POST','/reporting/results_explorer/question_overview_query/' + district_id +'/', data, this.get_scores);
                }
                */
                ajaxWrapper(
                    'POST',
                    `/reporting/results_explorer/question_responses/${district_id}/${filtersProp.question_details.id}/`,
                    data,
                    this.get_scores
                );
            } else {
                ajaxWrapper(
                    'POST',
                    `/reporting/results_explorer/question_overview_query/${district_id}/`,
                    data,
                    this.get_scores
                );
            }
        } else if (page === 'Math Experience') {
            /*
        else if (page == 'Standards Overview') {
            ajaxWrapper('POST','/reporting/results_explorer/question_overview_query/' + district_id +'/', data, this.get_scores);
        }
        */
            ajaxWrapper(
                'POST',
                `/reporting/results_explorer/mindset_overview_query/${district_id}/`,
                data,
                this.get_scores
            );
        } else if (page === 'Sankey Explorer') {
            const new_state = {};
            const { filters, assessments } = this.state;
            if (assessments.length === 0) {
                return false;
            }

            if (filters.starting_assessment == '') {
                new_state.starting_assessment = assessments[0].id;
            }
            if (filters.ending_assessment == '') {
                new_state.ending_assessment = assessments[1].id;
            }
            if (filters.category == '') {
                new_state.category = 'knowledge';
            }

            if (Object.keys(new_state).length > 0) {
                this.setGlobalState('choose', new_state);
            } else {
                ajaxWrapper(
                    'POST',
                    `/reporting/results_explorer/sankey_data/${district_id}/`,
                    data,
                    this.get_scores
                );
            }
        }

        const pages_without_data_loads = [
            'Student Profiles',
            'Student Answers',
            'Participation',
        ];

        if (pages_without_data_loads.indexOf(page) === -1) {
            this.setState({
                loading: true,
                filter_timestamp: Date.now(),
            });
        } else {
            this.setState({
                loading: false,
                loaded: true,
                filter_timestamp: Date.now(),
            });
        }
    }

    update_standard_filter_view(name, state) {
        const { filters: filtersProp } = this.props;
        if ('compare_scope' in state) {
            if (state.compare_scope === 'Compare to last semester') {
                // Find previous semester from currently selected semester
                // Copy all other filters to compare
                const current_term = filtersProp.direct.school_term;
                let last_term = null;
                if (current_term) {
                    let term_index = null;
                    const { school_terms } = this.props;
                    school_terms.forEach(term => {
                        if (term.schoolterm.id == current_term) {
                            term_index = school_terms.indexOf(term);
                        }
                    });

                    if (school_terms.length > term_index + 1) {
                        last_term = school_terms[term_index + 1].schoolterm.id;
                    }
                }

                if (last_term) {
                    const new_compare = JSON.parse(
                        JSON.stringify(filtersProp.direct)
                    );
                    new_compare.school_term = last_term;
                    const { update_filters } = this.props;
                    update_filters('filter-compare', new_compare);
                }
            } else if (state.compare_scope === 'Compare to my School') {
                // Clear existing area filters and set current users school
                const new_compare = {
                    area: '',
                    cluster: '',
                    school: '',
                    teacher: '',
                    section: '',
                };

                new_compare.school_term = filtersProp.direct.school_term;

                if (
                    window.cmState.user &&
                    window.cmState.user.district_user &&
                    window.cmState.user.district_user.school_id
                ) {
                    new_compare.school =
                        window.cmState.user.district_user.school_id;
                }
                const { update_filters } = this.props;
                update_filters('filter-compare', new_compare);
            } else if (state.compare_scope === 'Compare to my District') {
                // Clear existing area filters
                const new_compare = {
                    area: '',
                    cluster: '',
                    school: '',
                    teacher: '',
                    section: '',
                };

                new_compare.school_term = filtersProp.direct.school_term;

                const { update_filters } = this.props;
                update_filters('filter-compare', new_compare);
            }
        } else if ('time_scope' in state) {
            const new_filters = {};

            if (state.time_scope === 'Student from Assessment') {
                new_filters.use_current_students = false;
            } else if (state.time_scope === 'Current Students') {
                new_filters.use_current_students = true;
            }

            const { update_filters } = this.props;
            update_filters('filter-direct', new_filters);
        }

        this.setState({ standard_filters: state });
    }

    render() {
        const {
            filters: filtersProp,
            filters_open,
            side_navbar_open,
            page,
            school_terms,
            is_filtered,
            update_filters,
            show_percentage,
            header_text,
        } = this.props;
        const current_filters = filtersProp.direct;
        const { district_id } = this.props;
        const sizing = {
            side_navbar_open,
            filters_open,
        };
        const {
            district_overview,
            standard_filters,
            sense_making,
            question_responses,
            question_overview,
            mindset_overview,
            student_list_display,
            filter_timestamp,
        } = this.state;
        const results_displayed = [];

        if (page === 'Dashboard') {
            results_displayed.push(
                <DistrictOverview
                    data={district_overview}
                    school_terms={school_terms}
                    is_filtered={is_filtered}
                    update_filters={update_filters}
                    update_standard_filter_view={
                        this.update_standard_filter_view
                    }
                    standard_filters={standard_filters}
                    show_percentage={show_percentage}
                />
            );
        } else if (page === 'Sense Making') {
            results_displayed.push(
                <SenseMaking
                    data={sense_making}
                    is_filtered={is_filtered}
                    update_filters={update_filters}
                    header_text={header_text}
                />
            );
        } else if (page === 'Questions') {
            if (filtersProp.question_details.id) {
                if (question_responses) {
                    const key = current_filters.assessment;
                    results_displayed.push(
                        <QuestionResponses
                            key={`question_response_${key}`}
                            district_id={district_id}
                            filters={filtersProp}
                            update_filters={update_filters}
                            question={filtersProp.question_details.id}
                            question_overview={question_overview}
                            question_responses={question_responses}
                            {...sizing}
                        />
                    );
                }
            } else {
                results_displayed.push(
                    <QuestionOverview
                        filters={filtersProp}
                        update_filters={update_filters}
                        data={question_overview}
                        {...sizing}
                        show_percentage={show_percentage}
                    />
                );
            }
        } else if (page === 'Math Experience') {
            /*
        else if (page == 'Standards Overview') {
            results_displayed.push(<StandardsOverview data={this.state.question_overview}  />);
        }
        */
            results_displayed.push(
                <MindsetOverview
                    data={mindset_overview}
                    is_filtered={is_filtered}
                    update_filters={update_filters}
                    header_text={header_text}
                />
            );
        } else if (page === 'Student Profiles') {
            if (
                student_list_display === 'list' ||
                filtersProp.student_list.student_id
            ) {
                results_displayed.push(
                    <div style={{ textAlign: 'center' }}>
                        <div className="student-list-display-toggle active">
                            List
                        </div>
                        <div
                            role="button"
                            tabIndex={0}
                            className="student-list-display-toggle"
                            onClick={() =>
                                this.setState({
                                    student_list_display: 'demographic',
                                })
                            }
                        >
                            Demographic
                        </div>
                    </div>
                );
                let student_list_key = `StudentList_${filter_timestamp}`;
                const list_params = filtersProp.student_list;
                student_list_key += `_${list_params.category}_${list_params.proficiency}_${list_params.student_id}_${list_params.question_id}_${list_params.question_answer}`;

                results_displayed.push(
                    <StudentList
                        key={student_list_key}
                        filters={filtersProp}
                        update_filters={update_filters}
                        district_id={district_id}
                        data={{}}
                    />
                );
            } else {
                results_displayed.push(
                    <div style={{ textAlign: 'center' }}>
                        <div
                            role="button"
                            tabIndex={0}
                            className="student-list-display-toggle"
                            onClick={() =>
                                this.setState({ student_list_display: 'list' })
                            }
                        >
                            List
                        </div>
                        <div className="student-list-display-toggle active">
                            Demographic
                        </div>
                    </div>
                );
                const student_list_key = `StudentDemographics_${filter_timestamp}`;
                results_displayed.push(
                    <StudentDemographics
                        key={student_list_key}
                        filters={filtersProp}
                        district_id={district_id}
                        data={{}}
                    />
                );
            }
        } else if (page === 'Student Answers') {
            const student_list_key = `StudentAnswers_${filter_timestamp}`;
            results_displayed.push(
                <StudentAnswers
                    key={student_list_key}
                    filters={current_filters}
                    update_filters={update_filters}
                    district_id={district_id}
                    data={{}}
                />
            );
        } else if (page === 'Participation') {
            const { has_areas } = this.props;
            const student_list_key = `Participation_${filter_timestamp}`;
            results_displayed.push(
                <Participation
                    key={student_list_key}
                    filters={current_filters}
                    update_filters={update_filters}
                    district_id={district_id}
                    data={{}}
                    has_areas={has_areas}
                />
            );
        } else if (page === 'Sankey Explorer') {
            const { sankey_explorer } = this.state;
            results_displayed.push(
                <SankeyExplorer
                    data={sankey_explorer.data}
                    count={sankey_explorer.count}
                />
            );
        }
        const { error: errorState } = this.state;
        if (errorState) {
            return (
                <div>
                    <div className="container">
                        <Alert type="danger" text={errorState} />
                    </div>
                </div>
            );
        }
        const { loading, loaded } = this.state;
        return (
            <Wrapper loaded={!loading && loaded} style={{ marginTop: '0px' }}>
                {results_displayed}
            </Wrapper>
        );
    }
}
