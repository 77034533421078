import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Button, Alert, Icon } from 'library';
import QuestionDisplay from 'pages/questions/image.js';

class QuestionOnAssessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
        };

        this.decrease_question_order = this.decrease_question_order.bind(this);
        this.increase_question_order = this.increase_question_order.bind(this);

        this.delete = this.delete.bind(this);
        this.replace_question = this.replace_question.bind(this);
    }

    decrease_question_order() {
        this.props.change_group_order(this.props.question_on_assessment.order, this.props.previous_group.order);
    }

    increase_question_order() {
        this.props.change_group_order(this.props.question_on_assessment.order, this.props.next_group.order);
    }

    delete() {
        ajaxWrapper(
            'POST',
            `/api/home/questiononassessment/${this.props.question_on_assessment.id}/delete/`,
            {},
            this.props.refresh_assessment
        );
    }

    replace_question() {
        this.props.choose_question(this.props.question_on_assessment);
    }

    render() {
        let button_icon_style = {
            width: '15px',
            height: '15px',
        };

        let question = null;
        if (this.props.question_on_assessment.question) {
            question = (
                <QuestionDisplay
                    question={this.props.question_on_assessment.question}
                />
            );
        }

        let delete_button = null;
        if (this.props.question_on_assessment.id) {
            delete_button = (
                <Button
                    type="danger"
                    deleteType
                    text={<Icon size={1} icon="times" style={button_icon_style} />}
                    style={{ minWidth: 'auto' }}
                    onClick={this.delete}
                />
            );
        }

        let error = null;
        if (this.state.error) {
            error = <Alert type="danger" text={this.state.error} />;
        }

        let decrease_order = (
            <Button
                text={<Icon size={1} icon="chevron-up" style={button_icon_style} />}
                style={{ minWidth: 'auto', marginBottom: '10px' }}
                disabled={{ disabled: true }}
            />
        );
        let increase_order = (
            <Button
                text={<Icon size={1} icon="chevron-down" style={button_icon_style} />}
                style={{ minWidth: 'auto' }}
                disabled={{ disabled: true }}
            />
        );
        let order_change_disable = { disabled: 'disabled' };
        if (this.props.disable_controls) {
            order_change_disable = null;
        }
        if (this.props.previous_group) {
            decrease_order = (
                <Button
                    text={<Icon size={1} icon="chevron-up" style={button_icon_style} />}
                    onClick={this.decrease_question_order}
                    type="info"
                    style={{ minWidth: 'auto', marginBottom: '10px' }}
                    disabled={order_change_disable}
                />
            );
        }
        if (this.props.next_group) {
            increase_order = (
                <Button
                    text={<Icon size={1} icon="chevron-down" style={button_icon_style} />}
                    onClick={this.increase_question_order}
                    type="info"
                    style={{ minWidth: 'auto' }}
                    disabled={order_change_disable}
                />
            );
        }

        const replace_button = (
            <Button
                text={<Icon size={1} icon="undo" style={button_icon_style} />}
                onClick={this.replace_question}
                style={{ minWidth: 'auto', marginBottom: '10px' }}
                type="warning"
            />
        );

        return (
            <div
                style={{
                    width: '100%',
                    padding: '20px',
                    margin: '10px',
                }}

                className='simple-card'
            >
                <div className="row col-12" style={{ padding: '0px' }}>
                    <div className="col-3">{question}</div>
                    <div className="col-3">
                        <a
                            href={`/edit_question/${this.props.question_on_assessment.question.id}/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {this.props.question_on_assessment.question.name}
                        </a>
                    </div>
                    <div className="col-6 question-performance">
                        <div style={{ float: 'right' }}>
                            {replace_button}
                            <br />
                            {delete_button}
                        </div>
                        <div style={{ float: 'right', paddingRight: '10px' }}>
                            {decrease_order}
                            <br />
                            {increase_order}
                        </div>
                    </div>
                </div>

                {error}
            </div>
        );
    }
}

export default QuestionOnAssessment;
