import React, { Component } from 'react';

import {} from 'library';
import { Bar } from 'react-chartjs-2';

const BASIC_BAR_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    elements: {
        pointStyle: 'circle',
    },
    scales: {
        y: {
            min: 0,
            grid: {
                drawBorder: false,
            },
            ticks: {
                // Include a dollar sign in the ticks
                callback (value, index, ticks) {
                    return `${value  }%`;
                },
            },
        },
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    size: 16,
                },
            },
        },
    },
};

export default class MCLBreakdown extends Component {
    render() {
        const bar_chart_options = { ...BASIC_BAR_OPTIONS};

        const labels = [];
        const data = [];

        const colors = [
            '#1D81CF',
            '#B0F3FD',
            '#47E3FA',
            '#FFBC7F',
            '#FF820F',
            '#FC7F53',
            '#FB4404',
            '#C19BFF',
            '#8D59E3',
        ];

        for (const item of this.props.data) {
            labels.push(item.name);
            data.push(item.percent);
        }

        return (
            <div className="result-display-card">
                <div className="h2 bold header">{this.props.overview}</div>
                <Bar
                    data={{
                        labels,
                        datasets: [
                            {
                                data,
                                backgroundColor: colors,
                                borderRadius: 5,
                                borderSkipped: false,
                            },
                        ],
                    }}
                    options={bar_chart_options}
                    style={{ maxHeight: '600px' }}
                />
            </div>
        );
    }
}
