import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { formatDateString, format_time_difference } from 'functions';
import { FormWithChildren, Select, TextInput, Wrapper, PageBreak, Alert } from 'library';


class ClasslinkActivity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            last_sync: null,
            last_completed_sync: null,
            districts: [],
            users: [],

            search_loading: false,
        };

        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        // Context for districts synced through ClasslinkActivity
        let url = '/crons/classlink_context/';
        ajaxWrapper('GET', url, {}, (result) => this.setState({...result, loaded: true}));
    }

    filter(name, state){
        if (!state['search']){
            this.setState({users: []});
            return false
        }

        this.setState({
            ...state,
            search_loading: true,
        });

        let related = [
            'district',
            'schools',
            'math_anex_user',
            'math_anex_user__permissioned_district',
            'math_anex_user__permissioned_schools',
        ];

        let url = `/api/sis/districtuser/?limit=10&related=${related.join(',')}&exclude__classlink_id=BLANK_STRING&or__first_name__icontains=${state['search']}&or__last_name__icontains=${state['search']}&or__email__icontains=${state['search']}`;
        ajaxWrapper('GET', url, {}, (result) => this.setState({users: result, search_loading: false}));
    }

    render() {
        let users = [];
        for (let item of this.state.users){
            let math_anex_user = null;
            if (item.districtuser.math_anex_user.length > 0){
                let user = item.districtuser.math_anex_user[0]['user'];

                let permissioned_schools = [];
                for (let school of user.permissioned_schools){
                    permissioned_schools.push(<div>{school.school.name}</div>);
                }

                math_anex_user = <div className='row'>
                    <PageBreak style={{margin: '20px 0px 10px'}} />
                    <div className='col-12' ><h6>MathAnex User:</h6></div>
                    <div className='col-12' >{`Name: ${user.first_name} ${user.last_name}`}</div>
                    <div className='col-4' >{`District: ${user.permissioned_district.name}`}</div>
                    <div className='col-4' >{`Email: ${user.email}`}</div>
                    <div className='col-4' >{`Role: ${user.role}`}</div>

                    <div className='col-4' >{`Created: ${formatDateString(user.date_joined, 'mm/dd/yyyy')}`}</div>
                    <div className='col-8' >{`Updated: ${formatDateString(user.updated, 'mm/dd/yyyy')}`}</div>

                    <div className='col-12' >
                        <div>{`Schools:`}</div>
                        {permissioned_schools}
                    </div>
                </div>
            }

            let schools = [];
            for (let school of item.districtuser.schools){
                schools.push(<div>{school.school.name}</div>);
            }

            users.push(<div className='simple-card' >
                <div><h5>{`${item.districtuser.first_name} ${item.districtuser.last_name}`}</h5></div>
                <div className='row'>
                    <div className='col-4' >{`District: ${item.districtuser.district.name}`}</div>
                    <div className='col-4' >{`Email: ${item.districtuser.email}`}</div>
                    <div className='col-4' >{`Role: ${item.districtuser.role}`}</div>

                    <div className='col-4' >{`Created: ${formatDateString(item.districtuser.created_at, 'mm/dd/yyyy')}`}</div>
                    <div className='col-8' >{`Updated: ${formatDateString(item.districtuser.updated_at, 'mm/dd/yyyy')}`}</div>

                    <div className='col-12' >
                        <div>{`Schools:`}</div>
                        {schools}
                    </div>
                </div>

                {math_anex_user}
            </div>)
        }

        let districts = [];
        for (let item of this.state.districts){
            districts.push(<div className='simple-card'>
                <div><h5>{item.district.name}</h5></div>
                <div className='row'>
                    <div className='col-3' >{`Areas: ${item.areas_count}`}</div>
                    <div className='col-3' >{`Clusters: ${item.clusters_count}`}</div>
                    <div className='col-3' >{`Schools: ${item.schools_count}`}</div>

                    <div className='col-3' >{`Staff: ${item.staff_count}`}</div>
                    <div className='col-3' >{`Teachers: ${item.teachers_count}`}</div>
                    <div className='col-3' >{`Students: ${item.students_count}`}</div>

                    <div className='col-3' >{`Courses: ${item.courses_count}`}</div>
                    <div className='col-3' >{`Sections: ${item.sections_count}`}</div>
                </div>
            </div>);
        }

        let last_sync_jsx = null;
        if (this.state.last_sync){
            let last_sync = this.state.last_sync['cronactivity'];

            let elapsed_jsx = null;
            if (last_sync.end_date){
                let started = new Date(last_sync.created_at);
                let finished = new Date(last_sync.end_date);
                let elapsed = finished.getTime() - started.getTime();

                elapsed_jsx = <div>{`Elapsed: ${format_time_difference(elapsed)}`}</div>;
            }
            
            let missed_sync_error = null;
            if (!last_sync.completed){
                missed_sync_error = <Alert type='danger'>
                    <div>{`Sync not completed!!!`}</div>
                    <div>{`Last completed on ${formatDateString(this.state.last_completed_sync.created_at, 'mm/dd/yyyy')}`}</div>
                </Alert>
            }

            last_sync_jsx = <div>
                <div>{`Last Sync: ${formatDateString(last_sync.created_at, 'mm/dd/yyyy')}`}</div>
                {elapsed_jsx}
                {missed_sync_error}
            </div>;
        }

        return <Wrapper loaded={this.state.loaded} >
            <div className="container" style={{marginBottom: '100px'}}>
                <div><h2>Classlink Sync Dashboard:</h2></div>

                <div><h4>Last Syncs:</h4></div>
                <div className='sad-simple-card-container' >
                    <div className='simple-card'>
                        {last_sync_jsx}
                        
                    </div>
                </div>

                <div><h4>Users:</h4></div>
                <FormWithChildren
                    autoSetGlobalState
                    setGlobalState={this.filter}
                    globalStateName="usersearch"
                    row
                >
                    <TextInput className='col-12' name='search' label='Name or Email' />
                </FormWithChildren>

                <div className='sad-simple-card-container' >
                <Wrapper loaded={!this.state.search_loading} style={{margin:'0px'}} >
                    {users}
                </Wrapper>
                </div>

                <div style={{margin:'10px'}}><PageBreak /></div>

                <div><h4>Districts:</h4></div>
                <div className='sad-simple-card-container' >
                    {districts}
                </div>
            </div>
        </Wrapper>;
    }
}

export default ClasslinkActivity;
