import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    resolveVariables,
    runFunctions,
    resolveAllChildren,
    getChildren,
    getAllChildren,
} from 'functions';

import ajaxWrapper from 'base/ajax.js';
import { Alert, Button } from 'library';

/**
 * Renders a form
 * @example <FormWithChildren>
        {children}
    </FormWithChildren>
 * 
 * @augments {Component<Props,State>}
 */
class FormWithChildren extends Component {
    static propTypes = {
        /** Dictionary of values to populate form state with on first render */
        defaults: PropTypes.object,

        /** Flag to enforce re-rendering when some defaults are themselves objects. POORLY NAMED!! */
        uses_global_dict: PropTypes.bool,

        /** Changes to form state will be published to a value in global state */
        autoSetGlobalState: PropTypes.bool,
        /** Name to publish form state to in global state */
        globalStateName: PropTypes.string,
        /** Custom function to override standard setGlobalState */
        setGlobalState: PropTypes.func,

        /** Form will provide its entire state on updates rather than specific value */
        full_state: PropTypes.bool,

        /** Custom function that takes control of form submission */
        submit: PropTypes.func,
        /** URL for form to submit to */
        submitUrl: PropTypes.string,

        /** Function that will run after form has submitted successfully.  Passes returned data  */
        redirect: PropTypes.func,
        /** URL to redirect user after submission */
        redirectUrl: PropTypes.string,

        /** Function that will run after form has submitted successfully.  Does NOT pass any data */
        refreshData: PropTypes.func,
        /** Flag to reset form to original values after submission */
        reset_state_on_submit: PropTypes.bool,
        /** Function that will run after form has failed to submit */
        submit_failure: PropTypes.func,

        /** URL for deactivating specific object.  Will render delete button */
        deleteUrl: PropTypes.string,
        /** Redirection after succesful delete action */
        deleteRedirectUrl: PropTypes.string,

        /** Form will submit if enter is hit on keyboard */
        submit_on_enter: PropTypes.bool,

        /** Custom class for form wrapper */
        className: PropTypes.string,
        /** Boolean to flag if form wrapper should render as a Bootstrap row */
        row: PropTypes.bool,
        /** Custom button type. Uses Bootstrap classes */
        submitButtonType: PropTypes.string,
        /** Custom class for submit button */
        submitClassName: PropTypes.string,
        /** Custom text for submit button.  Default is 'Submit' */
        submit_text: PropTypes.string,
        /** Custom style to be applied to form wrapper */
        style: PropTypes.object,

        /** Deprecated!! DO NOT USE */
        dont_resolve_anything: PropTypes.bool,
        /** Deprecated!! DO NOT USE */
        functions: PropTypes.array,
        /** Deprecated!! DO NOT USE */
        objectName: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            form_child_update_key: null,
            required: [],

            defaults: {},

            form_is_saving_right_now: false,
        };

        this.get_form_defaults = this.get_form_defaults.bind(this);
        this.set_global_state = this.set_global_state.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.set_form_state = this.set_form_state.bind(this);
        this.reset_state_on_submit = this.reset_state_on_submit.bind(this);
        this.form_submit = this.form_submit.bind(this);
        this.form_submit_callback = this.form_submit_callback.bind(this);
        this.form_submit_failure = this.form_submit_failure.bind(this);
        this.reload = this.reload.bind(this);
        this.check_required_children = this.check_required_children.bind(this);
        this.form_delete = this.form_delete.bind(this);
        this.handle_key_press = this.handle_key_press.bind(this);
    }

    componentDidMount() {
        const defaults = this.get_form_defaults();
        this.setState(defaults, this.set_global_state);
    }

    componentDidUpdate() {
        if (!this.props.uses_global_dict) {
            return null;
        }

        let changed = false;

        Object.keys(this.props.defaults).forEach(key => {
            if (!(key in this.state)) {
                changed = true;
                return;
            }

            if (typeof this.props.defaults[key] === 'object') {
                if (
                    JSON.stringify(this.state[key]) !=
                    JSON.stringify(this.props.defaults[key])
                ) {
                    changed = true;
                }
            } else if (this.state[key] != this.props.defaults[key]) {
                changed = true;
            }
        });

        if (changed) {
            this.setState(this.get_form_defaults());
        }
    }

    get_form_defaults(clean) {
        let defaults;
        defaults = this.props.defaults || {};
        const props_defaults = JSON.parse(JSON.stringify(defaults));
        const children = getChildren(this.props);

        defaults = {};
        if (this.props && props_defaults && !this.props.dont_resolve_anything) {
            defaults = resolveVariables(
                props_defaults,
                window.cmState.getGlobalState(this)
            );
        }

        Object.keys(children).forEach(index => {
            const child = children[index];
            if (child) {
                if (child.props && 'default' in child.props) {
                    defaults[child.props.name] = child.props.default;
                } else if (clean) {
                    defaults[child.props.name] = undefined;
                }
            }
        });

        if (!this.props.dont_resolve_anything) {
            defaults = resolveVariables(
                defaults,
                window.cmState.getGlobalState()
            );
        }

        if (!('required' in defaults)) {
            defaults.required = '';
        }

        return defaults;
    }

    set_global_state(state) {
        if (typeof state === 'undefined') {
            state = this.state;
        }

        if (
            this.props.autoSetGlobalState == true ||
            this.props.autoSetGlobalState == 'true'
        ) {
            if (this.props.setGlobalState) {
                const copied_state = { ...state };
                delete copied_state.form_child_update_key;
                delete copied_state.required;

                this.props.setGlobalState(
                    this.props.globalStateName,
                    copied_state
                );
            }

            // window.cmState.setGlobalState(this.props.globalStateName, state);
        }
    }

    handle_change(e) {
        const newState = {};
        let newCompletedState;

        const name = e.target.getAttribute('name');
        newState[name] = e.target.value;

        if (this.props.full_state) {
            newCompletedState = this.state;
            newCompletedState[name] = e.target.value;
        } else {
            newCompletedState = newState;
        }

        this.setState(newState, this.set_global_state(newCompletedState));
    }

    set_form_state(state) {
        let newState;
        if (this.props.full_state) {
            newState = this.state;
        } else {
            newState = {};
        }

        Object.keys(state).forEach(index => {
            newState[index] = state[index];
        });

        this.setState(state);
        this.set_global_state(newState);
    }

    reset_state_on_submit() {
        const defaults = this.get_form_defaults(true);
        defaults.form_is_saving_right_now = false;

        // Reset key values for all children in order to fully clear states and rerender
        const date = Date.now();
        defaults.form_child_update_key = date;

        this.setState(defaults);
    }

    form_submit() {
        const data = { ...this.state };
        delete data.children;
        delete data.form_state;

        const new_state = {
            required: [],
        };

        const children = getChildren(this.props);
        const required = this.check_required_children([], children);

        if (required.length > 0) {
            new_state['required'] = required;
            this.setState(new_state);
        } else {
            new_state['form_is_saving_right_now'] = true;
            this.setState(new_state);

            for (var item in data) {
                if (item.endsWith('[]')) {
                    data[item] = JSON.stringify(data[item]);
                }
            }

            if (this.props.submit) {
                this.props.submit(
                    data,
                    this.form_submit_callback,
                    this.form_submit_failure
                );

                new_state.form_is_saving_right_now = true;
            } else if (this.props.submitUrl) {
                const { submitUrl } = resolveVariables(
                    { submitUrl: this.props.submitUrl },
                    window.cmState.getGlobalState(this)
                );

                ajaxWrapper(
                    'POST',
                    submitUrl,
                    data,
                    this.form_submit_callback,
                    this.form_submit_failure
                );
            } else if (
                this.props.functions &&
                this.props.functions.length > 0
            ) {
                data.success = true;
                this.form_submit_callback(data);
            }
        }
    }

    form_submit_callback(value) {
        if (typeof value !== 'undefined' && typeof value[0] !== 'undefined') {
            if (this.props.setGlobalState) {
                if (this.props.globalStateName) {
                    this.setState(value[0][this.props.objectName], () =>
                        this.props.setGlobalState(
                            this.props.globalStateName,
                            value[0][this.props.objectName]
                        )
                    );
                } else {
                    this.setState(
                        value[0][this.props.objectName],
                        this.props.setGlobalState('Form', this.state)
                    );
                }
            } else if (value.success == true) {
                // do nothing
            } else if (value[0]) {
                this.setState(value[0][this.props.objectName]);
            }
        }

        if (this.props.deleteRedirectUrl && value.success == true) {
            window.location.href = this.props.deleteRedirectUrl;
        } else if (this.props.redirectUrl) {
            let redirectUrl;
            if (this.props.objectName) {
                redirectUrl = resolveVariables(
                    { redirectUrl: this.props.redirectUrl },
                    value[0][this.props.objectName]
                );
            } else {
                redirectUrl = resolveVariables(
                    { redirectUrl: this.props.redirectUrl },
                    value
                );
            }

            window.location.href = redirectUrl.redirectUrl;
        }

        if (this.props.redirect) {
            value.form_state = this.state;
            this.props.redirect(value);
        } else if (this.props.functions) {
            let { functions } = this.props;
            if (this.props.objectName) {
                functions = resolveVariables(
                    functions,
                    value[0][this.props.objectName]
                );
            } else {
                functions = resolveVariables(functions, value);
                functions = resolveVariables(functions, this.state);
            }

            runFunctions(functions, this.setState, this.props.setGlobalState);
        } else if (this.props.refreshData) {
            this.props.refreshData();
        }

        if (this.props.reset_state_on_submit) {
            this.reset_state_on_submit();
        } else {
            this.setState({ form_is_saving_right_now: false });
        }
    }

    form_submit_failure(value) {
        if (this.props.submit_failure) {
            this.props.submit_failure(value);
        }
        if (this.props.reset_state_on_submit) {
            this.reset_state_on_submit();
        } else {
            this.setState({ form_is_saving_right_now: false });
        }
    }

    reload() {
        this.window.location.reload();
    }

    check_required_children(required, context) {
        Object.keys(context).forEach(index => {
            const child = context[index];
            if (window.cmState.is_valid_react_child(child)) {
                const { props } = child;

                if (props.required == true) {
                    if (
                        !(props.name in this.state) ||
                        this.state[props.name] == undefined ||
                        this.state[props.name] === ''
                    ) {
                        let field_name = props.label;
                        // Fallback behavior in case no label was applied to the input
                        if (!field_name || field_name == '') {
                            field_name = props.name;
                        }

                        required.push(
                            `The field ${field_name} must be filled out to submit the form. `
                        );
                    }
                }

                let { children } = child.props;
                if (typeof children !== 'undefined') {
                    if (typeof children.length === 'undefined') {
                        children = [child.props.children];
                    }
                    required = this.check_required_children(required, children);
                }
            }
        });

        return required;
    }

    form_delete(event, callback) {
        ajaxWrapper(
            'POST',
            this.props.deleteUrl,
            {},
            this.form_submit_callback,
            this.form_submit_failure
        );

        callback();
    }

    handle_key_press(event) {
        if (this.props.submit_on_enter != false) {
            if (event.key == 'Enter') {
                this.form_submit();
            }
        }
    }

    render() {
        let layout = '';
        if (typeof this.props.className !== 'undefined') {
            layout = this.props.className;
        }
        if (this.props.row == true || this.props.row == 'true') {
            layout += ' form-row row';
        } else {
            layout += ' form';
        }

        const newProps = {
            setFormState: this.set_form_state,
            handleChange: this.handle_change,
            handlechange: this.handle_change,
            handleKeyPress: this.handle_key_press,
            dont_resolve_anything: this.props.dont_resolve_anything,
        };

        let components = [];
        if (this.props.dont_resolve_anything) {
            components = getAllChildren(this, newProps, this.state, true);
        } else {
            components = resolveAllChildren(this, newProps, this.state, true);
        }

        if (this.state.form_child_update_key) {
            const new_components = [];
            Object.keys(components).forEach(i => {
                let component = components[i];
                component = React.cloneElement(component, {
                    key: `${this.state.form_child_update_key}_${i}`,
                });
                new_components.push(component);
            });

            components = new_components;
        }

        let buttons = [];
        let float;
        if (
            this.props.submitUrl ||
            this.props.submit ||
            (this.props.functions && this.props.functions.length > 0)
        ) {
            let type = 'primary';
            if (this.props.submitButtonType) {
                type = this.props.submitButtonType;
            }
            float = { float: 'left' };
            let classes = this.props.submitClassName;

            let form_protection = {};
            if (this.props.protect_form) {
                form_protection = {
                    deleteType: true,
                    delete_warning_text:
                        this.props.protect_form_text ||
                        'This form is protected, please double check your work.',
                    delete_text: 'Nevermind',
                };
            }

            let submitButton = (
                <Button
                    button_key={'form_submit_button_key'}
                    style={float}
                    type={type}
                    className={classes}
                    onClick={this.form_submit}
                    text={this.props.submit_text || 'Save'}
                    {...form_protection}
                />
            );
            // Anti-mash behavior for form.  This will force users to wait until callback functions have completed
            // and ensure the form is submitted properly
            if (this.state.form_is_saving_right_now) {
                submitButton = (
                    <Button
                        button_key={'form_submit_button_key'}
                        style={float}
                        type={type}
                        className={`${classes} disabled`}
                        disabled={{ disabled: 'disabled' }}
                        text={this.props.submit_text || 'Save'}
                    />
                );
            } else if (this.state.uploading_from_file_input) {
                submitButton = (
                    <Button
                        button_key={'form_submit_button_key'}
                        style={float}
                        type={type}
                        className={`${classes} disabled`}
                        disabled={{ disabled: 'disabled' }}
                        text={'Waiting for File Upload...'}
                    />
                );
            }
            buttons.push(submitButton);
        }

        if (this.props.deleteUrl) {
            float = { float: 'right' };
            const deleteButton = (
                <Button
                    style={float}
                    type="danger"
                    onClick={this.form_delete}
                    deleteType
                    text="Delete"
                />
            );
            buttons.push(deleteButton);
        }

        const failed = [];
        if (this.state.required != []) {
            Object.keys(this.state.required).forEach(i => {
                failed.push(
                    <Alert type="danger" text={this.state.required[i]} />
                );
            });
        }

        if (layout.indexOf('row') > -1) {
            buttons = (
                <div className="col-12" style={{ textAlign: 'right' }}>
                    {buttons}
                </div>
            );
        }

        // need to add in form_submit, delete, and handle change functions to components.
        return (
            <div
                className={layout}
                style={this.props.style}
                onKeyPress={this.handle_key_press}
            >
                {components}
                {failed}
                {buttons}
                <div style={{ clear: 'both' }} />
            </div>
        );
    }
}

export default FormWithChildren;
