import React from 'react';

import { Container } from 'library';

import ExplanationsExplained from '../components/explanations_explained';
import MCLExplained from '../components/mcl_explained';
import ExplanationsForQuestion from '../components/explanations_for_question';
import MCLTableForQuestion from '../components/mcl_table_for_question';

const MCLForQuestion = data => {
    return (
        <div className="mcl-report-container">
            <ExplanationsExplained />

            <ExplanationsForQuestion {...data} />

            <MCLExplained />

            <MCLTableForQuestion {...data} />
        </div>
    );
};

export default MCLForQuestion;
