import React, { Component } from 'react';

import { EmptyModal } from 'library';
import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

const DEFAULT_IMAGE = {
    'src': '/static/images/image_not_available.png',
    'alt': 'Image Not Avaliable',
};

class QuestionImage extends Component {
    constructor(props) {
        super(props);
        this.state = { show_image_modal: false };

        this.toggle_image_modal = this.toggle_image_modal.bind(this);
    }

    toggle_image_modal() {
        this.setState({ show_image_modal: !this.state.show_image_modal });
    }

    render() {
        let question = this.props.question;

        let image_src = DEFAULT_IMAGE['src'];
        if (question && question.images_by_language && question.images_by_language['English']){
            image_src = question.images_by_language['English'];
        }
        let image_alt = DEFAULT_IMAGE['alt'];
        if (question && question.text_by_language && question.text_by_language['English']){
            image_alt = question.text_by_language['English'];
        }

        const image_modal = (
            <EmptyModal
                show={this.state.show_image_modal}
                onHide={this.toggle_image_modal}
            >
                <div style={{ padding: '30px' }}>
                    <img
                        style={{ minWidth: '50%', maxWidth: '100%' }}
                        src={image_src}
                        alt={image_alt}
                    />
                </div>
            </EmptyModal>
        );

        let question_image = (
            <div style={this.props.style}>
                <p>{image_alt}</p>
            </div>
        );

        if (image_src != '') {
            question_image = (
                <QuestionEnlargeImage
                    image={image_src}
                    text={image_alt}
                />
            );
        }

        return <div className="question-image-container">{question_image}</div>;
    }
}

export default QuestionImage;
