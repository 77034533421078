import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDateString } from 'functions';

import { Button, Wrapper } from 'library';

class DailyQCList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            daily_qcs: [],
            user: { first_name: '', last_name: '' },
        };

        this.analyst_callback = this.analyst_callback.bind(this);
        this.daily_qc_callback = this.daily_qc_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/user/user/${this.props.user}/`,
            {},
            this.analyst_callback
        );
        ajaxWrapper(
            'GET',
            `/api/home/dailyqc/?order_by=-created_at&related=approach_component,question,user,daily_qc_responses__count&qc_user=${this.props.user}`,
            {},
            this.daily_qc_callback
        );
    }

    analyst_callback(result) {
        this.setState({ user: result[0].user, loaded: true });
    }

    daily_qc_callback(result) {
        const daily_qcs = [];

        for (let item of result) {
            const daily_qc = item.dailyqc;
            daily_qcs.push(daily_qc);
        }

        this.setState({ daily_qcs });
    }

    render() {
        const daily_qcs = [];
        for (const index in this.state.daily_qcs) {
            const daily_qc = this.state.daily_qcs[index];
            if (daily_qc.daily_qc_responses == 0) {
                continue;
            }

            let answer = daily_qc.answer.split('%20').join(' ');

            if (daily_qc.approach_component) {
                answer = `${daily_qc.approach_component.name}: ${daily_qc.approach_component.text}`;
            }

            daily_qcs.push(
                <tr>
                    <td>
                        {formatDateString(daily_qc.created_at, 'mm/dd/yyyy')}
                    </td>
                    <td>
                        {daily_qc.user.first_name} {daily_qc.user.last_name[0]}
                    </td>
                    <td>{daily_qc.question.name}</td>
                    <td>{answer}</td>
                    <td>{daily_qc.score.toFixed(2)}%</td>
                    <td>{daily_qc.analyst_score.toFixed(2)}%</td>
                    <td>{daily_qc.resolved_pct.toFixed(2)}%</td>
                    <td>
                        <Button
                            type="primary"
                            text="Review"
                            href={`/daily_qc_responses/${daily_qc.id}/`}
                        />
                    </td>
                </tr>
            );
        }

        const content = (
            <div>
                <div className="container">
                    <h2>
                        Daily QCs: {this.state.user.first_name}{' '}
                        {this.state.user.last_name[0]}
                    </h2>
                    <table className="table">
                        <tr>
                            <th>Date</th>
                            <th>Who Did QC?</th>
                            <th>Question</th>
                            <th>Answer/Component</th>
                            <th>Match Score</th>
                            <th>Analyst Score</th>
                            <th>Resolved Pct</th>
                            <th>Review Responses</th>
                        </tr>
                        {daily_qcs}
                    </table>
                </div>
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default DailyQCList;
