import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    FormWithChildren,
    TextInput,
    Select,
    Button,
    EmptyModal,
} from 'library';

import { prettifyString } from 'functions';

let TYPES = ['Normal', 'Expression', 'Equation'];

let TYPE_OPTIONS = TYPES.map(data => ({
    text: prettifyString(data),
    value: data,
}));

let COMPUTATIONS = [
    'counting',
    'rounding',
    'adding',
    'subtracting',
    'multiplying',
    'dividing',
    'exponential',
    'square_root',
    'greater_than',
    'less_than',
];

let COMPUTATION_OPTIONS = COMPUTATIONS.map(data => ({
    text: prettifyString(data),
    value: data,
}));

class MathComponentWayToGetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: null,
        };

        this.save_mapping = this.save_mapping.bind(this);
        this.delete_mapping = this.delete_mapping.bind(this);
    }

    componentDidMount() {}

    save_mapping(state, callback) {
        let existing_mapping = [...this.props.ways_to_get_mapping];

        var start_numbers = [];
        var state_start_numbers = state['start_numbers'];

        if (typeof state_start_numbers == 'string') {
            state_start_numbers = state_start_numbers.split(',');
        }

        for (var index of state_start_numbers) {
            start_numbers.push(parseFloat(index));
        }

        var derived_numbers = [];
        var state_derived_numbers = state['derived_numbers'];
        if (typeof state_derived_numbers == 'string') {
            state_derived_numbers = state_derived_numbers.split(',');
        }

        for (var index of state_derived_numbers) {
            derived_numbers.push(parseFloat(index));
        }

        let incoming_mapping = {
            type: state['type'],
            computations: state['computations'],
            start_numbers: start_numbers,
            derived_numbers: derived_numbers,
        };

        if (this.state.editing === -1) {
            existing_mapping.push(incoming_mapping);
        } else {
            existing_mapping[this.state.editing] = incoming_mapping;
        }

        this.props.save_mapping(existing_mapping);
    }

    delete_mapping(state, callback) {
        let existing_mapping = [...this.props.ways_to_get_mapping];
        existing_mapping.splice(this.state.editing, 1);
        this.props.save_mapping(existing_mapping);
    }

    render() {
        let existing_ways = [];
        for (let item of this.props.ways_to_get_mapping) {
            let index = this.props.ways_to_get_mapping.indexOf(item);

            existing_ways.push(
                <div key={index} className="simple-card">
                    <div style={{ float: 'right' }}>
                        <Button
                            type="info"
                            text="Edit"
                            onClick={() => this.setState({ editing: index })}
                        />
                    </div>
                    <div>{`Type: ${item['type']}`}</div>
                    <div>{`Computations: ${item['computations']}`}</div>
                    <div>{`Start Numbers: ${item['start_numbers']}`}</div>
                    <div>{`Derived Numbers: ${item['derived_numbers']}`}</div>
                </div>
            );
        }

        existing_ways.push(
            <div className="simple-card">
                <div style={{ float: 'right' }}>
                    <Button
                        type="primary"
                        text="Add New"
                        onClick={() => this.setState({ editing: -1 })}
                    />
                </div>
            </div>
        );

        let edit_form = null;
        if (this.state.editing != null) {
            let defaults = {};
            if (this.state.editing != -1) {
                defaults = this.props.ways_to_get_mapping[this.state.editing];
            }

            edit_form = (
                <EmptyModal
                    show={true}
                    onHide={() => this.setState({ editing: null })}
                >
                    <div
                        className="container"
                        style={{ paddingTop: '15px', paddingBottom: '15px' }}
                    >
                        <FormWithChildren
                            submit={this.save_mapping}
                            key={this.state.editing}
                            defaults={defaults}
                        >
                            <Select
                                label="Type"
                                name="type"
                                options={TYPE_OPTIONS}
                            />
                            <Select
                                label="Computations"
                                name="computations"
                                options={COMPUTATION_OPTIONS}
                                multiple={true}
                            />
                            <TextInput
                                label="Start Numbers (Separated by commas)"
                                name="start_numbers"
                            />
                            <TextInput
                                label="Derived Numbers (Separated by commas)"
                                name="derived_numbers"
                            />
                        </FormWithChildren>
                        <Button
                            style={{ float: 'right' }}
                            type="danger"
                            delete_type={true}
                            onClick={this.delete_mapping}
                            text="Delete Mapping"
                        />
                    </div>
                </EmptyModal>
            );
        }

        return (
            <div className="simple-grey-container">
                {existing_ways}
                {edit_form}
            </div>
        );
    }
}

export default MathComponentWayToGetForm;
