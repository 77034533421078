import React, { Component } from 'react';

export default class ResultsTab extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {

        let tables = []
        console.log("Results Tab", this.props.answers)
        for (var grade in this.props.answers) {
            console.log("grade", grade)
            let answers = this.props.answers[grade];
            let rows = []
            for (let index in answers) {
                console.log("index", index, answers[index])
                if (index > 4) {
                    break;
                }
                rows.push(<tr>
                    <td>{answers[index]['answer']}</td>
                    <td>{(answers[index]['percent'] * 100).toFixed(2)}%</td>
                    <td>{answers[index]['total_responses']}</td>
                </tr>)
            }

            tables.push(<table className='table col-md-4'>
                <tr>
                    <th>Grade Level {grade}</th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th>Answer</th>
                    <th>%</th>
                    <th># of Responses</th>
                </tr>
                {rows}
            </table>)
        }


        return (<div className='container'>{tables}</div>)
    }

};
