import React, { Component } from 'react';
import {
    Expression,
    useHelperExpression,
    GraphingCalculator,
    ScientificCalculator,
    FourFunctionCalculator,
} from 'desmos-react';

export default class DesmosCalculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scientific: false,
            last_calc_state: null,
        };

        this.calc_ref = React.createRef();
        this.toggle_calc = this.toggle_calc.bind(this);
        this.start_calc_tracking = this.start_calc_tracking.bind(this);
        this.track_calc = this.track_calc.bind(this);
    }

    componentDidMount() {
        this.start_calc_tracking();
    }

    toggle_calc() {
        this.setState(
            { scientific: !this.state.scientific },
            function () {
                setTimeout(this.start_calc_tracking, 200);
            }.bind(this)
        );
    }

    start_calc_tracking() {
        if (!this.calc_ref.current) {
            setTimeout(
                function () {
                    this.start_calc_tracking();
                }.bind(this),
                100
            );
        } else {
            this.calc_ref.current.observeEvent('change', this.track_calc);
            let calc_state = this.calc_ref.current.getState();
            this.setState({
                last_calc_state: calc_state,
            });
        }
    }

    track_calc(type) {
        let state = this.calc_ref.current.getState();

        let value = null;
        if (
            state.expressions.list.length >
                this.state.last_calc_state.expressions.list.length &&
            state.expressions.list.at(-1)['latex'] == ''
        ) {
            value = { evaluate: true };
        } else {
            let new_last_item = state.expressions.list.at(-1);
            let last_item = this.state.last_calc_state.expressions.list.at(-1);
            if (new_last_item['id'] == last_item['id']) {
                if (new_last_item['latex'].length > last_item['latex'].length) {
                    value = {
                        add: new_last_item['latex'].slice(
                            last_item['latex'].length
                        ),
                    };
                } else if (
                    new_last_item['latex'].length < last_item['latex'].length
                ) {
                    value = {
                        remove: last_item['latex'].replace(
                            new_last_item['latex'],
                            ''
                        ),
                    };
                }
            }
        }

        console.log('Calc Event', value);
        if (value && this.props.track_event_details) {
            this.props.track_event_details({
                type: 'desmos-calculator',
                value,
            });
        }

        this.setState({
            last_calc_state: state,
        });
    }

    render() {
        let switch_style = {
            display: 'inline-block',
            fontSize: '12px',
            padding: '4px 8px',
            width: 'auto',
            minWidth: 'auto',
            height: 'auto',
            lineHeight: 'normal',
            float: 'right',
            marginTop: '1px',
        };

        let switch_button = (
            <div
                className="btn btn-info"
                style={switch_style}
                onClick={this.toggle_calc}
            >
                Switch to Scientific
            </div>
        );

        let calculator = (
            <FourFunctionCalculator
                ref={this.calc_ref}
                attributes={{
                    className: 'calculator',
                    style: { width: '100%', height: '400px' },
                }}
                fontSize={12}
                keypad
                projectorMode
            >
                <Expression
                    type="expression"
                    id="slider"
                    latex="a=-1"
                    sliderBounds={{
                        min: '-5',
                        max: '5',
                        step: '0.01',
                    }}
                />
            </FourFunctionCalculator>
        );
        if (this.state.scientific) {
            switch_button = (
                <div
                    className="btn btn-info"
                    style={switch_style}
                    onClick={this.toggle_calc}
                >
                    Switch to Simple
                </div>
            );

            calculator = (
                <ScientificCalculator
                    ref={this.calc_ref}
                    attributes={{
                        className: 'calculator',
                        style: { width: '100%', height: '400px' },
                    }}
                    fontSize={12}
                    keypad
                    projectorMode
                >
                    <Expression
                        type="expression"
                        id="slider"
                        latex="a=-1"
                        sliderBounds={{
                            min: '-5',
                            max: '5',
                            step: '0.01',
                        }}
                    />
                </ScientificCalculator>
            );
        }

        return (
            <div>
                <div>
                    <div
                        style={{
                            background: 'rgba(0,0,0,.07)',
                            padding: '2px 6px',
                            border: 'thin solid rgba(0,0,0,.2)',
                            borderBottom: 'none',
                        }}
                    >
                        {'Calculator'} {switch_button}
                    </div>
                </div>

                {calculator}
            </div>
        );
    }
}
