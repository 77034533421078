import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Image, Wrapper, Icon } from 'library';

import AssessmentDetails from 'pages/reports/results_explorer/components/assessment_details.js';
import ProctorDetails from 'pages/reports/results_explorer/components/proctor_details.js';

class AssessmentDetailsButton extends Component {
    render() {
        const { student_assessment } = this.props;

        let button = [
            <div
                className="purple-button regular"
                style={{ marginBottom: '10px' }}
                onClick={() => this.props.set_assessment(student_assessment)}
            >
                View more
            </div>,
        ];

        if (window.cmState.user.role == 'Super Admin') {
            button.push(
                <div
                    className="purple-button regular"
                    onClick={() =>
                        this.props.get_proctor_details(student_assessment)
                    }
                >
                    Proctor Details
                </div>
            );
        }

        if (this.props.disabled) {
            button = (
                <div
                    className="purple-button regular disabled"
                    onClick={() => this.props.set_assessment(null)}
                >
                    View less
                </div>
            );
        }

        return button;
    }
}

export default class SingleStudentReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,

            student: null,
            has_permission: false,

            student_assessment: false,

            show_proctor_details: false,
            proctor_details: [],
            categories: [],
        };

        this.load_student = this.load_student.bind(this);
        this.load_permission = this.load_permission.bind(this);
        this.set_assessment = this.set_assessment.bind(this);

        this.get_proctor_details = this.get_proctor_details.bind(this);
        this.get_proctor_details_callback =
            this.get_proctor_details_callback.bind(this);

        this.unset_student = this.unset_student.bind(this);
    }

    componentDidMount() {
        const related = [
            'school',
            'student_assessments',
            'student_assessments__student_question_responses',
            'student_assessments__student_question_responses__group',
            'student_assessments__student_question_responses__group__parent',
            'student_assessments__assessment',
            'student_assessments__assessment__school_term',
            'student_assessments__assessment__questions_on_assessments',
            'student_assessments__assessment__questions_on_assessments__question',
        ];

        // This request should ONLY get categories that we want displayed
        ajaxWrapper(
            'GET',
            '/api/home/questioncategory/?exclude__name=Field Test',
            {},
            result => {
                this.setState({
                    categories: result.map(c => ({
                        id: c.questioncategory.id,
                        name: c.questioncategory.name,
                    })),
                });
            }
        );
        ajaxWrapper(
            'GET',
            `/api/sis/districtuser/${
                this.props.id
            }/?related__student_assessments__order_by=-created_at&related=${related.join(
                ','
            )}`,
            {},
            this.load_student
        );
        ajaxWrapper(
            'GET',
            `/sis/user_has_student_permission/${this.props.id}/`,
            {},
            this.load_permission
        );
    }

    load_permission(value) {
        this.setState({
            has_permission: value.has_permission,
        });
    }

    load_student(value) {
        const assessment_order_lookup = {};
        for (
            let i = 0;
            i < value[0].districtuser.student_assessments.length;
            i++
        ) {
            const { assessment } =
                value[0].districtuser.student_assessments[i].studentassessment;
            if (!assessment_order_lookup[assessment.id]) {
                assessment_order_lookup[assessment.id] = {};
            }

            // Create new list of reporting questions
            let display_questions = [];
            for (
                let j = 0;
                j < assessment.questions_on_assessments.length;
                j++
            ) {
                var question =
                    assessment.questions_on_assessments[j].questiononassessment;

                const category = this.state.categories.find(
                    c => c.id === question.question.category_id
                );

                if (!category) {
                    continue;
                }

                question.question.category = category.name;

                display_questions.push({ questiononassessment: question });

                if (!assessment_order_lookup[assessment.id][question.id]) {
                    assessment_order_lookup[assessment.id][
                        question.question_id
                    ] = question.order;
                }
            }

            assessment.questions_on_assessments = display_questions;
        }

        this.setState({
            student: value[0].districtuser,
            assessment_order_lookup,
            loaded: true,
        });
    }

    set_assessment(data) {
        this.setState({
            show_proctor_details: false,
            student_assessment: data,
        });
    }

    get_proctor_details(data) {
        ajaxWrapper(
            'GET',
            `/reporting/results_explorer/proctor_details/${data.id}/`,
            {},
            this.get_proctor_details_callback
        );

        this.setState({
            student_assessment: data,
            show_proctor_details: true,
            loading: true,
        });
    }

    get_proctor_details_callback(value) {
        this.setState({
            proctor_details: value,
            loading: false,
        });
    }

    unset_student() {
        const data = this.props.filters.student_list;
        data.student_id = '';

        this.props.update_filters('student_list', data);
    }

    render() {
        let content = null;
        let assessment_details = null;

        if (!this.state.has_permission) {
            content = (
                <div className="result-display-card">
                    <div>
                        <h2>Permission Denied</h2>
                    </div>
                </div>
            );
        } else if (this.state.loaded) {
            const student_assessment_data =
                this.state.student.student_assessments;
            const assessments = [];
            for (const item of student_assessment_data) {
                const student_assessment = item.studentassessment;

                let view_more = (
                    <AssessmentDetailsButton
                        student_assessment={student_assessment}
                        set_assessment={this.set_assessment}
                        get_proctor_details={this.get_proctor_details}
                    />
                );
                if (
                    this.state.student_assessment &&
                    this.state.student_assessment.id == student_assessment.id
                ) {
                    view_more = (
                        <AssessmentDetailsButton
                            student_assessment={student_assessment}
                            disabled
                            set_assessment={this.set_assessment}
                        />
                    );
                }

                let knowledge = (
                    <div className="simple-grey-tag">Not Yet Scored</div>
                );
                if (student_assessment.knowledge) {
                    knowledge = (
                        <div className="regular">
                            {student_assessment.knowledge}
                        </div>
                    );
                }
                let application = (
                    <div className="simple-grey-tag">Not Yet Scored</div>
                );
                if (student_assessment.application) {
                    application = (
                        <div className="regular">
                            {student_assessment.application}
                        </div>
                    );
                }
                let communication = (
                    <div className="simple-grey-tag">Not Yet Scored</div>
                );
                if (student_assessment.communication) {
                    communication = (
                        <div className="regular">
                            {student_assessment.communication}
                        </div>
                    );
                }

                assessments.push(
                    <tr>
                        <td width="32%">
                            <div className="regular">
                                {student_assessment.assessment.name}
                            </div>
                            <div>
                                {student_assessment.assessment.school_term.name}
                            </div>
                        </td>
                        <td width="17%">{knowledge}</td>
                        <td width="17%">{application}</td>
                        <td width="17%">{communication}</td>
                        <td width="17%">{view_more}</td>
                    </tr>
                );

                if (
                    this.state.show_proctor_details &&
                    this.state.student_assessment == student_assessment
                ) {
                    assessment_details = (
                        <ProctorDetails
                            student_assessment={this.state.student_assessment}
                            details={this.state.proctor_details}
                        />
                    );
                    assessments.push(
                        <tr>
                            <td colSpan="5">{assessment_details}</td>
                        </tr>
                    );
                } else if (
                    this.state.student_assessment &&
                    this.state.student_assessment == student_assessment
                ) {
                    assessment_details = (
                        <AssessmentDetails
                            student_assessment={this.state.student_assessment}
                        />
                    );
                    assessments.push(
                        <tr>
                            <td colSpan="5">{assessment_details}</td>
                        </tr>
                    );
                }
            }

            let results_explorer_navigation = null;
            if (this.props.filters) {
                results_explorer_navigation = [
                    <div className="h2 bold" style={{ margin: '20px 0px' }}>
                        Student Report
                        <div
                            className="orange-button"
                            onClick={this.unset_student}
                            style={{
                                marginLeft: '5px',
                                padding: '8px',
                                lineHeight: '16px',
                            }}
                        >
                            <Icon
                                size={1}
                                icon_type="fas"
                                icon="chevron-left"
                                style={{ width: '14px', textAlign: 'center' }}
                            />
                        </div>
                    </div>,
                    <div className="divider-line" />,
                ];
            }

            content = (
                <div className="result-display-card">
                    <div className="row">
                        <div
                            className="col-3"
                            style={{ padding: '12px 0px 0px' }}
                        >
                            <div style={{ margin: '10px 0px' }}>
                                <Image
                                    className="icon purple"
                                    src="/static/images/reporting/Person.svg"
                                    style={{
                                        width: '20px',
                                        marginRight: '8px',
                                    }}
                                />
                                <span className="grey-text h2 lighter">
                                    Name:{' '}
                                </span>
                                <span className="h2 lighter">
                                    {`${this.state.student.last_name}, ${this.state.student.first_name}`}
                                </span>
                            </div>
                            <div style={{ margin: '10px 0px' }}>
                                <Image
                                    className="icon purple"
                                    src="/static/images/reporting/School.svg"
                                    style={{
                                        width: '20px',
                                        marginRight: '8px',
                                    }}
                                />
                                <span className="grey-text h2 lighter">
                                    School:{' '}
                                </span>
                                <span className="h2 lighter">
                                    {this.state.student.school.name}
                                </span>
                            </div>
                            <div style={{ margin: '10px 0px' }}>
                                <Image
                                    className="icon purple"
                                    src="/static/images/reporting/Class.svg"
                                    style={{
                                        width: '20px',
                                        marginRight: '8px',
                                    }}
                                />
                                <span className="grey-text h2 lighter">
                                    Grade:{' '}
                                </span>
                                <span className="h2 lighter">
                                    {this.state.student.grade}
                                </span>
                            </div>
                        </div>
                        <div className="col-9">
                            <table
                                className="student-assessment-table"
                                style={{ width: '100%', textAlign: 'left' }}
                            >
                                <tr style={{ padding: '10px 20px' }}>
                                    <td width="32%">Assessment Name</td>
                                    <td width="17%">Knowledge</td>
                                    <td width="17%">Application</td>
                                    <td width="17%">Communication</td>
                                    <td width="17%" />
                                </tr>

                                {assessments}
                            </table>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Wrapper
                loaded={this.state.loaded && !this.state.loading}
                style={{ marginTop: '0px' }}
            >
                {content}
            </Wrapper>
        );
    }
}
