import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Icon, Wrapper } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

class CalibrationResponses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            calibration: {},
        };

        this.calibration_callback = this.calibration_callback.bind(this);
    }

    componentDidMount() {
        const related = [
            'question',
            'user',
            'component',
            'calibration_responses',
            'calibration_responses__student_question_response',
        ];

        ajaxWrapper(
            'GET',
            `/api/home/calibration/${
                this.props.calibration
            }/?related=${related.join(',')}`,
            {},
            this.calibration_callback
        );
    }

    calibration_callback(result) {
        this.setState({ calibration: result[0].calibration, loaded: true });
    }

    render() {
        const responses = [];
        for (const index in this.state.calibration.calibration_responses) {
            const response =
                this.state.calibration.calibration_responses[index]
                    .calibrationresponse;

            let match = <Icon size={2} icon="times" style={{ color: 'red' }} />;

            if (this.state.calibration.component.text) {
                if (
                    response.user_component_presence ==
                    response.anchored_component_presence
                ) {
                    match = (
                        <Icon
                            size={2}
                            icon="check"
                            style={{ color: 'green' }}
                        />
                    );
                }

                let user_response = 'False';
                if (response.user_component_presence == true) {
                    user_response = 'True';
                }

                let anchored_response = 'False';
                if (response.anchored_component_presence == true) {
                    anchored_response = 'True';
                }

                responses.push(
                    <tr>
                        <td>{response.student_question_response.answer}</td>
                        <td>{user_response}</td>
                        <td>{anchored_response}</td>
                        <td>{match}</td>
                    </tr>
                );
            }
        }

        let content = null;
        if (this.state.loaded) {
            content = (
                <div>
                    <div className="container">
                        <h2>Calibration Quiz</h2>
                        <p>
                            Who Took The Quiz:{' '}
                            {this.state.calibration.user.first_name}{' '}
                            {this.state.calibration.user.last_name[0]}
                        </p>
                        <p>
                            Question:{' '}
                            <QuestionDisplay
                                question={this.state.calibration.question}
                                display="text"
                                image
                            />
                        </p>
                        <p>
                            Answer:{' '}
                            {this.state.calibration.answer ||
                                `${this.state.calibration.component.name}: ${this.state.calibration.component.text}`}
                        </p>
                        <table className="table">
                            <tr>
                                <th>Student Response</th>
                                <th>
                                    {this.state.calibration.user.first_name}{' '}
                                    {this.state.calibration.user.last_name[0]}'s
                                    WoE
                                </th>
                                <th>Anchored WoE</th>
                                <th>Match?</th>
                            </tr>
                            {responses}
                        </table>
                    </div>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default CalibrationResponses;
