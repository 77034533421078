import React from 'react';

import { TableWithChildren } from 'library';

const MCLTableForQuestion = data => {
    const EVIDENCE_COLORS = {
        'Left Blank': 'rgb(255, 255, 255)',
        'No Evidence': 'rgb(230 230 230)',
        'Emerging Evidence': 'rgb(154 219 223)',
        'Partial Evidence': 'rgb(178 214 240)',
        'Sufficient Evidence': 'rgb(194 198 233)',
    };

    let mcl_options = ['m', 'c', 'l', 'no_mcl'];
    let student_rows = [];
    let all_explanations = {};
    let all_names = [];

    for (let item of data['answers']) {
        if (!mcl_options.includes(item['answer']['text'])) {
            continue;
        }
        if ('student_details' in item) {
            for (let jtem of item['student_details']) {
                let full_name = `${jtem['first_name']} ${jtem['last_name']}`;
                if (!(full_name in all_explanations)) {
                    all_explanations[full_name] = jtem;

                    all_names.push(full_name);
                }
            }
        }
    }

    all_names.sort();
    for (let key of all_names) {
        let item = all_explanations[key];

        let math = <td></td>;
        let context = <td></td>;
        let logic = <td></td>;
        if (item['mcl_score'].toLowerCase().includes('m')) {
            math = (
                <td
                    style={Object.assign(
                        { backgroundColor: 'rgb(186 225 165)' },
                        {}
                    )}
                >
                    <div>Yes</div>
                </td>
            );
        }
        if (item['mcl_score'].toLowerCase().includes('c')) {
            context = (
                <td
                    style={Object.assign(
                        { backgroundColor: 'rgb(186 225 165)' },
                        {}
                    )}
                >
                    <div>Yes</div>
                </td>
            );
        }
        if (item['mcl_score'].toLowerCase().includes('l')) {
            logic = (
                <td
                    style={Object.assign(
                        { backgroundColor: 'rgb(186 225 165)' },
                        {}
                    )}
                >
                    <div>Yes</div>
                </td>
            );
        }

        let background_color = 'rgb(255, 255, 255)';
        if (item['explanation_evidence'] in EVIDENCE_COLORS) {
            background_color = EVIDENCE_COLORS[item['explanation_evidence']];
        }

        student_rows.push(
            <tr wrap={false}>
                <td>
                    <div>{`${item['first_name']} ${item['last_name']}`}</div>
                </td>
                <td>
                    <div
                        style={{
                            padding: '6px',
                        }}
                    >
                        {item['answer']}
                    </div>
                </td>
                <td
                    style={Object.assign(
                        { backgroundColor: background_color },
                        {}
                    )}
                >
                    <div>{item['explanation_evidence']}</div>
                </td>
                {math}
                {context}
                {logic}
            </tr>
        );
    }

    if (student_rows.length == 0) {
        return null;
    }

    let header_style = {
        fontWeight: '500',
    };
    let large_header_style = {
        fontWeight: '500',
        fontSize: '18px',
    };

    return (
        <div style={{ margin: '0px 20px' }}>
            <div
                className="h1"
                style={Object.assign({
                    marginBottom: '20px',
                    textAlign: 'center',
                    fontSize: '26px',
                })}
            >
                All Student Explanations
            </div>

            <TableWithChildren
                style={{ border: 'thin solid #ccc', textAlign: 'center' }}
            >
                <tr>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div></div>
                    </td>
                    <td colSpan={3}>
                        <div style={large_header_style}>EVIDENCE OF:</div>
                    </td>
                </tr>
                <tr>
                    <td width="15%">
                        <div style={header_style}>Name</div>
                    </td>
                    <td width="34%">
                        <div style={header_style}>Student's Explanation</div>
                    </td>
                    <td width="15%">
                        <div style={header_style}>Explanation Category</div>
                    </td>
                    <td width="12%">
                        <div style={large_header_style}>MATH</div>
                    </td>
                    <td width="12%">
                        <div style={large_header_style}>CONTEXT</div>
                    </td>
                    <td width="12%">
                        <div style={large_header_style}>LOGIC</div>
                    </td>
                </tr>

                {student_rows}
            </TableWithChildren>
        </div>
    );
};

export default MCLTableForQuestion;
