import refreshToken from './refresh_token.js';
import clearTokens from './clear_tokens.js';

function handleError(xhr, type, url, data, returnFunc, failFunc) {
    console.log('Ajax Failure');
    console.log(xhr);

    if (failFunc) {
        failFunc(xhr.responseText);
    } else if (xhr.status === 400) {
        refreshToken(type, url, data, returnFunc, clearTokens);
    }
}

export default handleError;
