import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Alert, Image, Wrapper, Icon, EmptyModal } from 'library';

import ListControls from 'pages/reports/results_explorer/components/list_controls.js';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';

const OFFSET_CHANGE = 12;

export function StudentRow(props) {
    const { student, assessment } = props;

    const question_responses = [
        <td>{student.student_id}</td>,
        <td>
            <a onClick={() => props.set_student(student.id)}>
                <Image
                    className="icon purple"
                    src="/static/images/reporting/Person.svg"
                    style={{ width: '15px', marginRight: '8px' }}
                />
                <span
                    className="purple-text"
                    style={{ verticalAlign: 'top', lineHeight: '15px' }}
                >
                    {student.last_name}, {student.first_name}
                </span>
            </a>
        </td>,
    ];

    props.question_list.forEach(question => {
        if (assessment.question_responses[question.id]) {
            let correct = null;

            if (
                assessment.question_responses[question.id].score == 1 &&
                question.category != 'bcba4111-d2fc-4dc1-b19a-a1b843c6e8ee'
            ) {
                correct = (
                    <Icon
                        size={2}
                        icon="check"
                        style={{
                            fontSize: '12px',
                            marginLeft: '5px',
                            color: '#4DD293',
                        }}
                    />
                );
            }

            let answer_display =
                assessment.question_responses[question.id].answer;
            if (answer_display.length > 50) {
                answer_display = `${answer_display.substring(0, 50)}...`;
            }

            question_responses.push(
                <td style={{}}>
                    <span
                        title={
                            assessment.question_responses[question.id].answer
                        }
                    >
                        {answer_display}
                        {correct}
                    </span>
                </td>
            );
        } else {
            question_responses.push(<td />);
        }
    });

    return <tr>{question_responses}</tr>;
}

export default class StudentAnswers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            students: [],
            offset: 0,
            loaded: false,
            loading: false,
            student_count: -1,
            questions: [],
            clickedQuestion: null,
            categories: [],
        };

        this.change_offset = this.change_offset.bind(this);

        this.get_students = this.get_students.bind(this);
        this.student_answers_callback =
            this.student_answers_callback.bind(this);

        this.csv_download = this.csv_download.bind(this);
        this.set_student = this.set_student.bind(this);
    }

    componentDidMount() {
        if (this.props.filters.assessments.length == 1) {
            this.get_students();
        }
        ajaxWrapper('GET', '/api/home/questioncategory/', {}, result => {
            this.setState({
                categories: result.map(c => ({
                    id: c.questioncategory.id,
                    name: c.questioncategory.name,
                })),
            });
        });

        window.cmState.setGlobalState(
            'download_reporting_data',
            this.csv_download
        );
    }

    change_offset(absolute, change) {
        if (absolute > -1) {
            this.setState(
                {
                    offset: absolute,
                    loading: true,
                },
                this.get_students
            );
        } else if (change) {
            this.setState(
                {
                    offset: this.state.offset + change,
                    loading: true,
                },
                this.get_students
            );
        }
    }

    loadQuestion = result => {
        const questionData = result[0].question;
        this.setState({ clickedQuestion: questionData });
    };

    get_students() {
        const { district_id } = this.props;

        const data = {
            filters: this.props.filters,
            compare_filters: {},
            offset: this.state.offset,
            limit: OFFSET_CHANGE,
        };

        ajaxWrapper(
            'POST',
            `/reporting/results_explorer/get_student_answers/${this.props.district_id}/`,
            data,
            this.student_answers_callback
        );
    }

    csv_download() {
        const { district_id } = this.props;

        const data = {
            filters: this.props.filters,
            compare_filters: {},
            csv: true,
        };

        this.setState({
            download: true,
            download_url: `/reporting/results_explorer/get_student_answers/${this.props.district_id}/`,
            download_query: data,
        });
    }

    student_answers_callback(result) {
        this.setState({
            assessments: result.student_assessments,
            student_count: result.count,
            questions: result.questions,
            question_list: result.question_list,
            loaded: true,
            loading: false,
        });
    }

    set_student(id) {
        this.props.update_filters(
            'student_list',
            {
                student_id: id,
            },
            'Student Profiles'
        );
    }

    render() {
        const student_assessments = [];

        const question_headers = [];
        for (var index in this.state.question_list) {
            const question = this.state.question_list[index];
            let category = this.state.categories.find(
                c => c.id === question.category
            );
            category = category ? category.name : '';
            question_headers.push(
                <th
                    onClick={() => {
                        ajaxWrapper(
                            'GET',
                            `/api/home/question/${question.id}/?related=answers,answers__response_groups,answers__has_asset`,
                            {},
                            this.loadQuestion
                        );
                    }}
                    title={question.text}
                    style={{ cursor: 'pointer' }}
                >
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                            cursor: 'pointer',
                            color: 'blue',
                            textDecoration: 'underline',
                        }}
                    >
                        Question #{parseInt(index) + 1}
                    </div>
                    <div
                        style={{
                            cursor: 'pointer',
                            color: 'blue',
                            textDecoration: 'underline',
                        }}
                    >
                        {category}
                    </div>{' '}
                </th>
            );
        }

        let list_controls = null;
        if (this.state.student_count) {
            list_controls = (
                <ListControls
                    student_count={this.state.student_count}
                    change={OFFSET_CHANGE}
                    offset={this.state.offset}
                    change_offset={this.change_offset}
                />
            );
        }

        for (var index in this.state.assessments) {
            const assessment = this.state.assessments[index];

            student_assessments.push(
                <StudentRow
                    assessment={assessment}
                    student={assessment.student}
                    set_student={this.set_student}
                    question_list={this.state.question_list}
                />
            );
        }

        if (this.props.filters.assessments.length != 1) {
            return (
                <div style={{ width: '100%', overflowX: 'scroll' }}>
                    <Alert
                        type="danger"
                        text="Please Choose 1 Assessment To See Answers For. You can do that by clicking the assessment filter above. You can filter for assessments based on the school term selected."
                    />
                </div>
            );
        }
        let download_modal = null;
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={this.state.download_url}
                    query={this.state.download_query}
                    onHide={() => this.setState({ download: false })}
                />
            );
        }

        return (
            <div className="result-display-card">
                {this.state.clickedQuestion && (
                    <EmptyModal
                        show
                        onHide={() => this.setState({ clickedQuestion: null })}
                    >
                        <div style={{ padding: '30px', textAlign: 'center' }}>
                            <img
                                style={{
                                    minWidth: '50%',
                                    maxWidth: '100%',
                                    maxHeight: '75%',
                                    width: 'auto',
                                    margin: '0px auto',
                                }}
                                src={
                                    this.state.clickedQuestion
                                        .images_by_language.English
                                }
                                alt={
                                    this.state.clickedQuestion.text_by_language
                                        .English
                                }
                            />
                        </div>
                    </EmptyModal>
                )}
                <Wrapper
                    loaded={!this.state.loading && this.state.loaded}
                    style={{ marginTop: '0px' }}
                >
                    <div style={{ width: '100%', overflowX: 'scroll' }}>
                        <table className="table no-wrap">
                            <tr>
                                <th>Student ID</th>
                                <th>Student Name</th>
                                {question_headers}
                            </tr>
                            {student_assessments}
                        </table>

                        <div>{list_controls}</div>
                    </div>
                </Wrapper>
                {download_modal}
            </div>
        );
    }
}
