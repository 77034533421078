import formatDate from './format_date.js';

function format_date_string(date_string, date_format, timezone) {
    const date = new Date(Date.parse(date_string));
    if (!timezone || typeof timezone === 'undefined') {
        timezone = 'UTC';
    }

    if (date == 'Invalid Date') {
        return '';
    }
    return formatDate(date, date_format, timezone);
}

export default format_date_string;
