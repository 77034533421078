import React, { Component } from 'react';

import { PageBreak } from 'library';

import { LinearScale, ArcElement, PointElement, LineElement, Chart} from "chart.js"
Chart.register(LinearScale);
Chart.register(ArcElement);
Chart.register(PointElement);
Chart.register(LineElement);

import { Line, Pie } from 'react-chartjs-2';

const BASIC_BAR_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            align: 'end',
            labels: {
                usePointStyle: true,
                font: {
                    size: 14,
                },
                padding: 20,
            },
        },
    },
    elements: {
        pointStyle: 'circle',
    },
    scales: {
        y: {
            min: 0,
            grid: {
                drawBorder: false,
            },
            ticks: {
                // Include a dollar sign in the ticks
                callback (value, index, ticks) {
                    return `${value  }%`;
                },
            },
        },
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    size: 16,
                },
            },
        },
    },
};

class MindsetPieGraph extends Component {
    constructor(props) {
        super(props);

        this.show_student_list = this.show_student_list.bind(this);
    }

    show_student_list(labels, evt) {
        if (evt.length == 0) {
            return false;
        }

        const answer = labels[evt[0].element.$context.index];

        const data = {
            question_id: this.props.id,
            question_answer: answer,
        };

        this.props.update_filters('student_list', data, 'Student Profiles');
    }

    render() {
        const colors = ['#47E3FA', '#40AEFA', '#3878F9', '#3232A4', '#838093'];

        const question_details = this.props.question_results;
        const answers = [];
        for (var index in question_details.answers) {
            answers.push(index);
        }

        const question_results = this.props.question_details;
        for (var index in question_results.answers) {
            if (!index in answers) {
                answers.push(index);
            }
        }

        answers.sort();

        const labels = answers;
        const data = [];

        for (const i in answers) {
            var index = answers[i];
            if (question_details.answers[index]) {
                data.push(
                    (
                        (question_details.answers[index] /
                            question_details.count) *
                        100
                    ).toFixed(2)
                );
            } else {
                data.push(0);
            }
        }

        const chart_data = {
            labels,
            datasets: [
                {
                    label: `% Positive Answer for ${  this.props.overview}`,
                    data,
                    backgroundColor: colors,
                },
            ],
        };

        const pie_options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'right',
                    align: 'center',
                    labels: {
                        usePointStyle: true,
                        font: {
                            size: 14,
                        },
                        padding: 20,
                    },
                },
            },
        };

        return (
            <Pie
                data={chart_data}
                options={pie_options}
                getElementsAtEvent={elems => {
                    this.show_student_list(labels, elems);
                }}
                style={{ cursor: 'pointer', height: '300px' }}
            />
        );
    }
}

class MindsetLineGraph extends Component {
    render() {
        const {question_results} = this.props;

        const line_options = { ...BASIC_BAR_OPTIONS};

        const ctx = document.getElementById('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(252,127,83,.5)');
        gradient.addColorStop(0.8, 'rgba(252,127,83,0)');
        gradient.addColorStop(1, 'rgba(252,127,83,0)');

        const grade_labels = [];
        const grade_data = [];
        var grades = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        for (var grade_index in grades) {
            var grade = grades[grade_index];
            if (question_results.by_grade_results[grade]) {
                grade_labels.push(`Grade ${  grade}`);
                var grade_results = question_results.by_grade_results[grade];
                grade_data.push(
                    ((grade_results.sum / grade_results.count) * 100).toFixed(2)
                );
            }
        }

        console.log('Grade Data', grade_data);

        const line_data = {
            labels: grade_labels,
            datasets: [
                {
                    label: '% Positive Answer for your Students',
                    data: grade_data,

                    fill: {
                        target: 'origin',
                        above: gradient, // Area will be red above the origin
                    },
                    pointBackgroundColor: '#FC7F53',
                    pointBorderColor: 'rgba(252, 127, 83, .5)',
                    pointBorderWidth: 4,
                    borderColor: '#FC7F53',
                    tension: 0.4,
                },
            ],
        };

        if (this.props.is_filtered) {
            const {district_question_results} = this.props;

            const district_grade_data = [];
            var grades = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            for (var grade_index in grades) {
                var grade = grades[grade_index];
                console.log(
                    'District Grade',
                    grade,
                    district_question_results.by_grade_results[grade]
                );

                if (district_question_results.by_grade_results[grade]) {
                    var grade_results =
                        district_question_results.by_grade_results[grade];
                    district_grade_data.push(
                        (
                            (grade_results.sum / grade_results.count) *
                            100
                        ).toFixed(2)
                    );
                }
            }

            const district_gradient = ctx.createLinearGradient(0, 0, 0, 400);
            district_gradient.addColorStop(0, 'rgba(103, 100, 250, .5)');
            district_gradient.addColorStop(0.8, 'rgba(103, 100, 250, 0)');
            district_gradient.addColorStop(1, 'rgba(103, 100, 250, 0)');

            line_data.datasets.push({
                label: '% Positive Answer for District',
                data: district_grade_data,

                fill: {
                    target: 'origin',
                    above: district_gradient, // Area will be red above the origin
                },
                pointBackgroundColor: '#6764FA',
                pointBorderColor: 'rgba(103, 100, 250, .5)',
                pointBorderWidth: 4,
                borderColor: '#6764FA',
                tension: 0.4,
            });

            console.log('District Grade Data', district_grade_data, line_data);
        }

        return (
            <Line
                data={line_data}
                options={line_options}
                style={{ height: '400px' }}
            />
        );
    }
}

class MindsetQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
        if (this.props.first) {
            this.state.open = true;
        }
    }

    render() {
        let charts = [];
        let height = 400 + 31 + 40;

        const {question_results} = this.props;
        const {district_question_results} = this.props;

        charts.push(
            <div className="col-6">
                <div style={{ paddingBottom: '10px' }}>
                    {this.props.header_text.overview}
                </div>
                <div>
                    <MindsetPieGraph
                        id={this.props.id}
                        question_details={district_question_results}
                        question_results={question_results}
                        update_filters={this.props.update_filters}
                    />
                </div>
            </div>
        );

        if (this.props.is_filtered) {
            height += 300 + 31;
            charts.push(
                <div className="col-6">
                    <div style={{ paddingBottom: '10px' }}>
                        {this.props.header_text.compared_to}
                    </div>
                    <div>
                        <MindsetPieGraph
                            id={this.props.id}
                            question_details={district_question_results}
                            question_results={district_question_results}
                            update_filters={this.props.update_filters}
                        />
                    </div>
                </div>
            );

            charts.push(
                <div style={{ width: '100%', padding: '20px 0px' }}>
                    <PageBreak />
                </div>
            );
            charts.push(
                <div className="col-12">
                    <div>Results By Grade</div>
                    <div>
                        <MindsetLineGraph
                            is_filtered={this.props.is_filtered}
                            question_results={question_results}
                            district_question_results={
                                district_question_results
                            }
                        />
                    </div>
                </div>
            );
        } else {
            charts.push(
                <div style={{ width: '100%', padding: '20px 0px' }}>
                    <PageBreak />
                </div>
            );
            charts.push(
                <div className="col-6">
                    <div>Results By Grade</div>
                    <div>
                        <MindsetLineGraph
                            is_filtered={this.props.is_filtered}
                            question_results={question_results}
                            district_question_results={
                                district_question_results
                            }
                        />
                    </div>
                </div>
            );
        }

        const chart_style = {
            transition: '.2s height',
            overflow: 'hidden',
            margin: '20px 0px 40px',
            height: `${height  }px`,
        };
        let chevron = (
            <i className="fas fa-chevron-up" style={{ lineHeight: '40px' }} />
        );
        if (this.state.open == false) {
            chevron = (
                <i
                    className="fas fa-chevron-down"
                    style={{ lineHeight: '40px' }}
                 />
            );
            charts = [];

            chart_style.margin = '0px';
            chart_style.height = '0px';
        }

        return (
            <div className="result-display-card">
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({ open: !this.state.open })}
                >
                    <div style={{ float: 'right' }}>{chevron}</div>
                    <div className="h2 bold header">
                        {this.props.question.text}
                    </div>
                </div>
                <div style={chart_style}>
                    <div className="row">{charts}</div>
                </div>
            </div>
        );
    }
}

export default class MindsetOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.set_question = this.set_question.bind(this);
    }

    set_question(id) {
        if (id == '') {
            this.props.setGlobalState('question', {
                question: id,
                page: 'question_overview',
            });
        } else {
            this.props.setGlobalState('question', {
                question: id,
                page: 'question_responses',
            });
        }
    }

    render() {
        const content = null;
        const mindset = [];
        const mindset_by_grade = null;

        let first = true;
        for (const index in this.props.data.questions) {
            const question = this.props.data.questions[index];

            const question_results = this.props.data.question_details[index];
            const district_question_results =
                this.props.data.district.question_details[index];

            if (!question_results || !district_question_results) {
                continue;
            }

            mindset.push(
                <MindsetQuestion
                    id={index}
                    question={question}
                    is_filtered={this.props.is_filtered}
                    update_filters={this.props.update_filters}
                    first={first}
                    question_results={question_results}
                    district_question_results={district_question_results}
                    header_text={this.props.header_text}
                />
            );

            first = false;
        }

        return <div>{mindset}</div>;
    }
}
