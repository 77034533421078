function parse_sessions(events) {
    const sessions = [];
    let session = {
        start_time: null,
        end_time: null,
        total_time: '',
        events: [],
    };
    const user_events = [];
    let prev_user_event = null;

    let total_time = 0;
    events.forEach((user_event) => {
        if (!session.start_time) {
            session.start_time = new Date(Date.parse(user_event.created_at));
            session.events.push(user_event);
            prev_user_event = user_event;
            return;
        }

        // check if previous event and if there is one and its longer than a minute away, means they closed the tab and came back, indicating a new session.

        const prev_event_created = new Date(
            Date.parse(prev_user_event.created_at)
        );
        const event_created = new Date(Date.parse(user_event.created_at));
        const seconds_between_current_events =
            (event_created - prev_event_created) / 1000;
        if (seconds_between_current_events > 300) {
            session.end_time = new Date(
                Date.parse(prev_user_event.created_at)
            );
            const seconds_between_events =
                (session.end_time - session.start_time) / 1000;
            total_time += seconds_between_events;

            const hours = Math.floor(seconds_between_events / 3600);
            const minutes = Math.floor(
                (seconds_between_events - hours * 3600) / 60
            );
            const seconds = Math.floor(
                seconds_between_events - hours * 3600 - minutes * 60
            );
            session.total_time =
                `${hours
                } hours, ${minutes
                } minutes, ${seconds
                } seconds.`;
            sessions.push(session);
            session = {
                start_time: new Date(Date.parse(user_event.created_at)),
                end_time: null,
                total_time: '',
                events: [user_event],
                last_event_end: new Date(Date.parse(user_event.created_at)),
            };
        } else {
            session.events.push(user_event);
        }

        prev_user_event = user_event;
    })

    if (prev_user_event && prev_user_event.created_at) {
        session.end_time = new Date(Date.parse(prev_user_event.created_at));
        const seconds_between_events =
            (session.end_time - session.start_time) / 1000;
        total_time += seconds_between_events;

        const hours = Math.floor(seconds_between_events / 3600);
        const minutes = Math.floor((seconds_between_events - hours * 3600) / 60);
        const seconds = Math.floor(
            seconds_between_events - hours * 3600 - minutes * 60
        );
        session.total_time =
            `${hours} hours, ${minutes} minutes, ${seconds} seconds.`;
        sessions.push(session);
    }

    return { total_time, sessions };
}

export default parse_sessions;
