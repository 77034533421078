

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function prettifyString(incoming_value){
    let output = '';

    for (let piece of incoming_value.split('_')){
        if (output != ''){
            output += ' ';
        }
        output += capitalizeFirstLetter(piece);
    }

    output = output.trim();

    return output;
}


export default prettifyString;
