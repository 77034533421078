import React, { Component } from 'react';

import AssessmentDisplay from './display';


export default class AssessmentTab extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        var items = [];

        for (let item of this.props.questions_on_assessment){
            var assessment = item.questiononassessment.assessment;

            if (Object.keys(assessment).length == 0){
                continue;
            }

            items.push(<AssessmentDisplay type='outline-info' assessment={assessment} link={true} />);
        }

        return (<div style={{padding: '15px 0px'}}>{items}</div>)
    }
}

