import React from 'react';
import { Container, Wrapper, Image, Alert, Icon } from 'library';

import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';

const ApplicationQuestion = data => {
    let item = data['item'];
    let all_answers = item['all_answers'];

    console.log(item);

    if (item['question']['category'] == 'Mindset') {
        all_answers = [];
        let answers_lookup = {};

        for (let jtem of item['all_answers']) {
            answers_lookup[jtem['answer']['text']] = jtem;
        }

        let scale = [
            'Strongly Agree / Yes',
            'Agree / Sometimes',
            'Disagree / Not Really',
            'Strongly Disagree / No',
        ];
        for (let key of scale) {
            let jtem = answers_lookup[key];
            if (jtem) {
                all_answers.push(jtem);
            }
        }
    }

    let top_answers = [];
    for (let jtem of all_answers) {
        let percentage = (
            (jtem['students_who_answered'] / jtem['total_students']) *
            100
        ).toFixed(0);

        let text_style = { marginBottom: '4px', padding: '4px 8px' };
        if (jtem['answer']['is_correct']) {
            text_style['display'] = 'inline-block';
            top_answers.push(
                <div>
                    <Alert type="success" style={text_style}>
                        {`(Correct Answer) ${jtem['students_who_answered']} Students Answered `}
                        <span style={{ fontWeight: '500' }}>
                            {jtem['answer']['text']}
                        </span>
                        {` (${percentage}%)`}
                    </Alert>
                </div>
            );
        } else {
            top_answers.push(
                <div style={text_style}>
                    {`${jtem['students_who_answered']} Students Answered `}
                    <span style={{ fontWeight: '500' }}>
                        {jtem['answer']['text']}
                    </span>
                    {` (${percentage}%)`}
                </div>
            );
        }
    }

    let question_url = `/report_navigator/lookup/?type__icontains=question_on_assessment_report&question_on_assessment=${item['id']}`;
    // TODO: This is legacy support for old bugged data!  Remove as soon as report have been regenerated
    if (!('id' in item)) {
        question_url = `/report_navigator/lookup/?type__icontains=question_on_assessment_report&question_on_assessment=${item['question']['id']}`;
    }

    if (data['context']) {
        for (let context_item of data['context'])
            question_url += `&${context_item['type'].replace('_id', '')}=${
                context_item['id']
            }`;
    }

    return (
        <div
            className="simple-card"
            style={{ padding: '15px', display: 'flex', flexDirection: 'row' }}
        >
            <div style={{ border: 'thin solid #ccc', borderRadius: '3px' }}>
                <QuestionEnlargeImage
                    image={item['question']['image']}
                    text={item['question']['text']}
                    height="200"
                />
            </div>
            <div style={{ padding: '8px 15px' }}>
                <div
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <a
                        style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#0056b3',
                        }}
                        href={question_url}
                    >
                        {`${item['question']['text']}`}
                        { item['question']['generic_reengagement'] && <i
                            class="fas fa-comments"
                            style={{
                                color: '#0056b3',
                                fontSize: '18px',
                                margin: '0px 4px',
                                opacity: '.8',
                            }}
                        ></i> }
                    </a>
                </div>

                {top_answers}
            </div>
        </div>
    );
};

const ExplanationQuestion = data => {
    let item = data['item'];
    console.log(item);

    let answers_lookup = {};
    for (let jtem of item['all_answers']) {
        console.log(jtem['answer']);
        answers_lookup[jtem['answer']['text']] = jtem;
    }

    let top_answers = [];
    for (let key of ['m', 'c', 'l']) {
        if (!(key in answers_lookup)) {
            continue;
        }
        let jtem = answers_lookup[key];

        let percentage = (
            (jtem['students_who_answered'] / jtem['total_students']) *
            100
        ).toFixed(0);

        let text_style = {
            marginBottom: '4px',
            padding: '4px 8px',
        };

        top_answers.push(
            <div style={text_style}>
                <span>{`${jtem['students_who_answered']} Students Got A `}</span>
                <span
                    style={{ fontWeight: 700, paddingRight: '4px' }}
                >{`${key.toLocaleUpperCase()}`}</span>
                <span>{` (${percentage}%)`}</span>
            </div>
        );
    }

    let question_url = `/report_navigator/lookup/?type__icontains=question_on_assessment_report&question_on_assessment=${item['id']}`;
    if (data['context']) {
        for (let context_item of data['context'])
            question_url += `&${context_item['type'].replace('_id', '')}=${
                context_item['id']
            }`;
    }

    return (
        <div
            className="simple-card"
            style={{ padding: '15px', display: 'flex', flexDirection: 'row' }}
        >
            <div style={{ border: 'thin solid #ccc', borderRadius: '3px' }}>
                <QuestionEnlargeImage
                    image={item['question']['image']}
                    text={item['question']['text']}
                    height="200"
                />
            </div>
            <div style={{ padding: '8px 15px' }}>
                <div
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <a
                        style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#0056b3',
                        }}
                        href={question_url}
                    >
                        {`${item['question']['text']}`}
                        { item['question']['generic_reengagement'] && <i
                            class="fas fa-comments"
                            style={{
                                color: '#0056b3',
                                fontSize: '18px',
                                margin: '0px 4px',
                                opacity: '.8',
                            }}
                        ></i> }
                    </a>
                </div>

                {top_answers}
            </div>
        </div>
    );
};

const AssessmentOverview = data => {
    let questions = [];
    for (let item of data['questions']) {
        if (item['question']['category'] == 'Explanation') {
            questions.push(<ExplanationQuestion {...data} item={item} />);
        } else {
            questions.push(<ApplicationQuestion {...data} item={item} />);
        }
    }

    return (
        <div>
            <div style={{ padding: '0px 15px' }}>
                <div className="h1">How Can I Help My Students?</div>
                <div style={{ fontSize: '18px', marginBottom: '20px ' }}>
                    Here are the items from your last assessment. Please explore
                    any of the questions to see how your students think, and the
                    items highlighted with this icon
                    <i
                        class="fas fa-comments"
                        style={{
                            opacity: '.8',
                            fontSize: '18px',
                            margin: '0px 4px',
                        }}
                    ></i>
                    are the ones we feel would be most impactful to focus on.
                    For those, we have prepared re-engagement slides listed in
                    the corresponding tables below that can support you in
                    taking action around these key concepts.
                </div>

                <div
                    style={{
                        backgroundColor: 'rgba(11, 87, 208, 1)',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        margin: '10px 0px 20px ',
                    }}
                >
                    <Image
                        style={{
                            display: 'inline-block',
                            width: '45px',
                            padding: '10px 10px 10px 20px',
                        }}
                        src={'/static/images/hand-pointer.png'}
                    />

                    <div
                        style={{
                            padding: '10px 20px 10px 0px',
                            color: 'white',
                        }}
                    >
                        Click the question title for a breakdown of how your
                        students were thinking about the problem, as well as
                        some quick ideas and materials for how to support them.
                    </div>
                </div>
            </div>

            <div className="sad-simple-card-container">{questions}</div>
        </div>
    );
};

export default AssessmentOverview;
