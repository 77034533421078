import React from 'react';

export default function Icon(props) {
    const size = `fa-${props.size}x` || 'fa-3x';
    const icon_type = props.icon_type || 'fa';
    const icon = `fa-${props.icon}`;

    return (
        <i
            style={props.style}
            onClick={props.onClick}
            className={`${props.className} ${size} ${icon_type} ${icon}`}
        />
    );
}
