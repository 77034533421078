import React from 'react';
import { Icon, ClickableContent } from 'library';

export default ({ question }) => (
    <tr>
        <td>{question.name}</td>
        <td>{question.notes}</td>
        <td>
            {question.tags?.map(({ tag }) => (
                <ClickableContent
                    key={tag.id}
                    className="selection"
                    style={{
                        background: '#6764fa',
                        borderRadius: '5px',
                        marginRight: '5px',
                        color: 'white',
                        padding: '2px 6px',
                        margin: '10px 0px 10px 4px',
                        cursor: 'pointer',
                    }}
                >
                    <div style={{ display: 'inline-block' }}>{tag.name}</div>
                </ClickableContent>
            ))}
        </td>
        <td>
            <a
                href={`/edit_question_concept/${question.id}`}
                target="_blank"
                rel="noreferrer"
                style={{
                    borderRadius: '5px',
                    padding: '11px 14px',
                    height: '100%',
                    display: 'inline-block',
                }}
                className="btn-primary"
            >
                <Icon size={1} icon="edit" />
            </a>
        </td>
    </tr>
);
