import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    PageBreak,
    Icon,
    FormWithChildren,
    TextInput,
    NumberInput,
    Wrapper,
} from 'library';

class Collision extends Component {
    constructor(props) {
        super(props);
        this.state = { show_paths: false };

        this.toggle_paths = this.toggle_paths.bind(this);
    }

    toggle_paths() {
        this.setState({ show_paths: !this.state.show_paths });
    }

    render() {
        let icon = (
            <Icon size={1} icon="caret-right" style={{ color: 'black' }} />
        );
        let paths_display = null;
        if (this.state.show_paths) {
            icon = (
                <Icon size={1} icon="caret-down" style={{ color: 'black' }} />
            );
            const all_paths = [];
            for (const index in this.props.paths) {
                const steps = [];
                const path = this.props.paths[index];
                steps.push(<p>{path}</p>)
                all_paths.push(
                    <div
                        className="col-4"
                        style={{ border: '1px solid black' }}
                    >
                        {steps}
                    </div>
                );
            }

            paths_display = (
                <div className="row" style={{ marginBottom: '20px' }}>
                    {all_paths}
                </div>
            );
        }

        return (
            <div>
                <h3 onClick={this.toggle_paths}>
                    {icon} Answer: {this.props.number}, Collisions:{' '}
                    {this.props.paths.length}
                </h3>
                <PageBreak />
                {paths_display}
            </div>
        );
    }
}

export default class CheckQuestionNumbers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            collisions: null
        };

        this.get_number_collisions = this.get_number_collisions.bind(this);
        this.get_number_collisions_callback =
            this.get_number_collisions_callback.bind(this);
    }

    get_number_collisions(state, return_func) {
        this.setState({ loading_collisions: true });
        ajaxWrapper(
            'POST',
            '/check_question_numbers/',
            { numbers: state.numbers },
            this.get_number_collisions_callback
        );
        return_func();
    }

    get_number_collisions_callback(result) {
        this.setState({ collisions: result, loading_collisions: false });
    }

    render() {
        let collisions_info = [];
        let collision_count = null;
        if (this.state.collisions===null) {
            collisions_info = 'Waiting for input...';
        }
        else if (this.state.collisions.error) {
            collisions_info = this.state.collisions.error
        }
        else {
            collision_count=0;
            for (const [key, value] of Object.entries(this.state.collisions)) {
                collision_count++;
                collisions_info.push(
                    <Collision
                        number={key}
                        paths={value}
                    />
                );
            }
        }
        
       if (collision_count!== null && collision_count == 0) {
            collisions_info = 'No Collisions Found.';
        }

        const content = (
            <div className="container">
                <h2>Check Question Numbers</h2>
                <p>
                    Currently only computes correct addition, subtraction,
                    multiplication and division. Future additions will include
                    incorrect versions, squares, roots, etc.
                </p>
                <FormWithChildren
                    submit={this.get_number_collisions}
                    submit_text="Check For Collisions"
                >
                    <TextInput
                        name="numbers"
                        label="Enter a series of comma separated number inputs to check (E.g. 1,3.4,2)"
                    />
                </FormWithChildren>

                <h2 style={{ marginTop: '50px' }}>Collisions</h2>
                <Wrapper
                    loaded={!this.state.loading_collisions}
                    content={collisions_info}
                />
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}
