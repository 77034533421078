import React, { Component } from 'react';

import { FormWithChildren, Wrapper, DictBuilder } from 'library';

class TestPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const defaults = {
            dict: [
                {
                    English: 'This is some text',
                    Spanish: 'This is some spanish text',
                },
            ],
        };

        // wrangle the data and give it to the sortable/comparison tables.
        const content = (
            <div className="container">
                <FormWithChildren defaults={defaults}>
                    <DictBuilder
                        name="dict"
                        label="Test Dict Builder"
                        top_level_list
                    />
                </FormWithChildren>
            </div>
        );

        return <Wrapper loaded content={content} />;
    }
}

export default TestPage;
