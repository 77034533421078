import React from 'react';

export default function Div(props) {
    return (
        <div className={props.className || ''} style={props.style || {}}>
            {props.text}
            {props.children}
        </div>
    );
}
