import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { formatDateString } from 'functions';

import { Wrapper, Button, Tabs } from 'library';

import QuestionDisplay from 'pages/questions/question_display.js';

let NON_EXPLANATION_CATEGORIES = ['Knowledge', 'Application', 'Mindset'];

class ScoringConfigurationDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            questions: [],
        };
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            '/api/scoring_configuration/incomplete_questions/',
            {},
            result => {
                this.setState({
                    questions: result,
                    loaded: true,
                });
            }
        );
    }

    render() {
        let question_jsx = {
            explanation: [],
            nonexplanation: [],
        };

        for (let item of this.state.questions) {
            let question = item['question'];

            if (
                NON_EXPLANATION_CATEGORIES.includes(
                    question['category']['name']
                )
            ) {
                let url = `/student_response_grouping/${question['id']}/`;

                question_jsx['nonexplanation'].push(
                    <div className="simple-card">
                        <div style={{ float: 'right' }}>
                            <Button type="primary" text="Go To" href={url} />
                        </div>
                        <QuestionDisplay
                            question={question}
                            display="full_text"
                        />
                    </div>
                );
            } else {
                let url = `/scoring_configuration/question_component_overview/${question['id']}/`;

                question_jsx['explanation'].push(
                    <div className="simple-card">
                        <div style={{ float: 'right' }}>
                            <Button type="primary" text="Go To" href={url} />
                        </div>
                        <QuestionDisplay
                            question={question}
                            display="full_text"
                        />
                    </div>
                );
            }
        }

        const tab_names = ['Explanation', 'Non-Explanation'];

        const tab_contents = {
            Explanation: question_jsx['explanation'],
            'Non-Explanation': question_jsx['nonexplanation'],
        };

        let tabs = <Tabs tab_names={tab_names} tab_contents={tab_contents} />;

        return (
            <Wrapper loaded={this.state.loaded}>
                <div
                    className="sad"
                    style={{ marginTop: '-50px', padding: '30px 0px 50px 0px' }}
                >
                    <div className="container">
                        <div className="simple-card">
                            <h2>Incomplete Questions</h2>
                        </div>

                        <div className="simple-card">{tabs}</div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default ScoringConfigurationDashboard;
