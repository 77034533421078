import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Icon,
    FormWithChildren,
    Select,
    Wrapper,
    Alert,
    TextInput,
} from 'library';

import AnonDistrict from './components/anon_district';

class Assessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        let edit_button = null;
        let anon_button = null;

        if (window.cmState.user.role == 'Super Admin') {
            edit_button = (
                <a
                    target="_blank"
                    style={{
                        width: 'fit-content',
                        borderRadius: '5px',
                        display: 'block',
                        minWidth: '0px',
                        padding: '10px 9px 10px 11px',
                        height: 'auto',
                        lineHeight: '0px',
                    }}
                    className="btn-primary"
                    href={`/edit_assessment/${this.props.assessment.id}`}
                    rel="noreferrer"
                >
                    <Icon size={1} icon="edit" />
                </a>
            );
            if (this.props.show_anonymous) {
                anon_button = (
                    <td>
                        <Button
                            href={`/anonymous_assessments/${this.props.assessment.id}`}
                            type="warning"
                            text={this.props.assessment.anonymous_assessments}
                            target="_blank"
                        />
                    </td>
                );
            }
        }

        return (
            <tr>
                <td>
                    {this.props.assessment.name}
                    {edit_button}
                </td>
                <td>
                    {window.cmState.user.role == 'Super Admin' ? (
                        this.props.assessment.district.name
                    ) : (
                        <AnonDistrict
                            district={this.props.assessment.district}
                        />
                    )}
                </td>
                <td>{this.props.assessment.school_term.name}</td>
                <td>{this.props.assessment.grade_levels}</td>
                {anon_button}
            </tr>
        );
    }
}

class Assessments extends Component {
    constructor(props) {
        super(props);

        const anonymous = true;

        this.state = {
            loaded: false,
            assessments: {},

            district: '',
            completed: false,
            published: '',
            invoice_status: '',
            has_anonymous: null,
        };

        this.get_assessments = this.get_assessments.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    componentDidMount() {
        const url = '/assessment_overview/';

        ajaxWrapper('GET', url, {}, this.get_assessments);
    }

    get_assessments(result) {
        const assessments = [];

        for (const index in result) {
            const { assessment } = result[index];
            assessments.push(assessment);
        }

        this.setState({
            assessments,
            loaded: true,
        });
    }

    render() {
        // wrangle the data and give it to the sortable/comparison tables.
        var content = null;

        if (this.state.loaded) {
            const assessments = [];
            const invoice_statuses = [];
            const invoice_status_options = [];

            const assessment_list = this.state.assessments;
            if (this.state.has_anonymous) {
                assessment_list.sort((a, b) =>
                    a.anonymous_assessments < b.anonymous_assessments ? 1 : -1
                );
            }

            for (const index in assessment_list) {
                const assessment = assessment_list[index];
                if (invoice_statuses.indexOf(assessment.invoice_status) == -1) {
                    invoice_statuses.push(assessment.invoice_status);
                }

                if (
                    window.cmState.user.role != 'Super Admin' &&
                    assessment.completed
                ) {
                    continue;
                }
                if (
                    this.state.district &&
                    assessment.district.id != this.state.district
                ) {
                    continue;
                }
                if (
                    this.state.completed !== '' &&
                    assessment.completed != this.state.completed
                ) {
                    continue;
                }
                if (
                    this.state.published !== '' &&
                    assessment.published != this.state.published
                ) {
                    continue;
                }
                if (
                    this.state.invoice_status &&
                    assessment.invoice_status != this.state.invoice_status
                ) {
                    continue;
                }
                if (
                    this.state.has_anonymous &&
                    assessment.anonymous_assessments == 0
                ) {
                    continue;
                }
                if (
                    this.state.school_term &&
                    this.state.school_term != assessment.school_term.id
                ) {
                    continue;
                }
                if (
                    this.state.assessment_name &&
                    assessment.name
                        .toLowerCase()
                        .indexOf(this.state.assessment_name.toLowerCase()) == -1
                ) {
                    continue;
                }

                assessments.push(
                    <Assessment
                        assessment={assessment}
                        show_anonymous={this.state.has_anonymous}
                    />
                );
            }

            for (const status of invoice_statuses) {
                invoice_status_options.push({
                    text: status,
                    value: status,
                });
            }

            let anonymous_count = null;
            if (this.state.has_anonymous) {
                anonymous_count = <th>Anonymous Student Assessments</th>;
            }

            let admin_filters = null;
            if (window.cmState.user.role == 'Super Admin') {
                const defaults = {
                    district: this.state.district,
                    completed: this.state.completed,
                    published: this.state.published,
                    invoice_status: this.state.invoice_status,
                    has_anonymous: this.state.has_anonymous,
                };

                admin_filters = (
                    <Alert type="info">
                        <div style={{ marginBottom: '15px' }}>
                            <Button
                                type="primary"
                                href="/questions/"
                                text="View All Question Versions"
                            />
                            <Button
                                type="primary"
                                href="/question_concepts/"
                                text="View All Questions"
                            />
                            <Button
                                type="success"
                                href="/edit_assessment/"
                                text="Add New Assessment"
                            />
                        </div>
                        <FormWithChildren
                            defaults={defaults}
                            className="row"
                            autoSetGlobalState
                            setGlobalState={this.setGlobalState}
                            globalStateName="assessment"
                        >
                            <div className="col-6">
                                <TextInput
                                    name="assessment_name"
                                    label="Name"
                                />
                            </div>
                            <div className="col-3">
                                <Select
                                    optionsUrl="/api/sis/district/"
                                    optionsUrlMap={{
                                        text: '{district.name}',
                                        value: '{district.id}',
                                    }}
                                    name="district"
                                    label="District"
                                />
                            </div>
                            <div className="col-3">
                                <Select
                                    optionsUrl="/api/sis/schoolterm/"
                                    optionsUrlMap={{
                                        text: '{schoolterm.name}',
                                        value: '{schoolterm.id}',
                                    }}
                                    name="school_term"
                                    label="School Term"
                                />
                            </div>
                            <div className="col-3">
                                <Select
                                    boolean
                                    name="completed"
                                    label="Completed"
                                />
                            </div>
                            <div className="col-3">
                                <Select
                                    boolean
                                    name="published"
                                    label="Published"
                                />
                            </div>
                            <div className="col-3">
                                <Select
                                    options={invoice_status_options}
                                    name="invoice_status"
                                    label="Invoice Status"
                                />
                            </div>
                            <div className="col-3">
                                <Select
                                    boolean
                                    name="has_anonymous"
                                    label="Has Anonymous Student Assessments"
                                />
                            </div>
                        </FormWithChildren>
                    </Alert>
                );
            }

            var content = (
                <div className="container">
                    <h2>Assessments</h2>

                    {admin_filters}

                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>District</th>
                            <th>School Term</th>
                            <th>Grade Levels</th>
                            {anonymous_count}
                        </tr>

                        {assessments}
                    </table>
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default Assessments;
