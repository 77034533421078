import React, { Component } from 'react';

import { TextArea, Markdown, Icon } from 'library';

export default class MarkdownInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markdown: this.props.value,
        };

        this.handlechange = this.handlechange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ markdown: this.props.value });
        }
    }

    handlechange(event) {
        this.setState({ markdown: event.target.value });
        this.props.handlechange(event);
    }

    render() {
        return (
            <div>
                <label>{this.props.label}</label>
                <div className="row sad-simple-card-container">
                    <div className="col-6">
                        <div className="simple-card">{'Raw input'}</div>
                        <div className="simple-card">
                            <TextArea
                                {...this.props}
                                handlechange={this.handlechange}
                                label={null}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="simple-card">
                            <div style={{ display: 'inline-block' }}>
                                {'Markdown Rendered'}
                            </div>
                            <a
                                style={{
                                    display: 'inline-block',
                                    background: '#007bff',
                                    color: 'white',
                                    borderRadius: '100px',

                                    fontSize: '12px',
                                    padding: '3px 7px 1px',
                                    marginLeft: '10px',
                                }}
                                target="_blank"
                                href="https://www.markdownguide.org/basic-syntax/"
                            >
                                <Icon icon="question" size={1} />
                            </a>
                        </div>
                        <div className="simple-card">
                            <Markdown text={this.state.markdown} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
