import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { FormWithChildren, TextInput, Select, Alert } from 'library';
import TEXT_DICT from './translations.js';

export default class StudentLookup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            school_options: [],
            teacher_options: [],

            selected_school: null,
        };

        this.get_schools = this.get_schools.bind(this);
        this.load_schools = this.load_schools.bind(this);
        this.set_school = this.set_school.bind(this);
    }

    componentDidMount() {
        if (this.props.additional_details) {
            this.get_schools();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.additional_details && this.props.additional_details) {
            this.get_schools();
        }
    }

    get_schools() {
        ajaxWrapper(
            'GET',
            `/proctor/school_options/${this.props.district_id}/`,
            {},
            this.load_schools
        );
    }

    load_schools(value) {
        const lang = this.props.selected_language;
        const school_options = [
            { text: TEXT_DICT['School not listed'][lang], value: 'none' },
        ];
        for (const item of value.schools) {
            school_options.push({
                text: item.school.name,
                value: item.school.id,
            });
        }

        this.setState({
            school_options,
            teachers: value['teachers'],
        });
    }

    set_school(name, state) {
        if ('school' in state) {
            this.setState({
                selected_school: state['school'],
            });
        }
    }

    render() {
        const lang = this.props.selected_language;

        let id_label = TEXT_DICT['Student ID'][lang];
        if (
            this.props.assessment_id == '5bb3f7c6-bdee-47ef-8364-ad3e0dfc6b89'
        ) {
            id_label = 'Sequoia ID';
        }

        let additional_details = [];
        if (this.props.additional_details) {
            let teacher_options = [
                {
                    text: TEXT_DICT['Please Select A School'][lang],
                    value: '',
                },
            ];
            if (this.state.selected_school) {
                teacher_options = [];
                for (let item of this.state.teachers) {
                    let teacher = item['districtuser'];
                    if (teacher['school_id'] == this.state.selected_school) {
                        teacher_options.push({
                            text:
                                teacher['last_name'] +
                                ', ' +
                                teacher['first_name'],
                            value: teacher['id'],
                        });
                    }
                }
                teacher_options.push({
                    text: TEXT_DICT['Teacher not listed'][lang],
                    value: '',
                });
            }

            additional_details = [
                <Alert
                    animated
                    type="warning"
                    text={
                        TEXT_DICT[
                            'If you do not know your ID, please enter your School and Teacher.'
                        ][lang]
                    }
                />,
                <Select
                    key="school"
                    name="school"
                    options={this.state.school_options}
                    label={TEXT_DICT.School[lang]}
                    autocomplete="off"
                />,
                <Select
                    key="teacher"
                    name="teacher"
                    options={teacher_options}
                    label={TEXT_DICT.Teacher[lang]}
                    autocomplete="off"
                />,
                /*
                <TextInput
                    key="teacher"
                    name="teacher"
                    label={TEXT_DICT.Teacher[lang]}
                    autocomplete="off"
                />,
                */
            ];
        }

        const verification_details = [
            <br />,
            this.props.error,
            <TextInput
                key="student_id"
                name="student_id"
                label={id_label}
                autocomplete="off"
            />,
            <TextInput
                key="name"
                name="name"
                label={TEXT_DICT['Your Full Name'][lang]}
                autocomplete="off"
            />,
        ];

        verification_details.push(...additional_details);
        verification_details.push(<br />);

        return (
            <div>
                <h3>{TEXT_DICT['Enter Your Student Number'][lang]}</h3>
                <FormWithChildren
                    submit={this.props.validate_student}
                    submit_text={TEXT_DICT['Lookup Student'][lang]}
                    autoSetGlobalState
                    setGlobalState={this.set_school}
                >
                    {verification_details}
                </FormWithChildren>
            </div>
        );
    }
}
