import React from 'react';

import { Image, PageBreak } from 'library';

import ExplanationCards from './explanation_cards';

import QuestionEnlargeImage from 'pages/questions/enlarge_image.js';
const ExplanationsForQuestion = data => {
    return (
        <div
            style={{
                padding: '15pt',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0px auto',
                }}
            >
                <div style={{ border: 'thin solid #ccc', borderRadius: '3px' }}>
                    <QuestionEnlargeImage
                        image={data['question']['image']}
                        text={data['question']['text']}
                        height={220}
                    />
                </div>
                <div style={{ margin: '20pt 20pt 0pt', textAlign: 'center' }}>
                    <div
                        style={{
                            margin: '0pt 0pt',
                            fontSize: '16px',
                            fontWeight: '500',
                        }}
                    >
                        {data['question']['text']}
                    </div>
                    <div style={{ margin: '10pt 0pt' }}>
                        <div style={{ fontSize: '16px' }}>
                            The following data is a summary of your students'
                            responses to this problem.
                        </div>
                    </div>
                </div>
            </div>
            <div className="purple_arrow">
                <div
                    style={{
                        margin: '5pt 0pt',
                        fontSize: '18px',
                        fontWeight: '500',
                        textAlign: 'center',
                    }}
                >
                    Improving Mathematical Explanations
                </div>
                <div className="purple_arrow_tip_container">
                    <div className="purple_arrow_tip_hider">
                        <div className="purple_arrow_tip"></div>
                    </div>
                </div>
            </div>

            <ExplanationCards {...data} />

            <PageBreak style={{ margin: '40px 0px 60px' }} />
        </div>
    );
};

export default ExplanationsForQuestion;
