import React from 'react';
import ajaxWrapper from 'base/ajax.js';

import { FormWithChildren, TextInput, Icon, Wrapper, Image } from 'library';

class SearchItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { item } = this.props;
        let content = null;

        if (item.type == 'student') {
            content = (
                <div
                    className="global_search_item"
                    onClick={() =>
                        this.props.update_filters(
                            'student_list',
                            { student_id: item.value },
                            'Student Profiles'
                        )
                    }
                >
                    <Image
                        className="icon purple"
                        src="/static/images/reporting/Person.svg"
                        style={{ width: '20px', marginRight: '8px' }}
                    />
                    {item.name}
                </div>
            );
        } else if (item.type == 'school') {
            content = (
                <div
                    className="global_search_item"
                    onClick={() =>
                        this.props.update_filters('filter-direct', {
                            school: item.value,
                        })
                    }
                >
                    <Image
                        className="icon purple"
                        src="/static/images/reporting/School.svg"
                        style={{ width: '20px', marginRight: '8px' }}
                    />
                    {item.name}
                </div>
            );
        }

        return content;
    }
}

export default class GlobalSearchbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            search_timestamp: Date.now(),
            values: [],

            is_hovered: false,
            show: false,
        };

        this.search = this.search.bind(this);
        this.search_callback = this.search_callback.bind(this);

        this.show_tooltip = this.show_tooltip.bind(this);
        this.hide_tooltip = this.hide_tooltip.bind(this);
        this.on_hover = this.on_hover.bind(this);
        this.on_hover_exit = this.on_hover_exit.bind(this);
    }

    search(name, state) {
        const new_timestamp = Date.now();
        if (!state.search) {
            return false;
        }

        setTimeout(() => {
            if (this.state.search_timestamp == new_timestamp) {
                const data = {
                    search_string: state.search,
                    timestamp: new_timestamp,
                    filters: this.props.filters.direct,
                    compare_filters: this.props.filters.compare,
                };

                ajaxWrapper(
                    'POST',
                    `/reporting/results_explorer/global_search/${this.props.district_id}/`,
                    data,
                    this.search_callback
                );
                this.setState({
                    values: [],
                    loading: true,
                });
            }
        }, 250);

        this.setState({
            search_timestamp: new_timestamp,
        });
    }

    search_callback(value) {
        if (value.timestamp != this.state.search_timestamp) {
            return false;
        }

        this.setState({
            values: value.values,
            loading: false,
        });
    }

    show_tooltip() {
        if (!this.state.is_hovered) {
            return false;
        }

        this.setState({
            show: true,
        });
    }

    hide_tooltip() {
        if (this.state.is_hovered) {
            return false;
        }

        this.setState({
            show: false,
        });
    }

    on_hover() {
        this.setState({ is_hovered: true });

        setTimeout(() => {
            this.show_tooltip();
        }, 300);
    }

    on_hover_exit() {
        this.setState({ is_hovered: false });

        setTimeout(() => {
            this.hide_tooltip();
        }, 500);
    }

    render() {
        const wrapper_style = {
            display: 'none',
        };

        if (
            this.state.show &&
            (this.state.values.length > 0 || this.state.loading)
        ) {
            wrapper_style.display = 'block';
        }

        const results = [];
        for (const item of this.state.values) {
            results.push(
                <SearchItem
                    item={item}
                    update_filters={this.props.update_filters}
                />
            );
        }

        return (
            <div
                style={{ position: 'relative' }}
                onMouseEnter={this.on_hover}
                onMouseLeave={this.on_hover_exit}
            >
                <FormWithChildren
                    defaults={{}}
                    autoSetGlobalState
                    globalStateName="search"
                    setGlobalState={this.search}
                >
                    <TextInput
                        name="search"
                        style={{ width: '400px', border: 'none' }}
                        layout_style={{ margin: '0px' }}
                        placeholder="Search"
                        autocomplete="off"
                        right_hand_icon={
                            <Icon
                                size={1}
                                icon_type="fas"
                                icon="search purple-text"
                                style={{ padding: '10px' }}
                            />
                        }
                    />
                </FormWithChildren>
                <div className="global_search_container" style={wrapper_style}>
                    <Wrapper
                        loaded={!this.state.loading}
                        style={{ margin: '0px' }}
                    >
                        {results}
                    </Wrapper>
                </div>
            </div>
        );
    }
}
