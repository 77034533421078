import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import {} from 'library';

export default class SenseMaking extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const table_headers = [];
        const table_rows = [];

        const data_headers = this.props.data.headers;
        for (const header_index in data_headers) {
            table_headers.push(<th>{data_headers[header_index]}</th>);
        }

        const data_rows = this.props.data.rows;
        for (const row_index in data_rows) {
            const row = data_rows[row_index];
            const temp_row = [];
            for (const col_index in row) {
                temp_row.push(<td>{row[col_index]}</td>);
            }
            table_rows.push(<tr>{temp_row}</tr>);
        }

        return (
            <div className="result-display-card">
                <h4>Sense Making</h4>
                <CSVLink
                    filename="MathAnex_StudentSenseMaking.csv"
                    data={this.props.data.csv}
                    style={{ display: 'inline-block' }}
                >
                    <div className="purple-button">Download Data</div>
                </CSVLink>
                <table className="table">
                    {table_headers}
                    {table_rows}
                </table>
            </div>
        );
    }
}
