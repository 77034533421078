const TEXT_DICT = {
    //// NEEDS REVIEW

    // User validation and URL error messages

    bad_district_id_error: {
        English:
            'The URL you have entered does not match a district.  Please check your URL, or return to the login page.',
        Spanish:
            'La URL que ingresó no coincide con un distrito. Verifique su URL o regrese a la página de inicio de sesión.',
        Vietnamese:
            'URL bạn đã nhập không khớp với quận. Vui lòng kiểm tra URL của bạn hoặc quay lại trang đăng nhập.',
        Tagalog:
            'Ang URL na iyong inilagay ay hindi tumutugma sa isang distrito. Pakisuri ang iyong URL, o bumalik sa pahina ng pag-login.',
    },
    no_active_assessments_error: {
        English:
            'No active assessments were found for this district.  Please check your URL.',
        Spanish:
            'No se encontraron evaluaciones activas para este distrito. Por favor, compruebe su URL.',
        Vietnamese:
            'Không có đánh giá tích cực đã được tìm thấy cho huyện này. Vui lòng kiểm tra URL của bạn.',
        Tagalog:
            'Walang nakitang aktibong pagtatasa para sa distritong ito. Pakisuri ang iyong URL.',
    },
    bad_assessment_id_error: {
        English:
            'The URL you have entered does not match an assessment.  Please choose an option below.',
        Spanish:
            'La URL que ingresó no coincide con una evaluación. Elija una opción a continuación.',
        Vietnamese:
            'URL bạn đã nhập không khớp với đánh giá. Vui lòng chọn một tùy chọn bên dưới.',
        Tagalog:
            'Ang URL na iyong inilagay ay hindi tumutugma sa isang pagtatasa. Mangyaring pumili ng opsyon sa ibaba.',
    },
    supported_language_error: {
        English:
            'The lanaguage you have selected is not supported by this assessment.  Please ask for assistance from your Teacher.',
        Spanish:
            'El idioma que ha seleccionado no es compatible con esta evaluación. Pide ayuda a tu profesor.',
        Vietnamese:
            'Ngôn ngữ bạn đã chọn không được đánh giá này hỗ trợ. Hãy yêu cầu sự giúp đỡ từ Giáo viên của bạn.',
        Tagalog:
            'Ang wikang pinili mo ay hindi sinusuportahan ng pagtatasa na ito. Mangyaring humingi ng tulong mula sa iyong Guro.',
    },
    'Change Language': {
        English: 'Change Language',
        Spanish: 'Cambiar idioma',
        Vietnamese: 'Thay đổi ngôn ngữ',
        Tagalog: 'Baguhin ang Wika',
    },
    'Click here if you would like to hear this question read aloud': {
        English:
            'Click here if you would like to hear this question read aloud',
        Spanish: 'Haga clic aquí si desea escuchar esta pregunta en voz alta',
        Vietnamese: 'Bấm vào đây nếu bạn muốn nghe đọc to câu hỏi này',
        Tagalog:
            'Mag-click dito kung gusto mong marinig ang tanong na ito na basahin nang malakas',
    },
    'I would like to see assessments outside of my grade': {
        English: 'I would like to see assessments outside of my grade',
        Spanish: 'Me gustaría ver evaluaciones fuera de mi calificación',
        Vietnamese: 'Tôi muốn xem các đánh giá ngoài điểm của tôi',
        Tagalog: 'Gusto kong makakita ng mga pagtatasa sa labas ng aking grado',
    },

    'If you do not know your ID, please enter your School and Teacher.': {
        English:
            'If you do not know your ID, please enter your School and Teacher.',
        Spanish: 'Si no conoce su ID, ingrese su Escuela y Profesor.',
        Vietnamese:
            'Nếu bạn không biết ID của mình, vui lòng nhập Trường học và Giáo viên của bạn.',
        Tagalog:
            'Kung hindi mo alam ang iyong ID, mangyaring ilagay ang iyong Paaralan at Guro.',
    },
    'School not listed': {
        English: 'School not listed',
        Spanish: 'Escuela no listado',
        Vietnamese: 'Trường học không được liệt kê',
        Tagalog: 'Hindi nakalista ang paaralan',
    },
    'Teacher not listed': {
        English: 'Teacher not listed',
        Spanish: 'Profesor no listado',
        Vietnamese: 'Giáo viên không được liệt kê',
        Tagalog: 'Hindi nakalista ang guro',
    },
    'Please Select A School': {
        English: 'Please Select A School',
        Spanish: 'Por favor seleccione una escuela',
        Vietnamese: 'Vui lòng chọn trường',
        Tagalog: 'Mangyaring pumili ng paaralan',
    },
    'No active assessments matching your grade': {
        English: 'No active assessments matching your grade',
        Spanish:
            'No hay evaluaciones activas que coincidan con su calificación ',
        Vietnamese:
            'Không có bài đánh giá tích cực nào phù hợp với điểm của bạn',
        Tagalog: 'Walang aktibong pagtatasa na tumutugma sa iyong grado',
    },

    //// ALREADY REVIEWED

    // Options first displayed to user
    English: {
        English: 'I speak English',
    },
    Spanish: {
        Spanish: 'Yo hablo Español',
    },
    Vietnamese: {
        Vietnamese: 'Tôi nói Tiếng Việt',
    },
    Tagalog: {
        Tagalog: 'Nagsasalita ako ng Tagalog',
    },

    // General translations

    'Invalid Id': {
        English: 'Invalid Id',
        Spanish: 'Identificación inválida',
        Vietnamese: 'ID không hợp lệ',
        Tagalog: 'Di-wastong Id',
    },

    'Enter Your Student Number': {
        English: 'Enter Your Student Number',
        Spanish: 'Ingresa tu número de estudiante',
        Vietnamese: 'Nhập mã số sinh viên của bạn',
        Tagalog: 'Ilagay ang Iyong Student Number',
    },
    'Lookup Student': {
        English: 'Submit',
        Spanish: 'Enviar',
        Vietnamese: 'Gửi',
        Tagalog: 'Isumite',
    },
    'Thank you for completing this assessment!  Feel free to revisit any questions, or close this tab.':
        {
            English:
                'Great job completing this assessment!  Feel free to revisit any questions, or click the submit button if you are done.',
            Spanish:
                '¡Gracias por completar esta evaluación! Siéntete libre de revisar cualquier pregunta, o haz clic en el botón Enviar si haz terminado.',
            Vietnamese:
                'Cảm ơn bạn đã hoàn thành đánh giá này! Xin hãy xem lại bất kỳ câu hỏi nào hoặc đóng tab này.',
            Tagalog:
                'Maraming salamat sa pagkuha ng pagsusuring ito. Huwag mag-atubiling balikan ang anumang katanungan, o i-click ang submit button kung ikaw ay tapos na.',
        },
    'Your assessment has been submitted successfully! You can now close your tab.':
        {
            English:
                'Your assessment has been submitted successfully! You can now close your tab.',
            Spanish:
                '¡Tu evaluación ha sido enviada con éxito! Ahora puedes cerrar tu pestaña.',
            Vietnamese:
                'Bài đánh giá của bạn đã được gửi thành công! Bây giờ bạn có thể đóng tab của mình.',
            Tagalog:
                'Ang iyong pagsusuri ay matagumpay na naisumite! Maaari mo na ngayong isara ang iyong tab.',
        },
    'You have reached the end of this assessment.  Feel free to revisit any questions, or close this tab to complete the assessment.':
        {
            English:
                'You have reached the end of this assessment.  Feel free to revisit any questions, or close this tab to complete the assessment.',
            Spanish:
                'Has llegado al final de esta evaluación. Siéntete libre de revisar cualquier pregunta, o cerrar esta pestaña para completar la evaluación.',
            Vietnamese:
                'Bạn đã hoàn thành bài đánh giá này. Bạn có thể xem lại bất kỳ câu hỏi nào hoặc đóng tab này để hoàn thành bài đánh giá.',
            Tagalog:
                'Naabot mo na ang dulo ng pagsusuring ito. Huwag mag-atubiling balikan muli ang anumang katanungan, o isara ang tab na ito upang makumpleto ang pagsusuri.',
        },
    'Student ID': {
        English: 'Student ID',
        Spanish: 'Identificación del Estudiante',
        Vietnamese: 'ID học sinh',
        Tagalog: 'Student ID',
    },
    'Your Full Name': {
        English: 'Your Full Name',
        Spanish: 'Tu nombre completo',
        Vietnamese: 'Họ và tên của bạn',
        Tagalog: 'Ang Iyong Buong Pangalan',
    },
    'Forgot your Student ID?': {
        English: 'Forgot your Student ID?',
        Spanish: '¿Olvidaste tu identificación de estudiante?',
        Vietnamese: 'Bạn quên ID học sinh của mình?',
        Tagalog: 'Nakalimutan mo ba ang iyong Student ID?',
    },
    'Enter your name instead.': {
        English: 'Enter your name instead.',
        Spanish: 'Ingresa tu nombre en su lugar.',
        Vietnamese: 'Nhập tên của bạn thay thế.',
        Tagalog: 'Ilagay ang iyong pangalan sa halip.',
    },
    'Student found successfully': {
        English: 'Student found successfully',
        Spanish: 'Estudiante encontrado exitosamente',
        Vietnamese: 'Đã tìm được học sinh',
        Tagalog: 'Matagumpay na natagpuan ang estudyante',
    },
    'Begin Assessment': {
        English: 'Begin Assessment',
        Spanish: 'Comenzar evaluación',
        Vietnamese: 'Bắt đầu bài đánh giá',
        Tagalog: 'Simulan ang Pagsusuri',
    },
    Question: {
        English: 'Question',
        Spanish: 'Pregunta',
        Vietnamese: 'Câu hỏi',
        Tagalog: 'Tanong',
    },
    Context: {
        English: 'Context For The Upcoming Questions',
        Spanish: 'Contexto para las próximas preguntas',
        Vietnamese: 'Bối cảnh cho câu hỏi tiếp theo',
        Tagalog: 'Konteksto Para Sa Mga Susunod na Katanungan',
    },
    'Previous Question': {
        English: 'Previous Question',
        Spanish: 'Pregunta anterior',
        Vietnamese: 'Câu hỏi trước',
        Tagalog: 'Nakaraang Tanong',
    },
    'Next Question': {
        English: 'Next Question',
        Spanish: 'Próxima pregunta',
        Vietnamese: 'Câu hỏi tiếp theo',
        Tagalog: 'Susunod na Tanong',
    },
    'Skipped Questions:': {
        English: 'Skipped Questions:',
        Spanish: 'Preguntas saltadas:',
        Vietnamese: 'Câu hỏi đã bỏ qua',
        Tagalog: 'Mga Nilaktawang Tanong',
    },
    'Questions:': {
        English: 'Questions:',
        Spanish: 'Preguntas:',
        Vietnamese: 'Câu hỏi',
        Tagalog: 'Mga tanong',
    },
    'Go to Question': {
        English: 'Go to Question',
        Spanish: 'Ir a la Pregunta',
        Vietnamese: 'Chuyển đến câu hỏi',
        Tagalog: 'Pumunta sa Tanong',
    },
    'Please enter an ID and Name.': {
        English: 'Please enter an ID and Name.',
        Spanish: 'Por favor ingrese una identificación y un nombre.',
        Vietnamese: 'Vui lòng nhập ID và Tên.',
        Tagalog: 'Mangyaring i-type ang iyong ID at Pangalan.',
    },
    'The Student ID you submitted is invalid.': {
        English: 'The Student ID you submitted is invalid.',
        Spanish: 'La identificación de estudiante que ingresaste no es válida.',
        Vietnamese: 'ID Học sinh bạn đã gửi không hợp lệ.',
        Tagalog: 'Ang iyong isinumiteng Student ID ay hindi wasto.',
    },
    'The Student ID and Name you submitted do not match.  Are you sure your number is correct?':
        {
            English:
                'The Student ID and Name you submitted do not match.  Please check your student number and make sure it is correct. If you cannot remember it, only input your name.',
            Spanish:
                'La identificación de estudiante y el nombre que ingresaste no coinciden. Verifica tu número de estudiante y asegúrate que sea correcto. Si no puedes recordarlo, solo ingresa tu nombre.',
            Vietnamese:
                'ID Học sinh và Tên bạn đã gửi không khớp. Bạn có chắc đã nhập đúng mã số không?',
            Tagalog:
                'Ang Student ID at Pangalan na iyong isinumite ay hindi tugma. Mangyaring suriing mabuti ang iyong student number at tiyaking tama ito. Kung hindi mo ito maalala, maaari mong ilagay lamang ang iyong pangalan.',
        },
    'Please fill in all requested fields.': {
        English: 'Please fill in all requested fields.',
        Spanish: 'Por favor completa todos los campos solicitados.',
        Vietnamese: 'Vui lòng điền vào tất cả các trường được yêu cầu.',
        Tagalog: 'Mangyaring punan ang lahat ng mga hinihinging patlang.',
    },
    'Your account is not associated with this District.  Please check the URL you entered.':
        {
            English:
                'Your account is not associated with this District.  Please check the URL you entered.',
            Spanish:
                'Tu cuenta no está asociada con este Distrito. Verifique la URL que ingresaste.',
            Vietnamese:
                'Tài khoản của bạn không được liên kết với Khu vực này. Xin hãy kiểm tra URL bạn đã nhập.',
            Tagalog:
                'Ang iyong account ay hindi nauugnay sa Distritong ito. Pakisuri ang URL na iyong inilagay.',
        },
    'Submit Assessment': {
        English: 'Submit Assessment',
        Spanish: 'Enviar evaluación',
        Vietnamese: 'Gửi bài đánh giá',
        Tagalog: 'Isumite ang Pagsusuri',
    },
    'Submit Answer': {
        English: 'Submit Answer',
        Spanish: 'Enviar respuesta',
        Vietnamese: 'Gửi câu trả lời',
        Tagalog: 'Isumite ang Sagot',
    },
    'Skip Question': {
        English: 'Skip Question',
        Spanish: 'Saltar pregunta',
        Vietnamese: 'Bỏ qua câu hỏi',
        Tagalog: 'Laktawan ang Tanong',
    },
    Answer: {
        English: 'Answer',
        Spanish: 'Respuesta',
        Vietnamese: 'Trả lời',
        Tagalog: 'Sagot',
    },
    'Numbers Only': {
        English: 'Numbers Only',
        Spanish: 'Números Solamente',
        Vietnamese: 'Chỉ điền số',
        Tagalog: 'Numero Lamang',
    },
    'Optional To Use': {
        English: 'Optional To Use',
        Spanish: 'Opcional para usar',
        Vietnamese: 'Tùy chọn sử dụng',
        Tagalog: 'Opsyonal na Gamitin',
    },
    'Scratch Work': {
        English: 'Scratch Paper',
        Spanish: 'Papel de Borrador',
        Vietnamese: 'Giấy nháp',
        Tagalog: 'Scratch Paper',
    },
    'Welcome!': {
        English: 'Welcome!',
        Spanish: '¡Bienvenidos!',
        Vietnamese: 'Chào mừng bạn!',
        Tagalog: 'Maligayang pagdating!',
    },
    'Following Questions': {
        English:
            'Mathematics is not just about calculating. It is also important to be able to solve problems in context and communicate your reasoning in writing. This assessment is an opportunity to showcase how you solve problems and explain your thinking so your teacher can have window into how you think mathematically.',
        Spanish:
            'Las matemáticas no se tratan solo de calcular. También es importante poder resolver problemas en contexto y comunicar su razonamiento por escrito. Esta evaluación es una oportunidad para mostrar cómo resuelves problemas y explicas tu forma de pensar para que tu profesor pueda tener una idea de cómo piensas matemáticamente.',
        Vietnamese:
            'Toán học không chỉ là tính toán. Khả năng giải quyết vấn đề trong ngữ cảnh và truyền đạt lý luận của bạn bằng văn bản cũng rất quan trọng. Đánh giá này là một cơ hội để giới thiệu cách bạn giải quyết vấn đề và giải thích suy nghĩ của bạn để giáo viên của bạn có thể nhìn thấy cách bạn suy nghĩ về mặt toán học.',
        Tagalog:
            'Ang matematika ay hindi lamang tungkol sa pagkalkula. Importante ding malaman ang mga konteksto sa pagsagot ng mga problema at maipahayag ang iyong pangangatwiran sa pamamagitan ng pagsusulat. Ang pagsusuring ito ay isang paraan para ipamalas kung paano mo sagutin ang mga problema at ipaliwanag kung paano ka mag-isip upang malaman ng iyong guro kung paano ka mag-isip sa ngalan ng matematika.',
    },
    'Questions Range': {
        English:
            "Some questions might seem easy and some might seem challenging. Just be sure to try your best on every item, even if you aren't quite sure of the answer.",
        Spanish:
            'Algunas preguntas pueden parecer fáciles y otras pueden parecer desafiantes. Solo asegúrate de hacer tu mejor esfuerzo en cada elemento, incluso si no estas tan seguro de la respuesta.',
        Vietnamese:
            'Một số câu hỏi có vẻ dễ và một số có vẻ khó. Chỉ cần đảm bảo cố gắng hết sức với mọi mục, ngay cả khi bạn không chắc chắn về câu trả lời.',
        Tagalog:
            'Ang ilan sa mga tanong ay maaaring mukhang madali at ang ilan naman ay maaaring mukhang mahirap. Siguraduhing gagawin mo ang iyong makakaya sa bawat item, kahit na hindi ka sigurado sa sagot.',
    },
    'Go Back To Review Page': {
        English: 'Go Back To Review Page',
        Spanish: 'Volver a la Página de Revisión',
        Vietnamese: 'Quay lại trang đánh giá',
        Tagalog: 'Bumalik Sa Review Page',
    },
    Skipped: {
        English: 'Skipped',
        Spanish: 'Saltar',
        Vietnamese: 'Đã bỏ qua',
        Tagalog: 'Nilaktawan',
    },
    Login: {
        English: 'Log In',
        Spanish: 'Ingresar',
        Vietnamese: 'Đăng nhập',
        Tagalog: 'Mag-log in',
    },
    'Clever Unknown': {
        English: "I don't know my Clever Login.",
        Spanish: 'No se mi Ingreso de Clever.',
        Vietnamese: 'Tôi không biết Đăng nhập Clever của mình.',
        Tagalog: 'Hindi ko alam ang aking Clever Login.',
    },
    'Classlink Unknown': {
        English: "I don't know my ClassLink Login.",
        Spanish: 'No se mi Ingreso de ClassLink.',
        Vietnamese: 'Tôi không biết Đăng nhập ClassLink của mình.',
        Tagalog: 'Hindi ko alam ang aking ClassLink Login.',
    },
    'Date Of Birth': {
        English: 'Date Of Birth',
        Spanish: 'Fecha de Nacimiento',
        Vietnamese: 'Ngày sinh',
        Tagalog: 'Araw ng Kapanganakan',
    },
    School: {
        English: 'School',
        Spanish: 'Escuela',
        Vietnamese: 'Trường học',
        Tagalog: 'Paaralan',
    },
    Teacher: {
        English: 'Teacher',
        Spanish: 'Maestro/a',
        Vietnamese: 'Giáo viên',
        Tagalog: 'Guro',
    },

    warning_outside_window: {
        English:
            'The window for this assessment has closed.  Please check that this is the correct assessment before moving forward.',
        Spanish:
            'La ventana para esta evaluación se ha cerrado. Verifica que esta sea la evaluación correcta antes de continuar.',
        Vietnamese:
            'Cửa sổ cho đánh giá này đã đóng lại. Vui lòng kiểm tra xem đây có phải là đánh giá chính xác trước khi tiếp tục.',
        Tagalog:
            'Ang window para sa pagsusuring ito ay tapos na. Pakitiyak na ito ang tamang pagsusuri bago tumuloy.',
    },
    error_already_completed: {
        English:
            'You have already taken this assessment and cannot take it again.  If you think we made a mistake please contact our support.',
        Spanish:
            'Ya has tomado esta evaluación y no puedes volver a tomarla. Si crees que cometimos un error, comunícate con nuestro apoyo.',
        Vietnamese:
            'Bạn đã thực hiện đánh giá này và không thể thực hiện lại. Nếu bạn nghĩ rằng chúng tôi đã phạm sai lầm, vui lòng liên hệ với bộ phận hỗ trợ của chúng tôi.',
        Tagalog:
            'Natapos mo na ang pagsusuring ito at hindi na maaring balikan. Kung sa tingin mo ay nagkamali kami, mangyaring makipag-ugnayan sa aming support.',
    },
    error_already_graded: {
        English:
            'This assessment has already been analyzed and can no longer be taken. For a special exception, please contact our support: support@mathanex.com',
        Spanish:
            'Esta evaluación ya ha sido calificada y ya no se puede tomar. Para una excepción especial, ponte en contacto con nuestro apoyo: support@mathanex.com',
        Vietnamese:
            'Bài đánh giá này đã được chấm điểm và không thể thực hiện được nữa. Đối với một ngoại lệ đặc biệt, vui lòng liên hệ với bộ phận hỗ trợ của chúng tôi: support@mathanex.com',
        Tagalog:
            'Ang pagsusuring ito ay namarkahan na at hindi na maaaring balikan. Para sa isang espesyal na eksepsyon, mangyaring makipag-ugnayan sa aming support: support@mathanex.com',
    },
    warning_before_window: {
        English:
            'This assessment has not been released yet.  Please return on the following date, or contact our support.',
        Spanish:
            'Esta evaluación aún no se ha publicado. Vuelve en la siguiente fecha o ponte en contacto con nuestro apoyo.',
        Vietnamese:
            'Đánh giá này vẫn chưa được công bố. Vui lòng quay lại vào ngày sau hoặc liên hệ với bộ phận hỗ trợ của chúng tôi.',
        Tagalog:
            'Ang pagsusuring ito ay hindi pa inilalabas. Mangyaring bumalik sa mga sumusunod na petsa, o makipag-ugnayan sa aming support.',
    },
    'Start Date: ': {
        English: 'Start Date: ',
        Spanish: 'Fecha de Inicio: ',
        Vietnamese: 'Ngày bắt đầu: ',
        Tagalog: 'Petsa ng Pagsisimula: ',
    },
    'End Date: ': {
        English: 'End Date: ',
        Spanish: 'Fecha Final: ',
        Vietnamese: 'Ngày cuối: ',
        Tagalog: 'Petsa ng Pagtatapos: ',
    },
};

export default TEXT_DICT;
