import React, { Component } from 'react';
import { FormWithChildren, TextInput, TextArea, Button, Select } from 'library';
import TEXT_DICT from './translations.js';

export default class Question extends Component {
    constructor(props) {
        super(props);

        this.state = this.get_defaults();

        this.track_changes = this.track_changes.bind(this);
        this.zoom_in = this.zoom_in.bind(this);
        this.zoom_out = this.zoom_out.bind(this);
        this.submit_question = this.submit_question.bind(this);
    }

    track_changes(name, state) {
        this.setState(state);
        if (this.props.track_event_details) {
            this.props.track_event_details({ type: 'input', value: state });
        }
    }

    submit_question() {
        const { question } = this.props;

        //Convert answer into string
        if (question.answer_type == 'multi_select' && 'answer' in this.state) {
            this.state.answer = JSON.stringify(this.state.answer);
        }

        this.props.submit_question(this.state);
    }

    get_defaults() {
        const { question } = this.props;

        const defaults = {
            answer: '',
        };
        if (question.answer_type == 'multi_select') {
            defaults['answer'] = [];
        }

        if (
            question.id in this.props.question_answers &&
            this.props.question_answers[question.id].answer
        ) {
            if (
                question.answer_type == 'multi_select' &&
                typeof this.props.question_answers[question.id].answer ==
                    'string'
            ) {
                let cleaned_answers = this.props.question_answers[
                    question.id
                ].answer.replaceAll("'", '"');
                defaults.answer = JSON.parse(cleaned_answers);
            } else {
                defaults.answer =
                    this.props.question_answers[question.id].answer;
            }
        }

        if (
            question.explanation_question &&
            question.explanation_question.id in this.props.question_answers
        ) {
            defaults.explanation =
                this.props.question_answers[
                    question.explanation_question.id
                ].answer;
        }

        return defaults;
    }

    zoom_in() {
        this.props.change_zoom('in');
    }

    zoom_out() {
        this.props.change_zoom('out');
    }

    create_question_input(name, question, question_text) {
        const lang = this.props.language;

        let input = (
            <TextInput name={name} label={question_text} autocomplete="off" />
        );
        if (this.props.printable) {
            input = (
                <TextArea
                    name={name}
                    label={question_text}
                    rows={8}
                    disabled={true}
                />
            );
        }

        if (question.answer_type == 'numeric') {
            input = (
                <TextInput
                    name={name}
                    label={question_text}
                    autocomplete="off"
                />
            );
            if (this.props.printable) {
                input = (
                    <TextArea
                        name={name}
                        label={question_text}
                        rows={8}
                        disabled={true}
                    />
                );
            }
        } else if (question.answer_type == 'multiple_choice') {
            var options = [];
            var count = 0;

            for (var item of question.multiple_choices_by_language) {
                var text = item[lang];
                if (this.props.printable) {
                    text = (
                        <div>
                            <div className="select-box"></div>
                            <div style={{ marginLeft: '32px' }}>
                                {item[lang]}
                            </div>
                        </div>
                    );
                }

                options.push({
                    text,
                    value: item.English,
                    order: count,
                });

                count += 1;
            }

            options.sort((a, b) => (a.order > b.order ? 1 : -1));
            if (this.props.printable) {
                input = (
                    <Select
                        radio
                        options={options}
                        name={name}
                        label={question_text}
                        button_style={{
                            display: 'block',
                            width: 'fit-content',
                            padding: '5px 10px',
                            fontWeight: '400',
                            color: '#212529',
                            borderColor: '#ccc',
                        }}
                    />
                );
            } else {
                input = (
                    <Select
                        radio
                        options={options}
                        name={name}
                        label={question_text}
                    />
                );
            }
        } else if (question.answer_type == 'multi_select') {
            var options = [];
            var count = 0;

            for (var item of question.multiple_choices_by_language) {
                var text = item[lang];

                options.push({
                    text,
                    value: item.English,
                    order: count,
                });

                count += 1;
            }

            options.sort((a, b) => (a.order > b.order ? 1 : -1));
            input = (
                <Select
                    radio
                    options={options}
                    name={name}
                    label={question_text}
                    multiple
                />
            );
        } else if (question.answer_type == 'no_answer') {
            input = null;
        }

        if (question.category.name == 'Explanation' || question.category.name == 'Field Test') {
            input = (
                <TextArea
                    name={name}
                    label={question_text}
                    autocomplete="off"
                />
            );

            if (this.props.printable) {
                input = (
                    <TextArea
                        name={name}
                        label={question_text}
                        rows={16}
                        disabled={true}
                    />
                );
            }
        }

        return input;
    }

    render() {
        const defaults = this.get_defaults();

        const { question } = this.props;
        const lang = this.props.language;
        const question_text = question.text_by_language[lang];
        const input = this.create_question_input(
            'answer',
            question,
            question_text
        );

        let explanation = null;
        if (question.explanation_question) {
            const explanation_question_text =
                question.explanation_question.text_by_language[lang];
            explanation = this.create_question_input(
                'explanation',
                question.explanation_question,
                explanation_question_text
            );
        }

        let submit_text = TEXT_DICT['Skip Question'][lang];
        let submit_button_type = 'warning';
        if (
            ('answer' in this.state && this.state.answer) ||
            ('explanation' in this.state && this.state.explanation)
        ) {
            submit_text = TEXT_DICT['Submit Answer'][lang];
            submit_button_type = 'success';
        } else if (question.answer_type == 'no_answer') {
            submit_text = TEXT_DICT['Next Question'][lang];
            submit_button_type = 'success';
        }

        const question_form = (
            <FormWithChildren
                submit_on_enter={false}
                submitClassName="proctor-submit-button"
                defaults={defaults}
                autoSetGlobalState
                setGlobalState={this.track_changes}
            >
                <TextInput
                    style={{ display: 'none' }}
                    name="question"
                    value={question.id}
                />
                {input}
                {explanation}
            </FormWithChildren>
        );

        let submit_button = (
            <Button
                text={submit_text}
                type={submit_button_type}
                onClick={this.submit_question}
                can_only_be_clicked_once={true}
            />
        );
        if (this.props.printable) {
            submit_button = null;
        }

        const question_accessibility = (
            <div
                className="row"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div
                    className="col-12"
                    style={{
                        paddingTop: '10px',
                        paddingLeft: '0px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {submit_button}
                    {this.props.prev_question}
                </div>
            </div>
        );

        return (
            <div style={{ marginBottom: '10px' }}>
                {question_form}
                {question_accessibility}
            </div>
        );
    }
}
