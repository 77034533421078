import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Button,
    Alert,
    Wrapper,
} from 'library';

class ReviewAssessmentResponses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            assessment: {},
            error: '',
            student_assessments: [],
            offset: 0,
        };

        this.get_assessment = this.get_assessment.bind(this);
        this.change_offset = this.change_offset.bind(this);
        this.get_student_assessments = this.get_student_assessments.bind(this);
        this.refresh_student_assessments =
            this.refresh_student_assessments.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/assessment/${this.props.assessment_id
            }/?related=questions_on_assessments,questions_on_assessments__question`,
            {},
            this.get_assessment
        );
        this.refresh_student_assessments();
    }

    refresh_student_assessments() {
        ajaxWrapper(
            'GET',
            `/api/home/studentassessment/?order_by=-updated_at&assessment=${this.props.assessment_id
            }&related=student,student_question_responses&limit=10&offset=${this.state.offset}`,
            {},
            this.get_student_assessments
        );
    }

    get_assessment(result) {
        const { assessment } = result[0];

        this.setState({ assessment, loaded: true });
    }

    get_student_assessments(result) {
        console.log('Student Assessments', result);
        const student_assessments = [];
        for (const index in result) {
            const student_assessment = result[index].studentassessment;
            const question_dict = {};
            for (const question_index in student_assessment.student_question_responses) {
                const response =
                    student_assessment.student_question_responses[
                        question_index
                    ].studentquestionresponse;
                question_dict[response.question_id] = response.answer;
            }
            student_assessment.question_responses_dict = question_dict;
            student_assessments.push(student_assessment);
        }

        this.setState({
            student_assessments,
            loaded: true,
        });
    }

    change_offset(num) {
        this.setState(
            { offset: this.state.offset + num },
            this.refresh_student_assessments
        );
    }

    render() {
        let content = null;

        if (this.state.loaded) {
            const table_headers = [<th>Student ID</th>];
            const question_ordering = [];
            for (var index in this.state.assessment.questions_on_assessments) {
                const { question } = this.state.assessment.questions_on_assessments[index]
                    .questiononassessment;
                if (question) {
                    question_ordering.push(question.id);
                    table_headers.push(<th>{question.name}</th>);
                }
            }

            const student_responses = [];
            for (var index in this.state.student_assessments) {
                const student_assessment = this.state.student_assessments[index];
                const row = [<td>{student_assessment.student.student_id}</td>];
                for (const question_index in question_ordering) {
                    row.push(
                        <td>
                            {
                                student_assessment.question_responses_dict[
                                question_ordering[question_index]
                                ]
                            }
                        </td>
                    );
                }
                student_responses.push(<tr>{row}</tr>);
            }

            const response_table = (
                <table className="table">
                    <tr>{table_headers}</tr>
                    {student_responses}
                </table>
            );

            let error = null;
            if (this.state.error != '') {
                error = <Alert type="danger" text={this.state.error} />;
            }

            let prev = null;
            if (this.state.offset > 0) {
                prev = (
                    <Button
                        type="danger"
                        text="Previous Answers"
                        onClick={() => this.change_offset(-10)}
                    />
                );
            }
            const next = (
                <Button
                    type="primary"
                    text="Next Answers"
                    onClick={() => this.change_offset(10)}
                />
            );

            content = (
                <div className="container">
                    <h2>
                        Responses to Assessment: {this.state.assessment.name}
                    </h2>

                    {error}

                    {response_table}

                    {prev}
                    {next}
                </div>
            );
        }

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default ReviewAssessmentResponses;
