import React, { Component } from 'react';
import { prettifyString } from 'functions';


class DictDisplay extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        let ordered_keys = this.props.ordered_keys;
        if (!ordered_keys){
            ordered_keys = Object.keys(this.props.dict).sort();
        }
        
        let dict_jsx = [];
        for (let key of ordered_keys){
            let pretty_key = prettifyString(key);

            let value = this.props.dict[key];
            let pretty_value = '';
            if (typeof value === 'string'){
                pretty_value = prettifyString(value);
            }
            else {
                pretty_value = JSON.stringify(value);
            }

            dict_jsx.push(<div className='row'>
                <div className='col-12'>{`${pretty_key}: ${pretty_value}`}</div>
            </div>);
        }

        return (
            <div>
                {dict_jsx}
            </div>
        );
    }
}

export default DictDisplay;
