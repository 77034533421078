import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { Button, Alert, Icon } from 'library';
import QuestionDisplay from 'pages/questions/image.js';

class QuestionFieldTestGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
        };

        this.decrease_question_order = this.decrease_question_order.bind(this);
        this.increase_question_order = this.increase_question_order.bind(this);

        this.delete = this.delete.bind(this);
        this.replace_question = this.replace_question.bind(this);
        this.add_field_test_to_group = this.add_field_test_to_group.bind(this);
    }

    decrease_question_order() {
        this.props.change_group_order(this.props.group.order, this.props.previous_group.order);
    }

    increase_question_order() {
        this.props.change_group_order(this.props.group.order, this.props.next_group.order);
    }

    delete(id) {
        ajaxWrapper(
            'POST',
            `/api/home/questiononassessment/${id}/delete/`,
            {},
            this.props.refresh_assessment
        );
    }

    replace_question(question_on_assessment) {
        this.props.choose_question(question_on_assessment, undefined, 'Field Test');
    }

    add_field_test_to_group() {
        this.props.choose_question(undefined, this.props.group.order, 'Field Test');
    }

    render() {
        let button_icon_style = {
            width: '15px',
            height: '15px',
        };

        let group_jsx = [];
        if (this.props.group.questions_on_assessment.length > 0) {

            this.props.group.questions_on_assessment.forEach(questionOnAssessment => {
                let delete_button = null;
                if (questionOnAssessment.id) {
                    delete_button = (
                        <Button
                            type="danger"
                            deleteType
                            text={<Icon size={1} icon="times" style={button_icon_style} />}
                            style={{ minWidth: 'auto', verticalAlign: 'top' }}
                            onClick={() => this.delete(questionOnAssessment.id)}
                        />
                    );
                }
                const replace_button = (
                    <Button
                        text={<Icon size={1} icon="undo" style={button_icon_style} />}
                        onClick={() => this.replace_question(questionOnAssessment)}
                        style={{ minWidth: 'auto', marginBottom: '10px', verticalAlign: 'top' }}
                        type="warning"
                    />
                );
                let question_jsx = (
                    <div className='simple-card' >
                        <div style={{ float: 'right', }}>
                            {replace_button}
                            {delete_button}
                        </div>
                        <div >
                            <a
                                href={`/edit_question/${questionOnAssessment.question.id}/`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {questionOnAssessment.question.name}
                            </a>
                        </div>

                    </div>
                )
                group_jsx.push(question_jsx)
            });
        }



        let error = null;
        if (this.state.error) {
            error = <Alert type="danger" text={this.state.error} />;
        }

        let decrease_order = (
            <Button
                text={<Icon size={1} icon="chevron-up" style={button_icon_style} />}
                style={{ minWidth: 'auto', marginBottom: '10px' }}
                disabled={{ disabled: true }}
            />
        );
        let increase_order = (
            <Button
                text={<Icon size={1} icon="chevron-down" style={button_icon_style} />}
                style={{ minWidth: 'auto' }}
                disabled={{ disabled: true }}
            />
        );
        let order_change_disable = { disabled: 'disabled' };
        if (this.props.disable_controls) {
            order_change_disable = null;
        }
        if (this.props.previous_group) {
            decrease_order = (
                <Button
                    text={<Icon size={1} icon="chevron-up" style={button_icon_style} />}
                    onClick={this.decrease_question_order}
                    type="info"
                    style={{ minWidth: 'auto', marginBottom: '10px' }}
                    disabled={order_change_disable}
                />
            );
        }
        if (this.props.next_group) {
            increase_order = (
                <Button
                    text={<Icon size={1} icon="chevron-down" style={button_icon_style} />}
                    onClick={this.increase_question_order}
                    type="info"
                    style={{ minWidth: 'auto' }}
                    disabled={order_change_disable}
                />
            );
        }

        let add_to_group = (
            <Button
                text={<Icon size={1} icon="plus" style={button_icon_style} />}
                onClick={this.add_field_test_to_group}
                type="info"
                style={{ minWidth: 'auto', marginBottom: '10px' }}
            />
        )

        return (
            <div
                style={{
                    width: '100%',
                    padding: '20px',
                    margin: '10px',
                }}

                className='simple-card'
            >
                <div className="row col-12" style={{ padding: '0px' }}>
                    <div className='col-9'>
                        <h3>Field Test Group:</h3>
                        {group_jsx}
                    </div>
                    <div className="col-3 question-performance">
                        <div style={{ float: 'right' }}>
                            {add_to_group}
                            <br />
                        </div>
                        <div style={{ float: 'right', paddingRight: '10px' }}>
                            {decrease_order}
                            <br />
                            {increase_order}
                        </div>
                    </div>
                </div>

                {error}
            </div>
        );
    }
}

export default QuestionFieldTestGroup;
