import React, { Component } from 'react';

import { getURL } from 'functions';

export default class CleverLogin extends Component {
    constructor(props) {
        super(props);

        this.oauth_redirect = this.oauth_redirect.bind(this);
    }

    oauth_redirect() {
        let url = getURL().href;
        if (url.indexOf('/login/') === -1) {
            localStorage.setItem('clever_redirect', url);
        } else {
            localStorage.removeItem('clever_redirect');
        }

        url = 'https://clever.com/oauth/authorize?response_type=code';
        url += `&redirect_uri=${window.secretReactVars.clever_redirect_url}`;
        url += `&client_id=${window.secretReactVars.clever_client_id}`;
        const { districtCleverId } = this.props;
        if (
            window.secretReactVars.clever_redirect_url.indexOf('localhost') > -1
        ) {
            url += '&district_id=5b2ad81a709e300001e2cd7a';
        } else if (districtCleverId) {
            url += `&district_id=${districtCleverId}`;
        }

        window.location = url;
    }

    render() {
        return (
            <div
                test-data-id='clever-login'
                role="button"
                tabIndex={0}
                onClick={this.oauth_redirect}
                style={{
                    cursor: 'pointer',
                    width: '200px',
                    margin: '10px auto 10px auto',
                    textAlign: 'center',
                }}
            >
                <img
                    style={{ maxHeight: '50px', width: '100%' }}
                    alt="Log in with Clever"
                    src="https://files.readme.io/efb3f5d-LogInWithClever2x.png"
                />
            </div>
        );
    }
}
