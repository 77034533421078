import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Image, Alert } from 'library';

import ListControls from 'pages/reports/results_explorer/components/list_controls.js';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';
import student_list_header_text from 'pages/reports/results_explorer/functions/student_list_header_text.js';

import SingleStudentReport from 'pages/reports/report_types/single_student.js';

const OFFSET_CHANGE = 12;

class StudentRow extends Component {
    render() {
        const { assessment } = this.props;
        const { student } = this.props.assessment;

        let knowledge = <div className="simple-grey-tag">Not Yet Scored</div>;
        if (assessment.knowledge) {
            knowledge = (
                <span style={{ fontWeight: 600 }}>{assessment.knowledge}</span>
            );
        }
        let application = <div className="simple-grey-tag">Not Yet Scored</div>;
        if (assessment.application) {
            application = (
                <span style={{ fontWeight: 600 }}>
                    {assessment.application}
                </span>
            );
        }
        let communication = (
            <div className="simple-grey-tag">Not Yet Scored</div>
        );
        if (assessment.communication) {
            communication = (
                <span style={{ fontWeight: 600 }}>
                    {assessment.communication}
                </span>
            );
        }

        let student_question_answer = null;
        if (assessment.answer || assessment.answer == '') {
            student_question_answer = (
                <td style={{ overflowX: 'auto' }}>{assessment.answer}</td>
            );
        }

        return (
            <tr>
                <td>{student.student_id}</td>
                <td>
                    <div>
                        <a onClick={() => this.props.set_student(student.id)}>
                            <Image
                                className="icon purple"
                                src="/static/images/reporting/Person.svg"
                                style={{ width: '15px', marginRight: '8px' }}
                            />
                            <span
                                className="purple-text"
                                style={{
                                    verticalAlign: 'top',
                                    lineHeight: '15px',
                                }}
                            >
                                {student.last_name}, {student.first_name}
                            </span>
                        </a>
                    </div>
                </td>
                <td>
                    <div className="regular">{assessment.name}</div>
                    <div>{assessment.term}</div>
                </td>
                {student_question_answer}
                <td>{knowledge}</td>
                <td>{application}</td>
                <td>{communication}</td>
            </tr>
        );
    }
}

export default class StudentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            loading: false,

            demographics_loaded: false,
            demographics_loading: false,

            students: [],
            offset: 0,

            csv_data: [],
            csv_loading: '',

            student_count: 0,
            student_filter: '',
        };

        this.change_offset = this.change_offset.bind(this);

        this.get_student_list = this.get_student_list.bind(this);
        this.get_student_list_callback =
            this.get_student_list_callback.bind(this);

        this.csv_download = this.csv_download.bind(this);

        this.set_student = this.set_student.bind(this);
    }

    componentDidMount() {
        this.get_student_list();

        // TODO: This conditional should be moved into results_display.js where other page distinctions are made
        if (!this.props.filters.student_list.student_id) {
            window.cmState.setGlobalState(
                'download_reporting_data',
                this.csv_download
            );
        }
    }

    change_offset(absolute, change) {
        if (absolute > -1) {
            this.setState(
                {
                    offset: absolute,
                    loading: true,
                },
                this.get_student_list
            );
        } else if (change) {
            this.setState(
                {
                    offset: this.state.offset + change,
                    loading: true,
                },
                this.get_student_list
            );
        }
    }

    get_student_list() {
        const data = {
            filters: this.props.filters.direct,
            compare_filters: {},

            offset: this.state.offset,
            limit: OFFSET_CHANGE,

            question_id: this.props.filters.student_list.question_id,
            answer: this.props.filters.student_list.question_answer,

            category: this.props.filters.student_list.category.toLowerCase(),
            proficiency: this.props.filters.student_list.proficiency,

            way_of_thinking: this.props.filters.student_list.way_of_thinking,
        };

        ajaxWrapper(
            'POST',
            `/reporting/results_explorer/student_profiles/${this.props.district_id}/`,
            data,
            this.get_student_list_callback
        );
    }

    get_student_list_callback(result) {
        this.setState({
            assessments: result.student_assessments,
            student_count: result.count,
            loaded: true,
            loading: false,
        });
    }

    csv_download() {
        const data = {
            filters: this.props.filters.direct,
            compare_filters: {},

            question_id: this.props.filters.student_list.question_id,
            answer: this.props.filters.student_list.question_answer,

            category: this.props.filters.student_list.category.toLowerCase(),
            proficiency: this.props.filters.student_list.proficiency,

            way_of_thinking: this.props.filters.student_list.way_of_thinking,

            csv: true,
        };

        const url = `/reporting/results_explorer/student_profiles_csv/${this.props.district_id}/`;

        this.setState({
            download: true,
            download_url: url,
            download_query: data,
        });
    }

    set_student(id) {
        this.props.update_filters(
            'student_list',
            {
                student_id: id,
            },
            'Student Profiles'
        );
    }

    render() {
        let students = [];
        for (const index in this.state.assessments) {
            students.push(
                <StudentRow
                    assessment={this.state.assessments[index]}
                    set_student={this.set_student}
                />
            );
        }
        if (students.length == 0) {
            students = (
                <tr>
                    <td colSpan="5">
                        <Alert
                            text="No results were found for the given scope."
                            type="warning"
                        />
                    </td>
                </tr>
            );
        }

        let list_controls = null;
        if (this.state.student_count) {
            list_controls = (
                <ListControls
                    student_count={this.state.student_count}
                    change={OFFSET_CHANGE}
                    offset={this.state.offset}
                    change_offset={this.change_offset}
                />
            );
        }

        /*
        var csv = <a style={{color:'blue'}} onClick={this.get_students_csv}>Download For Excel</a>;
        if (this.state.csv_loading == 'Loading') {
            csv = <a>Preparing Data...</a>;
        }
        else if (this.state.csv_loading == 'Loaded') {
            csv = <CSVLink filename='MathAnexDownload.csv' data={this.state.csv_data}>Download Data</CSVLink>;
        }
        */

        const { title } = student_list_header_text(this.props.filters);

        let download_modal = null;
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={this.state.download_url}
                    query={this.state.download_query}
                    onHide={() => this.setState({ download: false })}
                />
            );
        }

        let list_headers = [
            <th>Student ID</th>,
            <th>Student Name</th>,
            <th>Assessment</th>,
            <th>Knowledge</th>,
            <th>Application</th>,
            <th>Communication</th>,
        ];
        if (this.props.filters.student_list.question_answer) {
            list_headers = [
                <th>Student ID</th>,
                <th>Student Name</th>,
                <th>Assessment</th>,
                <th>Answer</th>,
                <th>Knowledge</th>,
                <th>Application</th>,
                <th>Communication</th>,
            ];
        }

        let content = (
            <div>
                <div className="result-display-card">
                    <div className="h2 bold header">{title}</div>
                    <Wrapper
                        loaded={!this.state.loading && this.state.loaded}
                        style={{ marginTop: '0px' }}
                    >
                        <table className="table">
                            <tr>{list_headers}</tr>
                            {students}
                        </table>

                        {download_modal}
                    </Wrapper>
                </div>

                {list_controls}
            </div>
        );

        // TODO: This conditional should be moved into results_display.js where other page distinctions are made
        if (this.props.filters.student_list.student_id) {
            content = (
                <SingleStudentReport
                    id={this.props.filters.student_list.student_id}
                    filters={this.props.filters}
                    update_filters={this.props.update_filters}
                />
            );
        }

        return content;
    }
}
