import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';

import {
    Container,
    Button,
    PageBreak,
    TextInput,
    Alert,
    Wrapper,
} from 'library';

class DistrictSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            districts: [],
        };

        this.get_districts = this.get_districts.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/sis/district/', {}, this.get_districts);
    }

    get_districts(result) {
        const districts = result.map(district => district.district);

        sort_objects(districts, ['name']);

        this.setState({ districts, loaded: true });
    }

    render() {
        let content = null;
        const { districts: districtsState } = this.state;
        const districts = districtsState.map(district => (
            <tr>
                <td>
                    <Button
                        type="primary"
                        text="View"
                        href={`/can_share_permissions/${district.id}/`}
                    />
                </td>
                <td>{district.name}</td>
            </tr>
        ));

        content = (
            <div className="container" style={{ textAlign: 'left' }}>
                <div className="simple-card">
                    <h2>Select A District</h2>
                    <div style={{ height: '20px' }} />
                    <table className="table">{districts}</table>
                </div>
            </div>
        );
        const { loaded } = this.state;
        return (
            <Wrapper
                loaded={loaded}
                content={content}
                style={{ marginTop: '0px' }}
            />
        );
    }
}

function UserSelect({ user, share_permissions }) {
    const share = !user.can_share_permissions;
    let text = 'Grant Sharing Ability';

    if (!share) {
        text = 'Remove Sharing Ability';
    }

    return (
        <div className="simple-card">
            <Button
                type="warning"
                text={text}
                onClick={() => share_permissions(user.id, share)}
                style={{ float: 'right' }}
            />
            <div>
                {user.email}
                <div className="simple-grey-tag">{user.role}</div>
            </div>

            <div style={{ width: '100%', clear: 'both' }} />
        </div>
    );
}

export default class SharePermissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            error: '',

            district: null,

            users: [],
            special_users: [],
        };

        this.get_users = this.get_users.bind(this);
        this.get_users_callback = this.get_users_callback.bind(this);

        this.load_district = this.load_district.bind(this);

        this.share_permissions = this.share_permissions.bind(this);
        this.share_permissions_callback =
            this.share_permissions_callback.bind(this);
    }

    componentDidMount() {
        const { district_id } = this.props;
        if (district_id) {
            ajaxWrapper(
                'GET',
                `/api/sis/district/${district_id}/`,
                {},
                this.load_district
            );
            this.get_users();
        } else {
            this.setState({
                loaded: true,
            });
        }
    }

    get_users() {
        const { district_id } = this.props;

        ajaxWrapper(
            'GET',
            `/api/user/user/?permissioned_district=${district_id}&exclude__role=student&only=role,email,id,can_share_permissions&order_by=email`,
            {},
            this.get_users_callback
        );
    }

    get_users_callback(value) {
        const special_users = [];
        const users = [];

        value.forEach(item => {
            if (item.user.can_share_permissions) {
                special_users.push(item.user);
            } else {
                users.push(item.user);
            }
        });

        this.setState({
            special_users,
            users,
            loaded: true,
        });
    }

    load_district(value) {
        this.setState({ district: value[0].district });
    }

    share_permissions(id, share) {
        const data = {
            can_share_permissions: share,
        };

        this.setState(
            {
                loaded: false,
            },
            () =>
                ajaxWrapper(
                    'POST',
                    `/api/user/user/${id}/`,
                    data,
                    this.share_permissions_callback
                )
        );
    }

    share_permissions_callback(value) {
        if ('error' in value) {
            this.setState({
                error: value.error,
            });
            const { callback } = this.state;
            callback();

            return null;
        }

        this.get_users();
    }

    render() {
        let district_title = null;
        const {
            district,
            users: usersState,
            email_filter,
            special_users: special_usersState,
        } = this.state;
        if (district) {
            district_title = (
                <div className="simple-card">
                    <h2>{district.name}</h2>
                </div>
            );
        }

        const users = usersState
            .filter(
                user => email_filter && user.email.indexOf(email_filter) > -1
            )
            .map(user => (
                <UserSelect
                    user={user}
                    share_permissions={this.share_permissions}
                />
            ));

        const special_users = special_usersState.map(user => (
            <UserSelect
                user={user}
                share_permissions={this.share_permissions}
            />
        ));

        let content = (
            <div>
                <div>
                    {district_title}

                    <div className="simple-card">
                        <h3>Grant Share Permissions Ability To Email</h3>
                        <TextInput
                            name="email"
                            label="Filter By Email"
                            handlechange={e =>
                                this.setState({ email_filter: e.target.value })
                            }
                        />
                    </div>

                    <div>{users}</div>
                    <br />
                    <br />
                </div>

                <div>
                    <PageBreak />
                </div>

                <div>
                    <div className="simple-card">
                        <h3>Permissions Already Shared</h3>
                    </div>
                    <div>{special_users}</div>
                </div>
            </div>
        );

        let error = null;
        const { error: errorState, loaded } = this.state;
        const { district_id } = this.props;
        if (errorState) {
            error = <Alert type="danger">{errorState}</Alert>;
        }

        if (!district_id) {
            content = <DistrictSelect />;
        }

        return (
            <Wrapper loaded={loaded}>
                <div className="sad" style={{ padding: '30px 0px 50px 0px' }}>
                    <Container min_height>
                        {error}
                        {content}
                    </Container>
                </div>
            </Wrapper>
        );
    }
}
