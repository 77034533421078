const moment = require('moment-timezone');

function format_date(date, date_format, timezone) {
    const date_dict = {};
    if (!timezone || typeof timezone === 'undefined') {
        timezone = 'UTC';
    }
    const tzaware_date = moment(date).tz(timezone);

    date_dict.dd = tzaware_date.format('DD');

    date_dict.mm = tzaware_date.format('MM');
    date_dict.mmm = tzaware_date.format('MMM');

    date_dict.yyyy = tzaware_date.format('YYYY');
    date_dict.yy = tzaware_date.format('YY');

    date_dict.HH = tzaware_date.format('HH');
    date_dict.hh = date_dict.HH;
    date_dict.A = 'AM';
    if (date_dict.HH > 12) {
        date_dict.hh -= 12;
        date_dict.A = 'PM';
    }

    date_dict.MM = tzaware_date.format('mm');
    date_dict.SSS = tzaware_date.format('SSS');
    date_dict.SS = tzaware_date.format('SS');

    date_dict.ZZ = tzaware_date.format('zz');

    date_dict.LLLL = tzaware_date.format('LLLL');

    const keys = [
        'A',
        'mmm',
        'dd',
        'mm',
        'yyyy',
        'yy',
        'HH',
        'hh',
        'MM',
        'SSS',
        'SS',
        'P',
        'ZZ',
        'LLLL',
    ];

    for (const index in keys) {
        const key = keys[index];
        if (date_format) {
            date_format = date_format.replace(key, date_dict[key]);
            // console.log ("New Date Format", date_format, key, date_dict[key])
        }
    }

    return date_format;
}

export default format_date;
