import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { Wrapper, Alert, FormWithChildren, Select } from 'library';
import QuestionDisplay from 'pages/questions/question_display.js';
import FeatureDisplay from 'pages/analysts/feature_analysis/feature_display.js';

export default class FeatureExplorer extends Component {
    constructor(props) {
        super(props);

        this.state = { feature_list: [], loaded: false, question: {}, offset: 0};

        this.feature_callback = this.feature_callback.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.feature_query = this.feature_query.bind(this);
    }

    componentDidMount() {
        ajaxWrapper(
            'GET',
            `/api/home/question/${this.props.question_id}/`,
            {},
            result => {
                let question = result[0]['question'];

                this.setState({
                    question: question,
                });
            }
        );

        this.feature_query();

    }

    feature_query() {
        ajaxWrapper(
            'GET',
            '/api/analysis/analysisfeature/?related=student_question_response&limit=1000&student_question_response__question=' + this.props.question_id + '&offset=' + this.state.offset,
            {},
            this.feature_callback
        );
    }

    feature_callback(data) {
        this.setState({ feature_list: data, loaded: true });
    }

    next() {
        this.setState({offset: this.state.offset + 1000}, this.feature_query)
    }

    prev() {
        this.setState({offset: this.state.offset - 1000}, this.feature_query)
    }

    render() {
        var feature_rows = [];
        for (var feature_item of this.state.feature_list) {
            var feature = feature_item.analysisfeature;
             var row = <tr>
                <td>{feature.level} - {feature.name}</td>
                <td>{feature.string_value}</td>
                <td><FeatureDisplay feature={feature} /></td>
                <td><a href={'/features_response/' + this.props.question_id + '/' + feature.student_question_response.id + '/'} target='_blank'>Details</a></td>
             </tr>
            feature_rows.push(row);
        }

        var feature_options = [
            {'text': '1 - Number', 'value': 'Number'},
            {'text': '1 - Operator', 'value': 'Operator'},
            {'text': '1 - Pronoun', 'value': 'Pronoun'},
            {'text': '2 - Equals', 'value': 'Equals'},
            {'text': '2 - Unit', 'value': 'Unit'},
            {'text': '2 - Transition Phrase', 'value': 'Transition Phrase'},
            {'text': '3 - Simple Math Expression', 'value': 'Simple Math Expression'},
            {'text': '3 - Pronoun Math Expression', 'value': 'Pronoun Math Expression'},
            {'text': '3 - Unit Math Expression', 'value': 'Unit Math Expression'},
            {'text': '3 - Partial Math', 'value': 'Partial Math'},
            {'text': '4 - Assumed Calculation', 'value': 'Assumed Calculation'},
            {'text': '5 - Assumed Pronouns', 'value': 'Assumed Pronouns'},
            {'text': '6 - Calculation Sequence', 'value': 'Calculation Sequence'},
        ]

        var content = (
            <div className="container" style={{ marginTop: '50px' }}>
                <div>
                    <h2 class="col-12">Explore Features</h2>
                    <Alert type="info">
                        <QuestionDisplay
                            question={this.state.question}
                            display="full_text"
                            image
                        />
                    </Alert>
                    <FormWithChildren defaults={this.state} autoSetGlobalState={true} setGlobalState={this.filter} globalStateName={'feature_filter'}>
                        <Select options={feature_options} label='Feature' />
                    </FormWithChildren>
                </div>
                
                <div style={{ height: '30px' }}></div>
                <table className="table">
                    <tr>
                        <th>Feature Name</th>
                        <th>Value</th>
                        <th>Student Explanation</th>
                        <th>View</th>
                    </tr>
                    {feature_rows}
                </table>
            </div>
        );

        return <Wrapper content={content} loaded={this.state.loaded} />;
    }
}
