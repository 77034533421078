import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import { sort_objects } from 'functions';
import {
    Wrapper,
    Alert,
    Container,
    Button,
    Icon,
    FormWithChildren,
    Select,
} from 'library';
import { prettifyString } from 'functions';

import SideNavbar from 'pages/reports/components/side_navbar.js';
import Header from 'pages/reports/components/header.js';
import DownloadModal from 'pages/reports/results_explorer/components/download_modal.js';
import Participation from 'pages/reports/pages/participation';
import TeacherRoster from './components/teacher_roster';
import { BASE_DOMAIN } from 'constants';

class DownloadResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            download: false,
            section_ids: null,
        };

        this.toggle_download = this.toggle_download.bind(this);
    }

    toggle_download() {
        this.setState({ download: !this.state.download });
    }

    render() {
        let filters = this.props.filters;
        filters['assessments'] = [this.props.assessment['id']];

        let download_modal = (
            <a style={{ color: 'blue' }} onClick={this.toggle_download}>
                {this.props.title}
            </a>
        );
        if (this.state.download) {
            download_modal = (
                <DownloadModal
                    url={
                        '/reporting/results_explorer/get_student_answers/' +
                        this.props.district_id +
                        '/'
                    }
                    query={{
                        csv: true,
                        filters: filters,
                        compare_filters: {},
                        answers_only: true,
                    }}
                    onHide={this.toggle_download}
                />
            );
        }

        return <div>{download_modal}</div>;
    }
}
export default class ReportDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: true,
            loading_sections: false,
            loading_schools: false,
            show_periods: false,

            height: 0,
            width: 0,

            district: null,
            current_term: null,
            terms: [],
            schools: [],
            teachers: [],
            filters: {
                school: '',
                teacher: '',
                term: '',
            },
            assessments_taken: [],

            sections: [],
            periods: [],

            page: this.props.page ? this.props.page : 'dashboard',
            teacher_reports: {},
            district_reports: {},
            district_reports_length: 0,
            teacher_reports_length: 0,

            standards_reports: [],

            next_reporting: 'We do not have any reports for you at this time.',
        };

        this.update_dimensions = this.update_dimensions.bind(this);
        this.user_has_no_access = this.user_has_no_access.bind(this);
        this.load_context = this.load_context.bind(this);

        this.load_reports = this.load_reports.bind(this);
        this.get_reports = this.get_reports.bind(this);
        this.load_taken_assessments = this.load_taken_assessments.bind(this);

        this.sort_and_transform_sub_reports = this.sort_and_transform_sub_reports.bind(this);
        this.make_report_element = this.make_report_element.bind(this);
        this.make_report_sub_element = this.make_report_sub_element.bind(this);
        this.get_teacher_sections = this.get_teacher_sections.bind(this);
        this.load_teacher_sections = this.load_teacher_sections.bind(this);

        this.update_filters = this.update_filters.bind(this);

        this.get_standards_reports = this.get_standards_reports.bind(this);
        this.load_standards_reports = this.load_standards_reports.bind(this);
    }

    componentDidMount() {
        this.update_dimensions();
        window.addEventListener('resize', this.update_dimensions.bind(this));

        if (this.state.page == 'dashboard') {
            if (
                !(window.cmState.user.role === 'Super Admin') &&
                !window.cmState.user.permissioned_district_id
            ) {
                this.user_has_no_access();
            } else {
                let url = '/reporting/context/';
                if (this.props.admin_spoof) {
                    url += `?admin_spoof=${this.props.admin_spoof}`;
                }
                if (this.props.district_spoof) {
                    url += `?district_spoof=${this.props.district_spoof}`;
                }
                ajaxWrapper('GET', url, {}, this.load_context);
            }
        } else {
            this.setState({ loaded: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update_dimensions.bind(this));
    }

    get_reports(data) {
        var teacher_reports = this.state.teacher_reports;
        var district_reports = this.state.district_reports;
        let district_reports_length = this.state.district_reports_length;
        let teacher_reports_length = this.state.teacher_reports_length;

        for (let item of data) {
            let report = item['reportdata'];
            let assessment_id = report['assessment']['id'];
            let order_by = report["assessment"]["order_by_grade_level"];
            let display_name = report['display_name'];

            if (!(assessment_id in teacher_reports)) {
                teacher_reports[assessment_id] = {
                    main_report: null,
                    section_reports: [],
                    order_by: order_by,
                    display_name: display_name,
                };
            }
            if (!(assessment_id in district_reports)) {
                district_reports[assessment_id] = {
                    main_report: null,
                    school_reports: [],
                    order_by: order_by,
                    display_name: display_name,
                };
            }

            let link = report['lookup_url'];

            if (report['teacher_id'] && !report['period']) {
                teacher_reports_length += 1;
                teacher_reports[assessment_id]['main_report'] = this.make_report_element(link, display_name);
            } else if (report['section_id'] || report['period']) {
                teacher_reports_length += 1;
                teacher_reports[assessment_id]['section_reports'].push({
                    "display_name": display_name,
                    "link": link,
                    "order_by": order_by,
                });
            } else if (report['school_id']) {
                district_reports_length += 1;
                district_reports[assessment_id]['school_reports'].push({
                    "display_name": display_name,
                    "link": link,
                    "order_by": order_by,
                });
            } else if (report['district_id']) {
                district_reports_length += 1;
                district_reports[assessment_id]['main_report'] = this.make_report_element(link, display_name);
            }
        }

        for (const assessment_id in district_reports) {
            this.sort_and_transform_sub_reports(district_reports[assessment_id]["school_reports"]);
        }
        for (const assessment_id in teacher_reports) {
            this.sort_and_transform_sub_reports(teacher_reports[assessment_id]["section_reports"]);
        }

        this.setState(
            {
                teacher_reports: teacher_reports,
                district_reports: district_reports,
                district_reports_length: district_reports_length,
                teacher_reports_length: teacher_reports_length,
            },
            function () {
                // Use the current state to deterime if schools or sections are the appropriate query
                if (
                    !this.state.filters['teacher'] &&
                    this.state.filters['school'] &&
                    !this.state.loading_schools &&
                    this.state.school_ids.length > 0
                ) {
                    this.setState(
                        {
                            loading_schools: true,
                        },
                        function () {
                            let school_ids = this.state.school_ids;
                            if (this.state.filters['school']) {
                                school_ids = [this.state.filters['school']];
                            }
                            let term_id = this.state.filters['term'];
                            let url = `/reporting/get_reports/?assessment__school_term_id=${term_id}&type=overview_for_assessment&order_by=school__name&school_id__in=${school_ids}`;
                            ajaxWrapper('GET', url, {}, this.get_reports);
                        }.bind(this)
                    );
                } else if (
                    !this.state.loading_sections &&
                    (this.state.district_user.role == 'teacher' ||
                        this.state.filters['teacher']) &&
                    (this.state.section_ids == null ||
                        this.state.section_ids.length > 0)
                ) {
                    if (this.state.section_ids == null) {
                        this.get_teacher_sections();
                    } else if (this.state.section_ids.length > 0) {
                        this.setState(
                            {
                                loading_sections: true,
                            },
                            function () {
                                let term_id = this.state.filters['term'];
                                if (
                                    this.state.show_periods &&
                                    this.state.periods.length > 0
                                ) {
                                    let url = `/reporting/get_reports/?assessment__school_term_id=${term_id}&type=overview_for_assessment&order_by=period&teacher=${this.state.filters.teacher}&period__in=${this.state.periods}`;
                                    ajaxWrapper(
                                        'GET',
                                        url,
                                        {},
                                        this.get_reports
                                    );
                                } else {
                                    let url = `/reporting/get_reports/?assessment__school_term_id=${term_id}&type=overview_for_assessment&order_by=section__period&section_id__in=${this.state.section_ids}`;
                                    ajaxWrapper(
                                        'GET',
                                        url,
                                        {},
                                        this.get_reports
                                    );
                                }
                            }.bind(this)
                        );
                    }
                } else {
                    this.setState({ loading: false });
                }
            }.bind(this)
        );
    }

    sort_and_transform_sub_reports (reports) {
        // sort, first by order_by_grade_level, then alphabetically by name
        reports.sort((a, b) => {
            let a_order = a["order_by"];
            let b_order = b["order_by"];
            let a_name = a["display_name"];
            let b_name = b["display_name"];
            return (a_order - b_order) || a_name.localeCompare(b_name)
        });
        // transform from objects into react elements
        for (let i = 0; i < reports.length; i++) {
            let report = reports[i];
            reports[i] = this.make_report_sub_element(report["link"], report["display_name"]);
        }
    }

    make_report_element (link, display_name, marginLeft='0px') {
        return (
            <p style={{marginLeft: marginLeft}}>
                <a
                    style={{ color: 'blue' }}
                    href={link}
                    //target="_blank"
                >
                    {display_name}
                </a>
            </p>
        );
    }

    make_report_sub_element (link, display_name) {
        return this.make_report_element(link, display_name, '15px');
    }

    get_teacher_sections() {
        let teacher = this.state.filters['teacher'];

        let url = `/reporting/teachers_sections/?teacher=${teacher}`;
        ajaxWrapper('GET', url, {}, this.load_teacher_sections);
    }

    load_teacher_sections(result) {
        let ids = [];
        for (let item of result['sections']) {
            ids.push(item);
        }

        let periods = [];
        for (let item of result['periods']) {
            periods.push(item);
        }

        this.setState(
            {
                section_ids: ids,
                periods: periods,
            },
            function () {
                this.get_reports([]);
            }.bind(this)
        );
    }

    update_dimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight - 1,
        });
    }

    user_has_no_access() {
        if (window.cmState.user.email.indexOf('sandi.net') > -1) {
            this.setState({
                loaded: true,
                error: 'Please Contact Your IT Support at helpdesk@sandi.net or call at (619) 209-4357, your district is currently not sharing what permissions you should have access for. Tell them you need to be listed on the Clever system.',
            });
        } else {
            this.setState({
                loaded: true,
                error: 'Please Contact Your IT Support, your district is currently not sharing what permissions you should have access for. Tell them you need to be listed on the Clever system.',
            });
        }
    }

    load_context(result) {
        let filters = this.state.filters;
        if (result['district_user']['role'] == 'teacher') {
            filters['teacher'] = result['district_user']['id'];
        }
        let school_ids = [];
        for (let item of result['schools']) {
            school_ids.push(item['value']);
        }
        filters['term'] = result['current_term']['id'];

        this.setState(
            {
                loaded: true,
                district: result['district'],
                current_term: result['current_term'],
                terms: result['terms'],
                district_user: result['district_user'],
                schools: result['schools'],
                teachers: result['teachers'],

                section_ids: result['section_ids'],
                periods: result['periods'],

                school_ids: school_ids,
                filters: filters,
                next_reporting: result['next_reporting'],
                user: result['user'],
            },
            this.load_reports
        );
    }

    load_reports() {
        let url = `/reporting/get_reports/?assessment__school_term_id=${this.state.filters['term']}&type=overview_for_assessment&period__isnull=true`;
        if (this.state.filters.teacher) {
            url += '&teacher=' + this.state.filters.teacher;
        } else {
            url += '&district=' + this.state.district.id;
        }

        this.setState(
            {
                teacher_reports: {},
                district_reports: {},
                district_reports_length: 0,
                teacher_reports_length: 0,

                loading: true,
                loading_schools: false,
                loading_sections: false,

                standards_reports: [],
            },
            function () {
                if (
                    this.state.filters['school'] &&
                    !this.state.filters['teacher']
                ) {
                    // Run query for scoped reports only
                    this.get_reports([]);
                } else {
                    ajaxWrapper('GET', url, {}, this.get_reports);

                    this.get_standards_reports();
                }
            }.bind(this)
        );

        let taken_url =
            '/reporting/assessments_taken/' + this.state.district.id + '/';
        ajaxWrapper(
            'GET',
            taken_url,
            this.state.filters,
            this.load_taken_assessments
        );
    }

    get_standards_reports() {
        let url =
            '/reporting/get_reports/?type__in=met_standard,essential_math_assets&district=' +
            this.state.district.id;
        ajaxWrapper('GET', url, {}, this.load_standards_reports);
    }

    load_standards_reports(result) {
        this.setState({
            standards_reports: result,
        });
    }

    load_taken_assessments(result) {
        this.setState({ assessments_taken: result['assessments_taken'] });
    }

    update_filters(name, state) {
        console.log('Update', name, state);
        if ('defaults' in state) {
            return false;
        }

        let filters = this.state.filters;

        if ('teacher' in state) {
            filters['teacher'] = state['teacher'];
        } else if ('school' in state) {
            filters['school'] = state['school'];
            filters['teacher'] = '';
        }

        if ('term' in state) {
            filters['term'] = state['term'];
        }

        this.setState(
            {
                loading: true,
                filters: filters,
                section_ids: null,
            },
            this.load_reports
        );
    }

    render() {
        let page = this.state.page;
        const header_height = 62;

        const main_content_style = {
            transition: '.2s margin-left',
            marginLeft: '76px',
            height: `${this.state.height}px`,
        };

        const result_style = {
            background: 'white',
            height: `${this.state.height - header_height}px`,
            transition: '.2s margin',
            overflowY: 'auto',
            paddingTop: '20px',
        };

        let active_assessment_participation = null;
        let active_assessment_link = null;
        let results_explorer = null;
        if (this.state.district) {
            results_explorer = (
                <a
                    className="purple-button-block"
                    href={`/report_navigator/results_explorer/${this.state.district['id']}/`}
                >
                    Click here to see the previous Math ANEX Data Dashboard
                </a>
            );

            if (this.state.district.has_active_assessment) {
                active_assessment_participation = (
                    <Alert type="info">
                        <span>Your district has active assessments!</span>
                        <a
                            className="purple-button-block"
                            style={{ marginLeft: '10px' }}
                            href={
                                '/administration_guide/' +
                                this.state.district.id
                            }
                        >
                            View the Assessment Administration Guide
                        </a>
                        <a
                            className="purple-button-block"
                            style={{ marginLeft: '10px' }}
                            href="/report_navigator/participation/"
                        >
                            View your participation
                        </a>
                    </Alert>
                );

                active_assessment_link = (
                    <p>
                        Have your students take the assessment by sending them
                        to this link:{'  '}
                        <a
                            style={{ color: 'blue' }}
                            href={
                                '/p/' +
                                this.state.district.proctor_url_name +
                                '/'
                            }
                            target="_blank"
                        >
                            {`${BASE_DOMAIN}`}/p/
                            {this.state.district.proctor_url_name}/
                        </a>
                    </p>
                );
            }
        }

        let pdf_explorer = <p>{this.state.next_reporting}</p>;

        if (this.state.loading) {
            pdf_explorer = <Wrapper loaded={!this.state.loading} />;
        } else if (this.state.district_reports_length > 0) {
            let pdf_jsx = [];
            let sorted_keys = Object.keys(this.state.district_reports).sort((a, b) => {
                let a_order = this.state.district_reports[a]["order_by"]
                let b_order = this.state.district_reports[b]["order_by"]
                let a_name = this.state.district_reports[a]["display_name"]
                let b_name = this.state.district_reports[b]["display_name"]
                return (a_order - b_order) || a_name.localeCompare(b_name)
            })
            for (let key of sorted_keys) {
                if (this.state.district_reports[key]['main_report']) {
                    pdf_jsx.push(
                        this.state.district_reports[key]['main_report']
                    );
                }
                pdf_jsx.push(
                    ...this.state.district_reports[key]['school_reports']
                );
            }

            let standards_reports = null;
            if (this.state.standards_reports.length > 0) {
                let report_jsx = [];
                for (let report of this.state.standards_reports) {
                    report_jsx.push(
                        <p>
                            <a
                                style={{ color: 'blue' }}
                                href={report['reportdata']['lookup_url']}
                                //target="_blank"
                            >
                                {report['reportdata']['display_name']}
                            </a>
                        </p>
                    );
                }
                standards_reports = (
                    <div>
                        <br />
                        <br />
                        <h1>Standards Reports</h1>
                        {report_jsx}
                    </div>
                );
            }

            pdf_explorer = (
                <div>
                    <div>
                        <h1 style={{ marginBottom: '15px' }}>
                            District Reports
                        </h1>
                        {pdf_jsx}
                    </div>
                    {standards_reports}
                </div>
            );
        } else if (this.state.teacher_reports_length > 0) {
            let pdf_jsx = [];
            let sorted_keys = Object.keys(this.state.teacher_reports).sort((a, b) => {
                let a_order = this.state.teacher_reports[a]["order_by"]
                let b_order = this.state.teacher_reports[b]["order_by"]
                let a_name = this.state.teacher_reports[a]["display_name"]
                let b_name = this.state.teacher_reports[b]["display_name"]
                return (a_order - b_order) || b_name.localeCompare(a_name)
            })
            for (let key of sorted_keys) {
                if (this.state.teacher_reports[key]['main_report']) {
                    pdf_jsx.push(
                        this.state.teacher_reports[key]['main_report']
                    );
                }
                pdf_jsx.push(
                    ...this.state.teacher_reports[key]['section_reports']
                );
            }
            pdf_explorer = (
                <div>
                    <h1 style={{ marginBottom: '15px' }}>
                        Teacher Reports - For assessments that your students
                        took
                    </h1>
                    {pdf_jsx}
                </div>
            );
        }

        let filters = null;
        if (this.state.district_user && this.state.district_user.role == 'teacher') {
            
            filters = (
                <FormWithChildren
                    key={'report_scoping'}
                    className="row"
                    autoSetGlobalState
                    setGlobalState={this.update_filters}
                    globalStateName="filter"
                    defaults={this.state.filters}
                >
                    <Select
                        name="term"
                        label="School Term"
                        className="col-3"
                        options={this.state.terms}
                        no_blank_option
                    ></Select>
                </FormWithChildren>
            );
        } else if (this.state.schools.length > 0) {
            let teacher_options =
                this.state.teachers[this.state.filters.school];
            if (this.state.filters.school == '') {
                teacher_options = [
                    {
                        text: 'Please Select A School First',
                        value: '',
                    },
                ];
            }

            filters = (
                <FormWithChildren
                    key={'report_scoping'}
                    className="row"
                    autoSetGlobalState
                    setGlobalState={this.update_filters}
                    globalStateName="filter"
                    defaults={this.state.filters}
                >
                    <Select
                        name="term"
                        label="School Term"
                        className="col-3"
                        options={this.state.terms}
                        no_blank_option
                    ></Select>
                    <Select
                        name="school"
                        label="School"
                        className="col-3"
                        options={this.state.schools}
                    ></Select>
                    <Select
                        name="teacher"
                        label="Teacher"
                        className="col-3"
                        options={teacher_options}
                    ></Select>
                </FormWithChildren>
            );
        }

        let period_view_button = null;
        if (
            (this.state.user && this.state.district_user.role == 'teacher') ||
            this.state.filters.teacher != ''
        ) {
            period_view_button = (
                <Alert type="info">
                    You are currently viewing reports by Section
                    <div
                        style={{ marginLeft: '10px' }}
                        className="purple-button-block"
                        onClick={() =>
                            this.setState(
                                { show_periods: true },
                                this.load_reports
                            )
                        }
                    >
                        View By Period
                    </div>
                </Alert>
            );
            if (this.state.show_periods) {
                period_view_button = (
                    <Alert type="info">
                        You are currently viewing reports by Period
                        <div
                            style={{ marginLeft: '10px' }}
                            className="purple-button-block"
                            onClick={() =>
                                this.setState(
                                    { show_periods: false },
                                    this.load_reports
                                )
                            }
                        >
                            View By Section
                        </div>
                    </Alert>
                );
            }
        }

        let raw_results = [];
        for (var assessment of this.state.assessments_taken) {
            console.log('Assessment Taken', assessment);
            raw_results.push(
                <DownloadResults
                    assessment={assessment}
                    filters={this.state.filters}
                    title={assessment['name']}
                    district_id={this.state.district.id}
                />
            );
        }

        let download_raw = null;
        if (raw_results.length > 0) {
            download_raw = (
                <div>
                    <h4>Download RAW Student Answers Below</h4>
                    {raw_results}
                </div>
            );
        }

        let student_lookup = (
            <TeacherRoster teacher={this.state.filters['teacher']} />
        );

        let content = (
            <div className="row col-12">
                <div className=" col-12">
                    <div className="alert alert-success">
                        <p className="no-style">
                            Welcome to Math ANEX! Our team believes math
                            assessments should go beyond solely reporting
                            answers as correct/incorrect, by also uncovering{' '}
                            <b>
                                how students are thinking about and approaching
                                mathematics
                            </b>
                            .<br></br>
                            <br></br>
                            In our analysis, we have identified mathematical
                            assets your students demonstrated, even in their
                            incorrect answers. To learn more about our
                            asset-based approach to math assessment,{' '}
                            <a
                                href="https://docs.google.com/document/d/1BGa33hK2yMzgdK4mwx_Vxel3Y8lgjADDTo683j7z2Mo/edit?usp=sharing"
                                target="_blank"
                            >
                                click here.
                            </a>
                        </p>
                    </div>
                    {active_assessment_participation}
                    {active_assessment_link}

                    <div className="header h1">Your Reports</div>
                    <p>
                        In the reports below, you will see insights into how
                        your students were thinking about and approaching
                        essential math concepts during recent Math ANEX
                        assessments. You will also find suggestions for
                        instructional next steps, so that you can take action
                        based on your students’ unique ways of thinking.
                    </p>
                    {filters}

                    {period_view_button}
                </div>
                <div className="col-12">
                    {pdf_explorer}
                    <br />
                    <br />

                    {download_raw}
                    <br />
                    <br />
                    {student_lookup}
                    <br />
                    <br />
                    <div>
                        <div>Looking for the previous version?</div>
                        <br />
                        {results_explorer}
                    </div>
                </div>
            </div>
        );

        let authenticated_user_role = null;
        if (window.cmState.user && window.cmState.user.district_user) {
            authenticated_user_role = window.cmState.user.district_user.role;
        }

        if (page == 'participation') {
            content = <Participation admin_spoof={this.props.admin_spoof} />;
        }

        let pdf_name = 'PDF';
        if (this.state.pdf) {
            pdf_name = this.state.pdf.display_name;
        }

        return (
            <Wrapper loaded={this.state.loaded} style={{ marginTop: '0px' }}>
                <div className="reporting-dashboard">
                    <SideNavbar
                        height={this.state.height}
                        district={this.state.district}
                    />

                    <div style={main_content_style} className="sad">
                        <Header
                            page={prettifyString(page)}
                            width={this.state.width}
                            pdf_name={pdf_name}
                        />

                        <div style={result_style}>
                            {/* Main Content Display */}

                            {content}

                            <div style={{ clear: 'both', width: '100%' }} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
