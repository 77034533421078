import React, { Component } from 'react';

import { FormWithChildren, TextArea, Wrapper, MarkdownInput } from 'library';

import { Remark } from 'react-remark';
import supersub from 'remark-supersub';

class MarkdownTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markdown: '',
        };
    }

    render() {
        let defaultOptions = {
            syntax: {
                // see micromark-extension-cite
                enableAltSyntax: false,
                enablePandocSyntax: true,
            },
            toMarkdown: {
                // see mdast-util-cite
                standardizeAltSyntax: false,
                enableAuthorSuppression: true,
                useNodeValue: false,
            },
        };

        return (
            <Wrapper loaded={true}>
                <div className="container">
                    <h2>{'Markdown Tester'}</h2>
                    <div className="sad-simple-card-container">
                        <div className="simple-card">
                            <div className="markdown-container">
                                <Remark remarkPlugins={[supersub]}>
                                    {`# What is Markdown? 
## Simple syntax for adding diversity to rendered text 
### Heres a simple guide [I'm a link](https://www.markdownguide.org/basic-syntax/)

***

In fact this whole paragraph is written in Markdown!

Here's some **bold text** 

And *italics* 

I have also included Super and Sub script 

Wrap with \^ to 

Super^script^

Wrap with \~ to 

Sub~script~

I would list it all out
- If
- I
- Could
- Make 
- A 
- List!`}
                                </Remark>
                            </div>
                        </div>
                        <FormWithChildren>
                            <MarkdownInput
                                name="markdown"
                                label="Markdown Input"
                                rows="10"
                            />
                        </FormWithChildren>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default MarkdownTest;
