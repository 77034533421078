import React, { Component } from 'react';
import { sort_objects } from 'functions';
import ajaxWrapper from 'base/ajax.js';

import { Button, FormWithChildren, Select, Wrapper, Accordion } from 'library';
import QuestionImage from 'pages/questions/image.js';

function Question(props) {
    const { question } = props;

    return (
        <tr>
            <td>
                <QuestionImage question={question} />
            </td>
            <td>{question.name}</td>
            <td>
                <Button
                    href={`/edit_question/${question.id}`}
                    text="Edit"
                    type="primary"
                    style={{ marginBottom: '10px' }}
                />
                <Button
                    type="info"
                    href={`/student_response_grouping/${question.id}/`}
                    target="_blank"
                    text="Assign Sensemaking"
                    style={{ marginBottom: '10px' }}
                />
            </td>
        </tr>
    );
}

class Questions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            questions: {},

            assessment_options: [],
            assessment: '',
        };

        this.load_questions = this.load_questions.bind(this);
        this.load_assessment_options = this.load_assessment_options.bind(this);
        this.filter_questions = this.filter_questions.bind(this);
    }

    componentDidMount() {
        const url =
            '/api/home/question/?related=category,questions_on_assessments,questions_on_assessments__assessment';

        ajaxWrapper('GET', url, {}, this.load_questions);
        ajaxWrapper(
            'GET',
            '/api/home/assessment/?related=district,school_term',
            {},
            this.load_assessment_options
        );
    }

    load_questions(result) {
        const questions = [];
        for (let item of result) {
            questions.push(item.question);
        }

        sort_objects(questions, ['created_at']);

        this.setState({
            questions,
            loaded: true,
        });
    }

    load_assessment_options(value) {
        let assessments = [];
        for (let item of value) {
            const { assessment } = item;

            const name = `${assessment.district.name} : ${assessment.school_term.name} : ${assessment.name}`;

            assessments.push({
                text: name,
                value: assessment.id,
            });
        }

        assessments = sort_objects(assessments, ['text']);

        this.setState({
            assessment_options: assessments,
        });
    }

    filter_questions(name, state) {
        this.setState(state);
    }

    render() {
        const questions = [];

        if (this.state.loaded) {
            const questions_by_category = {};

            for (let question of this.state.questions) {
                
                if (!(question.category.name in questions_by_category)) {
                    questions_by_category[question.category.name] = [];
                }

                if (this.state.assessment) {
                    for (let item of question.questions_on_assessments) {
                        const assessment =  item.questiononassessment.assessment;
                        
                        if (assessment.id == this.state.assessment) {
                            questions_by_category[
                                question.category.name
                            ].push(
                                <Question
                                    select_item={this.select_item}
                                    question={question}
                                />
                            );
                        }
                    }
                } else {
                    questions_by_category[question.category.name].push(
                        <Question
                            select_item={this.select_item}
                            question={question}
                        />
                    );
                }
            }

            for (let category in questions_by_category) {
                questions.push(
                    <Accordion name={category} index={category}>
                        <table className="table">
                            <tr>
                                <th style={{ width: '20%' }}>Image</th>
                                <th style={{ width: '50%' }}>Text</th>
                                <th style={{ width: '30%' }}>Edit</th>
                            </tr>

                            {questions_by_category[category]}
                        </table>
                    </Accordion>
                );
            }
        }

        const content = (
            <div className="container">
                <div>
                    <Button
                        type="success"
                        href="/edit_question/"
                        text="Add New Question Version"
                        style={{ float: 'right' }}
                    />
                    <h2>Questions Versions (Items)</h2>
                </div>
                <FormWithChildren
                    autoSetGlobalState
                    setGlobalState={this.filter_questions}
                    globalStateName="assessment"
                    row
                >
                    <div className="col-12">
                        <Select
                            options={this.state.assessment_options}
                            name="assessment"
                            label="Assessments"
                        />
                    </div>
                </FormWithChildren>

                {questions}
            </div>
        );

        const { loaded } = this.state;
        return <Wrapper loaded={loaded} content={content} />;
    }
}

export default Questions;
