import React, { Component } from 'react';

import { UserValidator } from 'functions';
import { Image, Icon } from 'library';

class Link extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let className = 'sidebar-nav-link h2';
        let icon_class = 'icon';
        if (this.props.page == this.props.current_page) {
            className += ' bold blue';
            icon_class += ' blue';
        }

        let item_text = <div className={className}>{this.props.page}</div>;
        if (!this.props.open) {
            item_text = null;
        }

        return (
            <div
                className="sidebar-nav-item"
                onClick={() => this.props.onClick(this.props.page)}
            >
                <Image src={this.props.icon} className={icon_class} css={{}} />
                {item_text}
            </div>
        );
    }
}

export default class Navbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const sidebar_style = {
            height: `${this.props.height}px`,
            width: '210px',
        };

        let header_text = <div className="sidebar-nav-header">Math ANEX</div>;
        let staff_district_text = null;
        if (new UserValidator().is_staff()) {
            staff_district_text = (
                <div
                    className="sidebar-nav-district"
                    style={{
                        whiteSpace: 'initial',
                        color: 'white',
                        paddingTop: '10px',
                    }}
                >
                    {this.props.district.name}
                </div>
            );
        }

        let opener = (
            <div
                className="sidebar-nav-opener open"
                onClick={() => this.props.toggle_open(false)}
            >
                <Icon
                    size={1}
                    icon_type="fas"
                    icon="chevron-left"
                    style={{ padding: '3px 5px' }}
                />
            </div>
        );

        if (!this.props.open) {
            sidebar_style.width = '76px';
            header_text = null;
            staff_district_text = null;

            opener = (
                <div
                    className="sidebar-nav-opener"
                    onClick={() => this.props.toggle_open(true)}
                >
                    <Icon
                        size={1}
                        icon_type="fas"
                        icon="chevron-right"
                        style={{ padding: '3px 6px' }}
                    />
                </div>
            );
        }

        let mindset_overview = (
            <Link
                page="Math Experience"
                icon="/static/images/reporting/Mindset.svg"
                open={this.props.open}
                current_page={this.props.page}
                onClick={this.props.set_page}
            />
        );
        if (this.props.district_id == '21311d35-fcfa-4b3f-b44f-29aa93158a05') {
            mindset_overview = null;
        }

        let standards_overview = null;
        if (window.cmState.user.is_staff) {
            standards_overview = (
                <Link
                    page="Standards"
                    icon="/static/images/reporting/More.svg"
                    open={this.props.open}
                    current_page={this.props.page}
                    onClick={this.props.set_page}
                />
            );
        }

        let lodi_test = null;
        if (
            window.location.href.indexOf(
                'a7eacea1-a448-459f-93e6-8ffede6a4404'
            ) > -1
        ) {
            lodi_test = (
                <Link
                    page="Sense Making"
                    icon="/static/images/reporting/brain.png"
                    open={this.props.open}
                    current_page={this.props.page}
                    onClick={this.props.set_page}
                />
            );
        }

        return (
            <div className="sidebar-navigation" style={sidebar_style}>
                <div
                    style={{
                        padding: '15px 15px 100px 15px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <img
                        src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                        style={{
                            verticalAlign: 'top',
                            height: '50px',
                            width: 'auto',
                        }}
                    />
                    {header_text}
                    {staff_district_text}
                </div>

                {opener}

                <ul className="sidebar-navbar-nav">
                    {lodi_test}
                    <Link
                        page="Dashboard"
                        icon="/static/images/reporting/Dashboard.svg"
                        open={this.props.open}
                        current_page={this.props.page}
                        onClick={this.props.set_page}
                    />
                    <Link
                        page="Questions"
                        icon="/static/images/reporting/Question.svg"
                        open={this.props.open}
                        current_page={this.props.page}
                        onClick={this.props.set_page}
                    />

                    <Link
                        page="Student Profiles"
                        icon="/static/images/reporting/Student profile.svg"
                        open={this.props.open}
                        current_page={this.props.page}
                        onClick={this.props.set_page}
                    />
                    {mindset_overview}

                    <Link
                        page="Student Answers"
                        icon="/static/images/reporting/Answer.svg"
                        open={this.props.open}
                        current_page={this.props.page}
                        onClick={this.props.set_page}
                    />
                    <Link
                        page="Participation"
                        icon="/static/images/reporting/Participation.svg"
                        open={this.props.open}
                        current_page={this.props.page}
                        onClick={this.props.set_page}
                    />
                    {/*
                    {standards_overview}
                    <Link page={'Sankey Explorer'} icon={'/static/images/reporting/More.svg'} open={this.props.open} current_page={this.props.page} onClick={this.props.set_page} />
                    */}
                </ul>
            </div>
        );
    }
}
