import React, { Component } from 'react';

import { UserValidator } from 'functions';
import { Image, Icon } from 'library';

class Link extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let className = 'sidebar-nav-link h2';
        let icon_class = 'icon';
        if (this.props.page == this.props.current_page) {
            className += ' bold blue';
            icon_class += ' blue';
        }

        let item_text = <div className={className}>{this.props.page}</div>;
        if (!this.props.open) {
            item_text = null;
        }

        return (
            <div
                className="sidebar-nav-item"
                onClick={() => this.props.onClick(this.props.page)}
            >
                <Image src={this.props.icon} className={icon_class} css={{}} />
                {item_text}
            </div>
        );
    }
}

export default class Navbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const sidebar_style = {
            height: `${this.props.height}px`,
            width: '76px',
        };

        return (
            <div className="sidebar-navigation no-print" style={sidebar_style}>
                <div
                    style={{
                        padding: '15px 15px 100px 15px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <a href="/report_navigator/">
                        <img
                            src="https://mathanex-media.learning.amplify.com/math_anex_logo.png"
                            style={{
                                verticalAlign: 'top',
                                height: '50px',
                                width: 'auto',
                            }}
                        />
                    </a>
                </div>
            </div>
        );
    }
}
