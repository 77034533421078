import React from 'react';
import { Image } from 'library';

export default function ClickableContent(props) {
    const click = event => {
        event.preventDefault();

        if (props.href) {
            if (props.target == '_blank') {
                window.open(props.href, '_blank');
            } else {
                window.location.href = props.href;
            }
        } else if (props.onClick) {
            props.onClick(event, props.data);
        }
    };

    const style = {
        display: 'inline-block',
        ...props.style,
    };

    let close = null;
    if (props.show_close) {
        const close_style = {
            margin: '6px 6px 6px 12px',
            width: '10px',
            ...props.close_style,
        };
        close = (
            <Image
                src="/static/images/reporting/Vector.svg"
                className="icon"
                css={close_style}
            />
        );
    }

    return (
        <div className={props.className} onClick={click} style={style}>
            {props.children}
            {close}
        </div>
    );
}
